import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_TEXT_SNIPPET,
  DELETE_TEXT_SNIPPET,
  FETCH_TEXT_SNIPPETS,
  UPDATE_TEXT_SNIPPET,
} from '../actions';

export const STATE_KEY = 'text-snippets';

const initialState = {
  data: {},
};

const TextSnippetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_TEXT_SNIPPETS}_SUCCESS`: {
      const textSnippets = R.compose(
        R.indexBy(R.prop('id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', textSnippets, state);
    }
    case `${UPDATE_TEXT_SNIPPET}_SUCCESS`:
    case `${CREATE_TEXT_SNIPPET}_SUCCESS`: {
      const textSnippet = R.defaultTo({}, getGraphqlPayload(action));
      return R.assocPath(['data', textSnippet.id], textSnippet, state);
    }
    case `${DELETE_TEXT_SNIPPET}_SUCCESS`: {
      const textSnippet = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.assoc(
        'data',
        R.omit([textSnippet.id], R.prop('data', state)),
        state,
      );
    }
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default TextSnippetsReducer;
