import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_DATA_FRAGMENT } from '../fragments';

export const CREATE_PARTY = `${namespace}/CREATE_PARTY`;

const CREATE_MUTATION = gql`
  mutation createPartyData($payload: CreatePartyDataInput!) {
    createPartyData(data: $payload) {
      ...PartyData
    }
  }
  ${PARTY_DATA_FRAGMENT}
`;

export const createParty = (payload) => ({
  type: CREATE_PARTY,
  payload: {
    key: 'createPartyData',
    graphql: {
      mutation: CREATE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
