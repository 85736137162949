import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EVENT_LOG_FRAGMENT } from '../fragments';

export const FETCH_EVENT_LOGS = `${namespace}/FETCH_EVENT_LOGS`;
// fetch event logs
const FETCH_EVENT_LOGS_QUERY = gql`
  query listEventLogs($filters: EventLogFilters!) {
    listEventLogs(filters: $filters) {
      data {
        ...EventLog
      }
      totalCount
    }
  }
  ${EVENT_LOG_FRAGMENT}
`;

export const fetchEventLogs = (filters) => ({
  type: FETCH_EVENT_LOGS,
  payload: {
    key: 'listEventLogs',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_EVENT_LOGS_QUERY,
      variables: { filters },
    },
  },
});
