import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_TYPE_FRAGMENT } from '../fragments';

export const FETCH_PARTY_TYPES = `${namespace}/FETCH_PARTY_TYPES`;

export const FETCH_PARTY_TYPES_QUERY = gql`
  query listPartyTypes {
    listPartyTypes {
      ...PartyType
    }
  }
  ${PARTY_TYPE_FRAGMENT}
`;

export const fetchPartyTypes = () => ({
  type: FETCH_PARTY_TYPES,
  payload: {
    key: 'listPartyTypes',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_TYPES_QUERY,
    },
  },
});
