import * as R from 'ramda';

/**
 * Get sorted conversation by sent or received messages.
 */

type Conversation = {
  messages?: { nodes: { createdAt: string }[] };
};

export const getNearestConversations = (partyConversations: Conversation[]) =>
  partyConversations
    .map((partyConversation) => {
      const nearestDate = R.compose<
        Conversation[],
        NonNullable<Conversation['messages']>['nodes'],
        NonNullable<Conversation['messages']>['nodes'],
        NonNullable<Conversation['messages']>['nodes'][number],
        NonNullable<Conversation['messages']>['nodes'][number]['createdAt']
      >(
        R.prop('createdAt'),
        R.head,
        R.sort(
          (a, b) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
        ),
        R.pathOr([], ['messages', 'nodes']),
      )(partyConversation);

      return R.assoc('nearestDate', nearestDate, partyConversation);
    })
    .sort(
      (a, b) =>
        new Date(b.nearestDate).valueOf() - new Date(a.nearestDate).valueOf(),
    );

/**
 * Get sorted and uniq message events.
 *
 * Sendgrid could send multiple (ex. read) events for the
 * same recipient if the user open the email multiple times
 */

type Event = {
  status: string;
  date: string;
};

export const getMostRecentUniqueEvent = (events: Event[]) => {
  const eventTypes = events.reduce<Event['status'][]>(
    (acc, event) => (acc.includes(event.status) ? acc : [...acc, event.status]),
    [],
  );

  const mostRecentUniqueEvent = eventTypes.map((eventType) =>
    R.pipe<[Event[]], Event[], Event[], Event>(
      R.filter(R.propEq('status', eventType)),
      R.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
      R.head,
    )(events),
  );

  return mostRecentUniqueEvent;
};
