import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import OnLocationChange from '@common/components/OnLocationChange';
import Preloader from '@common/components/Preloader';
import UncaughtError from '@common/components/UncaughtError';
import AppOutdatedModal from '@modules/app/containers/AppOutdatedModal';
import * as Sentry from '@sentry/react';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { detect } from 'detect-browser';
import LocationListener from './LocationListener';

const browser = detect();

const RootLayout = () => {
  const { flagsReady: areFeatureFlagsReady } = useFlagsStatus();

  if (browser && ['ie'].includes(browser.name)) {
    return <Navigate to="/unsupported-browser" />;
  }

  if (!areFeatureFlagsReady) return <Preloader />;

  return (
    <Sentry.ErrorBoundary fallback={(props) => <UncaughtError {...props} />}>
      <Suspense fallback={<Preloader />}>
        <Outlet />
        <OnLocationChange />
        <LocationListener />
        <AppOutdatedModal />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default RootLayout;
