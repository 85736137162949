const PaperPlane = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 17"
    version="1.1"
    fill="currentColor"
  >
    <g
      id="Symbols"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g id="FREE-menu" transform="translate(-381.000000, -8.000000)">
        <g id="Group-4" transform="translate(370.903906, 0.000000)">
          <g id="Group-6" transform="translate(11.000000, 9.000000)">
            <g id="Group-3">
              <polygon
                id="Stroke-330"
                points="16 5.59552404e-14 0 6.83597884 6.26086957 9.35449735"
              ></polygon>
              <polygon
                id="Stroke-331"
                points="13.2173913 13.3121693 6.26086957 9.35449735 16 -5.06261699e-14"
              ></polygon>
              <polyline
                id="Stroke-332"
                points="6.26086957 9.35449735 6.26086957 15.1111111 8.69565217 10.7936508"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PaperPlane;
