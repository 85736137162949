import { gql } from '@apollo/client';

export const ORGANIZATION_MEMBER_FRAGMENT = gql`
  fragment OrganizationMember on OrganizationMember {
    _id
    role
    organization
    user {
      _id
      email
      isActive
      deletedAt
      lastActivityDate
      profile {
        name
        title
        avatar
        role
        department
        timezone
      }
    }
    createdAt
    updatedAt
  }
`;
