import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_COMPLIANCE_PROFILE,
  DELETE_COMPLIANCE_PROFILE,
  FETCH_COMPLIANCE_PROFILE,
  SET_CURRENT_COMPLIANCE_PROFILE,
  SET_CURRENT_DOCUMENT_CHECKLISTS,
  SET_CURRENT_REQUIREMENTS,
  UPDATE_COMPLIANCE_PROFILE,
} from '../actions';

const initialState = {
  complianceProfile: {},
  currentComplianceProfile: {},
  currentRequirements: [],
  currentDocumentChecklists: [],
};

const ComplianceProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_COMPLIANCE_PROFILE}_SUCCESS`: {
      const complianceProfile = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assocPath(
        ['complianceProfile', complianceProfile._id],
        complianceProfile,
        state,
      );
    }

    case `${CREATE_COMPLIANCE_PROFILE}_SUCCESS`:
    case `${UPDATE_COMPLIANCE_PROFILE}_SUCCESS`: {
      const complianceProfile = getGraphqlPayload(action, {});
      return R.assocPath(
        ['complianceProfile', complianceProfile._id],
        complianceProfile,
        state,
      );
    }
    case `${DELETE_COMPLIANCE_PROFILE}_SUCCESS`: {
      const complianceProfile = getGraphqlPayload(action, {});
      return R.assoc(
        'complianceProfile',
        R.omit([complianceProfile._id], R.path(['complianceProfile'], state)),
        state,
      );
    }
    case SET_CURRENT_COMPLIANCE_PROFILE: {
      const currentComplianceProfile = R.pathOr({}, ['payload'], action);

      return R.assoc(
        'currentComplianceProfile',
        currentComplianceProfile,
        state,
      );
    }
    case SET_CURRENT_REQUIREMENTS: {
      const requirements = R.pathOr([], ['payload'], action);
      return R.assoc('currentRequirements', requirements, state);
    }
    case SET_CURRENT_DOCUMENT_CHECKLISTS: {
      const checklists = R.compose(
        R.filter((checklist) => checklist.type),
        R.pathOr([], ['payload']),
      )(action);

      return R.assoc('currentDocumentChecklists', checklists, state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default ComplianceProfileReducer;
