import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_DELETE_CUSTOM_FIELDS = `${namespace}/BULK_DELETE_CUSTOM_FIELDS`;

const BULK_DELETE_CUSTOM_FIELDS_MUTATION = gql`
  mutation bulkDeleteCustomFields($payload: BulkDeleteCustomFieldsInput!) {
    bulkDeleteCustomFields(data: $payload) {
      scheduled
    }
  }
`;

export const bulkDeleteCustomFields = (customFieldIds) => ({
  type: BULK_DELETE_CUSTOM_FIELDS,
  payload: {
    key: 'bulkDeleteCustomFields',
    graphql: {
      mutation: BULK_DELETE_CUSTOM_FIELDS_MUTATION,
      variables: {
        payload: {
          customFieldIds,
        },
      },
    },
  },
});
