import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_MATCH_DOCUMENTS_REQUIREMENTS = `${namespace}/BULK_MATCH_DOCUMENTS_REQUIREMENTS`;

const BULK_MATCH_DOCUMENTS_REQUIREMENTS_MUTATION = gql`
  mutation bulkMatchDocumentsRequirements(
    $payload: BulkMatchDocumentsRequirementsInput!
  ) {
    bulkMatchDocumentsRequirements(data: $payload) {
      scheduled
    }
  }
`;

export const bulkMatchDocumentsRequirements = (payload) => ({
  type: BULK_MATCH_DOCUMENTS_REQUIREMENTS,
  payload: {
    key: 'bulkMatchDocumentsRequirements',
    graphql: {
      mutation: BULK_MATCH_DOCUMENTS_REQUIREMENTS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
