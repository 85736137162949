import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPDATE_REQUIREMENT_STATUS_KEY = 'updateRequirementStatus';
export const UPDATE_REQUIREMENT_STATUS = `${namespace}/UPDATE_REQUIREMENT_STATUS`;

const UPDATE_REQUIREMENT_STATUS_MUTATION = gql`
  mutation updateRequirementStatus($payload: UpdateRequirementStatusInput!) {
    updateRequirementStatus(data: $payload) {
      _id
      name
      status
      isTrackingCompliance
      hasWaivedRequirements
      hasOverriddenRequirements
      partyComplianceProfile {
        _id
        isProcessing
        party
        metadata
        complianceProfile
        name
        nextExpirationDate
        latestExpirationDate
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
        createdAt
        updatedAt
      }
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        publicNotes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        createdAt
        updatedAt
      }
      documentChecklists {
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;

export const updateRequirementStatus = (payload) => ({
  type: UPDATE_REQUIREMENT_STATUS,
  payload: {
    key: 'updateRequirementStatus',
    graphql: {
      mutation: UPDATE_REQUIREMENT_STATUS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
