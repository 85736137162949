import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';

import DateFilterInput from './DateFilterInput';
import DateFilterTypeSelect from './DateFilterTypeSelect';
import { KeyType } from './constants';
import type {
  FilterType,
  OnFilter,
  RangeFilter,
  SelectedFilter,
  WithinFilter,
} from './types';

type DateFilterSelectorProps = {
  selectedFilter?: SelectedFilter;
  filterTypes: FilterType[];
  onChange: (selectedFilter?: SelectedFilter) => void;
};

const DateFilterSelector = ({
  selectedFilter,
  filterTypes,
  onChange,
}: DateFilterSelectorProps) => {
  const [currentFilter, setCurrentFilter] = useState<
    Partial<SelectedFilter> | undefined
  >(selectedFilter);
  const debouncedOnChange = useMemo(() => debounce(onChange, 500), [onChange]);

  const selectCurrentFilter = (key?: KeyType) => {
    setCurrentFilter(key ? { key } : undefined);

    if (!key) {
      debouncedOnChange();
    }
  };
  const handleFilterInputChange = (filter: Partial<SelectedFilter>) => {
    const { key } = filter;

    if (key === KeyType.ON && filter.value) {
      debouncedOnChange(filter as OnFilter);

      return;
    }

    if (key === KeyType.BETWEEN) {
      setCurrentFilter(filter);

      if (filter.from && filter.to) {
        debouncedOnChange(filter as RangeFilter);
      }

      return;
    }

    if (key === KeyType.NEXT || key === KeyType.LAST || key === KeyType.OLDER) {
      setCurrentFilter(filter);

      if (filter.withinDateType && filter.withinDateValue) {
        debouncedOnChange(filter as WithinFilter);
      }

      return;
    }
  };

  return (
    <>
      <DateFilterTypeSelect
        selectedFilter={selectedFilter}
        filterTypes={filterTypes}
        onChange={selectCurrentFilter}
      />
      <DateFilterInput
        selectedFilter={currentFilter}
        onChange={handleFilterInputChange}
      />
    </>
  );
};

const DateFilterSelectorWrapper = ({
  selectedFilter,
  filterTypes,
  onChange,
}: DateFilterSelectorProps) => (
  <DateFilterSelector
    key={`selectedFilter-${selectedFilter?.key}${selectedFilter?.filterType}`}
    selectedFilter={selectedFilter}
    filterTypes={filterTypes}
    onChange={onChange}
  />
);

export default DateFilterSelectorWrapper;
