import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { WaiveOverrideRequestRecordRequirementMutation } from '@graphql/types/graphql';

export const WAIVE_OVERRIDE_REQUEST_RECORD_REQUIREMENT_MUTATION = graphql(`
  mutation WaiveOverrideRequestRecordRequirement($data: ToggleRequestRecordRequirementsWaiverInput!) {
    toggleRequestRecordRequirementsWaiver(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

type WaiveOverrideRequirementVariables = {
  variables: {
    requestId: string;
    requirement: Record<string, any>;
  };
  onCompleted?: (res: WaiveOverrideRequestRecordRequirementMutation) => void;
  onError?: (error: Error) => void;
};

export function useWaiveOverrideRequirementMutation() {
  const [toggleWaiveOverrideRequirement, { loading }] = useMutation(
    WAIVE_OVERRIDE_REQUEST_RECORD_REQUIREMENT_MUTATION,
  );

  function waiveOverrideRequirement({
    variables: { requestId, requirement },
    onCompleted,
    onError,
  }: WaiveOverrideRequirementVariables) {
    return toggleWaiveOverrideRequirement({
      variables: {
        data: {
          id: requestId!,
          requirements: [requirement],
        },
      },
      onCompleted,
      onError,
    });
  }

  return {
    waiveOverrideRequirement,
    loadingWaiveOverrideRequirement: loading,
  };
}
