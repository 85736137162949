import { useIsBaseAddOnEnabled } from './useIsBaseAddOnEnabled';

export const useIsComplianceRequirementsAddOnEnabled = () => {
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  //? We will have a add-on for compliance track in the future, for now we will just return the opposite of the base add-on
  const isComplianceRequirementsAddOnEnabled = !isBaseAddOnEnabled;

  return { isComplianceRequirementsAddOnEnabled };
};
