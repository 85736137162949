import { useState } from 'react';

import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { OrganizationMember } from '@graphql/types/graphql';

import {
  excludeMembersWithoutName,
  excludeUserById,
} from '../ActivityLogs.utils';

type Member = Omit<OrganizationMember, 'organization' | 'role'>;

const ORGANIZATION_MEMBERS_AND_CURRENT_USER_QUERY = graphql(`
  query OrganizationMembers {
    listOrganizationMembers {
      _id
      user {
        email
        _id
        profile {
          name
          avatar
        }
      }
    }
    me {
      _id
      email
      profile {
        name
        avatar
      }
    }
  }
`);

export const useMembers = () => {
  const [taggableMembers, setTaggableMembers] = useState<Member[]>([]);

  const { data, loading } = useQuery(
    ORGANIZATION_MEMBERS_AND_CURRENT_USER_QUERY,
    {
      onCompleted: (data) => {
        const validMembers = excludeMembersWithoutName(
          data.listOrganizationMembers || [],
        );
        const availableMembers = excludeUserById(data.me?._id, validMembers);
        setTaggableMembers(availableMembers);
      },
    },
  );

  return {
    taggableMembers,
    loading,
    currentUser: data?.me,
  };
};
