export const SortDirectionsMap = {
  asc: 'asc',
  ascend: 'asc',
  desc: 'desc',
  descend: 'desc',
  1: 'asc',
  '-1': 'desc',
};

export const ArrowType = {
  Up: 'arrow-up',
  Down: 'arrow-down',
};

export const SortDirectionsFullMap = {
  asc: 'ascend',
  desc: 'descend',
};
