import { Row as AntRow, Col } from 'antd';
import styled from 'styled-components';

export const ColLeft = styled(Col)`
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
`;

export const ColRight = styled(Col)`
  display: flex;

  > button,
  > div {
    margin: 10px 0 10px 10px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Row = ({ children, ...props }) => {
  return <StyledAntRow {...props}>{children}</StyledAntRow>;
};

const StyledAntRow = styled(AntRow)`
  padding: 8px 0;
  display: flex;
  position: sticky;
  top: 0;
  margin: 0 -2px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: width 2s;
  z-index: 1;
  justify-content: space-between;
`;
