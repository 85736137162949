import { gql } from '@apollo/client';

export const TEXT_SNIPPETS_FRAGMENT = gql`
  fragment TextSnippet on TextSnippet {
    id
    title
    content
    visibility
    user
    createdAt
    updatedAt
  }
`;
