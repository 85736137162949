import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import styled from 'styled-components';

type CloseIconWithConfirmationProps = {
  closeConfirmText: string | React.ReactNode;
  onClose?: () => void;
};

export const CloseIconWithConfirmation = ({
  closeConfirmText,
  onClose,
}: CloseIconWithConfirmationProps) => (
  <Popconfirm
    title={<StyledConfirmText>{closeConfirmText}</StyledConfirmText>}
    icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
    okText="Delete tag"
    okType="danger"
    onConfirm={(e) => {
      e?.stopPropagation();
      onClose?.();
    }}
    onCancel={(e) => {
      e?.stopPropagation();
    }}
  >
    <CloseOutlined
      onClick={(e) => {
        e?.stopPropagation();
      }}
    />
  </Popconfirm>
);

const StyledConfirmText = styled.span`
  font-size: 13px;
`;
