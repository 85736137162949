import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_COMPLIANCE_PROFILE_FRAGMENT } from '../fragments';

export const ASSIGN_COMPLIANCE_PROFILE = `${namespace}/ASSIGN_COMPLIANCE_PROFILE`;

const ASSIGN_COMPLIANCE_PROFILE_MUTATION = gql`
  mutation assignComplianceProfile($payload: AssignComplianceProfileInput!) {
    assignComplianceProfile(data: $payload) {
      _id
      hasWaivedRequirements
      hasOverriddenRequirements
      isTrackingCompliance
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        createdAt
        updatedAt
      }
      ...PartyComplianceProfile
    }
  }
  ${PARTY_COMPLIANCE_PROFILE_FRAGMENT}
`;

export const assignComplianceProfile = (payload) => ({
  type: ASSIGN_COMPLIANCE_PROFILE,
  payload: {
    key: 'assignComplianceProfile',
    graphql: {
      mutation: ASSIGN_COMPLIANCE_PROFILE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
