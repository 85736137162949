import { BulkOperationStatus } from '@graphql/types/graphql';
import { ContactModalFormItems } from '@modules/contacts/components/ContactModalFormItems';
import { useCreatePrimaryRecordContact } from '@modules/contacts/hooks/useCreatePrimaryRecordContact';
import { emptyUndefinedToNull } from '@trustlayer/common';
import { Form, Modal, message } from 'antd';
import { useAssignContact } from '../../hooks/useAssignContact';

type AddContactModalProps = {
  primaryRecordId: string;
  open: boolean;
  onClose: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
};

export const AddContactModal = ({
  primaryRecordId,
  open,
  onClose,
  onCompleted,
  onScheduled,
}: AddContactModalProps) => {
  const [form] = Form.useForm<{
    contactEmail: string;
    contactName?: string;
    contactId?: string;
  }>();
  const {
    createPrimaryRecordContact,
    loading: createPrimaryRecordContactLoading,
  } = useCreatePrimaryRecordContact({ primaryRecordId });
  const { assignContact, loading: assignContactLoading } = useAssignContact({
    primaryRecordId,
  });
  const handleOnClear = () => {
    form.resetFields();
  };
  const handleCancel = () => {
    handleOnClear();
    onClose?.();
  };
  const handleSubmit = async () => {
    const formValues = form.getFieldsValue();
    let response: BulkOperationStatus | boolean | undefined | null;

    if (formValues.contactId) {
      response = (await assignContact(formValues.contactId)).data
        ?.assignContactsToPrimaryRecord.operation.status;
    } else {
      response = (
        await createPrimaryRecordContact({
          contact: {
            email: formValues.contactEmail,
            contactPersonName:
              emptyUndefinedToNull(formValues.contactName) ?? undefined,
          },
          isDefaultRequestRecipient: true,
          isPrimary: false,
        })
      ).data?.createContact;
    }

    const isSyncOperation =
      response === BulkOperationStatus.Completed ||
      (typeof response === 'boolean' && !response);

    if (isSyncOperation) {
      message.success(`Contact added successfully.`);
      onCompleted?.();
    }

    const isScheduledOperation =
      response === BulkOperationStatus.Scheduled ||
      (typeof response === 'boolean' && response);

    if (isScheduledOperation) {
      message.success(`Contact added has been scheduled.`);
      onScheduled?.();
    }

    if (isSyncOperation || isScheduledOperation) {
      handleOnClear();
    }

    if (response === BulkOperationStatus.Failed) {
      message.error(
        `Failed to add the contact. Please try again or contact support.`,
      );
    }
  };

  return (
    <Modal
      open={open}
      title="Add Contact"
      okText="Add contact"
      okButtonProps={{
        loading: assignContactLoading || createPrimaryRecordContactLoading,
      }}
      onOk={handleSubmit}
      onCancel={handleCancel}
      bodyStyle={{ padding: '24px' }}
      width={800}
    >
      <Form layout="vertical" form={form}>
        <ContactModalFormItems form={form} />
      </Form>
    </Modal>
  );
};
