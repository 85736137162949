import styled from 'styled-components';

export default styled.div`
  max-width: 400px;
  font-size: 13px;

  p,
  ul,
  ol,
  dl {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  li {
    margin-left: 16px;
  }
`;
