import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EVENT_LOG_FRAGMENT } from '../fragments';

export const DELETE_COMMENT_EVENT = `${namespace}/DELETE_COMMENT_EVENT`;

// Delete comment.
const DELETE_COMMENT_EVENT_QUERY = gql`
  mutation deleteCommentEvent($payload: CommentEventDeleteInput!) {
    deleteCommentEvent(data: $payload) {
      ...EventLog
    }
  }
  ${EVENT_LOG_FRAGMENT}
`;

export const deleteCommentEvent = (payload) => ({
  type: DELETE_COMMENT_EVENT,
  payload: {
    key: 'deleteCommentEvent',
    graphql: {
      fetchPolicy: 'no-cache',
      query: DELETE_COMMENT_EVENT_QUERY,
      variables: { payload },
    },
  },
});
