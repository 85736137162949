import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const ASSIGN_DOCUMENTS_TO_PARTY = `${namespace}/ASSIGN_DOCUMENTS_TO_PARTY`;

const ASSIGN_DOCUMENTS_TO_PARTY_MUTATION = gql`
  mutation assignDocumentsToParty($payload: AssignDocumentsToPartyInput!) {
    assignDocumentsToParty(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const assignDocumentsToParty = (payload) => ({
  type: ASSIGN_DOCUMENTS_TO_PARTY,
  payload: {
    key: 'assignDocumentsToParty',
    graphql: {
      mutation: ASSIGN_DOCUMENTS_TO_PARTY_MUTATION,
      variables: { payload },
    },
  },
});
