import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getTextSnippets = R.compose(
  R.values,
  R.pathOr({}, [STATE_KEY, 'data']),
);

export const getTextSnippetById = (state, id) =>
  R.pathOr({}, [STATE_KEY, 'data', id], state);
