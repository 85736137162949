import type {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import { useCallback, useMemo, useRef } from 'react';

import { getDataWithoutTypename } from '@graphql/utils';
import { ACTION_ERROR_SUFFIX, ACTION_SUCCESS_SUFFIX } from '@store/constants';
import { useAppDispatch } from '@store/hooks';

import useMutation from './useMutation';

type UseMutationWithReduxCustomOptions = {
  reduxActionType: string;
  reduxActionKey: string;
};

/**
 * Use this hook to start mutating data using Apollo hooks incrementally without being worried about breaking the current behavior.
 * This hook will dispatch SUCCESS or ERROR action for the reduxAction type you pass.
 * This allows a progressive migration of all the components which are using and/or modifying the data in redux store.
 * Example:
 *    Comp A and B are using the data in redux, and Comp B might modify that data that is now reflected in Comp A.
 *    By using this hook you can refactor Comp B and still have the change being reflected in the Comp A.
 *    Once Comp A is migrated as well, you can replace this hook in favor of useMutation
 */
const useMutationWithRedux = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: MutationHookOptions<TData, TVariables> &
    UseMutationWithReduxCustomOptions,
): MutationTuple<TData, TVariables> => {
  const dispatch = useAppDispatch();
  const { reduxActionType, reduxActionKey, ...mutationOptions } = options;

  const meta = useMemo(
    () => ({
      previousAction: {
        payload: {
          key: reduxActionKey,
        },
      },
    }),
    [reduxActionKey],
  );

  const getEventsHandler = useCallback(
    (options?: {
      onCompleted?: (data: TData) => void;
      onError?: (data: ApolloError) => void;
    }) => ({
      onCompleted: (data: TData) => {
        dispatch({
          type: `${reduxActionType}${ACTION_SUCCESS_SUFFIX}`,
          payload: { data: getDataWithoutTypename(data) },
          meta,
        });
        options?.onCompleted?.(data);
      },
      onError: (error: ApolloError) => {
        dispatch({
          type: `${reduxActionType}${ACTION_ERROR_SUFFIX}`,
          payload: error,
          meta,
        });
        options?.onError?.(error);
      },
    }),
    [dispatch, meta, reduxActionType],
  );

  /**
   * add events handler to hook options
   */
  const mutationWithReduxOptions = {
    ...mutationOptions,
    ...getEventsHandler(mutationOptions),
  };

  const [mutationFn, res] = useMutation(mutation, mutationWithReduxOptions);

  /**
   * add events handler to mutationFn
   * because when we pass onCompleted or onError to mutationFn they will override the ones passed to the hook
   */
  const mutationOptionsRef = useRef(mutationOptions);
  const mutationFnWithRedux: typeof mutationFn = useCallback(
    (options) =>
      mutationFn({
        ...options,
        ...getEventsHandler({ ...mutationOptionsRef.current, ...options }),
      }),
    [getEventsHandler, mutationFn],
  );

  return [mutationFnWithRedux, res];
};

export default useMutationWithRedux;
