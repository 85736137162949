import type { IdFilter } from '@common/components/Table';
import {
  TagsFilter as BaseTagsFilter,
  type TagsFilterOperator,
  type TagsFilterType,
} from '@modules/party';

import {
  type CustomFilterProps,
  CustomFilterWrapper,
} from '@common/components/Table';

const normalizeFilter = (filter: TagsFilterType): IdFilter | null => {
  if (!filter.value || filter.value.length === 0) {
    return null;
  }

  return {
    type: filter.operator,
    filter: filter.value,
  };
};

const denormalizeFilter = (filter?: IdFilter) => {
  if (!filter) {
    return;
  }

  return {
    operator: filter?.type as TagsFilterOperator,
    value: filter?.filter,
  };
};

export const TagsFilter = ({
  model,
  onModelChange,
}: CustomFilterProps<IdFilter>) => {
  return (
    <CustomFilterWrapper>
      <BaseTagsFilter
        key={`${model?.value}${model?.type}`}
        filter={denormalizeFilter(model)}
        onFilterChange={(filter) => onModelChange(normalizeFilter(filter))}
      />
    </CustomFilterWrapper>
  );
};
