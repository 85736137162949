import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import {
  DELETE_PARTIES,
  DELETE_PARTY,
  FETCH_PARTY_WITH_DETAILS_BY_REQUEST,
  UPDATE_SUBJECT_STATUS,
} from '@modules/party/actions';
import {
  ASSOCIATE_DOCUMENTS_TO_PROJECT,
  DETACH_DOCUMENTS_FROM_PROJECT,
} from '@modules/project/actions';
import { UPDATE_REQUIREMENT_STATUS } from '@modules/requirement/actions';
import {
  getGraphqlPayload,
  getGraphqlResponse,
  getNewGraphqlPayload,
} from '@store/helpers';

import {
  ADD_BULK_DOCUMENT_TYPES,
  ADD_DOCUMENT_FLAG,
  ADD_DOCUMENT_VERIFICATION,
  ADD_NEW_CARRIER_TO_DOCUMENT,
  ADD_SUBSCRIPTIONS_PARTY_IDS,
  ARCHIVE_DOCUMENT,
  ARCHIVE_DOCUMENTS,
  ASSIGN_DOCUMENTS_TO_PARTY,
  DELETE_DOCUMENT,
  DELETE_DOCUMENTS,
  DELETE_DOCUMENT_FLAG,
  DELETE_DOCUMENT_VERIFICATION,
  DETACH_DOCUMENTS_FROM_PARTY,
  EDIT_CARRIER_IN_DOCUMENT,
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENT_REVIEW_THUMBNAILS,
  ORGANIZATION_UPDATE_DOCUMENT_STATE,
  PROCESS_DOCUMENT,
  PROCESS_DOCUMENT_URL,
  REMOVE_BULK_DOCUMENT_TYPES,
  REMOVE_CARRIER_FROM_DOCUMENT,
  REMOVE_DOCUMENTS_DATA_LOCALLY,
  RESET_REVIEW_DOCUMENTS,
  REVIEW_DOCUMENT,
  REVIEW_DOCUMENTS,
  SET_DOCUMENT_FILLABLE_FORM_STATE,
  SET_IS_LOADED_DOCUMENTS_REVIEW,
  SET_IS_UPDATING_DOCUMENT,
  SET_IS_UPLOADING_DOCUMENT,
  UNSUBSCRIBE_TO_PARTY_DOCUMENT_PROCESSED,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_ANNOTATIONS,
  UPDATE_DOCUMENT_FLAG,
  UPDATE_DOCUMENT_STATE,
  UPDATE_DOCUMENT_VERIFICATION,
} from '../actions';

export const STATE_KEY = 'document';

const initialState = {
  data: {},
  isUpdatingDocument: false,
  isLoadedDocumentsData: false,
  isLoadedDocumentsReviewData: false,
  subscriptionsPartyIds: [],
  totalCount: 0,
  isUploadingDocuments: false,
};

const DocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_DOCUMENT_REVIEW_THUMBNAILS}_SUCCESS`: {
      const documents = R.compose(
        R.indexBy(R.prop('_id')),
        R.propOr([], 'data'),
        getNewGraphqlPayload,
      )(action);

      const existingDocuments = R.prop('data', state);
      const newDocuments = R.mergeDeepLeft(documents, existingDocuments);

      return R.compose(R.assoc('data', newDocuments))(state);
    }
    case REMOVE_DOCUMENTS_DATA_LOCALLY: {
      const documentsToRemove = R.propOr([], 'payload', action);
      const newData = R.omit(documentsToRemove, R.prop('data', state));

      return R.assocPath(['data'], newData, state);
    }
    case `${FETCH_DOCUMENT}_SUCCESS`: {
      const document = R.defaultTo({}, getGraphqlPayload(action));

      return R.assocPath(['data', document._id], document, state);
    }
    case FETCH_DOCUMENTS: {
      return R.compose(R.assoc('data', []), R.assoc('totalCount', 0))(state);
    }
    case `${FETCH_DOCUMENTS}_SUCCESS`: {
      const documents = R.compose(
        R.indexBy(R.prop('_id')),
        R.propOr([], 'data'),
        getNewGraphqlPayload,
      )(action);

      const totalCount = R.compose(
        R.propOr(0, 'totalCount'),
        getNewGraphqlPayload,
      )(action);

      return R.compose(
        R.assoc('data', documents),
        R.assoc('totalCount', totalCount),
      )(state);
    }
    case `${ASSIGN_DOCUMENTS_TO_PARTY}_SUCCESS`:
    case `${ASSOCIATE_DOCUMENTS_TO_PROJECT}_SUCCESS`:
    case `${DETACH_DOCUMENTS_FROM_PARTY}_SUCCESS`:
    case `${DETACH_DOCUMENTS_FROM_PROJECT}_SUCCESS`: {
      const documents = R.compose(
        R.mergeDeepRight(state.data),
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', documents, state);
    }
    case `${FETCH_PARTY_WITH_DETAILS_BY_REQUEST}_SUCCESS`: {
      const documents = R.compose(
        R.indexBy(R.prop('_id')),
        R.propOr([], 'documents'),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', documents, state);
    }
    case `${DELETE_PARTIES}_SUCCESS`: {
      const documents = R.compose(
        R.mergeDeepRight(state.data),
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        R.flatten,
        R.map((party) => party.documents),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', documents, state);
    }
    case `${PROCESS_DOCUMENT_URL}_SUCCESS`:
    case `${PROCESS_DOCUMENT}_SUCCESS`: {
      const document = R.defaultTo({}, getGraphqlPayload(action));

      return R.mergeRight(state, {
        data: R.assoc(document._id, document, state.data),
        totalCount: state.totalCount + 1,
      });
    }
    case `${ADD_DOCUMENT_FLAG}_SUCCESS`:
    case `${UPDATE_DOCUMENT_FLAG}_SUCCESS`:
    case `${DELETE_DOCUMENT_FLAG}_SUCCESS`:
    case `${ADD_DOCUMENT_VERIFICATION}_SUCCESS`:
    case `${UPDATE_DOCUMENT_VERIFICATION}_SUCCESS`:
    case `${DELETE_DOCUMENT_VERIFICATION}_SUCCESS`:
    case `${REVIEW_DOCUMENT}_SUCCESS`: {
      const reviewDocument = getGraphqlPayload(action);

      return R.mergeRight(state, {
        data: R.assoc(reviewDocument._id, reviewDocument, state.data),
      });
    }
    case `${ADD_BULK_DOCUMENT_TYPES}_SUCCESS`:
    case `${REMOVE_BULK_DOCUMENT_TYPES}_SUCCESS`:
    case `${REVIEW_DOCUMENTS}_SUCCESS`: {
      const reviewDocuments = getGraphqlPayload(action);
      const documentsToObject = reviewDocuments.reduce(
        (acc, document) => ({ ...acc, [document._id]: document }),
        {},
      );

      return R.mergeDeepRight(state, { data: documentsToObject });
    }
    case `${UPDATE_DOCUMENT}_SUCCESS`:
    case `${UPDATE_DOCUMENT_ANNOTATIONS}_SUCCESS`:
    case `${ADD_NEW_CARRIER_TO_DOCUMENT}_SUCCESS`:
    case `${EDIT_CARRIER_IN_DOCUMENT}_SUCCESS`:
    case `${REMOVE_CARRIER_FROM_DOCUMENT}_SUCCESS`: {
      const document = R.defaultTo({}, getGraphqlPayload(action));
      const party = R.prop('party', document);

      const documentsToUpdate = R.compose(
        R.indexBy(R.prop('_id')),
        R.map((document) =>
          R.path(['party', '_id'], document) === R.prop('_id', party)
            ? R.assoc('party', party, document)
            : document,
        ),
        R.values,
        R.assoc(document._id, document),
      )(state.data);

      return R.assoc('data', documentsToUpdate, state);
    }
    case `${UPDATE_SUBJECT_STATUS}_SUCCESS`:
    case `${UPDATE_REQUIREMENT_STATUS}_SUCCESS`: {
      const party = R.compose(R.defaultTo({}), getGraphqlResponse)(action);

      const documentsToUpdate = R.compose(
        R.indexBy(R.prop('_id')),
        R.map((document) =>
          R.path(['party', '_id'], document) === party._id
            ? R.assoc('party', party, document)
            : document,
        ),
        R.values,
        R.propOr({}, 'data'),
      )(state);

      return R.assoc('data', documentsToUpdate, state);
    }
    case UPDATE_DOCUMENT_STATE: {
      const document = R.pathOr(
        {},
        ['payload', 'data', 'documentProcessed', 'document'],
        action,
      );
      return R.assocPath(['data', document._id], document, state);
    }
    case ORGANIZATION_UPDATE_DOCUMENT_STATE: {
      const document = R.pathOr(
        {},
        ['payload', 'data', 'organizationUpdates', 'document'],
        action,
      );
      return R.assocPath(['data', document._id], document, state);
    }
    case `${ARCHIVE_DOCUMENT}_SUCCESS`: {
      const document = R.defaultTo({}, getGraphqlPayload(action));

      return R.mergeRight(state, {
        data: R.assoc(document._id, document, state.data),
        totalCount: state.totalCount - (document.archivedAt ? 1 : 0),
      });
    }
    case `${DELETE_DOCUMENT}_SUCCESS`: {
      const document = R.defaultTo({}, getGraphqlPayload(action));

      return R.mergeRight(state, {
        data: R.assoc(document._id, document, state.data),
        totalCount: state.totalCount - 1,
      });
    }
    case `${ARCHIVE_DOCUMENTS}_SUCCESS`:
    case `${DELETE_DOCUMENTS}_SUCCESS`: {
      const documents = R.compose(
        R.map((document) => R.prop('_id', document)),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);
      // TODO: the business logic below is likely wrong and potentially leads to inconsistencies
      const data = R.omit(documents, state.data);
      const totalCount = state.totalCount - documents.length;

      return R.mergeRight(state, {
        data,
        totalCount,
      });
    }
    case `${DELETE_PARTY}_SUCCESS`: {
      const party = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      const documentsIds = R.compose(
        R.defaultTo([]),
        R.map((document) => document._id),
        R.filter(
          (document) => R.path(['party', '_id'], document) === party._id,
        ),
        R.values,
        R.propOr({}, 'data'),
      )(state);

      return R.assoc(
        'data',
        R.omit(documentsIds, R.prop('data', state)),
        state,
      );
    }
    case SET_IS_LOADED_DOCUMENTS_REVIEW: {
      return R.assoc('isLoadedDocumentsReviewData', action.payload, state);
    }
    case SET_IS_UPDATING_DOCUMENT: {
      return R.assoc('isUpdatingDocument', action.payload, state);
    }
    case ADD_SUBSCRIPTIONS_PARTY_IDS: {
      return R.assoc(
        'subscriptionsPartyIds',
        [...state.subscriptionsPartyIds, ...action.payload],
        state,
      );
    }
    case UNSUBSCRIBE_TO_PARTY_DOCUMENT_PROCESSED: {
      const newSubs = state.subscriptionsPartyIds.filter(
        (id) => id !== action.payload,
      );
      return R.assoc('subscriptionsPartyIds', newSubs, state);
    }
    case SET_IS_UPLOADING_DOCUMENT: {
      return R.assoc('isUploadingDocuments', action.payload, state);
    }
    case SET_DOCUMENT_FILLABLE_FORM_STATE: {
      const { documentId, status } = action.payload;

      /**
       * Since "R.assocPath" will create "fillableForm" key
       * if it doesn't exist in "document", the following check is needed.
       */
      const hasFillableForm = Boolean(state.data[documentId]?.fillableForm);

      if (hasFillableForm) {
        return R.assocPath(
          ['data', documentId, 'fillableForm', 'status'],
          status,
          state,
        );
      }

      return {
        ...state,
      };
    }
    case RESET_REVIEW_DOCUMENTS: {
      return R.assoc(
        'subscriptionsPartyIds',
        R.prop('subscriptionsPartyIds', state),
        initialState,
      );
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default DocumentReducer;
