import type {
  Contact,
  Conversation as ConversationAPI,
  User,
} from '@graphql/types/graphql';
import {
  MessageTitleByType,
  type MessageType as MessageTypeObj,
  type PartyConversationType,
  type PartyMessageStatus,
} from '@modules/party-conversation';
import { normalizeContacts } from '../../utils/normalizeContacts';

type MessageType = (typeof MessageTypeObj)[keyof typeof MessageTypeObj];
type MessageStatus =
  (typeof PartyMessageStatus)[keyof typeof PartyMessageStatus];
type SystemMessageType = keyof typeof MessageTitleByType;
type ConversationType =
  (typeof PartyConversationType)[keyof typeof PartyConversationType];

type MessagePreview = {
  _id: string;
  isRead: boolean;
  text: string;
  status: MessageStatus;
  author?: string;
  createdAt: string;
};

type Conversation = {
  _id: string;
  request?: string;
  subject?: string;
  type: ConversationType;
  createdAt: string;
  usersParticipants: ReturnType<typeof normalizeContacts>;
  contactsParticipants: ReturnType<typeof normalizeContacts>;
  lastMessage: MessagePreview;
};

export const getContactsMap = (contacts: any[]) => {
  return contacts.reduce(
    (acc, contact) => ({
      ...acc,
      [contact._id]: contact,
    }),
    {},
  );
};

export const getMessageContent = ({
  type,
  messageText,
}: {
  type?: MessageType | null;
  messageText: string;
}) => {
  const messageValue = Object.keys(MessageTitleByType).includes(type || '')
    ? MessageTitleByType[type as SystemMessageType]
    : messageText;

  return Boolean(messageValue) ? messageValue : 'This message has no content';
};

const getMessageAuthor = ({
  senderContact,
  senderUser,
}: {
  senderContact?: Contact;
  senderUser?: User;
}) =>
  Boolean(senderContact)
    ? senderContact?.contactPersonName || senderContact?.email || undefined
    : senderUser?.profile?.name || senderUser?.email;
/**
 * Map "conversations" API data to Conversations component props
 */
export const mapConversationsList = ({
  conversations,
}: {
  conversations: ConversationAPI[];
}): Conversation[] =>
  conversations.map((conversation) => {
    const message = conversation.messages.nodes[0];

    return {
      _id: conversation._id,
      request: conversation.request,
      subject: conversation.subject,
      type: conversation.type as ConversationType,
      createdAt: conversation.createdAt,
      contactsParticipants: normalizeContacts(
        conversation.participants.nodes || [],
      ),
      usersParticipants: normalizeContacts(
        conversation.usersParticipants.nodes || [],
      ),
      lastMessage: {
        _id: message._id,
        isRead: Boolean(message.readAt),
        createdAt: message.createdAt,
        status: message.status,
        text: getMessageContent({
          messageText: message.message,
          type: message.type as MessageType,
        }),
        author: getMessageAuthor({
          senderContact: message.senderContact || undefined,
          senderUser: message.senderUser || undefined,
        }),
      },
    };
  });
