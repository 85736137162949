import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const PROCESS_IMPORT_FILE = `${namespace}/PROCESS_IMPORT_FILE`;

const PROCESS_IMPORT_FILE_MUTATION = gql`
  mutation processImportFile($payload: ProcessImportFileInput!) {
    processImportFile(data: $payload) {
      url
      friendlyName
      s3Key
    }
  }
`;

export const processImportFile = (payload) => ({
  type: PROCESS_IMPORT_FILE,
  payload: {
    key: 'processImportFile',
    graphql: {
      mutation: PROCESS_IMPORT_FILE_MUTATION,
      variables: { payload },
      context: { hasUpload: true },
    },
  },
});
