import { LoadingOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

type InfiniteScrollListProps<ListData = any> = {
  loading?: boolean;
  list: ListData[];
  hasMoreItems: boolean;
  loadMoreItems: () => void;
  children: (param: ListData) => JSX.Element;
};

const OFFSET = 50;

export const InifiniteScrollList = ({
  loading = false,
  list,
  hasMoreItems,
  loadMoreItems,
  children,
}: InfiniteScrollListProps) => {
  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement>) => {
      if (!hasMoreItems || loading) {
        return;
      }

      const { scrollTop, scrollHeight, clientHeight } =
        (event?.target as HTMLUListElement) || {};

      // Check the direction of the scroll
      if (scrollTop > prevScrollTop) {
        if (scrollTop + OFFSET > scrollHeight - clientHeight) {
          loadMoreItems();
        }
      }

      // Update the previous scrollTop value
      setPrevScrollTop(scrollTop);
    },
    [loadMoreItems, hasMoreItems, loading, prevScrollTop],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: need to refresh the debounced function when handleScroll changes
  const debouncedHandleScroll = useCallback(debounce(handleScroll, 300), [
    handleScroll,
  ]);

  return (
    <StyledList onScroll={debouncedHandleScroll}>
      {list.map(children)}
      {loading && (
        <StyledLoader>
          <LoadingOutlined />
        </StyledLoader>
      )}
    </StyledList>
  );
};

const StyledList = styled.ul`
  margin: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  position: relative;
`;

const StyledLoader = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  pointer-events: none;
`;
