import * as R from 'ramda';

import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_PARTY_TYPE,
  DELETE_PARTY_TYPE,
  FETCH_PARTY_TYPES,
  SET_IS_ASSIGNING_TYPE,
  UPDATE_PARTY_TYPE,
} from '../actions';

const initialState = {
  data: {},
  isAssigningType: false,
};

const PartyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PARTY_TYPES}_SUCCESS`: {
      const partyTypes = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', partyTypes, state);
    }
    case `${CREATE_PARTY_TYPE}_SUCCESS`:
    case `${UPDATE_PARTY_TYPE}_SUCCESS`: {
      const partyType = R.compose(
        // type => R.mergeRight(R.prop(type._id, state.data), type),
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assocPath(['data', partyType._id], partyType, state);
    }
    case `${DELETE_PARTY_TYPE}_SUCCESS`: {
      const partyType = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.assoc(
        'data',
        R.omit([partyType._id], R.path(['data'], state)),
        state,
      );
    }
    case SET_IS_ASSIGNING_TYPE: {
      return R.assoc('isAssigningType', action.payload, state);
    }
    default: {
      return state;
    }
  }
};

export default PartyTypeReducer;
