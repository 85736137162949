import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_TEMPLATE_FRAGMENT } from '../fragments';

export const CREATE_EMAIL_TEMPLATE = `${namespace}/CREATE_EMAIL_TEMPLATE`;

const CREATE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation createEmailTemplate($payload: CreateEmailTemplateInput!) {
    createEmailTemplate(data: $payload) {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const createEmailTemplate = (payload) => ({
  type: CREATE_EMAIL_TEMPLATE,
  payload: {
    key: 'createEmailTemplate',
    graphql: {
      mutation: CREATE_EMAIL_TEMPLATE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
