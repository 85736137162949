import { Form, Input, Modal } from 'antd';

import { useCreateOrganization } from './hooks';

type CreateOrganizationModalProps = {
  visible: boolean;
  onClose: () => void;
};

export const CreateOrganizationModal = ({
  visible,
  onClose,
}: CreateOrganizationModalProps) => {
  const [form] = Form.useForm();
  const { createOrganization } = useCreateOrganization();

  const handleConfirm = async () => {
    if (form) {
      const values = await form.validateFields();
      createOrganization(values);
      onClose();
    }
  };

  return (
    <Modal
      title="Create a new workspace"
      open={visible}
      okText="Create"
      onOk={handleConfirm}
      onCancel={onClose}
    >
      <Form form={form} name="create_new_organization">
        <p>
          You can create and join multiple workspaces with the same account.
          Workspaces are isolated, and no information is shared between them.
        </p>
        <p>Please provide a name for your new workspace:</p>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input placeholder="Name..." />
        </Form.Item>
      </Form>
    </Modal>
  );
};
