import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const ARCHIVE_DOCUMENTS = `${namespace}/ARCHIVE_DOCUMENTS`;

const ARCHIVE_DOCUMENTS_MUTATION = gql`
  mutation archiveDocuments($payload: ArchiveDocumentsInput!) {
    archiveDocuments(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const archiveDocuments = (payload) => ({
  type: ARCHIVE_DOCUMENTS,
  payload: {
    key: 'archiveDocuments',
    graphql: {
      mutation: ARCHIVE_DOCUMENTS_MUTATION,
      variables: { payload },
    },
  },
});
