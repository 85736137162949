import { Empty, Tabs } from 'antd';
import styled from 'styled-components';

import ProjectRequirementsTreeContainer from '../containers/ProjectRequirementsTreeContainer';

const ProjectRequirementsView = ({
  localComplianceProfiles,
  onCheckRequirement,
  onCheckDocumentChecklist,
}) => (
  <ProjectRequirementsView.Wrapper data-cy="projectComplianceProfilesTab">
    {Boolean(localComplianceProfiles.length) ? (
      <>
        <ProjectRequirementsView.Title>
          Review and edit the compliance profiles in use in this project:
        </ProjectRequirementsView.Title>
        <Tabs type="card">
          {localComplianceProfiles.map((localComplianceProfile) => (
            <Tabs.TabPane
              tab={localComplianceProfile.name}
              key={localComplianceProfile._id}
            >
              <ProjectRequirementsTreeContainer
                localComplianceProfile={localComplianceProfile}
                onCheckRequirement={(...args) =>
                  onCheckRequirement(localComplianceProfile, 'rules', ...args)
                }
                onCheckDocumentChecklist={(...args) =>
                  onCheckDocumentChecklist(
                    localComplianceProfile,
                    'documentChecklists',
                    ...args,
                  )
                }
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </>
    ) : (
      <>
        <ProjectRequirementsView.Title>
          Compliance profiles in use in this project will be visible here. To
          get started, add at least one party with a compliance profile to this
          project.
        </ProjectRequirementsView.Title>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No compliance profiles to show"
        />
      </>
    )}
  </ProjectRequirementsView.Wrapper>
);

ProjectRequirementsView.Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

ProjectRequirementsView.Title = styled.div`
  font-size: 13px;
  margin-bottom: 30px;
`;

export default ProjectRequirementsView;
