import type { TableSortingState } from '@common/components/Table';
import { APPLIES_TO_ALL_PROJECTS_KEY } from '@modules/document/constants';

import type { LegacyDocumentsReportParams } from '@modules/report/types';
import { getReportDateFilter } from '@modules/report/utils';
import type { TableFiltersModel } from '../types';

/**
 * @name mapTableSortingToLegacyDocumentsReportSorting
 * @description - Maps the sorting state to the API sorting params
 */
const mapTableSortingToLegacyDocumentsReportSorting = ({
  columnId,
  direction,
}: TableSortingState) => {
  return { sortField: columnId, sortDirection: direction };
};

type UtilityParams = {
  filters: TableFiltersModel;
  sorting: TableSortingState;
};

export const mapTableParamsToLegacyDocumentsReportParams = ({
  filters: tableFilters,
  sorting: tableSorting,
}: UtilityParams): LegacyDocumentsReportParams => {
  // eslint-disable-next-line fp/no-let
  let reportFilters: LegacyDocumentsReportParams = {};

  if (tableFilters.verification) {
    reportFilters = {
      ...reportFilters,
      verification: tableFilters.verification.values as string | string[],
    };
  }

  if (tableFilters.severity) {
    reportFilters = {
      ...reportFilters,
      flag: tableFilters.severity.values as string | string[],
    };
  }

  if (tableFilters.archiveStatus) {
    reportFilters = {
      ...reportFilters,
      status: tableFilters.archiveStatus.values as string | string[],
    };
  }

  if (tableFilters.friendlyName) {
    reportFilters = {
      ...reportFilters,
      name: tableFilters.friendlyName.filter as string | undefined,
    };
  }

  if (tableFilters.requirementId) {
    reportFilters = {
      ...reportFilters,
      requirementId: tableFilters.requirementId.filter as string | undefined,
    };
  }

  if (tableFilters.reviewStatus) {
    reportFilters = {
      ...reportFilters,
      reviewStatus: tableFilters.reviewStatus.values as string | string[],
    };
  }

  if (tableFilters.subjects) {
    reportFilters = {
      ...reportFilters,
      subject: tableFilters.subjects,
    };
  }

  if (tableFilters.projects) {
    reportFilters = {
      ...reportFilters,
      associatedProjects: tableFilters.projects,
    };
  }

  if (tableFilters.type) {
    reportFilters = {
      ...reportFilters,
      type: tableFilters.type,
    };
  }

  if (tableFilters.appliesToAllProjects) {
    reportFilters = {
      ...reportFilters,
      associatedProjects: [
        APPLIES_TO_ALL_PROJECTS_KEY,
        ...(reportFilters.associatedProjects || []),
      ],
    };
  }

  if (tableFilters.expirationDate) {
    const expirationDateParams = getReportDateFilter(
      tableFilters.expirationDate,
      'expirationDate',
    );
    reportFilters = {
      ...reportFilters,
      ...expirationDateParams,
    };
  }

  if (tableFilters.createdAt) {
    const uploadDateParams = getReportDateFilter(
      tableFilters.createdAt,
      'uploadDate',
    );
    reportFilters = {
      ...reportFilters,
      ...uploadDateParams,
    };
  }

  const reportSorting =
    mapTableSortingToLegacyDocumentsReportSorting(tableSorting);

  return {
    ...reportFilters,
    ...reportSorting,
  };
};
