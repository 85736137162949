import { BulkOperationStatus } from '@graphql/types/graphql';
import { usePrimaryRecordName } from '@modules/primary-records';
import { recordEmptyUndefinedToNull } from '@trustlayer/common';
import { Form, Modal, message } from 'antd';
import { omit } from 'ramda';
import { useUpdatePrimaryRecordContact } from '../../hooks/useUpdatePrimaryRecordContact';
import { ContactForm, type ContactFormValues } from '../ContactForm';

type UpdateContactModalProps = {
  primaryRecordId: string;
  contactToUpdate: ContactFormValues;
  onCompleted: () => void;
  onClose: () => void;
};

export const UpdateContactModal = ({
  primaryRecordId,
  contactToUpdate,
  onCompleted,
  onClose,
}: UpdateContactModalProps) => {
  const { updatePrimaryRecordContact, loading } = useUpdatePrimaryRecordContact(
    {
      primaryRecordId,
    },
  );

  const { name } = usePrimaryRecordName({
    primaryRecordId,
  });
  const [form] = Form.useForm();

  const handleUpdateContact = async () => {
    const isFormValid = await form.validateFields();

    if (!isFormValid) {
      return;
    }

    const updatedContact = recordEmptyUndefinedToNull({
      //? Order is important here
      ...contactToUpdate,
      ...form.getFieldsValue(),
    });

    const res = await updatePrimaryRecordContact({
      contact: omit(['primary', 'defaultRequestRecipient'], updatedContact),
      isPrimary: updatedContact.primary,
      isDefaultRequestRecipient: updatedContact.defaultRequestRecipient,
    });

    const status = res.data?.updateContacts.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`Contact updated successfully.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(`Contact updated has been scheduled.`);
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `Failed to update the contact. Please try again or contact support.`,
      );
    }
  };

  return (
    <Modal
      open
      title="Edit contact"
      okText="Update contact"
      onOk={handleUpdateContact}
      confirmLoading={loading}
      onCancel={() => {
        onClose?.();
      }}
    >
      <ContactForm
        form={form}
        initialValue={contactToUpdate}
        companyName={name}
      />
    </Modal>
  );
};
