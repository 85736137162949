import moment from 'moment';
import * as R from 'ramda';

import { getGraphqlPayload } from '@store/helpers';

import { SET_REQUEST_TOKEN_ID, VALIDATE_REQUEST_TOKEN } from '../actions';

const initialState = {
  token: '',
  requestId: '',
  expiresAt: '',
  validatedAt: '',
};

const RequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${VALIDATE_REQUEST_TOKEN}_SUCCESS`: {
      const tokenData = getGraphqlPayload(action);
      tokenData.expiresAt = moment().add(1, 'h').format();

      return R.mergeDeepRight(state, tokenData);
    }
    case SET_REQUEST_TOKEN_ID: {
      return R.assoc('requestId', action.payload, state);
    }
    default: {
      return state;
    }
  }
};

export default RequestReducer;
