import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import { NetworkStatus } from '@apollo/client';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { useConversationUpdatesSubscription } from '@modules/primary-records/hooks/useConversationUpdatesSubscription';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { mapConversationsList } from '../Conversations.utils';

const DEFAULT_CONVERSATION_LIST_INPUT = {
  first: 10,
  sort: {
    lastReceivedConversationMessage: -1,
  },
};

const DEFAULT_MESSAGES_INPUT = {
  // get the last message of the conversation
  first: 1,
  offset: 0,
  sort: {
    createdAt: -1,
  },
};

export const CONVERSATIONS_QUERY = graphql(`
  query PrimaryRecordConversations(
    $primaryRecordInput: PrimaryRecordInput!
    $input: ConversationsInput
    $messagesInput: ConversationMessagesInput
  ) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      contacts {
        totalCount
      }
      conversations(input: $input) {
        totalCount
        nodes {
          _id
          createdAt
          subject
          request
          type
          participants {
            nodes {
              _id
              contactPersonName
              email
            }
          }
          usersParticipants {
            nodes {
              _id
              email
              profile {
                name
              }
            }
          }
          messages(input: $messagesInput) {
            nodes {
              _id
              message
              createdAt
              readAt
              status
              type
              senderContact {
                _id
                email
                contactPersonName
              }
              senderUser {
                _id
                email
                profile {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const useConversations = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [
    fetchConversations,
    { loading, fetchMore, data, refetch, networkStatus },
  ] = useLazyQuery(CONVERSATIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    nodes: conversationsList = [],
    totalCount: conversationsTotalCount = 0,
  } = data?.primaryRecord.conversations || {};

  const totalContactsCount = data?.primaryRecord.contacts.totalCount;

  const filterConversationsByMessageOrSubject = useMemo(
    () =>
      debounce((text: string) => {
        fetchConversations({
          variables: {
            primaryRecordInput: {
              id: primaryRecordId,
            },
            messagesInput: DEFAULT_MESSAGES_INPUT,
            input: {
              ...DEFAULT_CONVERSATION_LIST_INPUT,
              offset: 0,
              filter: {
                or: [
                  {
                    name: 'message',
                    operator: FILTER_OPERATORS.contains,
                    value: text,
                  },
                  {
                    name: 'subject',
                    operator: FILTER_OPERATORS.contains,
                    value: text,
                  },
                ],
              },
            },
          },
        });
      }, 500),
    [fetchConversations, primaryRecordId],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps to trigger only during the mount time
  useEffect(() => {
    fetchConversations({
      variables: {
        primaryRecordInput: { id: primaryRecordId },
        messagesInput: DEFAULT_MESSAGES_INPUT,
        input: {
          ...DEFAULT_CONVERSATION_LIST_INPUT,
          offset: 0,
        },
      },
    });
  }, []);

  const fetchMoreConversations = useCallback(() => {
    fetchMore({
      variables: {
        primaryRecordId: primaryRecordId,
        messagesInput: DEFAULT_MESSAGES_INPUT,
        input: {
          ...DEFAULT_CONVERSATION_LIST_INPUT,
          offset: conversationsList.length,
        },
      },
    });
  }, [conversationsList.length, primaryRecordId, fetchMore]);

  const refetchConversations = useCallback(() => {
    refetch({
      primaryRecordInput: { id: primaryRecordId },
      messagesInput: DEFAULT_MESSAGES_INPUT,
      input: {
        ...DEFAULT_CONVERSATION_LIST_INPUT,
        offset: 0,
      },
    });
  }, [primaryRecordId, refetch]);

  useConversationUpdatesSubscription({
    partyId: primaryRecordId,
    onData: () => refetchConversations(),
  });

  const _conversationsList = useMemo(() => {
    return mapConversationsList({
      // @ts-expect-error - Server type expects optional fields as required (totalCounts, and most of the Message fields)
      conversations: conversationsList,
    });
  }, [conversationsList]);

  return {
    loading,
    isInitialLoading: networkStatus === NetworkStatus.loading,
    filterConversationsByMessageOrSubject,
    hasMoreConversationsToFetch:
      _conversationsList.length < conversationsTotalCount,
    conversationsList: _conversationsList,
    totalContactsCount,
    fetchMoreConversations,
    refetchConversations,
  };
};
