import { graphql } from '@graphql/types';

export const NOTIFICATIONS_LIST_QUERY = graphql(`
  query NotificationsList(
    $filter: NotificationFiltersInput
    $first: Int
    $offset: Int
    $sort: JSONObject
  ) {
    notifications(
      filter: $filter
      first: $first
      offset: $offset
      sort: $sort
    ) {
      nodes {
        _id
        type
        readAt
        recipient
        organization
        createdAt
        updatedAt
        data
      }
      totalCount
    }
  }
`);

export const NOTIFICATIONS_LIST_TOTAL_COUNT_QUERY = graphql(`
  query NotificationsListTotalCount($filter: NotificationFiltersInput) {
    notifications(filter: $filter) {
      totalCount
    }
  }
`);
