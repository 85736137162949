import { NetworkStatus } from '@apollo/client';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { Organization } from '@graphql/types/graphql';
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';

const ORGANIZATIONS_QUERY = graphql(`
  query Organizations($input: ListOrganizationsInput!) {
    listOrganizations(input: $input) {
      totalCount
      nodes {
        id
        name
        icon
        slugifyName
      }
    }
  }
`);

export const useOrganizations = () => {
  const getVariables = useCallback(
    (offset: number, name?: string) => ({
      input: {
        first: 5,
        offset: offset,
        ...(name && {
          filter: {
            and: [
              {
                name: 'name',
                operator: FILTER_OPERATORS.contains,
                value: name,
              },
            ],
          },
        }),
      },
    }),
    [],
  );

  const [
    fetch,
    { data: organizationsData, loading, fetchMore, networkStatus },
  ] = useLazyQuery(ORGANIZATIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const listOrganizations = organizationsData?.listOrganizations;

  const fetchOrganizations = useCallback(
    debounce((name?: string) => {
      fetch({ variables: getVariables(0, name) });
    }, 500),
    [],
  );

  const fetchMoreOrganizations = (name?: string) =>
    fetchMore({
      variables: getVariables(listOrganizations?.nodes.length || 0, name),
    });

  useEffect(() => {
    fetch({ variables: getVariables(0) });
  }, [fetch, getVariables]);

  return {
    organizations: (listOrganizations?.nodes as Organization[]) || [],
    total: listOrganizations?.totalCount || 0,
    isLoading: loading,
    isFetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchOrganizations,
    fetchMoreOrganizations,
  };
};
