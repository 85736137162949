import type { RootState } from '@common/types';
import { STATE_KEY } from '../constants';

export const getIsAppAuthenticated = (state: RootState) =>
  state[STATE_KEY]?.isAuthenticatedAppReady;

export const getIsPersistRehydrated = (state: RootState) =>
  state[STATE_KEY]?.isPersistRehydrated;

export const getAppVersions = (state: RootState) => state[STATE_KEY]?.versions;
