import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { getGraphqlPayload, getGraphqlResponse } from '@store/helpers';

import {
  CREATE_PARTY_CUSTOM_FIELD,
  CREATE_PROJECT_CUSTOM_FIELDS,
  DELETE_CUSTOM_FIELD,
  FETCH_PARTY_CUSTOM_FIELDS,
  FETCH_PROJECT_CUSTOM_FIELDS,
  UPDATE_PARTY_CUSTOM_FIELD,
  UPDATE_PROJECT_CUSTOM_FIELDS,
} from '../actions';

const initialState = {
  projectCustomFields: {},
  partyCustomFields: {},
  partyCustomFieldsTotalCount: 0,
  projectCustomFieldsTotalCount: 0,
};

const getCustomFieldsData = (actionResponse) => {
  const customFieldsMap = R.compose(
    R.indexBy(R.prop('_id')),
    R.defaultTo([]),
    R.path(['nodes']),
  )(actionResponse);

  const totalCount = actionResponse?.totalCount || 0;

  return {
    customFieldsMap,
    totalCount,
  };
};

const CustomFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PROJECT_CUSTOM_FIELDS}_SUCCESS`: {
      const response = getGraphqlResponse(action);
      const { customFieldsMap, totalCount } = getCustomFieldsData(response);

      return {
        ...state,
        projectCustomFields: customFieldsMap,
        projectCustomFieldsTotalCount: totalCount,
      };
    }
    case `${FETCH_PARTY_CUSTOM_FIELDS}_SUCCESS`: {
      const response = getGraphqlResponse(action);
      const { customFieldsMap, totalCount } = getCustomFieldsData(response);

      return {
        ...state,
        partyCustomFields: customFieldsMap,
        partyCustomFieldsTotalCount: totalCount,
      };
    }
    case `${UPDATE_PROJECT_CUSTOM_FIELDS}_SUCCESS`:
    case `${CREATE_PROJECT_CUSTOM_FIELDS}_SUCCESS`: {
      const customField = R.defaultTo({}, getGraphqlPayload(action));

      return R.assocPath(
        ['projectCustomFields', customField._id],
        customField,
        state,
      );
    }
    case `${UPDATE_PARTY_CUSTOM_FIELD}_SUCCESS`:
    case `${CREATE_PARTY_CUSTOM_FIELD}_SUCCESS`: {
      const partyCustomField = R.defaultTo({}, getGraphqlPayload(action));

      return R.assocPath(
        ['partyCustomFields', partyCustomField._id],
        partyCustomField,
        state,
      );
    }
    case `${DELETE_CUSTOM_FIELD}_SUCCESS`: {
      const customField = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      const projectCustomFields = R.omit(
        [customField._id],
        R.prop('projectCustomFields', state),
      );
      const partyCustomFields = R.omit(
        [customField._id],
        R.prop('partyCustomFields', state),
      );

      return {
        ...state,
        projectCustomFields,
        partyCustomFields,
      };
    }
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default CustomFieldsReducer;
