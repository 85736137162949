const FIREBASE_ERRORS = {
  'auth/invalid-email': 'Email is not valid',
  'auth/user-disabled': 'User is disabled',
  'auth/user-not-found': 'Email or password is invalid',
  'auth/wrong-password': 'Email or password is invalid',
  'auth/popup-blocked':
    'Popup has been blocked by the browser, please authorize it',
  'auth/popup-closed-by-user': 'The popup has been closed',
};

export { FIREBASE_ERRORS };
