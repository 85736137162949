import { all, put, takeLatest } from 'redux-saga/effects';

import { SET_AUTHENTICATED_APP_READY } from '@modules/app/actions';

import { fetchTemplates } from '../actions';

function* fetchTemplateSaga() {
  yield put(fetchTemplates());
}

function* requirementSagas() {
  yield all([takeLatest(SET_AUTHENTICATED_APP_READY, fetchTemplateSaga)]);
}

export default requirementSagas;
