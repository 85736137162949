import { ReportType as ViewsTypeGql } from '@graphql/types/graphql';
import { PRIMARY_RECORD_STATUS } from '@modules/primary-records';

export const DEFAULT_VIEWS = {
  documents: {
    _id: `default_${ViewsTypeGql.Documents}`,
    name: 'Active Documents',
    isPrivate: false,
    type: ViewsTypeGql.Documents,
    filters: {
      status: ['nonArchived'],
      sortField: 'createdAt',
      sortDirection: 'desc',
    },
  },
  primaryObject: (primaryObjectName: string) => ({
    _id: `default_${ViewsTypeGql.Parties}`,
    name: `Active ${primaryObjectName}`,
    isPrivate: false,
    type: ViewsTypeGql.Parties,
    filters: {
      status: PRIMARY_RECORD_STATUS.Active,
      sortField: 'createdAt',
      sortDirection: 'desc',
    },
  }),
};
