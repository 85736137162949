import Preloader from '@common/components/Preloader';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import type { ReactElement } from 'react';
import { useOrganization } from '../hooks/useOrganization';

type RequestLandingOrganizationFeatureProviderProps = {
  children: ReactElement<any, any>;
  requestId: string;
};

export const RequestLandingOrganizationFeatureProvider = ({
  children,
  requestId,
}: RequestLandingOrganizationFeatureProviderProps) => {
  const { organization, loading } = useOrganization(requestId);

  if (loading) return <Preloader />;

  return (
    <FeatureToggles features={organization?.features || []}>
      {children}
    </FeatureToggles>
  );
};
