const DotsIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 4 18" fill="currentColor">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Parties/Table-Row"
        transform="translate(-1246.000000, -19.000000)"
        fillRule="nonzero"
      >
        <g id="Dots" transform="translate(1239.000000, 19.000000)">
          <path
            d="M9.0009,7.02 C8.29319258,7.01999999 7.63924396,7.39755739 7.28539024,8.01044999 C6.93153653,8.62334259 6.93153653,9.37845741 7.28539024,9.99135001 C7.63924396,10.6042426 8.29319258,10.9818 9.0009,10.9818 C10.0949209,10.9815515 10.9815985,10.0944709 10.98135,9.00045 C10.9811015,7.90642914 10.0940209,7.01975147 9,7.02 L9.0009,7.02 Z M9.0009,4.68 C9.70828587,4.67967846 10.3617658,4.30199548 10.7151803,3.68922058 C11.0685948,3.07644568 11.0682517,2.32167394 10.7142803,1.70922058 C10.3603089,1.09676721 9.70648587,0.719678461 8.9991,0.72 C8.29171413,0.720321539 7.63823417,1.09800452 7.2848197,1.71077942 C6.93140523,2.32355432 6.93174831,3.07832606 7.2857197,3.69077942 C7.6396911,4.30323279 8.29351413,4.68032154 9.0009,4.68 L9.0009,4.68 Z M9.0009,13.32 C7.90687914,13.32 7.02,14.2068791 7.02,15.3009 C7.02,16.3949209 7.90687914,17.2818 9.0009,17.2818 C10.0949209,17.2818 10.9818,16.3949209 10.9818,15.3009 C10.9818,14.2068791 10.0949209,13.32 9.0009,13.32 Z"
            id="Shape"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DotsIcon;
