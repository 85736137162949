import { message as notify } from 'antd';
import { call, cancel, putResolve, takeLatest } from 'redux-saga/effects';

import {
  PROCESS_DOCUMENT,
  PROCESS_DOCUMENT_URL,
} from '@modules/document/actions';
import { CREATE_REQUEST } from '@modules/request/actions';
import { getGraphqlPayload } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import {
  CREATE_EVENT,
  FETCH_USER_EVENT_LOG,
  GET_CSV_DATA,
  fetchEventLogs,
} from '../actions';

function* fetchEventLogByAction(action) {
  const partyData = getGraphqlPayload(action);

  if (!partyData || !~window.location.pathname.indexOf(partyData._id)) {
    yield cancel();
  }

  yield putResolve(
    fetchEventLogs({
      contextEntityType: {
        $in: ['request', 'party', 'document', 'data', 'requirement', 'subject'],
      },
      partyId: partyData?._id,
      skip: 0,
      limit: 10,
    }),
  );
}

function* eventLogSuccessExportSaga(payload) {
  yield call(trackEvent, 'User downloaded an activity log');
}

function* eventLogSuccessNotificationSaga({
  payload: {
    data: {
      createEvent: { type },
    },
  },
}) {
  switch (type) {
    case 'comment:created': {
      yield notify.success('Comment successfully added');
      break;
    }
    default:
      break;
  }
}

function* userEventLog({ payload: { userId, skip = 0, limit } }) {
  yield putResolve(
    fetchEventLogs({
      actorType: 'user',
      actorId: userId,
      skip,
      limit,
    }),
  );
}

function* eventLogsSaga() {
  yield takeLatest(
    [
      `${PROCESS_DOCUMENT}_SUCCESS`,
      `${PROCESS_DOCUMENT_URL}_SUCCESS`,
      `${CREATE_REQUEST}_SUCCESS`,
    ],
    fetchEventLogByAction,
  );
  yield takeLatest(`${CREATE_EVENT}_SUCCESS`, eventLogSuccessNotificationSaga);
  yield takeLatest(FETCH_USER_EVENT_LOG, userEventLog);
  yield takeLatest(`${GET_CSV_DATA}_SUCCESS`, eventLogSuccessExportSaga);
}

export default eventLogsSaga;
