import {
  CheckCircleFilled,
  FilePdfOutlined,
  HighlightOutlined,
  MailOutlined,
  MessageOutlined,
  PlusOutlined,
  SendOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { NOTIFICATION_BULK_SEND_REQUESTS_MESSAGE } from '../constants';
import { NotificationType } from '../types';

import NotificationItem from './NotificationItem';

const {
  PartyCreated,
  DocumentUpload,
  ConversationReply,
  MentionInPartyComment,
  EmailBounce,
  Requests,
  MentionInDocumentFlag,
} = NotificationType;

const NotificationSwitcher = ({
  organizationNamespace,
  notification,
  onNotificationStatusChanged,
  onLinkClicked,
}) => {
  const theme = useTheme();

  switch (notification.type) {
    case Requests:
      return (
        <NotificationItem
          BadgeIcon={CheckCircleFilled}
          NotificationIcon={SendOutlined}
          badgeStyles={{ color: theme.colors.limeGreen, fontSize: 'inherit' }}
          notificationId={notification._id}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.readAt)}
          onNotificationStatusChanged={onNotificationStatusChanged}
        >
          <span>{NOTIFICATION_BULK_SEND_REQUESTS_MESSAGE}</span>
        </NotificationItem>
      );
    case PartyCreated:
      return (
        <NotificationItem
          BadgeIcon={PlusOutlined}
          NotificationIcon={UserOutlined}
          badgeStyles={{ bgColor: theme.colors.blue }}
          notificationId={notification._id}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.readAt)}
          onNotificationStatusChanged={onNotificationStatusChanged}
        >
          <p>
            New party{' '}
            {notification.data.partyId ? (
              <Link
                to={`${organizationNamespace}/parties/${notification.data.partyId}/overview`}
                onClick={onLinkClicked}
              >
                {notification.data.partyName}
              </Link>
            ) : (
              <b>{notification.data.partyName}</b>
            )}{' '}
            created by <b>{notification.data.userName}</b>
          </p>
        </NotificationItem>
      );
    case DocumentUpload:
      return (
        <NotificationItem
          BadgeIcon={PlusOutlined}
          NotificationIcon={FilePdfOutlined}
          badgeStyles={{ bgColor: theme.colors.blue }}
          notificationId={notification._id}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.readAt)}
          onNotificationStatusChanged={onNotificationStatusChanged}
        >
          <p>
            A new document was uploaded for party{' '}
            <Link
              to={`${organizationNamespace}/parties/${notification.data.partyId}/overview`}
              onClick={onLinkClicked}
            >
              {notification.data.partyName}
            </Link>
          </p>
        </NotificationItem>
      );
    case MentionInPartyComment:
      return (
        <NotificationItem
          BadgeIcon={PlusOutlined}
          NotificationIcon={HighlightOutlined}
          badgeStyles={{ bgColor: theme.colors.blue }}
          notificationId={notification._id}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.readAt)}
          onNotificationStatusChanged={onNotificationStatusChanged}
        >
          <p>
            <b>{notification.data.commentator}</b> mentioned you in a comment on
            the{' '}
            <Link
              to={`${organizationNamespace}/parties/${notification.data.partyId}/overview`}
              onClick={onLinkClicked}
            >
              {notification.data.partyName}
            </Link>{' '}
            page
          </p>
        </NotificationItem>
      );
    case MentionInDocumentFlag:
      return (
        <NotificationItem
          BadgeIcon={PlusOutlined}
          NotificationIcon={HighlightOutlined}
          badgeStyles={{ bgColor: theme.colors.blue }}
          notificationId={notification._id}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.readAt)}
          onNotificationStatusChanged={onNotificationStatusChanged}
        >
          <p>
            You ware mentioned by <b>{notification.data.mentionedBy}</b> in the
            document{' '}
            <Link
              to={`${organizationNamespace}/parties/every/documents-review/${notification.data.documentId}`}
              onClick={onLinkClicked}
            >
              {notification.data.documentFriendlyName}
            </Link>
          </p>
        </NotificationItem>
      );
    case ConversationReply:
      return (
        <NotificationItem
          BadgeIcon={PlusOutlined}
          NotificationIcon={MessageOutlined}
          badgeStyles={{ bgColor: theme.colors.blue }}
          notificationId={notification._id}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.readAt)}
          onNotificationStatusChanged={onNotificationStatusChanged}
        >
          <p>
            New message received from{' '}
            <Link
              to={`${organizationNamespace}/parties/${notification.data.partyId}/messages/${notification.data.partyConversationId}`}
              onClick={onLinkClicked}
            >
              {notification.data.partyName}
            </Link>{' '}
          </p>
        </NotificationItem>
      );
    case EmailBounce:
      return (
        <NotificationItem
          BadgeIcon={WarningOutlined}
          NotificationIcon={MailOutlined}
          badgeStyles={{ bgColor: theme.colors.yellow }}
          notificationId={notification._id}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.readAt)}
          onNotificationStatusChanged={onNotificationStatusChanged}
        >
          <p>
            <Link
              to={`${organizationNamespace}/parties/${notification.data.partyId}/messages/${notification.data.partyConversationId}`}
              onClick={onLinkClicked}
            >
              Message in{' '}
              {notification.data.messageType === 'request'
                ? 'Document Request'
                : 'Follow-up'}{' '}
            </Link>
            unable to be delivered to {notification.data.messageRecipientEmail}
          </p>
        </NotificationItem>
      );
    default:
      console.warn(
        `This notification type (${notification.type}) is not supported yet`,
      );
      return null;
  }
};

export default NotificationSwitcher;
