import Icon, {
  BoldOutlined,
  ItalicOutlined,
  LinkOutlined,
  SnippetsOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import { Tooltip } from 'antd';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';

import NumberedListIcon from '@common/icons/NumberedListIcon';
import SelectSnippetPopover from '@modules/text-snippets/containers/SelectSnippetPopover';

import InsertLinkModal from './components/InsertLinkModal';
import PlaceholderSuggestionsEntryComponent from './components/PlaceholderSuggestionsEntryComponent';
import TextPlaceholder from './components/TextPlaceholder';
import {
  getEditorHTMLContent,
  getOriginalPositionSuggestions,
  getUpdatedEditorState,
  insertLinkKeyBinding,
} from './helpers';
import { getInsertLinkPlugin } from './plugins/InsertLinkPlugin';
import { getPlaceholderDecoratorPlugin } from './plugins/PlaceholderDecoratorPlugin';

const TextFormat = {
  Bold: 'BOLD',
  Italic: 'ITALIC',
  Underline: 'UNDERLINE',
  Strikethrough: 'STRIKETHROUGH',
  NumberedList: 'ordered-list-item',
  BulletedList: 'unordered-list-item',
};

/**
 * Text editor
 *
 * @param {Object} props
 * @param {boolean} [props.visible=true]
 * @param {boolean} [props.isValid]
 * @param {boolean} [props.focusDependence]
 * @param {boolean} [props.removeBorder=false]
 * @param {boolean} [props.disableInlineStyleButtons]
 * @param {boolean} [props.isOneLineEditor]
 * @param {number} [props.minHeight]
 * @param {number} [props.maxHeight=400]
 * @param {string} [props.placeholder]
 * @param {string} [props.defaultContent]
 * @param {boolean} [props.autofocus]
 * @param {{ id: string; name: string }[]} [props.members]
 * @param {EditorState} [props.editorState]
 * @param {{ name: string; description: string }[]} [props.placeholders]
 * @param {(newState: EditorState, memberIds: string[]) => void} [props.onChange]
 *
 * @returns {JSX.Element}
 *
 */
const RichTextEditor = ({
  visible = true,
  isValid,
  minHeight,
  maxHeight = 400,
  placeholder = '',
  defaultContent,
  focusDependence,
  removeBorder = false,
  disableInlineStyleButtons,
  isOneLineEditor,
  members = [],
  placeholders = [],
  editorState: controlledEditorState,
  autofocus,
  onChange,
}) => {
  const [editorState, setEditorState] = useState(
    controlledEditorState || getUpdatedEditorState(defaultContent),
  );
  const [isFocus, setIsFocus] = useState(false);
  const [insertLinkWasClicked, setInsertLinkWasClicked] = useState(false);
  const [visibleInsertLink, setVisibleInsertLink] = useState(false);
  const [visibleTextSnippetsPopover, setVisibleTextSnippetsPopover] =
    useState(false);
  const [currentEntityKey, setCurrentEntityKey] = useState(null);
  const [currentInsertLinkData, setCurrentInsertLinkData] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isMemberSuggestionsOpened, setIsMemberSuggestionsOpened] =
    useState(false);
  const [isPlaceholderSuggestionsOpened, setIsPlaceholderSuggestionsOpened] =
    useState(false);
  const [placeholderSearchText, setPlaceholderSearchText] = useState('');
  const [suggestedMembers, setSuggestedMembers] = useState(members);

  const { memberSuggestionsPlugin, MemberSuggestions } = useMemo(() => {
    const memberSuggestionsPlugin = createMentionPlugin();
    const { MentionSuggestions } = memberSuggestionsPlugin;
    return {
      memberSuggestionsPlugin,
      MemberSuggestions: MentionSuggestions,
    };
  }, []);

  const { placeholderSuggestionsPlugin, PlaceholderSuggestions } =
    useMemo(() => {
      const placeholderSuggestionsPlugin = createMentionPlugin({
        mentionTrigger: ['{'],
        supportWhitespace: true,
        mentionComponent({ children }) {
          return <TextPlaceholder>{children}</TextPlaceholder>;
        },
        entityMutability: 'IMMUTABLE',
        positionSuggestions: ({ decoratorRect, popover, props }) => {
          const isToShow = props.open && props.suggestions.length > 0;
          const transform = isToShow ? 'scaleY(1)' : 'scaleY(0)';
          const transition = isToShow
            ? 'all 0.25s cubic-bezier(.3,1.2,.2,1)'
            : 'all 0.25s cubic-bezier(.3,1,.2,1)';

          const { top, scrollTop } = getOriginalPositionSuggestions({
            decoratorRect,
            popover,
            props,
          });

          return {
            top: `${top + scrollTop}px`,
            width: '96%',
            left: '2%',
            maxWidth: '100%',
            maxHeight: '180px',
            overflowY: 'scroll',
            transform,
            transition,
            boxShadow: '-2px 2px 8px 0px rgb(220 220 220)',
          };
        },
      });
      const { MentionSuggestions } = placeholderSuggestionsPlugin;
      return {
        placeholderSuggestionsPlugin,
        PlaceholderSuggestions: MentionSuggestions,
      };
    }, []);

  const editorRef = useRef();
  const editorWrapperRef = useRef();
  const urlTextRef = useRef();

  const insertLinkPlugin = getInsertLinkPlugin({
    setCurrentEntityKey,
    setVisibleInsertLink,
    setCurrentInsertLinkData,
    visibleInsertLink,
    urlTextRef,
    setIsEditable,
  });

  const placeholderDecoratorPlugin = getPlaceholderDecoratorPlugin();

  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (editorRef && editorRef.current && focusDependence) {
        editorRef.current.focus();
      }
    });
  }, [focusDependence]);

  useEffect(() => {
    if (autofocus && editorRef && editorRef.current) {
      editorRef.current.focus();
    }
  }, [autofocus]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    if (controlledEditorState) {
      setEditorState(controlledEditorState);
    }
  }, [controlledEditorState, setEditorState]);

  const boxShadow = isValid
    ? !removeBorder
      ? '0 0 0 2px rgba(24, 144, 255, 0.2)'
      : 'none'
    : '0 0 0 2px rgba(245, 34, 45, 0.2)';

  const getBorderColor = () => {
    if (removeBorder) {
      return 'transparent';
    } else if (isValid && !isFocus) {
      return '#d9d9d9';
    } else if (isValid && isFocus) {
      return '#40a9ff';
    } else if (!isValid) {
      return '#f5222d';
    }
  };

  const getMembersIds = (state) => {
    const htmlString = getEditorHTMLContent(state);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const elements = doc.querySelectorAll('[data-entitytype="mention"]');

    const uniqueIds = new Set();
    elements.forEach((el) => {
      uniqueIds.add(el.getAttribute('data-entityid'));
    });

    return Array.from(uniqueIds);
  };

  const onEditorChange = (newState) => {
    const membersIds = getMembersIds(newState);
    setEditorState(newState);
    onChange?.(newState, membersIds);
  };

  const onToggle = (e, key) => {
    e.preventDefault();
    onEditorChange(RichUtils.toggleInlineStyle(editorState, key));
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (command === 'open-insert-link-modal') {
      setVisibleInsertLink(true);
    }
    if (newState) {
      onEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  const chooseSnippet = useCallback(
    (snippet) => {
      const contentState = editorState.getCurrentContent();
      const blocksFromHtml = stateFromHTML(
        R.propOr('', 'content', snippet),
      ).getBlockMap();

      const contentToUpdate = Modifier.replaceWithFragment(
        contentState,
        selection,
        blocksFromHtml,
      );

      setEditorState(
        EditorState.push(editorState, contentToUpdate, 'text-snippets'),
      );
      // This focuses/updates the editor state after the user selects a snippet
      window.requestAnimationFrame(() => {
        if (editorRef && editorRef.current) {
          editorRef.current.focus();
        }
      });
      setVisibleTextSnippetsPopover(false);
    },
    [editorState, selection, setEditorState],
  );

  const handleOnMemberSuggestionsOpenChange = (open) => {
    setIsMemberSuggestionsOpened(open);
  };

  const handleOnPlaceholderSuggestionsOpenChange = (open) => {
    setIsPlaceholderSuggestionsOpened(open);
  };

  useEffect(() => {
    if (insertLinkWasClicked && !visibleInsertLink) {
      setCurrentEntityKey(null);
      setCurrentInsertLinkData({});
      window.requestAnimationFrame(() => {
        if (editorRef && editorRef.current) {
          editorRef.current.focus();
        }
      });
    }
  }, [insertLinkWasClicked, visibleInsertLink]);

  const onMemberSearchChange = useCallback(
    ({ value }) => {
      setSuggestedMembers(defaultSuggestionsFilter(value, members));
    },
    [members],
  );

  return (
    <RichTextEditor.Wrapper
      ref={editorWrapperRef}
      $borderColor={getBorderColor()}
      $boxShadow={isFocus ? boxShadow : 'none'}
      $visible={visible}
    >
      <InsertLinkModal
        visible={visibleInsertLink}
        setVisible={setVisibleInsertLink}
        editorWrapperRef={editorWrapperRef}
        editorState={editorState}
        setEditorState={onEditorChange}
        currentEntityKey={currentEntityKey}
        currentInsertLinkData={currentInsertLinkData}
        urlTextRef={urlTextRef}
        isEditable={isEditable}
      />
      <RichTextEditor.EditorWrapper
        data-cy="richTextEditor"
        $minHeight={minHeight}
        $maxHeight={maxHeight}
        $isOneLineEditor={isOneLineEditor}
      >
        <Editor
          ref={editorRef}
          spellCheck={true}
          editorState={editorState}
          keyBindingFn={insertLinkKeyBinding}
          handleKeyCommand={handleKeyCommand}
          plugins={[
            placeholderDecoratorPlugin,
            placeholderSuggestionsPlugin,
            memberSuggestionsPlugin,
            insertLinkPlugin,
          ]}
          onChange={onEditorChange}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          textAlignment="left"
          placeholder={
            RichUtils.getCurrentBlockType(editorState) === 'unstyled'
              ? placeholder
              : ''
          }
        />
        <MemberSuggestions
          open={isMemberSuggestionsOpened}
          onSearchChange={onMemberSearchChange}
          suggestions={suggestedMembers}
          onOpenChange={handleOnMemberSuggestionsOpenChange}
        />
        <PlaceholderSuggestions
          open={isPlaceholderSuggestionsOpened}
          onSearchChange={({ value }) => setPlaceholderSearchText(value)}
          suggestions={placeholders.filter(
            (placeholder) =>
              placeholder?.name
                ?.toLowerCase()
                ?.includes(placeholderSearchText.toLowerCase()) ||
              placeholder?.description
                ?.toLowerCase()
                ?.includes(placeholderSearchText.toLowerCase()),
          )}
          onOpenChange={handleOnPlaceholderSuggestionsOpenChange}
          entryComponent={PlaceholderSuggestionsEntryComponent}
        />
      </RichTextEditor.EditorWrapper>
      {!disableInlineStyleButtons ? (
        <div>
          <RichTextEditor.InlineStyleButtons>
            <Tooltip title="Bold">
              <BoldOutlined
                onMouseDown={(e) => onToggle(e, TextFormat.Bold)}
                className={`editor-btn ${
                  currentStyle.has(TextFormat.Bold) ? 'editor-btn-active' : ''
                }`}
              />
            </Tooltip>
            <Tooltip title="Italic">
              <ItalicOutlined
                onMouseDown={(e) => onToggle(e, TextFormat.Italic)}
                className={`editor-btn ${
                  currentStyle.has(TextFormat.Italic) ? 'editor-btn-active' : ''
                }`}
              />
            </Tooltip>
            <Tooltip title="Underline">
              <UnderlineOutlined
                onMouseDown={(e) => onToggle(e, TextFormat.Underline)}
                className={`editor-btn ${
                  currentStyle.has(TextFormat.Underline)
                    ? 'editor-btn-active'
                    : ''
                }`}
              />
            </Tooltip>
            <Tooltip title="Strikethrough">
              <StrikethroughOutlined
                onMouseDown={(e) => onToggle(e, TextFormat.Strikethrough)}
                className={`editor-btn ${
                  currentStyle.has(TextFormat.Strikethrough)
                    ? 'editor-btn-active'
                    : ''
                }`}
              />
            </Tooltip>
            <Tooltip title="Numbered List">
              <Icon
                component={NumberedListIcon}
                onMouseDown={(e) => {
                  e.preventDefault();
                  onEditorChange(
                    RichUtils.toggleBlockType(
                      editorState,
                      TextFormat.NumberedList,
                    ),
                  );
                }}
                className={`editor-btn ${
                  blockType === TextFormat.NumberedList
                    ? 'editor-btn-active'
                    : ''
                }`}
              />
            </Tooltip>
            <Tooltip title="Bulleted List">
              <UnorderedListOutlined
                onMouseDown={(e) => {
                  e.preventDefault();
                  onEditorChange(
                    RichUtils.toggleBlockType(
                      editorState,
                      TextFormat.BulletedList,
                    ),
                  );
                }}
                className={`editor-btn ${
                  blockType === TextFormat.BulletedList
                    ? 'editor-btn-active'
                    : ''
                }`}
              />
            </Tooltip>
            <Tooltip title="Insert link">
              <LinkOutlined
                onMouseDown={async (e) => {
                  if (editorRef && editorRef.current) {
                    await editorRef.current.focus();
                    setInsertLinkWasClicked(true);
                    setVisibleInsertLink(true);
                  }
                  e.persist();
                }}
                className={`editor-btn ${
                  blockType === visibleInsertLink ? 'editor-btn-active' : ''
                }`}
              />
            </Tooltip>
            <RichTextEditor.VerticalSeparator />
            <SelectSnippetPopover
              visible={visibleTextSnippetsPopover}
              setVisible={setVisibleTextSnippetsPopover}
              chooseSnippet={chooseSnippet}
            >
              <SnippetsOutlined className="editor-btn" />
            </SelectSnippetPopover>
          </RichTextEditor.InlineStyleButtons>
        </div>
      ) : null}
    </RichTextEditor.Wrapper>
  );
};

RichTextEditor.Wrapper = styled.div`
  border-radius: 2px;
  border: 1px solid ${(props) => props.$borderColor};
  box-shadow: ${(props) => props.$boxShadow};
  transition: all 0.3s;
  background-color: white;
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};

  ul,
  ol {
    margin: 0;
  }

  .editor-btn {
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .editor-btn-active {
    color: ${(props) => props.theme.colors.blue};

    &:hover {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;

RichTextEditor.Header = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightBlue};
`;

RichTextEditor.InlineStyleButtons = styled.div`
  padding: 6px 11px 11px;
  display: flex;
  align-items: center;
`;

/**
 * @note the "min-height" property has to affect the editable element ".public-DraftEditor-content"
 * if it's placed in "DraftEditor-root" or in "EditorWrapper" the editable element will keep the default height
 * and it could produce errors durign e2e tests.
 */
RichTextEditor.EditorWrapper = styled.div`
  max-height: ${(props) => props.$maxHeight}px;
  overflow: auto;

  .DraftEditor-root {
    padding: 6px 11px;
  }

  .public-DraftEditor-content {
    min-height: ${(props) => props.$minHeight}px;
    width: 100%;
    line-height: 20px;
  }

  .public-DraftEditorPlaceholder-inner {
    margin-left: 11px;
    color: #bfbfbf;
  }

  ${({ $isOneLineEditor }) =>
    $isOneLineEditor &&
    css`
      .public-DraftStyleDefault-block {
        white-space: pre;
        overflow: hidden;
      }
    `}
`;

RichTextEditor.VerticalSeparator = styled.div`
  height: 15px;
  width: 1px;
  margin-right: 12px;
  background-color: rgba(0, 0, 0, 0.4);
`;

export default RichTextEditor;
