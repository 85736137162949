import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const INTEGRATION_STATUS = `${namespace}/INTEGRATION_STATUS`;

const INTEGRATION_STATUS_QUERY = gql`
  query integrationStatus($data: IntegrationStatusInput!) {
    integrationStatus(data: $data) {
      provider
      instance
      status {
        inProgress
        meta {
          lastSyncedAt
          parties {
            total
            unlinked
          }
          projects {
            total
            unlinked
          }
        }
        settings {
          autoSyncProjects
          autoSyncParties
        }
        projects {
          externalId
          displayId
          status
          externalName
          name
          id
          lastSyncedAt
        }
        parties {
          externalId
          displayId
          status
          externalName
          name
          id
          lastSyncedAt
        }
      }
    }
  }
`;

export const fetchIntegrationStatus = (data) => ({
  type: INTEGRATION_STATUS,
  payload: {
    key: 'integrationStatus',
    graphql: {
      fetchPolicy: 'no-cache',
      query: INTEGRATION_STATUS_QUERY,
      variables: { data },
    },
  },
});
