import * as R from 'ramda';

const getEntityByPathname = R.cond([
  [R.test(/parties\/.*\/documents\/?/), R.always('party-documents')],
  [R.test(/parties\/.*\/projects\/?/), R.always('party-projects')],
  [R.test(/\/parties\/?$/), R.always('parties')],
  [R.test(/\/documents\/?$/), R.always('documents')],
  [R.test(/\/projects\/?$/), R.always('projects')],
]);

export default getEntityByPathname;
