const SERVER_SORT = {
  asc: 1,
  desc: -1,
};

type TableSort = { colId: string; sort: 'asc' | 'desc' };

export const mapTableSortToServerSort = <TableSorts = TableSort[]>(
  tableSort: TableSorts,
) => {
  const activeSort =
    Array.isArray(tableSort) && tableSort.length > 0
      ? (tableSort[0] as TableSort)
      : ({} as TableSort);

  return {
    [activeSort.colId]: SERVER_SORT[activeSort.sort],
  };
};
