import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ENABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION = graphql(`
  mutation EnablePrimaryRecordsAutomation($data: PrimaryRecordsAutomationInput!) {
    enablePrimaryRecordsAutomation(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useEnableAutomationsMutation = () => {
  const [enablePrimaryRecordsAutomation, { loading: isEnablingAutomations }] =
    useMutation(ENABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION);

  const enableAutomations = (
    automationId: string,
    filters?: ServerFilterInput,
  ) => {
    return enablePrimaryRecordsAutomation({
      variables: {
        data: {
          automationId,
          filter: filters,
        },
      },
    });
  };

  return {
    enableAutomations,
    isEnablingAutomations,
  };
};
