import * as R from 'ramda';

import { UPDATE_USER } from '@modules/user/actions';
import { getGraphqlPayload } from '@store/helpers';

import { AUTH_CONSUME, GET_ORGANIZATION_INFO, LOGIN, LOGOUT } from '../actions';

export const initialState = {
  _id: null,
  isAuthenticated: false,
  isActive: false,
  email: null,
};

const buildAuthData = (user) => ({
  ...R.compose(
    R.pick(['_id', 'isActive', 'email']),
    R.pathOr({}, ['user']),
  )(user),
  isAuthenticated: true,
});

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_SUCCESS`:
    case `${AUTH_CONSUME}_SUCCESS`: {
      const user = getGraphqlPayload(action);
      const authData = buildAuthData(user);
      return R.mergeDeepRight(state, authData);
    }
    case `${UPDATE_USER}_SUCCESS`: {
      const user = getGraphqlPayload(action);
      return R.mergeDeepRight(
        state,
        R.pick(['_id', 'isActive', 'email'], user),
      );
    }
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    case `${GET_ORGANIZATION_INFO}_SUCCESS`: {
      const organization = getGraphqlPayload(action) || {};
      return R.merge(state, { organization });
    }
    default: {
      return state;
    }
  }
};

export default AuthReducer;
