import {
  type CustomFilterProps,
  CustomFilterWrapper,
} from '@common/components/Table';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { FullWidthSelect } from '@trustlayer/ui';

type Type = {
  _id: string;
  name: string;
};

const DOCUMENT_TYPES_QUERY = graphql(`
  query TypeCustomFilterDocumentTypes {
    listDocumentTypes {
      _id
      name
    }
  }
`);

const mapToOptions = (items: Type[]) =>
  items.map((item) => ({
    value: item._id,
    label: item.name,
  })) || [];

export const TypeCustomFilter = ({
  model,
  onModelChange,
}: CustomFilterProps) => {
  const { data, loading } = useQuery(DOCUMENT_TYPES_QUERY);

  return (
    <CustomFilterWrapper>
      <FullWidthSelect
        loading={loading}
        value={model || []}
        mode="multiple"
        onChange={(value) => {
          const isValueEmpty = Array.isArray(value) && value.length === 0;
          onModelChange(isValueEmpty ? undefined : value);
        }}
        placeholder="Search type..."
        options={mapToOptions(data?.listDocumentTypes || [])}
      />
    </CustomFilterWrapper>
  );
};
