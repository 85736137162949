import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const GET_NEW_MESSAGES_COUNT = `${namespace}/GET_NEW_MESSAGES_COUNT`;

const GET_NEW_MESSAGES_COUNT_QUERY = gql`
  query getNewMessagesCount($partyId: String!) {
    getNewMessagesCount(partyId: $partyId)
  }
`;

export const getNewMessagesCount = (partyId) => ({
  type: GET_NEW_MESSAGES_COUNT,
  payload: {
    key: 'getNewMessagesCount',
    graphql: {
      mutation: GET_NEW_MESSAGES_COUNT_QUERY,
      variables: {
        partyId,
      },
    },
  },
});
