import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const SEARCH = `${namespace}/SEARCH`;

const SEARCH_QUERY = gql`
  query search($data: SearchInput!) {
    search(data: $data)
  }
`;

export const search = (data) => ({
  type: SEARCH,
  payload: {
    key: 'search',
    graphql: {
      fetchPolicy: 'no-cache',
      query: SEARCH_QUERY,
      variables: { data },
    },
  },
});
