import { useApolloClient } from '@apollo/client';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const FETCH_FIRST_QUERY = graphql(`
  query GetFirstOrganization($input: ListOrganizationsInput!) {
    listOrganizations(input: $input) {
      nodes {
        id
        name
        slugifyName
        type
        website
        logo
        icon
        primaryContactName
        primaryContactEmail
        primaryContactPhone
        primaryContactTimezone
        billingManager
        address {
          line1
          line2
          city
          region
          country
          postalCode
          latitude
          longitude
        }
        requestTokenTTL
        defaultRequestMessage
        suspendedAt
        suspendedReason
        features
        credentials
        salesforceId
        settings {
          dashboardType
        }
        createdAt
        updatedAt
      }
    }
  }
`);

export const useFirstOrganizationQuery = () => {
  const client = useApolloClient();

  const fetchFirstOrganization = useCallback(
    async (slug?: string) => {
      const res = await client.query({
        query: FETCH_FIRST_QUERY,
        variables: {
          input: {
            first: 1,
            offset: 0,
            ...(slug && {
              filter: {
                and: [
                  {
                    name: 'slugifyName',
                    operator: FILTER_OPERATORS.equal,
                    value: slug,
                  },
                ],
              },
            }),
          },
        },
      });
      const organization = res.data?.listOrganizations?.nodes?.[0];
      if (!organization) {
        throw new Error('Organization not found');
      }
      return organization;
    },
    [client],
  );

  return {
    fetchFirstOrganization,
  };
};
