import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DISABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION = graphql(`
  mutation DisablePrimaryRecordsAutomation($data: PrimaryRecordsAutomationInput!) {
    disablePrimaryRecordsAutomation(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDisableAutomationsMutation = () => {
  const [disablePrimaryRecordsAutomation, { loading: isDisablingAutomations }] =
    useMutation(DISABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION);

  const disableAutomations = (
    automationId: string,
    filters?: ServerFilterInput,
  ) => {
    return disablePrimaryRecordsAutomation({
      variables: {
        data: {
          automationId,
          filter: filters,
        },
      },
    });
  };

  return {
    disableAutomations,
    isDisablingAutomations,
  };
};
