import { graphql } from '@graphql/types';

export const DELETE_VIEW_MUTATION = graphql(`
  mutation deleteView($id: String!) {
    deleteReport(id: $id) {
      _id
      name
      type
      filters
      organization
      user
      schedules {
        _id
        name
        recipients
        format
        frequency
        frequencyOptions
        message
        columns {
          value
          name
        }
        showOrganizationComplianceRow
        lastSendingDate
        lastSendingType
      }
      isPrivate
      readonly
      createdAt
      updatedAt
    }
  }
`);
