import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { WaiverOverrideRequestRecordSubjectMutation } from '@graphql/types/graphql';

export const WAIVE_OVERRIDE_REQUEST_RECORD_SUBJECT_MUTATION = graphql(`
  mutation WaiverOverrideRequestRecordSubject($data: ToggleRequestRecordSubjectsWaiverInput!) {
    toggleRequestRecordSubjectsWaiver(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

type WaiveOverrideSubjectVariables = {
  variables: {
    requestId: string;
    subject: Record<string, any>;
  };
  onCompleted?: (res: WaiverOverrideRequestRecordSubjectMutation) => void;
  onError?: (error: Error) => void;
};

export function useWaiveOverrideSubjectMutation() {
  const [toggleWaiveOverrideSubject, { loading }] = useMutation(
    WAIVE_OVERRIDE_REQUEST_RECORD_SUBJECT_MUTATION,
  );

  function waiveOverrideSubject({
    variables: { requestId, subject },
    onCompleted,
    onError,
  }: WaiveOverrideSubjectVariables) {
    return toggleWaiveOverrideSubject({
      variables: {
        data: {
          id: requestId!,
          subjects: [subject],
        },
      },
      onCompleted,
      onError,
    });
  }

  return {
    waiveOverrideSubject,
    loadingWaiveOverrideSubject: loading,
  };
}
