/**
 * @note This is the option types list from the table number filter supported also by the server.
 * here the full table filter type NumberFilter['type']
 */

export const NUMBER_FILTER_OPTION_TYPES = {
  equals: 'equals',
  notEqual: 'notEqual',
  contains: 'contains',
  notContains: 'notContains',
  greaterThan: 'greaterThan',
  greaterThanOrEqual: 'greaterThanOrEqual',
  lessThan: 'lessThan',
  lessThanOrEqual: 'lessThanOrEqual',
  inRange: 'inRange',
} as const;

export const AVAILABLE_NUMBER_FILTER_OPTION_TYPES = [
  NUMBER_FILTER_OPTION_TYPES.equals,
  NUMBER_FILTER_OPTION_TYPES.notEqual,
  NUMBER_FILTER_OPTION_TYPES.contains,
  NUMBER_FILTER_OPTION_TYPES.notContains,
  NUMBER_FILTER_OPTION_TYPES.greaterThan,
  NUMBER_FILTER_OPTION_TYPES.greaterThanOrEqual,
  NUMBER_FILTER_OPTION_TYPES.lessThan,
  NUMBER_FILTER_OPTION_TYPES.lessThanOrEqual,
  NUMBER_FILTER_OPTION_TYPES.inRange,
] as const;

/**
 * This is a collection of custom class names to use in columnDefs with cellClass.
 * The classes are defined in the StyledTable in this file.
 *
 */

export const COMMON_CELL_CLASSES = {
  noOverflow: 'cell-no-overflow',
  alignCenter: 'alignCenter',
};
