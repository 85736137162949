const CERTUM_TRUSTED_NETWORK_CA = `-----BEGIN CERTIFICATE-----
MIIDuzCCAqOgAwIBAgIDBETAMA0GCSqGSIb3DQEBBQUAMH4xCzAJBgNVBAYTAlBM
MSIwIAYDVQQKExlVbml6ZXRvIFRlY2hub2xvZ2llcyBTLkEuMScwJQYDVQQLEx5D
ZXJ0dW0gQ2VydGlmaWNhdGlvbiBBdXRob3JpdHkxIjAgBgNVBAMTGUNlcnR1bSBU
cnVzdGVkIE5ldHdvcmsgQ0EwHhcNMDgxMDIyMTIwNzM3WhcNMjkxMjMxMTIwNzM3
WjB+MQswCQYDVQQGEwJQTDEiMCAGA1UEChMZVW5pemV0byBUZWNobm9sb2dpZXMg
Uy5BLjEnMCUGA1UECxMeQ2VydHVtIENlcnRpZmljYXRpb24gQXV0aG9yaXR5MSIw
IAYDVQQDExlDZXJ0dW0gVHJ1c3RlZCBOZXR3b3JrIENBMIIBIjANBgkqhkiG9w0B
AQEFAAOCAQ8AMIIBCgKCAQEA4/t9o3K6wvDJFIf1awFO4W5AB7ptJ11/91sts1rH
UV+rpDKmYYe2bg+G0jACl/jXaVehGDldamR5xgFZrDwxSjh80gTSSyjoIF87B6LM
TXPb865Px1bVWqeWifrzq2jUI4ZZJ88JJ7ysbnKDHDBy3+Ci6dLhdHUZvSqeexVU
BBvXQzmtVSjF4hq79MDkrjhJM8x2hZ85RdKknvISjFH4fOQtf/WsX+sWn7Et0brM
kUJ3TCXJkDhv2/DM+44el1k+1WBO5gUo7Ul5E0u6SNsv+XLTOcr+H9g0cvW0QM8x
AcPs3hEtF10fuFDRXhmnad4HMyjKUJX5p1TLVIZQRan5SQIDAQABo0IwQDAPBgNV
HRMBAf8EBTADAQH/MB0GA1UdDgQWBBQIds3LB/8k9sXN7buQvOKEN0Z19zAOBgNV
HQ8BAf8EBAMCAQYwDQYJKoZIhvcNAQEFBQADggEBAKaorSLOAT2mo/9i0Eidi15y
sHhE49wcrwn9I0j6vSrEuVUEtRCjjSfeC4Jj0O7eDDd5QVsisrCaQVymcODU0HfL
I9MA4GxWL+FpDQ3Zqr8hgVDZBqWo/5U30Kr+4rP1mS1FhIrlQgnXdAIv94nYmem8
J9RHjboNRhx3zxSkHLmkMcScKHQDNP8zGSal6Q10tz6XxnboJ5ajZt3hrvJBW8qY
VoNzcOSGGtIxQbovvi0TWnZvTuhOgQ4/WwMioBK+ZlgRSssDxLQqKi2WF+A5VLxI
03YnnZotBqbJ7DnSq9ufmgsnAjUpsUCV5/nonFWIGUbWtzT1fs45mtk48VH3Tyw=
-----END CERTIFICATE-----`;
const SSL_COM_CLIENT_CERTIFICATE_INTERMEDIATE_CA_RSA_R2 = `-----BEGIN CERTIFICATE-----
MIIG7zCCBNegAwIBAgIQB5/ciUBIivHZb9J0CmRVZjANBgkqhkiG9w0BAQsFADB8
MQswCQYDVQQGEwJVUzEOMAwGA1UECAwFVGV4YXMxEDAOBgNVBAcMB0hvdXN0b24x
GDAWBgNVBAoMD1NTTCBDb3Jwb3JhdGlvbjExMC8GA1UEAwwoU1NMLmNvbSBSb290
IENlcnRpZmljYXRpb24gQXV0aG9yaXR5IFJTQTAeFw0xOTAzMjYxNzQxMDZaFw0z
NDAzMjIxNzQxMDZaMH4xCzAJBgNVBAYTAlVTMQ4wDAYDVQQIDAVUZXhhczEQMA4G
A1UEBwwHSG91c3RvbjERMA8GA1UECgwIU1NMIENvcnAxOjA4BgNVBAMMMVNTTC5j
b20gQ2xpZW50IENlcnRpZmljYXRlIEludGVybWVkaWF0ZSBDQSBSU0EgUjIwggIi
MA0GCSqGSIb3DQEBAQUAA4ICDwAwggIKAoICAQDmQ+3UxwVE9dAx75DUrLZwgASW
LLr/ID8bbGCfpcrSHIRsrR4ut5n49JGViu5DYE6addkpajbiMA2Jaw1Ap4RncDjZ
+0fzSWbqGKEE+vNPVLoKy7OVIrxf/9HzGUT6YaELSNrGTR0cYNcR+W5bE3JTxTMQ
iLMAwBbMXH4qKXQUT+oyIXD11CIMUtM8ECoo2o7qdpw1zaZWwVvhXy9mkAaRgrkw
2NpddZUVbJKF/spsJa3lNVdSi3wcJpDDQAl6jxtBF/3ctkY1OjBQz32yRlArFyms
Pc+we9ffHAgvfqbHVfXvgWG8urVith8/6MjmojHMCKqFoJueLbtTPoN8QhvVh49u
oRYYAUUH0HOAYCOzGBGrdJvMIYZqQsX90XlU7Qxp1En7vMkQswkQTvGmBPWrK/Ew
SAJc15BZm+i8QBxPqVKFORfLETLEC4ZrwomtW/oPxBP8zXPvQ0K1dQzAkw+JXxKv
/KiwDryFFhU5xMMB3yKxO5NRYXlnqW9nwfhdBTJScthzAtGO9KZQ2GPmq0NMVMuX
e1XdCOmnPxOptKkMldBItkaYgrkTzqP1nzIAhVfU4sNnHIxKPftwrZ9VMSc5Wkz8
8bOtAJyz3KQRY0qcAtR4LaeRkiZaEmprQA8EOpdJxtv03pBZtaUnnTY6DsEwGQ0+
P2mmB5IHB74SknyNswIDAQABo4IBaTCCAWUwEgYDVR0TAQH/BAgwBgEB/wIBADAf
BgNVHSMEGDAWgBTdBAkHovV6fVJTEpKV7jiAJQ2mWTCBgwYIKwYBBQUHAQEEdzB1
MFEGCCsGAQUFBzAChkVodHRwOi8vd3d3LnNzbC5jb20vcmVwb3NpdG9yeS9TU0xj
b21Sb290Q2VydGlmaWNhdGlvbkF1dGhvcml0eVJTQS5jcnQwIAYIKwYBBQUHMAGG
FGh0dHA6Ly9vY3Nwcy5zc2wuY29tMBEGA1UdIAQKMAgwBgYEVR0gADApBgNVHSUE
IjAgBggrBgEFBQcDAgYIKwYBBQUHAwQGCisGAQQBgjcKAwwwOwYDVR0fBDQwMjAw
oC6gLIYqaHR0cDovL2NybHMuc3NsLmNvbS9zc2wuY29tLXJzYS1Sb290Q0EuY3Js
MB0GA1UdDgQWBBRmj6a8t5Msnfm6SeVP3OqQUBFqnTAOBgNVHQ8BAf8EBAMCAYYw
DQYJKoZIhvcNAQELBQADggIBAMJr11ncGIPKbaZxuuU2P1TGyXF+gy+xH2TBNWNl
iJVL613nH1J7L2WcJQzqXYl77rKTzGeQexnKeYZ13MFwuE80vISif/gwK569WLoy
CvNVvGEZ2bZ+JL5K49mVhrv1gqO+MgMvc8iEENl1xoWRpJGD4EClk8t4u7NUCgBv
hYORiyzHCZcILHcEMvfEwmmFshMN6TqcAJdRjFT0Ru0hJcs5d7EFdM9dCa5ckXWr
KK49cSNq4qOaxqpG99EfDw6U2c70YcJ1/IhC1wL6z8qlGvhYQ0vJvqGJqW/DdeuW
cMmrB+qZL9WbORQ1nvlNggB6smEk0pXXYBr8HYjxT67XwtBBmkBXFpa7G6y4P0BO
3kxWGBfvRBJHfyaiwREgVWa36V/WjXtPmV8VHcv04Rqgk64E4OlSUxgi9k9VC6ki
vTXJN+Gg2uJJBQdf+ptVhJqkkrtB0gABF+kQP0xsagKkrS3NVrVKo6peWMx0h7l5
2bGqT8ucu4Qe200KQi2xp/r8jpP60EE9U4M8D1grH3Kh9OxVOL4wykdoC/yGJNLK
Il0BfsCVWB/GeSq5hxe/84K51OEJqpjDnOMrkRevfVzqGBFFAeg7Kg7uSysVR05w
R+ltp3ytaIbjGJtKad8raIbM1qiNFErG7YB7v4baI3BP1s/rTDtPLototahwHP7I
qOHO
-----END CERTIFICATE-----`;
const SSL_COM_ROOT_CERTIFICATION_AUTHORITY_RSA = `-----BEGIN CERTIFICATE-----
MIIF3TCCA8WgAwIBAgIIeyyb0xaAMpkwDQYJKoZIhvcNAQELBQAwfDELMAkGA1UE
BhMCVVMxDjAMBgNVBAgMBVRleGFzMRAwDgYDVQQHDAdIb3VzdG9uMRgwFgYDVQQK
DA9TU0wgQ29ycG9yYXRpb24xMTAvBgNVBAMMKFNTTC5jb20gUm9vdCBDZXJ0aWZp
Y2F0aW9uIEF1dGhvcml0eSBSU0EwHhcNMTYwMjEyMTczOTM5WhcNNDEwMjEyMTcz
OTM5WjB8MQswCQYDVQQGEwJVUzEOMAwGA1UECAwFVGV4YXMxEDAOBgNVBAcMB0hv
dXN0b24xGDAWBgNVBAoMD1NTTCBDb3Jwb3JhdGlvbjExMC8GA1UEAwwoU1NMLmNv
bSBSb290IENlcnRpZmljYXRpb24gQXV0aG9yaXR5IFJTQTCCAiIwDQYJKoZIhvcN
AQEBBQADggIPADCCAgoCggIBAPkP3aMrfcvQKv7sZ4Wm5y4bunfh4/WvpOz6Sl2R
xFdHaxh3a3by/ZPkPQ/CFp4LZsNWlJ4Xg4XOVu/yFv0AYvUiCVToZRdOQbngT0aX
qhvIuG5iXmmxX9sqAn78bMrzQdjt0Oj8P2FI7bADFB0QDksZ4LtO7IZl/zbzXmcC
C52GVWH9ejjt/uIZALdvoVBidXQ8oPrIJZK0bnoix/geoeOy3ZExqysdBP+lSgQ3
6YWkMyv94tZVNHwZpEpox7Ko07fKoZOI68GXvIz5HdkihCR0xwQ9aqkpk8zruFvh
/l8lqjRYyMEjVJ0bmBHDOJx+PYZspQ9AhnwC9FwCTyjLrnGfDzrIM/4RJTXq/LrF
YD3ZfBjVsqnTdXgDciLKOsMf7yzlLqn6niy2UUb9rwPW6mBo6oUWNmuF6R7As93E
JNyAKoFBbZQ+yODJgUEAnl6/f8UImKIYLEJAs/lvOCdLToD0PYFH4Ih86hzOtXVc
US4cK38acijnALXRdMbX5J+tB5O2UzU1/Dfkw/ZdFr4hc96SCvigY2q8lpJqPvi8
ZVWb3vUNiSYE/CUapiVpy8JtynziWV+XrOvvLsi81xtZPCvM8hnIk2snYxnP/Okm
+Mpxm3+T/jRnhE6Z6/yzeAkzcLpmpnbtG3PrGqUNxCITIJRWCk4sbE6x/c+cCbqi
M+2HAgMBAAGjYzBhMB0GA1UdDgQWBBTdBAkHovV6fVJTEpKV7jiAJQ2mWTAPBgNV
HRMBAf8EBTADAQH/MB8GA1UdIwQYMBaAFN0ECQei9Xp9UlMSkpXuOIAlDaZZMA4G
A1UdDwEB/wQEAwIBhjANBgkqhkiG9w0BAQsFAAOCAgEAIBgRlCn7Jp0cHh5wYfGV
cpNxJK1ok1iOMq8bs3AD/CUrdIWQPXhq9LmLpZc7tRiRux6n+UBbkflVma8eEdBc
Hadm47GUBwwyOabqG7B52B2ccETjit3E+ZUfijhDPwGFpUenPUayvOUiaPd7nNgs
PgohyC0zrL/FgZkxdMF1ccW+sfAjRfSda/wZY52jvATGGAslu1OJD7OAUN5F7kR/
q5R4ZJjT9ijdh9hwZXT7DrkT66cPYakylszeu+1jTBi7qUD3oFRuIIhxdRjqerQ0
cuAjJ3dctpDqhiVAq+8zD8ufgr6iIPv2tS0a5sKFsXQP+8hlAqRSAUfdSSLBv9jr
a6x+3uxjMxW3IwiPxg+NQVrdjsW5j+VFP3jbutIbQLH+cU0/4IGiul607BXgk90I
H37hVZkLId6Tngr75qNJvTYw/ud3sqB1l7UtgYgXZSD32pAAn8lSzDLKNXz1PQ/Y
K9f1JmzJBjSWFupwWRoyeXkLtoh/D1JIPb9s2KJELtFOt3JY04kTlf5Eq/jXixtu
nLwsoFvVagCvXzfh1foQC5ichucmj87w7G6KVwuA406ywKBjYZC6VWg3dGq2ktuf
oYYitmUnDuy2n0Jg5GfCtdpBC8TTi2EbvPofkSvXRAdeuims2cXp71NIWuuA8ShY
Ic2wBlX7Jz9TkHCpBB5XJ7k=
-----END CERTIFICATE-----`;
const TRUSTLAYER_INC = `-----BEGIN CERTIFICATE-----
MIIGCDCCA/CgAwIBAgIQZApzM1hg+x9UPdbRlBtNIjANBgkqhkiG9w0BAQsFADB+
MQswCQYDVQQGEwJVUzEOMAwGA1UECAwFVGV4YXMxEDAOBgNVBAcMB0hvdXN0b24x
ETAPBgNVBAoMCFNTTCBDb3JwMTowOAYDVQQDDDFTU0wuY29tIENsaWVudCBDZXJ0
aWZpY2F0ZSBJbnRlcm1lZGlhdGUgQ0EgUlNBIFIyMB4XDTIyMDcxMzE2NTYxM1oX
DTI1MDcxMjE2NTYxM1owcDELMAkGA1UEBhMCVVMxEzARBgNVBAgMCkNhbGlmb3Ju
aWExFjAUBgNVBAcMDVNhbiBGcmFuY2lzY28xGTAXBgNVBAoMEFRydXN0TGF5ZXIs
IEluYy4xGTAXBgNVBAMMEFRydXN0TGF5ZXIsIEluYy4wggEiMA0GCSqGSIb3DQEB
AQUAA4IBDwAwggEKAoIBAQCsRdhseFC+uOIHyRfwqDSWXfNNVpROcvqtF0bDrELq
oCO7422HlkUXWI7ff2XHDrZR/vx7SBOV2vODe4n76rz+w3cNznJibLRZYdIYtOpI
E29YMPcqUIS4IJ4l3bivFQkq9gLnhnbYe/L/qfHv89UGD0TDZUX5iXe/BSPorCzd
/Mg5+PTnx2fuUl93sseC1YHx7I8tSYSL1nGWXYoyu0gwrkW1MxIDahRCfIX1XSnu
P01SXLh71wmrFdnhQZQoZ2R3bzzyqLKWLKvAImcmWSX5BUWDjCV0bW0Vvu6RLlZK
2dCvipPe+OBuqZ7GxdpHnD1htEgvbvFczB32UGl2xOThAgMBAAGjggGOMIIBijAM
BgNVHRMBAf8EAjAAMB8GA1UdIwQYMBaAFGaPpry3kyyd+bpJ5U/c6pBQEWqdMFcG
CCsGAQUFBwEBBEswSTBHBggrBgEFBQcwAoY7aHR0cDovL2NlcnQuc3NsLmNvbS9T
U0xjb20tU3ViQ0EtY2xpZW50Q2VydC1SU0EtNDA5Ni1SMi5jZXIwVwYDVR0gBFAw
TjA8BgwrBgEEAYKpMAEDBAEwLDAqBggrBgEFBQcCARYeaHR0cHM6Ly93d3cuc3Ns
LmNvbS9yZXBvc2l0b3J5MA4GDCsGAQQBgqkwAQMFATAqBgNVHSUEIzAhBggrBgEF
BQcDAgYKKwYBBAGCNwoDDAYJKoZIhvcvAQEFMEwGA1UdHwRFMEMwQaA/oD2GO2h0
dHA6Ly9jcmxzLnNzbC5jb20vU1NMY29tLVN1YkNBLWNsaWVudENlcnQtUlNBLTQw
OTYtUjIuY3JsMB0GA1UdDgQWBBTIvUgvOIOGaIdM2XNT+aPkxw/x3zAOBgNVHQ8B
Af8EBAMCBsAwDQYJKoZIhvcNAQELBQADggIBAG+ysEUOYxRaqZKRdYuDQXWa0712
csTw53eqcMv27n3CaMrnvNc9U72Q/4zta1/rAxmNWU/QjSoI4XpnsTTHEWIILA8d
8XxeojNqHgKwPwm/kuDkDRHggL3Ik2esUXlCV4qYwYgX05ilMnzcYIW+wUxUFIQD
Te7axn2E8vwvbEf9sBIxdLP23ScJTA9vou5SV7WzhBo2aYdLPkn3tqMQEWqG0OpY
q8YRVh/2Q+xtYysUf7k13FZ17xJQPlZxsKk3g/EjJQdiRwlP8yKEBwMAKwSq/ykk
AC6ioNFCwcrnd8ZIkGKv83SbkFHhlkLp3ZdllQaU/pCSDKdUviUllebeG8zw6HgR
tGGtGmSEthwXG7cRtk9W/RCCZII0eeaC5MKnHrWuOTFxLWlk53YrakF2vPg01Mr9
dViQIfVZwb0o/weFBZCHYe7U9nkVBaZUXqMdeDIFepZm+QcrJ1cPhFoxAfCM5jq5
kh+MYOOpftYMFMqLodq8u+PlEg9MTTK1eoLR0acpN3jW2gzMj66U05BqNpZuF8EG
OujplEJKtf3iYwL3zH17hdOrzrxUgqJbpTWt3gUlRyZ3YCd5fNGS7aPrfg3y2uyf
BSucSKbGKye1NrTwf0nRkHyHA4GsFViNClACcbWSLA9aTG8c5iFl6IUnybL3mvxa
ExN03hVpXplGtqN5
-----END CERTIFICATE-----`;

const SSLCertificates = [
  CERTUM_TRUSTED_NETWORK_CA,
  SSL_COM_ROOT_CERTIFICATION_AUTHORITY_RSA,
  SSL_COM_CLIENT_CERTIFICATE_INTERMEDIATE_CA_RSA_R2,
  TRUSTLAYER_INC,
];

export default SSLCertificates;
