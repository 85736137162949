import { WarningFilled } from '@ant-design/icons';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { PartyMessageStatus } from '../constants';

import CheckMarkIcon from './Icons/CheckMarkIcon';
import TwoCheckMarksIcon from './Icons/TwoCheckMarksIcon';

const MessageStatusIcon = ({ type }) => {
  const themeContext = useContext(ThemeContext);

  switch (type) {
    case PartyMessageStatus.Sent: {
      return <CheckMarkIcon />;
    }
    case PartyMessageStatus.Read: {
      return <TwoCheckMarksIcon />;
    }
    case PartyMessageStatus.Warning: {
      return (
        <MessageStatusIcon.WarningFilled
          data-cy="warningIcon"
          color={themeContext.colors.yellow}
        />
      );
    }
    default: {
      return null;
    }
  }
};

MessageStatusIcon.WarningFilled = styled(WarningFilled)`
  color: ${(props) => props.color};
  margin-right: 0 !important;
`;

export default MessageStatusIcon;
