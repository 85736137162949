import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Preloader from '@common/components/Preloader';
import { getIsPersistRehydrated } from '@modules/app/selectors';

import { PersistorContext } from '../../index';

const withReduxPersist = (Page) =>
  function InnerReduxPersist(props) {
    const persistor = useContext(PersistorContext);
    const isPersistRehydrated = useSelector(getIsPersistRehydrated);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
    useEffect(() => {
      if (!isPersistRehydrated) {
        persistor.persist();
      }
    }, [isPersistRehydrated]);

    if (!isPersistRehydrated) {
      return <Preloader />;
    }

    return <Page {...props} />;
  };

export default withReduxPersist;
