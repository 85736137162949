import { omit } from 'lodash';

import type { ValueSetterParams } from '@common/components/Table';
import type { RecordAttribute } from '@graphql/types/graphql';

import {
  ATTRIBUTE_TYPES,
  type Address,
  type AttributeToUpdate,
  type AttributeType,
  DEFAULT_EMPTY_SELECT_VALUE,
} from './constants';

export const isAttributeValueEmpty = (attribute: RecordAttribute) => {
  if (attribute.type === ATTRIBUTE_TYPES.MultiSelect) {
    return attribute.selectedOptions?.length === 0;
  }

  if (attribute.type === ATTRIBUTE_TYPES.Dropdown) {
    return (
      attribute.selectedOptions === null ||
      (attribute.selectedOptions &&
        attribute.selectedOptions[0]?._id === DEFAULT_EMPTY_SELECT_VALUE._id)
    );
  }

  if (attribute.type === ATTRIBUTE_TYPES.Number) {
    return attribute.value === null || attribute.value === undefined;
  }

  return !attribute?.value;
};

export const isValidValue = ({
  newValue,
  type,
  name,
}: {
  newValue: string | Address;
  type: AttributeType;
  name: string;
}) => {
  if (type === ATTRIBUTE_TYPES.Address) {
    return typeof newValue === 'object' && 'latitude' in newValue;
  }

  if (type === ATTRIBUTE_TYPES.Text && name === 'Name') {
    /**
     * @note at the moment should not be possible to edit
     * the Primaryrecord name from the attribute table
     */
    return Boolean((newValue as string).trim());
  }
  return true;
};

const mapAddressToUpdate = (address: Address) => {
  const updatedValue = {
    ...address,
    line1: address.addressLine1,
    line2: address.addressLine2,
  };

  return omit(updatedValue, ['addressLine1', 'addressLine2']);
};

export const formatAttributeToUpdate = (
  attribute: RecordAttribute,
): AttributeToUpdate => {
  if (attribute.type === ATTRIBUTE_TYPES.Dropdown) {
    return {
      id: attribute._id,
      optionIds: isAttributeValueEmpty(attribute)
        ? null
        : [attribute.selectedOptions![0]?._id],
    };
  }

  if (attribute.type === ATTRIBUTE_TYPES.Address) {
    return {
      id: attribute._id,
      value: mapAddressToUpdate(attribute.value),
    };
  }

  return {
    id: attribute._id,
    value: attribute.value as string | number | null,
  };
};

export const onAttributeValueChange = (
  params: ValueSetterParams<RecordAttribute>,
) => {
  const shouldUpdate = isValidValue({
    newValue: params.newValue,
    type: params.data.type,
    name: params.data.name,
  });

  if (shouldUpdate) {
    if (params.data.type === ATTRIBUTE_TYPES.Dropdown) {
      params.data = {
        ...params.data,
        selectedOptions: [params.newValue],
      };
    }
    if (params.data.type === ATTRIBUTE_TYPES.MultiSelect) {
      params.data = {
        ...params.data,
        selectedOptions: params.newValue,
      };
    } else {
      params.data = {
        ...params.data,
        value: params.newValue,
      };
    }

    // optimistic udpate of the row
    params.node!.updateData(params.data);
  }

  // set "valueChanged"
  return shouldUpdate;
};
