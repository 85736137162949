import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import styled from 'styled-components';

const SubjectBadgePopover = ({ subject, children }) => (
  <Popover
    placement="bottom"
    overlayStyle={{ width: '300px' }}
    title={
      <SubjectBadgePopover.Title>{subject.name}</SubjectBadgePopover.Title>
    }
    content={
      <SubjectBadgePopover.Wrapper>
        <SubjectBadgePopover.DateWrapper>
          <SubjectBadgePopover.Date>
            <p>Effective</p>
            <p>{subject.effectiveDate}</p>
          </SubjectBadgePopover.Date>
          ⟶
          <SubjectBadgePopover.Date>
            <p>Expiring</p>
            <p>{subject.expDate}</p>
          </SubjectBadgePopover.Date>
        </SubjectBadgePopover.DateWrapper>
        {subject.expColor && (
          <SubjectBadgePopover.ExpStatus color={subject.expColor}>
            <SubjectBadgePopover.ExclamationCircleOutlined />
            {subject.expColor === 'red' && 'Inactive'}
            {subject.expColor === 'orange' &&
              `Expiring in ${subject.expirationDifference} days`}
          </SubjectBadgePopover.ExpStatus>
        )}
      </SubjectBadgePopover.Wrapper>
    }
  >
    {children}
  </Popover>
);

SubjectBadgePopover.defaultProps = {
  placement: 'bottom',
};

SubjectBadgePopover.Wrapper = styled.div`
  color: ${(props) => props.theme.colors.grayishBlue};
`;

SubjectBadgePopover.Title = styled.div`
  padding: 10px 0;
  color: ${(props) => props.theme.colors.grayishBlue};
`;

SubjectBadgePopover.DateWrapper = styled.div`
  padding: 5px 54px 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

SubjectBadgePopover.Date = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayText};

  p {
    margin: 0;
  }
`;

SubjectBadgePopover.ExpStatus = styled.div`
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`;

SubjectBadgePopover.ExclamationCircleOutlined = styled(
  ExclamationCircleOutlined,
)`
  margin-right: 5px;
  font-size: 19px;
`;

export default SubjectBadgePopover;
