import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const SEND_RESET_PASSWORD_EMAIL = `${namespace}/SEND_RESET_PASSWORD_EMAIL`;

const SEND_RESET_PASSWORD_EMAIL_MUTATION = gql`
  mutation sendResetPasswordEmail($payload: String!) {
    sendResetPasswordEmail(email: $payload)
  }
`;

export const sendResetPasswordEmail = (payload) => ({
  type: SEND_RESET_PASSWORD_EMAIL,
  payload: {
    key: 'sendResetPasswordEmail',
    graphql: {
      mutation: SEND_RESET_PASSWORD_EMAIL_MUTATION,
      variables: { payload },
    },
  },
});
