import { InputNumber, Select } from 'antd';
import styled from 'styled-components';

import {
  AttributeType,
  Operator,
  financialStrengthValues,
  ratingValues,
} from '../constants';

const createSelectOptions = (list) =>
  Object.entries(list).map(([key, value]) => (
    <Select.Option key={key} value={key}>
      {value}
    </Select.Option>
  ));

const DefinedRequirement = ({
  selectedOperator,
  fieldType,
  targetValue,
  setTargetValue,
}) => {
  switch (true) {
    case fieldType === AttributeType.Number &&
      (selectedOperator === Operator.GREATER_OR_EQUAL ||
        selectedOperator === Operator.LESS_OR_EQUAL):
      return (
        <DefinedRequirement.InputNumber
          data-cy="inputNumber"
          value={targetValue}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          step={1000000}
          onChange={(value) => setTargetValue(value || 0)}
          min={0}
        />
      );

    case fieldType === AttributeType.AmBestRating:
      return (
        <Select
          value={targetValue?.toString()}
          onChange={(value) => setTargetValue(value)}
        >
          {createSelectOptions(ratingValues)}
        </Select>
      );

    case fieldType === AttributeType.AmBestFinancialStrength:
      return (
        <Select
          value={targetValue?.toString()}
          onChange={(value) => setTargetValue(value)}
        >
          {createSelectOptions(financialStrengthValues)}
        </Select>
      );

    default:
      return null;
  }
};
DefinedRequirement.InputNumber = styled(InputNumber)`
  width: 250px !important;
`;

export default DefinedRequirement;
