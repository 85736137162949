import { Tag, Typography } from 'antd';

import type { RecordAttribute } from '@graphql/types/graphql';

import { isAttributeValueEmpty } from '../AttributesTable.utils';
import { ATTRIBUTE_TYPES } from '../constants';

export const AttributeValueRenderer = ({
  attribute,
}: {
  attribute: RecordAttribute;
}) => {
  if (isAttributeValueEmpty(attribute)) {
    return <Typography.Text type="secondary">Not Set</Typography.Text>;
  }

  switch (attribute.type) {
    case ATTRIBUTE_TYPES.Address:
      const addressValue =
        typeof attribute.value === 'string'
          ? attribute.value
          : attribute.value!.raw;

      return <span>{addressValue}</span>;
    case ATTRIBUTE_TYPES.MultiSelect:
      return (
        <>
          {attribute.selectedOptions?.map((item) => (
            <Tag key={item?._id}>{item?.value}</Tag>
          ))}
        </>
      );
    case ATTRIBUTE_TYPES.Dropdown:
      return <span>{attribute.selectedOptions![0]?.value}</span>;
    default:
      return <span>{attribute.value}</span>;
  }
};
