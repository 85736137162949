import styled from 'styled-components';

import LogoSign from '@common/components/Logo/Sign';
import Spinner from '@common/components/Spinner';

const Preloader = () => (
  <Preloader.Wrapper data-cy="preloader">
    <LogoSign width={50} height={50} />
    <div style={{ marginTop: 20 }}>
      <Spinner />
    </div>
  </Preloader.Wrapper>
);

Preloader.Wrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  z-index: 99999999;
`;

export default Preloader;
