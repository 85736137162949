import { BulkOperationStatus } from '@graphql/types/graphql';
import { AutomationSelect } from '@modules/automations/containers/AutomationSelect';
import { useDisableAutomationMutation } from '@modules/primary-records/hooks/useDisableAutomationMutation';
import { useEnableAutomationMutation } from '@modules/primary-records/hooks/useEnableAutomationMutation';
import { Form, Modal, Skeleton, Typography, message } from 'antd';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { usePrimaryRecordQuery } from './hooks/usePrimaryRecordQuery';

type EnableDisableAutomationModalProps = {
  open: boolean;
  primaryRecordId: string;
  type: 'enable' | 'disable';
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export function EnableDisableAutomationModal({
  open,
  primaryRecordId,
  type,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: EnableDisableAutomationModalProps) {
  const [form] = Form.useForm<{ automationId: string }>();
  const { primaryRecord, loading: primaryRecordLoading } =
    usePrimaryRecordQuery({
      id: primaryRecordId,
      skip: !open || !primaryRecordId,
    });

  const { enableAutomation, isEnablingAutomation } =
    useEnableAutomationMutation();
  const { disableAutomation, isDisablingAutomation } =
    useDisableAutomationMutation();

  const isLoading = isEnablingAutomation || isDisablingAutomation;
  const actionLabel = capitalize(type);

  const handleEnableAutomation = async (values: { automationId: string }) => {
    const res = await enableAutomation(primaryRecordId, values.automationId);

    const status = res.data?.enablePrimaryRecordsAutomation?.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Automation for ${primaryRecord?.name} enabled successfully.`,
      );
      onCompleted?.();
    } else if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Automation for ${primaryRecord?.name} has been scheduled for enabling.`,
      );
      onScheduled?.();
    } else if (status === BulkOperationStatus.Failed) {
      message.error(
        `The enabling of the automation for ${primaryRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const handleDisableAutomation = async (values: { automationId: string }) => {
    const res = await disableAutomation(primaryRecordId, values.automationId);

    const status = res.data?.disablePrimaryRecordsAutomation?.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Automation for ${primaryRecord?.name} disabled successfully.`,
      );
      onCompleted?.();
    } else if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Automation for ${primaryRecord?.name} has been scheduled for disabling.`,
      );
      onScheduled?.();
    } else if (status === BulkOperationStatus.Failed) {
      message.error(
        `The disabling of the automation for ${primaryRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const handleAction = (values: { automationId: string }) => {
    if (type === 'enable') {
      handleEnableAutomation(values);
    } else {
      handleDisableAutomation(values);
    }
  };

  return (
    <Modal
      data-cy={`${type}-automation-modal`}
      open={open}
      okButtonProps={{
        loading: isLoading,
        disabled: primaryRecordLoading,
      }}
      okText={actionLabel}
      title={`${actionLabel} automation`}
      onCancel={onCancel}
      onOk={() => form.submit()}
      modalRender={(modal) => (
        <Form form={form} onFinish={handleAction}>
          {modal}
        </Form>
      )}
    >
      {primaryRecordLoading ? (
        <Skeleton.Input
          style={{ width: '100px', height: '1rem', marginBottom: '0.5rem' }}
          active={primaryRecordLoading}
        />
      ) : (
        <Typography.Paragraph>
          {`${actionLabel} the automation for ${primaryRecord?.name}`}
        </Typography.Paragraph>
      )}
      <StyledSelectWrapper>
        <span>Automation</span>
        <Form.Item
          name="automationId"
          rules={[{ required: true, message: 'Please select an automation' }]}
        >
          {/* @ts-expect-error - value and onChange are implicitly defined by Form.Item */}
          <StyledAutomationSelect />
        </Form.Item>
      </StyledSelectWrapper>
    </Modal>
  );
}

const StyledSelectWrapper = styled.section`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;

  > span {
    margin-top: 5px;
  }
`;

const StyledAutomationSelect = styled(AutomationSelect)`
  width: fit-content;
`;
