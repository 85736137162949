import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const IGNORE_CONVERSATION_MESSAGE_ISSUE = graphql(`
  mutation IgnoreConversationMessageIssue($data: IgnoreConversationMessageIssueInput!) {
    ignoreConversationMessageIssue(data: $data) {
      _id
      resendable
      ignorable
      resolved
    }
  }
`);

export const useIgnoreConversationMessageIssueMutation = () => {
  const [ignoreConversationMessageIssueMutation, { loading }] = useMutation(
    IGNORE_CONVERSATION_MESSAGE_ISSUE,
  );

  const ignoreConversationMessageIssue = (id: string) => {
    return ignoreConversationMessageIssueMutation({
      variables: {
        data: {
          id,
        },
      },
    });
  };

  return {
    ignoreConversationMessageIssue,
    loading,
  };
};
