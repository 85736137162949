import {
  DOWNLOAD_CANCEL,
  DOWNLOAD_INIT,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_UPDATE,
} from '../actions';

const initialState = {
  downloads: [],
};

export default function downloadReducer(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_INIT:
      return {
        downloads: state.downloads.concat(action.payload),
      };
    case DOWNLOAD_UPDATE:
      return {
        downloads: state.downloads.map((d) => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        }),
      };
    case DOWNLOAD_SUCCESS:
    case DOWNLOAD_CANCEL:
      return {
        downloads: state.downloads.filter((d) => d.id !== action.payload),
      };
    default:
      return state;
  }
}
