import moment from 'moment';

import {
  KeyType,
  type SelectedFilter,
} from '@common/components/DateFilterSelector';

const getUtcValue = (date: moment.Moment) =>
  moment.utc(date.format('YYYY-MM-DD')).valueOf();

const getMomentInstance = (value: number) =>
  moment(moment.utc(value).format('YYYY-MM-DD'));

/**
 * It transforms a moment instace in a duration value
 */
export const normalizeFilterValue = (selectedFilter?: SelectedFilter) => {
  if (!selectedFilter) {
    return selectedFilter;
  }

  if (selectedFilter.key === KeyType.ON) {
    return {
      ...selectedFilter,
      value: getUtcValue(selectedFilter.value),
    };
  }

  if (selectedFilter.key === KeyType.BETWEEN) {
    return {
      ...selectedFilter,
      from: getUtcValue(selectedFilter.from),
      to: getUtcValue(selectedFilter.to),
    };
  }

  return selectedFilter;
};

/**
 * It transforms filter duration value in a moment instance
 */
export const denormalizeFilterValue = (
  selectedFilter?: SelectedFilter<number>,
) => {
  if (!selectedFilter) {
    return selectedFilter;
  }

  if (selectedFilter.key === KeyType.ON) {
    return {
      ...selectedFilter,
      value: getMomentInstance(selectedFilter.value),
    };
  }

  if (selectedFilter.key === KeyType.BETWEEN) {
    return {
      ...selectedFilter,
      from: getMomentInstance(selectedFilter.from).startOf('D'),
      to: getMomentInstance(selectedFilter.to).endOf('D'),
    };
  }

  return selectedFilter;
};
