import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const REMOVE_PARTY_FROM_PROJECTS = `${namespace}/REMOVE_PARTY_FROM_PROJECTS`;

const REMOVE_PARTY_FROM_PROJECTS_MUTATION = gql`
  mutation removePartyFromProjects($data: RemovePartyFromProjectsInput!) {
    removePartyFromProjects(data: $data) {
      _id
      name
      type {
        _id
        name
      }
      isActive
      isTrackingCompliance
      address {
        rawAddress
        line1
        line2
        city
        region
        country
        postalCode
        latitude
        longitude
      }
      additionalNotes
      partyContacts {
        _id
        contactPersonName
        companyName
        email
        phone
        isPrimary
      }
      organization
    }
  }
`;

export const removePartyFromProjects = (data) => ({
  type: REMOVE_PARTY_FROM_PROJECTS,
  payload: {
    key: 'removePartyFromProjects',
    graphql: {
      mutation: REMOVE_PARTY_FROM_PROJECTS_MUTATION,
      variables: {
        data,
      },
    },
  },
});
