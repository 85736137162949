export const Operator = {
  PRESENT: 'must be present',
  GREATER_OR_EQUAL: 'must be greater or equal to',
  LESS_OR_EQUAL: 'must be less than or equal to',
  COMPLETED_BY: 'must be completed by',
} as const;

export const AttributeType = {
  Boolean: 'boolean',
  Number: 'number',
  AmBestRating: 'amBestRating',
  AmBestFinancialStrength: 'amBestFinancialStrength',
  FillableForm: 'fillableForm',
  Connected: 'connected',
  Ai: 'ai',
} as const;

export const CustomAttributeTypeByOperator = {
  [Operator.PRESENT]: AttributeType.Boolean,
  [Operator.GREATER_OR_EQUAL]: AttributeType.Number,
  [Operator.LESS_OR_EQUAL]: AttributeType.Number,
} as const;

export const operators = {
  [AttributeType.Number]: [
    Operator.GREATER_OR_EQUAL,
    Operator.LESS_OR_EQUAL,
    Operator.PRESENT,
  ],
  [AttributeType.Ai]: [Operator.PRESENT],
  [AttributeType.Boolean]: [Operator.PRESENT],
  [AttributeType.AmBestRating]: [Operator.GREATER_OR_EQUAL],
  [AttributeType.AmBestFinancialStrength]: [Operator.GREATER_OR_EQUAL],
  [AttributeType.Connected]: [Operator.PRESENT],
} as const;

export const isInputNumber = (
  operator: (typeof Operator)[keyof typeof Operator],
) => {
  return (
    operator === Operator.GREATER_OR_EQUAL ||
    operator === Operator.LESS_OR_EQUAL
  );
};
