import { gql } from '@apollo/client';

import type { AnyGQLQueryAction, AnyGQLSuccessAction } from '@common/types';
import { ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { namespace } from '../constants';
import { FILLABLE_FORM_FRAGMENT } from '../fragments';

export const FETCH_FILLABLE_FORMS =
  `${namespace}/FETCH_FILLABLE_FORMS` as const;
export const FETCH_FILLABLE_FORMS_SUCCESS =
  `${namespace}/FETCH_FILLABLE_FORMS${ACTION_SUCCESS_SUFFIX}` as const;

const FETCH_FILLABLE_FORMS_QUERY = gql`
  query listForms($filters: ListFormFilters, $skip: Int, $limit: Int) {
    listForms(filters: $filters, skip: $skip, limit: $limit) {
      ...Form
    }
    countForms(filters: $filters)
  }
  ${FILLABLE_FORM_FRAGMENT}
`;

export type FetchFillableFormsPayload = {
  skip?: number;
  limit?: number;
  filters?: { name?: string };
};

export type FetchFillableForms = (
  payload: FetchFillableFormsPayload,
) => AnyGQLQueryAction<typeof FETCH_FILLABLE_FORMS, FetchFillableFormsPayload>;

export const fetchFillableForms: FetchFillableForms = (payload) => ({
  type: FETCH_FILLABLE_FORMS,
  payload: {
    key: 'listForms',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_FILLABLE_FORMS_QUERY,
      variables: payload,
    },
  },
});

export type FetchFillableFormsSuccessAction = AnyGQLSuccessAction<
  typeof FETCH_FILLABLE_FORMS_SUCCESS
>;
