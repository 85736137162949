import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTY_TO_PROJECT_MODAL = `${namespace}/FETCH_PARTY_TO_PROJECT_MODAL`;

const FETCH_PARTY_TO_PROJECT_MODAL_QUERY = gql`
  query getPartyData($id: String!, $byContext: Boolean) {
    getPartyData(id: $id, byContext: $byContext) {
      _id
      name
      isActive
      isTrackingCompliance
      type {
        _id
        name
      }
      partyComplianceProfile {
        _id
        complianceProfile
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
      }
      status
    }
  }
`;

export const fetchPartyToProjectModal = (id) => ({
  type: FETCH_PARTY_TO_PROJECT_MODAL,
  payload: {
    key: 'getPartyData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_TO_PROJECT_MODAL_QUERY,
      variables: { id, byContext: false },
    },
  },
});
