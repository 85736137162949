const warn = (error) => {
  console.warn(error.message || error);
  throw error; // To let the caller handle the rejection
};

const promiseMiddleware = () => (next) => (action) => {
  if (typeof action.then === 'function') {
    Promise.resolve(action).then(next, warn);
  } else {
    next(action);
  }
};

export default promiseMiddleware;
