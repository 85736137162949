import { Button, Collapse, Result } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

const UncaughtError = ({ error, componentStack }) => {
  const emailBodySubject = encodeURIComponent('ERROR: ');
  const emailBodyMessage = encodeURIComponent(
    `\n------------\nPlease write your message above this line!\n\nLocation: ${window?.location?.href}\n${error} ${componentStack}`,
  );

  return (
    <UncaughtError.Wrapper>
      <Result
        status="warning"
        title="An unexpected error occured"
        subTitle={
          <>
            <p>
              If the error persits, please contact our{' '}
              <a
                href={`mailto:support@trustlayer.io?subject=${emailBodySubject}&body=${emailBodyMessage}`}
              >
                customer support
              </a>
            </p>
            <Collapse ghost>
              <Panel header="Show error stacktrace">
                <div>{error?.toString()}</div>
                {componentStack}
              </Panel>
            </Collapse>
          </>
        }
        extra={
          <>
            <Button type="primary" onClick={() => window?.location?.reload()}>
              Reload
            </Button>
            <Button href="/">Back to home</Button>
          </>
        }
      />
    </UncaughtError.Wrapper>
  );
};

UncaughtError.Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15% 15px 0;
`;

export default UncaughtError;
