import styled from 'styled-components';

type Props = {
  isEmptyConversations: boolean;
  isEmptyContacts: boolean;
};

export const ConversationEmptyState = ({
  isEmptyConversations,
  isEmptyContacts,
}: Props) => {
  return (
    <StyledWrapper>
      <div>
        <img alt="No messages" src="/images/empty-communications.png" />
      </div>
      {!isEmptyContacts && (
        <StyledTitle>
          {isEmptyConversations
            ? 'No conversation yet'
            : 'No conversation selected'}
        </StyledTitle>
      )}
      {isEmptyContacts && (
        <StyledTitle>No contact information available</StyledTitle>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  color: #1e3b58;

  img {
    margin-bottom: 53px;
    max-height: 192px;
    max-width: 172px;
  }
`;

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`;
