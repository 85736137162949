const MarkIcon = ({ width, height, muted }) => (
  <svg width={width} height={height} viewBox="0 0 11 12">
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        id="Compliance-Summary-Items"
        transform="translate(-1.000000, -3.000000)"
        fillRule="nonzero"
      >
        <g id="Group-2">
          <g id="fa-solid:times-copy" transform="translate(1.000000, 2.000000)">
            <path
              d="M7.585,6.66666667 L10.7121875,3.53947917 C11.0959375,3.15572917 11.0959375,2.53354167 10.7121875,2.14947917 L10.0171875,1.45447917 C9.6334375,1.07072917 9.01125,1.07072917 8.6271875,1.45447917 L5.5,4.58166667 L2.3728125,1.45447917 C1.9890625,1.07072917 1.366875,1.07072917 0.9828125,1.45447917 L0.2878125,2.14947917 C-0.0959375,2.53322917 -0.0959375,3.15541667 0.2878125,3.53947917 L3.415,6.66666667 L0.2878125,9.79385417 C-0.0959375,10.1776042 -0.0959375,10.7997917 0.2878125,11.1838542 L0.9828125,11.8788542 C1.3665625,12.2626042 1.9890625,12.2626042 2.3728125,11.8788542 L5.5,8.75166667 L8.6271875,11.8788542 C9.0109375,12.2626042 9.6334375,12.2626042 10.0171875,11.8788542 L10.7121875,11.1838542 C11.0959375,10.8001042 11.0959375,10.1779167 10.7121875,9.79385417 L7.585,6.66666667 Z"
              id="Path"
              fill={muted ? '#BBBBBB' : '#FF0000'}
            />
            <rect
              id="Rectangle"
              fillOpacity="0"
              fill="#000000"
              x="0"
              y="0"
              width="11"
              height="16"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MarkIcon.defaultProps = {
  width: 10,
  height: 10,
  muted: false,
};

export default MarkIcon;
