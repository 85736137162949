import { InfoCircleFilled } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

type AddModuleModalProps = {
  visible: boolean;
  onSend: (moduleName: string) => void;
  onClose: () => void;
};

export const AddModuleModal = ({
  visible,
  onSend,
  onClose,
}: AddModuleModalProps) => {
  const [value, setValue] = useState('');

  return (
    <Modal
      data-cy="addModuleModal"
      open={visible}
      title="Add new module"
      okText="Add module"
      cancelText="Cancel"
      onCancel={onClose}
      okButtonProps={{
        disabled: !Boolean(value),
      }}
      onOk={() => {
        onSend(value);
      }}
    >
      <>
        <StyledLabel>Custom module name:</StyledLabel>
        <Input
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Name..."
        />
        <StyledAlert>
          <StyledIcon />
          <span>This name will be visible to your parties</span>
        </StyledAlert>
      </>
    </Modal>
  );
};

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const StyledAlert = styled.div`
margin-top: 22px;
margin-bottom: 3px;
`;

const StyledIcon = styled(InfoCircleFilled)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.mediumBlue};
`;
