import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { CreateOrEditRequirementForm_RulesQuery } from '@graphql/types/graphql';

export type Rules =
  CreateOrEditRequirementForm_RulesQuery['listRuleDefinitions'];

const RULES_QUERY = graphql(`
  query CreateOrEditRequirementForm_Rules {
    listRuleDefinitions {
      subjectId
      attributeId
      attributeLabel
      attributeDescription
      attributeType
      isActive
    }
  }  
`);

const getRequirementsRulesBySubject = (subjectId: string, rules: Rules) => {
  return rules.filter((rule) => rule.subjectId === subjectId);
};

export const useRequirementsRules = ({ subjectId }: { subjectId: string }) => {
  const { data, loading } = useQuery(RULES_QUERY);

  return {
    options: getRequirementsRulesBySubject(
      subjectId,
      data?.listRuleDefinitions || [],
    ),
    loading,
  };
};
