import { NetworkStatus } from '@apollo/client';

import useMutationWithRedux from '@graphql/hooks/useMutationWithRedux';
import useQueryWithRedux from '@graphql/hooks/useQueryWithRedux';
import { getOperationName } from '@graphql/utils';
import { CREATE_EVENT, FETCH_EVENT_LOGS } from '@modules/event-log/actions';
import { CREATE_EVENT_LOG_MUTATION } from '@modules/event-log/mutations';
import { getEventLogs } from '@modules/event-log/selectors';
import { PARTY_EVENT_LOGS_QUERY } from '@modules/party/pages/PartyDetailsPage/queries';

type UsePartyEventLogsProps = {
  partyId: string;
};

const usePartyEventLogs = ({ partyId }: UsePartyEventLogsProps) => {
  const skip = 0;
  const limit = 10;

  const defaultFilters = {
    contextEntityType: {
      $in: ['party', 'request', 'document', 'data', 'requirement', 'subject'],
    },
    partyId,
  };

  const {
    data: partyEventLogsRes,
    loading: isPartyEventLogsLoading,
    networkStatus,
    fetchMore,
  } = useQueryWithRedux(PARTY_EVENT_LOGS_QUERY, {
    reduxActionKey: getOperationName(PARTY_EVENT_LOGS_QUERY),
    reduxActionType: `${FETCH_EVENT_LOGS}_HOOK`,
    reduxSelector: getEventLogs,
    variables: {
      filters: {
        ...defaultFilters,
        skip,
        limit,
      },
    },
    skip: !partyId,
    notifyOnNetworkStatusChange: true,
  });

  const isTimelineLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isTimelineLoading = !isTimelineLoadingMore && isPartyEventLogsLoading;

  const [createEvent] = useMutationWithRedux(CREATE_EVENT_LOG_MUTATION, {
    reduxActionKey: getOperationName(CREATE_EVENT_LOG_MUTATION),
    reduxActionType: CREATE_EVENT,
  });

  const fetchMoreEventLogs = () => {
    fetchMore({
      variables: {
        filters: {
          ...defaultFilters,
          skip: partyEventLogsRes?.listEventLogs?.length,
          limit,
        },
      },
    });
  };

  return {
    partyEventLogs: partyEventLogsRes?.listEventLogs,
    createEvent,
    isTimelineLoading,
    isTimelineLoadingMore,
    fetchMore: fetchMoreEventLogs,
  };
};

export default usePartyEventLogs;
