import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { USER_FRAGMENT } from '../fragments';

export const UPDATE_USER = `${namespace}/UPDATE_USER`;

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($payload: UpdateUserInput!) {
    updateUser(data: $payload) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload: {
    key: 'updateUser',
    graphql: {
      mutation: UPDATE_USER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
