import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_REVIEW_DOCUMENTS = `${namespace}/BULK_REVIEW_DOCUMENTS`;

const BULK_REVIEW_DOCUMENTS_MUTATION = gql`
  mutation bulkReviewDocuments($payload: BulkReviewDocumentsInput) {
    bulkReviewDocuments(data: $payload) {
      scheduled
    }
  }
`;

export const bulkReviewDocuments = (payload) => ({
  type: BULK_REVIEW_DOCUMENTS,
  payload: {
    key: 'bulkReviewDocuments',
    graphql: {
      mutation: BULK_REVIEW_DOCUMENTS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
