import { Badge } from 'antd';
import styled from 'styled-components';

const DocumentReviewStatusTag = ({ isReviewed }) => (
  <DocumentReviewStatusTag.Wrapper>
    {isReviewed ? (
      <DocumentReviewStatusTag.Tag>
        <Badge color="green" text="Reviewed" />
      </DocumentReviewStatusTag.Tag>
    ) : (
      <DocumentReviewStatusTag.Tag>
        <Badge status="processing" text="To review" />
      </DocumentReviewStatusTag.Tag>
    )}
  </DocumentReviewStatusTag.Wrapper>
);

DocumentReviewStatusTag.Wrapper = styled.div`
  padding: 2px;
  display: inline-block;
`;

DocumentReviewStatusTag.Tag = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  white-space: nowrap;

  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
    top: 0;
  }

  .ant-badge-status-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    margin-left: 6px;
  }

  .ant-badge-status-processing + .ant-badge-status-text {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export default DocumentReviewStatusTag;
