const WaiveIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 19 17`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Requirements/Row/Compliant"
        transform="translate(-582.000000, -4.000000)"
        stroke="#677283"
        strokeWidth="1.5"
      >
        <g id="icon-/-waive" transform="translate(581.500000, 4.840332)">
          <rect
            id="Rectangle"
            x="4.84090909"
            y="0.75"
            width="10.3181818"
            height="13.7530382"
            rx="2"
          ></rect>
          <path
            d="M19.0909091,2.22222222 L0.909090909,14.3641493"
            id="Line-2"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

WaiveIcon.defaultProps = {
  width: 15,
  height: 15,
};

export default WaiveIcon;
