import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment User on User {
    _id
    email
    isActive
    deletedAt
    lastActivityDate
    profile {
      name
      phone
      title
      avatar
      role
      department
      timezone
    }
    createdAt
    updatedAt
  }
`;
