import { Progress } from 'antd';

type CircleProgressProps = {
  currentActiveItemsCount: number;
  totalItemsCount: number;
};

const DEFAULT_PROGRESS_CONFIG = {
  fontSize: 10,
  size: 80,
  strokeWidth: 5,
};

export const CircleProgress = ({
  currentActiveItemsCount,
  totalItemsCount,
}: CircleProgressProps) => {
  const isSuccess = currentActiveItemsCount === totalItemsCount;

  return (
    <Progress
      format={() => (
        <span>{`${currentActiveItemsCount}/${totalItemsCount}`}</span>
      )}
      percent={(currentActiveItemsCount / totalItemsCount) * 100}
      size="small"
      type="circle"
      strokeWidth={DEFAULT_PROGRESS_CONFIG.strokeWidth}
      width={DEFAULT_PROGRESS_CONFIG.size}
      status={isSuccess ? 'success' : 'active'}
    />
  );
};
