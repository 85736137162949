import type { RootState } from '@common/types';

import { STATE_KEY } from '../constants';
import type { FeatureFlagsState } from '../reducers';

export const isFeatureFlagEnabled = (
  state: RootState,
  featureFlagName: string,
) => Boolean(state[STATE_KEY]?.[featureFlagName]?.enabled);

export const getFeatureFlags = (state: RootState): FeatureFlagsState =>
  state[STATE_KEY];
