import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_TYPE_FRAGMENT } from '../fragments';

export const DELETE_DOCUMENT_TYPE = `${namespace}/DELETE_DOCUMENT_TYPE`;

const DELETE_DOCUMENT_TYPE_MUTATION = gql`
  mutation deleteDocumentType($id: String!) {
    deleteDocumentType(id: $id) {
      ...DocumentType
    }
  }
  ${DOCUMENT_TYPE_FRAGMENT}
`;

export const deleteDocumentType = (id) => ({
  type: DELETE_DOCUMENT_TYPE,
  payload: {
    key: 'deleteDocumentType',
    graphql: {
      mutation: DELETE_DOCUMENT_TYPE_MUTATION,
      variables: {
        id,
      },
    },
  },
});
