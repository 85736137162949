import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { ComplianceModule } from '@graphql/types/graphql';
import { useState } from 'react';

/**
 * @note Temporary implementation since the query "complianceProfile" doesn't provide the compliance modules yet.
 */
export const COMPLIANCE_PROFILE_QUERY = graphql(`
  query ComplianceEditorComplianceModules($requestRecordInput: RequestRecordInput!) {
  requestRecord(input: $requestRecordInput) {
    _id
    complianceModules {
      code
      label
      subjects {
        code
        label
        status
        effectiveDate
        latestExpirationDate
        requirements {
          custom
          attributeCode
          attributeType
          attributeLabel
          attributeDescription
          documentAttributeCode
          matchingCriteria
          operator
          targetValue
          value
          status
          invalidatedBy
          notes
          publicNotes
          resetOn
        }
        notes
        resetOn
        expiring
        expired
      }
    }
    complianceProfile {
      _id
      name
    }
  }
}`);

export const useComplianceEditor = ({ requestId }: { requestId: string }) => {
  const [modules, setModules] = useState<ComplianceModule[]>([]);

  const { loading } = useQuery(COMPLIANCE_PROFILE_QUERY, {
    variables: {
      requestRecordInput: {
        id: requestId,
      },
    },
    onCompleted: ({ requestRecord }) => {
      setModules(requestRecord.complianceModules);
    },
  });

  return {
    modules,
    onChanged: setModules,
    loading,
  };
};
