import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const DELETE_DOCUMENT = `${namespace}/DELETE_DOCUMENT`;

const DELETE_DOCUMENT_MUTATION = gql`
  mutation deleteDocument($id: String!) {
    deleteDocument(id: $id) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const deleteDocument = (id) => ({
  type: DELETE_DOCUMENT,
  payload: {
    key: 'deleteDocument',
    graphql: {
      mutation: DELETE_DOCUMENT_MUTATION,
      variables: { id },
    },
  },
});
