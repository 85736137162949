import { Badge } from 'antd';
import styled from 'styled-components';

import type { Document } from '@graphql/types/graphql';
import SubjectBadgePopover from '@modules/document/components/SubjectBadgePopover';
import { getSubjects } from '@modules/document/utils/document-helpers';

export function getDocumentSubjectsList(
  subjects: Document['metadata'],
  primaryRecord: Document['party'],
) {
  //? if something strange happens in subjectList is because we don't pass party in getSubjects, not passed from the beginning
  //? because we don't think is a check needed anymore
  return getSubjects({ metadata: subjects, party: primaryRecord }).map(
    (subject) => (
      <SubjectBadgePopover key={`key_${subject.name}`} subject={subject}>
        <StyledBadge dot={!!subject.expColor} $color={subject.expColor}>
          <StyledAbbreviation data-cy="subjectBadgeAbbr">
            {subject.abbr}
          </StyledAbbreviation>
        </StyledBadge>
      </SubjectBadgePopover>
    ),
  );
}

const StyledBadge = styled(Badge)<{ $color: any }>`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};

  .ant-badge-dot {
    background-color: ${(props) => props.$color} !important;
    z-index: auto;
  }
`;

const StyledAbbreviation = styled.abbr`
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grayText};
  user-select: none;
`;
