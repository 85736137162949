const CheckMarkIcon = () => (
  <span data-cy="sentIcon">
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9619 0L8.09394 3.55104L4.22598 7.10209L1.18426 4.30551L0.138672 5.25112L4.22598 9L13.0002 0.95231L11.9619 0Z"
        fill="#1890FF"
      />
    </svg>
  </span>
);

export default CheckMarkIcon;
