import { gql } from '@apollo/client';

export const FLAG_FRAGMENT = gql`
  fragment Flag on DocumentFlag {
    document {
      _id
      friendlyName
      party {
        _id
      }
      issuedBy {
        name
        contactName
        email
      }
    }
    addedOn
    severityLevel
    affectedSubjects
    notes
    user {
      _id
      email
      profile {
        name
      }
    }
    authorName
  }
`;
