import * as AntdIcons from '@ant-design/icons';
import React from 'react';
import type { FunctionComponent } from 'react';

export const getAntdIconOutlinedByName = (name: string | undefined) => {
  if (!name) return null;

  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
  const iconName = `${capitalizedName}Outlined`;

  const IconComponent = AntdIcons[
    iconName as keyof typeof AntdIcons
  ] as FunctionComponent;

  if (!IconComponent) {
    console.error(`Icon not found: ${iconName}`);
    return null;
  }

  return React.createElement(IconComponent);
};
