import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_SENDER_FRAGMENT } from '../fragments';

export const DELETE_EMAIL_SENDER = `${namespace}/DELETE_EMAIL_SENDER`;

const DELETE_EMAIL_SENDER_MUTATION = gql`
  mutation deleteEmailSender($_id: String!) {
    deleteEmailSender(_id: $_id) {
      ...EmailSender
    }
  }
  ${EMAIL_SENDER_FRAGMENT}
`;

export const deleteEmailSender = (_id) => ({
  type: DELETE_EMAIL_SENDER,
  payload: {
    key: 'deleteEmailSender',
    graphql: {
      mutation: DELETE_EMAIL_SENDER_MUTATION,
      variables: {
        _id,
      },
    },
  },
});
