import type {
  ActionData as APIAutomationActionData,
  Action as APIAutomationActionStep,
  EmailActionData as APIAutomationEmailActionData,
  Step as APIAutomationStep,
  TagsActionData as APIAutomationTagsActionData,
} from '@graphql/types/graphql';

import {
  type AutomationCode,
  type FilterName,
  type FilterOperator,
  STEP_JOB_TYPES,
  STEP_TYPES,
  TRIGGER_TYPES,
} from './constants';

export type TriggerType = keyof typeof TRIGGER_TYPES;

export type StepJobType = (typeof STEP_JOB_TYPES)[keyof typeof STEP_JOB_TYPES];
export type StepType = (typeof STEP_TYPES)[keyof typeof STEP_TYPES];

export type SendEmailJobsType = Pick<
  typeof STEP_JOB_TYPES,
  'sendDocumentReminder' | 'sendExpiringDocumentReminder'
>;

export type Filter = {
  operator: FilterOperator;
  name: FilterName;
  value: Record<string, number | undefined> | string;
};

export type TriggerFilter = { and: Filter[]; or?: Filter[] } | null;

export const isAPIAutomationActionStep = (
  step: APIAutomationStep,
): step is APIAutomationActionStep => {
  return step.type === STEP_TYPES.action;
};
export const isAPIAutomationActionTagStep = (
  data: APIAutomationActionData,
): data is APIAutomationTagsActionData => {
  return 'tags' in data;
};
export const isAPIAutomationActionEmailStep = (
  data: APIAutomationActionData,
): data is APIAutomationEmailActionData => {
  return 'emailTemplate' in data;
};

export type EmailActionData = {
  emailTemplateId: string | undefined;
};

export type TagsActionData = {
  tagIds?: string[];
  automationId?: string;
};

export type ActionData = EmailActionData | TagsActionData;

export type EmptyNode = {
  clientId: string;
  type: Step['type'] | Trigger['type'];
  job?: ActionStep['job'];
};

export type ActionStep<
  JobType = StepJobType | undefined,
  DataType = ActionData,
> = JobType extends undefined
  ? {
      type?: StepType;
      job?: JobType;
      data?: DataType;
      clientId: string;
    }
  : {
      type: StepType;
      job: JobType;
      data?: DataType;
      clientId: string;
    };

export type WaitStep = {
  type?: StepType;
  duration?: Record<string, number | undefined>;
  clientId: string;
};

export type Step = ActionStep | WaitStep;

export type Trigger = {
  type: TriggerType;
  event: string;
  filter: TriggerFilter;
  clientId: string;
};

export type Branch = {
  _id?: string;
  steps: Step[];
  trigger: Trigger;
};

export type Automation = {
  _id: string;
  name: string;
  actionsCount: number;
  organization: string;
  code?: AutomationCode;
  branches: Branch[];
  exitAutomation?: Automation | null;
  parentAutomation?: Automation | null;
};

export const WaitTypes = {
  step: 'step',
  trigger: 'trigger',
} as const;

export type WaitType = keyof typeof WaitTypes;

export const isEmptyNode = (step: Step | Trigger | EmptyNode) => {
  return !step.type;
};

export const isActionStep = (
  step: Step | Trigger,
): step is ActionStep<StepJobType, ActionData | undefined> => {
  return step.type === STEP_TYPES.action && !('event' in step);
};

export const isWaitStep = (step: Step | Trigger): step is WaitStep => {
  return step.type === STEP_TYPES.wait && !('event' in step);
};

export const isIncompleteActionStep = (step: Step) =>
  isActionStep(step) && !step.job;

// checing for !event.data is to differentiate between step and trigger. We might need to improve this in the future
export const isStep = (data?: Step | Trigger): data is Step =>
  (data?.type === STEP_TYPES.action || data?.type === STEP_TYPES.wait) &&
  !('event' in data);

export const isStartTrigger = (data: Step | Trigger): data is Trigger =>
  data?.type === TRIGGER_TYPES.start;

export const isWaitTrigger = (data: Step | Trigger): data is Trigger =>
  data?.type === TRIGGER_TYPES.wait;

// checing for !event.data is to differentiate between step and trigger. We might need to improve this in the future
export const isTrigger = (data?: Step | Trigger): data is Trigger =>
  (data?.type === TRIGGER_TYPES.start || data?.type === TRIGGER_TYPES.wait) &&
  'event' in data;

export const isEmailAction = (
  step: Step | Trigger,
): step is ActionStep<StepJobType, EmailActionData> => {
  return (
    isActionStep(step) &&
    (step.job === STEP_JOB_TYPES.sendDocumentReminder ||
      step.job === STEP_JOB_TYPES.sendExpiringDocumentReminder)
  );
};

export const isTagsAction = (
  step: Step,
): step is ActionStep<StepJobType, TagsActionData> => {
  return (
    isActionStep(step) &&
    (step.job === STEP_JOB_TYPES.addTags ||
      step.job === STEP_JOB_TYPES.removeTags)
  );
};

export const isNodeStep = ({
  node,
  waitType,
}: {
  node: EmptyNode;
  waitType: WaitType;
}) => {
  return (
    node.type === STEP_TYPES.action ||
    (node.type === STEP_TYPES.wait && waitType !== WaitTypes.trigger)
  );
};

export const isNodeTrigger = ({
  node,
  waitType,
}: {
  node: EmptyNode | Trigger | Step;
  waitType: WaitType;
}) => {
  return (
    node.type === TRIGGER_TYPES.start ||
    node.type === TRIGGER_TYPES.anchor ||
    (node.type === TRIGGER_TYPES.wait && waitType === WaitTypes.trigger)
  );
};
