import styled from 'styled-components';

interface PillLabelProps {
  label: string;
  type?: 'default' | 'success';
}

type LabelType = PillLabelProps['type'];

function getColorsByType(type: LabelType, theme: any) {
  switch (type) {
    case 'success':
      return `
        background-color: ${theme.colors.limeGreen};
        color: ${theme.colors.white};
      `;
    default:
      return `
      background-color: ${theme.colors.lightestGray};
      color: ${theme.colors.black};
    `;
  }
}

function PillLabel({ label, type, ...rest }: PillLabelProps) {
  return (
    <StyledWrapper type={type} {...rest}>
      {label}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.span<{ type: LabelType }>`
  margin: 0;
  padding: 1px 7px;
  ${({ theme, type }) => getColorsByType(type, theme)};
  border-radius: 50px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 600;
`;

export default PillLabel;
