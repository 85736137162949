import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { getGraphqlResponse } from '@store/helpers';

import {
  DISCONNECT_FROM_QUICKBOOKS,
  FETCH_QUICKBOOKS_AUTH_LINK,
} from '../actions';

const initialState = {
  authLink: '',
};

const QuickbooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_QUICKBOOKS_AUTH_LINK}_SUCCESS`: {
      return R.assoc('authLink', getGraphqlResponse(action), state);
    }
    case `${DISCONNECT_FROM_QUICKBOOKS}_SUCCESS`: {
      return R.mergeDeepRight(state, {
        authLink: getGraphqlResponse(action),
      });
    }
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default QuickbooksReducer;
