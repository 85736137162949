import {
  type CustomFilterProps,
  CustomFilterWrapper,
} from '@common/components/Table';
import { SubjectsSelect } from '@modules/document/components/SubjectsSelect';

type InfiniteScrollSelectProps = Pick<
  CustomFilterProps,
  'model' | 'onModelChange'
>;

export const SubjectCustomFilter = ({
  model,
  onModelChange,
}: InfiniteScrollSelectProps) => {
  return (
    <CustomFilterWrapper>
      <SubjectsSelect
        placeholder="Select a subject"
        value={model || []}
        onChange={(value) => {
          onModelChange(value);
        }}
      />
    </CustomFilterWrapper>
  );
};
