import { gql } from '@apollo/client';
import { message as notify } from 'antd';
import { END, eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import graphqlClient from '@graphql/client';
import {
  BULK_DELETE_CUSTOM_FIELDS,
  EXPORT_CUSTOM_FIELDS,
  IMPORT_CUSTOM_FIELDS,
} from '@modules/custom-field/actions';
import { setIsReloadPage } from '@modules/organization/actions';

const CUSTOM_FIELDS_PROCESSED = gql`
  subscription onCustomFieldsProcessed($organizationId: String!) {
    customFieldsProcessed(organizationId: $organizationId)
  }
`;

function* customFieldsProcessed() {
  const organizationId = yield select((state) => state.organization.active.id);

  try {
    const customFieldChannel = yield call(() =>
      eventChannel((emit) => {
        const subscription = graphqlClient
          .subscribe({
            query: CUSTOM_FIELDS_PROCESSED,
            variables: {
              organizationId,
            },
          })
          .subscribe({
            next(data) {
              emit(data);
              emit(END);
            },
          });

        const unsubscribe = () => {
          subscription.unsubscribe();
        };

        return unsubscribe;
      }),
    );

    // eslint-disable-next-line fp/no-loops
    while (true) {
      try {
        const payload = yield take(customFieldChannel);

        yield fork(handleCustomFieldsProcessedEvents, payload);
      } catch (err) {
        console.error('Socket error:', err);
      }
    }
  } catch (err) {
    console.error('Saga error:', err);
  } finally {
    if (yield cancelled()) {
      yield fork(customFieldsProcessed);
    }
  }
}

function* handleCustomFieldsProcessedEvents({ data }) {
  switch (data?.customFieldsProcessed?.type) {
    case 'export:custom:fields:success': {
      yield notify.success(
        'Custom field(s) successfully exported and sent to your email.',
      );
      break;
    }
    case 'export:custom:fields:fail': {
      yield notify.error('Custom fields failed to export.');
      break;
    }
    case 'import:custom:fields:success': {
      yield notify.success('Custom fields were successfully imported.');
      yield put(setIsReloadPage(true));
      break;
    }
    case 'import:custom:fields:fail': {
      yield notify.error('Custom fields failed to import.');
      break;
    }
    case 'bulk:delete:custom:fields:success': {
      notify.success('Bulk deletion of custom fields complete.');
      yield put(setIsReloadPage(true));
      break;
    }
    case 'bulk:delete:custom:fields:fail': {
      yield notify.error('Bulk deletion of custom fields failed.');
      break;
    }
    default:
      return;
  }
}

function* ruleSagas() {
  yield all([
    takeLatest(BULK_DELETE_CUSTOM_FIELDS, customFieldsProcessed),
    takeLatest(IMPORT_CUSTOM_FIELDS, customFieldsProcessed),
    takeLatest(EXPORT_CUSTOM_FIELDS, customFieldsProcessed),
  ]);
}

export default ruleSagas;
