import { Modal, Typography } from 'antd';
import type { EditorState } from 'draft-js';

import FullWidthSpace from '@common/components/FullWidthSpace';
import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  isEditorStateBlank,
  setInitialEditorStateFromHTML,
} from '@common/components/RichTextEditor/helpers';
import { useState } from 'react';

type NoteModalProps = {
  isEdit?: boolean;
  defaultValue?: string;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (param: string) => void;
};

export const NoteModal = ({
  isEdit,
  defaultValue,
  isSubmitting,
  onClose,
  onSubmit,
}: NoteModalProps) => {
  const [noteEditorState, setNoteEditorState] = useState<EditorState>(
    defaultValue
      ? setInitialEditorStateFromHTML(defaultValue)
      : getEmptyEditorState(),
  );

  const handleOnSubmit = () => {
    const note = isEditorStateBlank(noteEditorState)
      ? ''
      : getEditorHTMLContent(noteEditorState);

    onSubmit(note);
  };

  return (
    <Modal
      open
      title={`${isEdit ? 'Edit' : 'Add'} Note`}
      centered
      okText={isEdit ? 'Edit' : 'Add'}
      cancelText="Cancel"
      onCancel={onClose}
      onOk={handleOnSubmit}
      okButtonProps={{ loading: isSubmitting }}
    >
      <FullWidthSpace size="small" direction="vertical">
        <Typography.Text>Note:</Typography.Text>
        <RichTextEditor
          autofocus
          isValid
          minHeight={32}
          editorState={noteEditorState}
          placeholder="Add note description..."
          onChange={(e: EditorState) => setNoteEditorState(e)}
        />
        <Typography.Text type="secondary">
          This note will be visible on the request record page
        </Typography.Text>
      </FullWidthSpace>
    </Modal>
  );
};
