import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  FETCH_EMAIL_TEMPLATE,
  FETCH_EMAIL_TEMPLATES,
  UPDATE_EMAIL_TEMPLATE,
} from '../actions';

const initialState = {
  data: {},
};

const EmailTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_EMAIL_TEMPLATES}_SUCCESS`: {
      const emailTemplates = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', emailTemplates, state);
    }
    case `${FETCH_EMAIL_TEMPLATE}_SUCCESS`: {
      const emailTemplate = getGraphqlPayload(action);

      return R.assocPath(['data', emailTemplate?._id], emailTemplate, state);
    }
    case `${CREATE_EMAIL_TEMPLATE}_SUCCESS`:
    case `${UPDATE_EMAIL_TEMPLATE}_SUCCESS`: {
      const emailTemplate = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assocPath(['data', emailTemplate._id], emailTemplate, state);
    }
    case `${DELETE_EMAIL_TEMPLATE}_SUCCESS`: {
      const emailTemplate = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.dissocPath(['data', emailTemplate._id], state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default EmailTemplateReducer;
