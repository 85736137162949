import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const FilePillow = ({ name, downloadUrl, onRemove }) => (
  <Wrapper>
    <FileOutlined />
    <Tooltip title={name} mouseEnterDelay={0.5}>
      <a target="_blank" rel="noopener noreferrer" href={downloadUrl}>
        {name}
      </a>
    </Tooltip>
    {onRemove && (
      <FilePillow.RemoveIcon onClick={onRemove}>
        <CloseOutlined />
      </FilePillow.RemoveIcon>
    )}
  </Wrapper>
);

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 28px;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.white};

  a {
    margin-left: 5px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.lightBlack};
    }
  }
`;

FilePillow.RemoveIcon = styled.div`
  margin-top: 7px;
  margin-left: 10px;
  font-size: 12px;
`;

export default FilePillow;
