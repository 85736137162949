import { message as notify } from 'antd';
import { all, call, putResolve, takeLatest } from 'redux-saga/effects';

import { SET_AUTHENTICATED_APP_READY } from '@modules/app/actions';

import { trackEvent } from '@common/utils/track-helpers';
import {
  CREATE_REPORT,
  CREATE_SCHEDULED_REPORT,
  DELETE_REPORT,
  DELETE_SCHEDULED_REPORT,
  SEND_SCHEDULED_REPORT,
  UPDATE_REPORT,
  UPDATE_SCHEDULED_REPORT,
  fetchReports,
} from '../actions';

function* fetchReportsSaga() {
  yield putResolve(fetchReports());
}

function* successCreateReportSaga(payload) {
  yield call(trackEvent, 'User created a report');
}

function* successUpdateReportSaga(payload) {
  yield call(trackEvent, 'User edited a report');
}

function* successDeleteReportSaga(payload) {
  yield call(trackEvent, 'User deleted a report');
}

function successCreateScheduleSaga() {
  notify.success(`Schedule successfully created`);
}

function successUpdateScheduleSaga() {
  notify.success(`Schedule successfully updated`);
}

function successDeleteScheduleSaga() {
  notify.success(`Schedule successfully deleted`);
}

function successSendScheduleSaga() {
  notify.success(`Schedule successfully sent`);
}

function catchScheduleErrorSaga({ error }) {
  error.map(({ message }) => notify.error(message));
}

function* reportSagas() {
  yield all([
    takeLatest(SET_AUTHENTICATED_APP_READY, fetchReportsSaga),
    takeLatest(`${CREATE_REPORT}_SUCCESS`, successCreateReportSaga),
    takeLatest(`${UPDATE_REPORT}_SUCCESS`, successUpdateReportSaga),
    takeLatest(`${DELETE_REPORT}_SUCCESS`, successDeleteReportSaga),
    takeLatest(`${DELETE_REPORT}_SUCCESS`, successDeleteReportSaga),
    takeLatest(`${CREATE_SCHEDULED_REPORT}_SUCCESS`, successCreateScheduleSaga),
    takeLatest(`${UPDATE_SCHEDULED_REPORT}_SUCCESS`, successUpdateScheduleSaga),
    takeLatest(`${DELETE_SCHEDULED_REPORT}_SUCCESS`, successDeleteScheduleSaga),
    takeLatest(`${SEND_SCHEDULED_REPORT}_SUCCESS`, successSendScheduleSaga),
    takeLatest(
      [
        `${CREATE_SCHEDULED_REPORT}_FAIL`,
        `${UPDATE_SCHEDULED_REPORT}_FAIL`,
        `${DELETE_SCHEDULED_REPORT}_FAIL`,
        `${SEND_SCHEDULED_REPORT}_FAIL`,
      ],
      catchScheduleErrorSaga,
    ),
  ]);
}

export default reportSagas;
