import { ListCell } from '@common/components/Table';
import { Tag } from '@modules/party';

type TagsProps = {
  tags: Record<string, any>[];
};

export const Tags = ({ tags }: TagsProps) => {
  return (
    <ListCell>
      {tags.map((tag) => (
        <Tag
          key={tag._id}
          label={tag?.name}
          color={tag?.color}
          expiresAt={tag.expiresAt}
        />
      ))}
    </ListCell>
  );
};
