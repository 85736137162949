import { MinusOutlined, WarningFilled } from '@ant-design/icons';

import CheckIcon from '@common/icons/CheckIcon';
import MarkIcon from '@common/icons/MarkIcon';
import PendingReviewIcon from '@common/icons/PendingReviewIcon';
import { AttributeType } from '@modules/compliance-profile/constants';
import WaiveIcon from '@modules/document/components/icons/WaiveIcon';
import { colorScheme } from '@theme/patterns/colors';
import { RequirementComplianceStatus } from '@trustlayer/common';

export const ConnectedRequirementsAttributeIds = {
  NPN: 'licensesAndPermitsInsuranceLicenseNationalProducerNumber',
};

export const RequirementAttributeType = {
  ...AttributeType,
};

export const namespace = 'REQUIREMENT';
export const STATE_KEY = 'requirement';

export const RequirementStatus = {
  Compliant: RequirementComplianceStatus.Compliant,
  NonCompliant: RequirementComplianceStatus.NonCompliant,
  Waived: RequirementComplianceStatus.Waived,
  Overridden: RequirementComplianceStatus.Overridden,
  Invalidated: RequirementComplianceStatus.Invalidated,
  Expiring: 'expiring',
  Expired: 'expired',
  Review: 'review',
  Locked: 'locked',
} as const;

export const RequirementIconByStatus = {
  [RequirementStatus.NonCompliant]: <MarkIcon />,
  [RequirementStatus.Compliant]: <CheckIcon />,
  [RequirementStatus.Invalidated]: (
    <WarningFilled style={{ color: colorScheme.red.value }} />
  ),
  [RequirementStatus.Waived]: <WaiveIcon />,
  [RequirementStatus.Overridden]: <WaiveIcon />,
  [RequirementStatus.Expiring]: <MarkIcon />,
  [RequirementStatus.Expired]: <MarkIcon />,
  [RequirementStatus.Review]: <PendingReviewIcon />,
  [RequirementStatus.Locked]: <MinusOutlined />,
};

export const RequirementsWaiverStatus = {
  WithWaived: 'withWaived',
  WithoutWaived: 'withoutWaived',
};

export const RequirementsOverrideStatus = {
  WithOverride: 'withOverride',
  WithoutOverride: 'withoutOverride',
};

export const RequirementsStatusLabelMap = {
  [RequirementsWaiverStatus.WithWaived]: 'With waived requirements',
  [RequirementsWaiverStatus.WithoutWaived]: 'Without waived requirements',
  [RequirementsOverrideStatus.WithOverride]: 'With overridden requirements',
  [RequirementsOverrideStatus.WithoutOverride]:
    'Without overridden requirements',
};

export const NRExplanation = `has a status of Not Rated (NR).
The NR status is assigned to insurance companies that are not rated; may include previously
rated insurance companies or insurance companies that have never been rated by AM Best.`;

export const FinancialSizeCategoryData = {
  I: { min: 0, max: 1 },
  II: { min: 1, max: 2 },
  III: { min: 2, max: 5 },
  IV: { min: 5, max: 10 },
  V: { min: 10, max: 25 },
  VI: { min: 25, max: 50 },
  VII: { min: 50, max: 100 },
  VIII: { min: 100, max: 250 },
  IX: { min: 250, max: 500 },
  X: { min: 500, max: 750 },
  XI: { min: 750, max: 1000 },
  XII: { min: 1000, max: 1250 },
  XIII: { min: 1250, max: 1500 },
  XIV: { min: 1500, max: 2000 },
  XV: { min: 2000, max: 1 },
};

export const RatingImplication = {
  P: 'Positive',
  N: 'Negative',
  D: 'Developing',
};

export const RatingAction = {
  A: 'Affirmed',
  D: 'Downgraded',
  F: 'Assigned',
  N: 'No Change After Receipt of Annual Financial Statement',
  O: 'Other',
  P: 'Upgraded',
  U: 'Under Review',
  W: 'Withdraw',
};

export const ratingColors = {
  level_1: {
    borderColor: '#B7EB8F',
    backgroundColor: '#F6FFED',
    textColor: '#52C41A',
  },
  level_2: {
    borderColor: '#91D5FF',
    backgroundColor: '#E6F7FF',
    textColor: '#1890FF',
  },
  level_3: {
    borderColor: '#FFBB96',
    backgroundColor: '#FFF2E8',
    textColor: '#FA541C',
  },
  level_4: {
    borderColor: '#FFA39E',
    backgroundColor: '#FFF1F0',
    textColor: '#F5222D',
  },
  level_5: {
    borderColor: '#D9D9D9',
    backgroundColor: '#FAFAFA',
    textColor: '#262626',
  },
};
