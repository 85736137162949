import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { MessageType, SenderType } from '../../constants';
import { AttachmentList } from '../AttachmentList';
import EventContent from '../EventContent';
import { EventLogsPopover } from '../EventLogsPopover';
import { IssueList } from '../IssueList';
import MessageStatusIcon from '../MessageStatusIcon';

import { TL_BOT_PROFILE } from './constants';

const INFO_POPOVER_DENY_TYPES_LIST = [
  MessageType.ContactRemovedFromConversation,
  MessageType.ContactDeleted,
  MessageType.ContactEmailUpdatedResent,
  MessageType.MessageResent,
];

export const ConversationEvent = ({
  conversationEvent = {},
  senderData,
  conversationRequest,
  onSaveInDocuments,
  onDownloadAttachment,
  onResolveIssue,
  onIgnoreIssue,
}) => {
  const {
    type,
    outputHtml,
    request,
    senderType,
    status,
    attachments,
    sendgridEvents,
    recipients,
    createdAt,
    senderContactId,
    issues,
    data,
  } = conversationEvent;

  const isTrustLayerBot = senderType === SenderType.TrustLayer;

  const senderName = isTrustLayerBot
    ? TL_BOT_PROFILE.label
    : senderData?.name || '';

  const isEventLogsPopoverVisible =
    !INFO_POPOVER_DENY_TYPES_LIST.includes(type);

  return (
    <StyledWrapper>
      <StyledAvatarWrapper>
        <StyledAvatar
          data-cy="conversationEventAvatar"
          size={34}
          icon={<UserOutlined data-cy="userIcon" />}
          src={
            senderData?.avatar || (isTrustLayerBot && TL_BOT_PROFILE.avatarSrc)
          }
          $isTransparent={isTrustLayerBot}
        />
      </StyledAvatarWrapper>
      <StyledMain>
        <section>
          <div>
            {senderName && <StyledHeaderText>{senderName}</StyledHeaderText>}
            {senderData?.email && (
              <StyledHeaderSubtext>{senderData.email}</StyledHeaderSubtext>
            )}
          </div>

          <EventContent
            type={type || 'request'}
            outputHtml={outputHtml}
            conversationRequest={conversationRequest}
            requestFromMessage={request}
            eventData={data}
          />

          {Boolean(issues?.length) && (
            <StyledIssueList
              onIgnoreClik={onIgnoreIssue}
              onResolveClik={onResolveIssue}
              issues={issues}
            />
          )}

          {Boolean(attachments?.length) && (
            <StyledAttachmentList
              onSaveClick={onSaveInDocuments}
              onDownloadClick={onDownloadAttachment}
              attachments={attachments}
            />
          )}
        </section>

        <StyledQuickInfos>
          <MessageStatusIcon type={status} />

          {createdAt && (
            <StyledTime data-cy="messageDate">
              {moment(createdAt).format('LT')}
            </StyledTime>
          )}

          {isEventLogsPopoverVisible && (
            <EventLogsPopover
              senderEmail={senderData?.email}
              isSenderLogVisible={senderContactId}
              eventDate={createdAt}
              recipients={recipients}
              logs={sendgridEvents}
            >
              <StyledInfoIcon data-cy="conversationEventInfoIcon" />
            </EventLogsPopover>
          )}
        </StyledQuickInfos>
      </StyledMain>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.section`
  display: flex;
  padding: 10px 0 10px 10px;

  & > section {
    width: 100%;
  }
`;

const StyledAvatarWrapper = styled.div`
  margin-right: 10px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${({ $isTransparent }) =>
    $isTransparent ? 'transparent' : '#697b8d'};
`;

const StyledHeaderText = styled.span`
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledHeaderSubtext = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
`;

const StyledMain = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledAttachmentList = styled(AttachmentList)`
  margin-top: 6px;
`;

const StyledIssueList = styled(IssueList)`
  margin-top: 5px;
`;

const StyledQuickInfos = styled.section`
  white-space: nowrap;
`;

const StyledTime = styled.span`
  font-weight: 600;
  font-size: 12px;
  margin-right: 7px;
  margin-left: 4px;
`;

const StyledInfoIcon = styled(InfoCircleOutlined)`
  color: #999;
`;
