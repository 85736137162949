import { Form, Input } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';

import DatePicker from '@common/components/DatePicker';
import RichTextEditor from '@common/components/RichTextEditor';
import { defaultDateFormats } from '@common/utils/filter-helpers';

const GeneralTabForm = ({
  form,
  project,
  dates,
  setDates,
  descriptionEditorState,
  onDescriptionChange,
  visible,
}) => {
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [form, visible]);

  useEffect(() => {
    if (visible && project) {
      form.setFieldsValue({ name: project.name });
    }
  }, [form, project, visible]);

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 },
  };

  return (
    <GeneralTabForm.Form
      data-cy="generalProjectForm"
      {...layout}
      form={form}
      name="generalTabForm"
      layout="horizontal"
    >
      <Form.Item
        name="name"
        label="Project name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input
          data-cy="saveProjectInputName"
          autoFocus
          placeholder="Enter project name..."
        />
      </Form.Item>
      <Form.Item label="Description">
        <RichTextEditor
          isValid
          minHeight={50}
          placeholder="Enter description..."
          editorState={descriptionEditorState}
          onChange={onDescriptionChange}
        />
      </Form.Item>
      <Form.Item label="Dates">
        <DatePicker.RangePicker
          data-cy="projectDates"
          format={defaultDateFormats}
          allowEmpty={[true, true]}
          value={dates}
          onChange={setDates}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </GeneralTabForm.Form>
  );
};

GeneralTabForm.Form = styled(Form)`
  .ant-form-item-label {
    padding: 0;
  }
`;

export default GeneralTabForm;
