import { Button } from 'antd';
import styled from 'styled-components';

type TabButtonsProps = {
  tabs: Record<string, { key: string; name: string }>;
  activeTab: string;
  onClick?: (tabKey: string) => void;
};

export const TabButtons = ({ tabs, activeTab, onClick }: TabButtonsProps) => {
  return (
    <>
      {Object.values(tabs).map((tab) => (
        <StyledTabButton
          key={tab.key}
          type="text"
          onClick={() => onClick?.(tab.key)}
          $active={tab.key === activeTab}
        >
          {tab.name}
        </StyledTabButton>
      ))}
    </>
  );
};

const StyledTabButton = styled(Button)<{ $active: boolean }>`
  box-shadow: none !important;

  ${({ $active }) =>
    $active &&
    `
      background: var(--Daybreak-Blue-blue-1, #e6f4ff);
      color: var(--Daybreak-Blue-blue-6, #1677ff);
      pointer-events: none;
    `}
`;
