import { Empty, Input, Popover, Tooltip } from 'antd';
import { Fragment, useMemo, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { getTextSnippets } from '../selectors';

const SelectSnippetPopover = ({
  visible,
  setVisible,
  chooseSnippet,
  children,
}) => {
  const { organizationSlugifyName } = useParams();
  const navigate = useNavigate();

  const [filter, setFilter] = useState('');

  const textSnippets = useSelector(getTextSnippets);

  const filteredTextSnippets = useMemo(
    () =>
      textSnippets.filter(
        (textSnippet) =>
          textSnippet.content.indexOf(filter) > -1 ||
          textSnippet.title.indexOf(filter) > -1,
      ),
    [filter, textSnippets],
  );

  return (
    <>
      <GlobalStyle />
      <Popover
        title={
          <StyledHeader>
            <p>Select a text snippet</p>
            <StyledHotKeysTip>Ctrl + .</StyledHotKeysTip>
          </StyledHeader>
        }
        content={
          <div>
            <StyledFilterContainer>
              <Input
                placeholder="Filter by name..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </StyledFilterContainer>
            <StyledContent>
              {filteredTextSnippets.length > 0 ? (
                filteredTextSnippets.map((snippet, index) => (
                  <Fragment key={snippet.id}>
                    <GlobalHotKeys
                      allowChanges={true}
                      keyMap={
                        index < 9 ? { chooseSnippet: `ctrl+${index + 1}` } : {}
                      }
                      handlers={{
                        chooseSnippet: (e) =>
                          chooseSnippet(filteredTextSnippets[e.key - 1] || {}),
                      }}
                    >
                      <StyledListItemContainer
                        onClick={() => chooseSnippet(snippet)}
                      >
                        <StyledSnippetTwoLineInfo>
                          <p>
                            {
                              {
                                private: (
                                  <Tooltip title="Your private Snippet">
                                    <UserOutlined />
                                  </Tooltip>
                                ),
                                organization: (
                                  <Tooltip title="Workspace snippet">
                                    <TeamOutlined />
                                  </Tooltip>
                                ),
                              }[snippet.visibility]
                            }{' '}
                            {snippet.title}
                          </p>
                          <StyledContentSnippet>
                            {snippet.content.replace(/<[^>]*>?/gm, '')}
                          </StyledContentSnippet>
                        </StyledSnippetTwoLineInfo>
                        {index < 9 && (
                          <StyledHotKeysTip>
                            Ctrl + {index + 1}
                          </StyledHotKeysTip>
                        )}
                      </StyledListItemContainer>
                    </GlobalHotKeys>
                  </Fragment>
                ))
              ) : (
                <div>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No text snippets to show"
                  />
                </div>
              )}
            </StyledContent>
            <StyledCreateNewSnippetContainer>
              <span
                onClick={() =>
                  navigate(
                    `/${organizationSlugifyName}/settings/text-snippets`,
                    {
                      state: { isActiveModal: true },
                    },
                  )
                }
              >
                Create a new snippet
              </span>
            </StyledCreateNewSnippetContainer>
          </div>
        }
        trigger="click"
        open={visible}
        onOpenChange={(visible) => setVisible(visible)}
        overlayClassName="chose-text-snippet-popover"
      >
        {children}
      </Popover>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  .chose-text-snippet-popover {
    width: 292px;

    .ant-popover-title {
      padding: 0;
      border-bottom: none;
    }

    .ant-popover-inner {
      padding: 0;
    }
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  padding: 0 10px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 4px 4px 0 0;
  background-color: ${(props) => props.theme.colors.ghostWhite};

  p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.grayishBlue};
  }
`;

const StyledFilterContainer = styled.div`
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
`;

const StyledContent = styled.div`
  min-height: 142px;
  max-height: 300px;
  overflow: auto;
`;

const StyledListItemContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;

  &:hover {
    background-color: #e6f7ff;
  }
`;

const StyledSnippetTwoLineInfo = styled.div`
  width: 70%;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
`;

const StyledContentSnippet = styled.div`
  display: block;
  width: 100%;
  color: rgba(0, 0, 0, 0.65);
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  text-overflow: ellipsis;
`;

const StyledCreateNewSnippetContainer = styled.div`
  border-top: 1px solid #d9d9d9;
  padding: 10px;

  span {
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledHotKeysTip = styled.div`
  height: 19px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.ghostWhite};
  color: ${(props) => props.theme.colors.grayishBlue};
  border-radius: 3px;
  font-size: 10px;
  font-family: monospace;
`;

export default SelectSnippetPopover;
