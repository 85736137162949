import {
  organizationPlaceholders,
  partyPlaceholders,
} from '@common/constants/placeholders';

export const STATE_KEY = 'request';
export const namespace = 'REQUEST';

const LOCAL_STORAGE_PREFIX_KEY = 'request';
export const LOCAL_STORAGE_SUBSCRIBE_NOTIFICATIONS_KEY = `${LOCAL_STORAGE_PREFIX_KEY}:subscribe_to_notifications`;

export const PROJECT_STATUS = {
  NonCompliant: 'non_compliant',
  Compliant: 'compliant',
  Expiring: 'expiring',
} as const;

export const REQUEST_PLACEHOLDERS = [
  partyPlaceholders,
  organizationPlaceholders,
].reduce<
  {
    name: string;
    description: string;
  }[]
>((res, group) => {
  return [
    ...res,
    ...group.placeholders.map((placeholder) => ({
      name: placeholder.region,
      description: placeholder.name,
    })),
  ];
}, []);
