import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const MARK_PROJECT_AS_VIEWED = `${namespace}/MARK_PROJECT_AS_VIEWED`;

const MARK_PROJECT_AS_VIEWED_MUTATION = gql`
  mutation markProjectAsViewed($projectId: String!) {
    markProjectAsViewed(projectId: $projectId)
  }
`;

export const markProjectAsViewed = (projectId) => ({
  type: MARK_PROJECT_AS_VIEWED,
  payload: {
    key: 'markProjectAsViewed',
    graphql: {
      mutation: MARK_PROJECT_AS_VIEWED_MUTATION,
      variables: {
        projectId,
      },
    },
  },
});
