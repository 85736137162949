import { Button } from 'antd';

import { NoteCard } from '@common/components/NoteCard';
import { useQuery } from '@graphql/hooks';
import { SidebarError } from '@modules/primary-records/components/SideBarError';

import { ADDITIONAL_NOTES_QUERY } from './queries';

export const SidebarNotes = ({ recordId }: { recordId: string }) => {
  const { data, loading, error, refetch } = useQuery(ADDITIONAL_NOTES_QUERY, {
    variables: { primaryRecordInput: { id: recordId } },
    skip: !recordId,
    notifyOnNetworkStatusChange: true,
  });

  const additionalNotes = data?.primaryRecord.additionalNotes;

  const handleOnAddNote = () => {
    console.log('add note');
  };

  const handleEditNote = () => {
    console.log('edit note');
  };

  const handleDeleteNote = () => {
    console.log('delete note');
  };

  if (error) {
    return (
      <SidebarError
        sidebarLabel="Additional Notes"
        onActionClick={() => refetch()}
      />
    );
  }

  if (loading) {
    return <NoteCard.Skeleton />;
  }

  return (
    <>
      {additionalNotes?.[0] ? (
        <NoteCard
          note={additionalNotes[0]}
          onDelete={handleDeleteNote}
          onEdit={handleEditNote}
        />
      ) : (
        <Button disabled block type="dashed" onClick={handleOnAddNote}>
          Add note
        </Button>
      )}
    </>
  );
};
