import { REHYDRATE } from 'redux-persist';

import {
  SET_APP_VERSION_HARD_REFRESH,
  SET_AUTHENTICATED_APP_READY,
  SET_LATEST_APP_VERSION,
} from '../actions';

export const initialState = {
  isAuthenticatedAppReady: false,
  isPersistRehydrated: false,
  versions: {
    currentVersion: import.meta.env.VITE_APP_PACKAGE_VERSION,
  },
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED_APP_READY: {
      return {
        ...state,
        isAuthenticatedAppReady: true,
      };
    }
    case REHYDRATE: {
      return {
        ...state,
        isPersistRehydrated: true,
      };
    }
    case SET_LATEST_APP_VERSION: {
      return {
        ...state,
        versions: {
          ...state.versions,
          latestVersion: action.payload,
        },
      };
    }
    case SET_APP_VERSION_HARD_REFRESH: {
      return {
        ...state,
        versions: {
          ...state.versions,
          hardrefresh: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default AppReducer;
