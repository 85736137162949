import { SearchOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Table,
  Tabs,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  getEditorHTMLContent,
  getEmptyEditorState,
  getUpdatedEditorState,
} from '@common/components/RichTextEditor/helpers';
import Spinner from '@common/components/Spinner';
import TagLabel from '@common/components/TagLabel';
import { getRequirementsByRules } from '@common/utils/compliance-attributes-helpers';
import {
  createComplianceProfile,
  fetchComplianceProfile,
  updateComplianceProfile,
} from '@modules/compliance-profile/actions';
import { ComplianceProfileContext } from '@modules/compliance-profile/constants';
import { getComplianceProfileById } from '@modules/compliance-profile/selectors';
import { fetchProjectCustomFields } from '@modules/custom-field/actions';
import { CustomFieldType } from '@modules/custom-field/constants';
import ConnectivityBadge from '@modules/integration/components/ConnectivityBadge';
import { fetchPartyToProjectModal } from '@modules/party/actions';
import { overrideAttrs } from '@modules/project/utils/overrideAttrs';
import { mergeByAttributeId } from '@modules/requirement/utils/requirement-helpers';
import { search } from '@modules/search/actions';
import { getGraphqlPayload, getGraphqlResponse } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import {
  createProject,
  fetchProjectById,
  openProjectUpdatesWSSubscription,
  updateProject,
} from '../actions';
import CustomFieldsTab from '../components/CustomFieldsTab';
import ProjectRequirementsView from '../components/ProjectRequirementsView';
import ProjectAttachmentsTabContainer from '../containers/ProjectAttachmentsTabContainer';
import ProjectPartiesTabContainer from '../containers/ProjectPartiesTabContainer';
import GeneralTabForm from '../forms/GeneralTabForm';

const { TabPane } = Tabs;

const TabKeys = {
  General: {
    name: 'Overview',
    key: 'general',
  },
  CustomFields: {
    name: 'Custom fields',
    key: 'customFields',
  },
  Parties: {
    name: 'Parties',
    key: 'parties',
  },
  Requirements: {
    name: 'Compliance profiles',
    key: 'requirements',
  },
  ProjectAttachments: {
    name: 'Attachments',
    key: 'projectAttachments',
  },
};

const omittedFields = [
  '_id',
  'complianceProfile',
  'partiesCount',
  'projectsCount',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'deletedBy',
];

const getUTCMomentDate = (momentDate) => {
  if (!momentDate || !moment.isMoment(momentDate)) {
    return momentDate;
  }
  const plainStartDate = momentDate.format('YYYY-MM-DD');
  return moment.utc(plainStartDate).format();
};

const SaveProjectModal = ({
  projectId,
  initParties = [],
  visible,
  onClose,
  getComplianceProfileById,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isModalDataLoading, setIsModalDataLoading] = useState();
  const [project, setProject] = useState(null);
  const [tab, setTab] = useState(TabKeys.General.key);
  const [projectDescriptionEditorState, setProjectDescriptionEditorState] =
    useState(getEmptyEditorState());
  const [dates, setDates] = useState([null, null]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [parties, setParties] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);

  // Object where key is the party id and value is the original compliance profile of the project profile associated to the party
  const [partyProfileAssociation, setPartyProfileAssociation] = useState({});

  // profiles got from the fetched project data
  const [profilesMappings, setProfilesMappings] = useState([]);

  const [customFields, setCustomFields] = useState([]);
  const [customFieldsRequirements, setCustomFieldsRequirements] = useState([]);
  const [customFieldsDocumentChecklists, setCustomFieldsDocumentChecklists] =
    useState([]);

  // array of all the profile ids, both project and original of the same party
  const [localComplianceProfilesIds, setLocalComplianceProfilesIds] = useState(
    [],
  );

  /*
  Array of all the original profiles extended with:
  - rules from custom fields; if a rule is already in the profile, custom field isActive wins
  - rules from project: all the isActive statuses are taken and replaced to the ones of the original compliance profile
  */
  const [localComplianceProfiles, setLocalComplianceProfiles] = useState([]);
  const [profilesToFetch, setProfilesToFetch] = useState([]);
  const [generalTabForm] = Form.useForm();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const dispatch = useDispatch();

  const [fetchingProfiles, setFetchingProfiles] = useState(new Set());
  const addFetchingProfile = (id) =>
    setFetchingProfiles((prevSet) => new Set(prevSet).add(id));
  const removeFetchingProfile = (id) =>
    setFetchingProfiles((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(id);
      return newSet;
    });
  const isFetchingProfiles = fetchingProfiles.size > 0;

  const getMatchingProjectProfile = useCallback(
    (profileId) => {
      const altProfileId = R.compose(
        R.prop('projectComplianceProfile'),
        R.find((el) => el.originalComplianceProfile === profileId),
      )(profilesMappings);

      return altProfileId ? getComplianceProfileById(altProfileId) : null;
    },
    [getComplianceProfileById, profilesMappings],
  );

  /*
  Extend a global profile by:
  1. merging rules from custom fields; if a rule is already in the profile, custom field isActive wins
  2. rules from project: all the isActive statuses are taken and replaced to the ones of the global profile
*/
  const extendProfile = useCallback(
    (globalProfile) => {
      const projectProfile = getMatchingProjectProfile(
        R.prop('_id', globalProfile),
      );

      const globalRules = R.propOr([], 'rules', globalProfile);
      const globalChecklists = R.propOr(
        [],
        'documentChecklists',
        globalProfile,
      );

      const projectRules = R.propOr([], 'rules', projectProfile);
      const projectChecklists = R.propOr(
        [],
        'documentChecklists',
        projectProfile,
      );

      const rules = R.map(
        (item) => {
          const found = R.find(
            (projectRule) => projectRule.attributeId === item.attributeId,
            projectRules,
          );

          return found ? { ...item, isActive: found.isActive } : item;
        },
        mergeByAttributeId(globalRules, customFieldsRequirements),
      );

      const documentChecklists = R.map(
        (item) => {
          const found = R.find(
            (projectChecklist) =>
              projectChecklist.attributeId === item.attributeId,
            projectChecklists,
          );

          return found ? { ...item, isActive: found.isActive } : item;
        },
        mergeByAttributeId(globalChecklists, customFieldsDocumentChecklists),
      );

      return { ...globalProfile, rules, documentChecklists };
    },
    [
      customFieldsDocumentChecklists,
      customFieldsRequirements,
      getMatchingProjectProfile,
    ],
  );

  const init = async () => {
    setLoading(true);
    const customFieldsRes = await dispatch(
      fetchProjectCustomFields({ first: 150 }),
    );
    /**
     *  TODO: Refactor this and get it with the selector and evaluate to move the entire logic to the
     *  TODO: CustomFieldsTab component
     */
    setCustomFields(getGraphqlResponse(customFieldsRes)?.nodes);
    setParties(initParties || []);

    if (projectId) {
      const projectRes = await dispatch(fetchProjectById(projectId));
      const projectData = getGraphqlPayload(projectRes);

      setProfilesMappings(R.propOr([], 'complianceProfiles', projectData));
      setProjectDescriptionEditorState(
        getUpdatedEditorState(projectData.description),
        projectDescriptionEditorState,
      );
      setDates([
        projectData.startDate ? moment.utc(projectData.startDate) : null,
        projectData.endDate ? moment.utc(projectData.endDate) : null,
      ]);
      setParties(projectData.parties);
      setAttachments(projectData.attachments);
      onSetSelectedCustomFields(projectData?.customFields?.nodes ?? []);
      setProject(projectData);
    }
    setLoading(false);
  };

  const reset = () => {
    setLoading(false);
    setSubmitting(false);
    setProject(null);
    setTab(TabKeys.General.key);
    setProjectDescriptionEditorState(getEmptyEditorState());
    setDates([null, null]);
    setSearchResult([]);
    setSearchValue('');
    setParties([]);
    setSelectedCustomFields([]);
    setPartyProfileAssociation({});
    setProfilesMappings([]);
    setCustomFields([]);
    setCustomFieldsRequirements([]);
    setCustomFieldsDocumentChecklists([]);
    setLocalComplianceProfiles([]);
    setLocalComplianceProfilesIds([]);
    setUploadFiles([]);
    setAttachments([]);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (visible) {
      init();
    } else {
      reset();
    }
  }, [visible, projectId]);

  // * Keep an updated list of all compliance profiles ids
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    setLocalComplianceProfilesIds(
      R.compose(
        R.reject((id) => R.isNil(id)),
        R.uniq,
        R.flatten,
        R.concat(
          R.map(
            (party) =>
              R.path(['partyComplianceProfile', 'complianceProfile'], party),
            parties,
          ),
        ),
        R.concat(R.values(partyProfileAssociation)),
        R.map(R.values),
      )(profilesMappings),
    );
  }, [
    dispatch,
    getComplianceProfileById,
    partyProfileAssociation,
    profilesMappings,
    parties,
  ]);

  // * Fetch compliance profiles as soon as a new id appears in `localComplianceProfilesIds`
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    R.compose(
      R.forEach(async (id) => {
        setProfilesToFetch((profilesToFetch) =>
          R.concat(profilesToFetch, [id]),
        );
        addFetchingProfile(id);
        await dispatch(fetchComplianceProfile(id));
        removeFetchingProfile(id);
      }),
      R.reject(
        (id) => getComplianceProfileById(id) || R.includes(id, profilesToFetch),
      ),
    )(localComplianceProfilesIds);
  }, [dispatch, getComplianceProfileById, localComplianceProfilesIds]);

  // * Update party profile association when parties change
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    setPartyProfileAssociation(
      parties.reduce((acc, val) => {
        const profileId = R.path(
          ['partyComplianceProfile', 'complianceProfile'],
          val,
        );

        if (!profileId) return acc;

        const originalProfileId = R.compose(
          R.prop('originalComplianceProfile'),
          R.find((el) => el.projectComplianceProfile === profileId),
        )(profilesMappings);

        return R.assoc(val._id, originalProfileId || profileId, acc);
      }, {}),
    );
  }, [parties]);

  // * Keep local compliance profiles in sync
  useEffect(() => {
    setLocalComplianceProfiles(
      R.compose(
        R.map(extendProfile),
        R.filter(
          (profile) => profile && R.propEq('context', 'global', profile),
        ),
        R.map((profileId) => getComplianceProfileById(profileId)),
      )(localComplianceProfilesIds),
    );
  }, [extendProfile, getComplianceProfileById, localComplianceProfilesIds]);

  const onChangeHandle = useCallback(
    (value) => {
      setSearchValue(value);

      if (value) {
        dispatch(
          search({
            type: 'parties',
            query: value,
            options: { limit: 10 },
          }),
        ).then((payload) => {
          setSearchResult(
            R.compose(
              R.map((party) => ({
                ...party,
                type: {
                  name: party.type,
                },
              })),
              R.defaultTo([]),
              getGraphqlPayload,
            )(payload),
          );
        });
      } else {
        setSearchResult([]);
      }
    },
    [dispatch],
  );

  const options = searchResult.map((party) => ({
    value: party._id,
    label: (
      <span>
        {party.name}
        {!party.isActive && (
          <SaveProjectModal.InactiveParty>
            <TagLabel>inactive</TagLabel>
          </SaveProjectModal.InactiveParty>
        )}
      </span>
    ),
  }));

  const onSelectParty = async (value) => {
    setIsModalDataLoading(true);
    if (!parties.map((party) => party._id).includes(value)) {
      const payload = await dispatch(fetchPartyToProjectModal(value));
      const party = getGraphqlPayload(payload);
      const complianceProfile = R.path(
        ['partyComplianceProfile', 'complianceProfile'],
        party,
      );

      if (!getComplianceProfileById(complianceProfile) && complianceProfile) {
        await dispatch(fetchComplianceProfile(complianceProfile));
      }

      setParties(R.prepend(party, parties));
    }
    setIsModalDataLoading(false);
  };

  const onChangeProfileAssociation = (updatedAssociation) => {
    setPartyProfileAssociation({
      ...partyProfileAssociation,
      ...updatedAssociation,
    });
  };

  const deleteParty = (_id) => {
    setParties(parties.filter((party) => party._id !== _id));
  };

  const onSetSelectedCustomFields = (customFields) => {
    setSelectedCustomFields(customFields);

    /**
     array of all the rules of all the custom fields, extended
    first, map through each custom field map each of its rule to replace
        - name, in case its a dropdown, from the key to the value of the dropdown
        - placeholder {{ value }} in case its a custom requirement
        - masterDocumentAttributeId to match the value of the custom field
     then, map each obtained rule to extend it with the relevant custom field information
     */
    const customFieldsRules = R.compose(
      R.map((rule) => R.omit(omittedFields, rule)),
      R.map((rule) => {
        const relevantFields = customFields.filter((customField) => {
          return R.pathOr(
            [],
            ['customField', 'complianceProfile', 'rules'],
            customField,
          )
            .map((el) => el.attributeId)
            .includes(rule.attributeId);
        });

        return R.assoc('customFields', relevantFields, rule);
      }),
      R.flatten,
      R.map(({ customField, value }) => {
        return R.pathOr([], ['complianceProfile', 'rules'], customField).map(
          (el) => overrideAttrs(el, customField, value),
        );
      }),
    )(customFields);
    setCustomFieldsRequirements(getRequirementsByRules(customFieldsRules));

    const customFieldsDocumentChecklists = R.compose(
      R.map((documentChecklist) => {
        const relevantFields = customFields.filter((customField) => {
          return R.pathOr(
            [],
            ['customField', 'complianceProfile', 'documentChecklists'],
            customField,
          )
            .map((el) => el.attributeId)
            .includes(documentChecklist.attributeId);
        });

        return R.assoc('customFields', relevantFields, documentChecklist);
      }),
      R.flatten,
      R.map(({ customField, value }) => {
        return R.pathOr(
          [],
          ['complianceProfile', 'documentChecklists'],
          customField,
        ).map((el) => overrideAttrs(el, customField, value));
      }),
    )(customFields);
    setCustomFieldsDocumentChecklists(customFieldsDocumentChecklists);
  };

  const onToggleCheckbox = (updatedProfile, group, checkedKeys = []) => {
    const updatedGroup = R.propOr([], group, updatedProfile).map((el) =>
      checkedKeys.includes(el.attributeId)
        ? R.assoc('isActive', true, el)
        : R.assoc('isActive', false, el),
    );

    const index = R.findIndex(
      (profile) => profile._id === updatedProfile._id,
      localComplianceProfiles,
    );

    setLocalComplianceProfiles(
      R.update(
        index,
        R.assoc(group, updatedGroup, updatedProfile),
        localComplianceProfiles,
      ),
    );
  };

  const onSaveProject = () => {
    generalTabForm
      .validateFields()
      .then(async (values) => {
        setSubmitting(true);
        const updatedProfilesMappings = {};
        const updatingProfilesMappings = {};
        const fieldsToOmit = omittedFields.concat('customFields');

        // tracing compliance profiles
        const updatedComplianceProfilesNames = [];

        /**
         * projectId flag differentiates between edit and create mode
         */
        if (Boolean(projectId)) {
          /**
           * temporary implementation:
           * subscribe to projectUpdated socket in order to give a feedback when compliance sync process is completed.
           *
           * More explanation here: https://github.com/trustlayer/trustlayer-web/pull/1174
           *
           * @todo remove it as soon as the story https://app.shortcut.com/trustlayer/story/9536/visible-job-queue is done.
           */
          dispatch(openProjectUpdatesWSSubscription(projectId));
        }

        const processParty = async (party) => {
          const selectedProfileId = partyProfileAssociation[party._id];

          const selectedProfile = R.find(
            (profile) => profile._id === selectedProfileId,
            localComplianceProfiles,
          );

          if (!selectedProfile) {
            // This means that the party being processed has either no compliance profile or a custom one.
            return {
              partyId: party._id,
              complianceProfileId: null,
            };
          }

          const { name, rules, documentChecklists } = selectedProfile;

          const mapping = R.find(
            (el) => R.includes(selectedProfileId, R.values(el)),
            profilesMappings,
          );

          if (mapping) {
            console.log('Existing project-specific profile, updating...');
            if (!R.has(selectedProfileId, updatedProfilesMappings)) {
              // Since we update project profiles asyncronously, updatingProfilesMappings keeps track of the profiles that are already updating to avoid to start updating another one
              // If the profile is already updating, we wait for the result
              if (!R.has(selectedProfileId, updatingProfilesMappings)) {
                updatingProfilesMappings[selectedProfileId] = dispatch(
                  updateComplianceProfile({
                    _id: mapping.projectComplianceProfile,
                    name: name,
                    context: ComplianceProfileContext.Project,
                    contextId: projectId,
                    rules: rules.map((el) => R.omit(fieldsToOmit, el)),
                    documentChecklists: documentChecklists.map((el) => ({
                      ...R.omit(fieldsToOmit, el),
                      type: R.path(['type', '_id'], el),
                    })),
                  }),
                );

                await updatingProfilesMappings[selectedProfileId];
              } else {
                await updatingProfilesMappings[selectedProfileId];
              }

              updatedComplianceProfilesNames.push(name);

              // get result of promise updatingProfilesMappings[selectedProfileId]
              const result = await updatingProfilesMappings[selectedProfileId];
              updatedProfilesMappings[mapping.originalComplianceProfile] =
                getGraphqlPayload(result)?._id;
            }
          } else {
            console.log('New project-specific profile, creating...');
            // Since we want to create a new project profile for each unique profile, updatedProfilesMappings keeps track of the project profiles already created so that they are not re-created
            if (!R.has(selectedProfileId, updatedProfilesMappings)) {
              // Since we create project profiles asyncronously, updatingProfilesMappings keeps track of the profiles that are already creating to avoid to start creating another one
              // If the profile is already creating, we wait for the result
              if (!R.has(selectedProfileId, updatingProfilesMappings)) {
                updatingProfilesMappings[selectedProfileId] = dispatch(
                  createComplianceProfile({
                    ...R.omit(fieldsToOmit, selectedProfile),
                    name,
                    context: ComplianceProfileContext.Project,
                    contextId: projectId,
                    rules: rules.map((el) => R.omit(fieldsToOmit, el)),
                    documentChecklists: documentChecklists.map((el) => ({
                      ...R.omit(fieldsToOmit, el),
                      type: R.path(['type', '_id'], el),
                    })),
                  }),
                );

                await updatingProfilesMappings[selectedProfileId];
              } else {
                await updatingProfilesMappings[selectedProfileId];
              }

              // get result of promise updatingProfilesMappings[selectedProfileId]
              const result = await updatingProfilesMappings[selectedProfileId];
              updatedProfilesMappings[selectedProfileId] =
                getGraphqlPayload(result)?._id;
            }
          }

          return {
            partyId: party._id,
            complianceProfileId:
              updatedProfilesMappings[selectedProfileId] ||
              mapping.projectComplianceProfile,
          };
        };

        const partiesData = await Promise.all(parties.map(processParty));

        const normalizeCustomFieldValue = (el) => {
          switch (el.customField.type) {
            case CustomFieldType.Number:
              return isNaN(parseInt(el.value)) ? '' : el.value;
            default:
              return el.value ?? '';
          }
        };

        const [startDate, endDate] = dates;

        const data = {
          name: values.name,
          description: getEditorHTMLContent(projectDescriptionEditorState),
          startDate: getUTCMomentDate(startDate),
          endDate: getUTCMomentDate(endDate),
          customFields: selectedCustomFields.map((el) => ({
            customField: el.customField._id,
            value: normalizeCustomFieldValue(el),
          })),
          partiesData,
          complianceProfiles: R.compose(
            R.map((id) => ({
              projectComplianceProfile: updatedProfilesMappings[id],
              originalComplianceProfile: id,
            })),
            R.keys,
          )(updatedProfilesMappings),
          files: uploadFiles,
        };

        // eslint-disable-next-line fp/no-let
        let messageText = '';

        if (Boolean(projectId)) {
          const attachmentsToRemove = R.compose(
            R.pluck('_id'),
            R.reject((projectAttachment) =>
              R.find((el) => el._id === projectAttachment._id, attachments),
            ),
            R.propOr([], 'attachments'),
          )(project);
          const addedCustomFields = R.without(
            R.map((x) => x.customField._id, project?.customFields?.nodes ?? []),
            selectedCustomFields.map((el) => el.customField._id),
          );

          await dispatch(
            updateProject(
              R.compose(
                R.assoc('attachmentsToRemove', attachmentsToRemove),
                R.assoc('_id', projectId),
              )(data),
            ),
          );

          if (updatedComplianceProfilesNames.length) {
            trackEvent('User edited the compliance profile of a project');
          }
          if (addedCustomFields.length) {
            trackEvent('User added a custom field to a project');
          }

          if (onSuccess) {
            onSuccess();
          }
          messageText = 'Project saved, compliance synchronization in progress';
        } else {
          await dispatch(createProject(data));

          if (onSuccess) {
            onSuccess();
          }
          messageText = 'Project saved successfully';
        }

        setSubmitting(false);
        message.success(messageText);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        message.error('An error occurred while saving this project');
        setTab(TabKeys.General.key);
      });
  };

  const filterByNameInput = useRef();

  return (
    <SaveProjectModal.Modal
      maskClosable={false}
      title={
        projectId
          ? `Edit project ${R.propOr('', 'name', project)}`
          : 'New project'
      }
      open={visible}
      onCancel={onClose}
      width={1000}
      footer={[
        <SaveProjectModal.FooterTitleContainer key="footer-info">
          <ConnectivityBadge entity={project} showTitle={false} />
          {!R.isEmpty(R.propOr([], 'externalIds', project)) && (
            <Typography.Text>
              {'This project is synced with Procore'}
            </Typography.Text>
          )}
        </SaveProjectModal.FooterTitleContainer>,
        <Button
          key="close"
          onClick={onClose}
          data-cy="cancelProjectModalButton"
        >
          Cancel
        </Button>,
        <Button
          disabled={isModalDataLoading || loading || isFetchingProfiles}
          data-cy="saveProjectModalOkButton"
          key="submit"
          type="primary"
          loading={submitting}
          onClick={onSaveProject}
        >
          {projectId ? 'Update project' : 'Create project'}
        </Button>,
      ]}
    >
      {loading ? (
        <SaveProjectModal.Loading>
          <Spinner />
        </SaveProjectModal.Loading>
      ) : (
        <Tabs
          tabBarStyle={{ marginBottom: '30px' }}
          activeKey={tab}
          onChange={(key) => setTab(key)}
          animated={false}
        >
          <TabPane tab={TabKeys.General.name} key={TabKeys.General.key}>
            <GeneralTabForm
              form={generalTabForm}
              project={project}
              dates={dates}
              setDates={setDates}
              descriptionEditorState={projectDescriptionEditorState}
              onDescriptionChange={setProjectDescriptionEditorState}
              visible={visible}
            />
          </TabPane>
          <TabPane
            tab={
              <span data-cy="tabBtnCustomFields">
                {TabKeys.CustomFields.name}
              </span>
            }
            key={TabKeys.CustomFields.key}
          >
            <CustomFieldsTab
              customFields={customFields}
              selectedCustomFields={selectedCustomFields}
              onSetSelectedCustomFields={onSetSelectedCustomFields}
            />
          </TabPane>
          <TabPane
            tab={<span data-cy="tabBtnParties">{TabKeys.Parties.name}</span>}
            key={TabKeys.Parties.key}
          >
            <AutoComplete
              data-cy="saveProjectModalSearchPartyInput"
              options={options}
              filterOption={(_, option) =>
                !parties.find((party) => party._id === option.value)
              }
              allowClear
              onSearch={onChangeHandle}
              style={{ width: '100%' }}
              value={searchValue}
              onSelect={(item) => {
                setSearchValue('');
                onSelectParty(item);
                setSearchResult([]);
              }}
            >
              <Input
                prefix={<SearchOutlined />}
                placeholder={'Search parties...'}
              />
            </AutoComplete>
            <SaveProjectModal.Table
              size="small"
              data-cy="saveProjectPartiesList"
              dataSource={parties}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No parties to show"
                  />
                ),
              }}
              columns={[
                {
                  title: 'Parties in this project',
                  key: '_id',
                  filterDropdown: ({
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                  }) => {
                    return (
                      <div style={{ padding: '10px' }}>
                        <Input
                          ref={filterByNameInput}
                          placeholder="Filter by name..."
                          value={selectedKeys[0]}
                          allowClear
                          onChange={(e) => {
                            setSelectedKeys(
                              e.target.value ? [e.target.value] : [],
                            );

                            if (e.target.value === '') {
                              confirm({ closeDropdown: false });
                            }
                          }}
                          onPressEnter={confirm}
                        />
                      </div>
                    );
                  },
                  onFilter: (searchInput, party) => {
                    return party?.name
                      ?.toLowerCase()
                      ?.includes(searchInput?.toLowerCase());
                  },
                  onFilterDropdownOpenChange: (visible) => {
                    if (visible) {
                      setTimeout(
                        () => filterByNameInput?.current?.select(),
                        100,
                      );
                    }
                  },

                  render: (_, party) => (
                    <ProjectPartiesTabContainer
                      profilesMappings={profilesMappings}
                      localComplianceProfiles={localComplianceProfiles}
                      party={party}
                      deleteParty={(id) => deleteParty(id)}
                      onChangeProfileAssociation={onChangeProfileAssociation}
                      key={party._id}
                    />
                  ),
                },
              ]}
              pagination={{
                pageSize: 12,
                size: 'small',
                showSizeChanger: false,
                showQuickJumper: true,
                hideOnSinglePage: true,
              }}
            />
          </TabPane>
          {isComplianceRequirementsAddOnEnabled && (
            <TabPane
              tab={
                <span data-cy="tabBtnComplianceProfiles">
                  {TabKeys.Requirements.name}
                </span>
              }
              key={TabKeys.Requirements.key}
            >
              {isFetchingProfiles ? (
                <Spinner />
              ) : (
                <ProjectRequirementsView
                  localComplianceProfiles={localComplianceProfiles.filter(
                    (el) =>
                      Object.values(partyProfileAssociation).includes(el._id),
                  )}
                  onCheckRequirement={onToggleCheckbox}
                  onCheckDocumentChecklist={onToggleCheckbox}
                />
              )}
            </TabPane>
          )}

          <TabPane
            tab={TabKeys.ProjectAttachments.name}
            key={TabKeys.ProjectAttachments.key}
          >
            <ProjectAttachmentsTabContainer
              uploadFiles={uploadFiles}
              setUploadFiles={setUploadFiles}
              rejectedFiles={rejectedFiles}
              setRejectedFiles={setRejectedFiles}
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </TabPane>
        </Tabs>
      )}
    </SaveProjectModal.Modal>
  );
};

SaveProjectModal.Modal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-select {
    font-size: 13px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

SaveProjectModal.FooterTitleContainer = styled.div`
  margin-right: auto;
  opacity: 0.65;
  font-size: 12px;
`;

SaveProjectModal.Table = styled(Table)`
  margin-top: 20px;
  padding-bottom: 20px;
`;

SaveProjectModal.InactiveParty = styled.span`
  font-size: 12px;
  margin-left: 15px;
  color: #1890ff;
`;

SaveProjectModal.Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const mapStateToProps = (state) => ({
  getComplianceProfileById: (id) => getComplianceProfileById(state, id),
});

const SaveProjectModalWithRedux = connect(
  mapStateToProps,
  {},
)(SaveProjectModal);

export default SaveProjectModalWithRedux;
