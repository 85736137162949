import {
  QuestionCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Form, Popover, Select } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export type SortingItems = { id: string; name: string }[];

type SortButtonProps = {
  items: SortingItems;
  initialValues?: { columnId?: string; direction?: 'asc' | 'desc' };
  onClick: () => void;
  onChange: (params: { columnId: string; direction: 'asc' | 'desc' }) => void;
};

export const SortButton = ({
  items,
  initialValues,
  onClick,
  onChange,
}: SortButtonProps) => {
  const handleOnValueChange = (_: any, allValues: any) => {
    if (!allValues.columnId || !allValues.direction) {
      return;
    }

    onChange({
      columnId: allValues.columnId,
      direction: allValues.direction,
    });
  };

  const popoverContent = (
    <section>
      <StyledForm
        onValuesChange={handleOnValueChange}
        initialValues={initialValues}
      >
        <StyledFormItem name="columnId">
          <Select placeholder="Column">
            {items.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </StyledFormItem>
        <StyledFormItem name="direction">
          <Select placeholder="Direction">
            <Select.Option value="asc">Ascending</Select.Option>
            <Select.Option value="desc">Descending</Select.Option>
          </Select>
        </StyledFormItem>
      </StyledForm>
      <StyledInfo>
        <QuestionCircleOutlined />
        <span>Learn about sorting</span>
      </StyledInfo>
    </section>
  );

  return (
    <>
      <GlobalStyle />
      <Popover
        placement="bottomRight"
        trigger="click"
        overlayClassName="sortingMenuOverlay"
        content={popoverContent}
        destroyTooltipOnHide
      >
        <Button icon={<UnorderedListOutlined />} onClick={onClick}>
          Sort
        </Button>
      </Popover>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  .sortingMenuOverlay {
    .ant-popover-inner {
      overflow: hidden;
      padding: 0;
    }
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px;
`;

const StyledFormItem = styled(Form.Item)`
  min-width: 150px;
  margin: 0;
`;

const StyledInfo = styled.div`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.blueGray};
  color: ${({ theme }) => theme.colors.lightTextGray};
  display: flex;
  align-items: center;
  gap: 8px;
`;
