import { gql } from '@apollo/client';

import { DOCUMENT_FRAGMENT } from '../../document/fragments';
import { namespace } from '../constants';

export const DETACH_DOCUMENTS_FROM_PROJECT = `${namespace}/DETACH_DOCUMENTS_FROM_PROJECT`;

const DETACH_DOCUMENTS_FROM_PROJECT_MUTATION = gql`
  mutation detachDocumentsFromProject($data: DetachDocumentsFromProjectInput!) {
    detachDocumentsFromProject(data: $data) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const detachDocumentsFromProject = (data) => ({
  type: DETACH_DOCUMENTS_FROM_PROJECT,
  payload: {
    key: 'detachDocumentsFromProject',
    graphql: {
      mutation: DETACH_DOCUMENTS_FROM_PROJECT_MUTATION,
      variables: {
        data,
      },
    },
  },
});
