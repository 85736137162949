import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const DETACH_DOCUMENTS_FROM_PARTY = `${namespace}/DETACH_DOCUMENTS_FROM_PARTY`;

const DETACH_DOCUMENTS_FROM_PARTY_MUTATION = gql`
  mutation detachDocumentsFromParty($payload: DetachDocumentsToPartyInput!) {
    detachDocumentsFromParty(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const detachDocumentsFromParty = (payload) => ({
  type: DETACH_DOCUMENTS_FROM_PARTY,
  payload: {
    key: 'detachDocumentsFromParty',
    graphql: {
      mutation: DETACH_DOCUMENTS_FROM_PARTY_MUTATION,
      variables: { payload },
    },
  },
});
