import {
  ACTION_TYPES,
  OVERRIDE_ACTIONS,
  STATUS_TYPES,
  WAIVER_ACTIONS,
} from '../../constants';

export const REQUIREMENT_ACTIONS_BY_STATUS = {
  [STATUS_TYPES.default]: [
    {
      label: 'Waive requirement',
      code: ACTION_TYPES.waive,
    },
    {
      label: 'Override requirement',
      code: ACTION_TYPES.override,
    },
  ],
  [STATUS_TYPES.waived]: WAIVER_ACTIONS,
  [STATUS_TYPES.overridden]: OVERRIDE_ACTIONS,
};

export const NOTE_REQUIREMENT_ACTIONS = [
  {
    label: 'Add note',
    code: ACTION_TYPES.addNote,
  },
  {
    label: 'Edit note',
    code: ACTION_TYPES.editNote,
  },
];
