import {
  type OperationVariables,
  useMutation as useApolloMutation,
} from '@apollo/client';
import * as R from 'ramda';
import { useStore } from 'react-redux';

import { getContext } from '@store/helpers';

const useMutation: typeof useApolloMutation = (mutation, options) => {
  const store = useStore();
  const state = store.getState();
  const context = getContext(state);
  const optionsWithContext: OperationVariables = R.mergeDeepRight(
    options || {},
    {
      variables: context,
    },
  );

  return useApolloMutation(mutation, optionsWithContext);
};

export default useMutation;
