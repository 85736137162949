import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_SENDER_FRAGMENT } from '../fragments';

export const CREATE_EMAIL_SENDER = `${namespace}/CREATE_EMAIL_SENDER`;

const CREATE_EMAIL_SENDER_MUTATION = gql`
  mutation createEmailSender($payload: CreateEmailSenderInput!) {
    createEmailSender(data: $payload) {
      ...EmailSender
    }
  }
  ${EMAIL_SENDER_FRAGMENT}
`;

export const createEmailSender = (payload) => ({
  type: CREATE_EMAIL_SENDER,
  payload: {
    key: 'createEmailSender',
    graphql: {
      mutation: CREATE_EMAIL_SENDER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
