import { HolderOutlined } from '@ant-design/icons';
import type { ReactNode } from 'react';
import styled from 'styled-components';

export const DragAndDropIconProvider = ({
  children,
}: { children: ReactNode }) => {
  return (
    <StyledWrapper>
      <HolderOutlined className="icon" />
      {children}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    opacity: 0;
    font-size: 20px;
  }

  &&:hover .icon {
    opacity: 1;
  }
`;
