import { CalendarOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import type { Branch } from '../../types';

import {
  getFirstBranchSteps,
  getScheduleLabel,
  getStepSchedulesList,
} from './DocumentRemindersAutomationSchedules.utils';

type DocumentRemindersAutomationSchedulesProps = {
  branches: Branch[];
};

export const DocumentRemindersAutomationSchedules = ({
  branches,
}: DocumentRemindersAutomationSchedulesProps) => {
  const steps = getFirstBranchSteps(branches);
  const schedulesList = getStepSchedulesList(steps);

  return (
    <section>
      <StyledList>
        {schedulesList.map((schedules, index) => (
          <StyledItem key={`reminder-${index}`}>
            <StyledCalendarIcon />
            <StyledItemText>
              {`Reminder ${index + 1} ${getScheduleLabel(schedules, index)}`}
            </StyledItemText>
          </StyledItem>
        ))}
      </StyledList>
    </section>
  );
};

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 300px;
  max-height: 400;
`;

const StyledCalendarIcon = styled(CalendarOutlined)`
  color: ${({ theme }) => theme.colors.lightTextGray};
`;

const StyledItem = styled.li`
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledItemText = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-size: 14px;
`;
