import * as R from 'ramda';
import styled from 'styled-components';

import PartyLinkName from '@common/components/PartyLinkName';

const PartyProjectLine = ({ log }) => {
  const partyId = R.prop('partyId', log);
  const partyName = R.prop('partyName', log);
  const projectName = R.prop('projectName', log);

  return (
    <PartyProjectLine.Line>
      <PartyLinkName partyId={partyId}>{partyName}</PartyLinkName>
      {!R.isEmpty(partyName) && !R.isEmpty(projectName) && ', '}
      {projectName}
    </PartyProjectLine.Line>
  );
};

PartyProjectLine.Line = styled.div`
  margin-top: 3px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
`;

export default PartyProjectLine;
