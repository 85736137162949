import type { Contact, User } from '@graphql/types/graphql';

const isUserContact = (contact: User | Contact): contact is User =>
  'profile' in contact;

export const normalizeContacts = (contacts: (User | Contact)[]) =>
  contacts.map((contact) => ({
    _id: contact._id,
    name: isUserContact(contact)
      ? contact.profile?.name
      : contact.contactPersonName || '',
    email: contact.email || '',
  }));
