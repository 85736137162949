import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_TEMPLATES = `${namespace}/FETCH_TEMPLATES`;

const FETCH_TEMPLATES_QUERY = gql`
  query listRuleDefinitions {
    listRuleDefinitions {
      moduleId
      moduleLabel
      subjectId
      subjectLabel
      attributeId
      attributeLabel
      attributeDescription
      attributeType
      automaticallyDetected
      masterDocumentAttributeId
      isActive
      invalidatedBy
      invalidationScope
    }
  }
`;

export const fetchTemplates = () => ({
  type: FETCH_TEMPLATES,
  payload: {
    key: 'listRuleDefinitions',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_TEMPLATES_QUERY,
    },
  },
});
