import { Col, Row, Select } from 'antd';
import styled from 'styled-components';

import { FullWidthSelect } from '@trustlayer/ui';
import { NotificationType } from '../types';

const { Option } = Select;

type NotificationFilterProps = {
  selectedFilter?: NotificationType;
  onChange: (value: NotificationType) => void;
};

const NotificationFilter = ({
  selectedFilter,
  onChange,
}: NotificationFilterProps) => {
  return (
    <StyledRow align="middle" data-cy="notificationsFilter">
      <Col span={6}>Filter by type:</Col>
      <Col span={18}>
        <FullWidthSelect
          value={selectedFilter || NotificationType.All}
          onChange={onChange}
          dropdownMatchSelectWidth
        >
          <Option value={NotificationType.All}>All notifications type</Option>
          <Option value={NotificationType.Requests}>Requests</Option>
          <Option value={NotificationType.PartyCreated}>Parties created</Option>
          <Option value={NotificationType.DocumentUpload}>
            Documents uploaded
          </Option>
          <Option value={NotificationType.ConversationReply}>Messages</Option>
          <Option value={NotificationType.MentionInPartyComment}>
            Mentions
          </Option>
          <Option value={NotificationType.EmailBounce}>Message errors</Option>
        </FullWidthSelect>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  padding: 8px 15px 0;
`;

export default NotificationFilter;
