import {
  SET_FEATURE_FLAGS,
  type SetFeatureFlagsAction,
} from '../actions/setFeatureFlags';

export type FeatureFlagsState = {
  [name: string]: {
    enabled: boolean;
    name: string;
  };
};

export type FeatureFlagsAction = SetFeatureFlagsAction;

export const initialState: FeatureFlagsState = {};

const featureFlagsReducer = (
  state = initialState,
  action: FeatureFlagsAction,
) => {
  switch (action.type) {
    case SET_FEATURE_FLAGS:
      return action.payload?.reduce(
        (acc, toggle) => ({ ...acc, [toggle.name]: toggle }),
        {},
      );
    default:
      return state;
  }
};

export default featureFlagsReducer;
