import styled from 'styled-components';

import CloseIcon from '@common/icons/CloseIcon';

const DocumentUploadFail = ({ format }) => (
  <DocumentUploadFail.UploadFailWrapper>
    <CloseIcon size={40} color="red" />
    <div>
      Document upload failed. The .{format} file format is not supported.
    </div>
  </DocumentUploadFail.UploadFailWrapper>
);

DocumentUploadFail.UploadFailWrapper = styled.div`
  color: ${(props) => props.theme.colors.red};

  > div {
    padding-top: 2px;
    font-size: 15px;
  }
`;

export default DocumentUploadFail;
