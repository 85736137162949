import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPDATE_CONTACT = `${namespace}/UPDATE_CONTACT`;

const UPDATE_CONTACT_MUTATION = gql`
  mutation updatePartyContact($payload: UpdatePartyContactInput!) {
    updatePartyContact(data: $payload) {
      _id
      partyContacts {
        _id
        party
        contactPersonName
        companyName
        email
        phone
        fax
        title
        avatar
        isPrimary
        isDefaultRequestRecipient
        createdAt
        updatedAt
      }
    }
  }
`;

export const updateContact = (payload) => ({
  type: UPDATE_CONTACT,
  payload: {
    key: 'updatePartyContact',
    graphql: {
      mutation: UPDATE_CONTACT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
