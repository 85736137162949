import {
  type ClearPushAction,
  LOCATION_CLEAR_PUSH,
  LOCATION_PUSH,
  type PushAction,
} from '../actions';

export type LocationState = {
  pathname: string;
  push: {
    to: string | null;
  };
};

export type LocationAction = PushAction | ClearPushAction;

const initialState: LocationState = {
  pathname: '',
  push: {
    to: null,
  },
};

const locationReducer = (state = initialState, action: LocationAction) => {
  switch (action.type) {
    case LOCATION_PUSH:
      return {
        ...state,
        push: {
          to: action.payload,
        },
      };
    case LOCATION_CLEAR_PUSH:
      return {
        ...state,
        push: {
          to: null,
        },
      };
    default:
      return state;
  }
};

export default locationReducer;
