import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useDeletePrimaryRecordsMutation } from '@modules/primary-records/hooks/useDeletePrimaryRecordsMutation';
import { message } from 'antd';

type DeletePrimaryRecordsModalProps = {
  open: boolean;
  count?: number;
  filters?: ServerFilterInput;
  primaryObject: {
    name: string;
    pluralName: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const DeletePrimaryRecordsModal = ({
  open,
  count = 1,
  filters,
  primaryObject,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: DeletePrimaryRecordsModalProps) => {
  const { deletePrimaryRecords, isDeletingPrimaryRecords } =
    useDeletePrimaryRecordsMutation();

  const handleDelete = async () => {
    const res = await deletePrimaryRecords(filters);

    const status = res.data?.deletePrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `${count} ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} deleted successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${count} ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} scheduled for deletion.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The deletion of your ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      data-cy="delete-records-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isDeletingPrimaryRecords,
      }}
      okText="Delete"
      title={`Are you sure you want to delete ${count} ${count > 1 ? `${primaryObject.pluralName}` : `${primaryObject.name}`}?`}
      onCancel={onCancel}
      onOk={handleDelete}
    >
      <p>This action cannot be undone</p>
    </ModalConfirm>
  );
};
