import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PROJECT_FRAGMENT } from '../fragments';

export const DELETE_PROJECTS = `${namespace}/DELETE_PROJECTS`;

const DELETE_PROJECTS_MUTATION = gql`
  mutation deleteProjects($projectIds: [String!]!) {
    deleteProjects(projectIds: $projectIds) {
      ...Project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const deleteProjects = (projectIds) => ({
  type: DELETE_PROJECTS,
  payload: {
    key: 'deleteProjects',
    graphql: {
      mutation: DELETE_PROJECTS_MUTATION,
      variables: {
        projectIds,
      },
    },
  },
});
