import { NetworkStatus } from '@apollo/client';

import { useQuery } from '@graphql/hooks';

import { EMAIL_ACTIVITIES_QUERY } from '../queries';

export const useEmailActivities = (recordId: string) => {
  const { data, fetchMore, loading, error, refetch, networkStatus } = useQuery(
    EMAIL_ACTIVITIES_QUERY,
    {
      variables: {
        primaryRecordInput: { id: recordId },
        input: { first: 10, offset: 0 },
      },
      skip: !recordId,
      notifyOnNetworkStatusChange: true,
    },
  );

  const isEmailActivitiesLoadingMore =
    networkStatus === NetworkStatus.fetchMore;
  const isEmailActivitiesLoading = !isEmailActivitiesLoadingMore && loading;

  const { nodes: emailActivities, totalCount = 0 } =
    data?.primaryRecord.emailActivities || {};

  const fetchMoreEmailActivities = () => {
    fetchMore({
      variables: {
        input: {
          first: 10,
          offset: 10,
        },
      },
    });
  };

  return {
    emailActivities,
    totalCount,
    error,
    isEmailActivitiesLoading,
    isEmailActivitiesLoadingMore,
    fetchMore: fetchMoreEmailActivities,
    refetch,
  };
};
