import { NotificationOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { AppOutdatedCard } from '@modules/app';
import { checkAppOutdated } from '@modules/app/containers/AppOutdatedCard/AppOutdatedCard.utils';

import { getAppVersions } from '@modules/app/selectors';
import { useAppSelector } from '@store/hooks';

export function AppOutdatedCardMenu({
  isMinifiedMode,
  onClick,
}: {
  isMinifiedMode: boolean;
  onClick: () => void;
}) {
  const { currentVersion, latestVersion } = useAppSelector(getAppVersions);

  const needsUpdate = checkAppOutdated(currentVersion, latestVersion);

  if (!needsUpdate) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledFade $isVisible={isMinifiedMode}>
        <StyledIcon onClick={onClick} />
      </StyledFade>
      <StyledFade $isVisible={!isMinifiedMode}>
        <AppOutdatedCard latestVersion={latestVersion} />
      </StyledFade>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  margin: 12px 20px 48px;
  position: absolute;
  bottom: 0;
`;

const StyledIcon = styled(NotificationOutlined)`
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledFade = styled.div<{ $isVisible: boolean }>`
  ${({ $isVisible }) =>
    $isVisible
      ? `
        opacity:1;
        transition: opacity 0.3s ease-in-out;
        transition-delay: 0.35s;
      `
      : `
        opacity:0;
        position: absolute;
      `}
`;
