import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const ASSIGN_CONTACTS_MUTATION = graphql(`
  mutation AssignContactsToPrimaryRecord($data: AssignContactsToPrimaryRecordInput!) {
    assignContactsToPrimaryRecord(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useAssignContact = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [assignContactMutation, { loading }] = useMutation(
    ASSIGN_CONTACTS_MUTATION,
  );

  const assignContact = useCallback(
    (contactId: string) =>
      assignContactMutation({
        variables: {
          data: {
            primaryRecordId,
            // NOTE: this will always be synchronous since we are only assigning one contact at a time
            contacts: [{ contactId }],
          },
        },
      }),
    [assignContactMutation, primaryRecordId],
  );

  return {
    assignContact,
    loading,
  };
};
