import { message as notify } from 'antd';
import pluralize from 'pluralize';
import { all, put, putResolve, select, takeLatest } from 'redux-saga/effects';

import { updateParty } from '@modules/party/actions';
import { getParties } from '@modules/party/selectors';

import { SET_AUTHENTICATED_APP_READY } from '../../app/actions';
import {
  SET_TYPE_FOR_PARTIES,
  fetchPartyTypes,
  setIsAssigningType,
} from '../actions';

function* fetchPartyTypesSaga() {
  yield putResolve(fetchPartyTypes());
}

function* setTypeForPartiesSaga({ payload: { parties, type } }) {
  yield put(setIsAssigningType(true));

  const allParties = yield select(getParties);

  yield all(
    allParties
      .filter((party) => parties.some((partyId) => partyId === party._id))
      .map((party) => {
        return putResolve(
          updateParty({
            id: party._id,
            name: party.name,
            type,
          }),
        );
      }),
  );

  yield notify.success(
    `Party type successfully assigned to ${pluralize(
      'party',
      parties.length,
      true,
    )}`,
  );
  yield put(setIsAssigningType(false));
}

function* partyTypeSagas() {
  yield all([
    takeLatest(SET_AUTHENTICATED_APP_READY, fetchPartyTypesSaga),
    takeLatest(SET_TYPE_FOR_PARTIES, setTypeForPartiesSaga),
  ]);
}

export default partyTypeSagas;
