import {
  DateSuffix,
  FilterInputType,
  KeyType,
  type SelectedFilter,
} from '@common/components/DateFilterSelector';
import type { DateFilter } from '@common/components/Table';
import type { ISimpleFilterModelType } from 'ag-grid-community/dist/types/core/filter/provided/iSimpleFilter';

export const getReportDateFilter = (
  filter: SelectedFilter<number>,
  filterCode: string,
) => {
  if (filter.key === KeyType.ON) {
    return {
      [filterCode]: String(filter.value), // this is necessary to support filter chip in v1 since it was still, wrongly, using a much older implementation
      [`${filterCode}FilterType`]: filter.key,
      [`${filterCode}${DateSuffix.PickerValue}`]: String(filter.value),
    };
  }

  if (filter.key === KeyType.BETWEEN) {
    return {
      [filterCode]: `${String(filter.from)}~${String(filter.to)}`, // this is necessary to support filter chip in v1 since it was still, wrongly, using a much older implementation
      [`${filterCode}FilterType`]: filter.key,
      [`${filterCode}${DateSuffix.From}`]: String(filter.from),
      [`${filterCode}${DateSuffix.To}`]: String(filter.to),
    };
  }

  if (
    filter.key === KeyType.LAST ||
    filter.key === KeyType.NEXT ||
    filter.key === KeyType.OLDER
  ) {
    return {
      // these filters don't need the filterCode property like the others above
      [`${filterCode}FilterType`]: filter.key,
      [`${filterCode}${DateSuffix.WithinDateCount}`]: String(
        filter.withinDateValue,
      ),
      [`${filterCode}${DateSuffix.WithinDateType}`]: filter.withinDateType,
    };
  }
};

export const getTableDateFilterFromReportFilter = (
  activeFilters: Record<string, any>,
  filterCode: string,
): DateFilter<number> | undefined => {
  const key = activeFilters[`${filterCode}${DateSuffix.FilterType}`];

  if (key === KeyType.ON) {
    return {
      key,
      filterType: FilterInputType.SELECT,
      value: Number(activeFilters[`${filterCode}${DateSuffix.PickerValue}`]),
    };
  }

  if (key === KeyType.BETWEEN) {
    return {
      key,
      filterType: FilterInputType.RANGE,
      from: Number(activeFilters[`${filterCode}${DateSuffix.From}`]),
      to: Number(activeFilters[`${filterCode}${DateSuffix.To}`]),
    };
  }

  if (key === KeyType.LAST || key === KeyType.NEXT || key === KeyType.OLDER) {
    return {
      key,
      filterType: FilterInputType.PICKER,
      withinDateValue: Number(
        activeFilters[`${filterCode}${DateSuffix.WithinDateCount}`],
      ),
      withinDateType:
        activeFilters[`${filterCode}${DateSuffix.WithinDateType}`],
    };
  }
};

const getArrayValues = (values: string[] | string) => {
  return Array.isArray(values) ? values : [values];
};

export const getArrayFilter = getArrayValues;

export const getSetTypeFilter = (values: string[] | string) => {
  return {
    filterType: 'set' as const,
    values: getArrayValues(values),
  };
};

export const getTextTypeFilter = (value: string) => {
  return {
    filterType: 'text' as const,
    type: 'contains' as const,
    filter: value,
  };
};

export const getIdFilter = (
  values: string[] | string,
  operator: 'in' | 'notIn',
) => {
  return {
    type: operator,
    filter: getArrayValues(values),
  };
};

export const getNumberFilter = (
  value: string,
  operator: ISimpleFilterModelType,
) => {
  return {
    filter: Number(value),
    filterType: 'number' as const,
    type: operator,
  };
};
