export const REVIEW_STATUS = {
  Reviewed: 'reviewed',
  ToReview: 'toReview',
} as const;

export type ReviewStatus = (typeof REVIEW_STATUS)[keyof typeof REVIEW_STATUS];

export const ARCHIVE_STATUS = {
  Archived: 'archived',
  NonArchived: 'nonArchived',
} as const;

export const APPLIES_TO_ALL_PROJECTS_STATUS = {
  Applied: 'applied',
  NonApplied: 'nonAapplied',
} as const;

export const VERIFICATION_STATUS = {
  Verified: 'verified',
  ToVerify: 'toVerify',
  NotVerified: 'notVerified',
} as const;

export const SEVERITY_STATUS = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
} as const;

export type ArchiveStatus =
  (typeof ARCHIVE_STATUS)[keyof typeof ARCHIVE_STATUS];
