import { mapLegacyDocumentsReportParamsToTableParams } from '../containers/DocumentsList/utils';
import type { TableState } from '../containers/DocumentsSection';

export const mapURLSearchParamsToTableState = (
  params: Record<string, (string | null)[] | null | string>,
): TableState => {
  const { filters, sorting } = mapLegacyDocumentsReportParamsToTableParams(
    params || {},
  );

  const pagination = {
    currentPage: Number(params.page || 0),
    pageSize: Number(params.size || 0),
  };

  return {
    reportId:
      !Array.isArray(params.report) && params.report
        ? params.report
        : undefined,
    filters,
    sorting,
    pagination,
  };
};
