import { graphql } from '@graphql/types';

export const VIEWS_SELECTOR_QUERY = graphql(`
  query ViewsSelector {
    listReports {
      _id
      name
      type
      filters
      organization
      user
      schedules {
        _id
        name
        recipients
        format
        frequency
        frequencyOptions
        message
        columns {
          value
          name
        }
        showOrganizationComplianceRow
        lastSendingDate
        lastSendingType
      }
      isPrivate
      readonly
      createdAt
      updatedAt
    }
  }
`);
