import {
  CLOSE_DRAWER,
  type CloseNotificationDrawerAction,
  OPEN_DRAWER,
  type OpenNotificationDrawerAction,
  SET_SELECTED_FILTER,
  type SelectNotificationDrawerFilterAction,
} from '../actions';
import { NotificationType } from '../types';

export type NotificationState = {
  isDrawerOpen: boolean;
  selectedFilter: NotificationType;
};

export type NotificationAction =
  | CloseNotificationDrawerAction
  | OpenNotificationDrawerAction
  | SelectNotificationDrawerFilterAction;

export const initialState: NotificationState = {
  isDrawerOpen: false,
  selectedFilter: NotificationType.All,
};

const notificationReducer = (
  state = initialState,
  action: NotificationAction,
) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        ...state,
        selectedFilter: action.payload.type || NotificationType.All,
        isDrawerOpen: true,
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false,
      };
    case SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
