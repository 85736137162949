import { gql } from '@apollo/client';

import { namespace } from '../constants';
import {
  PARTY_DATA_DOCUMENT_FRAGMENT,
  PARTY_DATA_FRAGMENT,
} from '../fragments';

export const FETCH_PARTY_WITH_DETAILS = `${namespace}/FETCH_PARTY_WITH_DETAILS`;

// fetch party with details
const FETCH_PARTY_WITH_DETAILS_QUERY = gql`
  query getPartyData($id: String!, $byContext: Boolean) {
    getPartyData(id: $id, byContext: $byContext) {
      ...PartyData
      defaultRequestRecipients {
        _id
      }
      applicableDocuments {
        ...PartyDataDocument
      }
      totalApplicableDocumentsCount
    }
  }
  ${PARTY_DATA_FRAGMENT}
  ${PARTY_DATA_DOCUMENT_FRAGMENT}
`;

export const fetchPartyWithDetails = (id, byContext = true) => ({
  type: FETCH_PARTY_WITH_DETAILS,
  payload: {
    key: 'getPartyData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_WITH_DETAILS_QUERY,
      variables: { id, byContext },
    },
  },
});
