import { Button } from 'antd';
import styled from 'styled-components';

type SidebarErrorProps = { sidebarLabel: string; onActionClick: () => void };

export const SidebarError = ({
  sidebarLabel,
  onActionClick,
}: SidebarErrorProps) => {
  return (
    <StyledWrapper>
      <p className="sidebar_label">
        An error occurred while loading {sidebarLabel}
      </p>
      <Button onClick={onActionClick}>Try again</Button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  text-align: center;

  .sidebar_label {
    color: ${({ theme }) => theme.colors.grayText};
  }
`;
