import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PROJECT_FOR_LIST_FRAGMENT } from '../fragments';

export const FETCH_PARTY_PROJECTS = `${namespace}/FETCH_PARTY_PROJECTS`;

const FETCH_PARTY_PROJECTS_QUERY = gql`
  query listPartyProjects($filters: JSON, $sort: ElementsSort) {
    listPartyProjects(filters: $filters, sort: $sort) {
      data {
        ...ProjectForList
        projectPartyData {
          _id
          partyComplianceProfile {
            name
            complianceProfile
            complianceStats {
              complianceScore
              compliantRequirementsCount
              nonCompliantRequirementsCount
              waivedRequirementsCount
              overriddenRequirementsCount
              totalRequirementsCount
              totalSubjectsCount
              compliantSubjectsCount
            }
          }
        }
      }
      totalCount
    }
  }
  ${PROJECT_FOR_LIST_FRAGMENT}
`;

export const fetchPartyProjects = (payload) => ({
  type: FETCH_PARTY_PROJECTS,
  payload: {
    key: 'listPartyProjects',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_PROJECTS_QUERY,
      variables: payload,
    },
  },
});
