import { useRef } from 'react';
import styled from 'styled-components';

const PlaceholderSuggestionsEntryComponent = ({
  mention,
  theme,
  searchValue,
  isFocused,
  ...parentProps
}) => {
  const ref = useRef();

  return (
    <div {...parentProps} ref={ref}>
      <PlaceholderSuggestionsEntryComponent.Text>
        {mention.description}
      </PlaceholderSuggestionsEntryComponent.Text>
      <PlaceholderSuggestionsEntryComponent.Name>
        {mention.name}
      </PlaceholderSuggestionsEntryComponent.Name>
    </div>
  );
};

PlaceholderSuggestionsEntryComponent.HoverContent = styled.div`
  position: absolute;
  right: 0;
  top: ${({ offsetTop }) => offsetTop};
  width: 230px;
  height: 100%;
`;

PlaceholderSuggestionsEntryComponent.Text = styled.div`
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
`;

PlaceholderSuggestionsEntryComponent.Name = styled.div`
  font-size: 13px;
  color: grey;
`;

export default PlaceholderSuggestionsEntryComponent;
