import styled from 'styled-components';

const Disable = ({ isDisabled, children }) => {
  if (isDisabled) {
    return <Disable.StyledDisable>{children}</Disable.StyledDisable>;
  }

  return children;
};

Disable.StyledDisable = styled.div`
  opacity: 0.25;
  pointer-events: none;
`;

export default Disable;
