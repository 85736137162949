import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

export const AddItemButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <StyledButton type="dashed" icon={<PlusOutlined />} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  &&.ant-btn {
    box-shadow: none;
  }
`;
