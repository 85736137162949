import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { SCHEDULED_REPORT_FRAGMENT } from '../fragments';

export const SEND_SCHEDULED_REPORT = `${namespace}/SEND_SCHEDULED_REPORT`;

const SEND_SCHEDULED_REPORT_MUTATION = gql`
  mutation sendScheduledReport($scheduledReportId: ObjectId!) {
    sendScheduledReport(scheduledReportId: $scheduledReportId) {
      ...ScheduledReport
    }
  }
  ${SCHEDULED_REPORT_FRAGMENT}
`;

export const sendScheduledReport = (scheduledReportId) => ({
  type: SEND_SCHEDULED_REPORT,
  payload: {
    key: 'sendScheduledReport',
    graphql: {
      mutation: SEND_SCHEDULED_REPORT_MUTATION,
      variables: {
        scheduledReportId,
      },
    },
  },
});
