import React, { useEffect } from 'react';
import globalHook from 'use-global-hook';

const useGlobalDocumentInstance = globalHook(
  React,
  {},
  {
    setDocumentInstance: (store, documentInstance) =>
      store.setState({ documentInstance }),
  },
);

export const useDocumentInstance = () => {
  const [{ documentInstance }, { setDocumentInstance }] =
    useGlobalDocumentInstance();

  useEffect(() => () => setDocumentInstance(), [setDocumentInstance]);

  return {
    documentInstance,
    setDocumentInstance,
  };
};
