import { SearchOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CommandBar from '@modules/commandbar/components/CommandBar';
import { getIsCommandBarReady } from '@modules/commandbar/selectors';
import { getActiveOrganizationId } from '@modules/organization/selectors';

type SearchLabelProps = {
  minified?: boolean;
  onClick: () => void;
};

const SearchLabel = ({ minified, onClick }: SearchLabelProps) => {
  const cyId = 'globalSearch';

  if (minified) {
    return (
      <StyledMinifiedWrapper data-cy={cyId} onClick={onClick}>
        <SearchOutlined />
      </StyledMinifiedWrapper>
    );
  }

  return (
    <>
      <StyledSearch data-cy={cyId} onClick={onClick}>
        <SearchOutlined />
        <div className="label">Search...</div>
      </StyledSearch>
    </>
  );
};

export const SearchBar = ({ isMinifiedMode }: { isMinifiedMode?: boolean }) => {
  const isCommandBarReady = useSelector(getIsCommandBarReady);
  const activeOrganizationId = useSelector(getActiveOrganizationId);

  const onSearchOpen = () => {
    window?.CommandBar.open();
  };

  const Loader = isMinifiedMode ? (
    <Skeleton.Avatar active />
  ) : (
    <StyledSkeleton active />
  );

  return (
    <>
      {!isCommandBarReady ? (
        Loader
      ) : (
        <SearchLabel minified={isMinifiedMode} onClick={onSearchOpen} />
      )}

      {Boolean(activeOrganizationId) && <CommandBar />}
    </>
  );
};

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 4px 8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};

  .label {
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.lightTextGray};
  }
`;

const StyledMinifiedWrapper = styled.section`
  padding: 5px 9px;
  cursor: pointer;
`;

const StyledSkeleton = styled(Skeleton.Input)`
  width: 100% !important;
`;
