import { LOCATION_STATE_KEY } from '../constants';

export const LOCATION_PUSH = `${LOCATION_STATE_KEY}/push` as const;

export const push = (path: string) => ({
  type: LOCATION_PUSH,
  payload: path,
});

export type PushAction = ReturnType<typeof push>;

export const LOCATION_CLEAR_PUSH = `${LOCATION_STATE_KEY}/clearPush` as const;

export const clearPush = () => ({
  type: LOCATION_CLEAR_PUSH,
});

export type ClearPushAction = ReturnType<typeof clearPush>;
