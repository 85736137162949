import { type ReactNode, memo } from 'react';
import styled from 'styled-components';

export const CellEditorPopup = memo(({ children }: { children: ReactNode }) => {
  return <StyledCellEditor>{children}</StyledCellEditor>;
});

const StyledCellEditor = styled.div`
  overflow: hidden;
  word-break: break-word;
  max-width: 500px;
  height: auto;
  background-color: white;
  border: 1px solid #ccc;
  outline: none;
  margin: -1px;
  padding: 10px;
`;
