import { namespace } from '../constants';

export const DOWNLOAD_INIT = `${namespace}/DOWNLOAD_INIT`;

export const downloadInit = (payload) => ({
  type: DOWNLOAD_INIT,
  payload: {
    ...payload,
    id: Date.now(),
    percentage: 0,
  },
});
