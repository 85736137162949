import * as R from 'ramda';

import { getActiveOrganizationId } from '@modules/organization/selectors';

import { STATE_KEY, defaultSearchParams } from '../constants';

export const getLastUsedEffectiveDates = (state) =>
  R.path(
    [STATE_KEY, getActiveOrganizationId(state), 'lastUsedEffectiveDates'],
    state,
  );

export const getLastUsedExpirationDates = (state) =>
  R.path(
    [STATE_KEY, getActiveOrganizationId(state), 'lastUsedExpirationDates'],
    state,
  );

export const getCurrentProjectId = (state) =>
  R.pathOr(
    null,
    [STATE_KEY, getActiveOrganizationId(state), 'currentProjectId'],
    state,
  );

export const getPreferredSearchParams = (state) => {
  const orgId = getActiveOrganizationId(state);

  return R.compose(
    R.mergeDeepRight(defaultSearchParams),
    R.pick([
      'parties',
      'projects',
      'documents',
      'party-documents',
      'party-projects',
      'settings-integrations-parties',
      'settings-integrations-projects',
    ]),
    R.pathOr({}, [STATE_KEY, orgId]),
  )(state);
};

export const getUploadedDocumentsPanels = (state, requestId) =>
  R.pathOr([], [STATE_KEY, 'uploadedDocumentsPanels', requestId], state);

export const getAdvancedPartyComplianceIndicator = (state) =>
  R.path(
    [
      STATE_KEY,
      getActiveOrganizationId(state),
      'advancedPartyComplianceIndicator',
    ],
    state,
  );
