import { useDispatch } from 'react-redux';

import ProgressNotification from '@common/components/ProgressNotification';

import { downloadCancel } from '../actions';

const DownloadProgress = ({ download }) => {
  const dispatch = useDispatch();

  return (
    <ProgressNotification
      notificationKey={download.id}
      percentage={download.percentage}
      onClose={() => dispatch(downloadCancel(download.id))}
    />
  );
};

export default DownloadProgress;
