export const FILTERED_COLUMN_FIELDS = {
  party: 'party',
  subject: 'subject',
  expirationDate: 'expirationDate',
  createdAt: 'createdAt',
  friendlyName: 'friendlyName',
  type: 'type',
  reviewStatus: 'reviewStatus',
  severity: 'severity',
  verification: 'verification',
  requirementId: 'requirementId',
  projects: 'projects',
  archiveStatus: 'archiveStatus',
  subjects: 'subjects',
  appliesToAllProjects: 'appliesToAllProjects',
  partyId: 'partyId',
} as const;

export const COLUMN_FIELDS = {
  recap: 'recap',
  badges: 'badges',
  context: 'context',
  actions: 'actions',
  ...FILTERED_COLUMN_FIELDS,
} as const;
