import { Loader } from '@googlemaps/js-api-loader';
import { AutoComplete, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import styled from 'styled-components';

import { normalizeGooglePlacesResult } from '@common/components/FormattedAddress';

type AddressState = {
  addressLine1: string;
  addressLine2?: string;
  postalCode: string;
  city: string;
  region: string;
  country: string;
  latitude: number;
  longitude: number;
};

type AddressAutocompleteInputProps = {
  inputValue: string;
  placeholder?: string;
  onInputChange: (value: string) => void;
  onSelect?: (params: {
    addressDescription: string;
    addressState: AddressState;
  }) => void;
  onChange?: (value: string) => void;
};

const { Option } = AutoComplete;

const loader = new Loader({
  apiKey: import.meta.env.VITE_GMAPS_API_KEY,
  version: 'weekly',
});

/**
 * Controlled component that allows users to search for an address using Google Places API.
 */
export const AddressAutocompleteInput = ({
  inputValue,
  placeholder,
  onSelect,
  onInputChange,
}: AddressAutocompleteInputProps) => {
  const [isGooglePlacesReady, setIsGooglePlacesReady] = useState(false);

  const handleOnChange = (value: string) => {
    onInputChange?.(value);
  };

  const handleOnSelect = async (selectedAddress: string) => {
    const geoCode = await geocodeByAddress(selectedAddress);
    const attrs = normalizeGooglePlacesResult(geoCode[0]);

    const { lat: latitude, lng: longitude } = await getLatLng(geoCode[0]);

    onSelect?.({
      addressDescription: selectedAddress,
      addressState: {
        ...attrs,
        latitude,
        longitude,
      },
    });
  };

  useEffect(() => {
    const importPlaces = async () => {
      try {
        await loader.importLibrary('places');
        setIsGooglePlacesReady(true);
      } catch (e) {
        console.error('Failed to load the Google Places library.');
        console.error(e);
      }
    };

    importPlaces();
  }, []);

  if (!isGooglePlacesReady) {
    return <StyledSkeleton active />;
  }

  return (
    <>
      <PlacesAutocomplete
        debounce={500}
        onChange={() => {}} // This is required to prevent an error
        searchOptions={{ types: ['geocode'] }}
        value={inputValue || ''}
      >
        {({ getInputProps, suggestions }) => {
          const { onChange: originalOnChange, ...otherProps } = getInputProps();

          return (
            <StyledAutomplete
              {...otherProps}
              onChange={(value) => {
                originalOnChange({ target: { value } });
                handleOnChange(value);
              }}
              onSelect={handleOnSelect}
              placeholder={placeholder || 'Street address, city...'}
            >
              {suggestions.map((suggestion) => (
                <Option
                  key={suggestion.description}
                  value={suggestion.description}
                >
                  {suggestion.description}
                </Option>
              ))}
            </StyledAutomplete>
          );
        }}
      </PlacesAutocomplete>
      <StyledText>
        Start typing the address and select an option from the dropdown.
      </StyledText>
    </>
  );
};

const StyledSkeleton = styled(Skeleton.Input)`
  & > span {
    width: 100% !important;
  }
`;

const StyledText = styled.p`
  font-size: 12px;
  margin: 10px 0 0;
  color: ${({ theme }) => theme.colors.lightTextGray};
`;

const StyledAutomplete = styled(AutoComplete)`
  width: 100%;
`;
