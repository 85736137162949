import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const IMPORT_PARTIES = `${namespace}/IMPORT_PARTIES`;

const IMPORT_PARTIES_MUTATION = gql`
  mutation importParties($payload: ImportPartiesInput!) {
    importParties(data: $payload)
  }
`;

export const importParties = (payload) => ({
  type: IMPORT_PARTIES,
  payload: {
    key: 'importParties',
    graphql: {
      mutation: IMPORT_PARTIES_MUTATION,
      variables: { payload },
      context: { hasUpload: true },
    },
  },
});
