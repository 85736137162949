import { all, fork } from 'redux-saga/effects';

import appSagas from '@modules/app/sagas';
import authSagas from '@modules/auth/sagas';
import commandBarSagas from '@modules/commandbar/sagas';
import complianceProfileSagas from '@modules/compliance-profile/sagas';
import customFieldsSagas from '@modules/custom-field/sagas';
import documentTypeSagas from '@modules/document-type/sagas';
import documentSagas from '@modules/document/sagas';
import downloadSaga from '@modules/download/sagas';
import eventLogsSaga from '@modules/event-log/sagas';
import fillableFormSagas from '@modules/fillable-form/sagas';
import organizationMemberSagas from '@modules/organization-member/sagas';
import organizationSagas from '@modules/organization/sagas';
import partyComplianceProfileSagas from '@modules/party-compliance-profile/sagas';
import partyConversationSagas from '@modules/party-conversation/sagas';
import partyTypeSagas from '@modules/party-type/sagas';
import partySagas from '@modules/party/sagas';
import projectSagas from '@modules/project/sagas';
import reportSagas from '@modules/report/sagas';
import requestSagas from '@modules/request/sagas';
import requirementSagas from '@modules/requirement/sagas';
import searchSagas from '@modules/search/sagas';
import systemSettingsSagas from '@modules/system-settings/sagas';
import textSnippetsSagas from '@modules/text-snippets/sagas';
import userSagas from '@modules/user/sagas';

function* rootSaga() {
  yield all([
    fork(appSagas),
    fork(authSagas),
    fork(commandBarSagas),
    fork(customFieldsSagas),
    fork(documentSagas),
    fork(documentTypeSagas),
    fork(organizationSagas),
    fork(organizationMemberSagas),
    fork(partySagas),
    fork(partyConversationSagas),
    fork(partyTypeSagas),
    fork(partyComplianceProfileSagas),
    fork(projectSagas),
    fork(requestSagas),
    fork(requirementSagas),
    fork(complianceProfileSagas),
    fork(userSagas),
    fork(textSnippetsSagas),
    fork(eventLogsSaga),
    fork(systemSettingsSagas),
    fork(reportSagas),
    fork(fillableFormSagas),
    fork(searchSagas),
    fork(downloadSaga),
  ]);
}

export default rootSaga;
