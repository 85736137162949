import debounce from 'lodash/debounce';
import { useState } from 'react';

import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getUpdatedEditorState,
} from '@common/components/RichTextEditor/helpers';

const ProjectTextField = ({ customFieldName, value, updateValue }) => {
  const [editorState, setEditorState] = useState(getUpdatedEditorState(value));

  const debouncedUpdateValue = debounce((content) => updateValue(content), 500);

  const handleEditorChange = (updatedEditorState) => {
    setEditorState(updatedEditorState);
    debouncedUpdateValue(getEditorHTMLContent(updatedEditorState));
  };

  return (
    <RichTextEditor
      isValid
      minHeight={0}
      maxHeight={120}
      placeholder={`Enter ${customFieldName}...`}
      editorState={editorState}
      onChange={handleEditorChange}
    />
  );
};

export default ProjectTextField;
