import uniqBy from 'lodash/uniqBy';

import { useMutationWithRedux, useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import {
  REMOVE_BULK_DOCUMENT_TYPES,
  REMOVE_BULK_DOCUMENT_TYPES_KEY,
} from '../../../../../actions';

type UseDocumentTypesParams = {
  documentIds: string[];
  options: any;
};

type Foo = {
  _id: string;
  name: string;
};

const getUniqueTypes = (documents: any) => {
  if (!documents || !documents.length) {
    return [];
  }

  const types = documents.reduce(
    (acc: any, document: any) =>
      document?.types ? [...acc, document.types] : acc,
    [],
  );

  return uniqBy<Foo>(types.flat(), '_id');
};

const DOCUMENTS_TYPES_QUERY = graphql(`
  query RemoveTypeModalDocumentTypes($filter: Any) {
    listDocuments(filter: $filter) {
      types {
        _id
        name
      }
    }
  }
`);

const REMOVE_DOCUMENT_TYPES_MUTATION = graphql(`
  mutation RemoveDocumentsTypes($data: bulkDocumentTypesInput!) {
    removeBulkDocumentTypes(data: $data) {
      _id
      types {
        _id
        name
        pluralName
        isSystemType
      }
    }
  }
`);

export const useDocumentsTypes = ({
  documentIds,
  options,
}: UseDocumentTypesParams) => {
  const { data, loading: isLoadingTypes } = useQuery(DOCUMENTS_TYPES_QUERY, {
    variables: {
      filter: {
        ids: documentIds,
      },
    },
    skip: options.skip,
  });

  const [removeDocumentsTypes, { loading: isRemovingTypes }] =
    useMutationWithRedux(REMOVE_DOCUMENT_TYPES_MUTATION, {
      reduxActionKey: REMOVE_BULK_DOCUMENT_TYPES_KEY,
      reduxActionType: REMOVE_BULK_DOCUMENT_TYPES,
    });

  return {
    types: getUniqueTypes(data?.listDocuments),
    removeDocumentsTypes,
    isLoadingTypes,
    isRemovingTypes,
  };
};
