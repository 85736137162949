import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  isAsideNavigationCollapsed,
  setIsAsideNavigationCollapsed,
} from '@modules/user';

import { ContextsAddOnFeature } from '@modules/add-on';
import {
  AppOutdatedCardMenu,
  Header,
  NavigationMenu,
  SearchBar,
} from './containers';
import { SettingsMenu } from './containers/SettingsMenu';

type PageLayoutWithSideNavigationProps = {
  organizationCode: string;
  children: React.ReactNode;
};

export const PageLayoutWithSideNavigation = ({
  children,
  organizationCode,
}: PageLayoutWithSideNavigationProps) => {
  const isAsideCollapsed = useSelector(isAsideNavigationCollapsed);
  const dispatch = useDispatch();

  const onToggleSider = () => {
    dispatch(setIsAsideNavigationCollapsed(!isAsideCollapsed));
  };

  return (
    <StyledPageLayout>
      <StyledLayoutSider
        width={250}
        collapsedWidth="60"
        collapsed={isAsideCollapsed}
        onCollapse={onToggleSider}
        data-cy="mainMenu"
      >
        <StyledCollapseButton type="text" onClick={onToggleSider}>
          {isAsideCollapsed ? (
            <RightOutlined className="rightIcon" />
          ) : (
            <LeftOutlined className="leftIcon" />
          )}
        </StyledCollapseButton>
        <StyledSettingsMenuWrapper>
          <SettingsMenu isMinifiedMode={isAsideCollapsed} />
        </StyledSettingsMenuWrapper>
        <StyledSearcWrapper>
          <SearchBar isMinifiedMode={isAsideCollapsed} />
        </StyledSearcWrapper>
        <NavigationMenu organizationCode={organizationCode} />
        <AppOutdatedCardMenu
          isMinifiedMode={isAsideCollapsed}
          onClick={onToggleSider}
        />
      </StyledLayoutSider>
      <Layout>
        <ContextsAddOnFeature>
          <Header />
        </ContextsAddOnFeature>
        <StyledContent>{children}</StyledContent>
      </Layout>
    </StyledPageLayout>
  );
};

const StyledLayoutSider = styled(Layout.Sider)`
  border-right: 1px solid rgba(196, 214, 236, 0.5);
  background-color: #fafafa !important;
`;

const StyledSettingsMenuWrapper = styled.section`
  padding: 15px 12px;
  height: 64px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  margin-bottom: 8px;
`;

const StyledSearcWrapper = styled.section`
  padding: 8px 12px;
`;

const StyledPageLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

const StyledContent = styled(Layout.Content)`
  height: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

const StyledCollapseButton = styled(Button)`
  background-color: #fff;
  position: absolute;
  z-index: 10;
  right: -10px;
  top: 50px;
  padding: 0;
  border: 1px solid #d9d9d9;
  width: 22px;
  height: 22px;
  font-size: 13px;

  &&:hover {
    background-color: #fff;
  }

  .leftIcon {
    padding-right: 1px;
  }

  .rightIcon {
    padding-left: 1px;
  }
`;
