import { EventType } from './party';

export * from './document';
export * from './party';
export * from './tags';

export {
  SCHEDULED_ACTIONS_STATUS,
  PARTY_AUTOMATION_STATUS,
  BULK_ACTION,
} from './partyAutomations';

export const STATE_KEY = 'party';
export const namespace = 'PARTY';

export const bulkEventDefaultMessage = {
  [EventType.bulkUpdateActiveStatusPartiesSuccess]: `Your parties status have been successfully updated`,
  [EventType.BulkDeletePartiesSuccess]: `Your parties have been successfully deleted`,
  [EventType.bulkAssignComplianceProfilePartiesSuccess]: `Your compliance profile has been successfully added to parties`,
  [EventType.bulkAssignPartyTypePartiesSuccess]: `Your party type has been successfully added to parties`,
  [EventType.bulkAddTagsPartiesSuccess]: `Your tags have been successfully added to parties`,
  [EventType.bulkDeleteTagsPartiesSuccess]: `Your tags have been successfully deleted from parties`,
  [EventType.bulkAddPartiesToProjectSuccess]: `Your parties have been successfully added to project`,
  [EventType.bulkEnablePartiesContactsRemindersSuccess]: `Your party contacts have been successfully enabled`,
};

export const bulkEventAnalyticsMessage = {
  [EventType.bulkUpdateActiveStatusPartiesSuccess]:
    'User updated parties status',
  [EventType.BulkDeletePartiesSuccess]: 'User deleted parties',
  [EventType.bulkAssignComplianceProfilePartiesSuccess]:
    'User added compliance profile to parties',
  [EventType.bulkAssignPartyTypePartiesSuccess]:
    'User added party type to parties',
  [EventType.bulkAddTagsPartiesSuccess]: 'User added tags to parties',
  [EventType.bulkDeleteTagsPartiesSuccess]: 'User deleted tags from parties',
};

export const ContactEmailFilterOptionCodes = {
  withContactEmail: 'withContactEmail',
  withoutContactEmail: 'withoutContactEmail',
};

export const ContactEmailFilterOptionLabels = {
  [ContactEmailFilterOptionCodes.withContactEmail]: 'With Contact Email',
  [ContactEmailFilterOptionCodes.withoutContactEmail]: 'Without Contact Email',
};

export const partyDetailsTabs = {
  Overview: {
    name: 'Overview',
    key: 'overview',
  },
  Compliance: {
    name: 'Compliance',
    key: 'compliance',
  },
  Documents: {
    name: 'Documents',
    key: 'documents',
  },
  Projects: {
    name: 'Projects',
    key: 'projects',
  },
  CustomFields: {
    name: 'Custom Fields',
    key: 'customFields',
  },
  Communications: {
    name: 'Contacts & Messages',
    key: 'messages',
  },
};

export const SUBJECT_STATUS = {
  WAIVED: 'waived',
  OVERRIDDEN: 'overridden',
} as const;
