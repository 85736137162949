export enum FILTER_OPERATORS {
  contains = 'contains',
  notContains = 'notContains',
  equal = 'equal',
  notEqual = 'notEqual',
  in = 'in',
  notIn = 'notIn',
  greaterThan = 'greaterThan',
  greaterThanEqual = 'greaterThanEqual',
  lessThan = 'lessThan',
  lessThanEqual = 'lessThanEqual',
  between = 'between',
  beforeLast = 'beforeLast',
  beforeExactly = 'beforeExactly',
  withinLast = 'withinLast',
  withinNext = 'withinNext',
  afterExactly = 'afterExactly',
  afterNext = 'afterNext',
  match = 'match',
}

export enum FILTER_TYPES {
  options = 'options',
  string = 'string',
  number = 'number',
  id = 'id',
  date = 'date',
  match = 'match',
}

export type Filter<FilterName = string, FilterValue = any> = {
  name: FilterName;
  operator: FILTER_OPERATORS;
  value: FilterValue;
};

export type ServerFilterInput = {
  and: Filter[];
};
