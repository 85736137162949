import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchPartiesForSearch } from '@modules/party/actions';
import { getGraphqlPayload } from '@store/helpers';

// eslint-disable-next-line fp/no-let
let lastFetchId = 0;

export const usePartiesByName = () => {
  const [partyData, setPartyData] = useState([]);
  const [searchPartyName, setSearchPartyName] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchPartyData = async (value) => {
    if (value) {
      setLoading(true);

      lastFetchId += 1;
      const fetchId = lastFetchId;
      const res = await dispatch(
        fetchPartiesForSearch({ filters: { name: value } }),
      );

      if (fetchId !== lastFetchId) {
        return;
      }
      const data = R.compose(R.propOr([], 'data'), getGraphqlPayload)(res);
      setPartyData(data);

      setLoading(false);
    } else {
      setPartyData([]);
    }
  };

  const handleChange = (value) => {
    setPartyData([]);
    setSearchPartyName(value);
    setLoading(false);
  };

  return {
    fetchPartyData,
    partyData,
    searchPartyName,
    setSearchPartyName,
    handleChange,
    loading,
    setPartyData,
  };
};
