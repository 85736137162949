import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_TYPE_FRAGMENT } from '../fragments';

export const FETCH_DOCUMENT_TYPES = `${namespace}/FETCH_DOCUMENT_TYPES`;

export const FETCH_DOCUMENT_TYPES_QUERY = gql`
  query listDocumentTypes {
    listDocumentTypes {
      ...DocumentType
    }
  }
  ${DOCUMENT_TYPE_FRAGMENT}
`;

export const fetchDocumentTypes = () => ({
  type: FETCH_DOCUMENT_TYPES,
  payload: {
    key: 'listDocumentTypes',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_DOCUMENT_TYPES_QUERY,
    },
  },
});
