export const STATUS_MAP = {
  LINKED: {
    key: 'linked',
    title: 'Linked',
  },
  NO_MATCH: {
    key: 'no_match',
    title: 'No match found',
  },
  MATCH: {
    key: 'match_found',
    title: 'Exact match found',
  },
  PARTIAL_MATCH: {
    key: 'partial_match_found',
    title: 'Possible match found',
  },
  IGNORED: {
    key: 'ignored',
    title: 'Do not add to TrustLayer',
  },
};

export const statusList = Object.values(STATUS_MAP);
