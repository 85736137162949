import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import 'draft-js/dist/Draft.css';

import RichTextEditor from '@common/components/RichTextEditor';
import ToastMessage from '@common/components/ToastMessage';
import { INVALID_FORMAT_ERROR_MESSAGE } from '@common/constants';
import * as PartyActions from '@modules/party/actions';
import FilePillow from '@modules/request/components/landing/FilePillow';
import { fileValidator } from '@modules/request/utils/request-helpers';
import { getGraphqlPayload } from '@store/helpers';

const RichTextArea = ({
  isValid,
  isAttaching,
  minHeight = 30,
  attachments,
  setAttachments,
  setIsAttaching,
  focusDependence,
  textAreaEditorState,
  onChange,
}) => {
  const dispatch = useDispatch();

  const [isUploadErrorVisible, setIsUploadErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDropRejected = (files) => {
    const fileNames = files.map(({ file }) => file.name);
    setErrorMessage(
      <span>
        {INVALID_FORMAT_ERROR_MESSAGE}
        <strong> {fileNames.join(', ')}</strong>
      </span>,
    );
    setIsUploadErrorVisible(true);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (Boolean(acceptedFiles.length)) {
        setIsAttaching(true);
        const processedAttachments = await Promise.all(
          acceptedFiles.map((file) =>
            dispatch(PartyActions.processImportFile({ file })),
          ),
        );
        setIsAttaching(false);
        setAttachments([
          ...attachments,
          ...processedAttachments.map((payload) => getGraphqlPayload(payload)),
        ]);
      }
    },
    [attachments, dispatch, setAttachments, setIsAttaching],
  );

  const removeAttachment = (indexToRemove) => {
    setAttachments(attachments.filter((_, index) => index !== indexToRemove));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDrop,
    validator: fileValidator,
  });

  return (
    <div>
      <ToastMessage
        isError
        content={errorMessage}
        isVisible={isUploadErrorVisible}
        messageKey="uploadErrorToast"
        duration={5}
        onToastDestroy={() => setIsUploadErrorVisible(false)}
      />

      <RichTextEditor
        isValid={isValid}
        minHeight={minHeight}
        placeholder="Enter your message..."
        editorState={textAreaEditorState}
        onChange={onChange}
        focusDependence={focusDependence}
      />
      {!isValid && <StyledExplainText>Enter the message</StyledExplainText>}
      <StyledAttachments>
        <StyledAttachmentsPillows>
          {attachments.map((document, index) => (
            <FilePillow
              key={index}
              name={document.friendlyName}
              onRemove={() => removeAttachment(index)}
            />
          ))}
          <Spin spinning={isAttaching} indicator={<LoadingOutlined />} />
          {isAttaching && <span>Uploading files...</span>}
        </StyledAttachmentsPillows>
        <div {...getRootProps()}>
          <Button
            type="link"
            data-cy="addAttachment"
            icon={<PaperClipOutlined />}
          >
            <input {...getInputProps()} />
            Add attachment
          </Button>
        </div>
      </StyledAttachments>
    </div>
  );
};

const StyledExplainText = styled.div`
  margin-left: 3px;
  color: #f5222d;
  font-size: 14px;
  line-height: 30px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const StyledAttachments = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  .ant-btn-link {
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
  }

  .drop-input {
    border: red;
  }
`;

const StyledAttachmentsPillows = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

export default RichTextArea;
