import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const CONTACTS_BY_EMAIL_QUERY = graphql(`
  query ContactsByEmail($input: ContactsInput) {
    contacts(input: $input) {
      totalCount
    }
  }
`);

export const useValidateEmail = () => {
  const [contactsByEmail, { loading }] = useLazyQuery(CONTACTS_BY_EMAIL_QUERY);

  const isEmailTaken = useCallback(
    async (email: string) => {
      const response = await contactsByEmail({
        variables: {
          input: {
            filter: {
              and: [
                {
                  name: 'email',
                  operator: FILTER_OPERATORS.equal,
                  value: email,
                },
              ],
            },
          },
        },
      });

      return Boolean(response.data?.contacts.totalCount);
    },
    [contactsByEmail],
  );

  return {
    isEmailTaken,
    loading,
  };
};
