import { MinusCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import styled from 'styled-components';

import TagLabel from '@common/components/TagLabel';
import TwoLinesText from '@common/components/TwoLinesText';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { ComplianceProfilesSelect } from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import { useDisableProjectComplianceFeatureFlag } from '@modules/feature-flags/hooks';

const ProjectPartiesTabView = ({
  party,
  complianceProfiles,
  selectedProfile,
  onDeleteParty,
  onAssignProfile,
}) => {
  const { isDisableProjectComplianceFeatureFlagEnabled } =
    useDisableProjectComplianceFeatureFlag();
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const complianceProfileData = complianceProfiles.find(
    ({ _id }) => _id === selectedProfile,
  );

  /* 
  When a new profile is selected, a getComplianceProfile will be dispatched by the SaveProjectModal
  and complianceProfileData will be undefined until the query is completed. In this timeframe selectedProfileValue will be selectedProfile thus the id,
  which will make the ComplianceProfilesSelect fetches the name of the profile since its missing int he value, 
  even if it will come in a moment because of the getComplianceProfile. In some way its better, in some is redundant.
  To avoid this additional fetch, selectedProfile must become not just the id but an object with also the name
  */
  const selectedProfileValue = complianceProfileData
    ? {
        _id: complianceProfileData._id,
        name: complianceProfileData.name,
      }
    : selectedProfile;

  return (
    <ProjectPartiesTabView.ListItem>
      <TwoLinesText>
        <ProjectPartiesTabView.PartyName data-cy="projectPartyName">
          {party.name}
        </ProjectPartiesTabView.PartyName>
        <ProjectPartiesTabView.PartyType>
          {party?.type?.name}
          {!party.isActive && (
            <TagLabel data-cy="inactivePartyTagLabel">inactive</TagLabel>
          )}
        </ProjectPartiesTabView.PartyType>
      </TwoLinesText>
      <ProjectPartiesTabView.Actions>
        {isComplianceRequirementsAddOnEnabled && (
          <>
            {party?.isTrackingCompliance ? (
              <ComplianceProfilesSelect
                data-cy="partiesTabSelectComplianceProfile"
                allowClear={isDisableProjectComplianceFeatureFlagEnabled}
                value={selectedProfileValue}
                onChange={({ _id } = {}) => onAssignProfile(_id)}
                size="middle"
              />
            ) : (
              <ProjectPartiesTabView.NoComplianceTracking>
                No compliance tracking
              </ProjectPartiesTabView.NoComplianceTracking>
            )}
          </>
        )}

        <ProjectPartiesTabView.Remove
          data-cy="projectRemovePartyButton"
          onClick={() => onDeleteParty(party._id)}
        />
      </ProjectPartiesTabView.Actions>
    </ProjectPartiesTabView.ListItem>
  );
};

ProjectPartiesTabView.ListItem = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  padding: 5px 0;
`;

ProjectPartiesTabView.PartyName = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

ProjectPartiesTabView.PartyType = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);

  i {
    font-style: normal;
    margin-left: 15px;
    color: #1890ff;
  }
`;

ProjectPartiesTabView.Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

ProjectPartiesTabView.NoComplianceTracking = styled.span`
  opacity: 0.4;
`;

ProjectPartiesTabView.Remove = styled(MinusCircleOutlined)`
  margin-left: 25px;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

ProjectPartiesTabView.Select = styled(Select)`
  flex-grow: 1;
`;

export default ProjectPartiesTabView;
