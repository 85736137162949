export const DOCUMENT_ACTION_TYPES = {
  assignToParty: 'assignToParty',
  detachFromParty: 'detachFromParty',
  associateToProject: 'associateToProject',
  detachFromProject: 'detachFromProject',
  updateReviewStatus: 'updateReviewStatus',
  updateArchiveStatus: 'updateArchiveStatus',
  download: 'download',
  delete: 'delete',
  addTypes: 'addTypes',
  removeTypes: 'removeTypes',
  matchRequirements: 'matchRequirements',
  preview: 'preview',
  addRequirement: 'addRequirement',
} as const;

export type ActionType =
  (typeof DOCUMENT_ACTION_TYPES)[keyof typeof DOCUMENT_ACTION_TYPES];
