import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EVENT_LOG_FRAGMENT } from '../fragments';

export const CREATE_EVENT = `${namespace}/CREATE_EVENT`;

// record event logs
const CREATE_EVENT_QUERY = gql`
  mutation createEvent($payload: EventLogInput!) {
    createEvent(data: $payload) {
      ...EventLog
    }
  }
  ${EVENT_LOG_FRAGMENT}
`;

export const createEvent = (payload) => ({
  type: CREATE_EVENT,
  payload: {
    key: 'createEvent',
    graphql: {
      fetchPolicy: 'no-cache',
      query: CREATE_EVENT_QUERY,
      variables: { payload },
    },
  },
});
