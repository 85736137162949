import {
  COMPLIANCE_STATUS,
  type ComplianceStatus,
} from './ComplianceStats.constants';

export function getComplianceStatusFromValue(
  value: number,
  total: number,
): ComplianceStatus | undefined {
  if (value === 0 && total > 0) {
    return COMPLIANCE_STATUS.BAD;
  }

  if (value < total) {
    return COMPLIANCE_STATUS.PROGRESS;
  }

  if (value === total) {
    return COMPLIANCE_STATUS.GOOD;
  }
}
