import { gql } from '@apollo/client';
import { message as notify } from 'antd';
import pluralize from 'pluralize';
import * as R from 'ramda';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import graphqlClient from '@graphql/client';
import {
  SET_AUTHENTICATED_APP_READY,
  setAuthenticatedAppReady,
} from '@modules/app/actions';
import { logout } from '@modules/auth/actions';
import { updateOrganizationDocumentState } from '@modules/document/actions';
import { EventType } from '@modules/document/constants';
import { PROVIDERS } from '@modules/integration/constants';
import { fetchPartiesForSearch } from '@modules/party/actions';
import {
  fetchProjectsForSearch,
  fetchProjectsTotalCount,
} from '@modules/project/actions';
import { fetchQuickbooksAuthLink } from '@modules/quickbooks/actions';
import { push } from '@modules/router/actions';
import { getGraphqlPayload } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import {
  CONFIGURE_INTEGRATIONS_STATUS,
  CREATE_ORGANIZATION,
  CREATE_PERSONAL_ACCESS_TOKEN,
  DELETE_ORGANIZATION_ACTION,
  DELETE_PERSONAL_ACCESS_TOKEN,
  FETCH_INTEGRATION,
  FETCH_INTEGRATIONS_META,
  SET_ACTIVE_ORGANIZATION,
  UPDATE_ACTIVE_ORGANIZATION,
  UPDATE_PERSONAL_ACCESS_TOKEN,
  configureStatus,
  deleteOrganization,
  fetchIntegrationMeta,
  fetchIntegrationStatus,
  fetchPersonalAccessTokensByOrganizationId,
  setActiveOrganization,
  setIsReloadPage,
} from '../actions';
import { getIntegrationKey } from '../reducers';
import {
  getActiveIntegrations,
  getActiveOrganizationId,
  getIntegrationData,
  getIntegrationStatus,
  getOrganizations,
} from '../selectors';

// Constants

const ORGANIZATION_DOCUMENT_SUBSCRIPTION = gql`
  subscription onOrganizationUpdates($data: String!) {
    organizationUpdates(organization: $data)
  }
`;

function* subscribeOrganizationSaga() {
  const organization = yield select(getActiveOrganizationId);
  if (organization) {
    yield fork(subscribeToOrganizationSaga, organization);
  }
}

function* createOrUpdateOrganizationFailSaga(action) {
  yield notify.error(
    R.pathOr(
      'Unable to update organization',
      ['error', '0', 'message'],
      action,
    ),
  );
}

function* createOrganizationSuccessSaga(action) {
  const organization = R.path(
    ['payload', 'data', 'createOrganization'],
    action,
  );
  yield put(setActiveOrganization(organization));
  yield put(push(`/`));
  yield put(setAuthenticatedAppReady());

  notify.success(`${organization.name} created successfully.`);

  yield call(trackEvent, 'User created an organization');
}

function* updateOrganizationSuccessSaga(action) {
  const organization = getGraphqlPayload(action);
  yield put(setActiveOrganization(organization));
  yield notify.success('Changes saved successfully.', 3);
}

function* deleteOrganizationSaga({ payload: { id } }) {
  const organizations = yield select(getOrganizations);
  const nextOrganization = R.compose(
    R.head,
    R.filter((organization) => organization.id !== id),
  )(organizations);

  yield putResolve(deleteOrganization(id));

  yield call(trackEvent, 'User deleted an organization');

  if (nextOrganization) {
    yield put(setActiveOrganization(nextOrganization));
    yield put(push('/'));
  } else {
    yield putResolve(logout());
    yield put(push('/login'));
  }
}

/**
 * Update state by organization subscription
 */
function* updateStateOrganizationSubscription(payload) {
  const type = R.pathOr('', ['data', 'organizationUpdates', 'type'], payload);
  const result = R.pathOr('', ['data', 'organizationUpdates', 'data'], payload);

  switch (type) {
    case EventType.documentProcessed: {
      yield put(updateOrganizationDocumentState(payload));
      yield notify.success(
        `Document processing completed. Attributes extracted successfully.`,
      );
      break;
    }
    case EventType.documentEmptyExtractedData: {
      yield put(updateOrganizationDocumentState(payload));
      yield notify.success(`Document processing completed.`);
      break;
    }
    case EventType.importPartiesSuccess: {
      yield put(setIsReloadPage(true));
      if (result.message) {
        yield notify.success(result.message);
      } else {
        yield notify.success(`Your import files were successfully processed`);
      }
      yield call(trackEvent, 'User bulk-imported parties');
      break;
    }
    case EventType.importPartiesFail: {
      yield notify.error(result.message);
      break;
    }
    case EventType.assignProfilesSuccess: {
      const { partiesCount, projectsCount, complianceProfileName } = result;

      const feedbackMessage =
        projectsCount > 0
          ? `${pluralize('party', partiesCount, true)} across ${pluralize(
              'project',
              projectsCount,
              true,
            )} were affected.`
          : `${pluralize('party', partiesCount, true)} were affected.`;

      yield call(
        notify.success,
        `${complianceProfileName} compliance profile was updated.
        ${feedbackMessage}`,
      );
      break;
    }
    case EventType.assignProfilesFail: {
      yield notify.error(result.message);
      break;
    }
    default:
      return;
  }
}

/**
 * Document listener using organization queue
 */
export function* subscribeToOrganizationSaga(organization) {
  const organizationChannel = yield call(() =>
    eventChannel((emit) => {
      const subscription = graphqlClient
        .subscribe({
          query: ORGANIZATION_DOCUMENT_SUBSCRIPTION,
          variables: {
            data: organization,
          },
        })
        .subscribe({
          next(data) {
            emit(data);
          },
        });
      const unsubscribe = () => {
        subscription.unsubscribe();
      };

      return unsubscribe;
    }),
  );

  // eslint-disable-next-line fp/no-loops
  while (true) {
    try {
      const payload = yield take(organizationChannel);
      yield fork(updateStateOrganizationSubscription, payload);
    } catch (err) {
      console.error('Socket error:', err);
    }
  }
}

function* fetchIntegrationSaga({ payload }) {
  const currentIntegration = yield select((state) =>
    getIntegrationData(state, payload.provider, payload.instance),
  );

  // TODO: move quickbooks auth cheks outside this saga
  if (payload.provider === PROVIDERS.QUICKBOOKS.key) {
    yield putResolve(fetchQuickbooksAuthLink());
  }

  if (currentIntegration.externalId) {
    // eslint-disable-next-line fp/no-loops
    while (yield delay(1000)) {
      const integrationStatus = yield putResolve(
        fetchIntegrationStatus({
          organizationId: currentIntegration?.organizationId,
          provider: currentIntegration?.provider,
          ...payload,
        }),
      );

      const isInProgress = R.path(
        ['payload', 'data', 'integrationStatus', 'status', 'inProgress'],
        integrationStatus,
      );

      if (!isInProgress) {
        yield cancel();
      }
    }
  }
}

function* fetchIntegrationsMetaSaga() {
  const activeIntegrations = yield select(getActiveIntegrations);

  // TODO: move quickbooks auth cheks outside this saga
  if (
    R.find(R.propEq('provider', PROVIDERS.QUICKBOOKS.key))(activeIntegrations)
  ) {
    yield putResolve(fetchQuickbooksAuthLink());
  }

  // eslint-disable-next-line fp/no-loops
  for (const integration of activeIntegrations) {
    if (!integration?.externalId) continue;
    yield put(
      fetchIntegrationMeta({
        organizationId: integration?.organizationId,
        provider: integration?.provider,
        instance: integration?.instance,
      }),
    );
  }
}

function* fetchPersonalAccessTokensSaga() {
  const activeOrganizationId = yield select(getActiveOrganizationId);
  yield putResolve(
    fetchPersonalAccessTokensByOrganizationId(activeOrganizationId),
  );
}

function* notifyDeletePersonalAccessTokenSuccessSaga() {
  yield notify.success('Token deleted successfully!');
}

function* notifyUpdatePersonalAccessTokenSuccessSaga() {
  yield notify.success('Token updated successfully!');
}

function* getProjectsTotalCount() {
  yield putResolve(fetchProjectsTotalCount());
}

function* configureStatusSuccesSaga({ payload }) {
  const { data, ...pageOptions } = payload;

  const currentIntegration = yield select((state) =>
    getIntegrationData(state, data.provider, data.instance),
  );

  yield putResolve(
    configureStatus({
      organizationId: currentIntegration?.organizationId,
      provider: currentIntegration?.provider,
      instance: currentIntegration?.instance,
      ...data,
    }),
  );

  // eslint-disable-next-line fp/no-loops
  while (yield delay(1000)) {
    const integrationStatus = yield putResolve(
      fetchIntegrationStatus({
        organizationId: currentIntegration?.organizationId,
        provider: currentIntegration?.provider,
        instance: currentIntegration?.instance,
        ...pageOptions,
      }),
    );

    if (integrationStatus.error?.length) {
      yield cancel();
    }

    const currentStatus = yield select(getIntegrationStatus);
    const inProgress =
      currentStatus?.[
        getIntegrationKey(
          currentIntegration?.provider,
          currentIntegration?.instance,
        )
      ].inProgress;

    if (!Boolean(inProgress)) {
      yield putResolve(fetchPartiesForSearch());
      yield putResolve(fetchProjectsForSearch());
      yield cancel();
    }
  }
}

function* organizationSagas() {
  yield all([
    takeLatest(SET_AUTHENTICATED_APP_READY, subscribeOrganizationSaga),
    takeLatest(
      `${CREATE_ORGANIZATION}_FAIL`,
      createOrUpdateOrganizationFailSaga,
    ),
    takeLatest(`${CREATE_ORGANIZATION}_SUCCESS`, createOrganizationSuccessSaga),
    takeLatest(
      `${UPDATE_ACTIVE_ORGANIZATION}_FAIL`,
      createOrUpdateOrganizationFailSaga,
    ),
    takeLatest(
      `${UPDATE_ACTIVE_ORGANIZATION}_SUCCESS`,
      updateOrganizationSuccessSaga,
    ),
    takeLatest(DELETE_ORGANIZATION_ACTION, deleteOrganizationSaga),
    takeLatest(FETCH_INTEGRATION, fetchIntegrationSaga),
    takeLatest(FETCH_INTEGRATIONS_META, fetchIntegrationsMetaSaga),
    takeLatest(
      `${CREATE_PERSONAL_ACCESS_TOKEN}_SUCCESS`,
      fetchPersonalAccessTokensSaga,
    ),
    takeLatest(
      `${DELETE_PERSONAL_ACCESS_TOKEN}_SUCCESS`,
      notifyDeletePersonalAccessTokenSuccessSaga,
    ),
    takeLatest(
      `${UPDATE_PERSONAL_ACCESS_TOKEN}_SUCCESS`,
      notifyUpdatePersonalAccessTokenSuccessSaga,
    ),
    takeLatest(SET_ACTIVE_ORGANIZATION, getProjectsTotalCount),
    takeLatest(CONFIGURE_INTEGRATIONS_STATUS, configureStatusSuccesSaga),
  ]);
}

export default organizationSagas;
