import {
  COMPLIANCE_STATUS,
  type ComplianceStatus,
} from './ComplianceStats.constants';

export function getComplianceStatusFromValue(
  value: number,
  total: number,
): ComplianceStatus {
  if (value > 0 && total > 0 && value < total) {
    return COMPLIANCE_STATUS.PROGRESS;
  }

  if (total > 0 && value === total) {
    return COMPLIANCE_STATUS.GOOD;
  }

  if (total > 0 && value === 0) {
    return COMPLIANCE_STATUS.BAD;
  }

  return COMPLIANCE_STATUS.NULL;
}
