import { useSubscription } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type {
  BulkOperationStatus,
  OperationsUpdatesSubscriptionSubscription,
} from '@graphql/types/graphql';
import { getActiveOrganizationId } from '@modules/organization/selectors';
import { getMyId } from '@modules/user/selectors';
import { useAppSelector } from '@store/hooks';

const OPERATIONS_UPDATES_SUBSCRIPTION = graphql(`
  subscription OperationsUpdatesSubscription($organizationId: ObjectId!) {
    operations(organizationId: $organizationId) {
      operation {
        code
        name
        status
        progress
      }
      actor {
        id
      }
      errors {
        code
        message
      }
    }
  }
`);

type UseOperationsUpdatesArgs = {
  operationNames?: string[];
  operationStatuses?: BulkOperationStatus[];
  onUpdate?: (data: OperationsUpdatesSubscriptionSubscription) => void;
};

const isInList = (name: string, list?: string[]) => {
  if (!list) {
    return true;
  }

  return list?.includes(name);
};

export const useOperationsUpdates = ({
  operationNames,
  operationStatuses,
  onUpdate,
}: UseOperationsUpdatesArgs) => {
  const organizationId = useAppSelector(getActiveOrganizationId);
  const userId = useAppSelector(getMyId);

  return useSubscription(OPERATIONS_UPDATES_SUBSCRIPTION, {
    variables: {
      organizationId,
      userId,
    },
    skip: !organizationId || !userId,
    onData: ({ data }) => {
      const operation = data.data?.operations?.operation;

      if (!operation?.name || !operation?.status) {
        return;
      }

      const isOperationNameRelevant = isInList(operation.name, operationNames);

      const isOperationStatusRelevant = isInList(
        operation.status,
        operationStatuses,
      );

      if (data.data && isOperationNameRelevant && isOperationStatusRelevant) {
        onUpdate?.(data.data);
      }
    },
  });
};
