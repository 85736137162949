import { useEffect } from 'react';

export const KEYBOARD_KEYS = {
  ESC: 'Escape',
  ENTER: 'Enter',
  SPACE: ' ',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
} as const;

export const useHotkeys = (hotkeys: string, callback?: () => void) => {
  useEffect(() => {
    if (!callback) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === hotkeys) {
        callback();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [hotkeys, callback]);
};
