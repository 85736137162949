import { EventType } from './index';

export const getBulkEventDefaultMessage = ({ type, result }) => {
  const defaultMessages = {
    [EventType.bulkArchiveDocumentSuccess]: `Your documents were successfully ${
      result?.isArchived ? 'archived' : 'unarchived'
    }`,
    [EventType.bulkAssignDocumentsToPartySuccess]: `Your documents were successfully assigned to party`,
    [EventType.bulkDetachDocumentsFromPartySuccess]: `Your documents were successfully detached from party`,
    [EventType.bulkAssociateDocumentsToProjectSuccess]: `Your documents were successfully associated to project`,
    [EventType.bulkDetachDocumentsFromProjectSuccess]: `Your documents were successfully detached from project`,
    [EventType.bulkReviewDocumentsSuccess]: `Your documents were successfully set ${
      result?.isReviewed ? 'to "Reviewed"' : 'to "To review"'
    }`,
    [EventType.bulkAddRequirementValueSuccess]: `Your requirement value has been successfully set to documents`,
    [EventType.bulkDeleteDocumentsSuccess]: `Your documents have been successfully deleted`,
    [EventType.bulkMatchRequirementsSuccess]: `Your documents have been successfully matched to the requirements`,
    [EventType.bulkMatchComplianceSuccess]: `Your parties have been successfully made compliant`,
  };

  return defaultMessages[type];
};

export const getBulkEventAnalyticsMessage = ({ type, result }) => {
  const analyticsMessages = {
    [EventType.bulkArchiveDocumentSuccess]: 'User archived documents',
    [EventType.bulkAssignDocumentsToPartySuccess]:
      'User assign documents to party',
    [EventType.bulkDetachDocumentsFromPartySuccess]:
      'User detached documents from party',
    [EventType.bulkAssociateDocumentsToProjectSuccess]:
      'User associated documents to project',
    [EventType.bulkDetachDocumentsFromProjectSuccess]:
      'User detached documents from projects',
    [EventType.bulkReviewDocumentsSuccess]: `User marked documents ${
      result?.isReviewed ? 'to reviewed' : 'to review'
    }`,
    [EventType.bulkAddRequirementValueSuccess]: `User added requirement value to documents`,
    [EventType.bulkDeleteDocumentsSuccess]: `User bulk deleted documents`,
    [EventType.bulkMatchRequirementsSuccess]: `User bulk matched document requirements`,
  };

  return analyticsMessages[type];
};
