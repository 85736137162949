import { Modal } from 'antd';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentTypes } from '@modules/document-type/selectors';

import { addBulkDocumentTypes } from '../actions';
import DocumentTypeSelect from '../components/DocumentTypeSelect';

const AddDocumentTypesModal = ({
  visible,
  onCancel,
  onSuccess,
  documentsIds,
  selectedDocumentsCount,
}) => {
  const [documentTypeIds, setDocumentTypeIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const types = useSelector(getDocumentTypes);

  const onChangeDocumentsTags = (types) => {
    setDocumentTypeIds(types);
  };

  useEffect(() => {
    if (!visible) {
      setDocumentTypeIds([]);
    }
  }, [visible]);

  const handleOk = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        addBulkDocumentTypes({
          documentIds: documentsIds,
          documentTypeIds,
        }),
      );
      onSuccess();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      destroyOnClose={true}
      title="Add document types"
      open={visible}
      onOk={handleOk}
      okButtonProps={{
        disabled: !documentTypeIds.length || isLoading,
      }}
      okText="Add document types"
      onCancel={onCancel}
      width={550}
    >
      <p>
        Select which document types you want to add to the{' '}
        {pluralize('document', selectedDocumentsCount, true)}:
      </p>
      <DocumentTypeSelect types={types} onChange={onChangeDocumentsTags} />
    </Modal>
  );
};

export default AddDocumentTypesModal;
