export const fileToArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export const arrayBufferToFile = (arrayBuffer, fileName, mimeType) => {
  return new File([arrayBuffer], fileName, { type: mimeType });
};
