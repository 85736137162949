import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const PROCESS_DOCUMENT = `${namespace}/PROCESS_DOCUMENT`;

const PROCESS_DOCUMENT_MUTATION = gql`
  mutation processDocumentFile($payload: ProcessDocumentFileInput!) {
    processDocumentFile(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const processDocument = (payload) => ({
  type: PROCESS_DOCUMENT,
  payload: {
    key: 'processDocumentFile',
    graphql: {
      mutation: PROCESS_DOCUMENT_MUTATION,
      variables: { payload },
      context: { hasUpload: true },
    },
  },
});
