import * as R from 'ramda';

import type { AnyGQLSuccessAction } from '@common/types';
import {
  type GetNewGraphqlPayloadReturn,
  getGraphqlPayload,
  getNewGraphqlPayload,
} from '@store/helpers';

import {
  CREATE_FILLABLE_FORM_SUCCESS,
  type CreateFillableFormSuccessAction,
  DELETE_FILLABLE_FORM_SUCCESS,
  type DeleteFillableFormSuccessAction,
  FETCH_FILLABLE_FORMS_SUCCESS,
  FETCH_FILLABLE_FORM_SUCCESS,
  type FetchFillableFormSuccessAction,
  type FetchFillableFormsSuccessAction,
  UPDATE_FILLABLE_FORM_SUCCESS,
  type UpdateFillableFormSuccessAction,
} from '../actions';
import type { FillableForm } from '../fragments';

type FillableFormState = {
  data: Record<string, FillableForm>;
  totalCount: number;
};

const initialState: FillableFormState = {
  data: {},
  totalCount: 0,
};

type FillableFormActions =
  | FetchFillableFormsSuccessAction
  | DeleteFillableFormSuccessAction
  | UpdateFillableFormSuccessAction
  | CreateFillableFormSuccessAction
  | FetchFillableFormSuccessAction;

const reducer = (
  state: FillableFormState = initialState,
  action: FillableFormActions,
): FillableFormState => {
  switch (action.type) {
    case FETCH_FILLABLE_FORMS_SUCCESS: {
      const fillableForms = R.compose<
        [AnyGQLSuccessAction],
        GetNewGraphqlPayloadReturn<FillableForm[]>,
        FillableForm[],
        FillableFormState['data']
      >(
        R.indexBy(R.prop('_id')),
        R.propOr([], 'data'),
        getNewGraphqlPayload,
      )(action);

      const totalCount = R.compose<
        [AnyGQLSuccessAction],
        GetNewGraphqlPayloadReturn<number>,
        number
      >(
        R.propOr(0, 'totalCount'),
        getNewGraphqlPayload,
      )(action as AnyGQLSuccessAction);

      const newState = R.compose<
        [FillableFormState],
        FillableFormState,
        FillableFormState
      >(
        R.assoc('data', fillableForms),
        R.assoc('totalCount', totalCount),
      )(state);

      return newState;
    }
    case FETCH_FILLABLE_FORM_SUCCESS:
    case CREATE_FILLABLE_FORM_SUCCESS:
    case UPDATE_FILLABLE_FORM_SUCCESS: {
      const fillableForm: Record<string, any> = getGraphqlPayload(action) || {};

      return R.assocPath(['data', fillableForm._id], fillableForm, state);
    }
    case DELETE_FILLABLE_FORM_SUCCESS: {
      const fillableForm: Record<string, any> = getGraphqlPayload(action) || {};

      return R.dissocPath(['data', fillableForm._id], state);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
