import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getContacts = R.compose(
  R.values,
  R.pathOr([], [STATE_KEY, 'data']),
);

export const getContactsByParty = (state, partyId) =>
  R.compose(
    R.propOr([], 'partyContacts'),
    R.path([STATE_KEY, 'data', partyId]),
  )(state);

export const getParties = R.compose(
  R.values,
  R.pathOr({}, [STATE_KEY, 'data']),
);

export const getPartiesByIds = (state, ids = []) =>
  R.compose(
    R.filter((party) => ids.includes(party?._id)),
    getParties,
  )(state);

export const getParty = (state, id) => R.path([STATE_KEY, 'data', id], state);

export const getPartyContactsByIds = (state, partyId, ids) =>
  R.compose(
    R.filter((partyContact) => ids.includes(R.prop('_id', partyContact))),
    R.propOr([], 'partyContacts'),
    (state) => getParty(state, partyId),
  )(state);

export const getPartyByRequest = (state, requestId) => {
  const request = getRequest(state, requestId);

  if (!request || !request.party) {
    return null;
  }

  return R.path([STATE_KEY, 'data', request.party], state);
};

export const getIsChangingComplianceProfile = (state) =>
  R.path([STATE_KEY, 'isChangingComplianceProfile'], state);

export const getCompliantPartiesCount = (state) =>
  getParties(state).filter((party) => party.status === 'compliant').length;

export const getPartiesByType = (state, type) =>
  getParties(state).filter((party) => party.type._id === type);

export const getActivePartiesByType = (state, type) =>
  getPartiesByType(state, type).filter((party) => party.isActive);

export const getUniqPartiesByRequests = (state, requests) =>
  R.uniq(requests.map(({ id }) => getPartyByRequest(state, id)));

export const getRequests = R.compose(
  R.flatten,
  R.map((party) => R.propOr([], 'requests', party)),
  getParties,
);

export const getRequest = (state, id) =>
  R.compose(R.find(R.propEq('_id', id)), getRequests)(state);

export const getRequestsByParty = (state, partyId) =>
  R.filter((request) => request.party === partyId, getRequests(state));

export const getRequirementsByParty = (state, partyId) =>
  R.compose(R.propOr([], 'requirements'), (id) => getParty(state, id))(partyId);

export const getPartyComplianceProfileById = (state, id) =>
  R.path([STATE_KEY, 'data', id], state);

export const getIsAssigningProfiles = R.pathOr(false, [
  STATE_KEY,
  'assigningProfile',
]);

export const getPartyComplianceProfileByParty = (state, id) => {
  return R.compose(R.propOr(null, 'partyComplianceProfile'), (id) =>
    getParty(state, id),
  )(id);
};

export const getComplianceProfileIdByParty = (state, id) =>
  R.compose(R.propOr('', 'complianceProfile'), (id) =>
    getPartyComplianceProfileByParty(state, id),
  )(id);

export const getPartiesByComplianceProfileId = (state, complianceProfileId) =>
  R.compose(
    R.filter(
      R.pathEq(
        ['partyComplianceProfile', 'complianceProfile'],
        complianceProfileId,
      ),
    ),
    getParties,
  )(state);

export const getPartySelectedDocuments = (state, partyId) => {
  return R.pathOr({}, [STATE_KEY, 'selectedDocuments', partyId], state);
};

export const getTotalCount = R.pathOr(0, [STATE_KEY, 'totalCount']);
