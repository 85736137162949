import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { DeleteRequestRecordsInput } from '@graphql/types/graphql';

const DELETE_REQUEST_RECORDS_MUTATION = graphql(`
  mutation DeleteRequestRecords($data: DeleteRequestRecordsInput!) {
    deleteRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDeleteRequestsMutation = () => {
  const [deleteRequestRecordsMutation, { loading: deletingRequests }] =
    useMutation(DELETE_REQUEST_RECORDS_MUTATION);

  const deleteRequestRecords = (data: DeleteRequestRecordsInput) => {
    return deleteRequestRecordsMutation({ variables: { data } });
  };

  return {
    deleteRequestRecords,
    deletingRequests,
  };
};
