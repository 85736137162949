/**
 * Context keys
 *
 * Suggested syntax for keys:
 *    "record-<name>" for lists of objects
 *    "attribute-<name>" for context keys used as helpers
 * Documentation for records vs. attributes: https://app.commandbar.com/docs/context/records
 */
export const ContextAttributeKeys = Object.freeze({
  orgSlug: 'orgSlug',
  orgFeatures: 'orgFeatures',
});

export const ContextRecordKeys = Object.freeze({
  documentTypes: 'documentTypes',
  partyTypes: 'partyTypes',
  documents: 'documents',
  parties: 'parties',
  projects: 'projects',
  complianceProfiles: 'complianceProfiles',
});

/**
 * Callback keys
 *
 * Suggested syntax for keys: "<page>-<name>"
 *    Examples: global-createDoc, task-assignMembers
 */
export const GlobalCallbackKeys = Object.freeze({
  switchProjects: 'switchProjects',
  switchToOrg: 'switchToOrg',
  makePartyInactive: 'makePartyInactive',
  assignDocumentToParty: 'assignDocumentToParty',
  addPartyToProject: 'addPartyToProject',
  assignComplianceProfile: 'assignComplianceProfile',
  deleteParty: 'deleteParty',
  detachDocumentFromParty: 'detachDocumentFromParty',
  markToReview: 'markToReview',
  markReviewed: 'markReviewed',
  archiveDocument: 'archiveDocument',
  deleteDocument: 'deleteDocument',
});
