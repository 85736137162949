import styled from 'styled-components';

import PaperPlane from '@common/icons/PaperPlane';

type InfoMessageProps = {
  label: string;
  link: {
    href: string;
    label: string;
  };
};

export const InfoMessage = ({ label, link }: InfoMessageProps) => (
  <StyledWrapper>
    <PaperPlane />
    {label}
    <a href={link.href} target="blank" rel="noopener norefer">
      {link.label}
    </a>
  </StyledWrapper>
);

const StyledWrapper = styled.i`
  display: flex;
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;

  svg {
    position: relative;
    top: 4px;
    margin-right: 5px;
  }

  a {
    margin-left: 5px;
  }
`;
