import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ComplianceProfileContext } from '@modules/compliance-profile/constants';
import { getComplianceProfiles } from '@modules/compliance-profile/selectors';

import ProjectPartiesTabView from '../components/ProjectPartiesTabView';

const ProjectPartiesTabContainer = ({
  profilesMappings,
  localComplianceProfiles,
  party,
  deleteParty,
  onChangeProfileAssociation,
}) => {
  const globalComplianceProfiles = useSelector(getComplianceProfiles);
  const [selectedProfile, setSelectedProfile] = useState('');
  const [availableProfiles, setAvailableProfiles] = useState(
    globalComplianceProfiles,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    setSelectedProfile(
      R.compose(
        (value) => {
          // If a match was found in profilesMappings, then we return the value.
          if (value) return value;

          // Since we don't currently support custom compliance profiles in projects,
          // if the party is currently associated to a custom compliance profile
          // in the global namespace, we return null here. Doing so will display an
          // empty select for the party being added, and the user will need to specify
          // a global compliance profile.
          const isCustomProfile = !R.find(
            (el) => el._id === party?.partyComplianceProfile?.complianceProfile,
            availableProfiles,
          );

          if (isCustomProfile) {
            return null;
          }

          // In case the party is associated to a global compliance profile that
          // is not being used in the project yet, returning the id here will link
          // the compliance profile to the project.
          return party?.partyComplianceProfile?.complianceProfile;
        },
        R.prop('originalComplianceProfile'),
        R.find(
          (el) =>
            el.projectComplianceProfile ===
            party?.partyComplianceProfile?.complianceProfile,
        ),
      )(profilesMappings),
    );
  }, [profilesMappings, party]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    setAvailableProfiles(
      R.compose(
        R.uniqBy(R.prop('_id')),
        R.filter((el) => el.context === ComplianceProfileContext.Global),
        R.flatten,
      )([globalComplianceProfiles, localComplianceProfiles]),
    );
  }, [
    localComplianceProfiles,
    globalComplianceProfiles,
    profilesMappings,
    onChangeProfileAssociation,
  ]);

  const onAssignProfile = (id) => {
    setSelectedProfile(id);
    Boolean(id)
      ? onChangeProfileAssociation(R.assoc(party._id, id, {}))
      : onChangeProfileAssociation(R.assoc(party._id, null, {}));
  };

  const onDeleteParty = (id) => {
    setSelectedProfile('');
    onChangeProfileAssociation(R.assoc(party._id, null, {}));
    deleteParty(id);
  };

  return (
    <ProjectPartiesTabView
      party={party}
      complianceProfiles={availableProfiles}
      selectedProfile={selectedProfile}
      onAssignProfile={onAssignProfile}
      onDeleteParty={onDeleteParty}
    />
  );
};

export default ProjectPartiesTabContainer;
