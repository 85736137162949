import { Button, Modal } from 'antd';

import usePrevious from '@common/hooks/usePrevious';
import { getAppVersions } from '@modules/app/selectors';
import { getFeatureFlags } from '@modules/feature-flags/selectors';
import { useAppSelector } from '@store/hooks';
import { checkShouldRefresh } from './AppOutdatedModal.utils';

export const AppOutdatedModal = () => {
  const { currentVersion, latestVersion, hardrefresh } =
    useAppSelector(getAppVersions);
  const featureFlags = useAppSelector(getFeatureFlags);
  const prevFeatureFlags = usePrevious(featureFlags);

  const shouldRefresh = checkShouldRefresh({
    featureFlags,
    prevFeatureFlags,
    latestVersion,
    currentVersion,
    hardrefresh,
  });

  return (
    <Modal
      title="A new version is available"
      centered
      maskClosable={false}
      closable={false}
      open={shouldRefresh}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload the page
        </Button>,
      ]}
    >
      <p>
        Please reload the page to update to the newest available version of the
        app.
      </p>
    </Modal>
  );
};
