import type React from 'react';
import { Children, type ReactNode, memo } from 'react';
import styled from 'styled-components';

import type { CellEditorParams } from '../types';

interface ListCellProps {
  children: ReactNode[];
}

export const ListCell: React.FC<ListCellProps> = ({ children }) => {
  if (Children.count(children) === 0) {
    return <div>-</div>;
  }

  return <StyledListCellWrapper>{children}</StyledListCellWrapper>;
};

type ListCellEditorProps = { children: ReactNode[] } & CellEditorParams;

export const ListCellEditor = memo(
  ({ column, children }: ListCellEditorProps) => {
    return (
      <StyledCellEditor $width={column.getActualWidth()}>
        {children}
      </StyledCellEditor>
    );
  },
);

const StyledListCellWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 6px;
`;

const StyledCellEditor = styled.div<{ $width: number }>`
  overflow: hidden;
  word-break: break-word;
  width: ${(props) => props.$width * 2}px;
  max-width: 500px;
  height: auto;
  background-color: color-mix(in srgb, white, var(--ag-active-color) 10%);
  border: 1px solid #ccc;
  outline: none;
  margin: -1px;
  padding: 10px;
`;
