import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_TYPE_FRAGMENT } from '../fragments';

export const CREATE_DOCUMENT_TYPE = `${namespace}/CREATE_DOCUMENT_TYPE`;

const CREATE_DOCUMENT_TYPE_MUTATION = gql`
  mutation createDocumentType($payload: CreateDocumentTypeInput!) {
    createDocumentType(data: $payload) {
      ...DocumentType
    }
  }
  ${DOCUMENT_TYPE_FRAGMENT}
`;

export const createDocumentType = (payload) => ({
  type: CREATE_DOCUMENT_TYPE,
  payload: {
    key: 'createDocumentType',
    graphql: {
      mutation: CREATE_DOCUMENT_TYPE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
