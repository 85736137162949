import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PROJECTS_FOR_SEARCH = `${namespace}/FETCH_PROJECTS_FOR_SEARCH`;

const FETCH_PROJECTS_FOR_SEARCH_QUERY = gql`
  query listProjects($filters: JSON, $sort: ElementsSort) {
    listProjects(filters: $filters, sort: $sort) {
      data {
        _id
        name
      }
      totalCount
    }
  }
`;

export const fetchProjectsForSearch = (payload) => ({
  type: FETCH_PROJECTS_FOR_SEARCH,
  payload: {
    key: 'listProjects',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PROJECTS_FOR_SEARCH_QUERY,
      variables: payload,
    },
  },
});
