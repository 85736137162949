import { saveAs } from 'file-saver';
import { getExtension, getType } from 'mime';
import moment from 'moment';
import PSPDFKitWeb from 'pspdfkit';
import * as R from 'ramda';

import {
  arrayBufferToFile,
  fileToArrayBuffer,
} from '@common/utils/file-helpers';
import { decamelize } from '@common/utils/string-helpers';
import PspdfkitConfig from '@config/PspdfkitConfig';
import {
  AttributeType,
  SupportedSubjectDateValues,
  isSupportedSubjectDateValues,
} from '@modules/compliance-profile/constants';
import { FILLABLE_FORM_STATUS } from '@modules/fillable-form/constants';
import { SURVEY_MODULE_ID } from '@modules/surveys/constants';

import {
  SeverityLevel,
  acceptExtensionsArray,
  documentSubjectAbbreviation,
} from '../constants';

import { nextExpirationDate } from './nextExpirationDate.legacy';

const effectiveDateSuffix = '_effectiveDate';
const expirationDateSuffix = '_expirationDate';

/**
 * Generate document subject abbreviation.
 */
export const generateAbbreviationData = (title) => {
  const name = title
    ? R.adjust(0, R.toUpper, decamelize(title, ' ')).join('').trim()
    : '';

  const abbr = title
    ? name
        .split(' ')
        .map((x) => R.head(x))
        .join('')
        .slice(0, 3)
        .trim()
    : '';

  return { name, abbr };
};

/**
 * Get subject abbreviation and color exp status.
 */
export const getSubjects = ({ metadata, party }) =>
  R.compose(
    R.map((key) => {
      const expDate = R.path([key, 'expirationDate'], metadata);
      const effectiveDate = R.path([key, 'effectiveDate'], metadata);
      const parsedEffectiveDate = moment.utc(effectiveDate);
      const parsedExpirationDate = moment.utc(expDate);
      const maxDate = moment.utc().add(30, 'days');

      const getExp = () => {
        if (
          isSupportedSubjectDateValues(expDate) ||
          isSupportedSubjectDateValues(effectiveDate)
        ) {
          return null;
        }
        if (parsedExpirationDate.isAfter(maxDate)) {
          return null;
        }
        if (parsedExpirationDate.isAfter(moment())) {
          return 'orange';
        }
        return 'red';
      };

      const getAbbreviation = () => {
        if (documentSubjectAbbreviation[key]) {
          return documentSubjectAbbreviation[key];
        } else if (party) {
          const requirement = R.find(
            R.propEq('subjectId', key),
            R.prop('requirements', party),
          );
          return generateAbbreviationData(
            R.propOr(key, 'subjectLabel', requirement),
          );
        }
        return generateAbbreviationData(key);
      };

      return {
        name: R.propOr('', 'name', getAbbreviation()),
        abbr: R.propOr('', 'abbr', getAbbreviation()),
        expColor: getExp(),
        expirationDifference: moment(parsedExpirationDate)
          .startOf('day')
          .diff(moment().startOf('day'), 'days'),
        expDate: isSupportedSubjectDateValues(expDate)
          ? SupportedSubjectDateValues[expDate].label
          : parsedExpirationDate.format('ll'),
        effectiveDate: isSupportedSubjectDateValues(effectiveDate)
          ? SupportedSubjectDateValues[effectiveDate].label
          : parsedEffectiveDate.format('ll'),
      };
    }),
    R.filter((key) =>
      party
        ? R.compose(
            (subjects) => subjects.some((subject) => subject === key),
            R.uniq,
            R.map((requirement) => requirement.subjectId),
          )(R.propOr([], 'requirements', party))
        : true,
    ),
    R.filter(
      (key) =>
        R.path([key, 'expirationDate'], metadata) &&
        R.path([key, 'effectiveDate'], metadata),
    ),
    R.keys,
  )(metadata);

export const downloadFile = (url, fileName) => {
  return new Promise((res) => {
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'blob';
    req.onload = function () {
      const file = new Blob([req.response]);
      saveAs(file, fileName);
      res();
    };
    req.send();
  });
};

export const downloadDocumentFile = async (document) =>
  downloadFile(document.url, getUniqueDocumentFileName(document));

export const getInstantJSON = (document) => {
  const isSignedDocumentForm =
    document?.fillableForm?.status === FILLABLE_FORM_STATUS.SIGNED;

  if (isSignedDocumentForm) {
    return;
  }

  if (!document?.fillableForm) {
    return {
      format: 'https://pspdfkit.com/instant-json/v1',
      annotations: R.propOr([], 'annotations', document),
    };
  }

  return R.compose(
    R.assoc('format', 'https://pspdfkit.com/instant-json/v1'),
    R.filter(Boolean),
    R.omit('_id'),
    R.prop('fillableForm'),
  )(document);
};

export const downloadPSPDFKitDocument = async (document) => {
  const headlessInstance = await PSPDFKitWeb.load({
    ...PspdfkitConfig,
    headless: true,
    document: document?.url,
    instantJSON: getInstantJSON(document),
  });

  const buffer = await headlessInstance.exportPDF({
    flatten: document?.fillableForm?.status !== FILLABLE_FORM_STATUS.SIGNED,
    incremental: document?.fillableForm?.status === FILLABLE_FORM_STATUS.SIGNED,
  });

  PSPDFKitWeb.unload(headlessInstance);

  const fileExtension = getExtension(getType(document?.s3Key));

  saveAs(new Blob([buffer]), `${document.friendlyName}.${fileExtension}`);
};

export const flattenPdfFile = async (file) => {
  if (file.type !== 'application/pdf') {
    return file;
  }

  try {
    const arrayBuffer = await fileToArrayBuffer(file);
    const headlessInstance = await PSPDFKitWeb.load({
      ...PspdfkitConfig,
      headless: true,
      document: arrayBuffer,
    });
    const flattenedFile = await headlessInstance.exportPDF({ flatten: true });
    PSPDFKitWeb.unload(headlessInstance);

    return arrayBufferToFile(flattenedFile, file.name, file.type);
  } catch (err) {
    console.error(err);
    return file;
  }
};

/**
 * Get unique Document file name
 */

export const getUniqueDocumentFileName = ({ s3Key, friendlyName, _id }) => {
  const fileName = R.compose(R.join('.'), R.init, R.split('.'))(friendlyName);
  const fileExtension = R.last(R.split('.', s3Key));
  return `${fileName} (${_id}).${fileExtension}`;
};

/**
 * Get new documents.
 */
export const getNewDocuments = (documents) =>
  R.filter((document) =>
    moment(document.createdAt).isAfter(moment().subtract(1, 'days')),
  )(documents);

/**
 * Fill form by document metadata values.
 */
export const fillFormByDocumentMetadata = (metadata, createFormField) =>
  R.compose(
    R.mapObjIndexed((value) => createFormField({ value })),
    R.reduce(
      (resultObj, key) =>
        R.prop(`${key}${effectiveDateSuffix}`) &&
        R.prop(`${key}${expirationDateSuffix}`, metadata)
          ? R.assoc(
              key,
              [
                moment.utc(
                  R.propOr('', `${key}${effectiveDateSuffix}`, metadata),
                ),
                moment.utc(
                  R.propOr('', `${key}${expirationDateSuffix}`, metadata),
                ),
              ],
              resultObj,
            )
          : resultObj,
      {},
    ),
    R.keys,
  )(metadata);

/*
 *  Get effective date from document.
 */
export const getEffectiveDate = (document, subjectId) =>
  R.pathOr(null, ['metadata', subjectId, 'effectiveDate'], document);

/*
 *  Get expiration date from document.
 */
export const getExpirationDate = (document, subjectId) =>
  R.pathOr(null, ['metadata', subjectId, 'expirationDate'], document);

/*
 *  Get detected value from document by requirement.
 */
export const getDetectedValue = (document, requirement) =>
  R.compose(
    R.propOr('', R.prop('masterDocumentAttributeId', requirement)),
    R.propOr({}, 'data'),
  )(document);

/**
 * Get documents names by requirementId.
 */
export const getDocumentsBySatisfiedAttribute = (documents, requirement) =>
  R.compose(
    R.map(({ _id, friendlyName }) => ({ _id, friendlyName })),
    R.filter(
      (document) =>
        !document.archivedAt &&
        Boolean(getDetectedValue(document, requirement)),
    ),
  )(documents);

/**
 * Get value by requirement
 */
export const getTargetValueByRequirement = (requirement, value) => {
  const { attributeType, moduleId } = requirement;

  if (
    moduleId === SURVEY_MODULE_ID ||
    attributeType === AttributeType.FillableForm
  ) {
    return 'Completed';
  }

  if (
    attributeType === AttributeType.Boolean ||
    attributeType === AttributeType.Ai ||
    attributeType === AttributeType.Connected
  ) {
    return 'Present';
  }

  // ! deprecated?
  if (attributeType === 'date') {
    return moment.utc(value);
  }

  if (attributeType === AttributeType.Number) {
    // This temporarily handles the case where the user
    // selects "must be present" for a numeric attribute.
    return value === '' ? '1' : value;
  }

  return value;
};

/**
 * Get prev document.
 */
export const getPreviousDocument = (id, documents) =>
  R.compose(
    R.prop('_id'),
    (i) => documents[i < 0 ? documents.length + i : i],
    R.dec,
    R.findIndex(R.propEq('_id', id)),
  )(documents);

/**
 * Get next document.
 */
export const getNextDocument = (id, documents) =>
  R.compose(
    R.prop('_id'),
    (i) => documents[i % documents.length],
    R.inc,
    R.findIndex(R.propEq('_id', id)),
  )(documents);

/**
 * Check is expired documents.
 */
export const checkIsExpiredDocuments = (documents) =>
  !documents.some((document) => nextExpirationDate(document));

/**
 * Check document for subject.
 */
export const checkDocumentForSubject = (
  document,
  subjects,
  subjectsAsArrayString,
) =>
  R.compose(
    (metadataSubjects) =>
      subjects.every((x) =>
        metadataSubjects.some(
          (subject) => subject === (subjectsAsArrayString ? x : x.subjectId),
        ),
      ),
    (metadataSubjects) =>
      metadataSubjects.filter(
        (subject) =>
          getExpirationDate(document, subject) &&
          getEffectiveDate(document, subject),
      ),
    R.keys,
  )(document.metadata);

/**
 * Check is pdf document.
 */
export const getIsPdf = (name) => /^.*\.(pdf|PDF)$/.test(name);

/**
 * Enum for set flag priorities.
 */
export const PriorityFlags = {
  low: 1,
  medium: 2,
  high: 3,
  default: 0,
};

export const getNearestElementByDate = (elements, key = 'addedOn') =>
  R.compose(
    R.defaultTo({}),
    R.head,
    R.sort(
      (elementA, elementB) => new Date(elementB[key]) - new Date(elementA[key]),
    ),
  )(elements);

export const getNearestDocumentByFlag = (flags) => {
  // eslint-disable-next-line fp/no-let
  let maxPriorityFlag = -1;

  return R.compose(
    getNearestElementByDate,
    R.filter((flag) => PriorityFlags[flag.severityLevel] === maxPriorityFlag),
    R.forEach((flag) => {
      const currPriorityFlagLevel = PriorityFlags[flag.severityLevel];
      maxPriorityFlag =
        currPriorityFlagLevel > maxPriorityFlag
          ? currPriorityFlagLevel
          : maxPriorityFlag;
    }),
  )(flags);
};

/**
 *  1. Get all flags
 *  2. Search max priority flag
 *  3. Filter by max priority flag
 *  4. Sort filtered array by addedOn field
 *  5. Get first element in array
 *  6. Get document which contain flag
 */
export const getNearestHighestPriorityDocument = (documents = []) => {
  const nearestFlag = R.compose(
    getNearestDocumentByFlag,
    R.filter((document) => Boolean(document)),
    R.map((document) => document.flag),
    R.filter((document) => !Boolean(document.archivedAt)),
  )(documents);

  const currDocument = documents.find((document) =>
    Boolean(document.flag === nearestFlag),
  );
  return R.defaultTo({}, currDocument);
};

/**
 * Get nearest  verification date between all documents
 */
export const getNearestVerification = (documents = []) =>
  R.compose(
    R.defaultTo({}),
    (verifications) => getNearestElementByDate(verifications, 'verifiedOn'),
    R.flatten,
    R.map(({ verifications }) => verifications),
  )(documents);

/**
 * Get array with active documents and then archive
 */
export const getDocumentsByThumbnailOrder = (documents = []) => {
  const notArchivedDocuments = documents.filter((x) => !Boolean(x.archivedAt));
  const archivedDocuments = documents.filter((x) => Boolean(x.archivedAt));

  return [notArchivedDocuments, archivedDocuments];
};

/**
 * Get flag by affected subjects in documents.
 */
export const getFlagByAffectedSubject = (documents, subjectId) =>
  R.compose(
    R.defaultTo(null),
    R.head,
    (flags) => {
      const filterByLevel = (data = [], level) =>
        data.filter((x) => x.severityLevel === level);

      return [
        ...filterByLevel(flags, SeverityLevel.High),
        ...filterByLevel(flags, SeverityLevel.Medium),
        ...filterByLevel(flags, SeverityLevel.Low),
      ];
    },
    R.filter((x) => x.affectedSubjects.includes(subjectId)),
    R.map((x) => x.flag),
    R.filter((x) => x.flag),
  )(documents);

/**
 * Get verification by affected subjects in documents.
 */
export const getVerificationByAffectedSubject = (documents, subjectId) =>
  R.compose(
    R.defaultTo(null),
    R.head,
    R.reverse,
    R.sortBy(R.prop('verifiedOn')),
    R.filter((x) => x.affectedSubjects.includes(subjectId)),
    R.map((x) => x.verification),
    R.filter((x) => x.verification),
  )(documents);

/**
 * Get combined data about flag and verification.
 */
export const getFlagAndVerificationByAffectedSubject = (
  documents,
  subjectId,
) => {
  const activeDocuments = documents.filter((x) => !x.archivedAt);
  const flag = getFlagByAffectedSubject(activeDocuments, subjectId);
  const verification = getVerificationByAffectedSubject(
    activeDocuments,
    subjectId,
  );
  const documentByFlag = activeDocuments.find((x) => x.flag === flag);
  const documentByVerification = activeDocuments.find((x) => x.flag === flag);

  return {
    flag,
    verification,
    documentByFlag,
    documentByVerification,
  };
};

/**
 * Check if the naic is generated with uuid.
 */
export const getIsNaicAutoGenerated = (naic) => {
  const V4 = new RegExp(
    /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  );
  return V4.test(naic);
};

export const isSupportedFile = (filename) =>
  acceptExtensionsArray.includes(getType(filename));
