import { gql } from '@apollo/client';

import { graphql } from '@graphql/types';

export const REPORT_FRAGMENT = gql`
  fragment Report on ReportEntity {
    _id
    name
    type
    filters
    organization
    user
    schedules {
      _id
      name
      recipients
      format
      frequency
      frequencyOptions
      message
      columns {
        value
        name
      }
      showOrganizationComplianceRow
      lastSendingDate
      lastSendingType
    }
    isPrivate
    readonly
    createdAt
    updatedAt
  }
`;

export const REPORT_FRAGMENT_V2 = graphql`
  fragment Report on ReportEntity {
    _id
    name
    type
    filters
    organization
    user
    schedules {
      _id
      name
      recipients
      format
      frequency
      frequencyOptions
      message
      columns {
        value
        name
      }
      showOrganizationComplianceRow
      lastSendingDate
      lastSendingType
    }
    isPrivate
    readonly
    createdAt
    updatedAt
  }
`;

export const SCHEDULED_REPORT_FRAGMENT = gql`
  fragment ScheduledReport on ScheduledReportEntity {
    _id
    name
    organization
    report
    recipients
    format
    frequency
    frequencyOptions
    message
    columns {
      value
      name
    }
    showOrganizationComplianceRow
    lastSendingDate
    lastSendingType
  }
`;
