import { useInputFocusOnMount } from '@common/hooks';
import { ContactModalFormItems } from '@modules/contacts/components/ContactModalFormItems';
import { Form, type FormInstance, Input } from 'antd';
import styled from 'styled-components';

export type CreatePrimaryRecordModalState = {
  visible: boolean;
  primaryObjectId: string;
  primaryObjectName: string;
};

type FormValues = {
  recordName: string | null;
  contactEmail: string | null;
  contactName: string | null;
  contactNumber: string | null;
  contactId: string | null;
};

export const ModalFormContent = ({
  formInstance,
}: { formInstance: FormInstance<FormValues> }) => {
  const inputRef = useInputFocusOnMount(null);

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      name="createPrimaryRecordForm"
      labelCol={{
        sm: { span: 3 },
      }}
      wrapperCol={{
        sm: { span: 21 },
      }}
    >
      <Form.Item
        name="recordName"
        label="Name"
        rules={[{ required: true, message: 'Please enter a name' }]}
      >
        <Input
          data-cy="recordName"
          placeholder="Enter name..."
          ref={inputRef}
        />
      </Form.Item>

      <StyledContactFormGroup>
        <h3>Contact</h3>
        <ContactModalFormItems
          form={
            formInstance as FormInstance<{
              contactEmail: string;
              contactName?: string;
              contactId?: string;
            }>
          }
        />
        {/*
            //? Add this when BE accepts the number
          <Form.Item name="contactNumber" label="Phone">
            <Input
              disabled={isUserSelected}
              type="text"
              placeholder="Enter contact number..."
            />
          </Form.Item> */}
      </StyledContactFormGroup>
    </Form>
  );
};

const StyledContactFormGroup = styled.div`
  background-color: #FAFAFA;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  padding: 16px;

  h3 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.subtitleText};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`;
