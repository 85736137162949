import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';

const PartyLinkName = ({ children, partyId }) => {
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);

  if (!children) return null;

  return (
    <PartyLinkName.Link
      to={`${organizationNamespace}/parties/${partyId}/overview`}
    >
      {children}
    </PartyLinkName.Link>
  );
};

PartyLinkName.Link = styled(Link)`
  text-decoration: none;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
`;

export default PartyLinkName;
