import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '@modules/user/fragments';

import { namespace } from '../constants';

export const AUTH_CONSUME = `${namespace}/AUTH_CONSUME`;

const AUTH_CONSUME_MUTATION = gql`
  mutation authConsume($payload: AuthConsumeInput!) {
    authConsume(data: $payload) {
      token
      user {
        ...User
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const authConsume = (payload) => ({
  type: AUTH_CONSUME,
  payload: {
    key: 'authConsume',
    graphql: {
      mutation: AUTH_CONSUME_MUTATION,
      variables: { payload },
    },
  },
});
