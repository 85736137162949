import {
  type ApolloQueryResult,
  type DocumentNode,
  type OperationVariables,
  useApolloClient,
} from '@apollo/client';
import { useCallback } from 'react';
import { useStore } from 'react-redux';

import { getVariablesWithContext } from '@graphql/utils';
import { getContext } from '@store/helpers';

/**
 * useCustomLazyQuery is a workaround due to an Apollo/client issue:
 * the "onCompleted" callback is not relaiable since it could be not called when multiple queries are performed close one to another generating a race condition.
 *
 * @see link to apollo/client issue https://github.com/apollographql/apollo-client/issues/9284
 * @see link with the proposed workaround https://github.com/apollographql/apollo-client/issues/9284#issuecomment-1050603696
 *
 * @note in our application this issue is tangible within the AgGrdi table SSRM pagination.
 *
 * @see link to ShortCut bug https://app.shortcut.com/trustlayer/story/20928/aggrid-table-doesn-t-update-properly-it-s-pagination-status-and-data-after-quick-pages-change
 */

export const useCustomLazyQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
): ((params: OperationVariables) => Promise<ApolloQueryResult<TData>>) => {
  const store = useStore();
  const state = store.getState();
  const context = getContext(state);
  const client = useApolloClient();

  return useCallback(
    (options) => {
      const apolloOptions = getVariablesWithContext(options, context);
      return client.query<TData, TVariables>({
        query: query,
        ...apolloOptions,
      });
    },
    [client, query, context],
  );
};
