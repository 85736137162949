import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const IMPORT_COMPLIANCE_PROFILES = `${namespace}/IMPORT_COMPLIANCE_PROFILES`;

const IMPORT_COMPLIANCE_PROFILES_MUTATION = gql`
  mutation importComplianceProfile($payload: ImportComplianceProfileInput!) {
    importComplianceProfile(data: $payload) {
      scheduled
    }
  }
`;

export const importComplianceProfiles = (url) => ({
  type: IMPORT_COMPLIANCE_PROFILES,
  payload: {
    key: 'importComplianceProfiles',
    graphql: {
      mutation: IMPORT_COMPLIANCE_PROFILES_MUTATION,
      variables: {
        payload: { url },
      },
    },
  },
});
