import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const MARK_AS_READ_MESSAGES = `${namespace}/MARK_AS_READ_MESSAGES`;

const MARK_AS_READ_MESSAGES_MUTATION = gql`
  mutation markMessagesAsRead($data: MarkMessageAsReadInput!) {
    markMessagesAsRead(data: $data)
  }
`;

export const markMessagesAsRead = (data) => ({
  type: MARK_AS_READ_MESSAGES,
  payload: {
    key: 'markMessagesAsRead',
    graphql: {
      mutation: MARK_AS_READ_MESSAGES_MUTATION,
      variables: {
        data,
      },
    },
  },
});
