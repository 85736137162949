import { gql } from '@apollo/client';

export const PARTY_COMPLIANCE_PROFILE_FRAGMENT = gql`
  fragment PartyComplianceProfile on PartyData {
    partyComplianceProfile {
      _id
      isProcessing
      party
      metadata
      complianceProfile
      name
      nextExpirationDate
      latestExpirationDate
      complianceStats {
        complianceScore
        compliantRequirementsCount
        nonCompliantRequirementsCount
        waivedRequirementsCount
        overriddenRequirementsCount
        totalRequirementsCount
        totalSubjectsCount
        compliantSubjectsCount
      }
      createdAt
      updatedAt
    }
  }
`;
