import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTY_COMPLIANCE_BY_PROJECT = `${namespace}/FETCH_PARTY_COMPLIANCE_BY_PROJECT`;

const FETCH_PARTY_COMPLIANCE_BY_PROJECT_QUERY = gql`
  query listProjectPartyData($filter: Any) {
    listProjectPartyData(filter: $filter) {
      _id
      subjects {
        subjectId
        subjectLabel
        notes
        requirementComplianceStatusValue
        resetRequirementOn
      }
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        createdAt
        updatedAt
      }
      partyComplianceProfile {
        _id
        name
        complianceProfile
        complianceStats {
          complianceScore
          nonCompliantRequirementsCount
          compliantSubjectsCount
          totalSubjectsCount
          waivedRequirementsCount
          overriddenRequirementsCount
        }
      }
      party {
        _id
        name
        isTrackingCompliance
      }
    }
  }
`;

export const fetchPartyComplianceByProject = (partyId, projectId) => ({
  type: FETCH_PARTY_COMPLIANCE_BY_PROJECT,
  payload: {
    key: 'listProjectPartyData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_COMPLIANCE_BY_PROJECT_QUERY,
      variables: { filter: { party: partyId, project: projectId } },
    },
  },
});
