import { getOperationDefinition } from '@apollo/client/utilities';
import omitDeep from '@wertarbyte/omit-deep';
import type { DocumentNode } from 'graphql';
import * as R from 'ramda';

import type { getContext } from '@store/helpers';

export const getVariablesWithContext = <TOptions>(
  options: TOptions,
  context: ReturnType<typeof getContext>,
) => {
  return R.mergeDeepRight(options || {}, {
    variables: context,
  }) as TOptions;
};

export const getDataWithoutTypename = <T = any>(data?: T): T | undefined => {
  if (!data) {
    return data;
  }

  return omitDeep(R.clone(data), ['__typename']) as T;
};

export const getOperationName = (doc: DocumentNode) => {
  const operation = getOperationDefinition(doc);
  // @ts-ignore - don;'t know why `name` is not typed in Apollo
  const key = operation?.selectionSet?.selections[0]?.name?.value;

  return key || '';
};
