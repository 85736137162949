const NotificationIcon = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M14.5312 15.5H14.0156V8.19531C14.0156 5.16387 11.7748 2.65879 8.85938 2.24199V1.40625C8.85938 0.931445 8.4748 0.546875 8 0.546875C7.5252 0.546875 7.14062 0.931445 7.14062 1.40625V2.24199C4.2252 2.65879 1.98438 5.16387 1.98438 8.19531V15.5H1.46875C1.08848 15.5 0.78125 15.8072 0.78125 16.1875V16.875C0.78125 16.9695 0.858594 17.0469 0.953125 17.0469H5.59375C5.59375 18.3746 6.67227 19.4531 8 19.4531C9.32773 19.4531 10.4062 18.3746 10.4062 17.0469H15.0469C15.1414 17.0469 15.2188 16.9695 15.2188 16.875V16.1875C15.2188 15.8072 14.9115 15.5 14.5312 15.5ZM8 18.0781C7.43066 18.0781 6.96875 17.6162 6.96875 17.0469H9.03125C9.03125 17.6162 8.56934 18.0781 8 18.0781ZM3.53125 15.5V8.19531C3.53125 7.00078 3.99531 5.8793 4.83965 5.03496C5.68398 4.19062 6.80547 3.72656 8 3.72656C9.19453 3.72656 10.316 4.19062 11.1604 5.03496C12.0047 5.8793 12.4688 7.00078 12.4688 8.19531V15.5H3.53125Z" />
  </svg>
);

export default NotificationIcon;
