import { AutoComplete, Select, type SelectProps } from 'antd';
import type { ReactElement, ReactNode, UIEvent } from 'react';
import React from 'react';
import styled from 'styled-components';

type SelectPropsToOmit = 'dropdownRender' | 'filterSort' | 'listHeight';

interface InfiniteScrollSelectProps
  extends Omit<SelectProps<any>, SelectPropsToOmit> {
  popupHeight?: number;
  hasMore: boolean;
  isDataLoading?: boolean;
  loader?: ReactElement;
  loadMore: () => void;
  as?: 'Select' | 'AutoComplete';
}

const OFFSET = 50;

export default function InfiniteScrollSelect<T>({
  as = 'Select',
  popupHeight = 300,
  isDataLoading = false,
  hasMore,
  loader,
  loadMore,
  onPopupScroll = () => {},
  ...props
}: InfiniteScrollSelectProps) {
  function handleInfiniteScroll(evt: UIEvent<HTMLDivElement>) {
    onPopupScroll(evt);
    const popupEl = evt.target as HTMLElement;
    const maxScroll = popupEl.scrollHeight - popupEl.clientHeight;
    const scroll = popupEl.scrollTop;

    //? if behave strange when scrolling remeber refer on how is implemented in useContactsEmailsForSearch
    if (hasMore && scroll + OFFSET > maxScroll && !isDataLoading) {
      loadMore();
    }
  }

  return React.createElement(
    as === 'Select' ? StyledSelect : StyledAutoComplete,
    {
      'data-cy': 'infiniteScrollSelect',
      loading: isDataLoading,
      listHeight: popupHeight,
      onPopupScroll: handleInfiniteScroll,
      // @ts-expect-error - this is needed for the select but not for the autocomplete
      filterOption: false,
      dropdownRender: (menu: ReactNode) => (
        <div data-cy="infiniteScrollSelect_dropdown">
          {menu}
          <StyledLoader $isLoading={isDataLoading}>{loader}</StyledLoader>
        </div>
      ),
      ...props,
    },
  );
}

const StyledLoader = styled.div<{ $isLoading: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  position: absolute;
  bottom: 0;
  transition: opacity 300ms ease;
  opacity: ${({ $isLoading }) => Number($isLoading)};
  pointer-events: none;
`;

const StyledSelect = styled(Select)`
  font-size: 13px;
  width: 100%;
`;
const StyledAutoComplete = styled(AutoComplete)`
  font-size: 13px;
  width: 100%;
`;
