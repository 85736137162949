import {
  CloseOutlined,
  FolderOutlined,
  PlusOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Alert, Button, Form, Input, Modal, message } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

import { BulkOperationStatus } from '@graphql/types/graphql';
import { ComplianceProfilesSelect } from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import { useCreateRequestRecordsMutation } from '@modules/request-records/hooks/useCreateRequestRecordsMutation';
import pluralize from 'pluralize';
import { useEffect } from 'react';
import { mapClientRequestToMutationRequest } from './CreateRequestsModal.utils';
import { RichSelect } from './components/RichSelect';
import {
  DEFAULT_CONTEXT,
  DEFAULT_CONTEXT_LENGTH,
  INITIAL_REQUESTS,
  MAX_NEW_REQUESTS_COUNT,
} from './constants';
import { useContextRecords } from './hooks';

type CreateRequestsModalProps = {
  open: boolean;
  recordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

const ALERT_DESCRIPTION =
  'Give your request a unique name. Your request name will be displayed to contacts on the Portal page.';

export const CreateRequestsModal = ({
  open,
  recordId,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: CreateRequestsModalProps) => {
  const [form] = Form.useForm();

  const { createRequestRecords, loading: createRequestRecordsLoading } =
    useCreateRequestRecordsMutation();

  const {
    contextRecords,
    contextRecordsTotalCount,
    loading: contextRecordsLoading,
    fetchMoreContextRecords,
    filterContextRecordsByName,
  } = useContextRecords({ skip: !open });

  const handleOnFormSubmit = async () => {
    try {
      await form.validateFields();
      const { requests } = form.getFieldsValue();

      const requestRecords = mapClientRequestToMutationRequest(
        requests,
        recordId,
      );

      createRequestRecords({
        requestRecords,
        onCompleted: (data) => {
          const status = data.createRequestRecords.operation.status;
          if (status === BulkOperationStatus.Completed) {
            message.success(
              `${requests.length} ${pluralize('request', requests.length)} created successfully.`,
            );
            onCompleted?.();
          }

          if (status === BulkOperationStatus.Scheduled) {
            message.success(
              `${requests.length} ${pluralize('request', requests.length)} have been scheduled for creation.`,
            );
            onScheduled?.();
          }

          if (status === BulkOperationStatus.Failed) {
            message.error(
              `The creation of ${requests.length} ${pluralize('request', requests.length)} has failed. Please try again or contact support.`,
            );
            onFailed?.();
          }
        },
      });
    } catch (error) {
      message.error('Error: form validation failed.');
      return;
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open, form]);

  return (
    <>
      <GlobalStyle />
      <StyledModal
        open={open}
        width={800}
        title="New requests"
        onCancel={() => {
          onCancel?.();
        }}
        okText="Create requests"
        onOk={handleOnFormSubmit}
        okButtonProps={{
          loading: createRequestRecordsLoading,
        }}
      >
        <StyledCreateForm>
          <StyledAlert closable type="info" description={ALERT_DESCRIPTION} />

          <div className="row header">
            <span>Request name</span>
            {contextRecords.length > DEFAULT_CONTEXT_LENGTH && (
              <span>Related</span>
            )}
            <span>Profile</span>
          </div>

          <Form
            name="dynamicRequestsForm"
            autoComplete="off"
            form={form}
            initialValues={INITIAL_REQUESTS}
          >
            <Form.List name="requests">
              {(fields, { add, remove }) => (
                <>
                  <StyledList>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="row">
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input a valid request name.',
                            },
                          ]}
                        >
                          <Input placeholder="Request name" />
                        </Form.Item>
                        {contextRecords.length > DEFAULT_CONTEXT_LENGTH && (
                          <Form.Item {...restField} name={[name, 'related']}>
                            <RichSelect
                              optionsList={contextRecords}
                              optionsTotalCount={contextRecordsTotalCount}
                              loading={contextRecordsLoading}
                              onSearch={filterContextRecordsByName}
                              onLoadMore={fetchMoreContextRecords}
                            >
                              {(option) => (
                                <>
                                  {option._id === DEFAULT_CONTEXT._id ? (
                                    <ShopOutlined className="item-icon" />
                                  ) : (
                                    <FolderOutlined className="item-icon" />
                                  )}
                                  <span>{option.name}</span>
                                </>
                              )}
                            </RichSelect>
                          </Form.Item>
                        )}
                        <Form.Item
                          {...restField}
                          name={[name, 'profile']}
                          getValueFromEvent={(profile) => profile._id}
                        >
                          <ComplianceProfilesSelect size={'middle'} />
                        </Form.Item>

                        <CloseOutlined
                          className="delete"
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                  </StyledList>
                  <Form.Item>
                    <Button
                      type="dashed"
                      disabled={fields.length >= MAX_NEW_REQUESTS_COUNT}
                      onClick={() =>
                        add({
                          name: `Request ${fields.length + 1}`,
                          related: DEFAULT_CONTEXT._id,
                          profile: null,
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      Add request
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </StyledCreateForm>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header,
  .ant-modal-footer {
    padding: 16px 24px;
    margin: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledCreateForm = styled.section`
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayTone};

  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) 24px;
    align-items: baseline;
    gap: 8px;
    padding: 12px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  }

  .delete {
    grid-column: span 1 / -1;
  }

  .header {
    font-weight: bold;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  padding: 9px 16px;
  border: none;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

const StyledList = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

const GlobalStyle = createGlobalStyle`
  .item-icon {
    margin-right: 5px;
  }
`;
