import { graphql } from '@graphql/types';
import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table/types';
import useLazyQueryWithRedux from '@graphql/hooks/useLazyQueryWithRedux';
import type { DocumentsQuery } from '@graphql/types/graphql';
import { getOperationName } from '@graphql/utils';
import { FETCH_DOCUMENTS } from '@modules/document/actions';
import { mapTableFiltersToLegacyDocumentsFilters } from '../utils';

/**
 * @note
 *
 * - "party.requirements" needed to define the next expiration date
 * @see src/pages/DocumentsList/DocumentsList.tsx#L83-L84
 *
 * - "compliantAttributes" needed to add a warning inside the delete modal when the table is in PartyDetailsPage
 * @see https://github.com/trustlayer/trustlayer-cwt/blob/f4de00948b98f4b9b84036449998a9f1df9b3faf/apps/web/src/pages/DocumentsList/DocumentsList.tsx#L134
 *
 * - "issuedBy" needed for flag and varification badges
 * @see src/modules/document/containers/DocumentsList/components/DocumentBadges/DocumentBadges.tsx#L28
 */

const DOCUMENTS_LIST_QUERY = graphql(`
  query Documents($filter: Any, $sort: Any, $skip: Int, $limit: Int) {
    listDocuments(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
      _id
      friendlyName
      compliantAttributes
      connectedLicense {
        _id
      }
      notes
      projects {
        _id
        name
      }
      appliesToAllProjects
      types {
        name
      }
      party {
        _id
        name
        requirements {
          subjectId
          subjectLabel
        }
      }
      flag {
        addedOn
        authorName
        severityLevel
        notes
        user {
          email
          profile {
            name
          }
        }
      }
      verification {
        verifiedOn
        notes
        user {
          profile {
            name
          }
        }
      }
      metadata
      url
      archivedAt
      createdAt
      issuedBy {
        name
      }
      reviewedAt
      deletedAt
      s3Key
    }
    countDocuments(filter: $filter, skip: $skip, limit: $limit)
  }
`);

export const useDocumentsList = ({
  primaryRecordId,
  contextRecordId,
}: {
  primaryRecordId?: string;
  contextRecordId?: string;
}) => {
  const [getDocumentsListQuery] = useLazyQueryWithRedux(DOCUMENTS_LIST_QUERY, {
    reduxActionKey: getOperationName(DOCUMENTS_LIST_QUERY),
    reduxActionType: FETCH_DOCUMENTS,
  });

  const getDocumentsList: GetRowData<DocumentsQuery['listDocuments'][0]> =
    useCallback(
      async ({ startRow, endRow, filters, sorts }) => {
        const activeFilters = mapTableFiltersToLegacyDocumentsFilters(filters, {
          primaryRecordId,
          contextRecordId,
        });

        const activeSort = sorts[0] || {};

        const { data } = await getDocumentsListQuery({
          variables: {
            skip: startRow,
            limit: endRow - startRow,
            filter: activeFilters,
            sort: {
              field: activeSort.colId,
              order: activeSort.sort,
            },
          },
        });

        return {
          rowData: data?.listDocuments || [],
          rowCount: data?.countDocuments || data?.listDocuments.length || 0,
        };
      },
      [primaryRecordId, contextRecordId, getDocumentsListQuery],
    );

  return {
    getDocumentsList,
  };
};
