import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const GET_CSV_DATA = `${namespace}/GET_CSV_DATA`;

//export event logs for .csv.
const GET_CSV_DATA_QUERY = gql`
  query getCSVEventLogsData($userId: String!) {
    getCSVEventLogsData(userId: $userId) {
      data
    }
  }
`;

export const getCSVEventLogsData = (userId) => ({
  type: GET_CSV_DATA,
  payload: {
    key: 'getCSVEventLogsData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: GET_CSV_DATA_QUERY,
      variables: {
        userId,
      },
    },
  },
});
