/**
 * Formatters
 */

/**
 * Record formatters are used to transform record objects before passing them to CommandBar.
 * Examples of transformations:
 *   - Setting reserved fields (icon, category). See https://app.commandbar.com/sdk#addcontext-initialvalue.
 *   - Creating a calculated string to be used as the object's label or description
 */

export const documentFormatter = ({ _id, friendlyName, thumbnailUrl }) => ({
  id: _id,
  label: friendlyName,
  icon: thumbnailUrl,
});

export const documentTypeFormatter = ({ _id, pluralName }) => ({
  id: _id,
  label: pluralName,
});

export const partyFormatter = ({ _id, name }) => ({
  id: _id,
  label: name,
});

export const partyTypeFormatter = ({ _id, pluralName }) => ({
  id: _id,
  label: pluralName,
});

export const projectFormatter = ({ description, name, _id }) => ({
  description,
  label: name,
  id: _id,
});

export const complianceProfileFormatter = ({ name, _id }) => ({
  label: name,
  id: _id,
});

/**
 * Helper Functions
 */

/**
 * Wrapper to add data to CommandBar context
 * Context is a store that lives on the browser
 * Data passed to context can be used to customize commands. All data stays local.
 * Context can be used in:
 *.   - Lists of options (called "Records")
 *    - Urls (e.g., routerFunction('/{{context.teamId}}/notifications') )
 *    - Availability conditions (e.g., only show command if user is an admin)
 *    - Recommendation conditions (e.g., recommend a command if the user is an admin)
 *
 * Documentation: https://app.commandbar.com/sdk#addContext
 */
export const addContext = (key, valueOrFunction, options) => {
  // Note: If valueOrFunction is a function, it will be called when the bar opens
  //       This is used to lazy load data fetching, and isolate it to when the bar is opened
  if (window) {
    window.CommandBar.addContext(key, valueOrFunction, options || {});
  }
};

/**
 * Wrapper to add callbacks to CommandBar.
 * Callbacks are functions that are triggered when users select commands.
 *
 * Documentation: https://app.commandbar.com/sdk#addCallback
 */

export const addCallback = (key, callback) => {
  if (window) {
    window.CommandBar.addCallback(key, callback);
  }
};

/**
 * Sets a router function that link commands can use to update the
 * page's URL without triggering a reload.
 * See https://www.commandbar.com/docs/commands/actions/link#link-types
 */
export const addRouter = (router) => {
  if (window) {
    window.CommandBar.addRouter(router);
  }
};
