import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { REPORT_FRAGMENT } from '../fragments';

export const FETCH_REPORTS = `${namespace}/FETCH_PARTY_TYPES`;

const FETCH_REPORTS_QUERY = gql`
  query listReports {
    listReports {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const fetchReports = () => ({
  type: FETCH_REPORTS,
  payload: {
    key: 'listReports',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_REPORTS_QUERY,
    },
  },
});
