import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_LAYOUT_FRAGMENT } from '../fragments';

export const SELECT_EMAIL_LAYOUT = `${namespace}/SELECT_EMAIL_LAYOUT`;

const SELECT_EMAIL_LAYOUT_MUTATION = gql`
  mutation selectEmailLayout($_id: String!) {
    selectEmailLayout(_id: $_id) {
      ...EmailLayout
    }
  }
  ${EMAIL_LAYOUT_FRAGMENT}
`;

export const selectEmailLayout = (_id) => ({
  type: SELECT_EMAIL_LAYOUT,
  payload: {
    key: 'selectEmailLayout',
    graphql: {
      mutation: SELECT_EMAIL_LAYOUT_MUTATION,
      variables: {
        _id,
      },
    },
  },
});
