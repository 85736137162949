import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const PRIMARY_OBJECT_QUERY = graphql(`
  query PrimaryObjectGenerality($input: PrimaryObjectInput!) {
    primaryObject(input: $input) {
      icon
      name
      pluralName
    }
  }
`);

export const usePrimaryObjectGenerality = ({
  objectSlug,
}: {
  objectSlug?: string;
}) => {
  const { data, loading } = useQuery(PRIMARY_OBJECT_QUERY, {
    variables: { input: { slug: objectSlug } },
    skip: !objectSlug,
  });

  return {
    primaryObject: data?.primaryObject,
    loading,
  };
};
