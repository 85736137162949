import { Space as SpaceOg } from 'antd';
import type React from 'react';
import styled from 'styled-components';

export const FullWidthSpace = (
  props: React.ComponentPropsWithoutRef<typeof SpaceOg>,
) => {
  return <StyledSpace {...props} />;
};

const StyledSpace = styled(SpaceOg)`
  width: 100%;
`;

export default FullWidthSpace;
