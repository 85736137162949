import type { SubscriptionHookOptions } from '@apollo/client';

import { useSubscription } from '@graphql/hooks';
import { getOrganizationMemberByUserId } from '@modules/organization-member/selectors';
import { getMyId } from '@modules/user/selectors';
import { useAppSelector } from '@store/hooks';

import { NOTIFICATION_UPDATES_SUBSCRIPTION } from '../subscriptions';

const useNotificationUpdatesSubscription = (
  options: SubscriptionHookOptions,
) => {
  const userId = useAppSelector(getMyId);
  const recipient = useAppSelector((state) =>
    getOrganizationMemberByUserId(state, userId),
  );

  return useSubscription(NOTIFICATION_UPDATES_SUBSCRIPTION, {
    skip: !recipient?._id,
    variables: { memberId: recipient?._id },
    ...options,
  });
};

export default useNotificationUpdatesSubscription;
