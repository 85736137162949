import { reorder } from '@common/utils/filter-helpers';
import type { ComplianceModule } from '@graphql/types/graphql';
import clone from 'lodash/cloneDeep';
import { ComplianceProfileEntity } from './type';

export const getRequirementsWarningText = (moduleName: string) =>
  `Are you sure you want to remove all the requirements for the module ${moduleName}?`;

export const getAttributesWarningText = (documentType: string) =>
  `Are you sure you want to remove all attributes of this document type ${documentType}?`;

type reorderComplianceProfileParams = {
  modules: ComplianceModule[];
  type?: ComplianceProfileEntity;
  itemId: string;
  newItemIndex: number;
  currentItemIndex: number;
};

export const reorderComplianceProfile = ({
  modules,
  type,
  newItemIndex,
  currentItemIndex,
  itemId,
}: reorderComplianceProfileParams) => {
  const clonedModules = clone(modules);

  if (type === ComplianceProfileEntity.modules) {
    return reorder(clonedModules, currentItemIndex, newItemIndex);
  }

  if (type === ComplianceProfileEntity.subjects) {
    const [moduleCode] = itemId.split('-');
    return clonedModules.map((module) => {
      if (module.code === moduleCode) {
        return {
          ...module,
          subjects: reorder(module.subjects, currentItemIndex, newItemIndex),
        };
      }
      return module;
    });
  }

  if (type === ComplianceProfileEntity.requirements) {
    const [moduleCode, subjectCode] = itemId.split('-');

    return clonedModules.map((module) => {
      if (module.code !== moduleCode) {
        return module;
      }

      return {
        ...module,
        subjects: module.subjects.map((subject) => {
          if (subject.code !== subjectCode) {
            return subject;
          }

          return {
            ...subject,
            requirements: reorder(
              subject.requirements,
              currentItemIndex,
              newItemIndex,
            ),
          };
        }),
      };
    });
  }

  return clonedModules;
};
