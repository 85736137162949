import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  getEditorHTMLContent,
  getEditorPlainText,
  getEmptyEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import * as PartyConversationActions from '@modules/party-conversation/actions';
import { getParty } from '@modules/party/selectors';

import { FullWidthSelect } from '@trustlayer/ui';
import RichTextArea from '../components/RichTextArea';

const CreateConversationModal = ({
  selectedRecipientId,
  visible,
  onCancel = () => {},
}) => {
  const { partyId } = useParams();

  const [attachments, setAttachments] = useState([]);
  const [isFirstWrite, setIsFirstWrite] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isAttaching, setIsAttaching] = useState(false);
  const [messageEditorState, setMessageEditorState] = useState(
    getEmptyEditorState(),
  );
  const partyData = useSelector((state) => getParty(state, partyId));
  const organizationData = useSelector(getActiveOrganizationData);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const partyContacts = partyData?.partyContacts ?? [];
  const organizationName = organizationData?.name ?? '';
  const primaryContact = partyContacts.find((contact) => contact.isPrimary);
  const initialPrimaryParticipant = Boolean(primaryContact?.email)
    ? [primaryContact._id]
    : [];

  useEffect(() => {
    if (!isEditorStateBlank(messageEditorState) && !isFirstWrite) {
      setIsFirstWrite(true);
    }
  }, [messageEditorState, isFirstWrite]);

  useEffect(() => {
    if (isFirstWrite) {
      if (isEditorStateBlank(messageEditorState)) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    } else {
      setIsValid(true);
    }
  }, [messageEditorState, isFirstWrite]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (visible && form) {
      form.setFieldsValue({
        subject: `Message from ${organizationName}`,
        participants: Boolean(selectedRecipientId)
          ? [selectedRecipientId]
          : initialPrimaryParticipant,
      });
    }
  }, [visible, form]);

  const handleCancel = () => {
    onCancel();
    setMessageEditorState(getEmptyEditorState());
    setIsFirstWrite(false);
    setIsValid(true);
    form.resetFields();
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const values = await form.validateFields();
      if (!isFirstWrite) {
        setIsValid(false);
        return;
      }
      if (isValid && !isAttaching) {
        setIsSending(true);
        await dispatch(
          PartyConversationActions.createPartyConversation({
            ...values,
            party: partyId,
            message: {
              message: getEditorPlainText(messageEditorState),
              outputHtml: getEditorHTMLContent(messageEditorState),
              attachments,
            },
          }),
        );
        setIsSending(false);
        onCancel();
      }
    } catch {}
  };

  return (
    <ModalWrapper
      open={visible}
      onCancel={handleCancel}
      onOk={onSubmit}
      okText="Send"
      okButtonProps={{ loading: isSending, 'data-cy': 'messageSendBtn' }}
      title="New conversation"
      width={800}
      maskClosable={false}
    >
      <Form form={form} name="partyCreateConversationModal" layout="vertical">
        <StyledFormItem
          label="Recipients"
          name="participants"
          rules={[
            {
              required: true,
              message: 'Please select message recipients',
            },
          ]}
        >
          <FullWidthSelect
            placeholder="Enter recipients..."
            optionFilterProp="name"
            data-cy="recipientsSelection"
            mode="multiple"
          >
            {partyContacts
              .filter((contact) => contact.email)
              .map((contact) => (
                <Select.Option
                  key={contact._id}
                  value={contact._id}
                  name={contact.contactPersonName}
                >
                  {contact.contactPersonName} {'<'}
                  {contact.email}
                  {'>'}
                </Select.Option>
              ))}
          </FullWidthSelect>
        </StyledFormItem>
        <StyledFormItem
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please enter the message subject',
            },
          ]}
        >
          <Input data-cy="conversationSubject" placeholder="Enter subject..." />
        </StyledFormItem>
        <StyledFormItem label="Message" required>
          <StyledEditorWrapper>
            <RichTextArea
              minHeight={80}
              isValid={isValid}
              focusDependence={visible}
              isAttaching={isAttaching}
              setIsAttaching={setIsAttaching}
              attachments={attachments}
              setAttachments={setAttachments}
              textAreaEditorState={messageEditorState}
              onChange={setMessageEditorState}
            />
          </StyledEditorWrapper>
        </StyledFormItem>
      </Form>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 10px;
  }

  .ant-modal-footer {
    button + button {
      margin-left: 10px;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0 0 10px !important;
`;

const StyledEditorWrapper = styled.div`
  line-height: normal;
`;

export default CreateConversationModal;
