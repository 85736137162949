import { gql } from '@apollo/client';
import { message as notify } from 'antd';
import * as R from 'ramda';
import { END, eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancelled,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import graphqlClient from '@graphql/client';
import {
  BULK_DELETE_COMPLIANCE_PROFILE,
  CREATE_OR_UPDATE_COMPLIANCE_PROFILE,
  DELETE_COMPLIANCE_PROFILE,
  EXPORT_COMPLIANCE_PROFILES,
  IMPORT_COMPLIANCE_PROFILES,
  SET_IS_ACTIVE_DOCUMENT_CHECKLIST,
  SET_IS_ACTIVE_SUBJECT,
  createComplianceProfile,
  setCurrentComplianceProfile,
  setCurrentDocumentChecklists,
  setCurrentRequirements,
  updateComplianceProfile,
} from '@modules/compliance-profile/actions';
import { AttributeType, Operator } from '@modules/compliance-profile/constants';
import { setIsReloadPage } from '@modules/organization/actions';
import { setIsChangingComplianceProfile } from '@modules/party/actions';
import { getTemplates } from '@modules/requirement/selectors';

import { trackEvent } from '@common/utils/track-helpers';
import {
  getCurrentComplianceProfile,
  getCurrentDocumentChecklists,
  getCurrentRequirements,
} from '../selectors';

const COMPLIANCE_PROFILE_PROCESSED = gql`
  subscription onComplianceProfilesProcessed($organizationId: String!) {
    complianceProfilesProcessed(organizationId: $organizationId)
  }
`;

function* createOrUpdateComplianceProfileSaga() {
  const complianceProfile = yield select(getCurrentComplianceProfile);
  const rules = yield select(getCurrentRequirements);
  const documentChecklists = yield select(getCurrentDocumentChecklists);

  if (complianceProfile._id) {
    const rulesToUpdate = rules.map((rule) =>
      R.omit(['_id', 'complianceProfile', 'updatedAt', 'createdAt'], rule),
    );
    const documentChecklistsToUpdate = documentChecklists.map(
      R.omit(['_id', 'createdAt', 'updatedAt']),
    );

    yield put(setIsChangingComplianceProfile(true));
    const res = yield putResolve(
      updateComplianceProfile(
        R.omit(
          [
            'baseComplianceProfile',
            'partiesCount',
            'projectsCount',
            'updatedAt',
            'createdAt',
            'deletedAt',
            'deletedBy',
          ],
          R.mergeRight(complianceProfile, {
            rules: rulesToUpdate,
            documentChecklists: documentChecklistsToUpdate.map((checklist) => ({
              ...checklist,
              type: checklist.type._id,
            })),
          }),
        ),
      ),
    );

    const updatedProfile =
      R.path(['payload', 'data', 'updateComplianceProfile'], res) || {};

    yield call(trackEvent, 'User updated a compliance profile');
    yield put(setIsChangingComplianceProfile(false));
    yield put(setCurrentComplianceProfile(updatedProfile));
    yield put(setCurrentRequirements(updatedProfile.rules));
    yield put(setCurrentDocumentChecklists(updatedProfile.documentChecklists));

    if (R.prop('projectsCount', updatedProfile)) {
      yield notify.info(
        'Changes are being applied across projects using the profile.',
      );
    } else {
      yield notify.info(
        'Changes are being applied across parties using the profile.',
      );
    }
  } else {
    yield put(setIsChangingComplianceProfile(true));
    const res = yield putResolve(
      createComplianceProfile({
        ...complianceProfile,
        rules,
        documentChecklists: documentChecklists.map((checklist) => ({
          ...checklist,
          type: checklist.type._id,
        })),
      }),
    );
    const createdProfile =
      R.path(['payload', 'data', 'createComplianceProfile'], res) || {};

    yield call(trackEvent, 'User created a compliance profile');
    yield put(setIsChangingComplianceProfile(false));
    yield put(setCurrentComplianceProfile(createdProfile));
    yield put(setCurrentRequirements(createdProfile.rules));
    yield put(setCurrentDocumentChecklists(createdProfile.documentChecklists));
    yield notify.success('Compliance profile created successfully.');
  }
}

function* setIsActiveSubjectSaga({ payload: { subjectId, isActive } }) {
  const requirements = yield select(getCurrentRequirements);
  const templates = yield select(getTemplates);

  if (isActive) {
    const newRequirement = () => {
      if (subjectId === 'commercialGeneralLiability') {
        const definition = R.find(
          R.propEq(
            'attributeId',
            'evidenceOfInsuranceCommercialGeneralLiabilityEachOccurrence',
          ),
          templates,
        );
        return R.append(
          {
            ...definition,
            operator: Operator.GREATER_OR_EQUAL,
            targetValue: '1000000',
          },
          requirements,
        );
      } else if (subjectId === 'automobileLiability') {
        const definition = R.find(
          R.propEq(
            'attributeId',
            'evidenceOfInsuranceAutomobileLiabilityCombinedSingleLimitEachAccident',
          ),
          templates,
        );
        return R.append(
          {
            ...definition,
            operator: Operator.GREATER_OR_EQUAL,
            targetValue: '1000000',
          },
          requirements,
        );
      } else if (subjectId === 'excessOrUmbrellaLiability') {
        const definition = R.find(
          R.propEq(
            'attributeId',
            'evidenceOfInsuranceExcessOrUmbrellaLiabilityAggregate',
          ),
          templates,
        );
        return R.append(
          {
            ...definition,
            operator: Operator.GREATER_OR_EQUAL,
            targetValue: '1000000',
          },
          requirements,
        );
      } else if (subjectId === 'workersCompensation') {
        const definition = R.find(
          R.propEq(
            'attributeId',
            'evidenceOfInsuranceWorkersCompensationStatutoryLimits',
          ),
          templates,
        );
        return R.append(
          {
            ...definition,
            operator: Operator.PRESENT,
          },
          requirements,
        );
      } else if (subjectId === 'insuranceLicense') {
        const definition = R.find(
          R.propEq(
            'attributeId',
            'licensesAndPermitsInsuranceLicenseNationalProducerNumber',
          ),
          templates,
        );
        return R.append(
          {
            ...definition,
            operator: Operator.PRESENT,
          },
          requirements,
        );
      }
      const definition = R.find(R.propEq('subjectId', subjectId), templates);

      return R.append(
        {
          ...definition,
          attributeType: AttributeType.Boolean,
          operator: Operator.PRESENT,
        },
        requirements,
      );
    };

    yield put(setCurrentRequirements(newRequirement()));
  }
}

function* setIsActiveDocumentChecklistSaga({ payload: { typeId, isActive } }) {
  const currentChecklists = yield select(getCurrentDocumentChecklists);
  const checklistsToUpdate = R.compose(
    R.uniq,
    R.map((checklist) => {
      if (checklist.type._id === typeId) {
        return R.mergeRight(checklist, {
          isActive,
          attributeId: '',
          attributeLabel: '',
        });
      }
      return checklist;
    }),
  )(currentChecklists);

  yield put(setCurrentDocumentChecklists(checklistsToUpdate));
}

function* deleteComplianceProfileSuccessSaga({ payload }) {
  yield call(trackEvent, 'User deleted a compliance profile');
  yield notify.info('Compliance profile deleted successfully.');
}

function* complianceProfilesProcessed() {
  const organizationId = yield select((state) => state.organization.active.id);

  try {
    const complianceProfileChannel = yield call(() =>
      eventChannel((emit) => {
        const subscription = graphqlClient
          .subscribe({
            query: COMPLIANCE_PROFILE_PROCESSED,
            variables: {
              organizationId,
            },
          })
          .subscribe({
            next(data) {
              emit(data);
              emit(END);
            },
          });

        const unsubscribe = () => {
          subscription.unsubscribe();
        };

        return unsubscribe;
      }),
    );

    // eslint-disable-next-line fp/no-loops
    while (true) {
      try {
        const payload = yield take(complianceProfileChannel);

        yield fork(handleComplianceProfilesProcessedEvents, payload);
      } catch (err) {
        console.error('Socket error:', err);
      }
    }
  } catch (err) {
    console.error('Saga error:', err);
  } finally {
    if (yield cancelled()) {
      yield fork(complianceProfilesProcessed);
    }
  }
}

function* handleComplianceProfilesProcessedEvents({ data }) {
  switch (data?.complianceProfilesProcessed?.type) {
    case 'export:compliance:profile:success': {
      yield notify.success(
        'Compliance profile(s) successfully exported and sent to your email.',
      );
      break;
    }
    case 'export:compliance:profile:fail': {
      yield notify.error('Compliance profiles failed to export.');
      break;
    }
    case 'import:compliance:profile:success': {
      yield notify.success('Compliance profiles were successfully imported.');
      yield put(setIsReloadPage(true));
      break;
    }
    case 'import:compliance:profile:fail': {
      yield notify.error('Compliance profiles failed to import.');
      break;
    }
    case 'bulk:delete:compliance:profile:success': {
      notify.success('Bulk deletion of compliance profiles complete.');
      yield put(setIsReloadPage(true));
      break;
    }
    case 'bulk:delete:compliance:profile:fail': {
      yield notify.error('Bulk deletion of compliance profiles failed.');
      break;
    }
    default:
      return;
  }
}

function* ruleSagas() {
  yield all([
    takeLatest(
      CREATE_OR_UPDATE_COMPLIANCE_PROFILE,
      createOrUpdateComplianceProfileSaga,
    ),
    takeLatest(
      `${DELETE_COMPLIANCE_PROFILE}_SUCCESS`,
      deleteComplianceProfileSuccessSaga,
    ),
    takeLatest(IMPORT_COMPLIANCE_PROFILES, complianceProfilesProcessed),
    takeLatest(EXPORT_COMPLIANCE_PROFILES, complianceProfilesProcessed),
    takeLatest(BULK_DELETE_COMPLIANCE_PROFILE, complianceProfilesProcessed),
    takeLatest(SET_IS_ACTIVE_SUBJECT, setIsActiveSubjectSaga),
    takeLatest(
      SET_IS_ACTIVE_DOCUMENT_CHECKLIST,
      setIsActiveDocumentChecklistSaga,
    ),
  ]);
}

export default ruleSagas;
