import { Avatar } from 'antd';
import ColorHash from 'color-hash';
import * as R from 'ramda';
import styled from 'styled-components';

const geAbbreviature = R.compose(
  R.toUpper,
  R.join(''),
  R.map(R.head),
  R.split(' '),
  R.defaultTo(''),
);

const StyledAvatar = styled(Avatar)`
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  .ant-avatar-string {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }

  && {
    background-color: ${(props) => (props.color ? props.color : 'none')};
  }
`;

const colorHash = new ColorHash({
  lightness: 0.6,
  saturation: [0.75],
});

const UserAvatar = ({ name, email }) => {
  const nameOrEmail = name || email;

  return (
    <StyledAvatar
      shape="circle"
      color={colorHash.hex(nameOrEmail.toLowerCase())}
      size={40}
    >
      {geAbbreviature(nameOrEmail)}
    </StyledAvatar>
  );
};

export default UserAvatar;
