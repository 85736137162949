import * as R from 'ramda';

/**
 * Get attributes by typeId.
 */
export const getAttributesByDocumentType = (documentChecklists, type) =>
  R.compose(
    R.filter(R.pathEq(['type', '_id'], type._id)),
    R.filter((x) => Boolean(x.type) && Boolean(x.attributeId)),
  )(documentChecklists);

/**
 * Generate default checklists data.
 */
export const generateDefaultDocumentChecklists = (defaultDocumentType) => [
  {
    type: defaultDocumentType,
    attributeId: '',
    attributeLabel: '',
    isActive: true,
  },
];
