import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_TYPE_FRAGMENT } from '../fragments';

export const DELETE_PARTY_TYPE = `${namespace}/DELETE_PARTY_TYPE`;

const DELETE_PARTY_TYPE_MUTATION = gql`
  mutation deletePartyType($payload: DeletePartyTypeInput!) {
    deletePartyType(data: $payload) {
      ...PartyType
    }
  }
  ${PARTY_TYPE_FRAGMENT}
`;

export const deletePartyType = (payload) => ({
  type: DELETE_PARTY_TYPE,
  payload: {
    key: 'deletePartyType',
    graphql: {
      mutation: DELETE_PARTY_TYPE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
