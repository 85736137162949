import { namespace } from '../constants';

export const CLOSE_DRAWER = `${namespace}/CLOSE_DRAWER` as const;

export const closeNotificationDrawer = () => ({
  type: CLOSE_DRAWER,
});

export type CloseNotificationDrawerAction = ReturnType<
  typeof closeNotificationDrawer
>;
