import { gql } from '@apollo/client';

import { COMPLIANCE_PROFILE_FRAGMENT } from '@modules/compliance-profile/fragments';

export const CUSTOM_FIELD_FRAGMENT = gql`
  fragment CustomField on CustomField {
    _id
    name
    description
    source
    type
    options {
      key
      value
    }
    color
    complianceProfile {
      ...ComplianceProfileFrag
    }
    organization
    associatedEntities
  }
  ${COMPLIANCE_PROFILE_FRAGMENT}
`;
