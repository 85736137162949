import { Checkbox, Collapse } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

import DateFilterSelector, {
  KeyType,
} from '@common/components/DateFilterSelector';
import {
  adaptFilterToStartOfDayUtc,
  getSearchParamsToOmit,
  getSelectedFilter,
  pickOptionsFromFilterTypeOptions,
} from '@common/utils/date';
import CustomFieldTypeForm from '@modules/custom-field/components/CustomFieldTypeForm';
import {
  customFieldPath,
  getValueFromUrl,
} from '@modules/custom-field/utils/custom-field-helpers';

import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { ProjectFilterFields } from '../constants';

// TODO: BE should work on updating the date filter logic as it was done in parties and docs page
// TODO: adaptFilterToStartOfDayUtc is used because the previous DateFilter converted everything to UTC
// TODO: we should replace it with adaptFilter
const ProjectFiltersPopoverContent = ({
  searchParams,
  customFields,
  updateFilters,
}) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();
  return (
    <ProjectFiltersPopoverContent.Wrapper data-cy="projectFiltersContainer">
      <Collapse bordered={false}>
        <Collapse.Panel
          header={
            searchParams?.[ProjectFilterFields.StartDate] ? (
              <em>Start date</em>
            ) : (
              'Start date'
            )
          }
        >
          <DateFilterSelector
            selectedFilter={getSelectedFilter(
              ProjectFilterFields.StartDate,
              searchParams,
            )}
            filterTypes={pickOptionsFromFilterTypeOptions([
              KeyType.NEXT,
              KeyType.LAST,
              KeyType.BETWEEN,
              KeyType.ON,
            ])}
            onChange={(selectedFilter) =>
              updateFilters(
                adaptFilterToStartOfDayUtc(
                  ProjectFilterFields.StartDate,
                  selectedFilter,
                ),
                getSearchParamsToOmit(
                  searchParams,
                  ProjectFilterFields.StartDate,
                ),
              )
            }
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={
            searchParams?.[ProjectFilterFields.EndDate] ? (
              <em>End date</em>
            ) : (
              'End date'
            )
          }
        >
          <DateFilterSelector
            selectedFilter={getSelectedFilter(
              ProjectFilterFields.EndDate,
              searchParams,
            )}
            filterTypes={pickOptionsFromFilterTypeOptions([
              KeyType.NEXT,
              KeyType.LAST,
              KeyType.BETWEEN,
              KeyType.ON,
            ])}
            onChange={(selectedFilter) =>
              updateFilters(
                adaptFilterToStartOfDayUtc(
                  ProjectFilterFields.EndDate,
                  selectedFilter,
                ),
                getSearchParamsToOmit(
                  searchParams,
                  ProjectFilterFields.EndDate,
                ),
              )
            }
          />
        </Collapse.Panel>
        {isComplianceRequirementsAddOnEnabled && (
          <Collapse.Panel
            header={
              searchParams?.[ProjectFilterFields.ComplianceStatus] ? (
                <em>Compliance status</em>
              ) : (
                'Compliance status'
              )
            }
          >
            <Checkbox.Group
              value={R.flatten([
                searchParams?.[ProjectFilterFields.ComplianceStatus],
              ])}
              onChange={(values) =>
                updateFilters({
                  [ProjectFilterFields.ComplianceStatus]: values,
                })
              }
            >
              <div>
                <Checkbox data-cy="compliantCheckbox" value="compliant">
                  Compliant
                </Checkbox>
              </div>
              <div>
                <Checkbox data-cy="nonCompliantCheckbox" value="non_compliant">
                  Non-compliant
                </Checkbox>
              </div>
            </Checkbox.Group>
          </Collapse.Panel>
        )}
        {customFields.map(({ _id, name, type, options }) => (
          <Collapse.Panel
            key={_id}
            header={
              <ProjectFiltersPopoverContent.CustomFieldName>
                {searchParams?.[customFieldPath(_id, type)] ? (
                  <em>{name}</em>
                ) : (
                  name
                )}
              </ProjectFiltersPopoverContent.CustomFieldName>
            }
          >
            <CustomFieldTypeForm
              customFieldName={name}
              type={type}
              value={getValueFromUrl(_id, type, searchParams)}
              options={options}
              onChange={(value) => {
                const customFieldKey = customFieldPath(_id, type);

                value
                  ? updateFilters({
                      [customFieldKey]: value,
                    })
                  : updateFilters({}, [customFieldKey]);
              }}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </ProjectFiltersPopoverContent.Wrapper>
  );
};

ProjectFiltersPopoverContent.Wrapper = styled.div`
  width: 400px;
  margin: -12px;
  overflow-y: auto;
  max-height: 65vh;
  min-width: 253px;

  em {
    font-style: normal;
    color: rgb(24, 144, 255);
  }

  .ant-collapse {
    & > .ant-collapse-item {
      & > .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 10px 16px;
      }

      .ant-collapse-arrow {
        left: 0;
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: baseline;

    .ant-checkbox {
      position: relative;
      top: 2px;
    }

    .ant-checkbox + span {
      font-size: 13px;
    }
  }
`;

ProjectFiltersPopoverContent.CustomFieldName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ProjectFiltersPopoverContent;
