import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { SCHEDULED_REPORT_FRAGMENT } from '../fragments';

export const CREATE_SCHEDULED_REPORT = `${namespace}/CREATE_SCHEDULED_REPORT`;

const CREATE_SCHEDULED_REPORT_MUTATION = gql`
  mutation createScheduledReport($payload: CreateScheduledReportInput!) {
    createScheduledReport(data: $payload) {
      ...ScheduledReport
    }
  }
  ${SCHEDULED_REPORT_FRAGMENT}
`;

export const createScheduledReport = (payload) => ({
  type: CREATE_SCHEDULED_REPORT,
  payload: {
    key: 'createScheduledReport',
    graphql: {
      mutation: CREATE_SCHEDULED_REPORT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
