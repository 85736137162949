import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';

import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import {
  type CustomFilterProps,
  CustomFilterWrapper,
  type IdFilter,
} from '@common/components/Table';

import { usePrimaryRecordTypes } from './hooks';

const normalizeFilterValue = (value: string | string[] | undefined) => {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return null;
  }

  return {
    filter: Array.isArray(value) ? value : [value],
  };
};

export const PrimaryRecordTypesFilter = ({
  model,
  onModelChange,
}: CustomFilterProps<IdFilter>) => {
  const { types, typesTotalCount, loading, fetchMoreTypes, filterTypesByName } =
    usePrimaryRecordTypes();

  return (
    <CustomFilterWrapper>
      <InfiniteScrollSelect
        value={model?.filter}
        mode="multiple"
        isDataLoading={loading}
        allowClear
        showSearch
        onSearch={filterTypesByName}
        loadMore={fetchMoreTypes}
        hasMore={typesTotalCount > types.length}
        loader={<LoadingOutlined />}
        onChange={(value) => {
          onModelChange(normalizeFilterValue(value));
        }}
        placeholder="Search record types..."
      >
        {types?.map((recordType) => (
          <Select.Option key={recordType._id} value={recordType._id}>
            {recordType.name}
          </Select.Option>
        ))}
      </InfiniteScrollSelect>
    </CustomFilterWrapper>
  );
};
