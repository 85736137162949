import {
  CloseCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Alert, Button, Modal } from 'antd';
import { getExtension } from 'mime';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import FileName from '@common/components/FileName/FileName';
import {
  cancelDocumentsUploading,
  processDocumentsFiles,
} from '@modules/document/actions';
import { getIsUploadingDocuments } from '@modules/document/selectors';
import DocumentUploader from '@modules/request/components/DocumentUploader';

const UploadDocumentModal = ({
  defaultFiles = [],
  defaultRejectedFiles = [],
  isShow,
  setIsShow,
  onCompleted,
  ...dataToUpload
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [startUploading, setStartUploading] = useState(false);

  const mimetype = R.compose(R.prop('type'), R.head)(uploadedFiles);

  const uploading = useSelector(getIsUploadingDocuments);

  const hasFiles =
    !R.isEmpty(uploadedFiles) ||
    !R.isEmpty(rejectedFiles) ||
    !R.isEmpty(defaultRejectedFiles);

  useEffect(() => {
    if (startUploading && !uploading) {
      setUploaded(true);
      setStartUploading(false);
      if (onCompleted) {
        onCompleted();
      }
      setIsShow(false);
    }
  }, [startUploading, uploading, setIsShow, onCompleted]);

  useEffect(() => {
    if (Boolean(defaultFiles.length)) {
      setUploadedFiles(defaultFiles);
    }
  }, [defaultFiles]);

  const dispatch = useDispatch();

  const onUpload = async () => {
    await dispatch(
      processDocumentsFiles({
        files: uploadedFiles,
        data: {
          ...dataToUpload,
        },
      }),
    );
    setStartUploading(true);
  };

  const onClose = () => {
    setIsShow(false);
    setUploadedFiles([]);
    setRejectedFiles([]);
    setUploaded(false);
    setStartUploading(false);
  };

  const onCancel = () => {
    if (uploading) {
      Modal.confirm({
        title: 'Operation in progress',
        content:
          'An upload operation is in progress. Closing this modal now could result in a partial upload.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Continue uploading',
        cancelText: 'Cancel',
        onCancel() {
          dispatch(cancelDocumentsUploading());
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  const handleRemoveFile = (file) => {
    setUploadedFiles(
      uploadedFiles.filter((uploadFile) => file.name !== uploadFile.name),
    );
  };

  return (
    <UploadDocumentModal.Modal
      closable={false}
      maskClosable={false}
      open={isShow}
      title="Upload documents"
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onCancel} disabled={uploaded}>
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={uploading || R.isEmpty(uploadedFiles)}
          type="primary"
          onClick={() => {
            if (!uploaded) {
              onUpload();
            } else {
              onClose();
            }
          }}
        >
          {uploaded ? 'Continue' : 'Upload'}
        </Button>,
      ]}
    >
      <UploadDocumentModal.DocumentLabel>
        Upload one or more documents using the control below:
      </UploadDocumentModal.DocumentLabel>
      {hasFiles && !uploading && !uploaded ? (
        <>
          {(rejectedFiles?.length > 0 || defaultRejectedFiles?.length > 0) && (
            <StyledAlert
              type="error"
              description={
                <>
                  <p>
                    The files below cannot be uploaded because the file format
                    is not supported or is corrupted
                  </p>
                  <StyledRejectedFiles>
                    {defaultRejectedFiles.map((file) => (
                      <li key={`key_${file.name}`}>{file.file.name}</li>
                    ))}
                    {rejectedFiles.map((file) => (
                      <li key={`key_${file.name}`}>{file.file.name}</li>
                    ))}
                  </StyledRejectedFiles>
                </>
              }
              icon={<CloseCircleFilled />}
              showIcon
            />
          )}

          <UploadDocumentModal.FileWrapper>
            {uploadedFiles?.map((file) => (
              <FileName
                key={`key_${file.name}`}
                filename={file.name}
                onRemove={() => handleRemoveFile(file)}
              />
            ))}
          </UploadDocumentModal.FileWrapper>
        </>
      ) : (
        <DocumentUploader
          format={getExtension(mimetype)}
          // failed={failed}
          onUpload={(acceptedFiles, rejectedFiles) => {
            setUploadedFiles(acceptedFiles);
            setRejectedFiles(rejectedFiles);
          }}
          disableClick={uploading || uploaded}
          uploading={uploading}
          uploaded={uploaded}
        />
      )}
    </UploadDocumentModal.Modal>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 30px;
`;

const StyledRejectedFiles = styled.ul`
  padding-left: 30px;
`;

UploadDocumentModal.Modal = styled(Modal)`
  width: 800px !important;
`;

UploadDocumentModal.DocumentLabel = styled.p`
  margin: 0 0 30px;
  font-weight: 500;
`;

UploadDocumentModal.FileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > div {
    width: auto;
    margin-right: 5px;
  }
`;

UploadDocumentModal.DeleteIcon = styled(Button)`
  margin-left: 10px;
  margin-bottom: 3px;
`;

export default UploadDocumentModal;
