import styled from 'styled-components';

import FlagBadgePopover from '@common/components/Badges/components/FlagBadgePopover';
import ProjectAssociationBadgePopover from '@common/components/Badges/components/ProjectAssociationBadgePopover';
import VerificationBadgePopover from '@common/components/Badges/components/VerificationBadgePopover';
import type { Document } from '@graphql/types/graphql';

type DocumentBadgesProps = {
  document: Document;
  projectId?: string;
};

export const DocumentBadges = ({
  document,
  projectId,
}: DocumentBadgesProps) => {
  if (!document) {
    return null;
  }

  return (
    <StyledBadges>
      <StyledFlag
        defaultVisible
        isShortVersion
        documentData={{
          _id: document._id,
          issuedBy: document.issuedBy,
          documentName: document.friendlyName,
        }}
        flagData={document.flag}
        sizeIcon="Large"
        verificationData={undefined}
        nearestFlagSeverity={undefined}
      />

      <VerificationBadgePopover
        isShortVersion
        documentData={{
          _id: document._id,
          issuedBy: document.issuedBy,
          documentName: document.friendlyName,
        }}
        verificationData={document.verification}
        sizeIcon="Large"
        flagData={undefined}
      />

      <ProjectAssociationBadgePopover
        documentData={{
          _id: document._id,
          appliesToAllProjects: document.appliesToAllProjects,
          projects: document.projects,
          issuedBy: document.issuedBy,
          documentName: document.friendlyName,
        }}
        sizeIcon="Large"
        currentProjectId={projectId}
      />
    </StyledBadges>
  );
};

const StyledBadges = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledFlag = styled(FlagBadgePopover)`
  margin: 0;
`;
