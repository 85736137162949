import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_EMAIL_SENDER,
  DELETE_EMAIL_SENDER,
  FETCH_EMAIL_SENDERS,
  SELECT_EMAIL_SENDER,
  UPDATE_EMAIL_SENDER,
} from '../actions/index.js';

const initialState = {
  data: {},
};

const EmailSenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_EMAIL_SENDERS}_SUCCESS`: {
      const emailSenders = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', emailSenders, state);
    }
    case `${SELECT_EMAIL_SENDER}_SUCCESS`: {
      const emailSenders = R.compose(
        R.mergeDeepRight(state.data),
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', emailSenders, state);
    }
    case `${CREATE_EMAIL_SENDER}_SUCCESS`:
    case `${UPDATE_EMAIL_SENDER}_SUCCESS`: {
      const emailSender = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.assocPath(['data', emailSender._id], emailSender, state);
    }
    case `${DELETE_EMAIL_SENDER}_SUCCESS`: {
      const emailSender = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.dissocPath(['data', emailSender._id], state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default EmailSenderReducer;
