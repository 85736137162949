import { WarningFilled } from '@ant-design/icons';
import { Alert, Button, Space } from 'antd';
import styled from 'styled-components';

import { SingleCollapse } from '@common/components/SingleCollapse';

type IssueListProps = {
  onResolveClik: (contactId: string, issueId: string) => void;
  onIgnoreClik: (issueId: string) => void;
  issues: Array<{
    _id: string;
    contactId: string;
    email: string;
    ignorable: boolean;
    messageId: string;
    partyConversationId: string;
    reason: string;
    resendable: boolean;
    resolved: boolean;
    sendgridEventId: string;
  }>;
};

export const IssueList = ({
  issues = [],
  onIgnoreClik,
  onResolveClik,
  ...rest
}: IssueListProps) => {
  return (
    <StyledWrapper {...rest}>
      {issues &&
        issues.map(
          ({ _id, email, resendable, ignorable, resolved, contactId }) => (
            <SingleCollapse
              header={`Message not delivered to ${email}`}
              key={_id}
              isCollapsed={resolved}
            >
              <Alert
                showIcon
                message={
                  <>
                    <strong>Message not delivered</strong> - the address{' '}
                    <strong>{email}</strong> could not be found, check for typos
                    or unnecessary spaces and try again.
                  </>
                }
                action={
                  <Space direction="vertical">
                    {ignorable && (
                      <Button
                        size="small"
                        type="ghost"
                        onClick={() => onIgnoreClik(_id)}
                      >
                        Ignore
                      </Button>
                    )}
                    {resendable && (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => onResolveClik(contactId, _id)}
                      >
                        Resend
                      </Button>
                    )}
                  </Space>
                }
                type="warning"
                icon={<WarningFilled />}
              />
            </SingleCollapse>
          ),
        )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  order: 3;
`;
