import {
  type DocumentNode,
  type LazyQueryResultTuple,
  type OperationVariables,
  type QueryHookOptions,
  type TypedDocumentNode,
  useLazyQuery as useApolloLazyQuery,
} from '@apollo/client';
import { useStore } from 'react-redux';

import {
  getDataWithoutTypename,
  getVariablesWithContext,
} from '@graphql/utils';
import { getContext } from '@store/helpers';

export type UseLazyQueryCustomOptions = { includeTypename?: boolean };

const useLazyQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & UseLazyQueryCustomOptions,
): LazyQueryResultTuple<TData, TVariables> => {
  const store = useStore();
  const state = store.getState();
  const context = getContext(state);

  const { includeTypename, ...queryOptions } = options || {};
  const apolloOptions = getVariablesWithContext(queryOptions, context);

  const [queryFn, res] = useApolloLazyQuery<TData, TVariables>(
    query,
    apolloOptions,
  );

  const data = includeTypename
    ? res.data
    : getDataWithoutTypename<TData>(res.data);

  return [
    queryFn,
    {
      ...res,
      data,
    },
  ];
};

export default useLazyQuery;
