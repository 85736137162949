import type { ReactNode } from 'react';
import styled from 'styled-components';

type TagLabelProps = {
  children: ReactNode;
};

const TagLabel = ({ children, ...rest }: TagLabelProps) => (
  <StyledTag {...rest}>{children}</StyledTag>
);

const StyledTag = styled.span`
  border: 1px solid #f0f0f0;
  padding: 1px 6px;
  background-color: ${({ theme }) => theme.colors.lightestGray};
  color: #595959;
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  margin-left: 5px;
`;

export default TagLabel;
