import TextPlaceholder from '../components/TextPlaceholder';

const doubleBracesRegexp = /{{ ?(#[A-z]+ )?[A-z]+.[A-z]*}}/g;
const tripleBracesRegexp = /{{{ ?(#[A-z]+ )?[A-z]+.[A-z]*}}}/g;

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  // eslint-disable-next-line fp/no-let
  let matchArr, start;
  // eslint-disable-next-line fp/no-loops
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const doubleBracketsPlaceholderDecoratorStrategy = (contentBlock, callback) => {
  findWithRegex(doubleBracesRegexp, contentBlock, callback);
};
const tripleBracketsPlaceholderDecoratorStrategy = (contentBlock, callback) => {
  findWithRegex(tripleBracesRegexp, contentBlock, callback);
};

export const getPlaceholderDecoratorPlugin = () => ({
  decorators: [
    {
      strategy: tripleBracketsPlaceholderDecoratorStrategy,
      component: (props) => (
        <TextPlaceholder {...props}>{props.children}</TextPlaceholder>
      ),
    },
    {
      strategy: doubleBracketsPlaceholderDecoratorStrategy,
      component: (props) => (
        <TextPlaceholder {...props}>{props.children}</TextPlaceholder>
      ),
    },
  ],
});
