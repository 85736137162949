import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_SENDER_FRAGMENT } from '../fragments';

export const UPDATE_EMAIL_SENDER = `${namespace}/UPDATE_EMAIL_SENDER`;

const UPDATE_EMAIL_SENDER_MUTATION = gql`
  mutation updateEmailSender($payload: UpdateEmailSenderInput!) {
    updateEmailSender(data: $payload) {
      ...EmailSender
    }
  }
  ${EMAIL_SENDER_FRAGMENT}
`;

export const updateEmailSender = (payload) => ({
  type: UPDATE_EMAIL_SENDER,
  payload: {
    key: 'updateEmailSender',
    graphql: {
      mutation: UPDATE_EMAIL_SENDER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
