import { ListCell } from '@common/components/Table';
import type { Document } from '@graphql/types/graphql';

type DocumentProjectsProps = {
  projects: Document['projects'];
  appliesToAllProjects: Document['appliesToAllProjects'];
};

export const DocumentProjects = ({
  projects,
  appliesToAllProjects,
}: DocumentProjectsProps) => {
  const projectsOrFallback = projects || [];

  if (appliesToAllProjects) {
    return <p>Applies to all projects</p>;
  }

  if (!appliesToAllProjects && projectsOrFallback.length === 0) {
    return <p>No associated projects</p>;
  }

  return (
    <ListCell>
      {projectsOrFallback.map((project) => (
        <p key={project._id}>{project.name}</p>
      ))}
    </ListCell>
  );
};
