import type { ReactNode } from 'react';
import { useIsContextsAddOnEnabled } from '../hooks';

type ContextsAddOnFeatureProps = {
  children: ReactNode;
};

export const ContextsAddOnFeature = ({
  children,
}: ContextsAddOnFeatureProps) => {
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();

  if (!isContextsAddOnEnabled) return null;

  return <>{children}</>;
};
