import { Layout } from 'antd';
import styled from 'styled-components';

type SideBarProps = {
  children: React.ReactNode;
  collapsed: boolean;
  onCollapse: (param: any) => void;
};

export const SideBar = ({ children, collapsed, onCollapse }: SideBarProps) => {
  return (
    <StyledSider
      collapsed={collapsed}
      onCollapse={onCollapse}
      collapsible
      collapsedWidth={50}
      trigger={null}
      width={385}
    >
      {children}
    </StyledSider>
  );
};
const StyledSider = styled(Layout.Sider)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  border-left: 1px solid ${({ theme }) => theme.colors.gray};
`;
