import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const MAKE_ACTIVE_PRIMARY_RECORDS_MUTATION = graphql(`
  mutation MakeActivePrimaryRecords($data: ActivatePrimaryRecordsInput!) {
    activatePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useMakeActivePrimaryRecordsMutation = () => {
  const [
    makeActivePrimaryRecordsMutation,
    { loading: isMakingActivePrimaryRecords },
  ] = useMutation(MAKE_ACTIVE_PRIMARY_RECORDS_MUTATION);

  const makeActivePrimaryRecords = (filters?: ServerFilterInput) => {
    return makeActivePrimaryRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    makeActivePrimaryRecords,
    isMakingActivePrimaryRecords,
  };
};
