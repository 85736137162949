import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const ADD_DOCUMENT_FLAG = `${namespace}/ADD_DOCUMENT_FLAG`;

// Add document flag
const ADD_DOCUMENT_FLAG_MUTATION = gql`
  mutation addDocumentFlag($payload: AddDocumentFlagInput!) {
    addDocumentFlag(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const addDocumentFlag = (payload) => ({
  type: ADD_DOCUMENT_FLAG,
  payload: {
    key: 'addDocumentFlag',
    graphql: {
      mutation: ADD_DOCUMENT_FLAG_MUTATION,
      variables: { payload },
    },
  },
});
