import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useArchivePrimaryRecordMutation } from '@modules/primary-records/hooks/useArchivePrimaryRecordMutation';
import { Typography, message } from 'antd';
import { usePrimaryRecordQuery } from './hooks/usePrimaryRecordQuery';

type ArchivePrimaryRecordModalProps = {
  open: boolean;
  primaryRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const ArchivePrimaryRecordModal = ({
  open,
  primaryRecordId,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: ArchivePrimaryRecordModalProps) => {
  const { primaryRecord, loading } = usePrimaryRecordQuery({
    id: primaryRecordId,
    skip: !open || !primaryRecordId,
  });

  const { archivePrimaryRecord, isArchivingPrimaryRecord } =
    useArchivePrimaryRecordMutation();

  const handleArchive = async () => {
    // This should never happen, its an additianl safety net to avoid mistakenly calling the mutation without an ID, which will end up archiving all the records
    if (!primaryRecordId) {
      throw new Error('Primary record ID is required');
    }

    const res = await archivePrimaryRecord(primaryRecordId);

    const status = res.data?.archivePrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${primaryRecord?.name} archived successfully.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${primaryRecord?.name} has been scheduled for archiving.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The archiving of ${primaryRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      loading={loading}
      data-cy="archive-primary-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isArchivingPrimaryRecord,
      }}
      okText="Archive"
      title={`Are you sure you want to archive ${primaryRecord?.name}?`}
      onCancel={onCancel}
      onOk={handleArchive}
    >
      <Typography.Paragraph>
        Please confirm that you want to archive {primaryRecord?.name}
      </Typography.Paragraph>
      <ul>
        <li>It will not show up in your reports and dashboard</li>
        <li>No automatic requests for expired documents will be sent</li>
        <li>
          All associated documents will be archived (
          {primaryRecord?.stats.documents.totalCount})
        </li>
      </ul>
    </ModalConfirm>
  );
};
