export enum ActionType {
  override = 'override',
  waive = 'waive',
}

export enum ItemType {
  requirement = 'requirement',
  subject = 'subject',
}

export const ACTION_NOUNS_MAP = {
  [ActionType.override]: 'override',
  [ActionType.waive]: 'waiver',
};
