import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PROJECT_FRAGMENT } from '../fragments';

export const UPDATE_PROJECTS_ACTIVE_STATUS = `${namespace}/UPDATE_PROJECTS_ACTIVE_STATUS`;

const UPDATE_PROJECTS_ACTIVE_STATUS_MUTATION = gql`
  mutation updateProjectsActiveStatus(
    $payload: UpdateProjectsActiveStatusInput!
  ) {
    updateProjectsActiveStatus(data: $payload) {
      ...Project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const updateProjectsActiveStatus = (payload) => ({
  type: UPDATE_PROJECTS_ACTIVE_STATUS,
  payload: {
    key: 'updateProjectsActiveStatus',
    graphql: {
      mutation: UPDATE_PROJECTS_ACTIVE_STATUS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
