import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import styled from 'styled-components';

import DocumentReviewStatusTag from '@modules/document/components/DocumentReviewStatusTag';

type DocumentRecapProps = {
  documentId: string;
  friendlyName: string;
  isReviewed: boolean;
  notes?: string | null;
  types?: { name: string }[] | null;
  onNameClick: (documentId: string) => void;
};

export const DocumentRecap = ({
  documentId,
  friendlyName,
  isReviewed,
  notes,
  types,
  onNameClick,
}: DocumentRecapProps) => {
  const [type, ...restTypes] = types?.map(({ name }) => name) || [];

  return (
    <StyledWrapper>
      <div className="recapRow">
        <span className="recapTitle" onClick={() => onNameClick(documentId)}>
          {friendlyName}
        </span>
        {Boolean(notes?.length) && (
          <Popover title="Notes" trigger="click" content={<span>{notes}</span>}>
            <InfoCircleOutlined className="recapNoteIcon" />
          </Popover>
        )}
      </div>
      <div className="recapRow">
        <span className="recapSubtitle">{type || <i>No document type</i>}</span>

        {Boolean(restTypes?.length) && (
          <Tooltip title={restTypes.join(', ')}>
            <StyledBadge>{`+${restTypes.length}`}</StyledBadge>
          </Tooltip>
        )}
        <DocumentReviewStatusTag isReviewed={isReviewed} />
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 0;

  .recapRow {
    line-height: 24px;
  }

  .recapTitle {
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .recapNoteIcon {
    margin-left: 8px;
  }

  .recapSubtitle {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-right: 8px;

    i {
      font-style: normal;
      color: #aaa;
    }
  }
`;

const StyledBadge = styled.span`
  margin-right: 8px;
  padding: 3px 7px 4px;
  background-color: #e8edf3;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.grayText};
`;
