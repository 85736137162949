import { namespace } from '../constants';

export const SET_FEATURE_FLAGS = `${namespace}/SET_FEATURE_FLAGS`;

type UnleashToggles = { enabled: boolean; name: string }[];

export const setFeatureFlags = (payload: UnleashToggles) => ({
  type: SET_FEATURE_FLAGS,
  payload,
});

export type SetFeatureFlagsAction = ReturnType<typeof setFeatureFlags>;
