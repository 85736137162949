import type { Organization } from '@graphql/types/graphql';

import { namespace } from '../constants';

export const UPDATE_ORGANIZATIONS = `${namespace}/UPDATE_ORGANIZATIONS`;

export const updateOrganizations = (organization: Organization) => ({
  type: UPDATE_ORGANIZATIONS,
  payload: organization,
});
