import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const DELETE_DOCUMENTS = `${namespace}/DELETE_DOCUMENTS`;

const DELETE_DOCUMENTS_MUTATION = gql`
  mutation deleteDocuments($payload: DeleteDocumentsInput!) {
    deleteDocuments(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const deleteDocuments = (payload) => ({
  type: DELETE_DOCUMENTS,
  payload: {
    key: 'deleteDocuments',
    graphql: {
      mutation: DELETE_DOCUMENTS_MUTATION,
      variables: { payload },
    },
  },
});
