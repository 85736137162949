import { Modal, Select } from 'antd';
import { useEffect, useReducer } from 'react';

import { getGraphqlResponse } from '@store/helpers';
import { useAppDispatch } from '@store/hooks';

import { FullWidthSelect } from '@trustlayer/ui';
import { type TypeformForm, fetchTypeformForms } from '../actions';

enum SelectSurveyModalActionEnum {
  INIT_SELECT_SURVEY_MODAL_SUCCESS = 'INIT_SELECT_SURVEY_MODAL_SUCCESS',
  SELECT_FORM = 'SELECT_FORM',
}

type InitSelectSurveyModalSuccess = {
  type: SelectSurveyModalActionEnum.INIT_SELECT_SURVEY_MODAL_SUCCESS;
  payload: TypeformForm[];
};

type SelectForm = {
  type: SelectSurveyModalActionEnum.SELECT_FORM;
  payload: TypeformForm | undefined;
};

type SelectSurveyModalAction = InitSelectSurveyModalSuccess | SelectForm;

const { Option } = Select;

const initialState: {
  loading: boolean;
  typeformForms: TypeformForm[];
  selectedForm: TypeformForm | undefined;
} = {
  loading: true,
  typeformForms: [],
  selectedForm: undefined,
};

const selectSurveyModalReducer = (
  state = initialState,
  action: SelectSurveyModalAction,
) => {
  switch (action.type) {
    case SelectSurveyModalActionEnum.INIT_SELECT_SURVEY_MODAL_SUCCESS:
      return {
        ...state,
        typeformForms: action.payload,
        loading: false,
      };
    case SelectSurveyModalActionEnum.SELECT_FORM:
      return {
        ...state,
        selectedForm: action.payload,
      };
    default:
      return state;
  }
};

const SelectSurveyModal = ({
  visible,
  onSelectSurvey,
  disabledSurveyIds = [],
  onClose,
}: {
  visible: boolean;
  onSelectSurvey: (surveyId: string, title: string) => void;
  disabledSurveyIds?: string[];
  onClose: () => void;
}) => {
  const [{ typeformForms, selectedForm }, internalDispatch] = useReducer(
    selectSurveyModalReducer,
    initialState,
  );
  const dispatch = useAppDispatch();
  const selectableTypeformForms = typeformForms.filter(
    (form) => !disabledSurveyIds.includes(form.id),
  );

  const onOk = () => {
    onSelectSurvey(selectedForm!.id, selectedForm!.title);
    onClose();
  };

  useEffect(() => {
    const initSelectSurveyModal = async () => {
      const res = await dispatch(fetchTypeformForms());
      internalDispatch({
        type: SelectSurveyModalActionEnum.INIT_SELECT_SURVEY_MODAL_SUCCESS,
        payload: getGraphqlResponse(res, []),
      });
    };

    visible && initSelectSurveyModal();
  }, [visible, dispatch]);

  return (
    <Modal
      open={visible}
      title="Add Survey"
      destroyOnClose
      okButtonProps={{
        //@ts-expect-error
        'data-cy': 'addSurveyFormButton',
        disabled: !selectedForm,
      }}
      okText="Add Survey"
      zIndex={1005}
      onOk={onOk}
      onCancel={onClose}
    >
      <p>Select a survey you want to add to this compliance profile</p>
      <FullWidthSelect
        placeholder="Select a survey..."
        data-cy="selectSurvey"
        onChange={(value) =>
          internalDispatch({
            type: SelectSurveyModalActionEnum.SELECT_FORM,
            payload: selectableTypeformForms.find((form) => form.id === value),
          })
        }
      >
        {selectableTypeformForms.map(({ id, title }) => (
          <Option key={id} value={id}>
            {title}
          </Option>
        ))}
      </FullWidthSelect>
    </Modal>
  );
};

export default SelectSurveyModal;
