import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_LAYOUT_FRAGMENT } from '../fragments';

export const FETCH_EMAIL_LAYOUTS = `${namespace}/FETCH_EMAIL_LAYOUTS`;

const FETCH_EMAIL_LAYOUTS_QUERY = gql`
  query listEmailLayouts {
    listEmailLayouts {
      ...EmailLayout
    }
  }
  ${EMAIL_LAYOUT_FRAGMENT}
`;

export const fetchEmailLayouts = () => ({
  type: FETCH_EMAIL_LAYOUTS,
  payload: {
    key: 'listEmailLayouts',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_EMAIL_LAYOUTS_QUERY,
    },
  },
});
