import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const PRIMARY_RECORD_QUERY = graphql(`
  query PrimaryRecord_MakeActivePrimaryRecordModal(
    $input: PrimaryRecordInput!
  ) {
    primaryRecord(input: $input) {
      _id
      name
    }
  }
`);

export function usePrimaryRecordQuery({
  id,
  skip,
}: { id: string; skip: boolean }) {
  const res = useQuery(PRIMARY_RECORD_QUERY, {
    variables: {
      input: {
        id,
      },
    },
    skip,
  });

  const { data, ...rest } = res;

  return {
    primaryRecord: data?.primaryRecord,
    ...rest,
  };
}
