import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import Spinner from '@common/components/Spinner';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import { useSubjectsQuery } from './hooks';

type SubjectsSelectProps = {
  placeholder?: string;
  value?: string[];
  onChange: (subjects: string[]) => void;
};

export const SubjectsSelect = ({
  onChange,
  value,
  placeholder = 'Select subjects...',
}: SubjectsSelectProps) => {
  const {
    searchedValue,
    setSearchedValue,
    fetchMoreSubjects,
    fetchSubjects,
    isSubjectsLoadingMore,
    isInitialValuesLoading,
    subjects,
    totalSubjects,
    options,
  } = useSubjectsQuery({ value });

  if (isInitialValuesLoading) {
    return <StyledInputSkeleton />;
  }

  return (
    <InfiniteScrollSelect
      showSearch
      mode="multiple"
      data-cy="subjectsSelect"
      placeholder={placeholder}
      value={value}
      loader={<Spinner />}
      isDataLoading={isSubjectsLoadingMore}
      searchValue={searchedValue}
      onSearch={setSearchedValue}
      hasMore={Boolean(subjects?.length && subjects?.length < totalSubjects)}
      onChange={onChange}
      onDropdownVisibleChange={(open) => {
        if (open && !subjects?.length) {
          fetchSubjects();
        }
      }}
      loadMore={() => {
        fetchMoreSubjects();
      }}
      options={options}
    />
  );
};

const StyledInputSkeleton = styled(Skeleton.Input)`
  vertical-align: middle !important;
  height: 35px !important;
  width: 100% !important;
`;
