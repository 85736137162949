import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_TEMPLATE_FRAGMENT } from '../fragments';

export const FETCH_EMAIL_TEMPLATE = `${namespace}/FETCH_EMAIL_TEMPLATE`;

const FETCH_EMAIL_TEMPLATE_QUERY = gql`
  query getEmailTemplate($_id: ID!) {
    getEmailTemplate(_id: $_id) {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const fetchEmailTemplate = ({
  payload,
  query = FETCH_EMAIL_TEMPLATE_QUERY,
} = {}) => ({
  type: FETCH_EMAIL_TEMPLATE,
  payload: {
    key: 'getEmailTemplate',
    graphql: {
      fetchPolicy: 'no-cache',
      query,
      variables: {
        _id: payload,
      },
    },
  },
});
