import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { useCustomLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import { mapTableFiltersToQueryFilters } from '../RequestsTable.utils';

export const REQUESTS_QUERY = graphql(`
  query PrimaryRecordRequests(
    $primaryRecordInput: PrimaryRecordInput!
    $input: RequestRecordsInput
  ) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      requestRecords(input: $input) {
        nodes {
          _id
          complianceStats {
            compliantSubjects
            totalSubjects
          }
          contextRecord {
            _id
            name
          }
          name
        }
        totalCount
      }
    }
  }
`);

export const useRequests = ({
  primaryRecordId,
}: {
  primaryRecordId: string;
}) => {
  const getRequestsQuery = useCustomLazyQuery(REQUESTS_QUERY);

  const getRequestsList: GetRowData = useCallback(
    async ({ startRow, endRow, filters }) => {
      const queryFilters = mapTableFiltersToQueryFilters(filters);

      const { data } = await getRequestsQuery({
        variables: {
          primaryRecordInput: {
            id: primaryRecordId,
          },
          input: {
            offset: startRow,
            first: endRow - startRow,
            ...(Boolean(queryFilters.length) && {
              filter: { and: queryFilters },
            }),
          },
        },
      });

      return {
        rowData: data?.primaryRecord?.requestRecords.nodes || [],
        rowCount: data?.primaryRecord?.requestRecords.totalCount || 0,
      };
    },
    [primaryRecordId, getRequestsQuery],
  );

  return {
    getRequestsList,
  };
};
