import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware({
  onError(err) {
    console.error(err);
    Sentry.captureException(err);
  },
});

export default sagaMiddleware;
