import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EVENT_LOG_FRAGMENT } from '../fragments';

export const UPDATE_COMMENT_EVENT = `${namespace}/UPDATE_COMMENT_EVENT`;

// Update comment.
const UPDATE_COMMENT_EVENT_QUERY = gql`
  mutation updateCommentEvent($payload: CommentEventUpdateInput!) {
    updateCommentEvent(data: $payload) {
      ...EventLog
    }
  }
  ${EVENT_LOG_FRAGMENT}
`;

export const updateCommentEvent = (payload) => ({
  type: UPDATE_COMMENT_EVENT,
  payload: {
    key: 'updateCommentEvent',
    graphql: {
      fetchPolicy: 'no-cache',
      query: UPDATE_COMMENT_EVENT_QUERY,
      variables: { payload },
    },
  },
});
