import { FolderFilled, FolderOutlined, FolderTwoTone } from '@ant-design/icons';
import { Badge, List, Popover } from 'antd';
import * as R from 'ramda';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { BadgeColors, BadgeSize } from '../model/badges';

const ProjectAssociationBadgePopover = ({
  documentData,
  currentProjectId,
  sizeIcon,
}) => {
  const themeContext = useContext(ThemeContext);
  const isAppliesToAll = R.prop('appliesToAllProjects', documentData);
  const associatedProjects = R.propOr([], 'projects', documentData);
  const isAssociatedWithCurrentProject = R.any(
    (project) => project && project._id === currentProjectId,
    associatedProjects,
  );

  const renderIcon = () => {
    if (!isAppliesToAll && associatedProjects.length === 0) {
      return (
        <FolderOutlined
          data-cy="documentsRelationshipsIcon"
          style={{
            color: themeContext.colors[BadgeColors.grayIcon],
            fontSize: BadgeSize[sizeIcon],
          }}
        />
      );
    }

    if (isAppliesToAll) {
      return (
        <FolderFilled
          data-cy="documentsRelationshipsIcon"
          style={{
            color: 'rgb(184, 207, 232)',
            fontSize: BadgeSize[sizeIcon],
          }}
        />
      );
    }

    if (!currentProjectId) {
      return (
        <FolderTwoTone
          data-cy="documentsRelationshipsIcon"
          style={{
            fontSize: BadgeSize[sizeIcon],
          }}
        />
      );
    }

    return (
      <Badge
        dot
        color={
          isAssociatedWithCurrentProject
            ? themeContext.colors[BadgeColors.blueIcon]
            : 'red'
        }
      >
        <FolderTwoTone
          data-cy="documentsRelationshipsIcon"
          style={{
            fontSize: BadgeSize[sizeIcon],
          }}
        />
      </Badge>
    );
  };

  const renderTooltipContent = () =>
    isAppliesToAll ? (
      <span data-cy="documentsProjectRelationshipsContent">
        Applies to all projects
      </span>
    ) : (
      <ProjectAssociationBadgePopover.ProjectsList
        data-cy="documentsProjectRelationshipsContent"
        pagination={{ size: 'small', pageSize: 5, hideOnSinglePage: true }}
        split={false}
        locale={{
          emptyText: (
            <span data-cy="documentsProjectRelationshipsContent">
              No associated projects
            </span>
          ),
        }}
        dataSource={associatedProjects}
        renderItem={(item) => (
          <ProjectAssociationBadgePopover.ListItem>
            {item.name}
          </ProjectAssociationBadgePopover.ListItem>
        )}
      />
    );

  return (
    <Popover
      overlayStyle={{
        maxWidth: '300px',
      }}
      title={
        Boolean(!isAppliesToAll && associatedProjects.length) &&
        'Associated projects'
      }
      content={renderTooltipContent()}
    >
      {renderIcon()}
    </Popover>
  );
};

ProjectAssociationBadgePopover.defaultProps = {
  sizeIcon: 'Medium',
};

ProjectAssociationBadgePopover.ProjectsList = styled(List)`
  padding: 0;

  .ant-pagination {
    display: flex;
    justify-content: center;
  }

  .ant-list-item {
    position: relative;
    padding: 3px 7px 3px 14px;
  }

  .ant-list-item::before {
    content: '●';
    position: absolute;
    left: 0;
    top: 7px;
    font-size: 7px;
  }

  .ant-list-pagination {
    margin-top: 5px;
  }

  .ant-list-empty-text {
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
  }
`;

ProjectAssociationBadgePopover.ListItem = styled(List.Item)`
  font-size: 12px;
  padding: 6px;
`;

export default ProjectAssociationBadgePopover;
