import { FileFilled } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import {
  PartyConversationType,
  type PartyMessageStatus,
} from '@modules/party-conversation';
import MessageStatusIcon from '@modules/party-conversation/components/MessageStatusIcon';

type ConversationType =
  (typeof PartyConversationType)[keyof typeof PartyConversationType];

type MessageStatus =
  (typeof PartyMessageStatus)[keyof typeof PartyMessageStatus];

type ConversationPreviewProps = {
  subject: string;
  contacts: string[];
  type: ConversationType;
  isActive?: boolean;
  isMessageRead?: boolean;
  messageStatus?: MessageStatus;
  messageDate?: string;
  messageText?: string;
  messageAuthor?: string;
};

const ConversationTypeIconMap = {
  [PartyConversationType.DocumentRequest]: <FileFilled className="icon" />,
  [PartyConversationType.Conversation]: null,
};

export const ConversationPreview = ({
  subject,
  type,
  contacts,
  isMessageRead,
  messageStatus,
  messageDate,
  messageText,
  messageAuthor,
  isActive = false,
}: ConversationPreviewProps) => {
  const typeIcon = type && ConversationTypeIconMap[type];
  const contactsLabel = contacts.join(', ');

  return (
    <StyledConversationPreview $isActive={isActive}>
      <StyledIconSection>
        {typeIcon && <div className="iconWrapper">{typeIcon}</div>}
      </StyledIconSection>

      <StyledContentSection>
        <section className="header">
          <div className="alignItems subjectWrapper">
            <span className="subject">{subject}</span>
            {!isMessageRead && <Badge status="processing" />}
          </div>
          <div className="alignItems">
            <MessageStatusIcon type={messageStatus} />
            <span className="date">
              {messageDate && moment(messageDate).format('ll')}
            </span>
          </div>
        </section>
        <section>
          <Tooltip title={contactsLabel} placement="bottomLeft">
            <p className="contacts">{contactsLabel}</p>
          </Tooltip>
          <div className="message">{`${messageAuthor}: ${messageText}`}</div>
        </section>
      </StyledContentSection>
    </StyledConversationPreview>
  );
};

const StyledConversationPreview = styled.div<{ $isActive: boolean }>`
  --grid-gap: 8px;
  --icon-column-width: 29px;
  --content-column-width: calc(
    100% - var(--icon-column-width) - var(--grid-gap)
  );

  display: grid;
  grid-template-columns: var(--icon-column-width) var(--content-column-width);
  gap: var(--grid-gap);
  padding: var(--grid-gap);
  position: relative;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.lightBlue : theme.colors.white};

  &&::after {
    position: absolute;
    top: 0;
    right: 0;
    content: ' ';
    height: 100%;
    border-right: ${(props) =>
      props.$isActive ? `3px solid ${props.theme.colors.blue}` : 'none'};
  }

  &:hover {
    background-color: #f4f8fd;
  }
`;

const StyledIconSection = styled.section`
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
  }

  .icon {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const StyledContentSection = styled.section`
  .alignItems {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subjectWrapper {
    width: 60%;
  }

  .subject {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    color: ${({ theme }) => theme.colors.mineShaft};
  }

  .date {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightTextGray};
    white-space: nowrap;
  }

  .contacts,
  .message {
    font-size: 12px;
    line-height: 14px;
  }

  .contacts {
    margin: 5px 0;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.subtitleText};
  }

  .message {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: italic;
    color: ${({ theme }) => theme.colors.lightTextGray};
  }
`;
