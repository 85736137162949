import { Select } from 'antd';
import styled from 'styled-components';

type Option = {
  _id: string;
  value: string;
};

type CellEditorMultiSelectProps = {
  value: Option[] | undefined;
  onValueChange: (value: Option[] | undefined) => void;
  options: Option[];
};

export const CellEditorMultiSelect = ({
  value,
  onValueChange,
  options,
}: CellEditorMultiSelectProps) => {
  const handleChange = (_: unknown, selectedOptions: Option | Option[]) => {
    const selectedOptionsArray = Array.isArray(selectedOptions)
      ? selectedOptions
      : [selectedOptions];

    onValueChange(selectedOptionsArray);
  };

  return (
    <StyledEditorSelect
      mode="multiple"
      placeholder="Select a value"
      value={value || []}
      onChange={handleChange}
      options={options}
    >
      {options.map((option) => (
        <Select.Option key={option._id} value={option._id}>
          {option.value}
        </Select.Option>
      ))}
    </StyledEditorSelect>
  );
};

const StyledEditorSelect = styled(Select)`
  width: 100%;

  && .ant-select-selector {
    border: none;
    box-shadow: none;
  }
`;
