import { namespace } from '../constants';
import type { NotificationType } from '../types';

export const SET_SELECTED_FILTER = `${namespace}/SET_SELECTED_FILTER` as const;

export const selectNotificationDrawerFilter = (
  selectedFilter: NotificationType | null,
) => ({
  type: SET_SELECTED_FILTER,
  payload: selectedFilter,
});

export type SelectNotificationDrawerFilterAction = ReturnType<
  typeof selectNotificationDrawerFilter
>;
