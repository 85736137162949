import { gql } from '@apollo/client';

export const EMAIL_SENDER_FRAGMENT = gql`
  fragment EmailSender on EmailSender {
    _id
    name
    email
    organization
    selected
    isSystemSender
    deletedAt
    deletedBy
  }
`;
