import { useSelector } from 'react-redux';

import { getMyId } from '@modules/user/selectors';

import { getOrganizationMemberByUserId } from '../selectors';

const useMemberRole = () => {
  const userId = useSelector(getMyId);
  const member = useSelector((state) =>
    getOrganizationMemberByUserId(state, userId),
  );

  return member?.role;
};

export default useMemberRole;
