import { graphql } from '@graphql/types';

export const ATTRIBUTES_QUERY = graphql(`
  query PrimaryRecord_Attributes($primaryRecordInput: PrimaryRecordInput!) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      tags {
        nodes {
          _id
          name
          color
          createdAt
          expiresAt
        }
        totalCount
      }
      attributes {
        nodes {
          ... on SystemAttribute {
            _id
            name
            value
          }
          ... on CustomAttribute {
            _id
            name
            value
          }
        }
        totalCount
      }
    }
  }
`);
