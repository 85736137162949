import { WarningFilled } from '@ant-design/icons';
import moment from 'moment';
import pluralize from 'pluralize';
import styled from 'styled-components';

import PopoverWithList from '@common/components/PopoverWithList';

import { useMessagesIssues } from './hooks';

type MessagesIssuesQuickViewProps = {
  conversationId: string;
  onIssueClick: (id: string) => void;
};

export const MessagesIssuesQuickView = ({
  conversationId,
  onIssueClick,
}: MessagesIssuesQuickViewProps) => {
  const { loadMoreMessageIssues, issues, issuesCount } = useMessagesIssues({
    conversationId,
  });

  const formatDate = (createdAt: string) =>
    createdAt ? moment(createdAt).format('lll') : '';

  const getIssueComponent = (issue: {
    message: { _id: string; createdAt: string };
    email: string;
  }) => (
    <StyledIssueWrap>
      <StyledWarningFilled />
      <StyledMessageWrap onClick={() => onIssueClick(issue.message._id)}>
        <h4>{`Message not delivered to ${issue.email}`}</h4>
        <p>{formatDate(issue.message.createdAt)}</p>
      </StyledMessageWrap>
    </StyledIssueWrap>
  );

  return (
    <>
      {issuesCount > 0 && (
        <PopoverWithList
          totalItemsCount={issuesCount}
          items={issues}
          loadItems={loadMoreMessageIssues}
          placement="bottomRight"
          getItemComponent={getIssueComponent}
        >
          <StyledWarnIssuesContainer>
            <StyledWarningFilled />
            {`${issuesCount} ${pluralize('issue', issuesCount)}`}
          </StyledWarnIssuesContainer>
        </PopoverWithList>
      )}
    </>
  );
};

const StyledMessageWrap = styled.div`
  cursor: pointer;

  h4,
  p {
    margin-bottom: 0;
  }
`;

const StyledWarnIssuesContainer = styled.section`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const StyledIssueWrap = styled.section`
  display: flex;
  align-items: baseline;
`;

const StyledWarningFilled = styled(WarningFilled)`
  color: ${({ theme }) => theme.colors.yellow};
  margin-right: 5px;
`;
