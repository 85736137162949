import pluralize from 'pluralize';

import { AUTOMATION_CODES } from '@modules/automations/constants';

import { PARTY_AUTOMATION_STATUS } from '../constants';
import type { QueryPartyAutomation } from '../queries';

type AutomatedDocumentReminder = {
  scheduledAt: string;
  executedAt: string;
  partyConversationId: string;
  messageId: string;
};

export type PartyAutomation = {
  code: string;
  title: string;
  subtitle: string;
  isEnabled: boolean;
  isEnablable: boolean;
  scheduledActions: QueryPartyAutomation['scheduledActions'];
  branches: QueryPartyAutomation['branches'];
};

const hasAutomationEnabled = (automationList: string[], automation: string) =>
  automationList?.some((code) => code === automation);

export const hasExpiringDocumentRemindersAutomationEnabled = (
  automationList: string[],
) =>
  hasAutomationEnabled(
    automationList,
    AUTOMATION_CODES.expiringDocumentReminders,
  );

export const getNextAutomatedDocumentReminder = (
  reminders: AutomatedDocumentReminder[],
) => {
  const nextReminder =
    reminders?.filter((reminder) => !reminder.executedAt)[0] || {};

  return nextReminder;
};

type GetPartyAutomationsDataArgs = {
  partyAutomations?: QueryPartyAutomation[];
};

type GetSubtitleArgs = {
  code: string;
  scheduledActions?: QueryPartyAutomation['scheduledActions'];
  actionsCount: number;
};

const getSubtitle = ({
  code,
  scheduledActions,
  actionsCount,
}: GetSubtitleArgs) => {
  if (!scheduledActions) {
    return '';
  }

  if (code === AUTOMATION_CODES.documentReminders) {
    const executedActions = scheduledActions.filter(
      ({ executedAt }) => executedAt,
    );

    const suffix = `${pluralize('reminders', actionsCount)} sent`;

    return `${executedActions.length} of ${actionsCount} ${suffix}`;
  }

  return '';
};

export const getPartyAutomationsMap = ({
  partyAutomations,
}: GetPartyAutomationsDataArgs): Record<string, PartyAutomation> | undefined =>
  partyAutomations?.reduce((acc, automation) => {
    return {
      ...acc,
      [automation.code]: {
        code: automation.code,
        title: automation.name,
        subtitle: getSubtitle({
          code: automation.code,
          scheduledActions: automation.scheduledActions,
          actionsCount: automation.actionsCount,
        }),
        isEnabled: automation.status === PARTY_AUTOMATION_STATUS.enabled,
        isEnablable: automation.actionsCount > 0,
        scheduledActions: automation.scheduledActions,
        branches: automation.branches,
      },
    };
  }, {});
