import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_DETACH_DOCUMENTS_FROM_PROJECT = `${namespace}/BULK_DETACH_DOCUMENTS_FROM_PROJECT`;

const BULK_DETACH_DOCUMENTS_FROM_PROJECT_MUTATION = gql`
  mutation bulkDetachDocumentsFromProject(
    $payload: BulkDetachDocumentsFromProjectInput
  ) {
    bulkDetachDocumentsFromProject(data: $payload) {
      scheduled
    }
  }
`;

export const bulkDetachDocumentsFromProject = (payload) => ({
  type: BULK_DETACH_DOCUMENTS_FROM_PROJECT,
  payload: {
    key: 'bulkDetachDocumentsFromProject',
    graphql: {
      mutation: BULK_DETACH_DOCUMENTS_FROM_PROJECT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
