import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { REPORT_FRAGMENT } from '../fragments';

export const UPDATE_REPORT = `${namespace}/UPDATE_REPORT`;
const UPDATE_REPORT_MUTATION = gql`
  mutation updateReport($payload: UpdateReportInput!) {
    updateReport(data: $payload) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const updateReport = (payload) => ({
  type: UPDATE_REPORT,
  payload: {
    key: 'updateReport',
    graphql: {
      mutation: UPDATE_REPORT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
