export const INVALID_FORMAT_ERROR_MESSAGE =
  'The following file formats are not supported:';

export const dangerousFileExtensions = [
  '.ade',
  '.adp',
  '.app',
  '.asp',
  '.aspx',
  '.asx',
  '.bas',
  '.bat',
  '.cer',
  '.chm',
  '.cmd',
  '.cnt',
  '.com',
  '.cpl',
  '.crt',
  '.csh',
  '.der',
  '.diagcab',
  '.exe',
  '.fxp',
  '.gadget',
  '.grp',
  '.hlp',
  '.hpj',
  '.hta',
  '.htc',
  '.inf',
  '.ins',
  '.isp',
  '.its',
  '.jar',
  '.jnlp',
  '.js',
  '.jse',
  '.ksh',
  '.lnk',
  '.mad',
  '.maf',
  '.mag',
  '.mam',
  '.maq',
  '.mar',
  '.mas',
  '.mat',
  '.mau',
  '.mav',
  '.maw',
  '.mcf',
  '.mda',
  '.mdb',
  '.mde',
  '.mdt',
  '.mdw',
  '.mdz',
  '.msc',
  '.msh',
  '.msh1',
  '.msh2',
  '.mshxml',
  '.msh1xml',
  '.msh2xml',
  '.msi',
  '.msp',
  '.mst',
  '.msu',
  '.one',
  '.onepkg',
  '.ops',
  '.osd',
  '.pcd',
  '.pif',
  '.pl',
  '.plg',
  '.prf',
  '.prg',
  '.printerexport',
  '.ps1',
  '.ps1xml',
  '.ps2',
  '.ps2xml',
  '.psc1',
  '.psc2',
  '.psd1',
  '.psdm1',
  '.pst',
  '.py',
  '.pyc',
  '.pyo',
  '.pyw',
  '.pyz',
  '.pyzw',
  '.reg',
  '.scf',
  '.scr',
  '.sct',
  '.shb',
  '.shs',
  '.theme',
  '.tmp',
  '.url',
  '.vb',
  '.vbe',
  '.vbp',
  '.vbs',
  '.vhd',
  '.vhdx',
  '.vsmacros',
  '.vsw',
  '.webpnp',
  '.website',
  '.ws',
  '.wsc',
  '.wsf',
  '.wsh',
  '.xbap',
  '.xll',
  '.xnk',
  '.php',
  '.svg',
  '.jsp',
  '.html',
];

/**
  @description - use this file extensions for accepting only documents that can be signed or parsed
 */
export const documentAcceptedFileExtensions = [
  '.pdf',
  '.png',
  '.jpeg',
  '.jpg',
  '.gif',
];
