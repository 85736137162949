import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_DELETE_COMPLIANCE_PROFILE = `${namespace}/DELETE_COMPLIANCE_PROFILES`;

const BULK_DELETE_COMPLIANCE_PROFILE_MUTATION = gql`
  mutation bulkDeleteComplianceProfile(
    $payload: BulkDeleteComplianceProfileInput!
  ) {
    bulkDeleteComplianceProfile(data: $payload) {
      scheduled
    }
  }
`;

export const bulkDeleteComplianceProfiles = (complianceProfileIds) => ({
  type: BULK_DELETE_COMPLIANCE_PROFILE,
  payload: {
    key: 'bulkDeleteComplianceProfile',
    graphql: {
      mutation: BULK_DELETE_COMPLIANCE_PROFILE_MUTATION,
      variables: {
        payload: {
          complianceProfileIds,
        },
      },
    },
  },
});
