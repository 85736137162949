import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { COMPLIANCE_PROFILE_FRAGMENT } from '../fragments';

export const FETCH_COMPLIANCE_PROFILE = `${namespace}/FETCH_COMPLIANCE_PROFILE`;

const FETCH_COMPLIANCE_PROFILE_QUERY = gql`
  query getComplianceProfile($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      ...ComplianceProfileFrag
    }
  }
  ${COMPLIANCE_PROFILE_FRAGMENT}
`;

export const fetchComplianceProfile = (id) => ({
  type: FETCH_COMPLIANCE_PROFILE,
  payload: {
    key: 'getComplianceProfile',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_COMPLIANCE_PROFILE_QUERY,
      variables: { id },
    },
  },
});
