import type { RootState } from '@common/types';

import { STATE_KEY } from '../constants';
import type { NotificationType } from '../types';

export const getIsDrawerOpen = (state: RootState): boolean =>
  state[STATE_KEY].isDrawerOpen;

export const getSelectedFilter = (
  state: RootState,
): NotificationType | undefined => state[STATE_KEY].selectedFilter;
