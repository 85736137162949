import { DiffOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { htmlToText } from 'html-to-text';
import * as R from 'ramda';
import styled from 'styled-components';

import TagWithPopover from '@common/components/TagWithPopover';
import {
  getLabelCompletedBy,
  getOperatorDisplayValue,
} from '@common/utils/compliance-attributes-helpers';
import {
  AttributeType,
  Operator,
  financialStrengthValues,
  ratingValues,
} from '@modules/compliance-profile/constants';
import { parseCurrency } from '@trustlayer/common';

const { Paragraph } = Typography;

const MatchedRepeatedly = () => (
  <Tooltip title="Must be matched repeatedly">
    <DiffOutlined />
  </Tooltip>
);

const RuleDisplayValue = ({
  rule,
  isCustom,
  customFields = [],
  partyName,
  orgName,
}) => {
  const {
    attributeType,
    attributeLabel,
    matchingCriteria,
    operator,
    targetValue,
    masterDocumentAttributeId,
  } = rule;

  return (
    <Tooltip title={masterDocumentAttributeId} trigger={'contextMenu'}>
      {isCustom ? (
        <RuleDisplayValue.CustomRequirementLabel className="attribute-label">
          {matchingCriteria === 'matchRepeatedly' && <MatchedRepeatedly />}{' '}
          {htmlToText(attributeLabel)}{' '}
          {(operator === Operator.GREATER_OR_EQUAL ||
            operator === Operator.LESS_OR_EQUAL) && (
            <>
              <span className="operator">{operator}</span>{' '}
              <Highlight className="target-value">
                {parseCurrency(targetValue)}
              </Highlight>
            </>
          )}
        </RuleDisplayValue.CustomRequirementLabel>
      ) : (
        <span>
          {matchingCriteria === 'matchRepeatedly' && <MatchedRepeatedly />}{' '}
          <Highlight className="attribute-label">{attributeLabel}</Highlight>{' '}
          <span className="operator">{getOperatorDisplayValue(rule)}</span>{' '}
          {attributeType === AttributeType.Number &&
            (operator === Operator.GREATER_OR_EQUAL ||
              operator === Operator.LESS_OR_EQUAL) && (
              <Highlight className="target-value">
                {parseCurrency(targetValue)}
              </Highlight>
            )}
          {attributeType === AttributeType.AmBestRating && (
            <Highlight className="target-value">
              {ratingValues[targetValue]}
            </Highlight>
          )}
          {attributeType === AttributeType.AmBestFinancialStrength && (
            <Highlight className="target-value">
              {financialStrengthValues[targetValue]}
            </Highlight>
          )}
          {operator === Operator.COMPLETED_BY && (
            <Highlight className="target-value">
              {getLabelCompletedBy({ targetValue, partyName, orgName })}
            </Highlight>
          )}
        </span>
      )}
      {!R.isEmpty(customFields) && (
        <div>
          <CustomFieldTitle>Added by</CustomFieldTitle>
          {customFields.map(({ customField }) => (
            <TagWithPopover
              key={customField._id}
              color={customField.color}
              name={customField.name}
            />
          ))}
        </div>
      )}
    </Tooltip>
  );
};

const Highlight = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
`;

RuleDisplayValue.CustomRequirementLabel = styled(Paragraph)`
  font-weight: normal;
  color: ${(props) => props.theme.colors.grayishBlue};

  &.ant-typography {
    margin-bottom: 0 !important;
  }
`;

export const CustomFieldTitle = styled.span`
  font-size: 11px;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 7px;
`;

export default RuleDisplayValue;
