import { Empty } from 'antd';
import styled from 'styled-components';

interface EmptyStateProps {
  title?: string;
  desc?: string;
  loading?: boolean;
}

const EmptyState = ({ title, desc, loading }: EmptyStateProps) => {
  return (
    <StyledEmptyContainerWrapper data-cy="emptyState">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <div>
            {loading ? (
              <StyledEmptyContainerDesc>
                Loading, please wait...
              </StyledEmptyContainerDesc>
            ) : (
              <>
                {title && (
                  <StyledEmptyContainerTitle>{title}</StyledEmptyContainerTitle>
                )}
                {desc && (
                  <StyledEmptyContainerDesc>{desc}</StyledEmptyContainerDesc>
                )}
              </>
            )}
          </div>
        }
      ></Empty>
    </StyledEmptyContainerWrapper>
  );
};

const StyledEmptyContainerWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const StyledEmptyContainerTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #7b91a7;
`;

const StyledEmptyContainerDesc = styled.span`
  margin-bottom: 25px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayText};
`;

export default EmptyState;
