import type { NumberFilter } from '../../components/Table';
import type { AVAILABLE_NUMBER_FILTER_OPTION_TYPES } from '../../components/Table/constants';
import { FILTER_OPERATORS } from '../../constants/filters';

type AvailableOptionTypes =
  (typeof AVAILABLE_NUMBER_FILTER_OPTION_TYPES)[number];

/**
 * translation map from table option types to server option types, realted to number filter
 */
const OPTIONS_TYPES_MAP: Record<AvailableOptionTypes, FILTER_OPERATORS> = {
  contains: FILTER_OPERATORS.in,
  notContains: FILTER_OPERATORS.notIn,
  equals: FILTER_OPERATORS.equal,
  notEqual: FILTER_OPERATORS.notEqual,
  greaterThan: FILTER_OPERATORS.greaterThan,
  greaterThanOrEqual: FILTER_OPERATORS.greaterThanEqual,
  lessThan: FILTER_OPERATORS.lessThan,
  lessThanOrEqual: FILTER_OPERATORS.lessThanEqual,
  inRange: FILTER_OPERATORS.between,
};

const OPTIONS_TYPES_WITH_BASE_VALUE: NumberFilter['type'][] = [
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual',
  'equals',
  'notEqual',
];

export const getNumberFilter = (param: NumberFilter) => {
  if (!param.type) {
    return {
      operator: FILTER_OPERATORS.equal,
      value: param.filter,
    };
  }

  if (OPTIONS_TYPES_WITH_BASE_VALUE.includes(param.type)) {
    return {
      // @ts-expect-error - TS is considering types not present in OPTIONS_TYPES_WITH_BASE_VALUE
      operator: OPTIONS_TYPES_MAP[param.type] || FILTER_OPERATORS.in,
      value: param.filter,
    };
  }

  if (param.type === 'contains' || param.type === 'notContains') {
    return {
      operator: OPTIONS_TYPES_MAP[param.type],
      value: [param.filter],
    };
  }

  if (param.type === 'inRange') {
    return {
      operator: OPTIONS_TYPES_MAP[param.type],
      value: [param.filter, param.filterTo],
    };
  }
};
