/**
 * @note - Edit button with "Cancel" and "Save" actions are disabled for now
 * for the following reasons:
 *
 * - the "undoCellEditing" api from AgGrid on cancel click doesn't work as expected.
 * - the "stopEditing" param should discard changes and close the editor, but it doesn't work as expected.
 * - when the user click outside the editable cell, for example the "Cancel" button, the table stops editing and the actions button should be resetted, this could be done by redrawing the row in the onStopEditing callback,
 * but this behavior prevent the user to click the "Save" and "Cancel" buttons since clicking on them is clicking outside the editable cell and it will trigger the onStopEditing callback and redraw the row.
 */

import { Alert, Tag, message } from 'antd';
import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { PageTitle } from '@common/components/PageTitleWrapper';
import {
  type CellEditingStoppedEvent,
  Table,
  type TableRef,
} from '@common/components/Table';
import {
  BulkOperationStatus,
  type RecordAttribute,
} from '@graphql/types/graphql';
import {
  formatAttributeToUpdate,
  onAttributeValueChange,
} from './AttributesTable.utils';
import { AttributeValueRenderer } from './components/AttributeValueRenderer';
import { EditorSelector } from './components/EditorSelector';
import { ATTRIBUTES_TYPENAMES } from './constants';
import { useRecordAttributes } from './hooks';

type AttributesTableProps = {
  recordId: string;
};

export const AttributesTable = ({ recordId }: AttributesTableProps) => {
  const tableRef = useRef<TableRef>(null);

  const { getAttributes, updateAttributes, refetchAttributes } =
    useRecordAttributes({
      primaryRecordId: recordId,
    });

  // const [editingCellId, setEditingCellId] = useState<string | null>(null);

  // const handleOnEditClick = useCallback((rowIndex?: number) => {
  //   if (rowIndex === undefined) return;
  //   tableRef.current!.editCell({
  //     rowIndex,
  //     columnField: 'value',
  //   });
  // }, []);

  const handleOnStopEditing = useCallback(
    ({
      data: attribute,
      valueChanged,
    }: CellEditingStoppedEvent<RecordAttribute>) => {
      // @note - force row redraw to reset EditButton's internal state when editing is stopped with a focus out of the cell
      // params.api.redrawRows({
      //   rowNodes: [params.node],
      // });

      if (attribute && valueChanged) {
        updateAttributes({
          attributes: [formatAttributeToUpdate(attribute)],
          onCompleted: (data) => {
            const status = data.updateRecordAttributes.operation.status;
            if (status === BulkOperationStatus.Completed) {
              message.success(`Attribute ${attribute.name} has been updated.`);
              refetchAttributes();
            }

            if (status === BulkOperationStatus.Scheduled) {
              // this state never happens since we are updating a single attribute at a time
              message.success(
                `Attribute ${attribute.name} has been scheduled for update.`,
              );
            }

            if (status === BulkOperationStatus.Failed) {
              message.error(
                `The update of the attribute has failed. Please try again or contact support.`,
              );
            }
          },
        });
      }
    },
    [updateAttributes, refetchAttributes],
  );

  const columns = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        sortable: false,
        valueGetter: ({ data }: { data: any }) => ({
          isSystemAttribute:
            data.__typename === ATTRIBUTES_TYPENAMES.SystemAttribute,
          name: data.name,
        }),
        cellRenderer: ({
          value,
        }: {
          value: { isSystemAttribute: boolean; name: string };
        }) => {
          return (
            <>
              <span>{value.name}</span>
              {value.isSystemAttribute && <StyledTag>SYSTEM</StyledTag>}
            </>
          );
        },
      },
      {
        headerName: 'Value',
        flex: 1,
        field: 'value',
        editable: true,
        sortable: false,
        cellEditorSelector: EditorSelector,
        valueSetter: onAttributeValueChange,
        cellRenderer: ({ data }: any) => {
          return <AttributeValueRenderer attribute={data} />;
        },
      },
      // {
      //   field: 'actions',
      //   lockPosition: 'right' as const,
      //   pinned: 'right' as const,
      //   width: 180,
      //   maxWidth: 180,
      //   sortable: false,
      //   cellRenderer: ({ node }: any) => {
      //     return (
      //       <>
      //         {editingCellId === node.id ? (
      //           <Space direction="horizontal">
      //             <Button
      //               onClick={() => {
      //                 console.log('onCancel clicked');
      //                 tableRef.current!.stopEditing(true);
      //                 setEditingCellId(null);
      //               }}
      //             >
      //               Cancel
      //             </Button>
      //             <Button
      //               type="primary"
      //               onClick={() => {
      //                 console.log('onSave clicked');
      //                 tableRef.current!.stopEditing(false);
      //                 setEditingCellId(null);
      //               }}
      //             >
      //               Save
      //             </Button>
      //           </Space>
      //         ) : (
      //           <Button
      //             onClick={() => {
      //               setEditingCellId(node.id);
      //               handleOnEditClick(node.rowIndex);
      //             }}
      //           >
      //             Edit
      //           </Button>
      //         )}
      //       </>
      //     );
      //   },
      // },
    ],
    // [editingCellId, setEditingCellId, handleOnEditClick],
    [],
  );

  return (
    <StyledWrapper>
      <StyledHeader>
        <PageTitle title="Attributes" />
      </StyledHeader>
      <StyledTable>
        <StyledAlert
          showIcon
          message="Edit attribute values by double-clicking on the attribute value cell."
          type="info"
          closable
        />
        <Table
          ref={tableRef}
          isSideBarDisbaled
          isPaginationDisabled
          columnDefs={columns}
          getRowData={getAttributes}
          onCellEditStopped={handleOnStopEditing}
        />
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTag = styled(Tag)`
  border: none;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 9px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.lightTextGray};
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledHeader = styled.section`
  padding: 16px 24px 0;
`;

const StyledAlert = styled(Alert)`
  max-width: 500px;
  margin-bottom: 20px;
  padding: 9px 16px;
  border: none;
  color: ${({ theme }) => theme.colors.mineShaft};
`;
