import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

export const EmptyConversationState = ({
  isEmptyConversations,
  isEmptyContacts,
  onClick,
}) => {
  const actionButton = (
    <Button
      type="primary"
      onClick={() => {
        if (!isEmptyContacts) {
          onClick();
        }
      }}
      disabled={isEmptyContacts}
      ghost={isEmptyContacts}
    >
      Start conversation
    </Button>
  );

  return (
    <EmptyConversationState.Wrapper>
      <div>
        <img alt="No messages" src="/images/empty-communications.png" />
      </div>
      <EmptyConversationState.Title>
        {isEmptyConversations ? 'No messages yet' : 'No conversation selected'}
      </EmptyConversationState.Title>
      <HideForViewerRole>
        <EmptyConversationState.Description>
          {isEmptyConversations
            ? 'Start a conversation by clicking on the button below.'
            : `Select an existing conversation from the Messages tab,
  or start a new one by clicking on the button below.`}
        </EmptyConversationState.Description>
        {isEmptyContacts ? (
          <Tooltip title="No contact information available">
            {actionButton}
          </Tooltip>
        ) : (
          actionButton
        )}
      </HideForViewerRole>
    </EmptyConversationState.Wrapper>
  );
};

EmptyConversationState.Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  color: #1e3b58;

  img {
    margin-bottom: 53px;
    max-height: 192px;
    max-width: 172px;
  }
`;

EmptyConversationState.Title = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

EmptyConversationState.Description = styled.div`
  margin-top: 20px;
  margin-bottom: 25px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
`;
