import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const FETCH_DOCUMENT = `${namespace}/FETCH_DOCUMENT`;

const FETCH_DOCUMENT_QUERY = gql`
  query getDocument($payload: GetDocumentInput!) {
    getDocument(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const fetchDocument = (payload) => ({
  type: FETCH_DOCUMENT,
  payload: {
    key: 'getDocument',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_DOCUMENT_QUERY,
      variables: { payload },
    },
  },
});
