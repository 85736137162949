import { gql } from '@apollo/client';

export const PARTY_DATA_DOCUMENT_FRAGMENT = gql`
  fragment PartyDataDocument on Document {
    _id
    friendlyName
    notes
    expirationDate
    s3Key
    organization
    party {
      _id
    }
    metadata
    insurers
    issuedBy {
      partyContact
      email
    }
    flag {
      addedOn
      severityLevel
      affectedSubjects
      notes
      user {
        _id
        email
        profile {
          name
        }
      }
      authorName
    }
    verification {
      verifiedOn
      verifiedVia
      affectedSubjects
      notes
      user {
        _id
        email
        profile {
          name
        }
      }
    }
    archivedAt
    reviewedAt
    createdAt
    updatedAt
  }
`;
