export function objectsMerge<T extends Record<string, any>>() {
  return {
    merge(
      existing: T,
      incoming: T,
      { mergeObjects }: { mergeObjects: (existing: T, incoming: T) => T },
    ): T {
      return mergeObjects(existing, incoming);
    },
  };
}
