import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PROJECTS_TOTAL_COUNT = `${namespace}/FETCH_PROJECTS_TOTAL_COUNT`;
const FETCH_PROJECTS_TOTAL_COUNT_QUERY = gql`
  query listProjects {
    listProjects {
      totalCount
    }
  }
`;

export const fetchProjectsTotalCount = (
  query = FETCH_PROJECTS_TOTAL_COUNT_QUERY,
) => ({
  type: FETCH_PROJECTS_TOTAL_COUNT,
  payload: {
    key: 'listProjects',
    graphql: {
      fetchPolicy: 'no-cache',
      query,
    },
  },
});
