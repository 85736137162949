import { convertFromHTML } from 'draft-convert';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

export * from './editor-helpers';

export const isEditorStateBlank = (editorState: EditorState): boolean => {
  return !editorState.getCurrentContent().hasText();
};

export const getEditorHTMLContent = (editorState: EditorState) => {
  return stateToHTML(editorState.getCurrentContent(), {
    entityStyleFn: (entity) => {
      const entityType = entity.getType().toLowerCase();

      if (entityType === 'mention') {
        const { mention } = entity.getData();

        return {
          element: 'span',
          attributes: {
            'data-entityid': mention?.id,
            'data-entityname': mention?.name,
            'data-entitytype': 'mention',
          },
        };
      }
    },
  });
};

export const getEditorPlainText = (editorState: EditorState) => {
  return editorState.getCurrentContent().getPlainText();
};

export const getEmptyEditorState = () => {
  return EditorState.createEmpty();
};

/**
 * @description creates a new EditorState from scratch adding a text preserving the line breaks
 * @param message - string
 * @returns new EditorState
 */
export const setInitialEditorStateFromText = (message: string) => {
  const content = ContentState.createFromText(message);
  return EditorState.createWithContent(content);
};

/**
 * @description creates a new EditorState from scratch adding a html string
 * @param message - html string
 * @returns new EditorState
 */
export const setInitialEditorStateFromHTML = (message: string) => {
  const content = stateFromHTML(message);
  return EditorState.createWithContent(content);
};

export const convertHTMLToContentState = (html: string) => {
  html = html ?? '';
  return convertFromHTML({
    htmlToEntity: (
      nodeName: string,
      node: HTMLBaseElement,
      createEntity: (
        blockType: string,
        mutabilityType: string,
        data: Record<string, any>,
      ) => void,
    ) => {
      const isMention =
        node.dataset?.entitytype === 'mention' &&
        node.dataset?.entityid &&
        node.dataset?.entityname;

      if (isMention) {
        return createEntity('mention', 'SEGMENTED', {
          mention: {
            id: node.dataset.entityid,
            name: node.dataset.entityname,
          },
        });
      }
      if (nodeName === 'a') {
        return createEntity('LINK', 'MUTABLE', { url: node.href });
      }
    },
  })(html);
};

/**
 * (A) creates a new EditorState from scratch adding an htmlContent
 * or (B) creates a new EditorState starting from an existing one adding an htmlContent
 * @param htmlContent
 * @param editorState
 * @returns new EditorState
 */
export const getUpdatedEditorState = (
  htmlContent: string = '',
  editorState?: EditorState,
) => {
  const currentEditorState = editorState || getEmptyEditorState();
  const contentState = convertHTMLToContentState(htmlContent);

  return EditorState.push(
    currentEditorState,
    contentState,
    'change-block-data',
  );
};
