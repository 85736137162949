import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_LAYOUT_FRAGMENT } from '../fragments';

export const CREATE_EMAIL_LAYOUT = `${namespace}/CREATE_EMAIL_LAYOUT`;

const CREATE_EMAIL_LAYOUT_MUTATION = gql`
  mutation createEmailLayout($payload: CreateEmailLayoutInput!) {
    createEmailLayout(data: $payload) {
      ...EmailLayout
    }
  }
  ${EMAIL_LAYOUT_FRAGMENT}
`;

export const createEmailLayout = (payload) => ({
  type: CREATE_EMAIL_LAYOUT,
  payload: {
    key: 'createEmailLayout',
    graphql: {
      mutation: CREATE_EMAIL_LAYOUT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
