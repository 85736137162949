import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { TEXT_SNIPPETS_FRAGMENT } from '../fragments';

export const FETCH_TEXT_SNIPPETS = `${namespace}/FETCH_TEXT_SNIPPETS`;

const FETCH_TEXT_SNIPPETS_QUERY = gql`
  query listTextSnippets {
    listTextSnippets {
      ...TextSnippet
    }
  }
  ${TEXT_SNIPPETS_FRAGMENT}
`;

export const fetchTextSnippets = () => ({
  type: FETCH_TEXT_SNIPPETS,
  payload: {
    key: 'listTextSnippets',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_TEXT_SNIPPETS_QUERY,
    },
  },
});
