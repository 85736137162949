import type { GraphQLFormattedError } from 'graphql';
import * as R from 'ramda';

import type { AnyGQLSuccessAction, RootState } from '@common/types';

/**
 * @deprecated use getGraphqlResponse instead
 */

export const getGraphqlPayload = <SuccessAction extends AnyGQLSuccessAction>(
  action: SuccessAction,
  defaultPayload:
    | NonNullable<SuccessAction['payload']['data']>[string]
    | null = null,
): NonNullable<SuccessAction['payload']['data']>[string] | null => {
  const queryKey = R.pathOr(
    '',
    ['meta', 'previousAction', 'payload', 'key'],
    action,
  );
  return R.pathOr(defaultPayload, ['payload', 'data', queryKey], action);
};

/**
 * @description - This util retrieve the payload of a GQL request,
 *                returning directly the data if the response contains only one response
 *                otherwise it returns the payload so the key in the action is not needed
 *                anymore
 */
export const getGraphqlResponse = <SuccessAction extends AnyGQLSuccessAction>(
  { payload }: SuccessAction,
  defaultPayload:
    | NonNullable<SuccessAction['payload']['data']>[string]
    | null = null,
): NonNullable<SuccessAction['payload']['data']>[string] | null => {
  const gqlResponse = payload?.data;

  if (!gqlResponse) return defaultPayload;

  const requestsKeys = Object.keys(gqlResponse);

  // Cast any to gqlResponse since I believe it will never be hit. Will double check
  if (requestsKeys.length > 1) return gqlResponse as any;
  return gqlResponse?.[requestsKeys[0]] ?? defaultPayload;
};

type ErrorPayload = {
  message?: string;
  code?: string;
  data?: any;
};

function getError(action: AnyGQLSuccessAction): ErrorPayload | undefined {
  if (!action?.error || !action?.payload?.error) {
    return;
  }

  const error = R.path<GraphQLFormattedError>(['error', 0], action);
  const message = error?.message;
  const code = error?.extensions?.code as string | undefined;
  const data = error?.extensions?.data;

  return {
    message,
    code,
    data,
  };
}

/*
Helper to handle new payload's type, which has to be slowly introduced in all the queries.
The response is adapted to return the same data structure as of getGraphqlPayload
*/

export type GetNewGraphqlPayloadReturn<T> = {
  data?: T | null;
  totalCount?: number;
  error?: ErrorPayload;
};

export const getNewGraphqlPayload = <SuccessAction extends AnyGQLSuccessAction>(
  action: SuccessAction,
  defaultPayload:
    | NonNullable<SuccessAction['payload']['data']>[string]
    | null = null,
): GetNewGraphqlPayloadReturn<
  NonNullable<SuccessAction['payload']['data']>[string] | null
> => {
  const queryKey = R.pathOr(
    '',
    ['meta', 'previousAction', 'payload', 'key'],
    action,
  );

  const data = R.pathOr(defaultPayload, ['payload', 'data', queryKey], action);

  const error = getError(action);

  const countKey = queryKey.startsWith('list')
    ? `count${queryKey.split(/list(.+)/)[1]}`
    : undefined;

  const totalCount: number | undefined = countKey
    ? R.path(['payload', 'data', countKey], action)
    : undefined;

  return {
    data,
    totalCount,
    error,
  };
};

type GetContext = (state: RootState) => {
  organization: string;
  organizationSlugifyName: string;
  project: string;
};

export const getContext: GetContext = (state) => {
  const organization = state.organization?.active;
  const orgSystemSettings = state['system-settings']?.[organization?.id];

  const context = {
    organization: organization?.id || '',
    organizationSlugifyName: organization?.slugifyName || '',
    project: orgSystemSettings?.currentProjectId || '',
  };

  return context;
};
