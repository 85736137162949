import { useSubscription } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const CONVERSATION_UPDATES_SUBSCRIPTION = graphql(`
  subscription PrimaryRecordConversationUpdates(
    $partyId: ObjectId!
  ) {
    conversationUpdates(partyId: $partyId) {
      type
    }
  }
`);

export const useConversationUpdatesSubscription = ({
  partyId,
  onData,
}: {
  partyId: string;
  onData: () => void;
}) => {
  const { data, loading } = useSubscription(CONVERSATION_UPDATES_SUBSCRIPTION, {
    variables: {
      partyId,
    },
    onData,
  });

  return { data, loading };
};
