import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react';
import { equals } from 'ramda';
import { type ReactNode, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  getActiveOrganizationId,
  getOrganizationByRequest,
} from '@modules/organization/selectors';
import { getTokenRequestId } from '@modules/request/selectors';
import { getMyId } from '@modules/user/selectors';
import { useAppDispatch } from '@store/hooks';

import { setFeatureFlags } from '../actions/setFeatureFlags';
import { unleashConfig } from '../constants';

type FeatureFlagProviderProps = {
  children: ReactNode;
};

const client = new UnleashClient(unleashConfig);

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const dispatch = useAppDispatch();
  const requestId = useSelector(getTokenRequestId);
  const prevFeatureFlags = useRef([]);
  const activeOrganizationId = useSelector(getActiveOrganizationId);
  const userId = useSelector(getMyId);
  const documentRequestOrganization = useSelector((state) =>
    getOrganizationByRequest(state, requestId),
  );

  const handleUpdate = useCallback(() => {
    // Prevent dispatching the sync FF at every feature flag update
    if (equals(client.toggles, prevFeatureFlags.current)) return;

    // Sync with redux store for easy access FF from sagaJs
    dispatch(setFeatureFlags(client.toggles));
    prevFeatureFlags.current = client.toggles;
  }, [dispatch]);

  useEffect(() => {
    client.start();

    client.on('error', console.error);
    client.on('update', handleUpdate);

    return () => {
      client.off('error', console.error);
      client.off('update', handleUpdate);

      client.stop();
    };
  }, [handleUpdate]);

  useEffect(() => {
    client.updateContext({
      organizationId: activeOrganizationId || documentRequestOrganization?.id,
      userId,
    });
  }, [userId, activeOrganizationId, documentRequestOrganization?.id]);

  return (
    <FlagProvider unleashClient={client} startClient={false}>
      {children}
    </FlagProvider>
  );
};
