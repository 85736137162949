import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_SENDER_FRAGMENT } from '../fragments';

export const FETCH_EMAIL_SENDERS = `${namespace}/FETCH_EMAIL_SENDERS`;

const FETCH_EMAIL_SENDERS_QUERY = gql`
  query listEmailSenders {
    listEmailSenders {
      ...EmailSender
    }
  }
  ${EMAIL_SENDER_FRAGMENT}
`;

export const fetchEmailSenders = () => ({
  type: FETCH_EMAIL_SENDERS,
  payload: {
    key: 'listEmailSenders',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_EMAIL_SENDERS_QUERY,
    },
  },
});
