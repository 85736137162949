import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Form, type FormInstance, Input, Radio } from 'antd';

type ViewFormProps = {
  initialValues?: {
    name: string;
    isPrivate: boolean;
  };
  form: FormInstance;
};

export const ViewForm = ({ initialValues, form }: ViewFormProps) => {
  return (
    <Form
      form={form}
      name="viewForm"
      layout="vertical"
      initialValues={initialValues || { name: '', isPrivate: false }}
    >
      <Form.Item
        label="View name"
        name="name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input data-cy="enterViewNameInput" placeholder="Enter view name..." />
      </Form.Item>
      <Form.Item label="Visibility" name="isPrivate">
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={false}>
            <TeamOutlined style={{ marginRight: 8 }} />
            Workspace
          </Radio.Button>
          <Radio.Button value={true}>
            <UserOutlined style={{ marginRight: 8 }} />
            Private
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};
