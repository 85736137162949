import { SortDirectionsMap } from '@common/constants';
import { DocumentSortFields } from '@modules/document/constants';
import { PartySortFields } from '@modules/party/constants';
import { ProjectSortFields } from '@modules/project/constants';

/**
 * List view map.
 */
export const ListView = {
  Table: 'table',
  Card: 'card',
};

export const namespace = 'SYSTEM_SETTINGS';

export const STATE_KEY = 'system-settings';

export const defaultSearchParams = {
  parties: {
    sortField: PartySortFields.LastActivityDate,
    sortDirection: SortDirectionsMap.desc,
    view: 'table',
    size: '12',
  },
  documents: {
    sortField: DocumentSortFields.UploadDate,
    sortDirection: SortDirectionsMap.desc,
    view: 'table',
    size: '12',
  },
  projects: {
    sortField: ProjectSortFields.Name,
    sortDirection: SortDirectionsMap.desc,
    view: 'table',
    size: '12',
  },
  'party-documents': {
    sortField: DocumentSortFields.UploadDate,
    sortDirection: SortDirectionsMap.desc,
    view: 'table',
    size: '12',
  },
  'party-projects': {
    sortField: ProjectSortFields.Name,
    sortDirection: SortDirectionsMap.desc,
    view: 'table',
    size: '12',
  },
};
