import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_DOCUMENT_TYPE,
  DELETE_DOCUMENT_TYPE,
  FETCH_DOCUMENT_TYPES,
  UPDATE_DOCUMENT_TYPE,
} from '../actions';

const initialState = {
  data: {},
};

const DocumentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_DOCUMENT_TYPES}_SUCCESS`: {
      const documentTypes = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', documentTypes, state);
    }
    case `${CREATE_DOCUMENT_TYPE}_SUCCESS`:
    case `${UPDATE_DOCUMENT_TYPE}_SUCCESS`: {
      const documentType = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assocPath(['data', documentType._id], documentType, state);
    }
    case `${DELETE_DOCUMENT_TYPE}_SUCCESS`: {
      const documentType = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.dissocPath(['data', documentType._id], state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default DocumentTypeReducer;
