import { all, putResolve, takeLatest } from 'redux-saga/effects';

import { SET_AUTHENTICATED_APP_READY } from '@modules/app/actions';

import { fetchDocumentTypes } from '../actions';

function* fetchDocumentTypesSaga() {
  yield putResolve(fetchDocumentTypes());
}

function* documentTypeSaga() {
  yield all([takeLatest(SET_AUTHENTICATED_APP_READY, fetchDocumentTypesSaga)]);
}

export default documentTypeSaga;
