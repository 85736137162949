import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPDATE_ADDITIONAL_NOTE = `${namespace}/UPDATE_ADDITIONAL_NOTE`;

const UPDATE_ADDITIONAL_NOTE_MUTATION = gql`
  mutation updateAdditionalNotes($payload: UpdateAdditionalNotesInput!) {
    updateAdditionalNotes(data: $payload) {
      _id
      additionalNotes
    }
  }
`;

export const updateAdditionalNotes = (payload) => ({
  type: UPDATE_ADDITIONAL_NOTE,
  payload: {
    key: 'updateAdditionalNotes',
    graphql: {
      mutation: UPDATE_ADDITIONAL_NOTE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
