import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_DOCUMENTS_WIDGET = `${namespace}/FETCH_DOCUMENTS_WIDGET`;

const FETCH_DOCUMENTS_WIDGET_QUERY = gql`
  query getDocumentsWidgetData($filters: DocumentsWidgetFilter!) {
    getDocumentsWidgetData(filters: $filters) {
      documentsRecent {
        _id
        friendlyName
        reviewedAt
        party {
          _id
          name
        }
      }
      documentsToReview {
        _id
        friendlyName
        reviewedAt
        party {
          _id
          name
        }
      }
      documentsExpiring {
        _id
        friendlyName
        reviewedAt
        party {
          _id
          name
        }
      }
      totalRecent
      totalToReview
      totalExpiring
    }
  }
`;

export const fetchDocumentsWidget = ({ filters }) => ({
  type: FETCH_DOCUMENTS_WIDGET,
  payload: {
    key: 'getDocumentsWidgetData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_DOCUMENTS_WIDGET_QUERY,
      variables: { filters },
    },
  },
});
