import useMemberRole from '../hooks/useMemberRole';
import { ROLES, nonAdminRoles } from '../models/data';

const HideForRoles = ({ roles = [], children }) => {
  const role = useMemberRole();

  if (roles.includes(role)) {
    return null;
  }

  return children;
};

export const HideForViewerRole = (props) => {
  return <HideForRoles {...props} roles={[ROLES.VIEWER]} />;
};

export const HideForNonAdminRole = (props) => {
  return <HideForRoles {...props} roles={nonAdminRoles} />;
};

export default HideForRoles;
