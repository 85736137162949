import { gql } from '@apollo/client';

import type { AnyGQLQueryAction, AnyGQLSuccessAction } from '@common/types';
import { ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { namespace } from '../constants';
import { FILLABLE_FORM_FRAGMENT } from '../fragments';

export const FETCH_FILLABLE_FORM = `${namespace}/FETCH_FILLABLE_FORM` as const;
export const FETCH_FILLABLE_FORM_SUCCESS =
  `${namespace}/FETCH_FILLABLE_FORM${ACTION_SUCCESS_SUFFIX}` as const;

const FETCH_FILLABLE_FORM_QUERY = gql`
  query getForm($_id: String!) {
    getForm(_id: $_id) {
      ...Form
    }
  }
  ${FILLABLE_FORM_FRAGMENT}
`;

export type FetchFillableFormPayload = string;

export type FetchFillableForm = (
  payload: FetchFillableFormPayload,
) => AnyGQLQueryAction<
  typeof FETCH_FILLABLE_FORM,
  { _id: FetchFillableFormPayload }
>;

export const fetchFillableForm: FetchFillableForm = (_id) => ({
  type: FETCH_FILLABLE_FORM,
  payload: {
    key: 'getForm',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_FILLABLE_FORM_QUERY,
      variables: { _id },
    },
  },
});

export type FetchFillableFormSuccessAction = AnyGQLSuccessAction<
  typeof FETCH_FILLABLE_FORM_SUCCESS
>;
