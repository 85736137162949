import moment from 'moment';
import pluralize from 'pluralize';

import { DateSuffix, KeyType } from '@common/components/DateFilterSelector';
import { FilterTypeOptionsMap } from '@common/utils/date';

export const defaultDateFormats = [
  // 'll',
  'MM/DD/YYYY',
  'MM/DD/YY',
  'MM-DD-YYYY',
  'MM-DD-YY',
  'MMDDYY',
  'MMDDYYYY',
];

export const normalizeDate = (value = '') => {
  if (!value) return;

  const formatDate = 'YYYY-MM-DD';
  const [startDate, endDate] = value.split('~');

  if (endDate) {
    return `${moment.utc(parseInt(startDate)).format(formatDate)} - ${moment
      .utc(parseInt(endDate))
      .format(formatDate)}`;
  }

  return moment.utc(parseInt(startDate)).format(formatDate);
};

export const normalizeArray = (value = []) =>
  !Array.isArray(value) ? [value].join(', ') : value.join(', ');

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const processRangeFilter = ({ key, label, value, activeFilters = {} }) => {
  const filterTypeKey = activeFilters[`${key}FilterType`] || '';
  const filterTypeOption = FilterTypeOptionsMap[filterTypeKey];

  const withinSuffix =
    activeFilters[`${key}${DateSuffix.WithinDateCount}`] || 0;

  const withinDateType =
    activeFilters[`${key}${DateSuffix.WithinDateType}`] || '';

  const formattedOptionLabel = filterTypeOption?.label.toLowerCase();

  switch (filterTypeKey) {
    case KeyType.LAST:
    case KeyType.NEXT:
    case KeyType.OLDER:
      return {
        label,
        value: `${formattedOptionLabel} ${pluralize(
          withinDateType,
          parseInt(withinSuffix),
          true,
        )}`,
      };
    case KeyType.ON:
    case KeyType.BETWEEN:
      return {
        label: `${label} ${formattedOptionLabel}`,
        value: `${value}`,
      };
    default:
      return {};
  }
};

export const getFilterData = ({
  key,
  filters,
  formatValue,
  activeFilters = {},
}) => {
  const { label, normalizeValue, isRangeFilter } = filters[key] || {};
  const filterValue = formatValue(key, activeFilters[key]);
  const value = normalizeValue?.(filterValue) || filterValue;

  if (!label) {
    if (!value) return {};
    return filterValue;
  }

  return isRangeFilter
    ? processRangeFilter({ key, label, value, activeFilters })
    : { label, value };
};

export const excludeNullableAndEmptyString = (list) => {
  return list.filter(
    (value) => value !== null && value !== undefined && value !== '',
  );
};
