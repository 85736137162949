import { CellActions } from '@common/components/Table';
import type { Document } from '@graphql/types/graphql';

import type { ActionType } from '../../../../constants';
import { DownloadDocumentButton } from '../../../DownloadDocumentButton';

import { getActionMenuItems } from './DocumentActions.utils';

type DocumentActionsProps = {
  document: Document;
  excludedActions?: ActionType[];
  projectId?: string;
  onClick: (params: { actionType: ActionType; document: Document }) => void;
};

const isDocumentAssignToCurrentProject = ({
  document,
  projectId,
}: {
  document: Document;
  projectId: string;
}) => {
  if (!document.projects) {
    return false;
  }

  return document.projects.some((project) => project._id === projectId);
};

export const DocumentActions = ({
  document,
  excludedActions,
  projectId,
  onClick,
}: DocumentActionsProps) => {
  const isDetachProjectDisabled = Boolean(
    !projectId ||
      !isDocumentAssignToCurrentProject({ document, projectId }) ||
      document.appliesToAllProjects,
  );

  const menuItems = getActionMenuItems({
    handleOnClick: (actionType) => onClick({ actionType, document }),
    excludedActions,
    documentInfo: {
      isDetachPartyDisabled: !Boolean(document.party),
      isDetachProjectDisabled,
      isDocumentReviewed: document.reviewedAt,
      isDocumentArchived: document.archivedAt,
    },
  });

  return (
    <CellActions>
      <DownloadDocumentButton document={document} />
      {menuItems.length ? (
        <CellActions.ThreeDotsMenu menuItems={menuItems} />
      ) : null}
    </CellActions>
  );
};
