import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const CHECK_DOCUMENT_REQUEST_EXISTENCE = `${namespace}/CHECK_DOCUMENT_REQUEST_EXISTENCE`;

const CHECK_DOCUMENT_REQUEST_EXISTENCE_QUERY = gql`
  query checkDocumentRequestExistence($requestId: String!) {
    checkDocumentRequestExistence(requestId: $requestId)
  }
`;

export const checkDocumentRequestExistence = (requestId) => ({
  type: CHECK_DOCUMENT_REQUEST_EXISTENCE,
  payload: {
    key: 'checkDocumentRequestExistence',
    graphql: {
      fetchPolicy: 'no-cache',
      query: CHECK_DOCUMENT_REQUEST_EXISTENCE_QUERY,
      variables: {
        requestId,
      },
    },
  },
});
