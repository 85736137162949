import * as R from 'ramda';
import { v4 as createUuid } from 'uuid';

import { isProduction } from '@config/EnvironmentConfig';

const prepareRequestLink = (operation, forward) => {
  const { token } = operation.getContext();
  const { organization, organizationSlugifyName, project } =
    operation.variables;

  operation.variables = R.omit(
    ['organization', 'organizationSlugifyName', 'project'],
    operation.variables,
  );

  // Include cookies on cross-origin requests. Disabled on dev environments due to CORS errors.
  if (isProduction || import.meta.env.VITE_INCLUDE_CREDENTIALS) {
    operation.setContext({
      fetchOptions: {
        credentials: 'include',
      },
    });
  }

  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
        organization,
        organizationSlugifyName,
        project,
        /**
         * @note set a custom header with an unique id in order to be able to track
         * the whole Sentry log error flow between client and server
         *
         * this value is used also as Sentry scope-tag in the errorLink middleware.
         */
        'x-transaction-id': createUuid(),
      },
    });
  }

  return forward(operation);
};

export default prepareRequestLink;
