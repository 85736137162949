import { gql } from '@apollo/client';

import type { AnyGQLMutationAction, AnyGQLSuccessAction } from '@common/types';
import { ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { namespace } from '../constants';
import { FILLABLE_FORM_FRAGMENT } from '../fragments';

export const CREATE_FILLABLE_FORM =
  `${namespace}/CREATE_FILLABLE_FORM` as const;
export const CREATE_FILLABLE_FORM_SUCCESS =
  `${namespace}/CREATE_FILLABLE_FORM${ACTION_SUCCESS_SUFFIX}` as const;

const CREATE_FILLABLE_FORM_MUTATION = gql`
  mutation createForm($payload: CreateFormInput!) {
    createForm(data: $payload) {
      ...Form
    }
  }
  ${FILLABLE_FORM_FRAGMENT}
`;

export type CreateFillableFormPayload = {
  _id: string;
  documentFile: File;
  name: string;
  format: string;
  additionalInfo: string;
  appliesToAllProjects: boolean;
  formFieldValues: Record<string, any>[];
  formFields: Record<string, any>[];
  annotations: Record<string, any>[];
};

export type CreateFillableForm = (
  payload: CreateFillableFormPayload,
) => AnyGQLMutationAction<
  typeof CREATE_FILLABLE_FORM,
  { payload: CreateFillableFormPayload },
  { hasUpload: boolean }
>;

export const createFillableForm: CreateFillableForm = (payload) => ({
  type: CREATE_FILLABLE_FORM,
  payload: {
    key: 'createForm',
    graphql: {
      mutation: CREATE_FILLABLE_FORM_MUTATION,
      variables: {
        payload,
      },
      context: { hasUpload: true },
    },
  },
});

export type CreateFillableFormSuccessAction = AnyGQLSuccessAction<
  typeof CREATE_FILLABLE_FORM_SUCCESS
>;
