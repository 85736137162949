import type { MenuProps } from 'antd';
import type { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { filter, flatMapDeep } from 'lodash';

export function extractElementsByKeyFromAntdMenu(
  data: MenuProps['items'],
  key: string,
): MenuProps['items'] {
  return filter(
    flatMapDeep(data, (item: any) => {
      if (item.children) {
        return [
          item,
          ...(extractElementsByKeyFromAntdMenu(item.children, key) || []),
        ];
      }
      return [item];
    }),
    key,
  );
}

export function getActiveMenuItem(
  menuItems: MenuProps['items'] | undefined,
  activeKey?: string,
): MenuItemType | null | undefined {
  if (!activeKey) return null;

  return menuItems?.find((item) => item?.key === activeKey) as
    | MenuItemType
    | null
    | undefined;
}
