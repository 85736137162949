import { gql } from '@apollo/client';

import { DOCUMENT_FRAGMENT } from '../../document/fragments';
import { namespace } from '../constants';

export const ASSOCIATE_DOCUMENTS_TO_PROJECT = `${namespace}/ASSOCIATE_DOCUMENTS_TO_PROJECT`;

const ASSOCIATE_DOCUMENTS_TO_PROJECT_MUTATION = gql`
  mutation associateDocumentsToProject(
    $data: AssociateDocumentsToProjectInput!
  ) {
    associateDocumentsToProject(data: $data) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const associateDocumentsToProject = (data) => ({
  type: ASSOCIATE_DOCUMENTS_TO_PROJECT,
  payload: {
    key: 'associateDocumentsToProject',
    graphql: {
      mutation: ASSOCIATE_DOCUMENTS_TO_PROJECT_MUTATION,
      variables: {
        data,
      },
    },
  },
});
