import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { COMPLIANCE_PROFILE_FRAGMENT } from '../fragments';

export const CREATE_COMPLIANCE_PROFILE = `${namespace}/CREATE_COMPLIANCE_PROFILE`;

const CREATE_COMPLIANCE_PROFILE_MUTATION = gql`
  mutation createComplianceProfile($payload: CreateComplianceProfileInput!) {
    createComplianceProfile(data: $payload) {
      ...ComplianceProfileFrag
    }
  }
  ${COMPLIANCE_PROFILE_FRAGMENT}
`;

export const createComplianceProfile = (payload) => ({
  type: CREATE_COMPLIANCE_PROFILE,
  payload: {
    key: 'createComplianceProfile',
    graphql: {
      mutation: CREATE_COMPLIANCE_PROFILE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
