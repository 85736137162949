import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_ADD_REQUIREMENT_VALUE = `${namespace}/BULK_ADD_REQUIREMENT_VALUE`;

const BULK_ADD_REQUIREMENT_VALUE_MUTATION = gql`
  mutation bulkAddRequirementValue($payload: BulkAddRequirementValueInput) {
    bulkAddRequirementValue(data: $payload) {
      scheduled
    }
  }
`;

export const bulkAddRequirementValue = (payload) => ({
  type: BULK_ADD_REQUIREMENT_VALUE,
  payload: {
    key: 'bulkAddRequirementValue',
    graphql: {
      mutation: BULK_ADD_REQUIREMENT_VALUE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
