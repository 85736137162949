import styled from 'styled-components';

export const ArchivedBanner = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  background: repeating-linear-gradient(
    45deg,
    #f1f1f1,
    #f1f1f1 10px,
    #fafafa 10px,
    #fafafa 20px
  );
`;
