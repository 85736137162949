import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { USER_FRAGMENT } from '../fragments';

export const DELETE_USER = `${namespace}/DELETE_USER`;

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;

export const deleteUser = (id) => ({
  type: DELETE_USER,
  payload: {
    key: 'deleteUser',
    graphql: {
      mutation: DELETE_USER_MUTATION,
      variables: { id },
    },
  },
});
