import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { CURRENT_PROJECT_FRAGMENT } from '../fragments';

export const FETCH_CURRENT_PROJECT_BY_ID = `${namespace}/FETCH_CURRENT_PROJECT_BY_ID`;

const FETCH_CURRENT_PROJECT_BY_ID_QUERY = gql`
  query getProjectById($_id: String!) {
    getProjectById(_id: $_id) {
      ...CurrentProject
    }
  }
  ${CURRENT_PROJECT_FRAGMENT}
`;

export const fetchCurrentProjectById = (_id) => ({
  type: FETCH_CURRENT_PROJECT_BY_ID,
  payload: {
    key: 'getProjectById',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_CURRENT_PROJECT_BY_ID_QUERY,
      variables: { _id },
    },
  },
});
