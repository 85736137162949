const PrimaryStarIcon = ({ width = 17, height = 17 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.5" cy="8.5" r="8.5" fill="white" />
    <path
      d="M13.5286 6.48282L10.3052 6.01436L8.86433 3.09317C8.82497 3.01319 8.76022 2.94844 8.68024 2.90909C8.47966 2.81006 8.23591 2.89258 8.13561 3.09317L6.6947 6.01436L3.47136 6.48282C3.38249 6.49551 3.30124 6.53741 3.23903 6.60088C3.16383 6.67818 3.12239 6.78217 3.12382 6.89001C3.12524 6.99785 3.16942 7.1007 3.24665 7.17598L5.57878 9.44971L5.0278 12.6604C5.01488 12.735 5.02315 12.8119 5.05166 12.8821C5.08017 12.9523 5.12779 13.0131 5.18912 13.0577C5.25045 13.1022 5.32303 13.1287 5.39863 13.1341C5.47424 13.1395 5.54984 13.1236 5.61686 13.0882L8.49997 11.5724L11.3831 13.0882C11.4618 13.1301 11.5532 13.144 11.6408 13.1288C11.8617 13.0907 12.0102 12.8813 11.9721 12.6604L11.4212 9.44971L13.7533 7.17598C13.8168 7.11377 13.8587 7.03252 13.8714 6.94366C13.9056 6.72149 13.7508 6.51583 13.5286 6.48282Z"
      fill="#FFA800"
    />
  </svg>
);

export default PrimaryStarIcon;
