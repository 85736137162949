import { List, Popover, Spin, type TooltipProps } from 'antd';
import type { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

type Item = {
  _id: string;
  [key: string]: unknown;
};

type PopoverWithListProps<T> = {
  items: T[];
  loadItems: () => void;
  totalItemsCount: number;
  getItemComponent: (item: T) => ReactNode;
  children: ReactNode;
} & Pick<TooltipProps, 'placement'>;

const SpinLoader = () => (
  <StyledSpinWrap>
    <Spin spinning />
  </StyledSpinWrap>
);

const PopoverWithList = <T extends Item>({
  children,
  totalItemsCount,
  items = [],
  loadItems,
  getItemComponent,
  placement,
}: PopoverWithListProps<T>) => {
  const InifinityScrollableList = (
    <StyledListWrapper id="PopoverWithListScrollableWrapper">
      <InfiniteScroll
        dataLength={items.length}
        hasMore={items.length < totalItemsCount}
        next={loadItems}
        scrollableTarget="PopoverWithListScrollableWrapper"
        loader={<SpinLoader />}
      >
        <List
          dataSource={items}
          renderItem={(item) => (
            <List.Item key={item._id}>{getItemComponent(item)}</List.Item>
          )}
        />
      </InfiniteScroll>
    </StyledListWrapper>
  );

  return (
    <Popover placement={placement} content={InifinityScrollableList}>
      {children}
    </Popover>
  );
};

const StyledListWrapper = styled.div`
  max-height: 300px;
  min-width: 300px;
  overflow: auto;
`;

const StyledSpinWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export default PopoverWithList;
