import { gql } from '@apollo/client';

import { CUSTOM_FIELD_FRAGMENT } from '@modules/custom-field/fragments';

export const PROJECT_FRAGMENT = gql`
  fragment Project on Project {
    _id
    name
    description
    isActive
    parties {
      _id
      name
      isActive
      isTrackingCompliance
      type {
        _id
        name
      }
      partyComplianceProfile {
        _id
        complianceProfile
        name
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
      }
      status
    }
    organization
    startDate
    endDate
    customFields {
      nodes {
        customField {
          ...CustomField
        }
        value
      }
      totalCount
    }
    externalIds
    complianceProfiles {
      projectComplianceProfile
      originalComplianceProfile
    }
    attachments {
      _id
      friendlyName
      url
      s3Key
    }
    complianceStatus {
      status
      compliantPartiesCount
      totalPartiesCount
      totalActivePartiesCount
      compliantActivePartiesCount
    }
    deletedAt
    deletedBy
    createdAt
    updatedAt
  }
  ${CUSTOM_FIELD_FRAGMENT}
`;
