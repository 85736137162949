import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_LAYOUT_FRAGMENT } from '../fragments';

export const DELETE_EMAIL_LAYOUT = `${namespace}/DELETE_EMAIL_LAYOUT`;

const DELETE_EMAIL_LAYOUT_MUTATION = gql`
  mutation deleteEmailLayout($_id: String!) {
    deleteEmailLayout(_id: $_id) {
      ...EmailLayout
    }
  }
  ${EMAIL_LAYOUT_FRAGMENT}
`;

export const deleteEmailLayout = (_id) => ({
  type: DELETE_EMAIL_LAYOUT,
  payload: {
    key: 'deleteEmailLayout',
    graphql: {
      mutation: DELETE_EMAIL_LAYOUT_MUTATION,
      variables: {
        _id,
      },
    },
  },
});
