import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const SEND_REQUEST_REMINDER = `${namespace}/SEND_REQUEST_REMINDER`;

const SEND_REQUEST_REMINDER_MUTATION = gql`
  mutation sendRequestReminder($payload: SendRequestReminderInput!) {
    sendRequestReminder(data: $payload) {
      _id
      lastRequestSentOn
      requests {
        _id
        deletedAt
        deletedBy
        forwardedAt
        forwardedTo
        sentByUser
        party
        message
        attachments {
          url
          friendlyName
          s3Key
        }
        documentChecklists
        sentOn
        organization
        dueDate
        project
        createdAt
        updatedAt
      }
    }
  }
`;

export const sendRequestReminder = (payload) => ({
  type: SEND_REQUEST_REMINDER,
  payload: {
    key: 'sendRequestReminder',
    graphql: {
      mutation: SEND_REQUEST_REMINDER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
