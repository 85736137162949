import * as R from 'ramda';

import {
  PROVIDERS,
  STATUS_MAP,
  defaultIntegrationsCredentials,
} from '@modules/integration/constants';
import { getRequest } from '@modules/party/selectors';

import { STATE_KEY } from '../constants';

export const getOrganizations = R.compose(
  R.values,
  R.pathOr({}, [STATE_KEY, 'data']),
);

export const getOrganization = (state, id) =>
  R.path([STATE_KEY, 'data', id], state);

export const getOrganizationByRequest = (state, requestId) => {
  if (!requestId) {
    return null;
  }

  const request = getRequest(state, requestId);

  if (!request || !request.organization) {
    return null;
  }

  return R.path([STATE_KEY, 'data', request.organization], state);
};

export const getActiveOrganizationId = (state) =>
  R.path([STATE_KEY, 'active', 'id'], state);

export const getOrganizationRequestTokenTTL = (state) =>
  R.path([STATE_KEY, 'active', 'requestTokenTTL'], state);

export const getOrganizationDefaultRequestMessage = (state) =>
  R.path([STATE_KEY, 'active', 'defaultRequestMessage'], state);

export const getActiveOrganizationData = (state) =>
  R.path([STATE_KEY, 'active'], state);

export const getActiveOrganizationProjectsTotalCount = (state) =>
  R.path([STATE_KEY, 'active', 'projectsTotalCount'], state);

export const getOrganizationNamespaceUrl = (state) => {
  const { slugifyName } = getActiveOrganizationData(state) || {};
  return slugifyName ? `/${slugifyName}` : '';
};

export const getActiveOrganizationSettings = (state) =>
  R.path([STATE_KEY, 'active', 'settings'], state);

export const getIsPartyBelongToActiveOrganization = (state, party) => {
  const activeOrganization = getActiveOrganizationId(state);
  return activeOrganization === party?.organization;
};

export const getIsReloadPage = R.path([STATE_KEY, 'reload']);

export const getBulkOperationCompleted = (state) =>
  state[STATE_KEY].bulkOperationCompleted;

export const getOrganizationFeatures = R.path([
  STATE_KEY,
  'active',
  'features',
]);

export const getUnmappedParties = (state, integrationKey) =>
  R.filter(
    (item) =>
      item.status !== STATUS_MAP.IGNORED.key &&
      item.status !== STATUS_MAP.LINKED.key,
    R.pathOr(
      [],
      [STATE_KEY, 'integrationStatus', integrationKey, 'parties'],
      state,
    ),
  );

export const getUnmappedProjects = (state, provider) =>
  R.filter(
    (item) =>
      item.status !== STATUS_MAP.IGNORED.key &&
      item.status !== STATUS_MAP.LINKED.key,
    R.pathOr([], [STATE_KEY, 'integrationStatus', provider, 'projects'], state),
  );

export const getIntegrationData = (state, provider, instance) =>
  R.compose(
    R.mergeDeepRight(
      defaultIntegrationsCredentials.find(
        (providersDefaults) => providersDefaults.provider === provider,
      ),
    ),
    R.defaultTo({
      organizationId: getActiveOrganizationId(state),
    }),
    R.find(
      (credential) =>
        credential.provider === provider &&
        ((!instance && !credential.instance) ||
          credential.instance === instance),
    ),
    R.pathOr({}, [STATE_KEY, 'active', 'credentials']),
  )(state);

export const getQuickbooksData = (state) =>
  getIntegrationData(state, PROVIDERS.QUICKBOOKS.key);

export const getActiveIntegrations = (state) =>
  R.pathOr([], [STATE_KEY, 'active', 'credentials'], state);

export const getIntegrationStatus = R.path([STATE_KEY, 'integrationStatus']);

export const getPersonalAccessTokens = R.compose(
  R.values,
  R.pathOr({}, [STATE_KEY, 'personalAccessTokens']),
);
