import { SupportedSubjectDateValues } from '@modules/compliance-profile/constants';
import { utc } from './utc';
export function formatDocumentDateLabel(date?: string) {
  if (!date) return null;

  const supportedSubjectDateValue =
    SupportedSubjectDateValues[date as keyof typeof SupportedSubjectDateValues];

  if (supportedSubjectDateValue) {
    return supportedSubjectDateValue.label;
  }

  const formattedDate = utc(date);

  if (formattedDate.isValid()) {
    return formattedDate.format('MMM DD, YYYY');
  }

  return null;
}
