import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const ARCHIVE_DOCUMENT = `${namespace}/ARCHIVE_DOCUMENT`;

const ARCHIVE_DOCUMENT_MUTATION = gql`
  mutation archiveDocument($payload: ArchiveDocumentInput!) {
    archiveDocument(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const archiveDocument = (payload) => ({
  type: ARCHIVE_DOCUMENT,
  payload: {
    key: 'archiveDocument',
    graphql: {
      mutation: ARCHIVE_DOCUMENT_MUTATION,
      variables: { payload },
    },
  },
});
