import pluralize from 'pluralize';

import { type Branch, type Step, type WaitStep, isWaitStep } from '../../types';

const getHumanizedDuration = (duration: WaitStep['duration'] = {}) => {
  const unit = Object.keys(duration)[0];
  const quantity = duration[unit];

  return `${quantity} ${pluralize(unit, quantity)}`;
};

export const getFirstBranchSteps = (branches?: Branch[]): Step[] => {
  const firstBranch = branches?.[0];

  if (!firstBranch) {
    return [];
  }

  return firstBranch.steps;
};

export const getScheduleLabel = (schedules: string[], index: number) => {
  if (!schedules.length) {
    return `${index === 0 ? '(after enabled)' : ''}`;
  }

  const suffix = index === 0 ? 'after enabled' : 'later';
  return `(${schedules.join(' and ')} ${suffix})`;
};

export const getStepSchedulesList = (steps: Step[]) => {
  const { schedules } = steps.reduce<{
    schedules: string[][];
    tempWaitsQueue: string[];
  }>(
    (acc, step) => {
      if (isWaitStep(step)) {
        const humanizedDuration = getHumanizedDuration(step.duration);

        return {
          ...acc,
          tempWaitsQueue: [...acc.tempWaitsQueue, humanizedDuration],
        };
      }

      return {
        schedules: [...acc.schedules, acc.tempWaitsQueue],
        tempWaitsQueue: [],
      };
    },
    { schedules: [], tempWaitsQueue: [] },
  );

  return schedules;
};
