import { Button, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  MessageType,
  ReplyMarker,
} from '@modules/party-conversation/constants';

import { InfoMessage } from '../InfoMessage';

export const EMPTY_MESSAGE_TEXT = 'This message has no content';
export const DOCUMENT_LINK_LABEL = 'View document request page';
export const REQUEST_BASE_ROUTE = '/request-landing/';

const EVENT_WITH_DOCUMENT_REQUEST_LINK = {
  [MessageType.Request]: 'Document request was sent.',
  [MessageType.Reminder]: 'Reminder was sent.',
  [MessageType.RequestForwarded]: 'Document request forwarded.',
  [MessageType.Expiring]: 'Automatic reminder was sent.',
  [MessageType.NewTokenLink]: 'An updated access link has been requested.',
};

const EVENT_WITH_CONTACT_ACTION = {
  [MessageType.ContactRemovedFromConversation]: ({ partyContact }) =>
    `Removed ${partyContact?.email} from conversation`,
  [MessageType.ContactDeleted]: ({ partyContact }) =>
    `${partyContact?.email} deleted as contact.`,
  [MessageType.ContactEmailUpdatedResent]: ({ partyContactAfterUpdate }) =>
    `Contact changed to ${partyContactAfterUpdate?.email} and message was resent.`,
  [MessageType.MessageResent]: ({ partyContact }) =>
    `Message was resent to ${partyContact?.email}`,
};

function htmlToElement(html) {
  const template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = `<div>${html}</div>`;
  return template.content.firstChild;
}

const ShadowComponent = ({ html }) => {
  const shadowRef = useRef(null);
  const shadowRootRef = useRef(null);
  const [showDangerouslyInnerHTML, setShowDangerouslyInnerHTML] =
    useState(false);
  const hasStyleTag = useMemo(() => {
    const styleTagRegex = /<style[^>]*>.*?<\/style>/;
    return styleTagRegex.test(html);
  }, [html]);

  const shadowRootSupported =
    typeof window !== 'undefined' &&
    window.Element &&
    Object.prototype.hasOwnProperty.call(
      window.Element.prototype,
      'attachShadow',
    );

  useEffect(() => {
    if (!shadowRootSupported) return;
    // Attach shadow DOM only once
    if (!shadowRootRef.current) {
      shadowRootRef.current = shadowRef.current.attachShadow({ mode: 'open' });
    }

    // Update the shadow DOM content when the HTML prop changes
    shadowRootRef.current.innerHTML = html;

    return () => {
      // Optional cleanup when the component unmounts
      shadowRootRef.current.innerHTML = '';
    };
  }, [html, shadowRootSupported]);

  if (shadowRootSupported) {
    return <div data-cy="shadowComponent" ref={shadowRef}></div>;
  }

  if (showDangerouslyInnerHTML || !hasStyleTag) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  return (
    <StyledWarning>
      <span>
        This text can compromise the responsiveness of the platform if displayed
      </span>
      <Button danger onClick={() => setShowDangerouslyInnerHTML(true)}>
        Show me anyway
      </Button>
    </StyledWarning>
  );
};

const EventContent = ({
  type,
  outputHtml,
  conversationRequest,
  requestFromMessage,
  eventData = {},
}) => {
  const [expanded, setExpanded] = useState(false);
  const parsedHtml = htmlToElement(DOMPurify.sanitize(outputHtml));
  const marker = parsedHtml?.querySelector(`[title="${ReplyMarker}"]`);
  marker?.parentElement?.remove();

  if (type === MessageType.Message) {
    return (
      <>
        {outputHtml ? (
          <StyledMessageWrapper>
            <ShadowComponent html={parsedHtml.outerHTML} />
            {Boolean(marker?.length) && (
              <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                <StyledExpanderButton onClick={() => setExpanded(!expanded)}>
                  •••
                </StyledExpanderButton>
              </Tooltip>
            )}
            {expanded && <ShadowComponent html={marker?.outerHTML} />}
          </StyledMessageWrapper>
        ) : (
          <StyledEmptyMessage>{EMPTY_MESSAGE_TEXT}</StyledEmptyMessage>
        )}
      </>
    );
  }

  if (EVENT_WITH_DOCUMENT_REQUEST_LINK[type]) {
    return (
      <InfoMessage
        label={EVENT_WITH_DOCUMENT_REQUEST_LINK[type]}
        link={{
          href: `${REQUEST_BASE_ROUTE}${
            requestFromMessage || conversationRequest
          }`,
          label: DOCUMENT_LINK_LABEL,
        }}
      />
    );
  }

  if (EVENT_WITH_CONTACT_ACTION[type]) {
    return (
      <StyledEventLog>
        {EVENT_WITH_CONTACT_ACTION[type](eventData)}
      </StyledEventLog>
    );
  }

  return null;
};

const StyledWarning = styled.div`
  border: 1px solid ${(props) => props.theme.colors.lightBGGray};
  padding: 10px;
  border-radius: 5px;

  span {
    display: block;
    padding-bottom: 10px;
  }
`;

const StyledMessageWrapper = styled.div`
  flex: 1;
  word-wrap: break-word;
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  p,
  ol,
  ul {
    margin-bottom: 0;
  }

  ol,
  ul {
    padding-left: 20px;
  }
`;

const StyledEmptyMessage = styled.div`
  color: ${(props) => props.theme.colors.lightGray};
  font-style: italic;
`;

const StyledExpanderButton = styled.span`
  height: 9px;
  padding: 0 5px 0 7px;
  align-items: center;
  letter-spacing: 2px;
  font-size: 10px;
  background-color: #e8edf3;
  border-radius: 10px;
  cursor: pointer;

  :hover {
    background-color: #dbdee2;
  }
`;

const StyledEventLog = styled.span`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
`;

export default EventContent;
