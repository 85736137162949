import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const EXPORT_COMPLIANCE_PROFILES = `${namespace}/EXPORT_COMPLIANCE_PROFILES`;

const EXPORT_COMPLIANCE_PROFILES_MUTATION = gql`
  mutation exportComplianceProfile($payload: ExportComplianceProfileInput!) {
    exportComplianceProfile(data: $payload) {
      scheduled
    }
  }
`;

export const exportComplianceProfiles = (payload) => ({
  type: EXPORT_COMPLIANCE_PROFILES,
  payload: {
    key: 'exportComplianceProfiles',
    graphql: {
      mutation: EXPORT_COMPLIANCE_PROFILES_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
