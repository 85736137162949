import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const VALIDATE_REQUEST_TOKEN = `${namespace}/VALIDATE_REQUEST_TOKEN`;

const VALIDATE_REQUEST_TOKEN_QUERY = gql`
  query validateRequestToken($data: ValidateRequestTokenInput!) {
    validateRequestToken(data: $data) {
      token
      requestId
      recipientEmail
    }
  }
`;

export const validateRequestToken = (data) => ({
  type: VALIDATE_REQUEST_TOKEN,
  payload: {
    key: 'validateRequestToken',
    graphql: {
      fetchPolicy: 'no-cache',
      query: VALIDATE_REQUEST_TOKEN_QUERY,
      variables: { data },
    },
  },
});
