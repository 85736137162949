export enum FilterInputType {
  SELECT = 'select',
  RANGE = 'range',
  PICKER = 'picker',
}

export enum KeyType {
  NEXT = 'next',
  LAST = 'last',
  BETWEEN = 'between',
  ON = 'on',
  OLDER = 'older',
}

export enum WithinDateType {
  DAYS = 'day',
  WEEKS = 'week',
  MONTHS = 'month',
}

export enum DateSuffix {
  WithinDateCount = 'WithinDateCount',
  WithinDateType = 'WithinDateType',
  From = 'From',
  To = 'To',
  PickerValue = 'PickerValue',
  FilterType = 'FilterType',
}

export const WithinDateOptions = [
  { key: 'day', label: 'Days', daysCount: 1 },
  { key: 'week', label: 'Weeks', daysCount: 7 },
  { key: 'month', label: 'Months', daysCount: 30 },
];

export const MIN_DATE = -8640000000000000;

/**
 * Possible date options.
 */
export const FilterTypeOptions = [
  {
    key: KeyType.NEXT,
    label: 'Within the next',
    formType: FilterInputType.SELECT,
  },
  {
    key: KeyType.LAST,
    label: 'Within the last',
    formType: FilterInputType.SELECT,
  },
  {
    key: KeyType.BETWEEN,
    label: 'Between',
    formType: FilterInputType.RANGE,
  },
  {
    key: KeyType.ON,
    label: 'On',
    formType: FilterInputType.PICKER,
  },
  {
    key: KeyType.OLDER,
    label: 'Older than',
    formType: FilterInputType.SELECT,
  },
] as const;
