import styled from 'styled-components';

import useMemberRole from '../hooks/useMemberRole';
import { ROLES } from '../models/data';

const DisableForRoles = ({ roles = [], children }) => {
  const role = useMemberRole();

  if (roles.includes(role)) {
    return <DisableForRoles.Disabled>{children}</DisableForRoles.Disabled>;
  }

  return children;
};

DisableForRoles.Disabled = styled.div`
  position: relative;
  opacity: 0.5;
  pointer-events: none;
`;

export const DisableForViewerRole = (props) => {
  return <DisableForRoles {...props} roles={[ROLES.VIEWER]} />;
};

export default DisableForRoles;
