import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const PendingReviewIcon = () => (
  <PendingReviewIcon.Wrapper>
    <SearchOutlined />
  </PendingReviewIcon.Wrapper>
);

PendingReviewIcon.Wrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #9fb3c6;
`;

export default PendingReviewIcon;
