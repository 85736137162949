import { gql } from '@apollo/client';

import { useLazyQuery } from '@graphql/hooks';
import { useCallback } from 'react';

type FetchTagsParams = {
  ids?: string[];
  name?: string;
  offset?: number;
  first?: number;
  queryOptions?: any;
};

type Tag = {
  _id: string;
  name: string;
};

type TagsQueryData = {
  tags: {
    nodes: Tag[];
    totalCount: number;
  };
};

type TagsQueryVariables = {
  input: {
    offset?: number;
    first?: number;
    filter?: {
      and: {
        name: string;
        operator: string;
        value: string[];
      }[];
    };
  };
};

const TAGS_QUERY = gql`
  query Tags($input: TagsInput) {
    tags(input: $input) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`;

const getIdsFilter = (ids: string[]) => {
  return {
    name: 'id',
    operator: 'in',
    value: ids,
  };
};

const getNameFilter = (name: string) => {
  return {
    name: 'name',
    operator: 'contains',
    value: name,
  };
};

export const useTags = () => {
  const [fetchTagsQuery, { data: queryData, loading, fetchMore }] =
    useLazyQuery<TagsQueryData, TagsQueryVariables>(TAGS_QUERY, {
      variables: {
        input: {
          offset: 0,
          first: 10,
        },
      },
      notifyOnNetworkStatusChange: true,
    });

  const fetchTags = useCallback(
    (params?: FetchTagsParams) => {
      const { ids = [], name, offset, first, queryOptions } = params || {};

      fetchTagsQuery({
        variables: {
          input: {
            offset,
            first,
            ...((ids.length || name) && {
              filter: {
                and: [
                  ...(ids.length ? [getIdsFilter(ids)] : []),
                  ...(name ? [getNameFilter(name)] : []),
                ],
              },
            }),
          },
        },
        ...queryOptions,
      });
    },
    [fetchTagsQuery],
  );

  return {
    fetchTags,
    tags: queryData?.tags?.nodes || [],
    tagsTotalCount: queryData?.tags?.totalCount || 0,
    loading,
    fetchMoreTags: fetchMore,
  };
};
