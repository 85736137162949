import { FolderOutlined } from '@ant-design/icons';
import styled from 'styled-components';
type ContextRecordCellProps = {
  name?: string;
};

export const ContextRecordCell = ({ name }: ContextRecordCellProps) => {
  return (
    <StyledContextCell>
      {name ? (
        <div>
          <FolderOutlined className="icon" />
          <span>{name}</span>
        </div>
      ) : (
        <span className="empty">None</span>
      )}
    </StyledContextCell>
  );
};

const StyledContextCell = styled.section`
  color: ${({ theme }) => theme.colors.mineShaft};

  .icon {
    margin-right: 8px;
  }

  .empty {
    color: ${({ theme }) => theme.colors.lightTextGray};
  }
`;
