import { notification } from 'antd';
import { getApps } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { path, pathOr } from 'ramda';
import React from 'react';
import { all, call, put, putResolve, takeEvery } from 'redux-saga/effects';

import client from '@graphql/client';
import { push } from '@modules/router/actions';

import {
  identifyUser,
  resetTracking,
  trackEvent,
} from '@common/utils/track-helpers';
import {
  AUTH_CONSUME,
  LOGIN,
  LOGOUT,
  SEND_RESET_PASSWORD_EMAIL,
  logout,
} from '../actions';
import { PROPAGATE_LOGOUT_TO_ALL_TABS } from '../constants';

function* loginSuccessSaga(action) {
  const user = path(['payload', 'data', 'authConsume', 'user'], action);

  (window.dataLayer || []).push({
    userId: user?._id,
    userEmail: user?.email,
    userName: user?.profile?.name,
    userPhone: user?.profile?.phone,
    userAvatar: user?.profile?.avatar,
    userCreatedAt: Date.parse(user?.createdAt),
  });

  yield call(identifyUser, user?._id);
  yield call(trackEvent, 'User logged in');
}

function* loginFailSaga(action) {
  yield call(notification.error, {
    message: pathOr('Unable to login', ['error', '0', 'message'], action),
  });

  // firebase auth might have been successfull, meaning `user` exists, while TL auth failed through AUTH_CONSUME
  yield call(logoutFirebase);
}

function* logoutFirebase() {
  const apps = getApps();
  const isFirebaseAppInitialised = apps?.length;
  if (!isFirebaseAppInitialised) {
    console.error(
      'Unable to sign out from Firebase: no Firebase App is initialised',
    );
    return;
  }
  const auth = getAuth(apps[0]);
  yield call(signOut, auth);
}

function* logoutSaga({ meta }) {
  const { payload } = meta?.previousAction || {};
  yield call(logoutFirebase);
  localStorage.setItem(PROPAGATE_LOGOUT_TO_ALL_TABS, 'true');
  yield call(trackEvent, 'User logged out');
  yield call(resetTracking);
  const redirectRoute = payload?.redirectTo || '/login';
  yield put(push(redirectRoute));
  client.clearStore();
}

function* forgotPasswordSuccessSaga() {
  yield call(notification.success, {
    message: React.createElement(
      'span',
      { 'data-cy': 'authForgotPasswordNotificationOnSuccess' },
      'Recovery link sent. Please check your email.',
    ),
  });
  yield put(push('/login'));
}

function* requestFailSaga(payload) {
  const error = payload?.error?.[0];

  if (payload?.type === `${LOGOUT}_FAIL`) {
    return;
  }

  if (/ERR002|ERR003|ERR004|ERR005/.test(error?.message)) {
    console.warn('Loggin out the user...');
    yield putResolve(logout());
    yield put(push('/login'));
  }
}

function* logoutFailedSaga() {
  // Logout might fails in case of token not valid
  // we want to force LOGOUT_SUCCESS in order to trigger cleanup of reducers and local storage
  // logout action perfromed by the client must be addressed independently on the backend response
  yield put({
    type: `${LOGOUT}_SUCCESS`,
  });
}

function* authSagas() {
  yield all([
    yield takeEvery((action) => /_FAIL$/.test(action.type), requestFailSaga),
    yield takeEvery(
      [`${LOGIN}_SUCCESS`, `${AUTH_CONSUME}_SUCCESS`],
      loginSuccessSaga,
    ),
    yield takeEvery([`${LOGIN}_FAIL`, `${AUTH_CONSUME}_FAIL`], loginFailSaga),
    yield takeEvery(
      `${SEND_RESET_PASSWORD_EMAIL}_SUCCESS`,
      forgotPasswordSuccessSaga,
    ),
    yield takeEvery(`${LOGOUT}_SUCCESS`, logoutSaga),
    yield takeEvery(`${LOGOUT}_FAIL`, logoutFailedSaga),
  ]);
}

export default authSagas;
