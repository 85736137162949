import { FullStory } from '@fullstory/browser';
import { fullStoryIntegration } from '@sentry/fullstory';
import type { BrowserOptions } from '@sentry/react';

import environment from '@config/EnvironmentConfig';

const SentryConfig = {
  dsn: import.meta.env.VITE_SENTRY_DATA_SOURCE_NAME,
  enabled: String(import.meta.env.VITE_SENTRY_ENABLED).toLowerCase() === 'true',
  environment,
  release: import.meta.env.VITE_PLATFORM_VERSION,
  dist: environment,
  autoSessionTracking: true,
  integrations: [
    fullStoryIntegration('trustlayer-inc', { client: FullStory }) as any,
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 6,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Assertion failed: Annotation ID',
    "Can't execute code from a freed script",
    // ignore network errors on client related to Firebase
    'Firebase: Error (auth/network-request-failed)',
    // ignore network errors on client when fetching resources
    'NetworkError',
    // ignore network errors when lazy loading files on client
    'Element type is invalid. Received a promise that resolves to: undefined. Lazy element type must resolve to a class or function',
    /**
     * ignore network errors on fetch api
     * @note all fetch passes through fs.js, a full-story file on their domain,
     * because FS overrides window.fetch therefore we cannot add their domain in the denyUrls list
     */
    'Failed to fetch',
    'ResizeObserver loop limit exceeded',
    'Uncaught ResizeObserver loop completed with undelivered notifications.',
    'Current user not active (ERR003)',
    /Tag \".*\" already exists/,
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
} satisfies BrowserOptions;

export default SentryConfig;
