import {
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  getRedirectResult as getRedirectResultFirebase,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  signInWithPopup as signInWithPopupFirebase,
  signInWithRedirect as signInWithRedirectFirebase,
  verifyPasswordResetCode,
} from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';

import { FIREBASE_ERRORS } from '../constants';

export const FirebaseAuthContext = React.createContext();

export const FirebaseProvider = {
  microsoft: OAuthProvider,
  google: GoogleAuthProvider,
  github: GithubAuthProvider,
  saml: SAMLAuthProvider,
  oidc: OAuthProvider,
};

const getProviderInstance = ({ providerType, id }) => {
  const providerId = providerType === 'microsoft' ? 'microsoft.com' : id;

  return providerId
    ? new FirebaseProvider[providerType](providerId)
    : new FirebaseProvider[providerType]();
};

/**
 * @note using React18 with StrictMode it mounts twice the component in dev mode,
 * so the loading status doesn't work as expected in dev mode, but it works in integration and production.
 *
 * @link docs: https://react.dev/blog/2022/03/29/react-v18#new-strict-mode-behaviors
 *
 *
 * please avoid using the useEffectOnce workaround
 *
 * @links video: https://www.youtube.com/watch?v=MXSuOR2yRvQ&ab_channel=Olli
 */

const FirebaseAuthProvider = ({ children, firebaseAuth, firebaseApp }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef(false);

  const signInWithPopup = async ({ providerType, id, tenantId }) => {
    setIsLoading(true);

    // Switch to dedicated tenantId for the organization if present.
    if (tenantId) {
      console.log(`Using tenantId ${tenantId}`);
      firebaseAuth.tenantId = tenantId;
    }

    const providerInstance = getProviderInstance({ providerType, id });

    try {
      const { user } = await signInWithPopupFirebase(
        firebaseAuth,
        providerInstance,
      );
      const token = await user.getIdToken();
      return { token };
    } catch (err) {
      throw Error(FIREBASE_ERRORS[err.code] || err.message);
    } finally {
      !isUnmounted.current && setIsLoading(false);
    }
  };

  const signInWithEmailAndPassword = async ({ email, password, tenantId }) => {
    setIsLoading(true);

    // Switch to dedicated tenantId for the organization if present.
    if (tenantId) {
      console.log(`Using tenantId ${tenantId}`);
      firebaseAuth.tenantId = tenantId;
    }

    try {
      const { user } = await signInWithEmailAndPasswordFirebase(
        firebaseAuth,
        email,
        password,
      );
      const token = await user.getIdToken();
      return { token };
    } catch (err) {
      throw Error(FIREBASE_ERRORS[err.code] || err.message);
    } finally {
      !isUnmounted.current && setIsLoading(false);
    }
  };

  const signInWithRedirect = async ({ providerType, id, tenantId }) => {
    setIsLoading(true);

    // Switch to dedicated tenantId for the organization if present.
    if (tenantId) {
      console.log(`Using tenantId ${tenantId}`);
      firebaseAuth.tenantId = tenantId;
    }

    const providerInstance = getProviderInstance({ providerType, id });

    try {
      return await signInWithRedirectFirebase(firebaseAuth, providerInstance);
    } catch (error) {
      throw Error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectResult = async () => {
    setIsLoading(true);
    try {
      return await getRedirectResultFirebase(firebaseAuth);
    } catch (error) {
      throw Error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  return (
    <FirebaseAuthContext.Provider
      value={{
        isLoading,
        app: firebaseApp,
        auth: firebaseAuth,
        signInWithEmailAndPassword,
        signInWithPopup,
        signInWithRedirect,
        verifyPasswordResetCode,
        handleRedirectResult,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthProvider;
