import { CreateOrEditRequirementForm } from '@modules/compliance-profile/containers/CreateOrEditRequirementForm';
import { useState } from 'react';
import { AddItemButton } from '../components/AddItemButton';

export const AddRequirementForm = ({
  subjectId,
  onConfirm,
}: { subjectId: string; onConfirm: () => void }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  return isFormVisible ? (
    <CreateOrEditRequirementForm
      subjectId={subjectId}
      onConfirm={() => {
        setIsFormVisible(false);
        onConfirm?.();
      }}
      onClose={() => {
        setIsFormVisible(false);
      }}
    />
  ) : (
    <AddItemButton
      onClick={() => {
        setIsFormVisible(true);
      }}
    >
      Add Requirement
    </AddItemButton>
  );
};
