import { gql } from '@apollo/client';

export const EMAIL_LAYOUT_FRAGMENT = gql`
  fragment EmailLayout on EmailLayout {
    _id
    name
    mjml
    organization
    selected
    type
    deletedAt
    deletedBy
  }
`;
