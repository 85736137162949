import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { ADD_ON } from '../constants';

export const useIsBaseAddOnEnabled = () => {
  const features = useFeatures();

  const isBaseAddOnEnabled = features.includes(ADD_ON.BASE);

  return { isBaseAddOnEnabled };
};
