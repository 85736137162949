const getAssociatedProvidersNames = (list) => {
  const idExpr = /(?<provider>\w+)(:(?<instance>\w+))?\/(?<id>.+)/;
  const result = list.map((item) => {
    const match = idExpr.exec(item);
    if (match) {
      const providerName =
        match.groups?.provider?.[0]?.toUpperCase() +
        match.groups?.provider?.slice(1);
      return `${providerName} (${match.groups?.id})`;
    }
    return item;
  });
  return result.join(' and ');
};

export default getAssociatedProvidersNames;
