import { gql } from '@apollo/client';

import { CustomFieldsEntities, namespace } from '../constants';
import { CUSTOM_FIELD_FRAGMENT } from '../fragments';

export const FETCH_PROJECT_CUSTOM_FIELDS = `${namespace}/FETCH_PROJECT_CUSTOM_FIELDS`;
export const FETCH_PARTY_CUSTOM_FIELDS = `${namespace}/FETCH_PARTY_CUSTOM_FIELDS`;

const FETCH_CUSTOM_FIELDS_QUERY = gql`
  query listCustomFields($input: CustomFieldsInput) {
    customFields(input: $input) {
      totalCount
      nodes {
        ...CustomField
      }
    }
  }
  ${CUSTOM_FIELD_FRAGMENT}
`;

const fetchCustomFields = (entity, paginationParams) => {
  const type =
    entity === CustomFieldsEntities.party
      ? FETCH_PARTY_CUSTOM_FIELDS
      : FETCH_PROJECT_CUSTOM_FIELDS;
  return {
    type,
    payload: {
      graphql: {
        fetchPolicy: 'no-cache',
        query: FETCH_CUSTOM_FIELDS_QUERY,
        variables: {
          input: {
            filter: {
              associatedEntities: [entity],
            },
            ...paginationParams,
          },
        },
      },
    },
  };
};

export const fetchPartyCustomFields = (paginationParams = {}) =>
  fetchCustomFields(CustomFieldsEntities.party, paginationParams);

export const fetchProjectCustomFields = (paginationParams = {}) =>
  fetchCustomFields(CustomFieldsEntities.project, paginationParams);
