const CheckIcon = ({ width, height, muted }) => (
  <svg width={width} height={height} viewBox="0 0 14 10">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Compliance-Summary-Items"
        transform="translate(0.000000, -32.000000)"
        fillRule="nonzero"
      >
        <g id="Group-2">
          <g
            id="fa-solid:check-copy"
            transform="translate(0.000000, 29.000000)"
          >
            <path
              d="M4.75502125,12.650027 L0.205016802,8.58232434 C-0.0683389339,8.33794439 -0.0683389339,7.94171006 0.205016802,7.69730567 L1.19494355,6.812287 C1.46829929,6.5678826 1.91154191,6.5678826 2.18489764,6.812287 L5.24999829,9.55246164 L11.8151024,3.68328496 C12.0884581,3.43890501 12.5317007,3.43890501 12.8050565,3.68328496 L13.7949832,4.56830363 C14.0683389,4.81268358 14.0683389,5.20891791 13.7949832,5.4533223 L5.74497534,12.6500514 C5.47159226,12.8944314 5.02837698,12.8944314 4.75502125,12.650027 L4.75502125,12.650027 Z"
              id="Path"
              fill={muted ? '#BBBBBB' : '#00BA00'}
            />
            <rect
              id="Rectangle"
              fillOpacity="0"
              fill="#000000"
              x="0"
              y="0"
              width="14"
              height="14"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CheckIcon.defaultProps = {
  width: 12,
  height: 12,
  muted: false,
};

export default CheckIcon;
