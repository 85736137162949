import Icon from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';

import usePrevious from '@common/hooks/usePrevious';
import NotificationIcon from '@common/icons/NotificationIcon';
import { useLazyQuery } from '@graphql/hooks';
import { getIsDrawerOpen } from '@modules/notification/selectors';
import { getOrganizationMemberByUserId } from '@modules/organization-member/selectors';
import { getMyId } from '@modules/user/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { openNotificationDrawer } from '../../actions';
import { NOTIFICATION_UPDATES_SUBSCRIPTION } from '../../subscriptions';

import { NOTIFICATION_BELL_TOTAL_COUNT_QUERY } from './queries';

const NotificationsBell = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getMyId);
  const recipient = useAppSelector((state) =>
    getOrganizationMemberByUserId(state, userId),
  );
  const isNotificationDrawerOpened = useAppSelector(getIsDrawerOpen);
  const wasNotificationDrawerOpened = usePrevious(isNotificationDrawerOpened);

  const [fetchUnreadNotificationsTotalCount, { data, subscribeToMore }] =
    useLazyQuery(NOTIFICATION_BELL_TOTAL_COUNT_QUERY, {
      variables: {
        filter: {
          isRead: false,
        },
      },
    });

  const unreadTotalCount = data?.notifications?.totalCount || 0;

  const handleOpenNotificationDrawer = () => dispatch(openNotificationDrawer());

  useEffect(() => {
    fetchUnreadNotificationsTotalCount();
  }, [fetchUnreadNotificationsTotalCount]);

  useEffect(() => {
    if (!recipient?._id) {
      return;
    }

    subscribeToMore({
      document: NOTIFICATION_UPDATES_SUBSCRIPTION,
      variables: { memberId: recipient?._id },
      updateQuery: (prevData) => {
        const { totalCount } = prevData.notifications || {};
        const newTotalCount = totalCount ?? 0 + 1;

        return {
          notifications: { totalCount: newTotalCount },
        };
      },
    });
  }, [subscribeToMore, recipient?._id]);

  useEffect(() => {
    // best would be to have a subscription listening for changes but, since we don't have it yet,
    // we are refreshing the list whenever the notification drawer gets closed
    if (wasNotificationDrawerOpened && !isNotificationDrawerOpened) {
      fetchUnreadNotificationsTotalCount();
    }
  }, [
    isNotificationDrawerOpened,
    wasNotificationDrawerOpened,
    fetchUnreadNotificationsTotalCount,
  ]);

  return (
    <Tooltip title="Notifications">
      <StyledIconWrapper>
        <Badge dot={unreadTotalCount > 0}>
          <StyledNotificationIcon
            component={NotificationIcon}
            onClick={handleOpenNotificationDrawer}
          />
        </Badge>
      </StyledIconWrapper>
    </Tooltip>
  );
};

const StyledIconWrapper = styled.div`
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
`;

const StyledNotificationIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.65);

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export default NotificationsBell;
