import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { SCHEDULED_REPORT_FRAGMENT } from '../fragments';

export const UPDATE_SCHEDULED_REPORT = `${namespace}/UPDATE_SCHEDULED_REPORT`;

const UPDATE_SCHEDULED_REPORT_MUTATION = gql`
  mutation updateScheduledReport($payload: UpdateScheduledReportInput!) {
    updateScheduledReport(data: $payload) {
      ...ScheduledReport
    }
  }
  ${SCHEDULED_REPORT_FRAGMENT}
`;

export const updateScheduledReport = (payload) => ({
  type: UPDATE_SCHEDULED_REPORT,
  payload: {
    key: 'updateScheduledReport',
    graphql: {
      mutation: UPDATE_SCHEDULED_REPORT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
