import { lazy } from 'react';
/**
 * Trying to import a module.
 * @param {Function} fn - import function.
 * @param {number} retriesLeft - retries count.
 * @param {number} interval - timeout interval.
 * @returns {Promise} import result.
 */
const retry = (fn, retriesLeft = 5, interval = 567) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          console.warn(
            `Loading error. Retrying (${retriesLeft} retries left)...`,
          );
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export const lazyWithRetry = (fn) =>
  lazy(() =>
    retry(fn).catch((err) => {
      console.error(err);

      if (
        window.confirm(
          'A network error occurred. Click "Ok" to reload the current page.',
        )
      ) {
        window.location.reload();
      }

      return err;
    }),
  );
