import { Button, Card } from 'antd';
import styled from 'styled-components';

export const AppOutdatedCard = ({
  latestVersion,
}: { latestVersion: string }) => {
  return (
    <StyledCard bordered>
      <p className="title">Update Available</p>
      <p className="content">
        The new version v{latestVersion} of the TrustLayer app is now available.
      </p>
      <Button
        className="update-button"
        type="primary"
        key="back"
        onClick={() => {
          window.location.reload();
        }}
      >
        Update
      </Button>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 8px 12px;

    .title {
      font-size: 12px;
      font-weight: 600;
    }

    .content {
      font-size: 12px;
      font-weight: 400;
    }

    .update-button {
      float: right;
    }
  }
`;
