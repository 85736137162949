import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_LAYOUT_FRAGMENT } from '../fragments';

export const UPDATE_EMAIL_LAYOUT = `${namespace}/UPDATE_EMAIL_LAYOUT`;

const UPDATE_EMAIL_LAYOUT_MUTATION = gql`
  mutation updateEmailLayout($payload: UpdateEmailLayoutInput!) {
    updateEmailLayout(data: $payload) {
      ...EmailLayout
    }
  }
  ${EMAIL_LAYOUT_FRAGMENT}
`;

export const updateEmailLayout = (payload) => ({
  type: UPDATE_EMAIL_LAYOUT,
  payload: {
    key: 'updateEmailLayout',
    graphql: {
      mutation: UPDATE_EMAIL_LAYOUT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
