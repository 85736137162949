import { ThunderboltOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const TriggerIcon = () => {
  return (
    <StyledIconWrapper>
      <ThunderboltOutlined style={{ color: '#EB2F96' }} />
    </StyledIconWrapper>
  );
};

const StyledIconWrapper = styled.span`
  padding: 5px 7px;
  border-radius: 8px;
  background-color: #fff0f6;
`;
