import type { PropsWithChildren } from 'react';
import {
  DragDropContext,
  Draggable,
  type DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import type { ComplianceProfileEntity } from '../type';

export type OnChangedParams = {
  type: ComplianceProfileEntity;
  itemId: string;
  currentItemIndex: number;
  newItemIndex: number;
};

type DnDContextProps = PropsWithChildren<{
  onChanged: (result: OnChangedParams | undefined) => void;
}>;

type DroppableProps = PropsWithChildren<{
  id: string;
  type: string;
}>;

type DraggableProps = PropsWithChildren<{
  id: string;
  index: number;
}>;

const getDraggingStyle = (isDragging: boolean, draggableStyle: any) => {
  return {
    backgroundColor: isDragging ? '#ffffffc1' : 'inerith',
    ...draggableStyle,
  };
};

export const ComplianceProfileDragAndDrop = ({
  onChanged,
  children,
}: DnDContextProps) => {
  const handleDragEnd = (result: DropResult) => {
    const { destination, type, source, draggableId } = result;

    const formattedResult = destination
      ? {
          type: type as ComplianceProfileEntity,
          itemId: draggableId,
          currentItemIndex: source.index,
          newItemIndex: destination?.index ?? 0,
        }
      : undefined;

    onChanged(formattedResult);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>{children}</DragDropContext>
  );
};

ComplianceProfileDragAndDrop.Droppable = ({
  id,
  type,
  children,
}: DroppableProps) => {
  return (
    <Droppable droppableId={id} type={type}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

ComplianceProfileDragAndDrop.Draggable = ({
  id,
  index,
  children,
}: DraggableProps) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getDraggingStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};
