import { CheckOutlined } from '@ant-design/icons';
import { Badge, List, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

const dateFormat = 'MMM DD, hh:mm A';

const NotificationItem = ({
  BadgeIcon,
  badgeStyles,
  NotificationIcon,
  notificationId,
  createdAt,
  isRead,
  onNotificationStatusChanged,
  children,
}) => {
  return (
    <StyledNotificationWrapper>
      <StyledIconMessageWrapper>
        <Badge
          count={
            <StyledBadgeIconWrapper badgeStyles={badgeStyles}>
              <BadgeIcon />
            </StyledBadgeIconWrapper>
          }
        >
          <StyledIconWrapper>
            <NotificationIcon />
          </StyledIconWrapper>
        </Badge>
        <StyledMessageWrapper>
          {children}
          <StyledDate>{moment(createdAt).format(dateFormat)}</StyledDate>
        </StyledMessageWrapper>
      </StyledIconMessageWrapper>
      <div>
        {isRead ? (
          <Tooltip placement="left" title="Mark as unread">
            <StyledReadIcon
              onClick={() => onNotificationStatusChanged(notificationId, false)}
              data-cy="markNotificationAsUnread"
            >
              •
            </StyledReadIcon>
          </Tooltip>
        ) : (
          <Tooltip placement="left" title="Mark as read">
            <span data-cy="markNotificationAsRead">
              <StyledCheckOutlined
                onClick={() =>
                  onNotificationStatusChanged(notificationId, true)
                }
              />
            </span>
          </Tooltip>
        )}
      </div>
    </StyledNotificationWrapper>
  );
};

export default NotificationItem;

const StyledNotificationWrapper = styled(List.Item)`
  border-bottom: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

const StyledIconMessageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBadgeIconWrapper = styled.span`
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: ${({ badgeStyles }) => badgeStyles.fontSize || '10px'};
  color: ${({ badgeStyles }) => badgeStyles.color || 'white'};
  background-color: ${({ badgeStyles }) => badgeStyles.bgColor};
`;

const StyledIconWrapper = styled.span`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
`;

const StyledMessageWrapper = styled.div`
  margin: 0 15px 0 25px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  label {
    font-size: 12px;
    opacity: 0.6;
  }
`;

const StyledDate = styled.div`
  font-size: 12px;
  opacity: 0.6;
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  margin-left: auto;
  cursor: pointer;
`;

const StyledReadIcon = styled.span`
  margin-left: auto;
  font-size: 25px;
  cursor: pointer;
`;
