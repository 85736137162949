import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_DELETE_PROJECTS = `${namespace}/BULK_DELETE_PROJECTS`;

const BULK_DELETE_PROJECTS_MUTATION = gql`
  mutation bulkDeleteProjects($payload: BulkDeleteProjectsInput) {
    bulkDeleteProjects(data: $payload) {
      scheduled
    }
  }
`;

export const bulkDeleteProjects = (payload) => ({
  type: BULK_DELETE_PROJECTS,
  payload: {
    key: 'bulkDeleteProjects',
    graphql: {
      mutation: BULK_DELETE_PROJECTS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
