import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { RemoveRequestRecordSubjectWaiverOverrideMutation } from '@graphql/types/graphql';
import { NonWaived } from '@trustlayer/common';

export const REMOVE_REQUEST_RECORD_SUBJECT_WAIVER_OVERRIDE_MUTATION = graphql(`
  mutation RemoveRequestRecordSubjectWaiverOverride($data: ToggleRequestRecordSubjectsWaiverInput!) {
    toggleRequestRecordSubjectsWaiver(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

type RemoveWaiverOverrideSubjectVariables = {
  variables: {
    requestId: string;
    code: string;
  };
  onCompleted?: (res: RemoveRequestRecordSubjectWaiverOverrideMutation) => void;
  onError?: (error: Error) => void;
};

export function useRemoveWaiveOverrideSubjectMutation() {
  const [toggleWaiverOverrideSubject, { loading }] = useMutation(
    REMOVE_REQUEST_RECORD_SUBJECT_WAIVER_OVERRIDE_MUTATION,
  );

  function removeWaiverOverrideSubject({
    variables: { requestId, code },
    onCompleted,
    onError,
  }: RemoveWaiverOverrideSubjectVariables) {
    return toggleWaiverOverrideSubject({
      variables: {
        data: {
          id: requestId!,
          subjects: [
            {
              code: code,
              status: NonWaived,
            },
          ],
        },
      },
      onCompleted,
      onError,
    });
  }

  return {
    removeWaiverOverrideSubject,
    loading,
  };
}
