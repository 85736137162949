import { Modal, type ModalProps } from 'antd';
import styled from 'styled-components';
import Spinner from '../Spinner';

export const ModalConfirm = ({
  icon,
  content,
  title,
  loading = false,
  ...props
}: Omit<ModalProps, 'title'> & {
  title?: string;
  icon?: React.ReactNode;
  content?: React.ReactNode;
  loading?: boolean;
}) => {
  const titleWithIcon = (
    <StyledHeader>
      <StyledIcon>{icon}</StyledIcon>
      {title}
    </StyledHeader>
  );

  const modalProps = !loading
    ? {
        title: titleWithIcon,
        ...props,
      }
    : { footer: false, open: props.open, onCancel: props.onCancel };

  const modalChildren = !loading ? props.children : <Spinner />;

  return (
    <Modal
      bodyStyle={{ padding: '0 24px', ...props.bodyStyle }}
      {...modalProps}
    >
      {modalChildren}
    </Modal>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 16px;
`;

const StyledIcon = styled.div`
  color: ${({ theme }) => theme.colors.yellow};
`;
