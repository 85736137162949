import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_DATA_FRAGMENT } from '../fragments';

export const UPDATE_PARTY = `${namespace}/UPDATE_PARTY`;

const UPDATE_MUTATION = gql`
  mutation updatePartyData($payload: UpdatePartyDataInput!) {
    updatePartyData(data: $payload) {
      ...PartyData
    }
  }
  ${PARTY_DATA_FRAGMENT}
`;

export const updateParty = (payload) => ({
  type: UPDATE_PARTY,
  payload: {
    key: 'updatePartyData',
    graphql: {
      mutation: UPDATE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
