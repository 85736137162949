import { Divider, Tree } from 'antd';
import styled from 'styled-components';

const ProjectRequirementsTreeView = ({
  requirementsTreeData,
  documentChecklistsTreeData,
  checkedRequirements = [],
  checkedDocumentChecklists = [],
  onCheckRequirement,
  onCheckDocumentChecklist,
}) => (
  <ProjectRequirementsTreeView.Wrapper>
    <Tree
      data-cy="requirementsTree"
      blockNode={true}
      selectable={false}
      checkable
      defaultExpandAll
      checkedKeys={checkedRequirements}
      treeData={requirementsTreeData}
      onCheck={onCheckRequirement}
    />
    {Boolean(documentChecklistsTreeData.length) && (
      <>
        <Divider orientation="left">Document checklists</Divider>
        <Tree
          data-cy="documentChecklistsTree"
          blockNode={true}
          selectable={false}
          checkable
          defaultExpandAll
          checkedKeys={checkedDocumentChecklists}
          treeData={documentChecklistsTreeData}
          onCheck={onCheckDocumentChecklist}
        />
      </>
    )}
  </ProjectRequirementsTreeView.Wrapper>
);

ProjectRequirementsTreeView.Wrapper = styled.div`
  .ant-divider-inner-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 13px;
    font-weight: 400;
  }

  .ant-tree-treenode {
    font-size: 13px;
  }
`;

ProjectRequirementsTreeView.Requirement = styled.div`
  padding-left: 43px;
  margin: 2.5px 0;
`;

export default ProjectRequirementsTreeView;
