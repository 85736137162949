import { useCallback, useState } from 'react';

type Patch<T> = Partial<T> | ((prevState: T) => Partial<T>);

const useSetState = <T extends object>(
  initialState: T = {} as T,
): [T, (patch: Patch<T>) => void, () => void] => {
  const [state, set] = useState<T>(initialState);

  const setState = useCallback((patch: Patch<T>) => {
    set((prevState) =>
      Object.assign(
        {},
        prevState,
        patch instanceof Function ? patch(prevState) : patch,
      ),
    );
  }, []);

  const setInitialState = useCallback(() => {
    set(initialState);
  }, [initialState]);

  return [state, setState, setInitialState];
};

export default useSetState;
