import type { SortDirection } from '@common/components/Table';
import type { LegacyDocumentsReportParams } from '@modules/report/types';
import {
  getArrayFilter,
  getSetTypeFilter,
  getTableDateFilterFromReportFilter,
  getTextTypeFilter,
} from '@modules/report/utils';
import {
  APPLIES_TO_ALL_PROJECTS_KEY,
  APPLIES_TO_ALL_PROJECTS_STATUS,
} from '../../../constants';
import type { TableFiltersModel } from '../types';

type TableParams = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
};

export const mapLegacyDocumentsReportParamsToTableParams = (
  reportParams: LegacyDocumentsReportParams,
): TableParams => {
  // eslint-disable-next-line fp/no-let
  let tableFilters: TableFiltersModel = {};

  if (reportParams.status) {
    tableFilters = {
      ...tableFilters,
      archiveStatus: getSetTypeFilter(reportParams.status as string[] | string),
    };
  }

  if (reportParams.verification) {
    tableFilters = {
      ...tableFilters,
      verification: getSetTypeFilter(
        reportParams.verification as string[] | string,
      ),
    };
  }

  if (reportParams.flag) {
    tableFilters = {
      ...tableFilters,
      severity: getSetTypeFilter(reportParams.flag),
    };
  }

  if (reportParams.reviewStatus) {
    tableFilters = {
      ...tableFilters,
      reviewStatus: getSetTypeFilter(reportParams.reviewStatus),
    };
  }

  if (reportParams.subject) {
    tableFilters = {
      ...tableFilters,
      subjects: getArrayFilter(reportParams.subject),
    };
  }

  if (reportParams.associatedProjects) {
    const projectReportFilter = getArrayFilter(reportParams.associatedProjects);

    const projectIds = projectReportFilter.filter(
      (projectId) => projectId !== APPLIES_TO_ALL_PROJECTS_KEY,
    );

    if (projectIds.length) {
      tableFilters = {
        ...tableFilters,
        projects: projectIds,
      };
    }

    if (projectReportFilter.includes(APPLIES_TO_ALL_PROJECTS_KEY)) {
      tableFilters = {
        ...tableFilters,
        appliesToAllProjects: getSetTypeFilter(
          APPLIES_TO_ALL_PROJECTS_STATUS.Applied,
        ),
      };
    }
  }

  if (reportParams.appliesToAllProjects) {
    tableFilters = {
      ...tableFilters,
      appliesToAllProjects: getSetTypeFilter(reportParams.appliesToAllProjects),
    };
  }

  if (reportParams.type) {
    tableFilters = {
      ...tableFilters,
      type: getArrayFilter(reportParams.type),
    };
  }

  if (reportParams.requirementId) {
    tableFilters = {
      ...tableFilters,
      requirementId: getTextTypeFilter(reportParams.requirementId),
    };
  }

  if (reportParams.name) {
    tableFilters = {
      ...tableFilters,
      friendlyName: getTextTypeFilter(reportParams.name),
    };
  }

  const expirationDateFilter = getTableDateFilterFromReportFilter(
    reportParams,
    'expirationDate',
  );

  if (expirationDateFilter) {
    tableFilters = {
      ...tableFilters,
      expirationDate: expirationDateFilter,
    };
  }

  const uploadDateFilter = getTableDateFilterFromReportFilter(
    reportParams,
    'uploadDate',
  );

  if (uploadDateFilter) {
    tableFilters = {
      ...tableFilters,
      createdAt: uploadDateFilter,
    };
  }

  const tableSorting = {
    direction: reportParams.sortDirection,
    columnId: reportParams.sortField,
  };

  return {
    filters: tableFilters,
    sorting: tableSorting,
  };
};
