import { FileTextOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, message as notify } from 'antd';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import qs from 'query-string';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ActionsMenuWithButtons } from '@common/components/ActionsMenuWithButtons';
import { PageTitle } from '@common/components/PageTitleWrapper';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import {
  type CustomSelectionChangedEvent,
  FilterButton,
  SortButton,
  type SortDirection,
  type SortingItems,
} from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';
import {
  type Document,
  type ReportEntity as ViewEntity,
  ReportType as ViewType,
} from '@graphql/types/graphql';
import { downloadInit } from '@modules/download/actions';
import {
  bulkDetachDocumentsFromProject,
  detachDocumentsFromProject,
} from '@modules/project/actions';
import UploadDocumentModal from '@modules/request/components/UploadDocumentModal';
import { DEFAULT_VIEWS, ViewsSelector } from '@modules/view';

import {
  archiveDocument,
  bulkArchiveDocuments,
  bulkDeleteDocuments,
  bulkDetachDocumentsFromParty,
  bulkReviewDocuments,
  deleteDocument,
  detachDocumentsFromParty,
  reviewDocument,
} from '../../actions';
import {
  ARCHIVE_STATUS,
  type ActionType,
  DOCUMENT_ACTION_TYPES,
  REVIEW_STATUS,
  REVIEW_STATUS_LABEL_MAP,
  type ReviewStatus,
  getBulkEventAnalyticsMessage,
  getBulkEventDefaultMessage,
} from '../../constants';
import { useDownloadDocument } from '../../hooks';
import AddDocumentTypesModal from '../../modals/AddDocumentTypesModal';
import AddRequirementValueModal from '../../modals/AddRequirementValueModal';
import AssignDocumentsToPartyModal from '../../modals/AssignDocumentModal';
import AssignDocumentToPartyWithPreviewModal from '../../modals/AssignOneDocumentModal';
import AssignDocumentsToProjectModal from '../../modals/AssociateToProjectModal';
import BulkMatchRequirementsModal from '../../modals/BulkMatchRequirementsModal';
import { getUniqueDocumentFileName } from '../../utils/document-helpers';
import { type DocumentListRef, DocumentsList } from '../DocumentsList';
import type { TableFiltersModel } from '../DocumentsList/types';
import {
  mapLegacyDocumentsReportParamsToTableParams,
  mapTableFiltersToLegacyDocumentsFilters,
  mapTableParamsToLegacyDocumentsReportParams,
} from '../DocumentsList/utils';

import { trackEvent } from '@common/utils/track-helpers';
import type { LegacyDocumentsReportParams } from '@modules/report/types';
import { getActionsMenuItems } from './DocumentsSection.utils';
import { RemoveTypesModal } from './containers/RemoveTypesModal';
import { useBulkActions } from './hooks';

export type ActionOptions = {
  job?: ReviewStatus;
};

export type TableState = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
  pagination: {
    currentPage?: number;
    pageSize?: number;
  };
  reportId?: string;
};

type DocumentsWrapperProps = {
  partyId?: string;
  projectId: string;
  organizationSlugifyName: string;
  excludedActions?: ActionType[];
  initialState?: TableState;
  onDocumentsDeleteSuccess?: () => void;
  onStateChanged?: (params: TableState) => void;
};

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedDocumentsIds: string[];
  selectedDocumentId?: string;
  selectedRowsData: any[];
};

const DEFAULT_PARTY_CODE = 'every';

const sortingInitialState = {
  columnId: 'createdAt',
  direction: 'desc' as const,
};

const filterInitialState = {
  archiveStatus: {
    filterType: 'set' as const,
    values: [ARCHIVE_STATUS.NonArchived],
  },
};

const paginationInitialState = {
  pageSize: 12,
  currentPage: 0,
};

const DEFAULT_TABLE_STATE = {
  filters: filterInitialState,
  sorting: sortingInitialState,
  pagination: paginationInitialState,
  reportId: undefined,
};

/**
 * @note since the legacy code assumes that the archive status is "nonarchived" if not present in the filters,
 * to keep the same behavior this function set explicitly the archive status to "nonarchived" if not present in the filters
 */
const setArchiveStatusFilter = (
  filters: TableFiltersModel,
): TableFiltersModel => {
  if (!filters.archiveStatus) {
    return {
      ...filters,
      archiveStatus: {
        filterType: 'set',
        values: [ARCHIVE_STATUS.NonArchived],
      },
    };
  }

  return filters;
};

const initState = (initialState?: TableState): TableState => {
  if (!initialState) {
    return DEFAULT_TABLE_STATE;
  }

  if (isEmpty(initialState.filters)) {
    return DEFAULT_TABLE_STATE;
  }

  const normalizedFilters = setArchiveStatusFilter(initialState.filters);

  return {
    ...initialState,
    filters: normalizedFilters,
  };
};

export const DocumentsWrapper = ({
  partyId,
  projectId,
  initialState,
  organizationSlugifyName,
  onDocumentsDeleteSuccess,
  excludedActions,
  onStateChanged,
}: DocumentsWrapperProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef<DocumentListRef>(null);
  const { downloadDocument } = useDownloadDocument();

  useBulkActions({
    onSubscriptionUpdate: ({ eventType }) => {
      refreshDocumentsTable();
      notify.success(
        getBulkEventDefaultMessage({ type: eventType, result: {} }),
      );
      trackEvent(getBulkEventAnalyticsMessage({ type: eventType, result: {} }));
    },
    onSubscriptionError: (error) => {
      notify.error(error.message);
    },
  });

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
    activeFilters: 0,
  });

  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedDocumentsIds: [],
    selectedDocumentId: undefined,
    selectedRowsData: [],
  });

  const [tableState, setTableState] = useSetState<TableState>(
    initState(initialState),
  );

  const [visibleModals, setVisibleModals] = useSetState({
    assignToPartyWithPreview: false,
    assignToParty: false,
    removeTypes: false,
    addTypes: false,
    assignProject: false,
    matchRequirements: false,
    addRequirements: false,
    upload: false,
  });

  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);

  const [visibleColumns, setVisibleColumns] = useState<SortingItems>([]);
  const inputSearchValue = initialState?.filters?.friendlyName?.filter || '';

  const downloadableDocuments = selection.selectedRowsData.filter(
    (document) => !document.connectedLicense,
  );

  const areDocumentsArchived =
    tableState.filters.archiveStatus?.values?.includes(ARCHIVE_STATUS.Archived);

  const bulkActionsMenuItems = useMemo(
    () =>
      getActionsMenuItems({
        excludedActions: excludedActions,
        options: {
          isDownloadDisabled:
            selection.isSelectAllChecked || !(downloadableDocuments.length > 0),
          isPreviewDisabled: selection.isSelectAllChecked,
          isDetachFromProjectDisabled: !projectId,
          isAddTypesDisabled: selection.isSelectAllChecked,
          isRemoveTypesDisabled: selection.isSelectAllChecked,
          isMatchRequirementsDisabled: selection.isSelectAllChecked,
          isArchivedStatusEnable: areDocumentsArchived,
        },
      }),
    [
      selection.isSelectAllChecked,
      projectId,
      areDocumentsArchived,
      excludedActions,
      downloadableDocuments.length,
    ],
  );

  const reportParams = useMemo(() => {
    return mapTableParamsToLegacyDocumentsReportParams({
      filters: tableState.filters,
      sorting: tableState.sorting,
    });
  }, [tableState.filters, tableState.sorting]);

  const getQueryFilters = () => {
    if (selection.isSelectAllChecked) {
      const tableFilters = tableRef?.current?.getFilters();
      return mapTableFiltersToLegacyDocumentsFilters(tableFilters, {
        primaryRecordId: partyId,
        contextRecordId: projectId,
      });
    }

    return {
      ...(Boolean(selection.selectedDocumentsIds.length) && {
        ids: selection.selectedDocumentsIds,
      }),
    };
  };

  const refreshDocumentsTable = () => {
    tableRef?.current?.refreshTable();
  };

  const selectAllDocuments = () => {
    tableRef?.current?.selectAllRows();
  };

  const handleSelectVisibleDocuemnts = () => {
    tableRef?.current?.selectVisibleRows();
  };

  const handleOnActionCompleted = () => {
    refreshDocumentsTable();
    setSelection({ selectedDocumentId: undefined });
  };

  const resetDocumentsTableSelection = () => {
    tableRef?.current?.resetSelection();
    setCounters({ selectedRows: 0 });
    setSelection({
      isSelectAllChecked: false,
      selectedDocumentsIds: [],
    });
  };

  const handleOnSelectionChanged = (params: CustomSelectionChangedEvent) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedDocumentsIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleOnFiltersChanged = (params: {
    activeFiltersCount: number;
    activeFilters: TableFiltersModel;
  }) => {
    setCounters({
      activeFilters: params.activeFiltersCount,
    });

    setTableState({
      filters: params.activeFilters,
    });

    onStateChanged?.({
      ...tableState,
      filters: params.activeFilters,
    });
  };

  const handleOnPaginationChanged = (params: any) => {
    setTableState({
      pagination: params,
    });

    onStateChanged?.({
      ...tableState,
      pagination: params,
    });
  };

  const handleOnSortChanged = (sorting: {
    columnId?: string;
    direction?: SortDirection;
  }) => {
    setTableState({
      sorting: sorting || sortingInitialState,
    });

    onStateChanged?.({
      ...tableState,
      sorting: sorting || sortingInitialState,
    });
  };

  const onUpdateDocumentArchiveStatus = (document: Document) => {
    const { archivedAt, friendlyName, _id } = document;

    Modal.confirm({
      title: `Are you sure you want to ${
        archivedAt ? 'unarchive' : 'archive'
      } ${friendlyName}?`,
      okText: `${archivedAt ? 'Unarchive' : 'Archive'} document`,
      onOk: async () => {
        await dispatch(
          archiveDocument({
            id: _id,
            isArchived: !Boolean(archivedAt),
          }),
        );
        handleOnActionCompleted();
      },
    });
  };

  const onUpdateDocumentReviewStatus = (document: Document) => {
    const { reviewedAt, friendlyName, _id } = document;

    Modal.confirm({
      title: `Are you sure you want to ${
        reviewedAt ? 'mark as to review' : 'mark as reviewed'
      } ${friendlyName}?`,
      onOk: async () => {
        await dispatch(
          reviewDocument({
            id: _id,
            isReviewed: !reviewedAt,
          }),
        );
        handleOnActionCompleted();
      },
    });
  };

  const onDetachDocumentFromParty = ({ friendlyName, _id }: Document) => {
    Modal.confirm({
      title: `Do you want to detach ${friendlyName} from the current party?`,
      okText: 'Detach',
      onOk: async () => {
        await dispatch(
          detachDocumentsFromParty({
            documentIds: [_id],
          }),
        );
        handleOnActionCompleted();
      },
    });
  };

  const onBulkDetchDocumentFromParty = () => {
    Modal.confirm({
      title: `Do you want to detach ${pluralize(
        'document',
        counters.selectedRows,
        true,
      )} from the current party?`,
      onOk: async () => {
        await dispatch(
          bulkDetachDocumentsFromParty({
            filterQuery: getQueryFilters(),
          }),
        );

        notify.success(
          `${pluralize(
            'document',
            counters.selectedRows,
            true,
          )} scheduled to detach from party`,
        );
        resetDocumentsTableSelection();
      },
    });
  };

  const onDetachDocumentFromProject = ({ friendlyName, _id }: Document) => {
    Modal.confirm({
      title: `Do you want to detach ${friendlyName} from the current project?`,
      okText: 'Detach',
      onOk: async () => {
        await dispatch(
          detachDocumentsFromProject({
            documentIds: [_id],
          }),
        );
        handleOnActionCompleted();
      },
    });
  };

  const onDocumentDelete = (document: Document) => {
    const { _id, friendlyName, compliantAttributes } = document;

    Modal.confirm({
      title: `${
        !isEmpty(compliantAttributes) && Boolean(partyId)
          ? 'This document is being used to match compliance requirements.'
          : ''
      } Are you sure you want to delete ${friendlyName}? This action cannot be undone`,
      okText: 'Delete document',
      okType: 'danger',
      onOk: async () => {
        await dispatch(deleteDocument(_id));
        handleOnActionCompleted();
        onDocumentsDeleteSuccess?.();
      },
    });
  };

  const onBulkDetachDocumentsFromProject = () => {
    Modal.confirm({
      title: `Do you want to detach ${pluralize(
        'document',
        counters.selectedRows,
        true,
      )} documents from the current project?`,
      onOk: async () => {
        await dispatch(
          bulkDetachDocumentsFromProject({
            filterQuery: getQueryFilters(),
          }),
        );

        notify.success(
          `${pluralize(
            'document',
            counters.selectedRows,
            true,
          )} scheduled to detach from project`,
        );
        resetDocumentsTableSelection();
      },
    });
  };

  const onBulkUpdateReviewStatus = (status: ReviewStatus | undefined) => {
    const isReviewed = status === REVIEW_STATUS.Reviewed;
    const label = REVIEW_STATUS_LABEL_MAP[status || REVIEW_STATUS.ToReview];

    Modal.confirm({
      title: `Do you want to mark ${pluralize(
        'document',
        counters.selectedRows,
        true,
      )} as ${label}?`,
      onOk: async () => {
        await dispatch(
          bulkReviewDocuments({
            filterQuery: getQueryFilters(),
            isReviewed,
          }),
        );

        notify.success(
          `${pluralize(
            'document',
            counters.selectedRows,
            true,
          )} scheduled to mark as "${label}"`,
        );
        resetDocumentsTableSelection();
      },
    });
  };

  const onBulkUpdateArchiveStatus = () => {
    Modal.confirm({
      title: `Are you sure you want to ${
        areDocumentsArchived ? 'unarchive' : 'archive'
      } ${pluralize('document', counters.selectedRows, true)}?`,
      onOk: async () => {
        await dispatch(
          bulkArchiveDocuments({
            filterQuery: getQueryFilters(),
            isArchived: !areDocumentsArchived,
          }),
        );

        notify.success(
          `${pluralize(
            'document',
            counters.selectedRows,
            true,
          )} scheduled to be ${
            areDocumentsArchived ? 'unarchived' : 'archived'
          }`,
        );
        resetDocumentsTableSelection();
      },
    });
  };

  const onBulkDocumentsDelete = () => {
    const isHaveProveCompliance = selection.selectedRowsData.some(
      (document) => !isEmpty(document.compliantAttributes),
    );

    Modal.confirm({
      title: `${
        isHaveProveCompliance && Boolean(partyId)
          ? 'Some of the selected documents are being used to match compliance requirements.'
          : ''
      } Are you sure you want to delete ${pluralize(
        'document',
        counters.selectedRows,
        true,
      )}?`,
      okType: 'danger',
      okText: 'Delete documents',
      onOk: async () => {
        await dispatch(
          bulkDeleteDocuments({
            filterQuery: getQueryFilters(),
          }),
        );

        notify.success(
          `${pluralize(
            'document',
            counters.selectedRows,
            true,
          )} scheduled for deletion`,
        );

        resetDocumentsTableSelection();
        onDocumentsDeleteSuccess?.();
      },
    });
  };

  const onBulkDocumentsDownload = () => {
    if (downloadableDocuments.length > 1) {
      const files = downloadableDocuments.map((doc) => ({
        url: doc.url,
        name: getUniqueDocumentFileName(doc),
      }));
      dispatch(
        downloadInit({
          files,
          zipName: 'Documents',
        }),
      );
    } else {
      downloadableDocuments.forEach(downloadDocument);
    }

    resetDocumentsTableSelection();
    trackEvent('User bulk-downloaded documents');
  };

  const onDocumentPreview = (document: Document) => {
    if (document.party) {
      navigate(
        `/${organizationSlugifyName}/parties/${document.party._id}/documents-review/${document._id}`,
      );
    } else {
      setSelection({ selectedDocumentId: document._id });
      setVisibleModals({ assignToPartyWithPreview: true });
    }
  };

  const onBulkDocumentsPreview = () => {
    const searchParams = qs.stringify({
      selected: selection.selectedDocumentsIds,
    });

    navigate(
      `/${organizationSlugifyName}/parties/${
        partyId || DEFAULT_PARTY_CODE
      }/documents-review?${searchParams}`,
    );
  };

  const handleActionClick = ({
    actionType,
    document,
  }: {
    actionType: ActionType;
    document: Document;
  }) => {
    const { _id } = document;

    switch (actionType) {
      case DOCUMENT_ACTION_TYPES.assignToParty:
        setSelection({ selectedDocumentId: _id });
        setVisibleModals({ assignToParty: true });
        break;
      case DOCUMENT_ACTION_TYPES.associateToProject:
        setSelection({ selectedDocumentId: _id });
        setVisibleModals({ assignProject: true });
        break;
      case DOCUMENT_ACTION_TYPES.detachFromProject:
        onDetachDocumentFromProject(document);
        break;
      case DOCUMENT_ACTION_TYPES.detachFromParty:
        onDetachDocumentFromParty(document);
        break;
      case DOCUMENT_ACTION_TYPES.updateReviewStatus:
        onUpdateDocumentReviewStatus(document);
        break;
      case DOCUMENT_ACTION_TYPES.updateArchiveStatus:
        onUpdateDocumentArchiveStatus(document);
        break;
      case DOCUMENT_ACTION_TYPES.delete:
        onDocumentDelete(document);
        break;
      case DOCUMENT_ACTION_TYPES.preview:
        onDocumentPreview(document);
        break;
      default:
        break;
    }
  };

  const handleBulkActionsClick = async ({
    actionType,
    options,
  }: {
    actionType: ActionType;
    options?: ActionOptions;
  }) => {
    switch (actionType) {
      case DOCUMENT_ACTION_TYPES.assignToParty:
        setVisibleModals({ assignToParty: true });
        break;
      case DOCUMENT_ACTION_TYPES.detachFromParty:
        onBulkDetchDocumentFromParty();
        break;
      case DOCUMENT_ACTION_TYPES.associateToProject:
        setVisibleModals({ assignProject: true });
        break;
      case DOCUMENT_ACTION_TYPES.detachFromProject:
        onBulkDetachDocumentsFromProject();
        break;
      case DOCUMENT_ACTION_TYPES.addTypes:
        setVisibleModals({ addTypes: true });
        break;
      case DOCUMENT_ACTION_TYPES.removeTypes:
        setVisibleModals({ removeTypes: true });
        break;
      case DOCUMENT_ACTION_TYPES.updateReviewStatus:
        onBulkUpdateReviewStatus(options?.job);
        break;
      case DOCUMENT_ACTION_TYPES.matchRequirements:
        setVisibleModals({ matchRequirements: true });
        break;
      case DOCUMENT_ACTION_TYPES.addRequirement:
        setVisibleModals({ addRequirements: true });
        break;
      case DOCUMENT_ACTION_TYPES.updateArchiveStatus:
        onBulkUpdateArchiveStatus();
        break;
      case DOCUMENT_ACTION_TYPES.preview:
        onBulkDocumentsPreview();
        break;
      case DOCUMENT_ACTION_TYPES.delete:
        onBulkDocumentsDelete();
        break;
      case DOCUMENT_ACTION_TYPES.download:
        onBulkDocumentsDownload();
        break;
      default:
        break;
    }
  };

  const handleChangeView = useCallback(
    ({
      viewId: reportId,
      viewParams,
    }: {
      viewId?: string;
      viewParams: LegacyDocumentsReportParams;
    }) => {
      const { filters, sorting } = mapLegacyDocumentsReportParamsToTableParams(
        viewParams || {},
      );

      setTableState({ reportId, filters, sorting });

      onStateChanged?.({
        pagination: tableState.pagination,
        reportId,
        filters,
        sorting,
      });

      tableRef?.current?.setFilters(filters);
      tableRef?.current?.setSorting(
        sorting.columnId || sortingInitialState.columnId,
        sorting.direction || sortingInitialState.direction,
      );
    },
    [onStateChanged, setTableState, tableState.pagination],
  );

  const handleDeleteView = () => {
    setTableState({
      reportId: undefined,
      sorting: sortingInitialState,
      filters: filterInitialState,
    });

    if (onStateChanged) {
      onStateChanged({
        ...tableState,
        reportId: undefined,
        sorting: sortingInitialState,
        filters: filterInitialState,
      });
    }

    tableRef?.current?.setFilters(filterInitialState);
  };

  return (
    <StyledWrapper>
      <UploadDocumentModal
        isShow={visibleModals.upload}
        setIsShow={(isVisible: boolean) =>
          setVisibleModals({ upload: isVisible })
        }
        party={partyId}
        onCompleted={() => {
          refreshDocumentsTable();
        }}
      />
      <AssignDocumentToPartyWithPreviewModal
        visible={visibleModals.assignToPartyWithPreview}
        documentId={selection.selectedDocumentId}
        onSuccess={() => {
          setVisibleModals({ assignToPartyWithPreview: false });
          handleOnActionCompleted();
        }}
        onCancel={() => {
          setVisibleModals({ assignToPartyWithPreview: false });
          setSelection({ selectedDocumentId: undefined });
        }}
      />

      <AssignDocumentsToPartyModal
        areAllDocumentsSelected={selection.isSelectAllChecked}
        visible={visibleModals.assignToParty}
        documentId={selection.selectedDocumentId}
        documents={selection.selectedDocumentsIds}
        selectedDocumentsCount={counters.selectedRows}
        onCancel={() => {
          setVisibleModals({ assignToParty: false });
          setSelection({ selectedDocumentId: undefined });
        }}
        onSuccess={() => {
          setVisibleModals({ assignToParty: false });
          resetDocumentsTableSelection();
          handleOnActionCompleted();
        }}
      />

      <AssignDocumentsToProjectModal
        areAllDocumentsSelected={selection.isSelectAllChecked}
        visible={visibleModals.assignProject}
        documentId={selection.selectedDocumentId}
        documents={selection.selectedDocumentsIds}
        selectedDocumentsCount={counters.selectedRows}
        onCancel={() => {
          setVisibleModals({ assignProject: false });
          setSelection({ selectedDocumentId: undefined });
        }}
        onSuccess={() => {
          setVisibleModals({ assignProject: false });
          resetDocumentsTableSelection();
          handleOnActionCompleted();
        }}
      />

      <AddDocumentTypesModal
        visible={visibleModals.addTypes}
        onCancel={() => {
          setVisibleModals({ addTypes: false });
        }}
        onSuccess={() => {
          setVisibleModals({ addTypes: false });
          resetDocumentsTableSelection();
          refreshDocumentsTable();
        }}
        documentsIds={selection.selectedDocumentsIds}
        selectedDocumentsCount={counters.selectedRows}
      />
      <RemoveTypesModal
        visible={visibleModals.removeTypes}
        onCancel={() => {
          setVisibleModals({ removeTypes: false });
        }}
        onSuccess={() => {
          setVisibleModals({ removeTypes: false });
          resetDocumentsTableSelection();
          refreshDocumentsTable();
        }}
        documentIds={selection.selectedDocumentsIds}
      />
      <BulkMatchRequirementsModal
        visible={visibleModals.matchRequirements}
        onCancel={() => {
          setVisibleModals({ matchRequirements: false });
        }}
        onSuccess={() => {
          setVisibleModals({ matchRequirements: false });
          resetDocumentsTableSelection();
          refreshDocumentsTable();
        }}
        documentsIds={selection.selectedDocumentsIds}
        selectedDocumentsCount={counters.selectedRows}
      />
      <AddRequirementValueModal
        areAllDocumentsSelected={selection.isSelectAllChecked}
        visible={visibleModals.addRequirements}
        selectedDocumentsCount={counters.selectedRows}
        onCancel={() => {
          setVisibleModals({ addRequirements: false });
        }}
        onSuccess={() => {
          setVisibleModals({ addRequirements: false });
          resetDocumentsTableSelection();
          refreshDocumentsTable();
        }}
        documents={selection.selectedDocumentsIds}
        documentsFilter={undefined}
      />
      <TableHeader>
        <PageTitle title="Documents" icon={<FileTextOutlined />} />
        <TableHeader.TopRightActions>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setVisibleModals({ upload: true });
            }}
            type="primary"
          >
            Upload documents
          </Button>
        </TableHeader.TopRightActions>
        {areBulkActionsButtonsVisible ? (
          <TableHeader.BottomFullActions>
            <StyledBulkActions>
              <SelectionDropdown
                areAllSelected={selection.isSelectAllChecked}
                selectedEntitiesCount={counters.selectedRows}
                onSelectAll={selectAllDocuments}
                onSelectAllVisible={handleSelectVisibleDocuemnts}
                onSelectNone={resetDocumentsTableSelection}
                totalCount={counters.totalRows}
                totalVisible={counters.visibleRows}
              />
              <ActionsMenuWithButtons<ActionType, ActionOptions>
                items={bulkActionsMenuItems}
                onClick={(code, options) =>
                  handleBulkActionsClick({
                    actionType: code,
                    options: options,
                  })
                }
              />
            </StyledBulkActions>
          </TableHeader.BottomFullActions>
        ) : (
          <>
            <TableHeader.BottomLeftActions>
              <StyledSearchInput
                key={inputSearchValue}
                defaultValue={inputSearchValue}
                onSearch={(value: string) => {
                  tableRef.current?.setFilters({
                    friendlyName: {
                      filterType: 'text',
                      filter: value,
                      type: 'contains',
                    },
                  });
                }}
              />
              <ViewsSelector
                type={ViewType.Documents}
                defaultView={
                  DEFAULT_VIEWS.documents as ViewEntity as ViewEntity
                }
                viewParams={reportParams}
                viewId={tableState.reportId}
                onCreated={handleChangeView}
                onDeleted={handleDeleteView}
                onReset={handleDeleteView}
                onSelectChanged={handleChangeView}
              />
            </TableHeader.BottomLeftActions>

            <TableHeader.BottomRightActions>
              <FilterButton
                activeFiltersCount={counters.activeFilters}
                onClick={() => {
                  tableRef?.current?.toggleFiltersPanel();
                }}
              />
              <SortButton
                items={visibleColumns}
                initialValues={tableState.sorting}
                onChange={(sort) => {
                  tableRef.current!.setSorting(sort.columnId, sort.direction);
                }}
                onClick={() => {
                  const columns = tableRef?.current
                    ?.getSortableColumns()
                    ?.sort((a, b) => (a.name > b.name ? 1 : -1));
                  setVisibleColumns(columns || []);
                }}
              />
            </TableHeader.BottomRightActions>
          </>
        )}
      </TableHeader>
      <StyledTable>
        <DocumentsList
          ref={tableRef}
          primaryRecordId={partyId}
          contextRecordId={projectId}
          onActionClick={handleActionClick}
          onSelectionChanged={handleOnSelectionChanged}
          onFilterChanged={handleOnFiltersChanged}
          onSortChanged={handleOnSortChanged}
          onPaginationChanged={handleOnPaginationChanged}
          excludedActions={excludedActions}
          tableState={tableState}
        />
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledBulkActions = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 280px;
`;
