import {
  ACTION_TYPES,
  OVERRIDE_ACTIONS,
  STATUS_TYPES,
  WAIVER_ACTIONS,
} from '../../constants';

export const SUBJECT_ACTIONS_BY_STATUS = {
  [STATUS_TYPES.default]: [
    {
      label: 'Waive subject',
      code: ACTION_TYPES.waive,
    },
    {
      label: 'Override subject',
      code: ACTION_TYPES.override,
    },
  ],
  [STATUS_TYPES.waived]: WAIVER_ACTIONS,
  [STATUS_TYPES.overridden]: OVERRIDE_ACTIONS,
};
