import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useEffect, useState } from 'react';

import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import {
  type CustomFilterProps,
  CustomFilterWrapper,
} from '@common/components/Table';
import { useProjectsForSearch } from '@modules/project/hooks';

type InfiniteScrollSelectProps = Pick<
  CustomFilterProps,
  'model' | 'onModelChange'
>;

export const ProjectsCustomFilter = ({
  model,
  onModelChange,
}: InfiniteScrollSelectProps) => {
  const [selectedProjects, setSelectedProjects] = useState<
    string[] | undefined
  >();
  const {
    projects,
    projectsTotalCount,
    loading,
    loadMoreProjectsForSearch,
    setSearch,
  } = useProjectsForSearch();

  useEffect(() => {
    setSelectedProjects(model || []);
  }, [model]);

  return (
    <CustomFilterWrapper>
      <InfiniteScrollSelect
        value={selectedProjects}
        data-cy="infiniteScrollFilter-projects"
        isDataLoading={loading}
        mode="multiple"
        allowClear
        showSearch
        onSearch={setSearch}
        loadMore={loadMoreProjectsForSearch}
        hasMore={projectsTotalCount > projects.length}
        loader={<LoadingOutlined />}
        onChange={(value) => {
          const isValueEmpty = Array.isArray(value) && value.length === 0;
          onModelChange(isValueEmpty ? undefined : value);
          setSelectedProjects(value);
        }}
        placeholder="Search projects..."
      >
        {projects?.map((project) => (
          <Select.Option key={project._id} value={project._id}>
            {project.name}
          </Select.Option>
        ))}
      </InfiniteScrollSelect>
    </CustomFilterWrapper>
  );
};
