import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import * as ReduxPersist from '@config/ReduxPersist';
import { STATE_KEY as APP_STATE_KEY } from '@modules/app/constants';
import AppReducer from '@modules/app/reducers';
import { STATE_KEY as AUTH_STATE_KEY } from '@modules/auth/constants';
import AuthReducer from '@modules/auth/reducers';
import { STATE_KEY as COMMANDBAR_STATE_KEY } from '@modules/commandbar/constants';
import CommandBarReducer from '@modules/commandbar/reducers';
import { STATE_KEY as COMPLIANCE_PROFILE_STATE_KEY } from '@modules/compliance-profile/constants';
import ComplianceProfileReducer from '@modules/compliance-profile/reducers';
import { STATE_KEY as CUSTOM_FIELD_STATE_KEY } from '@modules/custom-field/constants';
import CustomFieldReducer from '@modules/custom-field/reducers';
import { STATE_KEY as DASHBOARD_STATE_KEY } from '@modules/dashboard/constants';
import DashboardReducer from '@modules/dashboard/reducers';
import { STATE_KEY as DOCUMENT_TYPE_STATE_KEY } from '@modules/document-type/constants';
import DocumentTypeReducer from '@modules/document-type/reducers';
import DocumentReducer, {
  STATE_KEY as DOCUMENT_STATE_KEY,
} from '@modules/document/reducers';
import { STATE_KEY as DOWNLOAD_STATE_KEY } from '@modules/download/constants';
import DownloadReducer from '@modules/download/reducers';
import { STATE_KEY as EMAIL_LAYOUT_STATE_KEY } from '@modules/email-layout/constants';
import EmailLayoutReducer from '@modules/email-layout/reducers';
import { STATE_KEY as EMAIL_SENDER_STATE_KEY } from '@modules/email-sender/constants';
import EmailSenderReducer from '@modules/email-sender/reducers';
import { STATE_KEY as EMAIL_TEMPLATE_STATE_KEY } from '@modules/email-template/constants';
import EmailTemplateReducer from '@modules/email-template/reducers';
import { STATE_KEY as EVENT_LOG_STATE_KEY } from '@modules/event-log/constants';
import EventLogReducer from '@modules/event-log/reducers';
import { STATE_KEY as FEATURE_FLAGS_STATE_KEY } from '@modules/feature-flags';
import FeatureFlagsReducer from '@modules/feature-flags/reducers';
import { STATE_KEY as FILLABLE_FORM_STATE_KEY } from '@modules/fillable-form/constants';
import FillableFormReducer from '@modules/fillable-form/reducers';
import { STATE_KEY as NOTIFICATION_STATE_KEY } from '@modules/notification/constants';
import NotificationReducer from '@modules/notification/reducers';
import OrganizationMemberReducer from '@modules/organization-member/reducers';
import { STATE_KEY as ORGANIZATION_STATE_KEY } from '@modules/organization/constants';
import OrganizationReducer from '@modules/organization/reducers';
import { STATE_KEY as PARTY_CONVERSATION_STATE_KEY } from '@modules/party-conversation/constants';
import PartyConversationReducer from '@modules/party-conversation/reducers';
import { STATE_KEY as PARTY_TYPE_STATE_KEY } from '@modules/party-type/constants';
import PartyTypeReducer from '@modules/party-type/reducers';
import { STATE_KEY as PARTY_STATE_KEY } from '@modules/party/constants';
import PartyReducer from '@modules/party/reducers';
import { STATE_KEY as PROJECT_STATE_KEY } from '@modules/project/constants';
import ProjectReducer from '@modules/project/reducers';
import { STATE_KEY as QUICKBOOKS_STATE_KEY } from '@modules/quickbooks/constants';
import QuickbooksReducer from '@modules/quickbooks/reducers';
import { STATE_KEY as REPORT_STATE_KEY } from '@modules/report/constants';
import ReportReducer from '@modules/report/reducer';
import { STATE_KEY as REQUEST_STATE_KEY } from '@modules/request/constants';
import RequestReducer from '@modules/request/reducers';
import { STATE_KEY as REQUIREMENT_STATE_KEY } from '@modules/requirement/constants';
import RequirementReducer from '@modules/requirement/reducers';
import { LOCATION_STATE_KEY } from '@modules/router/constants';
import LocationReducer from '@modules/router/reducers/LocationReducer';
import { STATE_KEY as SYSTEM_SETTINGS_STATE_KEY } from '@modules/system-settings/constants';
import SystemSettingsReducer from '@modules/system-settings/reducers';
import { STATE_KEY as TEXT_SNIPPETS_STATE_KEY } from '@modules/text-snippets/constants';
import TextSnippetsReducer from '@modules/text-snippets/reducers';
import { STATE_KEY as USER_STATE_KEY } from '@modules/user/constants';
import UserReducer from '@modules/user/reducers';

import { STATE_KEY as ORGANIZATION_MEMBER_STATE_KEY } from '../modules/organization-member/constants';

const persistedOrganization = persistReducer(
  ReduxPersist.organizationConfig,
  OrganizationReducer,
);

function rootReducerFn(reducers = {}) {
  const rootReducer = combineReducers({
    ...reducers,
    [APP_STATE_KEY]: AppReducer,
    [AUTH_STATE_KEY]: AuthReducer,
    [COMMANDBAR_STATE_KEY]: CommandBarReducer,
    [DASHBOARD_STATE_KEY]: DashboardReducer,
    [DOCUMENT_STATE_KEY]: DocumentReducer,
    [DOCUMENT_TYPE_STATE_KEY]: DocumentTypeReducer,
    [EMAIL_LAYOUT_STATE_KEY]: EmailLayoutReducer,
    [EMAIL_SENDER_STATE_KEY]: EmailSenderReducer,
    [EMAIL_TEMPLATE_STATE_KEY]: EmailTemplateReducer,
    [EVENT_LOG_STATE_KEY]: EventLogReducer,
    [NOTIFICATION_STATE_KEY]: NotificationReducer,
    [ORGANIZATION_STATE_KEY]: persistedOrganization,
    [ORGANIZATION_MEMBER_STATE_KEY]: OrganizationMemberReducer,
    [PARTY_STATE_KEY]: PartyReducer,
    [PARTY_TYPE_STATE_KEY]: PartyTypeReducer,
    [PROJECT_STATE_KEY]: ProjectReducer,
    [CUSTOM_FIELD_STATE_KEY]: CustomFieldReducer,
    [REQUIREMENT_STATE_KEY]: RequirementReducer,
    [PARTY_CONVERSATION_STATE_KEY]: PartyConversationReducer,
    [COMPLIANCE_PROFILE_STATE_KEY]: ComplianceProfileReducer,
    [TEXT_SNIPPETS_STATE_KEY]: TextSnippetsReducer,
    [USER_STATE_KEY]: UserReducer,
    [QUICKBOOKS_STATE_KEY]: QuickbooksReducer,
    [REPORT_STATE_KEY]: ReportReducer,
    [REQUEST_STATE_KEY]: RequestReducer,
    [SYSTEM_SETTINGS_STATE_KEY]: SystemSettingsReducer,
    [FILLABLE_FORM_STATE_KEY]: FillableFormReducer,
    [DOWNLOAD_STATE_KEY]: DownloadReducer,
    [FEATURE_FLAGS_STATE_KEY]: FeatureFlagsReducer,
    [LOCATION_STATE_KEY]: LocationReducer,
  });

  return persistReducer(ReduxPersist.rootConfig, rootReducer);
}

export default rootReducerFn;
