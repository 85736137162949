import { dangerousFileExtensions } from '@common/constants';

export const addPartyIdToTypeformLinks = (message, partyId) => {
  const typeformLinkRegex = /(href="[^"]+typeform.com\/to\/\w+)"/g;
  const typeformLinkReplacement = `$1#party_id=${partyId}"`;
  return message.replace(typeformLinkRegex, typeformLinkReplacement);
};

const dangerousFileExtensionsRegex = new RegExp(
  `(${dangerousFileExtensions.join('|')})\\s*$`,
);

export function fileValidator(file) {
  if (dangerousFileExtensionsRegex.test(file.name)) {
    return new Error('Invalid format');
  }

  if (file.size < 1) {
    return new Error('Corrupted File');
  }

  return null;
}
