import { Alert, Button, List, Spin, Tabs } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';

import EmptyState from '@common/components/EmptyState';
import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import {
  closeNotificationDrawer,
  selectNotificationDrawerFilter,
} from '../../actions';
import ListDivider from '../../components/ListDivider';
import NotificationFilter from '../../components/NotificationFilter';
import NotificationSwitcher from '../../components/NotificationSwitcher';
import { getSelectedFilter } from '../../selectors';
import type { NotificationType } from '../../types';

import { NotificationTab, useNotifications } from './hooks';

const { TabPane } = Tabs;

const NotificationList = () => {
  const dispatch = useAppDispatch();
  const organizationNamespace: string = useAppSelector(
    getOrganizationNamespaceUrl,
  );
  const selectedFilter = useAppSelector(getSelectedFilter);
  const [selectedTab, setSelectedTab] = useState(NotificationTab.unread);
  const isNotificationReadTabSelected = selectedTab === NotificationTab.read;

  const {
    isLoadingNotifications,
    isLoadingMoreNotifications,
    fetchMoreNotifications,
    notifications,
    unreadTotalCount,
    readTotalCount,
    fetchInitialNotifications,
    markAllNotifications,
    markNotification,
    hasNewNotifications,
  } = useNotifications({
    type: selectedFilter,
    isRead: isNotificationReadTabSelected,
  });

  const handleRefreshUnreadNotifications = async () => {
    await fetchInitialNotifications();
  };

  const handleOnFilterChange = (value: NotificationType) => {
    dispatch(selectNotificationDrawerFilter(value));
  };

  const handleOnNotificationLinkClick = () => {
    dispatch(closeNotificationDrawer());
  };

  const toggleNotificationTab = () => {
    setSelectedTab((selectedTab) =>
      selectedTab === NotificationTab.read
        ? NotificationTab.unread
        : NotificationTab.read,
    );
  };

  return (
    <StyledList
      data-cy="notificationsList"
      loading={isLoadingNotifications}
      locale={{
        emptyText: <EmptyState desc="No new notifications" />,
      }}
      footer={
        notifications.length <
        (isNotificationReadTabSelected ? readTotalCount : unreadTotalCount) ? (
          <StyledDrawerFooter data-cy="loadMoreNotifications">
            <StyledLoadMoreIcon onClick={fetchMoreNotifications}>
              {!isLoadingMoreNotifications ? '•••' : <Spin />}
            </StyledLoadMoreIcon>
          </StyledDrawerFooter>
        ) : null
      }
      header={
        <>
          <NotificationFilter
            selectedFilter={selectedFilter}
            onChange={handleOnFilterChange}
          />
          <StyledTabs
            activeKey={selectedTab}
            onChange={toggleNotificationTab}
            defaultActiveKey={NotificationTab.unread}
            tabBarExtraContent={
              Boolean(notifications?.length) && (
                <StyledMarkAllAsRead onClick={markAllNotifications}>
                  Mark all as{' '}
                  {!isNotificationReadTabSelected ? 'read' : 'unread'}
                </StyledMarkAllAsRead>
              )
            }
          >
            <TabPane
              tab={`Unread${
                unreadTotalCount !== -1 ? ` (${unreadTotalCount})` : ''
              }`}
              key={NotificationTab.unread}
            />
            <TabPane tab={`Read`} key={NotificationTab.read} />
          </StyledTabs>
          {hasNewNotifications && !isNotificationReadTabSelected && (
            <Alert
              message={'New messages available'}
              action={
                <Button onClick={handleRefreshUnreadNotifications} type="link">
                  REFRESH
                </Button>
              }
            />
          )}
        </>
      }
      dataSource={notifications}
      renderItem={(item, i) => {
        const nextNotificationCreationDate = notifications[i + 1]?.createdAt;

        const isNotificationNewerThanSevenDays =
          moment().diff(item.createdAt, 'days') <= 8;
        const isNextNotificationOlderThanSevenDays =
          Boolean(nextNotificationCreationDate) &&
          moment().diff(nextNotificationCreationDate, 'days') > 8;

        return (
          <>
            <NotificationSwitcher
              organizationNamespace={organizationNamespace}
              notification={item}
              onNotificationStatusChanged={markNotification}
              onLinkClicked={handleOnNotificationLinkClick}
            />
            {isNotificationNewerThanSevenDays &&
              isNextNotificationOlderThanSevenDays &&
              selectedTab === NotificationTab.unread && (
                <ListDivider label="Older than 7 days" />
              )}
          </>
        );
      }}
    />
  );
};

const StyledList = styled(List)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-list-header {
    padding: 0;
  }

  .ant-spin-nested-loading {
    height: 0;
    flex: 1;

    .ant-spin-container {
      height: 100%;
    }

    .ant-list-items {
      overflow-y: scroll;
      height: 100%;
    }
  }
` as typeof List;

const StyledTabs = styled(Tabs)`
  padding: 0 15px;

  .ant-tabs-nav {
    margin: 0;

    &::before {
      display: none;
    }
  }
`;

const StyledMarkAllAsRead = styled.span`
  margin-left: auto;
  padding: 8px;
  display: flex;
  align-items: center;
  color: #1890ff;
  cursor: pointer;
`;

const StyledDrawerFooter = styled.div`
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
`;

const StyledLoadMoreIcon = styled.span`
  font-size: 16px;
  cursor: pointer;

  :hover {
    color: #1890ff;
  }
`;

export default NotificationList;
