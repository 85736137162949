import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import { FETCH_TEMPLATES } from '../actions';

const initialState = {
  templates: [],
  requirementsDraft: [],
};

const RequirementReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_TEMPLATES}_SUCCESS`: {
      const templates = R.compose(R.defaultTo([]), getGraphqlPayload)(action);

      return R.assoc('templates', templates, state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default RequirementReducer;
