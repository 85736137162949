import { Popover, Tag, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import { htmlToText } from 'html-to-text';
import styled from 'styled-components';

import PopoverContent from '@common/components/PopoverContent';
import { containsText } from '@common/utils/string-helpers';

const TagWithPopover = ({ color, name, value = '' }) => (
  <StyledTag>
    <Tooltip title={name}>
      <StyledBadge color={color} />
    </Tooltip>
    {containsText(value) ? (
      <Popover
        trigger={['click']}
        title={name}
        content={
          <PopoverContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(value),
            }}
          />
        }
      >
        <StyledPopoverLabel>{htmlToText(value)}</StyledPopoverLabel>
      </Popover>
    ) : (
      <StyledLabel>{name}</StyledLabel>
    )}
  </StyledTag>
);

const StyledTag = styled(Tag)`
  border-radius: 99em;
  background-color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
  font-size: 11px;
  display: inline-flex;
  align-items: center;
`;

const StyledBadge = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 99em;
  background-color: ${({ color }) => color};
`;

const StyledLabel = styled.span`
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
`;

const StyledPopoverLabel = styled(StyledLabel)`
  cursor: pointer;
  text-shadow: none;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export default TagWithPopover;
