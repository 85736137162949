import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTIES_WITH_DETAILS = `${namespace}/FETCH_PARTIES_WITH_DETAILS`;

const FETCH_PARTIES_WITH_DETAILS_QUERY = gql`
  query listPartiesData($filters: PartyListFiltersEntity, $sort: ElementsSort) {
    listPartiesData(filters: $filters, sort: $sort) {
      data {
        _id
        name
        failedMessages {
          _id
        }
        type {
          name
        }
        isActive
        taggings {
          _id
          partyTag {
            _id
            name
            color
          }
          expiresAt
        }
        totalDocumentsCount
        totalDocumentsToReviewCount
        totalApplicableDocumentsCount
        totalApplicableDocumentsToReviewCount
        nearestFlagSeverityOrder
        partyComplianceProfile {
          _id
          complianceProfile
          name
          nextExpirationDate
          latestExpirationDate
          earliestExpirationDate
          complianceStats {
            complianceScore
            nonCompliantRequirementsCount
            waivedRequirementsCount
            overriddenRequirementsCount
            totalSubjectsCount
            compliantSubjectsCount
            complianceSubjectsScore
          }
        }
        projectStats {
          compliantProjectsCount
          totalProjectsCount
        }
        lastRequestSentOn
        partyContacts {
          _id
          email
          isDefaultRequestRecipient
        }
        isTrackingCompliance
        lastActivityDate
        messagesStatus {
          totalMessages
          newMessages
        }
        externalIds
      }
      totalCount
    }
  }
`;

export const fetchPartiesWithDetails = ({ filters, sort }) => ({
  type: FETCH_PARTIES_WITH_DETAILS,
  payload: {
    key: 'listPartiesData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTIES_WITH_DETAILS_QUERY,
      variables: { filters, sort },
    },
  },
});
