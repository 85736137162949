import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const PROCESS_DOCUMENT_URL = `${namespace}/PROCESS_DOCUMENT_URL`;

const PROCESS_DOCUMENT_URL_MUTATION = gql`
  mutation processDocumentUrl($payload: ProcessDocumentUrlInput!) {
    processDocumentUrl(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const processDocumentUrl = (payload) => ({
  type: PROCESS_DOCUMENT_URL,
  payload: {
    key: 'processDocumentUrl',
    graphql: {
      mutation: PROCESS_DOCUMENT_URL_MUTATION,
      variables: { payload },
      context: { hasUpload: true },
    },
  },
});
