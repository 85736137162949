import { getGraphqlPayload } from '@store/helpers';
import { message as notify } from 'antd';
import * as R from 'ramda';
import { all, takeLatest } from 'redux-saga/effects';

import {
  CREATE_REQUEST,
  FORWARD_REQUEST,
  SEND_REQUEST_REMINDER,
} from '../actions';

function* createRequestFailSaga(action) {
  yield notify.error(
    R.pathOr(
      'Unable to create document request',
      ['error', '0', 'message'],
      action,
    ),
  );
}

function* sendReminderSuccessSaga() {
  yield notify.success(`Reminder successfully sent`);
}

function* sendReminderFailSaga(action) {
  yield notify.error(
    R.pathOr('Unable to send reminder', ['error', '0', 'message'], action),
  );
}

function* forwardRequestSuccessSaga(payload) {
  const party = getGraphqlPayload(payload);

  const contactPersonName = R.compose(
    R.prop('contactPersonName'),
    (contactId) => R.find(R.propEq('_id', contactId), party.partyContacts),
    R.prop('forwardedTo'),
    R.last,
    R.prop('requests'),
  )(party);

  yield notify.success(
    `Request successfully forwarded to "${contactPersonName}"`,
  );
}

function* forwardRequestFailSaga(action) {
  yield notify.error(
    R.pathOr('Unable to forward request', ['error', '0', 'message'], action),
  );
}

function* requestSagas() {
  yield all([
    takeLatest(`${CREATE_REQUEST}_FAIL`, createRequestFailSaga),
    takeLatest(`${SEND_REQUEST_REMINDER}_SUCCESS`, sendReminderSuccessSaga),
    takeLatest(`${SEND_REQUEST_REMINDER}_FAIL`, sendReminderFailSaga),
    takeLatest(`${FORWARD_REQUEST}_SUCCESS`, forwardRequestSuccessSaga),
    takeLatest(`${FORWARD_REQUEST}_FAIL`, forwardRequestFailSaga),
  ]);
}

export default requestSagas;
