import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';

const SelectionDropdown = ({
  selectedEntitiesCount,
  onSelectAll = () => {},
  onSelectAllVisible = () => {},
  onSelectNone = () => {},
  totalCount,
  totalVisible,
  areAllSelected,
}) => {
  const areVisiblesTheTotality = totalVisible >= totalCount;

  return (
    <StyledWrapper
      data-cy="selectionDropdown"
      $areVisiblesTheTotality={areVisiblesTheTotality}
    >
      <Dropdown
        trigger={!areVisiblesTheTotality ? ['click'] : []}
        dropdownRender={() => (
          <Menu>
            <Menu.Item
              key="selection-dropdown-select-all"
              data-cy="selection-dropdown-select-all"
              onClick={() => onSelectAll()}
            >
              Select all ({totalCount})
            </Menu.Item>
            <Menu.Item
              key="selection-dropdown-select-visible"
              data-cy="selection-dropdown-select-visible"
              onClick={() => onSelectAllVisible()}
            >
              Select visible ({totalVisible})
            </Menu.Item>
            <Menu.Item
              key="selection-dropdown-select-none"
              data-cy="selection-dropdown-select-none"
              onClick={() => onSelectNone()}
            >
              Select none
            </Menu.Item>
          </Menu>
        )}
      >
        <div>
          {areAllSelected
            ? `All selected (${totalCount})`
            : `${selectedEntitiesCount} selected`}
          {!areVisiblesTheTotality && <CaretDownOutlined />}
        </div>
      </Dropdown>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.span`
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  cursor: ${(props) => (!props.$areVisiblesTheTotality ? 'pointer' : 'default')};
`;

export default SelectionDropdown;
