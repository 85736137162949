import { Modal, Tag } from 'antd';
import without from 'lodash/without';
import { useState } from 'react';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';

import { useDocumentsTypes } from './hooks';

type RemoveTypesModalProps = {
  visible: boolean;
  documentIds: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

const { CheckableTag } = Tag;

export const RemoveTypesModal = ({
  visible,
  documentIds,
  onCancel,
  onSuccess,
}: RemoveTypesModalProps) => {
  const [selectedTypeIds, setSelectedTypeIds] = useState<string[]>([]);

  const { types, isLoadingTypes, removeDocumentsTypes, isRemovingTypes } =
    useDocumentsTypes({
      documentIds,
      options: {
        skip: !visible,
      },
    });

  const handleOk = async () => {
    removeDocumentsTypes({
      variables: {
        data: {
          documentIds,
          documentTypeIds: selectedTypeIds,
        },
      },
      onCompleted: () => {
        setSelectedTypeIds([]);
        onSuccess();
      },
    });
  };

  const handleOnTagClick = (id: string) =>
    selectedTypeIds.includes(id)
      ? setSelectedTypeIds((prevState) => without(prevState, id))
      : setSelectedTypeIds((prevState) => [...prevState, id]);

  const content =
    Number(types?.length) > 0 ? (
      <>
        <p>Select which document types you want to remove</p>
        <StyledTag>
          {types?.map((type) => {
            if (!type) {
              return null;
            }

            return (
              <CheckableTag
                data-cy="removeDocumentTypes"
                key={`key_${type._id}`}
                checked={selectedTypeIds.includes(type._id)}
                onChange={() => handleOnTagClick(type._id)}
              >
                {type.name}
              </CheckableTag>
            );
          })}
        </StyledTag>
      </>
    ) : (
      <p>No document types available on selected documents</p>
    );

  return (
    <Modal
      destroyOnClose={true}
      title="Remove document types"
      open={visible}
      onOk={handleOk}
      okButtonProps={{
        disabled: !selectedTypeIds.length,
        loading: isRemovingTypes,
      }}
      okText="Remove"
      onCancel={() => {
        setSelectedTypeIds([]);
        onCancel();
      }}
      width={550}
    >
      {isLoadingTypes ? <Spinner /> : content}
    </Modal>
  );
};

const StyledTag = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  .ant-tag-checkable {
    cursor: pointer;
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: ${(props) => props.theme.colors.red};
  }

  .ant-tag-checkable-checked {
    background-color: ${(props) => props.theme.colors.red};
  }
`;
