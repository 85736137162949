import type { Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import type { GraphQLError } from 'graphql';

import { trackError } from '@common/utils/track-helpers';

import { showGraphqlErrorToastMessage } from './utils';

const INTERNAL_SERVER_ERROR_CODE = 'INTERNAL_SERVER_ERROR';

class TrackedError extends Error {
  constructor(message: string, name: string) {
    super(message);
    this.name = name;
  }
}

const getTrackedError = ({
  graphqlError,
  operation,
  transactionID,
}: {
  graphqlError: GraphQLError;
  operation: Operation;
  transactionID: string;
}) => {
  const tags = {
    sourceTag: 'User-facing server error (ERR999)',
    transaction_id: transactionID,
  };

  const isApplicationError = Boolean(graphqlError.extensions?.applicationCode);
  const isUnhandledError =
    graphqlError.extensions?.code === INTERNAL_SERVER_ERROR_CODE;

  if (isApplicationError) {
    return;
  }

  if (isUnhandledError) {
    return {
      error: new TrackedError(
        graphqlError.message,
        `InternalServerError-${operation.operationName}`,
      ),
      fingerprintKeys: [INTERNAL_SERVER_ERROR_CODE, operation.operationName],
      tags,
    };
  }

  /**
   * Errors already handled by server but WITH NO application error codes.
   */
  return {
    error: new TrackedError(
      graphqlError.message,
      `ServerError-${operation.operationName}`,
    ),
    fingerprintKeys: [operation.operationName, graphqlError.message],
    tags,
  };
};

const errorLink = onError((errorResponse) => {
  const { graphQLErrors, networkError, operation } = errorResponse;

  console.warn({ errorResponse });

  if (networkError) {
    console.warn(`[Network error]: ${networkError.message}`);
  }

  if (graphQLErrors) {
    const operationContext = operation?.getContext();
    const transactionID = operationContext?.headers?.['x-transaction-id'];

    graphQLErrors.forEach((graphqlError) => {
      showGraphqlErrorToastMessage(graphqlError.message);

      const error = getTrackedError({
        graphqlError,
        operation,
        transactionID,
      });

      if (!error) {
        return;
      }

      trackError(error);
    });
  }
});

export default errorLink;
