export const ORGANIZATION_PLACEHOLDER_VALUES = {
  organizationName: 'organizationName',
  organizationLogoUrl: 'organizationLogoUrl',
  organizationAddress: 'organizationAddress',
  organizationPrimaryContactName: 'organizationPrimaryContactName',
  organizationPrimaryContactEmail: 'organizationPrimaryContactEmail',
  organizationPrimaryContactPhone: 'organizationPrimaryContactPhone',
} as const;

export const organizationPlaceholders = {
  title: 'Organization',
  placeholders: [
    {
      name: 'Organization name',
      region: `{{ ${ORGANIZATION_PLACEHOLDER_VALUES.organizationName} }}`,
    },
    {
      name: 'Organization logo',
      region: `{{ ${ORGANIZATION_PLACEHOLDER_VALUES.organizationLogoUrl} }}`,
    },
    {
      name: 'Organization address',
      region: `{{ ${ORGANIZATION_PLACEHOLDER_VALUES.organizationAddress} }}`,
    },
    {
      name: 'Organization primary contact name',
      region: `{{ ${ORGANIZATION_PLACEHOLDER_VALUES.organizationPrimaryContactName} }}`,
    },
    {
      name: 'Organization primary contact email',
      region: `{{ ${ORGANIZATION_PLACEHOLDER_VALUES.organizationPrimaryContactEmail} }}`,
    },
    {
      name: 'Organization primary contact phone',
      region: `{{ ${ORGANIZATION_PLACEHOLDER_VALUES.organizationPrimaryContactPhone} }}`,
    },
  ],
};
