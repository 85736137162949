import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useDeleteRequestsMutation } from '@modules/request-records/hooks/useDeleteRequestsMutation';
import { Modal, message } from 'antd';
import pluralize from 'pluralize';

export const DeleteRequestsModal = ({
  open,
  filters,
  count,
  onCompleted,
  onScheduled,
  onFailed,
  onCancel,
}: {
  open: boolean;
  filters: ServerFilterInput;
  count: number;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
  onCancel?: () => void;
}) => {
  const { deleteRequestRecords, deletingRequests } =
    useDeleteRequestsMutation();

  const handleOnOk = async () => {
    const res = await deleteRequestRecords({
      filter: filters,
    });
    const status = res.data?.deleteRequestRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `${count} ${pluralize('request', count)} deleted successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${count} ${pluralize('request', count)} have been scheduled for deletion.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The deletion of your ${count} ${pluralize('request', count)} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <Modal
      open={open}
      confirmLoading={deletingRequests}
      title={`Are you sure you want to delete ${count} ${pluralize('request', count)}?`}
      okText={'Delete requests'}
      okType={'danger'}
      onOk={handleOnOk}
      onCancel={onCancel}
    />
  );
};
