import withReduxPersist from '@common/hoc/withReduxPersist';
import withAuth from '@modules/auth/hoc/withAuth';
import { Outlet } from 'react-router-dom';

const UserLayout = withReduxPersist(
  withAuth(function RootPageInner() {
    return <Outlet />;
  }),
);

export default UserLayout;
