import {
  ArrowType,
  SortDirectionsFullMap,
  SortDirectionsMap,
} from '@common/constants';
import { normalizeArray, normalizeDate } from '@common/utils/filter-helpers';

export const ProjectComplianceStatus = {
  Compliant: 'compliant',
  NonCompliant: 'non_compliant',
};

export const ProjectComplianceStatusLabelsMap = {
  [ProjectComplianceStatus.Compliant]: 'Compliant',
  [ProjectComplianceStatus.NonCompliant]: 'Non-compliant',
};

export const ProjectSortFields = {
  Name: 'name',
  StartDate: 'startDate',
  EndDate: 'endDate',
  Status: 'status',
};

/**
 * Project filter fields.
 */
export const ProjectFilterFields = {
  Name: 'name',
  CustomField: 'customField',
  StartDate: 'startDate',
  EndDate: 'endDate',
  ComplianceStatus: 'complianceStatus',
};

/**
 * Default project field enum.
 */
export const ProjectField = {
  Description: 'description',
  IsActive: 'isActive',
  StartDate: 'startDate',
  EndDate: 'endDate',
  CustomFields: 'customFields',
  Parties: 'partiesData',
  ComplianceProfiles: 'complianceProfiles',
  Attachments: 'attachments',
};

/**
 * Default project fields.
 */
export const DEFAULT_PROJECT_FIELDS = Object.values(ProjectField);

/**
 * Normalized project filters.
 */
export const NormalizedProjectFilters = {
  [ProjectFilterFields.Name]: {
    label: 'Name',
    normalizeValue: (value) => value,
  },
  [ProjectFilterFields.CustomField]: {
    normalizeValue: (value) => value,
  },
  [ProjectFilterFields.StartDate]: {
    label: 'Start date',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [ProjectFilterFields.EndDate]: {
    label: 'End date',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [ProjectFilterFields.ComplianceStatus]: {
    label: 'Compliance status',
    normalizeValue: normalizeArray,
  },
};

/**
 * Data for projects sort.
 */
export const getProjectsSortOptions = ({
  isComplianceRequirementsAddOnEnabled,
}) =>
  [
    {
      label: 'Name',
      iconType: ArrowType.Up,
      field: ProjectSortFields.Name,
      direction: SortDirectionsMap.asc,
      helpText: 'A › Z',
    },
    {
      label: 'Name',
      iconType: ArrowType.Down,
      field: ProjectSortFields.Name,
      direction: SortDirectionsMap.desc,
      helpText: 'Z › A',
    },
    {
      label: 'Start date',
      iconType: ArrowType.Up,
      field: ProjectSortFields.StartDate,
      direction: SortDirectionsMap.asc,
      helpText: 'A › Z',
    },
    {
      label: 'Start date',
      iconType: ArrowType.Down,
      field: ProjectSortFields.StartDate,
      direction: SortDirectionsMap.desc,
      helpText: 'Z › A',
    },
    {
      label: 'End date',
      iconType: ArrowType.Up,
      field: ProjectSortFields.EndDate,
      direction: SortDirectionsMap.asc,
      helpText: 'A › Z',
    },
    {
      label: 'End date',
      iconType: ArrowType.Down,
      field: ProjectSortFields.EndDate,
      direction: SortDirectionsMap.desc,
      helpText: 'Z › A',
    },
    {
      label: 'Status',
      iconType: ArrowType.Up,
      field: ProjectSortFields.Status,
      isVisible: isComplianceRequirementsAddOnEnabled,
      direction: SortDirectionsMap.asc,
      helpText: 'noncompliant › compliant',
    },
    {
      label: 'Status',
      iconType: ArrowType.Down,
      isVisible: isComplianceRequirementsAddOnEnabled,
      field: ProjectSortFields.Status,
      direction: SortDirectionsMap.desc,
      helpText: 'compliant › noncompliant',
    },
  ].filter((option) => option.isVisible !== false);

/**
 * Get sort label by field and direction.
 */
export const getSortLabelData = ({
  field,
  direction,
  isComplianceRequirementsAddOnEnabled,
}) =>
  getProjectsSortOptions({ isComplianceRequirementsAddOnEnabled }).find(
    (option) => option.field === field && option.direction === direction,
  );

export const getProjectsColumns = ({
  sortField,
  sortDirection,
  isPartyView,
  isComplianceRequirementsAddOnEnabled,
}) => {
  const order = SortDirectionsFullMap[sortDirection];

  return [
    {
      title: 'Name',
      dataIndex: ProjectSortFields.Name,
      key: ProjectSortFields.Name,
      sorter: true,
      sortOrder: sortField === ProjectSortFields.Name ? order : false,
    },
    {
      title: isPartyView ? 'Status' : 'Compliant parties',
      dataIndex: ProjectSortFields.Status,
      key: ProjectSortFields.Status,
      sorter: true,
      sortOrder: sortField === ProjectSortFields.Status ? order : false,
      width: 80,
      isVisible: isComplianceRequirementsAddOnEnabled,
    },
    {
      title: 'Start date',
      dataIndex: ProjectSortFields.StartDate,
      key: ProjectSortFields.StartDate,
      sorter: true,
      sortOrder: sortField === ProjectSortFields.StartDate ? order : false,
      width: 120,
    },
    {
      title: 'End date',
      dataIndex: ProjectSortFields.EndDate,
      key: ProjectSortFields.EndDate,
      sorter: true,
      sortOrder: sortField === ProjectSortFields.EndDate ? order : false,
      width: 120,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
    },
  ].filter((column) => column.isVisible !== false);
};

export const EventType = {
  bulkUpdateProjectsActiveStatusFail: 'bulk:update:status:projects:fail',
  bulkUpdateProjectsActiveStatusSuccess: 'bulk:update:status:projects:success',
  bulkDeleteProjectsFail: 'bulk:delete:projects:fail',
  bulkDeleteProjectsSuccess: 'bulk:delete:projects:success',
};
