import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const PRIMARY_OBJECT_QUERY = graphql(`
  query PrimaryObject($input: PrimaryObjectInput!) {
    primaryObject(input: $input) {
      _id
      name
      icon
      pluralName
      slug
    }
  }
`);

export const usePrimaryObjectQuery = (objectSlug: string | undefined) => {
  const { data, loading } = useQuery(PRIMARY_OBJECT_QUERY, {
    variables: { input: { slug: objectSlug } },
    skip: !objectSlug,
  });

  return {
    primaryObjectData: data?.primaryObject,
    isPrimaryObjectLoading: loading,
  };
};
