import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_EMAIL_LAYOUT,
  DELETE_EMAIL_LAYOUT,
  FETCH_EMAIL_LAYOUTS,
  RESET_TO_SYSTEM_DEFAULT,
  SELECT_EMAIL_LAYOUT,
  UPDATE_EMAIL_LAYOUT,
} from '../actions';

const initialState = {
  data: {},
};

const EmailLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_EMAIL_LAYOUTS}_SUCCESS`: {
      const emailLayouts = R.compose(
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', emailLayouts, state);
    }
    case `${SELECT_EMAIL_LAYOUT}_SUCCESS`: {
      const emailLayouts = R.compose(
        R.mergeDeepRight(state.data),
        R.indexBy(R.prop('_id')),
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', emailLayouts, state);
    }
    case `${RESET_TO_SYSTEM_DEFAULT}_SUCCESS`:
    case `${CREATE_EMAIL_LAYOUT}_SUCCESS`:
    case `${UPDATE_EMAIL_LAYOUT}_SUCCESS`: {
      const emailLayout = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.assocPath(['data', emailLayout._id], emailLayout, state);
    }
    case `${DELETE_EMAIL_LAYOUT}_SUCCESS`: {
      const emailLayout = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.dissocPath(['data', emailLayout._id], state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default EmailLayoutReducer;
