import * as R from 'ramda';

import { getGraphqlPayload } from '@store/helpers';

import {
  CREATE_REPORT,
  CREATE_SCHEDULED_REPORT,
  DELETE_REPORT,
  DELETE_SCHEDULED_REPORT,
  FETCH_REPORTS,
  SEND_SCHEDULED_REPORT,
  UPDATE_REPORT,
  UPDATE_SCHEDULED_REPORT,
} from '../actions';

const initialState = {
  data: {},
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_REPORTS}_SUCCESS`: {
      const omitPageAndSizeFromFilters = (report) => {
        const newFilters = R.omit(['size', 'page'], R.prop('filters', report));
        const newReport = R.merge(report, { filters: newFilters });
        return newReport;
      };

      const reports = R.compose(
        R.indexBy(R.prop('_id')),
        R.map(omitPageAndSizeFromFilters), // omit page and size from old reports (saved before 12/2020)
        R.defaultTo([]),
        getGraphqlPayload,
      )(action);

      return R.assoc('data', reports, state);
    }
    case `${CREATE_REPORT}_SUCCESS`:
    case `${UPDATE_REPORT}_SUCCESS`: {
      const report = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.assocPath(['data', report._id], report, state);
    }
    case `${DELETE_REPORT}_SUCCESS`: {
      const report = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.dissocPath(['data', report._id], state);
    }
    case `${CREATE_SCHEDULED_REPORT}_SUCCESS`: {
      const schedule = getGraphqlPayload(action);
      const newSchedules = [
        ...state?.data?.[schedule.report]?.schedules,
        schedule,
      ];
      return R.assocPath(
        ['data', schedule.report, 'schedules'],
        newSchedules,
        state,
      );
    }
    case `${UPDATE_SCHEDULED_REPORT}_SUCCESS`: {
      const schedule = getGraphqlPayload(action);
      const prevSchedulePosition = R.findIndex(R.propEq('_id', schedule._id))(
        state?.data?.[schedule.report]?.schedules,
      );
      return R.assocPath(
        ['data', schedule.report, 'schedules', prevSchedulePosition],
        schedule,
        state,
      );
    }
    case `${DELETE_SCHEDULED_REPORT}_SUCCESS`: {
      const schedule = getGraphqlPayload(action);
      const updatedSchedules = R.reject(
        R.propEq('_id', schedule._id),
        state?.data?.[schedule.report]?.schedules,
      );
      return R.assocPath(
        ['data', schedule.report, 'schedules'],
        updatedSchedules,
        state,
      );
    }
    case `${SEND_SCHEDULED_REPORT}_SUCCESS`: {
      const schedule = getGraphqlPayload(action);
      const prevSchedulePosition = R.findIndex(R.propEq('_id', schedule._id))(
        state?.data?.[schedule.report]?.schedules,
      );
      return R.assocPath(
        ['data', schedule.report, 'schedules', prevSchedulePosition],
        schedule,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export default ReportReducer;
