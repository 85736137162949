import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const ADD_DOCUMENT_VERIFICATION = `${namespace}/ADD_DOCUMENT_VERIFICATION`;

const ADD_DOCUMENT_VERIFICATION_MUTATION = gql`
  mutation addDocumentVerification($payload: AddDocumentVerificationInput!) {
    addDocumentVerification(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const addDocumentVerification = (payload) => ({
  type: ADD_DOCUMENT_VERIFICATION,
  payload: {
    key: 'addDocumentVerification',
    graphql: {
      mutation: ADD_DOCUMENT_VERIFICATION_MUTATION,
      variables: { payload },
    },
  },
});
