import type { ReactNode } from 'react';
import styled from 'styled-components';

export const TableHeader = ({ children }: { children: ReactNode }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

TableHeader.TopRightActions = ({ children }: { children: ReactNode }) => {
  return <div className="top-right-actions">{children}</div>;
};

TableHeader.BottomFullActions = ({ children }: { children: ReactNode }) => {
  return <div className="bottom-full-actions">{children}</div>;
};

TableHeader.BottomLeftActions = ({ children }: { children: ReactNode }) => {
  return <div className="bottom-left-actions">{children}</div>;
};

TableHeader.BottomRightActions = ({ children }: { children: ReactNode }) => {
  return <div className="bottom-right-actions">{children}</div>;
};

const StyledHeader = styled.section`
  --grid-t-area: 1 / 1 / 2 / 3;
  --grid-tr-area: 1 / 2 / 2 / 3;
  --grid-tl-area: 1 / 1 / 2 / 2;
  --grid-br-area: 2 / 2 / 3 / 3;
  --grid-bl-area: 2 / 1 / 3 / 2;
  --grid-b-area: 2 / 1 / 3 / 3;

  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;

  .top-right-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-area: var(--grid-tr-area);
  }

  .bottom-full-actions {
    display: flex;
    justify-content: flex-start;
    grid-area: var(--grid-b-area);
  }

  .bottom-left-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    grid-area: var(--grid-bl-area);
  }

  .bottom-right-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    grid-area: var(--grid-br-area);
  }
`;
