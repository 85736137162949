import { graphql } from '@graphql/types';

export const BULK_ASSIGN_DOCUMENTS_TO_PARTY_MUTATION = graphql(`
  mutation BulkAssignDocumentsToParty(
    $payload: BulkAssignDocumentsToPartyInput
  ) {
    bulkAssignDocumentsToParty(data: $payload) {
      scheduled
    }
  }
`);
