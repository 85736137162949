import type React from 'react';
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';

interface LinkProps extends RouterLinkProps {
  // Add any additional props you need here
}

export const LinkCell: React.FC<LinkProps> = ({ to, children, ...rest }) => {
  return (
    <RouterLink to={to} {...rest}>
      {children}
    </RouterLink>
  );
};

export default LinkCell;
