import { Input, Modal, Typography, message } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import capitalize from 'lodash/capitalize';
import { type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import FullWidthDatePicker from '@common/components/FullWidthDatePicker';
import FullWidthSpace from '@common/components/FullWidthSpace';
import { utc } from '@common/utils/date';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import {
  BulkOperationStatus,
  type WaiveOverrideRequestRecordRequirementMutation,
  type WaiverOverrideRequestRecordSubjectMutation,
} from '@graphql/types/graphql';
import { RequirementComplianceStatus } from '@trustlayer/common';
import {
  getSubjectInfoSubtitle,
  getWaiveOverrideMutationPayload,
  isDateDisabled,
} from './WaiveAndOverrideModal.utils';
import { ACTION_NOUNS_MAP, ActionType, ItemType } from './constants';
import { useWaiveOverrideRequirementMutation } from './hooks/useWaiveOverrideRequirementMutation';
import { useWaiveOverrideSubjectMutation } from './hooks/useWaiveOverrideSubjectMutation';

export type SubmitParams = {
  notes?: string;
  expiringDate: string | null;
};

type WaiveAndOverrideModalProps = PropsWithChildren<{
  open: boolean;
  actionType: ActionType;
  itemType: ItemType;
  itemData: {
    code: string;
    status: RequirementComplianceStatus;
  };
  requestRecordId: string;
  defaultState?: {
    status?: RequirementComplianceStatus;
    notes?: string;
    expiringDate?: string;
  };
  onCompleted?: () => void;
  onCancel?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
}>;

const { TextArea } = Input;

export const WaiveAndOverrideModal = ({
  open,
  actionType,
  itemType,
  itemData,
  requestRecordId,
  defaultState,
  children,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: WaiveAndOverrideModalProps) => {
  const defaultExpiringDate = useMemo(
    () =>
      defaultState?.expiringDate ? dayjs(defaultState?.expiringDate) : null,
    [defaultState?.expiringDate],
  );
  const defaultNotes = defaultState?.notes ?? '';

  const [notes, setNotes] = useState(defaultNotes);
  const [expiringDate, setExpiringDate] = useState<Dayjs | null>(
    defaultExpiringDate,
  );

  const { waiveOverrideRequirement, loadingWaiveOverrideRequirement } =
    useWaiveOverrideRequirementMutation();
  const { waiveOverrideSubject, loadingWaiveOverrideSubject } =
    useWaiveOverrideSubjectMutation();

  const handleOnMutationCompleted = (
    res:
      | WaiveOverrideRequestRecordRequirementMutation
      | WaiverOverrideRequestRecordSubjectMutation,
  ) => {
    const status =
      'toggleRequestRecordRequirementsWaiver' in res
        ? res.toggleRequestRecordRequirementsWaiver?.operation.status
        : res.toggleRequestRecordSubjectsWaiver?.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Your ${itemTypeLabel} has been successfully ${
          actionType === ActionType.override ? 'overridden' : 'waived'
        }.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Your ${itemTypeLabel} has been scheduled for ${actionType}.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The ${actionType} of your ${itemTypeLabel} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const handleWaiveOverride = () => {
    if (!open) return;

    const operationData = getWaiveOverrideMutationPayload({
      code: itemData.code,
      itemType,
      status: itemData.status,
      actionType: actionType,
      notes,
      resetOn: expiringDate ? utc(expiringDate).toISOString() : null,
    });

    if (itemType === ItemType.subject) {
      waiveOverrideSubject({
        variables: {
          requestId: requestRecordId!,
          subject: operationData,
        },
        onCompleted: handleOnMutationCompleted,
      });
    }

    if (itemType === ItemType.requirement) {
      waiveOverrideRequirement({
        variables: {
          requestId: requestRecordId!,
          requirement: operationData,
        },
        onCompleted: handleOnMutationCompleted,
      });
    }
  };

  const actionTypeLabel = actionType;
  const itemTypeLabel = itemType;
  const isEditing =
    defaultState?.status === RequirementComplianceStatus.Waived ||
    defaultState?.status === RequirementComplianceStatus.Overridden;
  const capitilizedActionTypeLabel = capitalize(actionTypeLabel);
  const okLabel = isEditing ? 'Edit' : capitilizedActionTypeLabel;
  const title = `${isEditing ? 'Edit ' : ''}${capitilizedActionTypeLabel} ${itemTypeLabel}`;

  useEffect(() => {
    setNotes(defaultNotes);
  }, [defaultNotes]);

  useEffect(() => {
    setExpiringDate(defaultExpiringDate);
  }, [defaultExpiringDate]);

  return (
    <Modal
      open={open}
      title={title}
      centered={true}
      width={685}
      okText={okLabel}
      onOk={handleWaiveOverride}
      onCancel={onCancel}
      okButtonProps={{
        loading: loadingWaiveOverrideSubject || loadingWaiveOverrideRequirement,
      }}
    >
      <FullWidthSpace direction="vertical" size="middle">
        <Typography.Text>
          {`Please confirm that you want to ${actionTypeLabel} the ${itemTypeLabel} below:`}
        </Typography.Text>
        <StyledSubjectInfoWrapper>{children}</StyledSubjectInfoWrapper>
        <label>Notes:</label>
        <TextArea
          rows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <label>{`Remove ${ACTION_NOUNS_MAP[actionType]} on:`}</label>
        <FullWidthDatePicker
          onChange={setExpiringDate}
          value={expiringDate}
          disabledDate={isDateDisabled}
          format={defaultDateFormats}
        />
      </FullWidthSpace>
    </Modal>
  );
};

WaiveAndOverrideModal.SubjectInfo = ({
  actionType,
  name,
}: {
  actionType: ActionType;
  name: string;
}) => {
  const actionTypeLabel = actionType;
  return (
    <>
      <StyledSubjectLabel>{name}</StyledSubjectLabel>
      <StyledInfo>{getSubjectInfoSubtitle(actionTypeLabel)}</StyledInfo>
    </>
  );
};

const StyledSubjectLabel = styled.p`
  font-weight: 600;
  margin-bottom: 5px;
`;

const StyledInfo = styled.p`
  margin: 0;
`;

const StyledSubjectInfoWrapper = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.blueGray};
  border-radius: 4px;
`;
