import { namespace } from '../constants';
import type { NotificationType } from '../types';

export const OPEN_DRAWER = `${namespace}/OPEN_DRAWER` as const;

export const openNotificationDrawer = (
  payload: { type: NotificationType | null } = { type: null },
) => ({
  type: OPEN_DRAWER,
  payload,
});

export type OpenNotificationDrawerAction = ReturnType<
  typeof openNotificationDrawer
>;
