import { PageTitle } from '@common/components/PageTitleWrapper';
import { ActivityLogs } from '@modules/primary-records/containers/ActivityLogs';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { useParams } from 'react-router-dom';

export function PrimaryRecordActivityTimeline() {
  const { recordId } = useParams<PrimaryRecordsRoutesParams>();

  return (
    <section>
      <PageTitle title="Activity timeline" />
      <ActivityLogs recordId={recordId!} />
    </section>
  );
}
