import { gql } from '@apollo/client';

export const EVENT_LOG_FRAGMENT = gql`
  fragment EventLog on EventLog {
    _id
    type
    data
    contextEntityType
    contextEntityId
    actorType
    projectName
    projectId
    partyName
    partyId
    actorId
    createdAt
    updatedAt
    deletedAt
  }
`;
