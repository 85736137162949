import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const CREATE_PERSONAL_ACCESS_TOKEN = `${namespace}/CREATE_PERSONAL_ACCESS_TOKEN`;

const CREATE_PERSONAL_ACCESS_TOKEN_MUTATION = gql`
  mutation createPersonalAccessToken(
    $payload: CreatePersonalAccessTokenInput!
  ) {
    createPersonalAccessToken(data: $payload)
  }
`;

export const createPersonalAccessToken = (payload) => ({
  type: CREATE_PERSONAL_ACCESS_TOKEN,
  payload: {
    key: 'createPersonalAccessToken',
    graphql: {
      mutation: CREATE_PERSONAL_ACCESS_TOKEN_MUTATION,
      variables: { payload },
    },
  },
});
