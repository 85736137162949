export const PARTY_PLACEHOLDER_VALUES = {
  partyName: 'partyName',
  partyContactName: 'partyContactName',
  partyContactPhone: 'partyContactPhone',
  partyProfilePageUrl: 'partyProfilePageUrl',
  commentLink: 'commentLink',
  partyAdditionalNotes: 'partyAdditionalNotes',
} as const;

export const partyPlaceholders = {
  title: 'Party',
  placeholders: [
    {
      name: 'Party name',
      region: `{{ ${PARTY_PLACEHOLDER_VALUES.partyName} }}`,
    },
    {
      name: 'Party primary contact name',
      region: `{{ ${PARTY_PLACEHOLDER_VALUES.partyContactName} }}`,
    },
    {
      name: 'Party primary contact phone',
      region: `{{ ${PARTY_PLACEHOLDER_VALUES.partyContactPhone} }}`,
    },
    {
      name: 'Party profile page URL',
      region: `{{ ${PARTY_PLACEHOLDER_VALUES.partyProfilePageUrl} }}`,
    },
    {
      name: 'Party comments URL',
      region: `{{ ${PARTY_PLACEHOLDER_VALUES.commentLink} }}`,
    },
    {
      name: 'Party additional notes',
      region: `{{ ${PARTY_PLACEHOLDER_VALUES.partyAdditionalNotes} }}`,
    },
  ],
};
