import { EditOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const DraftLink = ({
  contentState,
  entityKey,
  children,
  setVisibleInsertLink,
  setCurrentEntityKey,
  setCurrentInsertLinkData,
  visibleInsertLink,
  urlTextRef,
  setIsEditable,
}) => {
  const { url } = contentState.getEntity(entityKey).getData();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const handleVisibleChange = (isVisible) => {
    setIsPopoverVisible(isVisible);
    setIsEditable(isVisible);
  };
  const onOpenEdit = () => {
    setVisibleInsertLink(true);
    setCurrentEntityKey(entityKey);
    setCurrentInsertLinkData({ url });
    setIsPopoverVisible(false);
  };

  return (
    <Popover
      content={
        <StyledPopoverWrapper>
          <StyledCustomLink onClick={() => window.open(url, '_blank')}>
            {url}
          </StyledCustomLink>
          <StyledEditButton onClick={onOpenEdit} style={{ marginLeft: 20 }} />
        </StyledPopoverWrapper>
      }
      onOpenChange={handleVisibleChange}
      open={!visibleInsertLink && isPopoverVisible}
    >
      <a
        ref={urlTextRef}
        className="link"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        aria-label={url}
      >
        {children}
      </a>
    </Popover>
  );
};

const StyledEditButton = styled(EditOutlined)`
  cursor: pointer;
  transition:
    opacity 0.5s,
    color 0.2s;

  :hover {
    opacity: 0.7;
  }

  :active {
    color: ${(props) => props.theme.colors.blue};
  }
`;

const StyledPopoverWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
`;

const StyledCustomLink = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};
  transition:
    opacity 0.5s,
    color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    opacity: 0.7;
  }
`;

export const getInsertLinkPlugin = (props) => ({
  decorators: [
    {
      strategy: linkStrategy,
      component: DraftLink,
      props,
    },
  ],
});
