// reference: https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
/**
 * @deprecated this is slightly different from the paginationWithInput.ts because it was an old version of the input (see keyArgs)
 * @description This function is used to merge the incoming data with the existing data in a substitutive manner.
 */
function offsetPagination() {
  return {
    keyArgs: ['filter', 'sort'],
    merge(
      existing: { nodes: any[] },
      incoming: { nodes: any[] },
      { args }: Record<string, any>,
    ) {
      const { offset = 0 } = args;

      if (!offset) {
        return incoming;
      }

      const mergedNodes = [
        ...(existing?.nodes || []),
        ...(incoming?.nodes || []),
      ];

      return {
        ...(existing || {}),
        ...(incoming || {}),
        nodes: mergedNodes,
      };
    },
  };
}

export default offsetPagination;
