import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_FRAGMENT } from '../fragments';

export const FETCH_ORGANIZATION_BY_ID = `${namespace}/FETCH_ORGANIZATION_BY_ID`;

const FETCH_ORGANIZATION_BY_ID_QUERY = gql`
  query getOrganization($id: String!) {
    getOrganization(id: $id) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const fetchOrganizationById = (id) => ({
  type: FETCH_ORGANIZATION_BY_ID,
  payload: {
    key: 'getOrganization',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_ORGANIZATION_BY_ID_QUERY,
      variables: { id },
    },
  },
});
