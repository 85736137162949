import styled from 'styled-components';

import ImpulseSpinner from './ImpulseSpinner';

export const SpinnerType = {
  Dark: {
    $frontColor: '#1890FF',
    $backColor: '#F4F8FD',
  },
  Light: {
    $frontColor: '#F4F8FD',
    $backColor: '#397DD2',
  },
  DarkBackground: {
    $frontColor: '#e5e7eb',
    $backColor: '#acb3be',
  },
};

const Spinner = ({
  style = SpinnerType.Dark,
  spinnerWrapperStyle = {},
  ...props
}) => (
  <Spinner.Wrapper style={spinnerWrapperStyle} {...props}>
    <ImpulseSpinner {...style} />
  </Spinner.Wrapper>
);

Spinner.Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 0 50px;
  align-items: center;
  justify-content: center;
`;

export default Spinner;
