import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DOCUMENT_TYPES_QUERY = graphql(`
  query AddDocumentChecklistModalDocumentTypes {
    listDocumentTypes {
      _id
      name
    }
  }
`);

export const useDocumentTypes = ({
  skip,
  excludedDocumentTypeIds = [],
}: { skip: boolean; excludedDocumentTypeIds?: string[] }) => {
  const { data, loading } = useQuery(DOCUMENT_TYPES_QUERY, {
    skip,
  });

  const documentTypes = (data?.listDocumentTypes || []).filter(
    (type) =>
      !excludedDocumentTypeIds.some((excludedId) => excludedId === type._id),
  );

  return {
    documentTypes,
    loading,
  };
};
