import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_FRAGMENT } from '../fragments';

export const UPDATE_ACTIVE_ORGANIZATION = `${namespace}/UPDATE_ACTIVE_ORGANIZATION`;

const UPDATE_ACTIVE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization($payload: UpdateOrganizationInput!) {
    updateOrganization(data: $payload) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const updateActiveOrganization = (payload) => ({
  type: UPDATE_ACTIVE_ORGANIZATION,
  payload: {
    key: 'updateOrganization',
    graphql: {
      mutation: UPDATE_ACTIVE_ORGANIZATION_MUTATION,
      variables: {
        payload,
      },
      context: { hasUpload: true },
    },
  },
});
