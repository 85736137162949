import { NetworkStatus } from '@apollo/client';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export type Contact = {
  _id: string;
  email?: string | null;
  contactPersonName?: string | null;
  phone?: string | null;
};

const DAFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
  offset: 0,
};

const CONTACTS_EMAIL_FOR_SEARCH_QUERY = graphql(`
  query ContactsEmailsForSearch($input: ContactsInput) {
    contacts(input: $input) {
      nodes {
        _id
        email
        contactPersonName
        phone
      }
      totalCount
    }
}
`);

export const useContactsEmailsForSearch = (email?: string) => {
  const [inputFilter, setInputFilter] = useState(() => {
    return {
      ...DEFAULT_INPUT,
      filter: email
        ? {
            and: [
              {
                name: 'email',
                operator: FILTER_OPERATORS.contains,
                value: email,
              },
            ],
          }
        : undefined,
    };
  });
  const { data, loading, fetchMore, networkStatus } = useQuery(
    CONTACTS_EMAIL_FOR_SEARCH_QUERY,
    {
      variables: {
        input: inputFilter,
      },
      skip: !inputFilter.filter,
      notifyOnNetworkStatusChange: true,
    },
  );
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isLoading = !isLoadingMore && loading;
  const fetchMoreContactsForSearch = () => {
    fetchMore({
      variables: {
        input: {
          ...inputFilter,
          offset: data?.contacts.nodes.length || 0,
        },
      },
    });
  };
  const debounceSetInputFilter = useCallback(debounce(setInputFilter, 500), []);

  useEffect(() => {
    debounceSetInputFilter({
      ...DEFAULT_INPUT,
      filter: email
        ? {
            and: [
              {
                name: 'email',
                operator: FILTER_OPERATORS.contains,
                value: email,
              },
            ],
          }
        : undefined,
    });
  }, [email, debounceSetInputFilter]);

  return {
    contactsList: data?.contacts.nodes || [],
    contactsListTotalCount: data?.contacts.totalCount || 0,
    contactsLoading: isLoading,
    contactsLoadingMore: isLoadingMore,
    fetchMoreContactsForSearch,
  };
};
