import { Alert, type AlertProps, Button } from 'antd';
import styled from 'styled-components';

const RefreshDataBanner = ({
  onRefreshClick,
  marginBottom = 8,
}: AlertProps & { onRefreshClick: () => void; marginBottom?: number }) => {
  return (
    <StyledWrapper $marginBottom={marginBottom}>
      <Alert
        message={
          <>
            An operation is completed, the data shown might be changed.
            <Button type="link" onClick={onRefreshClick}>
              Refresh now
            </Button>
          </>
        }
        banner
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{ $marginBottom: number }>`
  margin-bottom: ${(props) => props.$marginBottom}px;
`;

export default RefreshDataBanner;
