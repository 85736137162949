export const STATE_KEY = 'report';
export const namespace = 'REPORT';

export const DropdownMenuKey = {
  Edit: 'Edit',
  Export: 'Export as',
  Schedule: 'Schedule email reports',
  Delete: 'Delete',
};

export const DropdownMenuExportKey = {
  CSV: 'csv',
  XLSX: 'xlsx',
};

export const DropdownMenuScheduleKey = {
  CreateSchedule: 'Create new schedule',
};

export const ReportType = {
  Parties: 'parties',
  Projects: 'projects',
  Documents: 'documents',
};

export const REPORT_COLUMN_LABELS = {
  businessName: 'Business name',
  type: 'Type',
  contactPersonName: 'Contact person name',
  contactPersonEmail: 'Contact person email',
  contactPersonPhone: 'Contact person phone',
  addressLine1: 'Address',
  addressLine2: 'Address 2',
  city: 'City',
  state: 'State',
  postalCode: 'Postal Code',
  country: 'Country',
  website: 'Website',
  additionalNotes: 'Additional Notes',
  tag1: 'Tag 1',
  tag2: 'Tag 2',
  tag3: 'Tag 3',
  tag4: 'Tag 4',
  tag5: 'Tag 5',
  projectName: 'Project name',
  complianceStatus: 'Compliance status',
  compliantSubjects: 'Compliant subjects',
  nonCompliantSubjects: 'Non-compliant subjects',
  waivedRequirements: 'Waived requirements',
  overriddenRequirements: 'Overridden requirements',
  expiringSubjects: 'Expiring subjects',
  documentsReviewStatus: 'Documents review status',
  documentsByType: 'Documents by type',
  nextExpiration: 'Next expiration',
  expirationsBySubject: 'Expirations by subject',
  lastRequest: 'Last request',
  lastActivity: 'Last activity',
  createdAt: 'Added date',
  customFields: 'Custom fields',
  complianceProfileName: 'Compliance Profile',
  documentsFlagNotes: 'Documents flag notes',
};

export const REPORT_COLUMNS = [
  { name: 'businessName', value: true },
  { name: 'type', value: true },
  { name: 'contactPersonName', value: true },
  { name: 'contactPersonEmail', value: true },
  { name: 'contactPersonPhone', value: true },
  { name: 'addressLine1', label: 'Address', value: true },
  { name: 'addressLine2', label: 'Address 2', value: true },
  { name: 'city', label: 'City', value: true },
  { name: 'state', label: 'State', value: true },
  { name: 'postalCode', label: 'Postal Code', value: true },
  { name: 'country', label: 'Country', value: true },
  { name: 'website', value: true },
  { name: 'additionalNotes', value: true },
  { name: 'tag1', value: true },
  { name: 'tag2', value: true },
  { name: 'tag3', value: true },
  { name: 'tag4', value: true },
  { name: 'tag5', value: true },
  { name: 'projectName', value: true },
  { name: 'complianceStatus', value: true },
  { name: 'compliantSubjects', value: true },
  {
    name: 'nonCompliantSubjects',
    value: true,
  },
  { name: 'waivedRequirements', value: true },
  {
    name: 'overriddenRequirements',
    value: true,
  },
  { name: 'expiringSubjects', value: true },
  {
    name: 'documentsReviewStatus',
    value: true,
  },
  { name: 'documentsByType', value: true },
  { name: 'nextExpiration', value: true },
  {
    name: 'expirationsBySubject',
    value: true,
  },
  { name: 'lastRequest', value: true },
  { name: 'lastActivity', value: true },
  { name: 'createdAt', value: true },
  { name: 'complianceProfileName', value: true },
  {
    name: 'customFields',
    value: true,
  },
  {
    name: 'documentsFlagNotes',
    value: true,
  },
];

export const REPORT_INSURANCE_LICENSE_LABELS = [
  'Party Name',
  'National Producer Number',
  'State License',
  'State License Number',
  'Class Code',
  'Issue Date',
  'Expiration Date',
  'License Status',
  'Lines of Authority',
];
