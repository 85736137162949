import omitDeep from '@wertarbyte/omit-deep';
import * as R from 'ramda';

import { ACTION_ERROR_SUFFIX, ACTION_SUCCESS_SUFFIX } from '@store/constants';
import { getContext } from '@store/helpers';

const getActionGraphqlOptions = ({ payload }) => payload?.graphql;

const getActionType = ({ type }) => {
  if (typeof type !== 'undefined') {
    return type;
  }

  throw new Error(
    'Action which matched graphql middleware needs to have "type" key which is not null',
  );
};

const handleErrors = ({ action, next, error }) => {
  /**
   * @note better place to log error since there is the whole stacktrace
   */
  console.error(
    `[GraphQL ERROR] (${action?.payload?.key}) ${error.message}: `,
    error,
  );

  const errorObject = !error.graphQLErrors
    ? {
        data: error.message,
        status: 0,
      }
    : error.graphQLErrors;

  const nextAction = {
    type: `${getActionType(action)}${ACTION_ERROR_SUFFIX}`,
    error: errorObject,
    meta: {
      previousAction: action,
    },
  };

  next(nextAction);
  return nextAction;
};

const handleSuccess = ({ action, next, response }) => {
  const nextAction = {
    type: `${getActionType(action)}${ACTION_SUCCESS_SUFFIX}`,
    payload: omitDeep(R.clone(response), ['__typename']),
    meta: {
      previousAction: action,
    },
  };

  next(nextAction);
  return nextAction;
};

const graphqlMiddleware = (client) => {
  return ({ getState }) =>
    (next) =>
    (action) => {
      const isGraphqlRequest = getActionGraphqlOptions(action);

      if (!isGraphqlRequest) {
        return next(action);
      }

      next(action);

      /**
       * applies some redux state values to graphql request variables
       */
      const state = getState();
      const context = getContext(state);

      const requestConfig = R.mergeDeepRight(
        {
          ...getActionGraphqlOptions(action),
        },
        {
          variables: context,
        },
      );

      const makeRequest = requestConfig.mutation ? client.mutate : client.query;

      /**
       * define handlers to apply action result suffix
       */
      return makeRequest(requestConfig).then(
        (response) => handleSuccess({ action, next, response }),
        (error) => handleErrors({ action, next, error }),
      );
    };
};

export default graphqlMiddleware;
