import * as R from 'ramda';

export const colorScheme = {
  mainBackground: { value: '#fff', name: 'Main Background' },
  primary: { value: '#1677FF', name: 'Primary' },
  white: { value: '#FFF', name: 'White' },
  black: { value: '#000', name: 'Black' },
  blue: { value: '#1890FF', name: 'Blue' },
  boldBlue: { value: '#1A6FE0', name: 'boldBlue' },
  lightBlackTone: { value: '#35475C', name: 'lightBlackTone' },
  gray: { value: '#E8E8E8', name: 'Gray' },
  red: { value: '#FF0000', name: 'Red' },
  green: { value: '#008000', name: 'Green' },
  lightestBlue: { value: '#FBFCFE', name: 'LightestBlue' },
  lightBlue: { value: '#F4F8FD', name: 'LightBlue' },
  mediumBlue: { value: '#3f9aff', name: 'MediumBlue' },
  lightBlack: { value: '#697B8D', name: 'LightBlack' },
  lightGray: { value: '#D9D9D9', name: 'LightGray' },
  lightGrayTone: { value: '#F1F5F9', name: 'LightGrayTone' },
  blueGray: { value: '#F7F9FC', name: 'BlueGray' },
  darkGray: { value: '#CECECE', name: 'DarkGray' },
  darkGrayText: { value: '#959595', name: 'darkGrayText' },
  grayText: { value: '#697B8D', name: 'GrayText' },
  lightestGray: { value: '#f5f5f5', name: 'LightestGray' },
  grayBg: { value: '#5b6474', name: 'GrayBg' },
  limeGreen: { value: '#52c41a', name: 'LimeGreen' },
  darkLateGray: { value: '#323639', name: 'DarkLateGray' },
  orange: { value: '#fc906b', name: 'Orange' },
  ghostWhite: { value: '#F5F7FB', name: 'ghostWhite' },
  purple: { value: '#F9F0FF', name: 'Purple' },
  darkPurple: { value: '#722ED1', name: 'DarkPurple' },
  lightPurple: { value: '#D3ADF7', name: 'LightPurple' },
  grayishBlue: { value: '#354052', name: 'GrayishBlue' },
  paleBlue: { value: '#E9F3FE', name: 'PaleBlue' },
  paleGray: { value: '#5c6c87', name: 'PaleGray' },
  yellow: { value: '#FAAD14', name: 'Yellow' },
  darkYellow: { value: '#613400', name: 'DarkYellow' },
  veryPaleBlue: { value: '#f0f1f2', name: 'veryPaleBlue' },
  blueAlice: { value: '#e6f7ff', name: 'blueAlice' },
  lightGreen: { value: '#0ec66a', name: 'lightGreen' },
  blueBg: { value: '#207dd2', name: 'blueBg' },
  blueGradientStart: { value: '#297fef', name: 'blueGradientStart' },
  blueGradientEnd: { value: '#30cdfe', name: 'blueGradientEnd' },
  brightGreen: { value: '#4ccd5f', name: 'brightGreen' },
  lightRed: { value: '#ff3636', name: 'lightRed' },
  iconRed: { value: '#F22626', name: 'iconRed' },
  errorText: { value: '#a8071a', name: 'errorText' },
  iconOrange: { value: '#FFA200', name: 'iconOrange' },
  iconBlue: { value: '#3F9AFF', name: 'iconBlue' },
  iconGreen: { value: '#4ED256', name: 'iconGreen' },
  iconGray: { value: '#E8EDF3', name: 'iconGray' },
  lightRedTooltipBg: { value: '#FCE5E5', name: 'lightRedTooltipBg' },
  redTooltipBorder: { value: '#F68383', name: 'redTooltipBorder' },
  lightBlueTooltipBg: { value: '#F2F9FF', name: 'lightBlueTooltipBg' },
  blueTooltipBorder: { value: '#8CC8FF', name: 'blueTooltipBorder' },
  lightGreenTooltipBg: { value: '#EDFBEC', name: 'lightGreenTooltipBg' },
  greenTooltipBorder: { value: '#5FD857', name: 'greenTooltipBorder' },
  paleYellow: { value: '#fffbe6', name: 'paleYellow' },
  yellowTooltipBorder: { value: '#ffe58f', name: 'yellowTooltipBorder' },
  iconYellow: { value: '#ffbf4f', name: 'iconYellow' },
  tagBlueGray: { value: '#e5effa', name: 'tagBlueGray' },
  blackGray: { value: '#737373', name: 'blackGray' },
  mineShaft: { value: '#262626', name: 'mineShaft' },
  lightTextGray: { value: '#8C8C8C', name: 'lightTextGray' },
  subtitleText: { value: '#595959', name: 'subtitleText' },
  lightBGGray: { value: '#0000000f', name: 'lightBGGray' },
};

export const getColor = (colorKey) => R.path([colorKey, 'value'], colorScheme);

const getColorsMap = () =>
  R.compose(
    R.reduce((acc, [key, scheme]) => R.assoc(key, scheme.value, acc), {}),
    R.toPairs,
  )(colorScheme);

const colors = getColorsMap();

export default colors;
