const Sign = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 141 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="141" height="125" fill="white" />
    <path
      d="M133.14 34.18H101.31C99.4827 34.18 97.7302 34.9059 96.438 36.198C95.1459 37.4902 94.42 39.2427 94.42 41.07V102.62L87.86 97.62V55.81C87.8593 54.2288 87.488 52.6697 86.776 51.2578C86.064 49.846 85.031 48.6206 83.76 47.68L55.69 26.27V10.92C55.69 9.61513 56.2084 8.36371 57.131 7.44103C58.0537 6.51836 59.3051 6 60.61 6L96.39 6L133.14 34.18Z"
      fill="#1C66DB"
    />
    <path
      d="M84.71 55.17V55.25C84.7134 55.2301 84.7134 55.2099 84.71 55.19V55.19C84.34 53.43 81.05 52.51 79.32 52.51H46L7 22.56H44.25C45.3079 22.575 46.3337 22.9251 47.18 23.56L81.93 50.2C82.1533 50.3602 82.3639 50.5374 82.56 50.73C83.7978 51.8956 84.5631 53.4761 84.71 55.17Z"
      fill="#41E6B2"
    />
    <path
      d="M84.71 55.56V118.97L45.93 89.22L45.94 55.56H84.71Z"
      fill="#41E6B2"
    />
  </svg>
);

Sign.defaultProps = {
  width: 28,
  height: 28,
};

export default Sign;
