import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const DELETE_PARTY = `${namespace}/DELETE_PARTY`;

const DELETE_MUTATION = gql`
  mutation deletePartyData($id: String!) {
    deletePartyData(id: $id) {
      _id
      name
      type {
        _id
        name
      }
      isActive
      hasWaivedRequirements
      hasOverriddenRequirements
      deletedAt
      deletedBy
      status
      website
      isTrackingCompliance
      isSendingExpiringRequest
      address {
        rawAddress
        line1
        line2
        city
        region
        country
        postalCode
        latitude
        longitude
      }
      requests {
        _id
        deletedAt
        deletedBy
        forwardedAt
        forwardedTo
        sentByUser
        party
        message
        attachments {
          url
          friendlyName
          s3Key
        }
        documentChecklists
        sentOn
        organization
        dueDate
        project
        createdAt
        updatedAt
      }
      partyComplianceProfile {
        _id
        isProcessing
        party
        metadata
        complianceProfile
        name
        nextExpirationDate
        latestExpirationDate
        earliestExpirationDate
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
        createdAt
        updatedAt
      }
      subjects {
        subjectId
        subjectLabel
        notes
        requirementComplianceStatusValue
        resetRequirementOn
      }
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        invalidatedBy
        invalidationScope
        publicNotes
        createdAt
        updatedAt
      }
      documentChecklists {
        _id
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
      additionalNotes
      nonresponsiveSince
      lastActivityDate
      totalDocumentsCount
      totalDocumentsToReviewCount
      totalDocumentsReviewedCount
      totalApplicableDocumentsCount
      totalApplicableDocumentsReviewedCount
      totalApplicableDocumentsToReviewCount
      taggings {
        _id
        partyTag {
          _id
          name
          color
        }
        addedAt
        addedBy
        expiresAt
      }
      organization
      createdAt
      updatedAt
      lastRequestSentOn
      nearestFlagSeverityOrder
      externalIds
    }
  }
`;

export const deleteParty = (id) => ({
  type: DELETE_PARTY,
  payload: {
    key: 'deletePartyData',
    graphql: {
      mutation: DELETE_MUTATION,
      variables: {
        id,
      },
    },
  },
});
