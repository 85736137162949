import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PspdfkitConfig from '@config/PspdfkitConfig';
import { getIsAuthenticated } from '@modules/auth/selectors';

const PspdfkitWorkerPreloader = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      import('pspdfkit').then((PSPDFKitWeb) => {
        PSPDFKitWeb.preloadWorker(PspdfkitConfig);
      });
    }
  }, [isAuthenticated]);

  return null;
};

export default PspdfkitWorkerPreloader;
