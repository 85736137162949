import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_ARCHIVE_DOCUMENTS = `${namespace}/BULK_ARCHIVE_DOCUMENTS`;

const BULK_ARCHIVE_DOCUMENTS_MUTATION = gql`
  mutation bulkArchiveDocuments($payload: BulkArchiveDocumentsInput) {
    bulkArchiveDocuments(data: $payload) {
      scheduled
    }
  }
`;

export const bulkArchiveDocuments = (payload) => ({
  type: BULK_ARCHIVE_DOCUMENTS,
  payload: {
    key: 'bulkArchiveDocuments',
    graphql: {
      mutation: BULK_ARCHIVE_DOCUMENTS_MUTATION,
      variables: { payload },
    },
  },
});
