import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { COMPLIANCE_PROFILE_FRAGMENT } from '../fragments';

export const DELETE_COMPLIANCE_PROFILE = `${namespace}/DELETE_COMPLIANCE_PROFILE`;

const DELETE_COMPLIANCE_PROFILE_MUTATION = gql`
  mutation deleteComplianceProfile($payload: DeleteComplianceProfileInput!) {
    deleteComplianceProfile(data: $payload) {
      ...ComplianceProfileFrag
    }
  }
  ${COMPLIANCE_PROFILE_FRAGMENT}
`;

export const deleteComplianceProfile = (payload) => ({
  type: DELETE_COMPLIANCE_PROFILE,
  payload: {
    key: 'deleteComplianceProfile',
    graphql: {
      mutation: DELETE_COMPLIANCE_PROFILE_MUTATION,
      variables: {
        payload: {
          _id: payload,
        },
      },
    },
  },
});
