import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const ISSUES_QUERY = graphql(`
  query ConversationIssues(
    $conversationInput: ConversationInput!
    $options: ConversationMessageIssuesInput
  ) {
    conversation(input: $conversationInput) {
      _id
      issues(options: $options) {
        nodes {
          _id
          email
          message {
            _id
            createdAt
          }
        }
        totalCount
      }
    }
  }
`);

const DAFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
};

export const useMessagesIssues = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const { data, loading, fetchMore } = useQuery(ISSUES_QUERY, {
    variables: {
      conversationInput: { id: conversationId },
      options: {
        ...DEFAULT_INPUT,
        offset: 0,
      },
    },
  });

  const issues = data?.conversation.issues.nodes || [];

  return {
    issues,
    loading,
    issuesCount: data?.conversation.issues.totalCount || 0,
    loadMessageIssues: () => {},
    loadMoreMessageIssues: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: issues.length,
          },
        },
      });
    },
  };
};
