import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useDeletePrimaryRecordMutation } from '@modules/primary-records/hooks/useDeletePrimaryRecordMutation';
import { message } from 'antd';

type DeletePrimaryRecordModalProps = {
  open: boolean;
  primaryRecord: {
    _id: string;
    name: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const DeletePrimaryRecordModal = ({
  open,
  primaryRecord,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: DeletePrimaryRecordModalProps) => {
  const { deletePrimaryRecord, isDeletingPrimaryRecord } =
    useDeletePrimaryRecordMutation();

  const handleDelete = async () => {
    const res = await deletePrimaryRecord(primaryRecord._id);

    const status = res.data?.deletePrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${primaryRecord.name} deleted successfully.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(`${primaryRecord.name} has been scheduled for deletion.`);
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The deletion of your ${primaryRecord.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      data-cy="delete-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isDeletingPrimaryRecord,
      }}
      okText="Delete"
      title={`Are you sure you want to delete ${primaryRecord.name}?`}
      onCancel={onCancel}
      onOk={handleDelete}
    >
      <p>This action cannot be undone</p>
    </ModalConfirm>
  );
};
