import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_TYPE_FRAGMENT } from '../fragments';

export const UPDATE_DOCUMENT_TYPE = `${namespace}/UPDATE_DOCUMENT_TYPE`;

const UPDATE_DOCUMENT_TYPE_MUTATION = gql`
  mutation updateDocumentType($payload: UpdateDocumentTypeInput!) {
    updateDocumentType(data: $payload) {
      ...DocumentType
    }
  }
  ${DOCUMENT_TYPE_FRAGMENT}
`;

export const updateDocumentType = (payload) => ({
  type: UPDATE_DOCUMENT_TYPE,
  payload: {
    key: 'updateDocumentType',
    graphql: {
      mutation: UPDATE_DOCUMENT_TYPE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
