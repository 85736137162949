import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_MEMBER_FRAGMENT } from '../fragments';

export const FETCH_ORGANIZATION_MEMBER_BY_ID = `${namespace}/FETCH_ORGANIZATION_MEMBER_BY_ID`;

const FETCH_ORGANIZATION_MEMBER_BY_ID_QUERY = gql`
  query getOrganizationMember($id: String!) {
    getOrganizationMember(id: $id) {
      ...OrganizationMember
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`;

export const fetchOrganizationMemberById = (id) => ({
  type: FETCH_ORGANIZATION_MEMBER_BY_ID,
  payload: {
    key: 'getOrganizationMember',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_ORGANIZATION_MEMBER_BY_ID_QUERY,
      variables: { id },
    },
  },
});
