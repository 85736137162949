import { Avatar } from 'antd';
import styled from 'styled-components';

import PrimaryStarIcon from '@common/icons/PrimaryStarIcon';
import { getColorFromText } from '@common/utils/color-hash-generator';
import { getAbbreviature } from '@common/utils/text-helpers';

type ContactAvatarProps = {
  avatarURL?: string;
  isPrimary?: boolean;
  name?: string;
};

export const ContactAvatar = ({
  avatarURL,
  isPrimary,
  name,
}: ContactAvatarProps) => {
  return (
    <StyledContactAvatar>
      <StyledAvatar
        size={24}
        src={avatarURL || undefined}
        $color={getColorFromText(name || '')}
      >
        {getAbbreviature(name)}
      </StyledAvatar>
      <>
        {isPrimary && (
          <StyledStarIcon>
            <PrimaryStarIcon width={14} height={14} />
          </StyledStarIcon>
        )}
      </>
    </StyledContactAvatar>
  );
};

const StyledContactAvatar = styled.div`
  position: relative;
`;

const StyledStarIcon = styled.div`
  position: absolute;
  top: 12px;
  left: 16px;
`;

const StyledAvatar = styled(Avatar)<{ $color: string }>`
  background-color: ${({ $color }) => $color || 'none'};
`;
