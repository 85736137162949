import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const DISCONNECT_FROM_QUICKBOOKS = `${namespace}/DISCONNECT_FROM_QUICKBOOKS`;

const DISCONNECT_FROM_QUICKBOOKS_MUTATION = gql`
  mutation disconnectFromQuickbooks {
    disconnectFromQuickbooks
  }
`;

export const disconnectFromQuickbooks = () => ({
  type: DISCONNECT_FROM_QUICKBOOKS,
  payload: {
    key: 'disconnectFromQuickbooks',
    graphql: {
      mutation: DISCONNECT_FROM_QUICKBOOKS_MUTATION,
    },
  },
});
