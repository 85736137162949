import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useMakeActivePrimaryRecordsMutation } from '@modules/primary-records/hooks/useMakeActivePrimaryRecordsMutation';
import { Typography, message } from 'antd';

type MakeActivePrimaryRecordsModalProps = {
  open: boolean;
  count: number;
  filters?: ServerFilterInput;
  primaryObject: {
    name: string;
    pluralName: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const MakeActivePrimaryRecordsModal = ({
  open,
  count = 1,
  primaryObject,
  filters,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: MakeActivePrimaryRecordsModalProps) => {
  const { makeActivePrimaryRecords, isMakingActivePrimaryRecords } =
    useMakeActivePrimaryRecordsMutation();

  const handleMakeActive = async () => {
    const res = await makeActivePrimaryRecords(filters);

    const status = res.data?.activatePrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `${count} ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} made active successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${count} ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} scheduled for activation.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The activation of your ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const name =
    count > 1 ? `${primaryObject.pluralName}` : `${primaryObject.name}`;

  return (
    <ModalConfirm
      data-cy="make-active-primary-records-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        loading: isMakingActivePrimaryRecords,
      }}
      okText="Make active"
      title={`Are you sure you want to make active ${count} ${name}?`}
      onCancel={onCancel}
      onOk={handleMakeActive}
    >
      <Typography.Paragraph>
        Please confirm that you want to make active {count} {name}.
      </Typography.Paragraph>
      <ul>
        <li>
          ${count > 1 ? 'They' : 'It'} will show up in your reports and
          dashboard
        </li>
        <li>Automatic requests for expired documents will be sent</li>
      </ul>
    </ModalConfirm>
  );
};
