import { Button } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import {
  getEditorHTMLContent,
  getEditorPlainText,
  getEmptyEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import type { AttachmentInput } from '@graphql/types/graphql';
import RichTextArea from '@modules/party-conversation/components/RichTextArea';

type Message = {
  text: string;
  html: string;
  attachments: AttachmentInput[];
};

type MessageEditorProps = {
  isSendingMessage: boolean;
  onOk: (message: Message) => void;
};

export const MessageEditor = ({
  onOk,
  isSendingMessage,
}: MessageEditorProps) => {
  const [attachments, setAttachments] = useState([]);
  const [messageEditorState, setMessageEditorState] = useState(
    getEmptyEditorState(),
  );
  const [isAttaching, setIsAttaching] = useState(false);
  const isTextAreaEmpty = isEditorStateBlank(messageEditorState);

  const handleMessageSumbit = async () => {
    if (!isTextAreaEmpty || Boolean(attachments.length)) {
      const message = {
        text: getEditorPlainText(messageEditorState),
        html: getEditorHTMLContent(messageEditorState),
        attachments,
      };

      await onOk(message);

      setMessageEditorState(getEmptyEditorState());
      setAttachments([]);
    }
  };

  return (
    <StyledWrapper>
      <StyledTextEditorWrapper>
        <RichTextArea
          isValid
          focusDependence={false}
          attachments={attachments}
          isAttaching={isAttaching}
          setAttachments={setAttachments}
          setIsAttaching={setIsAttaching}
          textAreaEditorState={messageEditorState}
          onChange={setMessageEditorState}
        />
      </StyledTextEditorWrapper>

      <Button
        type="primary"
        ghost={isTextAreaEmpty}
        disabled={isTextAreaEmpty}
        onClick={handleMessageSumbit}
        loading={isSendingMessage || isAttaching}
      >
        Send
      </Button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledTextEditorWrapper = styled.div`
  flex: 1;
`;
