import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getProjects = R.compose(R.values, R.path([STATE_KEY, 'data']));

export const getProject = (state, id) =>
  R.pathOr(null, [STATE_KEY, 'data', id], state);

export const getCurrentProject = R.pathOr({}, [STATE_KEY, 'currentProject']);

export const getProjectById = (state, id) =>
  R.pathOr(null, [STATE_KEY, 'data', id], state);

export const getTotalProjectsCount = R.pathOr(0, [STATE_KEY, 'totalCount']);

export const getProjectIsLoading = R.pathOr(0, [STATE_KEY, 'isLoading']);

export const getSubscribedProjectsMap = R.pathOr({}, [
  STATE_KEY,
  'subscribedProjectsMap',
]);

export const getProjectPartyData = (state, partyId) =>
  R.path([STATE_KEY, 'projectPartyData', partyId], state);
