import { message as notify } from 'antd';
import type { AnyAction } from 'redux';
import { all, takeLatest } from 'redux-saga/effects';

import { ACTION_ERROR_SUFFIX, ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { CREATE_FILLABLE_FORM, UPDATE_FILLABLE_FORM } from '../actions';

type CreateFillableFormFailedPayload = AnyAction & {
  error: { message: string }[];
};

function createFillableFormFailed({ error }: CreateFillableFormFailedPayload) {
  error.map(({ message }) => notify.error(message));
}

function createUpdateFillableFormSucceeded() {
  notify.success(`Form successfully saved.`);
}

function* fillableFormSagas() {
  yield all([
    takeLatest(
      [
        `${CREATE_FILLABLE_FORM}${ACTION_ERROR_SUFFIX}`,
        `${UPDATE_FILLABLE_FORM}${ACTION_ERROR_SUFFIX}`,
      ],
      createFillableFormFailed,
    ),
    takeLatest(
      [
        `${CREATE_FILLABLE_FORM}${ACTION_SUCCESS_SUFFIX}`,
        `${UPDATE_FILLABLE_FORM}${ACTION_SUCCESS_SUFFIX}`,
      ],
      createUpdateFillableFormSucceeded,
    ),
  ]);
}

export default fillableFormSagas;
