import { namespace } from '../constants';

export const SET_AUTHENTICATED_APP_READY = `${namespace}/SET_AUTHENTICATED_APP_READY`;
export const SET_LATEST_APP_VERSION = `${namespace}/SET_LATEST_APP_VERSION`;
export const SET_APP_VERSION_HARD_REFRESH = `${namespace}/SET_APP_VERSION_HARD_REFRESH`;

export const setAuthenticatedAppReady = () => ({
  type: SET_AUTHENTICATED_APP_READY,
});

export const setLatestAppVersion = (payload: string) => ({
  type: SET_LATEST_APP_VERSION,
  payload,
});

export const setAppVersionHardRefresh = (payload: boolean) => ({
  type: SET_APP_VERSION_HARD_REFRESH,
  payload,
});
