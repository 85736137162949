import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { TEXT_SNIPPETS_FRAGMENT } from '../fragments';

export const DELETE_TEXT_SNIPPET = `${namespace}/DELETE_TEXT_SNIPPET`;

const DELETE_TEXT_SNIPPET_MUTATION = gql`
  mutation deleteTextSnippet($id: String!) {
    deleteTextSnippet(id: $id) {
      ...TextSnippet
    }
  }
  ${TEXT_SNIPPETS_FRAGMENT}
`;

export const deleteTextSnippet = (id) => ({
  type: DELETE_TEXT_SNIPPET,
  payload: {
    key: 'deleteTextSnippet',
    graphql: {
      mutation: DELETE_TEXT_SNIPPET_MUTATION,
      variables: {
        id,
      },
    },
  },
});
