import * as R from 'ramda';

import {
  ADD_UPLOADED_DOCUMENTS_PANEL,
  REMOVE_UPLOADED_DOCUMENTS_PANEL,
  UPDATE_SYSTEM_SETTINGS,
} from '../actions';

const initialState = {};

const SystemSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SYSTEM_SETTINGS: {
      return R.mergeDeepLeft(action.payload, state);
    }
    case ADD_UPLOADED_DOCUMENTS_PANEL: {
      const requestId = R.path(['payload', 'requestId'], action);
      const panels = R.uniq(
        R.append(
          R.path(['payload', 'panel'], action),
          R.pathOr([], ['uploadedDocumentsPanels', requestId], state),
        ),
      );

      return R.assocPath(
        ['uploadedDocumentsPanels', action.payload.requestId],
        panels,
        state,
      );
    }
    case REMOVE_UPLOADED_DOCUMENTS_PANEL: {
      const requestId = R.path(['payload', 'requestId'], action);
      const panels = R.without(
        [R.path(['payload', 'panel'], action)],
        R.pathOr([], ['uploadedDocumentsPanels', requestId], state),
      );

      return R.assocPath(
        ['uploadedDocumentsPanels', action.payload.requestId],
        panels,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export default SystemSettingsReducer;
