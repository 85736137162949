import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { RemoveRequestRecordRequirementWaiverOverrideMutation } from '@graphql/types/graphql';
import { NonWaived } from '@trustlayer/common';

export const REMOVE_REQUEST_RECORD_REQUIREMENT_WAIVER_OVERRIDE_MUTATION =
  graphql(`mutation RemoveRequestRecordRequirementWaiverOverride($data: ToggleRequestRecordRequirementsWaiverInput!) {
  toggleRequestRecordRequirementsWaiver(data: $data) {
    operation {
      code
      name
      status
      progress
    }
    errors {
      code
      message
    }
  }
}
`);

type RemoveWaiverOverrideRequirementVariables = {
  variables: {
    requestId: string;
    code: string;
  };
  onCompleted?: (
    res: RemoveRequestRecordRequirementWaiverOverrideMutation,
  ) => void;
  onError?: (error: Error) => void;
};

export function useRemoveWaiveOverrideRequirementMutation() {
  const [toggleWaiverOverrideRequirement, { loading }] = useMutation(
    REMOVE_REQUEST_RECORD_REQUIREMENT_WAIVER_OVERRIDE_MUTATION,
  );

  function removeWaiverOverrideRequirement({
    variables: { requestId, code },
    onCompleted,
    onError,
  }: RemoveWaiverOverrideRequirementVariables) {
    return toggleWaiverOverrideRequirement({
      variables: {
        data: {
          id: requestId!,
          requirements: [
            {
              attributeCode: code,
              status: NonWaived,
            },
          ],
        },
      },
      onCompleted,
      onError,
    });
  }

  return {
    removeWaiverOverrideRequirement,
    loading,
  };
}
