import {
  type DocumentNode,
  type OperationVariables,
  type QueryHookOptions,
  type QueryResult,
  type TypedDocumentNode,
  useQuery as useApolloQuery,
} from '@apollo/client';
import { useStore } from 'react-redux';

import {
  getDataWithoutTypename,
  getVariablesWithContext,
} from '@graphql/utils';
import { getContext } from '@store/helpers';

export type UseQueryCustomOptions = { includeTypename?: boolean };

const useQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & UseQueryCustomOptions,
): QueryResult<TData, TVariables> => {
  const store = useStore();
  const state = store.getState();
  const context = getContext(state);

  const { includeTypename, ...queryOptions } = options || {};

  const apolloOptions = getVariablesWithContext(queryOptions, context);

  const res = useApolloQuery<TData, TVariables>(query, apolloOptions);

  const data = includeTypename
    ? res.data
    : getDataWithoutTypename<TData>(res.data);

  return {
    ...res,
    data,
  };
};

export default useQuery;
