import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

const ToastMessage = ({
  content,
  actionLabel,
  onActionClick = () => undefined,
  isVisible,
  isError,
  isCloseButtonEnabled,
  messageKey,
  duration = 0,
  onToastDestroy,
}) => {
  const handleButtonClick = useCallback(() => {
    message.destroy(messageKey);
    onActionClick();
  }, [onActionClick, messageKey]);

  const onCloseClick = useCallback(() => {
    message.destroy(messageKey);

    if (onToastDestroy) {
      onToastDestroy();
    }
  }, [messageKey, onToastDestroy]);

  useEffect(() => {
    if (!isVisible) {
      message.destroy(messageKey);
      return;
    }

    message.open({
      key: messageKey,
      duration,
      onClose: onToastDestroy,
      content: (
        <StyledMessageWrapper>
          {isError && <StyledCloseCircleFilled />}
          {content}
          {actionLabel && (
            <StyledButtonLink type="link" onClick={handleButtonClick}>
              {actionLabel}
            </StyledButtonLink>
          )}
          {isCloseButtonEnabled && (
            <StyledCloseWrapper>
              <CloseOutlined onClick={onCloseClick} />
            </StyledCloseWrapper>
          )}
        </StyledMessageWrapper>
      ),
    });

    return () => {
      message.destroy(messageKey);
    };
  }, [
    handleButtonClick,
    content,
    duration,
    actionLabel,
    isVisible,
    onCloseClick,
    messageKey,
    isCloseButtonEnabled,
    isError,
    onToastDestroy,
  ]);

  return null;
};

const StyledMessageWrapper = styled.span`
  position: relative;
`;

const StyledButtonLink = styled(Button)`
  padding: 1px 6px;
`;

const StyledCloseCircleFilled = styled(CloseCircleFilled)`
  color: #ff3636;
`;

const StyledCloseWrapper = styled.span`
  position: absolute;
  top: -28px;
  font-size: 14px;
  right: -28px;
  padding: 1px 5px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);

  .anticon {
    margin: 0;
    font-size: 14px;
    top: 0;
  }
`;

export default ToastMessage;
