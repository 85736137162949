import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const UPDATE_DOCUMENT_FLAG = `${namespace}/UPDATE_DOCUMENT_FLAG`;

const UPDATE_DOCUMENT_FLAG_MUTATION = gql`
  mutation updateDocumentFlag($payload: UpdateDocumentFlagInput!) {
    updateDocumentFlag(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const updateDocumentFlag = (payload) => ({
  type: UPDATE_DOCUMENT_FLAG,
  payload: {
    key: 'updateDocumentFlag',
    graphql: {
      mutation: UPDATE_DOCUMENT_FLAG_MUTATION,
      variables: { payload },
    },
  },
});
