import { gql } from '@apollo/client';

import type { AnyGQLMutationAction, AnyGQLSuccessAction } from '@common/types';
import { ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { namespace } from '../constants';
import { FILLABLE_FORM_FRAGMENT } from '../fragments';

export const DELETE_FILLABLE_FORM =
  `${namespace}/DELETE_FILLABLE_FORM` as const;
export const DELETE_FILLABLE_FORM_SUCCESS =
  `${namespace}/DELETE_FILLABLE_FORM${ACTION_SUCCESS_SUFFIX}` as const;

const DELETE_FILLABLE_FORM_MUTATION = gql`
  mutation deleteForm($_id: String!) {
    deleteForm(_id: $_id) {
      ...Form
    }
  }
  ${FILLABLE_FORM_FRAGMENT}
`;

export type DeleteFillableFormPayload = string;

export type DeleteFillableForm = (
  payload: DeleteFillableFormPayload,
) => AnyGQLMutationAction<
  typeof DELETE_FILLABLE_FORM,
  { _id: DeleteFillableFormPayload }
>;

export const deleteFillableForm: DeleteFillableForm = (_id) => ({
  type: DELETE_FILLABLE_FORM,
  payload: {
    key: 'deleteForm',
    graphql: {
      mutation: DELETE_FILLABLE_FORM_MUTATION,
      variables: {
        _id,
      },
    },
  },
});

export type DeleteFillableFormSuccessAction = AnyGQLSuccessAction<
  typeof DELETE_FILLABLE_FORM_SUCCESS
>;
