import { call, putResolve, takeLatest } from 'redux-saga/effects';

import { SET_AUTHENTICATED_APP_READY } from '@modules/app/actions';

import { trackEvent } from '@common/utils/track-helpers';
import {
  CREATE_OR_UDPATE_TEXT_SNIPPET,
  createTextSnippet,
  fetchTextSnippets,
  updateTextSnippet,
} from '../actions';

function* fetchTextSnippetsSaga() {
  yield putResolve(fetchTextSnippets());
}

function* createOrUpdateTextSnippetSaga({ payload }) {
  if (payload.id) {
    yield putResolve(updateTextSnippet(payload));
    yield call(trackEvent, 'User updated a text snippet');
  } else {
    yield putResolve(createTextSnippet(payload));
    yield call(trackEvent, 'User created a text snippet');
  }
}

function* textSnippetsSagas() {
  yield takeLatest(SET_AUTHENTICATED_APP_READY, fetchTextSnippetsSaga);
  yield takeLatest(
    CREATE_OR_UDPATE_TEXT_SNIPPET,
    createOrUpdateTextSnippetSaga,
  );
}

export default textSnippetsSagas;
