import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, InputNumber, Row, Select } from 'antd';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CustomFieldTypeName from '@modules/custom-field//components/CustomFieldTypeName';
import { CustomFieldType } from '@modules/custom-field/constants';
import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';

import ProjectTextField from './ProjectTextField';

const CustomFieldsTab = ({
  customFields,
  selectedCustomFields,
  onSetSelectedCustomFields,
}) => {
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const addCustomField = (customFieldId) => {
    const data = R.compose(
      (customField) => ({
        customField,
        value: customField.type === CustomFieldType.Dropdown ? '' : '',
      }),
      R.find(R.propEq('_id', customFieldId)),
    )(customFields);

    onSetSelectedCustomFields(R.append(data, selectedCustomFields));
  };

  const updateCustomField = (customFieldId, index) => {
    R.compose(
      onSetSelectedCustomFields,
      (data) =>
        selectedCustomFields.map((x, currentIndex) =>
          currentIndex === index ? data : x,
        ),
      (customField) => ({
        customField,
        value: R.pathOr('', ['options', '0', 'key'], customField),
      }),
      R.find(R.propEq('_id', customFieldId)),
    )(customFields);
  };

  const removeCustomField = (index) => {
    onSetSelectedCustomFields(R.remove(index, 1, selectedCustomFields));
  };

  const updateValue = (value, index) => {
    onSetSelectedCustomFields(
      selectedCustomFields.map((x, currentIndex) =>
        currentIndex === index
          ? {
              customField: x.customField,
              value,
            }
          : x,
      ),
    );
  };

  const availableToSelectCustomField = (availableCustomFieldId) => {
    if (!customFields || !Array.isArray(customFields)) return [];

    return customFields.filter(
      (customField) =>
        availableCustomFieldId === customField._id ||
        !selectedCustomFields.some(
          (selectedCustomField) =>
            selectedCustomField.customField._id === customField._id,
        ),
    );
  };

  return (
    <CustomFieldsTab.Wrapper data-cy="customFieldsTab">
      <CustomFieldsTab.HelpText>
        <p>
          Use{' '}
          <Link to={`${organizationNamespace}/settings/projects`}>
            custom fields
          </Link>{' '}
          to specify additional information on this project. Requirements
          associated to custom fields will be combined with the ones from the
          compliance profiles in use in this project.
        </p>
      </CustomFieldsTab.HelpText>
      {selectedCustomFields.map(({ customField, value }, index) => (
        <Row key={customField._id} gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <CustomFieldsTab.Select
              data-cy="changeCustomField"
              value={customField._id}
              onSelect={(value) => updateCustomField(value, index)}
            >
              {availableToSelectCustomField(customField._id).map(
                (customField) => (
                  <Select.Option key={customField._id} value={customField._id}>
                    <CustomFieldTypeName
                      type={customField.type}
                      title={customField.name}
                    />
                  </Select.Option>
                ),
              )}
            </CustomFieldsTab.Select>
          </Col>
          <Col span={14}>
            {customField.type === CustomFieldType.Dropdown && (
              <CustomFieldsTab.Select
                placeholder="Select..."
                optionFilterProp="children"
                value={value || []}
                onSelect={(value) => updateValue(value, index)}
              >
                {customField.options.map((option) => (
                  <Select.Option key={option.key} value={option.key}>
                    {option.value}
                  </Select.Option>
                ))}
              </CustomFieldsTab.Select>
            )}
            {customField.type === CustomFieldType.Text && (
              <ProjectTextField
                customFieldName={customField.name}
                value={value}
                updateValue={(value) => updateValue(value, index)}
              />
            )}
            {customField.type === CustomFieldType.Number && (
              <InputNumber
                placeholder={`Enter ${customField.name}...`}
                style={{ width: '100%' }}
                onChange={(value) => updateValue(value, index)}
                value={value}
              />
            )}
          </Col>
          <Col span={1}>
            <CustomFieldsTab.Remove
              data-cy="removeCustomFieldButton"
              onClick={() => removeCustomField(index)}
            />
          </Col>
        </Row>
      ))}{' '}
      {availableToSelectCustomField().length > 0 && (
        <Row gutter={16}>
          <Col span={8}>
            <CustomFieldsTab.Select
              data-cy="selectCustomField"
              showSearch
              placeholder="Select a custom field..."
              onSelect={(value) => addCustomField(value)}
              value={[]}
              filterOption={(input, option) =>
                R.pathOr('', ['children', 'props', 'title'], option)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {availableToSelectCustomField().map((customField) => (
                <Select.Option key={customField._id} value={customField._id}>
                  <CustomFieldTypeName
                    type={customField.type}
                    title={customField.name}
                  />
                </Select.Option>
              ))}
            </CustomFieldsTab.Select>{' '}
          </Col>
        </Row>
      )}
    </CustomFieldsTab.Wrapper>
  );
};

CustomFieldsTab.Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;

  > .ant-row {
    align-items: baseline;
  }
`;

CustomFieldsTab.HelpText = styled.div`
  font-size: 13px;
  margin-bottom: 30px;
`;

CustomFieldsTab.Remove = styled(MinusCircleOutlined)`
  font-size: 20px;

  :hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

CustomFieldsTab.Select = styled(Select)`
  width: 100%;
  margin: 0 0 10px;
`;

export default CustomFieldsTab;
