import { gql } from '@apollo/client';

import { namespace } from '../constants';
import {
  PARTY_CONVERSATION_FRAGMENT,
  PARTY_CONVERSATION_MESSAGE_FRAGMENT,
} from '../fragments';

import type { IssueOptions } from './types';

export const FETCH_PARTY_CONVERSATIONS = `${namespace}/FETCH_PARTY_CONVERSATIONS`;

const FETCH_PARTY_CONVERSATIONS_QUERY = gql`
  query listPartyConversations(
    $payload: ListPartyConversationsInput!
    $issuesOptions: PartyMessageIssueInput
    $messagesInput: PartyMessagesInput
  ) {
    listPartyConversations(data: $payload) {
      ...PartyConversation
      messages(input: $messagesInput) {
        totalCount
        nodes {
          ...PartyConversationMessage
        }
      }
      issues(options: $issuesOptions) {
        totalCount
      }
    }
  }
  ${PARTY_CONVERSATION_MESSAGE_FRAGMENT}
  ${PARTY_CONVERSATION_FRAGMENT}
`;

export const fetchPartyConversations = (
  listPartyConversationsInput: {
    requestId?: string;
    organizationId?: string;
    partyId?: string;
  },
  issuesOptions?: IssueOptions,
  messagesInput = {
    first: 1,
    offset: 0,
    sort: {
      createdAt: -1,
    },
  },
) => ({
  type: FETCH_PARTY_CONVERSATIONS,
  payload: {
    key: 'listPartyConversations',
    graphql: {
      query: FETCH_PARTY_CONVERSATIONS_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        payload: listPartyConversationsInput,
        issuesOptions,
        messagesInput,
      },
    },
  },
});
