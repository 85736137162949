import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import styled from 'styled-components';

const FileName = ({
  filename,
  confirmRemoveMessage,
  onClick,
  onRemove = null,
}) => (
  <FileNameWrapper
    onClick={() => (onClick ? onClick() : null)}
    data-cy="filenameWrapper"
  >
    <FileOutlined />
    <span>{filename}</span>
    <FileInfo onClick={(e) => e.stopPropagation()}>
      {onRemove && confirmRemoveMessage ? (
        <Popconfirm
          title={confirmRemoveMessage}
          onConfirm={onRemove}
          okText="Remove"
          cancelText="Cancel"
        >
          <CloseOutlined />
        </Popconfirm>
      ) : (
        <CloseOutlined
          data-cy="fileNameOnRemoveIconButton"
          onClick={onRemove}
        />
      )}
    </FileInfo>
  </FileNameWrapper>
);

export const FileNameWrapper = styled.div`
  margin-bottom: 3px;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid ${(props) => props.theme.colors.gray};
  background-color: ${(props) => props.theme.colors.white};
  line-height: initial;
  width: 100%;

  svg {
    margin: 0 3px;
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none !important;
    color: inherit !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 3px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightBlue};
    border-color: #2eb6f5;
    color: #40a9ff;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;

  > div {
    margin: 0 3px;
  }
`;

export const ToReview = styled.div`
  font-size: 11px;
  cursor: pointer;
`;

export const CloseIcon = styled(CloseOutlined)`
  padding-left: 10px;
  opacity: 0.7;
  cursor: pointer;
`;

export default FileName;
