import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_COMPLIANCE_PROFILE_FRAGMENT } from '../fragments';

export const UPDATE_PARTY_COMPLIANCE_PROFILE = `${namespace}/UPDATE_PARTY_COMPLIANCE_PROFILE`;

const UPDATE_COMPLIANCE_PROFILE_MUTATION = gql`
  mutation updatePartyComplianceProfile(
    $payload: UpdatePartyComplianceProfileInput!
  ) {
    updatePartyComplianceProfile(data: $payload) {
      _id
      ...PartyComplianceProfile
    }
  }
  ${PARTY_COMPLIANCE_PROFILE_FRAGMENT}
`;

export const updatePartyComplianceProfile = (payload) => ({
  type: UPDATE_PARTY_COMPLIANCE_PROFILE,
  payload: {
    key: 'updatePartyComplianceProfile',
    graphql: {
      mutation: UPDATE_COMPLIANCE_PROFILE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
