import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_TYPE_FRAGMENT } from '../fragments';

export const UPDATE_PARTY_TYPE = `${namespace}/UPDATE_PARTY_TYPE`;

const UPDATE_PARTY_TYPE_MUTATION = gql`
  mutation updatePartyType($payload: UpdatePartyTypeInput!) {
    updatePartyType(data: $payload) {
      ...PartyType
    }
  }
  ${PARTY_TYPE_FRAGMENT}
`;

export const updatePartyType = (payload) => ({
  type: UPDATE_PARTY_TYPE,
  payload: {
    key: 'updatePartyType',
    graphql: {
      mutation: UPDATE_PARTY_TYPE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
