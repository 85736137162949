import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const AUTOMATIONS_QUERY = graphql(`
  query AutomationsSelect($input: AutomationsInput) {
    automations(input: $input) {
      totalCount
      nodes {
        _id
        name
      }
    }
  }
`);

export const useAutomationsQuery = () => {
  const {
    data: queryData,
    loading,
    fetchMore,
  } = useQuery(AUTOMATIONS_QUERY, {
    refetchWritePolicy: 'merge',
    pollInterval: 0,
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        offset: 0,
        first: 10,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    automations: queryData?.automations.nodes || [],
    automationsTotalCount: queryData?.automations.totalCount || 0,
    fetchMoreAutomations: fetchMore,
    loading,
  };
};
