import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { FLAG_FRAGMENT } from '../fragments';

const FETCH_FLAGS_BY_PARTY_ID_QUERY = gql`
  query getFlagsById($partyId: String!) {
    getFlagsById(partyId: $partyId) {
      _id
      partyFlags {
        ...Flag
      }
    }
  }
  ${FLAG_FRAGMENT}
`;

export const FETCH_FLAGS_BY_PARTY_ID = `${namespace}/FETCH_FLAGS_BY_PARTY_ID`;

export const fetchFlagsByPartyId = (partyId) => ({
  type: FETCH_FLAGS_BY_PARTY_ID,
  payload: {
    key: 'getFlagsById',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_FLAGS_BY_PARTY_ID_QUERY,
      variables: { partyId },
    },
  },
});
