import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { TEXT_SNIPPETS_FRAGMENT } from '../fragments';

export const CREATE_TEXT_SNIPPET = `${namespace}/CREATE_TEXT_SNIPPET`;

const CREATE_TEXT_SNIPPET_MUTATION = gql`
  mutation createTextSnippet($payload: CreateTextSnippetInput!) {
    createTextSnippet(data: $payload) {
      ...TextSnippet
    }
  }
  ${TEXT_SNIPPETS_FRAGMENT}
`;

export const createTextSnippet = (payload) => ({
  type: CREATE_TEXT_SNIPPET,
  payload: {
    key: 'createTextSnippet',
    graphql: {
      mutation: CREATE_TEXT_SNIPPET_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
