const TwoCheckMarksIcon = ({ color = '#1890ff' }) => (
  <span data-cy="readIcon">
    <svg
      width="17"
      height="9"
      viewBox="0 0 17 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5.25112L4.08731 9L5.11828 8.04769L1.03828 4.30551L0 5.25112ZM15.9617 0L8.22581 7.10209L5.18409 4.30551L4.13849 5.25112L8.22581 9L17 0.95231L15.9617 0ZM12.8615 0.95231L11.8305 0L7.18753 4.25857L8.22581 5.20417L12.8615 0.95231Z"
        fill={color}
      />
    </svg>
  </span>
);

export default TwoCheckMarksIcon;
