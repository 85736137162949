import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PERSONAL_ACCESS_TOKENS_BY_ORGANIZATION_ID = `${namespace}/FETCH_PERSONAL_ACCESS_TOKENS_BY_ORGANIZATION_ID`;

const FETCH_PERSONAL_ACCESS_TOKENS_BY_ORGANIZATION_ID_QUERY = gql`
  query getOrganization($id: String!) {
    getOrganization(id: $id) {
      id
      personalAccessTokens {
        _id
        name
        createdAt
        level
      }
    }
  }
`;

export const fetchPersonalAccessTokensByOrganizationId = (id) => ({
  type: FETCH_PERSONAL_ACCESS_TOKENS_BY_ORGANIZATION_ID,
  payload: {
    key: 'getOrganization',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PERSONAL_ACCESS_TOKENS_BY_ORGANIZATION_ID_QUERY,
      variables: { id },
    },
  },
});
