import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const DELETE_ORGANIZATION = `${namespace}/DELETE_ORGANIZATION`;

const DELETE_ORGANIZATION_MUTATION = gql`
  mutation deleteOrganization($id: String!) {
    deleteOrganization(id: $id) {
      id
      name
      slugifyName
      type
      website
      logo
      primaryContactName
      primaryContactEmail
      primaryContactPhone
      primaryContactTimezone
      billingManager
      createdAt
      updatedAt
    }
  }
`;

export const deleteOrganization = (id) => ({
  type: DELETE_ORGANIZATION,
  payload: {
    key: 'deleteOrganization',
    graphql: {
      mutation: DELETE_ORGANIZATION_MUTATION,
      variables: { id },
    },
  },
});
