import * as R from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getActiveOrganizationId } from '@modules/organization/selectors';
import { getLocation } from '@modules/router/selectors';

import {
  SET_CURRENT_PROJECT_ID,
  SET_LAST_USED_EFFECTIVE_DATE,
  SET_LAST_USED_EXPIRATION_DATE,
  SET_PREFERRED_SEARCH_PARAMS,
  UPDATE_ADVANCED_PARTY_COMPLIANCE_INDICATOR,
  updateSystemSettings,
} from '../actions';
import {
  getLastUsedEffectiveDates,
  getLastUsedExpirationDates,
} from '../selectors';
import getEntityByPathname from '../utils/getEntityByPathname';

function* updateSystemSettingsSaga({ type, payload }) {
  const getData = (entity) => {
    switch (type) {
      case SET_LAST_USED_EFFECTIVE_DATE: {
        return {
          lastUsedEffectiveDates: R.compose(
            R.slice(0, 5),
            R.prepend(payload),
          )(lastUsedEffectiveDates),
        };
      }
      case SET_LAST_USED_EXPIRATION_DATE: {
        return {
          lastUsedExpirationDates: R.compose(
            R.slice(0, 5),
            R.prepend(payload),
          )(lastUsedExpirationDates),
        };
      }
      case SET_CURRENT_PROJECT_ID: {
        return { currentProjectId: payload };
      }
      case SET_PREFERRED_SEARCH_PARAMS: {
        return { [entity]: payload };
      }
      case UPDATE_ADVANCED_PARTY_COMPLIANCE_INDICATOR: {
        return {
          advancedPartyComplianceIndicator: payload,
        };
      }
      default:
        return null;
    }
  };

  const organizationId = yield select(getActiveOrganizationId);
  const { pathname } = yield select(getLocation);
  const lastUsedEffectiveDates = yield select(getLastUsedEffectiveDates);
  const lastUsedExpirationDates = yield select(getLastUsedExpirationDates);
  const entity = getEntityByPathname(pathname);

  const data = getData(entity);

  if (data && Boolean(organizationId)) {
    yield put(updateSystemSettings({ [organizationId]: data }));
  }
}

function* systemSettingsSagas() {
  yield takeLatest(
    [
      SET_LAST_USED_EFFECTIVE_DATE,
      SET_LAST_USED_EXPIRATION_DATE,
      SET_CURRENT_PROJECT_ID,
      SET_PREFERRED_SEARCH_PARAMS,
      UPDATE_ADVANCED_PARTY_COMPLIANCE_INDICATOR,
    ],
    updateSystemSettingsSaga,
  );
}

export default systemSettingsSagas;
