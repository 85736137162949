export const SCHEDULED_ACTIONS_STATUS = {
  waiting: 'waiting',
  running: 'running',
  succeeded: 'succeeded',
  failed: 'failed',
} as const;

export const PARTY_AUTOMATION_STATUS = {
  enabled: 'enabled',
  disabled: 'disabled',
} as const;

export const BULK_ACTION = {
  disable: 'disable',
  enable: 'enable',
} as const;
