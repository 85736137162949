import { ACTION_TYPES } from '@modules/request-records';
import { RequirementComplianceStatus } from '@trustlayer/common';
import dayjs, { type Dayjs } from 'dayjs';
import { ActionType, ItemType } from './constants';

export const isDateDisabled = (current: Dayjs) =>
  current < dayjs().endOf('day');

export const getSubjectInfoSubtitle = (actionTypeLabel: string) => {
  const label = actionTypeLabel === ActionType.waive ? 'waived' : 'overridden';

  return `All existing and added requirements from this compliance subject will be ${label} while is active.`;
};

export const getActionKey = (
  actionType: ACTION_TYPES,
  status: RequirementComplianceStatus,
) => {
  if (
    actionType === ACTION_TYPES.waive ||
    (actionType === ACTION_TYPES.edit &&
      status === RequirementComplianceStatus.Waived)
  ) {
    return ActionType.waive;
  }

  if (
    actionType === ACTION_TYPES.override ||
    (actionType === ACTION_TYPES.edit &&
      status === RequirementComplianceStatus.Overridden)
  ) {
    return ActionType.override;
  }

  // It should never reach here
  return undefined;
};

export function getWaiveOverrideMutationPayload({
  code,
  actionType,
  itemType,
  status,
  notes,
  resetOn,
}: {
  code: string;
  status: RequirementComplianceStatus;
  itemType: ItemType;
  actionType: ActionType;
  notes?: string;
  resetOn?: string | null;
}) {
  const trimmedNotes = notes?.trim();
  const payloadNotes = trimmedNotes?.length ? trimmedNotes : undefined;
  const payloadStatus =
    status === RequirementComplianceStatus.Waived ||
    status === RequirementComplianceStatus.Overridden
      ? status
      : undefined;
  const payloadStatusBasedOnActionType =
    actionType === ActionType.waive
      ? RequirementComplianceStatus.Waived
      : RequirementComplianceStatus.Overridden;

  if (itemType === ItemType.subject) {
    return {
      code,
      status: payloadStatus ?? payloadStatusBasedOnActionType,
      notes: payloadNotes,
      resetOn: resetOn ?? undefined,
    };
  }

  return {
    attributeCode: code,
    status: payloadStatus ?? payloadStatusBasedOnActionType,
    notes: payloadNotes,
    resetOn: resetOn ?? undefined,
  };
}
