import * as R from 'ramda';

const appConfig = {
  apiUrl: R.defaultTo(
    'http://localhost:4000',
    import.meta.env.VITE_TRUSTLAYER_API_URL,
  ),
  graphqlApiUrl: R.defaultTo(
    'http://localhost:4000/graphql',
    import.meta.env.VITE_TRUSTLAYER_GRAPHQL_API_URL,
  ),
  graphqlApiWsUrl: R.defaultTo(
    'ws://localhost:4000/graphql',
    import.meta.env.VITE_TRUSTLAYER_API_GRAPHQL_WS_URL,
  ),
  shortSha: R.defaultTo('', import.meta.env.VITE_SHORT_SHA),
  platformVersion: R.defaultTo('', import.meta.env.VITE_PLATFORM_VERSION),
};

export default appConfig;
