import {
  ARCHIVE_STATUS,
  REVIEW_STATUS,
  VERIFICATION_STATUS,
} from '@modules/document/constants';

import { getReportDateFilter } from '@modules/report/utils';
import type { LegacyQueryFilters, TableFiltersModel } from '../types';
import { addContextFilters } from './addContextFilters';

const getLegacyOptionFilter = (values: string[], code: string) => {
  if (values.length === 2) {
    return undefined;
  }

  if (values.includes(code)) {
    return null;
  }

  return {
    $ne: null,
  };
};

export const mapTableFiltersToLegacyDocumentsFilters = (
  tableFilters: TableFiltersModel | null | undefined,
  options: {
    primaryRecordId?: string;
    contextRecordId?: string;
  },
) => {
  if (!tableFilters) {
    return {};
  }

  const { primaryRecordId, contextRecordId } = options;

  // eslint-disable-next-line fp/no-let
  let filters: LegacyQueryFilters = {};

  if (tableFilters.type) {
    filters = {
      ...filters,
      types: tableFilters.type,
    };
  }

  if (tableFilters.friendlyName) {
    filters = {
      ...filters,
      friendlyName: tableFilters.friendlyName.filter as string | undefined,
    };
  }

  if (tableFilters.subjects) {
    filters = {
      ...filters,
      subjects: Array.isArray(tableFilters.subjects)
        ? tableFilters.subjects
        : [tableFilters.subjects],
    };
  }

  if (tableFilters.severity) {
    filters = {
      ...filters,
      'flag.severityLevel': {
        $in: tableFilters.severity.values as string[],
      },
    };
  }

  if (tableFilters.projects) {
    filters = {
      ...filters,
      associatedProjects: tableFilters.projects,
    };
  }

  if (tableFilters.reviewStatus) {
    const options = getLegacyOptionFilter(
      tableFilters.reviewStatus.values as string[],
      REVIEW_STATUS.ToReview,
    );

    filters = {
      ...filters,
      ...(options !== undefined && { reviewedAt: options }),
    };
  }

  if (tableFilters.archiveStatus) {
    const options = getLegacyOptionFilter(
      tableFilters.archiveStatus.values as string[],
      ARCHIVE_STATUS.NonArchived,
    );

    filters = {
      ...filters,
      ...(options !== undefined && { archivedAt: options }),
    };
  }

  if (tableFilters.verification) {
    const options = getLegacyOptionFilter(
      tableFilters.verification.values as string[],
      VERIFICATION_STATUS.NotVerified,
    );

    filters = {
      ...filters,
      ...(options !== undefined && { verification: options }),
    };
  }

  if (tableFilters.appliesToAllProjects) {
    filters = {
      ...filters,
      appliesToAllProjects: true,
    };
  }

  if (tableFilters.createdAt) {
    filters = {
      ...filters,
      ...getReportDateFilter(tableFilters.createdAt, 'uploadDate'),
    };
  }

  if (tableFilters.expirationDate) {
    filters = {
      ...filters,
      ...getReportDateFilter(tableFilters.expirationDate, 'expirationDate'),
    };
  }

  if (tableFilters.requirementId) {
    filters = {
      ...filters,
      requirementId: tableFilters.requirementId.filter as string | undefined,
    };
  }

  /**
   * @note "contextOptions" are used to force some filters based on the context (global or context record, or based on primray record),
   * the user is not able to change them from the table UI.
   */
  return addContextFilters({
    activeFilters: filters,
    contextOptions: { primaryRecordId, contextRecordId },
  });
};
