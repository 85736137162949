import { Form, Input, Modal, message } from 'antd';
import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';

import { bulkAddRequirementValue } from '../actions';

const AddRequirementValueModal = ({
  visible,
  onCancel,
  onSuccess,
  documents,
  documentsFilter,
  selectedDocumentsCount,
  areAllDocumentsSelected,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleOk = async () => {
    if (form) {
      const filterQuery = !areAllDocumentsSelected
        ? {
            ids: documents,
          }
        : documentsFilter;
      try {
        const { requirementId, requirementValue = '' } =
          await form.validateFields();

        await dispatch(
          bulkAddRequirementValue({
            filterQuery,
            requirementId,
            requirementValue,
          }),
        );

        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )} scheduled to add requirement value`,
        );

        onSuccess();
      } catch (e) {}
    }
  };

  return (
    <Modal
      destroyonClose={true}
      title={`Add requirement value to ${pluralize(
        'document',
        selectedDocumentsCount,
        true,
      )}`}
      open={visible}
      onOk={handleOk}
      okButtonProps={{
        'data-cy': 'addRequirementValueSubmit',
      }}
      okText="Add requirement value"
      onCancel={onCancel}
      width={750}
    >
      <Form
        form={form}
        preserve={false}
        layout="vertical"
        name="addRequirementValueForm"
      >
        <Form.Item
          label="Requirement ID"
          name="requirementId"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input
            placeholder="Enter requirement ID..."
            data-cy="requirementId"
          />
        </Form.Item>
        <Form.Item label="Requirement value" name="requirementValue">
          <Input
            placeholder="Enter requirement value..."
            data-cy="requirementValue"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRequirementValueModal;
