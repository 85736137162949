import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FORWARD_REQUEST = `${namespace}/FORWARD_REQUEST`;

const FORWARD_REQUEST_MUTATION = gql`
  mutation forwardRequest($payload: ForwardRequestInput!) {
    forwardRequest(data: $payload) {
      _id
      lastRequestSentOn
      partyContacts {
        _id
        party
        contactPersonName
        companyName
        email
        phone
        fax
        title
        avatar
        isPrimary
        createdAt
        updatedAt
      }
      requests {
        _id
        deletedAt
        deletedBy
        forwardedAt
        forwardedTo
        sentByUser
        party
        message
        attachments {
          url
          friendlyName
          s3Key
        }
        documentChecklists
        sentOn
        organization
        dueDate
        project
        createdAt
        updatedAt
      }
    }
  }
`;

export const forwardRequest = (payload) => ({
  type: FORWARD_REQUEST,
  payload: {
    key: 'forwardRequest',
    graphql: {
      mutation: FORWARD_REQUEST_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
