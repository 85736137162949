import { useEffect, useState } from 'react';

import { SortDirectionsMap } from '@common/constants';
import { useMutation, useQuery } from '@graphql/hooks';
import useNotificationUpdatesSubscription from '@modules/notification/hooks/useNotificationUpdatesSubscription';

import { DEFAULT_NOTIFICATION_SIZE } from '../../../constants';
import { MARK_NOTIFICATION_MUTATION } from '../../../mutations';
import { NotificationType } from '../../../types';
import {
  NOTIFICATIONS_LIST_QUERY,
  NOTIFICATIONS_LIST_TOTAL_COUNT_QUERY,
} from '../queries';

export enum NotificationTab {
  unread = 'unread-notifications-tab',
  read = 'read-notifications-tab',
}

const useNotifications = ({
  type,
  isRead,
}: {
  type?: NotificationType;
  isRead: boolean;
}) => {
  const [unreadTotalCount, setUnreadTotalCount] = useState(0);
  const [readTotalCount, setReadTotalCount] = useState(0);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  const notificationsQuery = useQuery(NOTIFICATIONS_LIST_QUERY, {
    variables: {
      first: DEFAULT_NOTIFICATION_SIZE,
      offset: 0,
      filter: {
        isRead: isRead,
        type: type,
      },
      sort: {
        createdAt: SortDirectionsMap.desc,
      },
    },
    onCompleted: (data) => {
      const setter = isRead ? setReadTotalCount : setUnreadTotalCount;
      setter(data.notifications?.totalCount ?? 0);
    },
  });

  const otherTabTotalCountQuery = useQuery(
    NOTIFICATIONS_LIST_TOTAL_COUNT_QUERY,
    {
      variables: {
        filter: {
          isRead: !isRead,
          type,
        },
      },
      onCompleted: (data) =>
        (isRead ? setUnreadTotalCount : setReadTotalCount)(
          data.notifications?.totalCount ?? 0,
        ),
    },
  );

  const [markNotifications] = useMutation(MARK_NOTIFICATION_MUTATION);

  const fetchMoreNotifications = () => {
    return notificationsQuery.fetchMore({
      variables: {
        offset: notificationsQuery.data?.notifications?.nodes.length,
      },
    });
  };

  const fetchInitialNotifications = async () => {
    await notificationsQuery.refetch();
    await otherTabTotalCountQuery.refetch();
    setHasNewNotifications(false);
  };

  const markNotification = async (
    notificationId: string,
    isRead: boolean,
  ): Promise<void> => {
    markNotifications({
      variables: {
        data: {
          isRead,
        },
        filter: {
          ids: [notificationId],
        },
      },
      update(cache) {
        try {
          const variables = {
            filter: {
              isRead: !isRead,
              type,
            },
            sort: {
              createdAt: SortDirectionsMap.desc,
            },
          };

          const queryRes = cache.readQuery({
            query: NOTIFICATIONS_LIST_QUERY,
            variables,
          });

          cache.writeQuery({
            query: NOTIFICATIONS_LIST_QUERY,
            variables,
            data: {
              notifications: {
                totalCount: queryRes?.notifications?.totalCount
                  ? queryRes?.notifications.totalCount - 1
                  : (queryRes?.notifications?.totalCount ?? 0),
                nodes:
                  queryRes?.notifications?.nodes.filter(
                    (notification) => notification._id !== notificationId,
                  ) || [],
              },
            },
          });

          otherTabTotalCountQuery.refetch();
        } catch (err) {
          console.error(err);
        }
      },
    });
  };

  const markAllNotifications = async () => {
    markNotifications({
      variables: {
        filter: {
          type,
        },
        data: {
          isRead: !isRead,
        },
      },
      onCompleted: fetchInitialNotifications,
    });
  };

  useNotificationUpdatesSubscription({
    onSubscriptionData: (options) => {
      try {
        const { type: newNotificationType } =
          options?.subscriptionData?.data?.notificationUpdates?.notification ||
          {};

        if (type !== newNotificationType && type !== NotificationType.All) {
          return;
        }

        setHasNewNotifications(true);
      } catch (err) {
        console.error(err);
      }
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    setHasNewNotifications(false);
  }, [isRead, type]);

  return {
    notifications: notificationsQuery.data?.notifications?.nodes || [],
    isLoadingNotifications:
      notificationsQuery.loading || otherTabTotalCountQuery.loading,
    unreadTotalCount,
    readTotalCount,
    isLoadingMoreNotifications: false,
    fetchMoreNotifications,
    fetchInitialNotifications,
    markAllNotifications,
    markNotification,
    hasNewNotifications,
  };
};

export default useNotifications;
