import { gql } from '@apollo/client';
import { message as notify } from 'antd';
import * as R from 'ramda';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import graphqlClient from '@graphql/client';
import { updatePartyContactsState } from '@modules/party/actions';

import {
  CLOSE_CONVERSATIONS,
  OPEN_CONVERSATIONS,
  deleteConversationState,
  updateConversationState,
} from '../actions';
import { ConversationSubscriptionType } from '../constants';
import {
  PARTY_CONVERSATION_FRAGMENT,
  PARTY_CONVERSATION_MESSAGE_FRAGMENT,
} from '../fragments';

export const CONVERSATION_UPDATE_SUBSCRIPTION = gql`
  subscription conversationUpdates(
    $partyId: ObjectId!
    $issuesOptions: PartyMessageIssueInput
    $messagesInput: PartyMessagesInput
  ) {
    conversationUpdates(partyId: $partyId) {
      type
      partyConversation {
        ...PartyConversation
        messages(input: $messagesInput) {
          totalCount
          nodes {
            ...PartyConversationMessage
          }
        }
        issues(options: $issuesOptions) {
          totalCount
        }
      }
      newPartyContact
      error
    }
  }
  ${PARTY_CONVERSATION_FRAGMENT}
  ${PARTY_CONVERSATION_MESSAGE_FRAGMENT}
`;

function* handleConversationSubscriptionByType(payload) {
  const type = R.pathOr('', ['data', 'conversationUpdates', 'type'], payload);

  switch (type) {
    case ConversationSubscriptionType.ConversationMessageFailed: {
      const res = R.path(['data', 'conversationUpdates'], payload);
      if (R.prop('partyConversation', res)) {
        yield put(updateConversationState(payload));
      } else if (R.prop('deletedPartyConversation', res)) {
        yield put(deleteConversationState(payload));
      }
      yield call(notify.destroy);
      yield call(notify.error, R.prop('error', res));
      break;
    }
    case ConversationSubscriptionType.ConversationMessageSent: {
      yield call(notify.success, 'Message successfully sent');
      break;
    }
    case ConversationSubscriptionType.ConversationUpdate: {
      yield put(updateConversationState(payload));
      yield put(updatePartyContactsState(payload));
      break;
    }
    default:
      return;
  }
}

function* subscribeToConversationUpdateSaga(action) {
  try {
    const conversationChannel = yield call(() =>
      eventChannel((emit) => {
        const subscription = graphqlClient
          .subscribe({
            query: CONVERSATION_UPDATE_SUBSCRIPTION,
            variables: {
              partyId: action.payload,
              messagesInput: {
                first: 500,
                offset: 0,
              },
              issuesOptions: { filter: { resolved: false } },
            },
          })
          .subscribe({
            next(data) {
              emit(data);
            },
          });

        const unsubscribe = () => {
          subscription.unsubscribe();
        };

        return unsubscribe;
      }),
    );

    yield takeEvery(CLOSE_CONVERSATIONS, () => {
      conversationChannel.close();
    });

    // eslint-disable-next-line fp/no-loops
    while (true) {
      try {
        const payload = yield take(conversationChannel);
        yield fork(handleConversationSubscriptionByType, payload);
      } catch (err) {
        console.error('Socket error:', err);
      }
    }
  } catch (err) {
    console.error('Saga error:', err);
  }
}

function* partyConversationSagas() {
  yield all([
    takeLatest(OPEN_CONVERSATIONS, subscribeToConversationUpdateSaga),
  ]);
}

export default partyConversationSagas;
