import moment from 'moment';

import { BadgeColors } from '../model/badges';

// Empty date status.
const EmptyStatus = {
  color: BadgeColors.grayIcon,
  status: null,
};

/**
 * Get simplified information about effective date.
 */
export const getEffectiveStatus = (effectiveDate) => {
  const daysBeforeStart = getDateBeforeStart(effectiveDate);

  if (Boolean(daysBeforeStart) && daysBeforeStart > 0) {
    return {
      color: BadgeColors.orangeIcon,
      status: `Starts in ${daysBeforeStart} days`,
    };
  }

  return EmptyStatus;
};

/**
 * Get simplified information about expiration date.
 */
export const getExpirationStatus = (expirationDate) => {
  if (expirationDate) {
    const hours = moment(expirationDate).diff(moment(), 'hours');

    if (hours > 0 && hours < 24) {
      return {
        color: BadgeColors.orangeIcon,
        status: `Expires today`,
      };
    }

    if (hours > 0 && hours < 30 * 24) {
      return {
        color: BadgeColors.orangeIcon,
        status: `Expiring ${moment(expirationDate).fromNow()}`,
      };
    }

    if (hours <= 0) {
      return {
        color: BadgeColors.redIcon,
        status: `Expired ${moment(expirationDate).fromNow()}`,
      };
    }
  }

  return EmptyStatus;
};

/**
 * Get date from day.
 */
export const getRemainingDays = (date) =>
  moment(date).startOf('day').diff(moment().startOf('day'), 'days');

/**
 * Get remaining days from received date.
 */
export const getDateBeforeStart = (date) =>
  Math.floor(moment.duration(moment(date).diff(moment())).asDays());

/**
 * Get flag level by order.
 */
export const getSeverityLevelByOrder = (nearestFlagSeverityOrder) => {
  switch (nearestFlagSeverityOrder) {
    case 3:
      return 'high';
    case 2:
      return 'medium';
    case 1:
      return 'low';
    default:
      return null;
  }
};
