import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_TEMPLATE_FRAGMENT } from '../fragments';

export const FETCH_EMAIL_TEMPLATES = `${namespace}/FETCH_EMAIL_TEMPLATES`;
const FETCH_EMAIL_TEMPLATES_QUERY = gql`
  query listEmailTemplates {
    listEmailTemplates {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const fetchEmailTemplates = ({
  query = FETCH_EMAIL_TEMPLATES_QUERY,
} = {}) => ({
  type: FETCH_EMAIL_TEMPLATES,
  payload: {
    key: 'listEmailTemplates',
    graphql: {
      fetchPolicy: 'no-cache',
      query,
    },
  },
});
