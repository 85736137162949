import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { FullStory, init as FullStoryInit } from '@fullstory/browser';
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';
import { LicenseManager } from 'ag-grid-enterprise';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import packageJson from '../package.json';

import App from './App';
import { AG_GRID_CONFIG } from './config/AgGridConfig';
import FullStoryConfig from './config/FullStoryConfig';
import SentryConfig from './config/SentryConfig';
import { persistor, store } from './store/configureStore';

// Set Ag-Grid license key
LicenseManager.setLicenseKey(AG_GRID_CONFIG.licenseKey);

// Initialize Segment; it will be conditionally loaded via Google Tag Manager
window.analytics = new AnalyticsBrowser();

// Initialize FullStory
FullStoryInit(FullStoryConfig);

// Initialize Sentry
Sentry.init(SentryConfig);
Sentry.setTag('appVersion', packageJson.version);

// Forwarding Segment's identify and track calls to FullStory
window.analytics.on('identify', (uid, properties) => {
  FullStory('setIdentity', { uid, properties });
});

window.analytics.on('track', (name, properties) => {
  FullStory('trackEvent', { name, properties });
});

async function enableMocking() {
  if (import.meta.env.DEV) {
    const { worker } = await import('./mocks/browser');
    return worker.start({ onUnhandledRequest: 'bypass' });
  }
}

const mountEl = document.getElementById('root');
const root = createRoot(mountEl!);
export const PersistorContext = React.createContext(persistor);

enableMocking().then(() => {
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <PersistGate persistor={persistor} />
        <PersistorContext.Provider value={persistor}>
          <App />
        </PersistorContext.Provider>
      </React.StrictMode>
    </Provider>,
  );
});

export { store };
