import { CalendarOutlined, CheckCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';

import { SCHEDULED_ACTIONS_STATUS } from '../../constants';
import type { QueryPartyAutomation } from '../../queries';

import { getHuminzedDurationDiffWithNow } from './PartyAutomationScheduledActions.utils';

export type OnPartyAutomationScheduledActionsViewClick = (params: {
  partyConversationId?: string;
  messageId?: string;
}) => void;

type PartyAutomationSchedulesProps = {
  actions: QueryPartyAutomation['scheduledActions'];
  onViewClick: OnPartyAutomationScheduledActionsViewClick;
};

export const PartyAutomationScheduledActions = ({
  actions,
  onViewClick,
}: PartyAutomationSchedulesProps) => {
  return (
    <section>
      <StyledList>
        {actions?.map((action, index) => (
          <StyledItem key={`action-${index}`} data-cy="schduledAction">
            {action.status === SCHEDULED_ACTIONS_STATUS.succeeded ? (
              <>
                <StyledInnerItemWrap>
                  <StyledCheckIcon data-cy="partyAutmationCheckIcon" />
                  <StyledItemText>
                    {`Reminder ${index + 1} (${getHuminzedDurationDiffWithNow({
                      executedDate: action.executedAt || '',
                    })})`}
                  </StyledItemText>
                </StyledInnerItemWrap>
                <StyledLink
                  onClick={() => {
                    onViewClick({
                      partyConversationId: action.result?.partyConversationId,
                      messageId: action.result?.messageId,
                    });
                  }}
                >
                  View
                </StyledLink>
              </>
            ) : (
              <StyledInnerItemWrap>
                <StyledCalendarIcon data-cy="partyAutmationCalendarIcon" />
                <StyledItemText>
                  {`Reminder ${index + 1} (${moment(action.scheduledAt).format(
                    'll',
                  )})`}
                </StyledItemText>
              </StyledInnerItemWrap>
            )}
          </StyledItem>
        ))}
      </StyledList>
    </section>
  );
};

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 300px;
  max-height: 400;
`;

const StyledCalendarIcon = styled(CalendarOutlined)`
  color: ${({ theme }) => theme.colors.lightTextGray};
`;

const StyledCheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }) => theme.colors.limeGreen};
`;

const StyledInnerItemWrap = styled.div`
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledItem = styled.li`
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledItemText = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-size: 14px;
`;

const StyledLink = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  padding: 0;
  margin-left: 15px;
  cursor: pointer;
`;
