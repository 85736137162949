import { gql } from '@apollo/client';

import { ORGANIZATION_FRAGMENT } from '@modules/organization/fragments';

import { namespace } from '../constants';

export const REMOVE_QUICKBOOKS_CREDENTIALS = `${namespace}/REMOVE_QUICKBOOKS_CREDENTIALS`;

const REMOVE_QUICKBOOKS_CREDENTIALS_MUTATION = gql`
  mutation removeQuickbooksCredentials($realmId: String!) {
    removeQuickbooksCredentials(realmId: $realmId) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const removeQuickbooksCredentials = (realmId) => ({
  type: REMOVE_QUICKBOOKS_CREDENTIALS,
  payload: {
    key: 'removeQuickbooksCredentials',
    graphql: {
      mutation: REMOVE_QUICKBOOKS_CREDENTIALS_MUTATION,
      variables: {
        realmId,
      },
    },
  },
});
