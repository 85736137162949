import { DatePicker } from 'antd';
import type React from 'react';
import styled from 'styled-components';

export const FullWidthSpace = (
  props: React.ComponentPropsWithoutRef<typeof DatePicker>,
) => {
  return <StyledDatePicker {...props} />;
};

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default FullWidthSpace;
