import { Checkbox, Popconfirm, Skeleton } from 'antd';
import styled from 'styled-components';

type CheckboxesListProps = {
  items: { id: string; label: string; isActive: boolean }[];
  onChange: (id: string) => void;
  getWarningText: (label: string) => string;
  showWarning: boolean;
  loading?: boolean;
};

export const CheckboxesList = ({
  items,
  onChange,
  getWarningText,
  showWarning,
  loading,
}: CheckboxesListProps) => {
  if (loading) {
    return (
      <StyledSkeletonWrapper>
        <Skeleton.Input active size="small" />
        <Skeleton.Input active size="small" />
      </StyledSkeletonWrapper>
    );
  }
  return (
    <StyledList>
      {items.map(({ id, label, isActive }) => (
        <li key={id}>
          {showWarning && isActive ? (
            <Popconfirm
              placement="right"
              title={
                <div style={{ width: '250px' }}>{getWarningText(label)}</div>
              }
              onConfirm={() => {
                onChange(id);
              }}
            >
              <Checkbox checked={isActive}>{label}</Checkbox>
            </Popconfirm>
          ) : (
            <Checkbox
              checked={isActive}
              onChange={() => {
                onChange(id);
              }}
            >
              {label}
            </Checkbox>
          )}
        </li>
      ))}
    </StyledList>
  );
};

CheckboxesList.ListTitle = styled.h3`
  color: ${(props) => props.theme.colors.grayText};
  font-size: 13px;
  text-transform: uppercase;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    padding: 4px 0;
  }

  .ant-checkbox-wrapper {
    font-size: 13px;
  }
`;

const StyledSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;

  && .ant-skeleton-input-sm {
    width: 80%;
  }
`;
