import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_TYPE_FRAGMENT } from '../fragments';

export const CREATE_PARTY_TYPE = `${namespace}/CREATE_PARTY_TYPE`;

const CREATE_PARTY_TYPE_MUTATION = gql`
  mutation createPartyType($payload: CreatePartyTypeInput!) {
    createPartyType(data: $payload) {
      ...PartyType
    }
  }
  ${PARTY_TYPE_FRAGMENT}
`;

export const createPartyType = (payload) => ({
  type: CREATE_PARTY_TYPE,
  payload: {
    key: 'createPartyType',
    graphql: {
      mutation: CREATE_PARTY_TYPE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
