import { AlignLeftOutlined } from '@ant-design/icons';
import { Button, Cascader, Input, Radio, Tooltip } from 'antd';
import styled from 'styled-components';

import { useState } from 'react';
import { type Rules, useRequirementsRules } from './hooks/useRequirementsRules';

import DefinedRequirement from '@modules/compliance-profile/components/DefinedRequirement';
import { displayRule } from '@modules/compliance-profile/utils/rule-helpers';
import { MATCH_OPTIONS, operators } from '../../constants';

type Operator = keyof typeof operators;

const searchFilter = (inputValue: string, options: any[]) =>
  options.some(({ label }) =>
    label.toLowerCase().includes(inputValue.toLowerCase()),
  );

const getSelectedRequirementRule = (
  options: Rules,
  selectedRequirementId: string,
) => {
  return options.find(
    ({ attributeId }) => attributeId === selectedRequirementId,
  );
};

const { TextArea } = Input;

type RequirementState = {
  isCustom?: boolean;
  description?: string;
  matchingCriteria?: string;
  selectedAttribute: any;
};

type CreateOrEditRequirementProps = {
  isEdit?: boolean;
  subjectId: string;
  initialState?: RequirementState;
  onClose?: () => void;
  onConfirm: () => void;
};

const getCascaderOptions = (options: Rules) => {
  return options.map((option) => ({
    label: option.attributeLabel,
    value: option.attributeId,
    children: (operators[option.attributeType as Operator] || []).map(
      (operator) => ({
        value: operator,
        label: operator,
      }),
    ),
  }));
};

export const CreateOrEditRequirementForm = ({
  isEdit,
  initialState,
  subjectId,
  onClose,
  onConfirm,
}: CreateOrEditRequirementProps) => {
  const [areAdvancedOptionsVisible, setAreAdvancedOptionsVisible] =
    useState(false);

  const { options } = useRequirementsRules({ subjectId });

  const [description, setDescription] = useState(
    initialState?.description || '',
  );

  const [selectedRequirementRule, setSelectedRequirementRule] = useState<
    Rules[number] | undefined
  >();
  const [selectedValue, setSelectedValue] = useState<string>();

  const [selectedOperator, setSelectedOperator] = useState<
    Operator | undefined
  >();

  return (
    <StyledWrapper>
      <StyledFormWrapper>
        <StyledInputsWrapper>
          {initialState?.isCustom ? (
            <span>Custom Requirement</span>
          ) : (
            <>
              <StyledCascader
                placeholder="Click to select an item or start typing..."
                expandTrigger="hover"
                options={getCascaderOptions(options)}
                onChange={(changedValue) => {
                  if (!changedValue) {
                    setSelectedOperator(undefined);
                    setSelectedRequirementRule(undefined);
                    return;
                  }
                  setSelectedOperator(changedValue[1] as Operator);
                  setSelectedRequirementRule(
                    getSelectedRequirementRule(
                      options,
                      changedValue[0] as string,
                    ),
                  );
                }}
                displayRender={(labels) => displayRule(labels)}
                showSearch={{ filter: searchFilter }}
              />
              <DefinedRequirement
                targetValue={selectedValue}
                selectedOperator={selectedOperator}
                fieldType={selectedRequirementRule?.attributeType}
                setTargetValue={setSelectedValue}
              />
            </>
          )}
          <Tooltip title="Provide additional information for this requirement">
            <Button
              type="default"
              icon={<AlignLeftOutlined />}
              onClick={() =>
                setAreAdvancedOptionsVisible(!areAdvancedOptionsVisible)
              }
            />
          </Tooltip>
        </StyledInputsWrapper>
        {areAdvancedOptionsVisible && (
          <div>
            <StyledDescriptionWrapper>
              <TextArea
                rows={2}
                placeholder="Additional information..."
                value={description}
                autoSize={{ minRows: 2, maxRows: 8 }}
                onChange={({ target }) => setDescription(target.value)}
              />
            </StyledDescriptionWrapper>
            <StyledMatchingCriteria>
              <span>Matching criteria:</span>
              <Radio.Group
                defaultValue={
                  initialState?.matchingCriteria || MATCH_OPTIONS.matchOne
                }
                onChange={(e) => {
                  console.log(e.target.value);
                }}
              >
                <Radio value={MATCH_OPTIONS.matchOne}>Match once</Radio>
                <Radio value={MATCH_OPTIONS.matchRepeatedly}>
                  Match repeatedly
                </Radio>
              </Radio.Group>
            </StyledMatchingCriteria>
          </div>
        )}
      </StyledFormWrapper>
      <div>
        <Button
          onClick={() => {
            onClose?.();
          }}
        >
          Cancel
        </Button>
        <Button
          className="confirmButton"
          type="primary"
          onClick={() => {
            onConfirm();
          }}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ffe58e;
  background: ${({ theme }) => theme.colors.paleYellow};
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  .confirmButton {
    margin-left: 10px;
  }
`;

const StyledInputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledFormWrapper = styled.div`
  flex: 3;
`;

const StyledCascader = styled(Cascader)`
  min-width: 350px;
  flex: 3;
`;

const StyledDescriptionWrapper = styled.div`
  margin: 10px 0 0;
`;

const StyledMatchingCriteria = styled.div`
  margin: 10px 0 0;

  .ant-radio-wrapper,
  > span {
    font-size: 13px;
  }
  
  > span {
    margin-right: 10px;
  }
`;
