import * as R from 'ramda';

import { STATE_KEY } from '../reducers';

export const getDocuments = R.compose(
  R.filter((document) => !document.deletedAt && Boolean(document.organization)),
  R.values,
  R.pathOr(null, [STATE_KEY, 'data']),
);

export const getDocumentsByIds = (state, ids = []) =>
  R.compose(
    R.filter((document) => ids.includes(document._id)),
    getDocuments,
  )(state);

export const getActiveDocuments = R.compose(
  R.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
  R.filter((document) => !Boolean(document.archivedAt)),
  getDocuments,
);

export const getArchivedDocuments = R.compose(
  R.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
  R.filter((document) => Boolean(document.archivedAt)),
  getDocuments,
);

export const getDocument = (state, id) =>
  R.path([STATE_KEY, 'data', id], state);

export const getDocumentFillableFormState = (state, id) => {
  const document = getDocument(state, id);
  return document?.fillableForm?.status;
};

export const getDocumentsByParty = (state, partyId) =>
  R.compose(
    R.filter((document) => R.path(['party', '_id'], document) === partyId),
    getDocuments,
  )(state);

export const getIsUpdatingDocument = R.pathOr(false, [
  STATE_KEY,
  'isUpdatingDocument',
]);

export const getIsExistSubscription = (state, partyId) =>
  R.compose(
    (ids) => ids.some((id) => id === partyId),
    R.pathOr([], [STATE_KEY, 'subscriptionsPartyIds']),
  )(state);

export const getDocumentProjects = (state, documentId) =>
  R.compose(R.propOr([], 'projects'), (state) =>
    getDocument(state, documentId),
  )(state);

export const getSubscriptions = R.pathOr(
  [],
  [STATE_KEY, 'subscriptionsPartyIds'],
);

export const getTotalDocumentsCount = R.pathOr(0, [STATE_KEY, 'totalCount']);

export const getIsUploadingDocuments = R.pathOr(false, [
  STATE_KEY,
  'isUploadingDocuments',
]);
