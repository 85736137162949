import { gql } from '@apollo/client';

import type { AnyGQLQueryAction, AnyGQLSuccessAction } from '@common/types';
import { ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { namespace } from '../constants';
import {
  PARTY_CONVERSATION_MESSAGE_FRAGMENT,
  type PartyConversation,
} from '../fragments';

export const GET_PARTY_CONVERSATION_MESSAGES =
  `${namespace}/GET_PARTY_CONVERSATION_MESSAGES` as const;
export const GET_PARTY_CONVERSATION_MESSAGES_SUCCESS =
  `${GET_PARTY_CONVERSATION_MESSAGES}${ACTION_SUCCESS_SUFFIX}` as const;

const GET_PARTY_CONVERSATION_MESSAGES_QUERY = gql`
  query getPartyConversationMessages(
    $conversationId: String
    $messagesInput: PartyMessagesInput
  ) {
    partyConversation(conversationId: $conversationId) {
      _id
      messages(input: $messagesInput) {
        totalCount
        nodes {
          ...PartyConversationMessage
        }
      }
    }
  }
  ${PARTY_CONVERSATION_MESSAGE_FRAGMENT}
`;

export type GetPartyConversationMessages = AnyGQLQueryAction<
  typeof GET_PARTY_CONVERSATION_MESSAGES,
  {
    conversationId: PartyConversation['_id'];
    messagesInput: {
      first: 500;
      offset: 0;
      sort: {
        createdAt: 1;
      };
    };
  }
>;

export type GetPartyConversationMessagesSuccess = AnyGQLSuccessAction<
  typeof GET_PARTY_CONVERSATION_MESSAGES_SUCCESS,
  Pick<PartyConversation, 'messages'>
>;

export const getPartyConversationMessages = (
  conversationId: PartyConversation['_id'],
) => ({
  type: GET_PARTY_CONVERSATION_MESSAGES,
  payload: {
    key: 'partyConversation',
    graphql: {
      query: GET_PARTY_CONVERSATION_MESSAGES_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        conversationId,
        messagesInput: {
          first: 500,
          offset: 0,
          sort: {
            createdAt: 1,
          },
        },
      },
    },
  },
});
