import { Form, Input, Modal, Select } from 'antd';
import { type SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  getEditorHTMLContent,
  getEditorPlainText,
  getEmptyEditorState,
  setInitialEditorStateFromHTML,
} from '@common/components/RichTextEditor/helpers';
import * as PartyConversationActions from '@modules/party-conversation/actions';

import { FullWidthSelect } from '@trustlayer/ui';
import RichTextArea from '../components/RichTextArea';

type CreateConversationModalProps = {
  partyId: string;
  partyContacts?: any[];
  defaultRecipients?: string[];
  defaultMessage?: string;
  defaultSubject?: string;
  visible: boolean;
  onCancel?: () => void;
  onSubmitEnd?: () => void;
};

const CreateConversationModal = ({
  partyId,
  partyContacts = [],
  defaultRecipients = [],
  defaultMessage = '',
  defaultSubject,
  visible,
  onCancel = () => {},
  onSubmitEnd = () => {},
}: CreateConversationModalProps) => {
  const [attachments, setAttachments] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isAttaching, setIsAttaching] = useState(false);
  const [messageEditorState, setMessageEditorState] = useState(
    setInitialEditorStateFromHTML(defaultMessage),
  );

  useEffect(() => {
    if (visible) {
      setMessageEditorState(setInitialEditorStateFromHTML(defaultMessage));
    }
  }, [visible, defaultMessage]);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const formCleanUp = () => {
    form.resetFields();
    setMessageEditorState(getEmptyEditorState());
    setAttachments([]);
  };

  const handleCancel = () => {
    onCancel();
    formCleanUp();
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();
      const values = await form.validateFields();

      if (!isAttaching) {
        setIsSending(true);
        await dispatch(
          PartyConversationActions.createPartyConversation({
            ...values,
            party: partyId,
            message: {
              message: getEditorPlainText(messageEditorState),
              outputHtml: getEditorHTMLContent(messageEditorState),
              attachments,
            },
          }),
        );
        setIsSending(false);
        formCleanUp();
        onSubmitEnd();
      }
    } catch {}
  };

  return (
    <ModalWrapper
      open={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText="Send"
      destroyOnClose
      //@ts-expect-error - Antd types do not admit data-cy prop in okButton
      okButtonProps={{ loading: isSending, 'data-cy': 'messageSendBtn' }}
      title="New conversation"
      width={800}
    >
      <Form form={form} name="partyCreateConversationModal" layout="vertical">
        <StyledFormItem
          label="Recipients"
          name="participants"
          initialValue={defaultRecipients}
          rules={[
            {
              required: true,
              message: 'Please select message recipients',
            },
          ]}
        >
          <FullWidthSelect
            placeholder="Enter recipients..."
            optionFilterProp="name"
            data-cy="recipientsSelection"
            defaultValue={defaultRecipients}
            mode="multiple"
          >
            {partyContacts
              .filter((contact) => contact.email)
              .map((contact) => (
                <Select.Option
                  key={contact._id}
                  value={contact._id}
                  name={contact.contactPersonName}
                >
                  {contact.contactPersonName} {'<'}
                  {contact.email}
                  {'>'}
                </Select.Option>
              ))}
          </FullWidthSelect>
        </StyledFormItem>
        <StyledFormItem
          label="Subject"
          name="subject"
          initialValue={defaultSubject}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please enter the message subject',
            },
          ]}
        >
          <Input
            data-cy="conversationSubject"
            defaultValue={defaultSubject}
            placeholder="Enter subject..."
          />
        </StyledFormItem>
        <StyledFormItem label="Message" required>
          <RichTextArea
            minHeight={80}
            isValid={true}
            focusDependence={visible}
            isAttaching={isAttaching}
            setIsAttaching={setIsAttaching}
            attachments={attachments}
            setAttachments={setAttachments}
            textAreaEditorState={messageEditorState}
            onChange={setMessageEditorState}
          />
        </StyledFormItem>
      </Form>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 10px;
  }

  .ant-modal-footer {
    button + button {
      margin-left: 10px;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0 0 10px !important;
`;

export default CreateConversationModal;
