import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_DETACH_DOCUMENTS_FROM_PARTY = `${namespace}/BULK_DETACH_DOCUMENTS_FROM_PARTY`;

const BULK_DETACH_DOCUMENTS_FROM_PARTY_MUTATION = gql`
  mutation bulkDetachDocumentsFromParty(
    $payload: BulkDetachDocumentsFromPartyInput
  ) {
    bulkDetachDocumentsFromParty(data: $payload) {
      scheduled
    }
  }
`;

export const bulkDetachDocumentsFromParty = (payload) => ({
  type: BULK_DETACH_DOCUMENTS_FROM_PARTY,
  payload: {
    key: 'bulkDetachDocumentsFromParty',
    graphql: {
      mutation: BULK_DETACH_DOCUMENTS_FROM_PARTY_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
