import { gql } from '@apollo/client';

export const CURRENT_PROJECT_FRAGMENT = gql`
  fragment CurrentProject on Project {
    _id
    name
    description
    startDate
    endDate
    isActive
    externalIds
    customFields {
      nodes {
        customField {
          _id
          name
          type
          color
          options {
            key
            value
          }
        }
        value
      }
    }
    complianceStatus {
      status
      compliantPartiesCount
      totalPartiesCount
      totalActivePartiesCount
      compliantActivePartiesCount
    }
    parties {
      _id
    }
  }
`;
