import { DATA_MODEL_REFERENCES } from '@common/constants';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const REMOVE_RECORD_CONTACTS = graphql(`
  mutation RemoveRecordContacts($data: RemoveRecordContactsInput!) {
    removeRecordContacts(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useRemovePrimaryRecordContact = ({
  primaryRecordId,
}: {
  primaryRecordId: string;
}) => {
  const [deleteContact, { loading }] = useMutation(REMOVE_RECORD_CONTACTS);

  const handleRemovePrimaryRecordContact = (contactId: string) => {
    return deleteContact({
      variables: {
        data: {
          recordContacts: [
            {
              id: primaryRecordId,
              contacts: [contactId],
              reference: DATA_MODEL_REFERENCES.primaryRecord,
            },
          ],
        },
      },
    });
  };

  return {
    removePrimaryRecordContact: handleRemovePrimaryRecordContact,
    loading,
  };
};
