import {
  CloudDownloadOutlined,
  ContainerOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';

import {
  type ActionType,
  DOCUMENT_ACTION_TYPES,
  REVIEW_STATUS,
} from '../../constants';

type Params = {
  excludedActions?: ActionType[];
  options?: {
    isDownloadDisabled?: boolean;
    isPreviewDisabled?: boolean;
    isAddTypesDisabled?: boolean;
    isRemoveTypesDisabled?: boolean;
    isDetachFromProjectDisabled?: boolean;
    isMatchRequirementsDisabled?: boolean;
    isArchivedStatusEnable?: boolean;
  };
};

export const getActionsMenuItems = ({
  excludedActions = [],
  options,
}: Params) => {
  const actions = [
    {
      code: DOCUMENT_ACTION_TYPES.download,
      disabled: options?.isDownloadDisabled, // add !downloadableDocuments.length
      label: 'Download',
      icon: <CloudDownloadOutlined />,
    },
    {
      code: DOCUMENT_ACTION_TYPES.preview,
      disabled: options?.isPreviewDisabled,
      label: 'Review',
      icon: <FileSearchOutlined />,
    },
    {
      code: DOCUMENT_ACTION_TYPES.updateArchiveStatus,
      label: options?.isArchivedStatusEnable ? 'Unarchive' : 'Archive',
      icon: <ContainerOutlined />,
    },
    {
      code: DOCUMENT_ACTION_TYPES.assignToParty,
      label: 'Assign to party',
    },
    {
      code: DOCUMENT_ACTION_TYPES.detachFromParty,
      label: 'Detach from current party',
    },
    {
      code: DOCUMENT_ACTION_TYPES.associateToProject,
      label: 'Associate to project',
    },
    {
      code: DOCUMENT_ACTION_TYPES.detachFromProject,
      disabled: options?.isDetachFromProjectDisabled,
      label: 'Detach from current project',
    },
    {
      code: DOCUMENT_ACTION_TYPES.addTypes,
      disabled: options?.isAddTypesDisabled,
      label: 'Add document types',
    },
    {
      code: DOCUMENT_ACTION_TYPES.removeTypes,
      disabled: options?.isRemoveTypesDisabled,
      label: 'Remove document types',
    },
    {
      code: DOCUMENT_ACTION_TYPES.matchRequirements,
      disabled: options?.isMatchRequirementsDisabled,
      label: 'Bulk match requirements',
    },
    {
      code: DOCUMENT_ACTION_TYPES.updateReviewStatus,
      label: 'Mark as reviewed',
      options: { job: REVIEW_STATUS.Reviewed },
    },
    {
      code: DOCUMENT_ACTION_TYPES.updateReviewStatus,
      label: 'Mark as to review',
      options: { job: REVIEW_STATUS.ToReview },
    },
    {
      code: DOCUMENT_ACTION_TYPES.addRequirement,
      label: 'Add requirement value',
    },
    {
      code: DOCUMENT_ACTION_TYPES.delete,
      label: 'Delete',
    },
  ];

  return actions.filter(
    (action) => excludedActions.includes(action.code) === false,
  );
};
