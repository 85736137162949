import { Select, Skeleton } from 'antd';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import Spinner from '@common/components/Spinner';
import useSetState from '@common/hooks/useSetState';

import { getSanitzedValues } from './TagsFilter.utils';
import { TagsFilterOperator } from './constants';
import { useTags } from './useTags';

export type TagsFilterType = {
  value?: string[];
  operator?: TagsFilterOperator;
};

export type TagsFilterProps = {
  filter?: TagsFilterType;
  onFilterChange: (params: TagsFilterType) => void;
};

export const DEFAULT_TAGS_OPERATOR = TagsFilterOperator.include;

const initialState: TagsFilterType = {
  operator: DEFAULT_TAGS_OPERATOR,
  value: [],
};

export const TagsFilter = ({ onFilterChange, filter }: TagsFilterProps) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [internalFilter, setInternalFilter] = useSetState<TagsFilterType>(
    filter || initialState,
  );
  const { fetchTags, tags, tagsTotalCount, fetchMoreTags, loading } = useTags();

  const prefilledValueKey = JSON.stringify(filter?.value);

  // biome-ignore lint/correctness/useExhaustiveDependencies: more dependencies
  const debouncedOnTagSelected = useCallback(
    debounce((value) => onFilterChange(value), 1000),
    [onFilterChange],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: more dependencies
  const debouncedOnInputChange = useCallback(
    debounce(
      (tagName) =>
        fetchTags({
          offset: 0,
          first: 10,
          name: tagName,
        }),
      600,
    ),
    [fetchTags],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing dependencies
  useEffect(() => {
    if (filter?.value?.length) {
      setIsLoaderVisible(true);
      fetchTags({
        queryOptions: {
          onCompleted() {
            setIsLoaderVisible(false);
          },
        },
        ids: filter?.value || [],
      });
    }
  }, [prefilledValueKey]);

  return (
    <>
      <StyledSelect
        data-cy="tagsFilterOperatorSelect"
        value={internalFilter.operator}
        onChange={(operator) => {
          setInternalFilter({ operator: operator as TagsFilterOperator });
          if (internalFilter.value?.length) {
            debouncedOnTagSelected({
              operator: operator,
              value: internalFilter.value,
            });
          }
        }}
      >
        <Select.Option key="include-key" value={TagsFilterOperator.include}>
          Include
        </Select.Option>
        <Select.Option
          key="not-include-key"
          value={TagsFilterOperator.notInclude}
        >
          Does not Include
        </Select.Option>
      </StyledSelect>

      {isLoaderVisible ? (
        <StyledInputSkeleton active size="large" />
      ) : (
        <StyledInfiniteScrollSelect
          showSearch
          data-cy="filterSelectTags"
          placeholder="Enter tags..."
          value={getSanitzedValues(internalFilter.value)}
          isDataLoading={loading}
          hasMore={tags.length < tagsTotalCount}
          mode="multiple"
          onDropdownVisibleChange={(open) =>
            open &&
            fetchTags({
              offset: 0,
              first: 10,
            })
          }
          onSearch={(value) => debouncedOnInputChange(value)}
          loadMore={() => {
            fetchMoreTags({
              variables: {
                input: {
                  offset: tags.length,
                  first: 10,
                },
              },
            });
          }}
          onChange={(values) => {
            setInternalFilter({ value: values as string[] });
            debouncedOnTagSelected({
              operator: internalFilter.operator,
              value: values,
            });
          }}
          loader={<Spinner />}
        >
          {tags?.map((tag) => (
            <Select.Option
              data-cy="filterSelectTagsOption"
              key={`key_${tag._id}`}
              value={tag._id}
              label={tag.name}
            >
              {tag.name}
            </Select.Option>
          ))}
        </StyledInfiniteScrollSelect>
      )}
    </>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 10px;
`;

const StyledInfiniteScrollSelect = styled(InfiniteScrollSelect)`
  margin-bottom: 10px;
`;

const StyledInputSkeleton = styled(Skeleton.Input)`
  height: 60px;
  width: 100% !important;
`;
