import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const IMPORT_CUSTOM_FIELDS = `${namespace}/IMPORT_CUSTOM_FIELDS`;

const IMPORT_CUSTOM_FIELDS_MUTATION = gql`
  mutation importCustomFields($payload: ImportCustomFieldsInput!) {
    importCustomFields(data: $payload) {
      scheduled
    }
  }
`;

export const importCustomFields = (url) => ({
  type: IMPORT_CUSTOM_FIELDS,
  payload: {
    key: 'importCustomFields',
    graphql: {
      mutation: IMPORT_CUSTOM_FIELDS_MUTATION,
      variables: {
        payload: { url },
      },
    },
  },
});
