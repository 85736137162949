import { CaretDownOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';

type OnSaveClickParams = {
  url: string;
  name: string;
};

type Attachment = { s3Key: string; url: string; friendlyName: string };

type AttachmentListProp = {
  onSaveClick: (params: OnSaveClickParams) => void;
  onDownloadClick: (params: Attachment) => void;
  attachments: Attachment[];
};

export enum MENU_ACTION_LABEL {
  SaveInDocuments = 'Save in Documents',
  Download = 'Download',
}

export const AttachmentList = ({
  onSaveClick,
  onDownloadClick,
  attachments = [],
}: AttachmentListProp) => {
  return (
    <section data-cy="attachmentList">
      {attachments.map((attachment) => (
        <StyledAttachment key={attachment.s3Key}>
          <PaperClipOutlined />
          <StyledAttachmentLink
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.friendlyName}
          </StyledAttachmentLink>
          <Dropdown
            trigger={['click']}
            dropdownRender={() => (
              <Menu>
                <Menu.Item
                  key={`${attachment.s3Key}-save`}
                  onClick={() =>
                    onSaveClick({
                      url: attachment.url,
                      name: attachment.friendlyName,
                    })
                  }
                >
                  {MENU_ACTION_LABEL.SaveInDocuments}
                </Menu.Item>
                <Menu.Item
                  key={`${attachment.s3Key}-download`}
                  onClick={() => onDownloadClick(attachment)}
                >
                  {MENU_ACTION_LABEL.Download}
                </Menu.Item>
              </Menu>
            )}
            placement="bottom"
          >
            <StyledCaretDownOutlined data-cy="attachmentListDropdownIcon" />
          </Dropdown>
        </StyledAttachment>
      ))}
    </section>
  );
};

const StyledAttachment = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledAttachmentLink = styled.a`
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 12px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const StyledCaretDownOutlined = styled(CaretDownOutlined)`
  margin-left: 5px;
`;
