import { PlusOutlined } from '@ant-design/icons';
import { PageTitle } from '@common/components/PageTitleWrapper';
import SearchInput from '@common/components/SearchInput';
import { CellActions, Table, type TableRef } from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import { BulkOperationStatus } from '@graphql/types/graphql';
import {
  AddContactModal,
  UpdateContactModal,
  useRemovePrimaryRecordContact,
  useSetPrimaryContactMutation,
} from '@modules/contacts';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { Button, Tooltip, message } from 'antd';
import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ContactAvatar } from '../../components/ContactAvatar';
import { CONTACTS_COLUMN_FIELDS } from './constants';
import { type Contact, useContacts } from './hooks/useContacts';

type ContactsTableProps = {
  recordId: string;
};

export const ContactsTable = ({ recordId }: ContactsTableProps) => {
  const { getContacts } = useContacts({ primaryRecordId: recordId });
  const { setPrimaryContactMutation } = useSetPrimaryContactMutation({
    primaryRecordId: recordId,
  });
  const { removePrimaryRecordContact } = useRemovePrimaryRecordContact({
    primaryRecordId: recordId,
  });
  const contactsTableRef = useRef<TableRef>(null);
  const [isAddContactModalVisible, setIsAddContactModalVisible] =
    useState(false);
  const [contactToEdit, setContactToEdit] = useState<Contact | undefined>();

  const handleRemovePrimaryRecordContact = useCallback(
    async (contactId: string) => {
      const res = await removePrimaryRecordContact(contactId);
      const status = res.data?.removeRecordContacts.operation.status;

      if (status === BulkOperationStatus.Completed) {
        message.success(`Contact removed successfully.`);
      }

      if (status === BulkOperationStatus.Scheduled) {
        message.success(`Contact removal has been scheduled.`);
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `The removal of the contact has failed. Please try again or contact support.`,
        );
      }

      contactsTableRef.current?.refreshTable();
    },
    [removePrimaryRecordContact],
  );

  const handleSetPrimaryContact = useCallback(
    async (contactId: string) => {
      const res = await setPrimaryContactMutation(contactId);
      const status = res.data?.updateContacts.operation.status;

      if (status === BulkOperationStatus.Completed) {
        message.success(`Contact set as primary successfully.`);
      }

      if (status === BulkOperationStatus.Scheduled) {
        message.success(`Contact set as primary has been scheduled.`);
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `Failed to set the contact as primary. Please try again or contact support.`,
        );
      }

      contactsTableRef.current?.refreshTable();
    },
    [setPrimaryContactMutation],
  );

  const columns = useMemo(
    () => [
      {
        headerName: 'Name',
        field: CONTACTS_COLUMN_FIELDS.name,
        sortable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
        },
        cellDataType: 'text',
        valueGetter: ({ data }: { data: any }) => data,
        cellRenderer: ({ value }: { value: Contact }) => {
          return (
            <StyledName>
              <ContactAvatar
                avatarURL={value.avatar}
                isPrimary={value.primary}
                name={value.contactPersonName}
              />
              <span>{value.contactPersonName}</span>
            </StyledName>
          );
        },
      },
      {
        headerName: 'Email',
        field: CONTACTS_COLUMN_FIELDS.email,
        sortable: false,
        cellDataType: 'text',
        valueGetter: ({ data }: { data: Contact }) => data.email,
      },
      {
        headerName: 'Company',
        field: CONTACTS_COLUMN_FIELDS.company,
        sortable: false,
        cellDataType: 'text',
        valueGetter: ({ data }: { data: Contact }) => data?.companyName,
      },
      {
        headerName: 'Actions',
        field: CONTACTS_COLUMN_FIELDS.actions,
        lockPosition: 'right' as const,
        pinned: 'right' as const,
        width: 90,
        maxWidth: 90,
        sortable: false,
        valueGetter: ({ data }: { data: Contact }) => data,
        cellRenderer: ({ value }: { value: Contact }) => {
          return (
            <CellActions>
              <HideForViewerRole>
                <CellActions.ThreeDotsMenu
                  menuItems={[
                    {
                      label: 'Set as primary contact',
                      code: 'primary',
                      disabled: value.primary,
                      onClick: () => handleSetPrimaryContact(value._id!),
                    },
                    {
                      label: 'Edit',
                      code: 'edit',
                      disabled: false,
                      onClick: () => {
                        setContactToEdit(value);
                      },
                    },
                    {
                      label: (
                        <Tooltip
                          title={
                            <StyledTextTooltip>
                              <span>
                                Unable to remove when set
                                <br />
                                as primary contact
                              </span>
                            </StyledTextTooltip>
                          }
                          trigger={value.primary ? 'hover' : 'none'}
                          mouseEnterDelay={0.5}
                          zIndex={9999}
                        >
                          Remove
                        </Tooltip>
                      ),
                      code: 'remove',
                      alert: true,
                      disabled: value.primary,
                      onClick: () =>
                        handleRemovePrimaryRecordContact(value._id!),
                    },
                  ]}
                />
              </HideForViewerRole>
            </CellActions>
          );
        },
      },
    ],
    [handleRemovePrimaryRecordContact, handleSetPrimaryContact],
  );

  return (
    <StyledWrapper>
      {contactToEdit && (
        <UpdateContactModal
          primaryRecordId={recordId}
          contactToUpdate={contactToEdit}
          onCompleted={() => {
            contactsTableRef.current?.refreshTable();
            setContactToEdit(undefined);
          }}
          onClose={() => {
            setContactToEdit(undefined);
          }}
        />
      )}
      <AddContactModal
        primaryRecordId={recordId}
        onCompleted={() => {
          contactsTableRef.current?.refreshTable();
          setIsAddContactModalVisible(false);
        }}
        onClose={() => {
          setIsAddContactModalVisible(false);
        }}
        open={isAddContactModalVisible}
      />

      <TableHeader>
        <PageTitle title="Contacts" />
        <TableHeader.TopRightActions>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setIsAddContactModalVisible(true);
            }}
          >
            Add Contact
          </Button>
        </TableHeader.TopRightActions>
        <TableHeader.BottomLeftActions>
          <StyledSearchInputWrapper>
            <SearchInput
              onSearch={(value: string) => {
                contactsTableRef.current?.setFilters({
                  name: {
                    filterType: 'text',
                    filter: value,
                    type: 'contains',
                  },
                });
              }}
            />
          </StyledSearchInputWrapper>
        </TableHeader.BottomLeftActions>
      </TableHeader>
      <StyledTable>
        <Table
          ref={contactsTableRef}
          isSideBarDisbaled
          columnDefs={columns}
          getRowData={getContacts}
        />
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledSearchInputWrapper = styled.div`
  width: 280px;
`;

const StyledName = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTextTooltip = styled.div`
  text-align: center;
`;
