import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import type { ReactElement } from 'react';

export type ThreeDotsMenuItem<TCode = string> = {
  disabled?: boolean;
  label: ReactElement | string;
  code: TCode;
  alert?: boolean;
  onClick?: () => void;
};

type DotsMenuProps<TCode = string> = {
  items: ThreeDotsMenuItem<TCode>[];
  className?: string;
  border?: boolean;
  onClick?: (key: TCode) => void;
};

export const ThreeDotsMenu = <TCode = string>({
  items,
  className,
  border = false,
  onClick,
}: DotsMenuProps<TCode>) => {
  return (
    <Dropdown
      className={className}
      dropdownRender={() => (
        <Menu
          onClick={({ key }) => onClick?.(key as TCode)}
          items={items.map(({ code, alert, ...item }) => ({
            ...item,
            key: String(code),
            danger: alert,
          }))}
        />
      )}
      trigger={['click']}
    >
      {border ? (
        <Button icon={<MoreOutlined />} />
      ) : (
        <Button
          icon={<MoreOutlined />}
          type="text"
          style={{ boxShadow: 'none' }}
        />
      )}
    </Dropdown>
  );
};
