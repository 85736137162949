import { useSubscription } from '@graphql/hooks';

import {
  setAppVersionHardRefresh,
  setLatestAppVersion,
} from '@modules/app/actions';
import { getAppVersions } from '@modules/app/selectors';
import { WEB_APP_UPDATES_SUBSCRIPTION } from '@modules/app/subscriptions/webAppVersionSubscription';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect } from 'react';

export const AppVersionManager = () => {
  const dispatch = useAppDispatch();
  const versions = useAppSelector(getAppVersions);

  const { data } = useSubscription(WEB_APP_UPDATES_SUBSCRIPTION, {
    variables: {
      version: versions.currentVersion,
    },
  });

  useEffect(() => {
    if (data?.webAppUpdates?.version) {
      dispatch(setLatestAppVersion(data?.webAppUpdates.version));
    }
    if (data?.webAppUpdates?.hardrefresh) {
      dispatch(setAppVersionHardRefresh(data?.webAppUpdates.hardrefresh));
    }
  }, [
    dispatch,
    data?.webAppUpdates?.version,
    data?.webAppUpdates?.hardrefresh,
  ]);

  return null;
};
