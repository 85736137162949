import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const REMOVE_BULK_DOCUMENT_TYPES_KEY = 'removeBulkDocumentTypes';
export const REMOVE_BULK_DOCUMENT_TYPES = `${namespace}/REMOVE_BULK_DOCUMENT_TYPES`;

export const REMOVE_BULK_DOCUMENT_TYPES_MUTATION = gql`
  mutation removeBulkDocumentTypes($data: bulkDocumentTypesInput!) {
    removeBulkDocumentTypes(data: $data) {
      _id
      types {
        _id
        name
        pluralName
        isSystemType
      }
    }
  }
`;

export const removeBulkDocumentTypes = (data) => ({
  type: REMOVE_BULK_DOCUMENT_TYPES,
  payload: {
    key: 'removeBulkDocumentTypes',
    graphql: {
      mutation: REMOVE_BULK_DOCUMENT_TYPES_MUTATION,
      variables: { data },
    },
  },
});
