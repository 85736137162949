import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '@modules/user/fragments';

import { namespace } from '../constants';

export const LOGIN = `${namespace}/LOGIN`;

const LOGIN_MUTATION = gql`
  mutation login($payload: SignInInput!) {
    login(data: $payload) {
      token
      user {
        ...User
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const login = (payload) => ({
  type: LOGIN,
  payload: {
    key: 'login',
    graphql: {
      mutation: LOGIN_MUTATION,
      variables: { payload },
    },
  },
});
