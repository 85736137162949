import { graphql } from '@graphql/types';

export const PARTY_WITH_DETAILS_QUERY = graphql(`
  query PartyWithDetails($id: String!, $byContext: Boolean) {
    getPartyData(id: $id, byContext: $byContext) {
      _id
      name
      automaticallyWaivedSubjects {
        subjectId
        status
      }
      automaticallyWaivedRequirements {
        attributeId
        status
      }
      type {
        _id
        name
      }
      isActive
      hasWaivedRequirements
      hasOverriddenRequirements
      deletedAt
      deletedBy
      status
      website
      isTrackingCompliance
      isSendingExpiringRequest
      address {
        rawAddress
        line1
        line2
        city
        region
        country
        postalCode
        latitude
        longitude
      }
      documents {
        _id
        friendlyName
        notes
        s3Key
        organization
        party {
          _id
        }
        metadata
        insurers
        issuedBy {
          partyContact
          email
        }
        flag {
          addedOn
          severityLevel
          affectedSubjects
          notes
          user {
            _id
            email
            profile {
              name
            }
          }
          authorName
        }
        verification {
          verifiedOn
          verifiedVia
          affectedSubjects
          notes
          user {
            _id
            email
            profile {
              name
            }
          }
        }
        archivedAt
        reviewedAt
        createdAt
        updatedAt
      }
      requests {
        _id
        deletedAt
        deletedBy
        forwardedAt
        forwardedTo
        sentByUser
        party
        message
        attachments {
          url
          friendlyName
          s3Key
        }
        documentChecklists
        sentOn
        organization
        dueDate
        project
        createdAt
        updatedAt
      }
      partyComplianceProfile {
        _id
        isProcessing
        party
        metadata
        complianceProfile
        name
        nextExpirationDate
        latestExpirationDate
        earliestExpirationDate
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
        createdAt
        updatedAt
      }
      subjects {
        subjectId
        subjectLabel
        notes
        requirementComplianceStatusValue
        resetRequirementOn
      }
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        invalidatedBy
        invalidationScope
        publicNotes
        createdAt
        updatedAt
      }
      documentChecklists {
        _id
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
      additionalNotes
      partyContacts {
        _id
        party
        contactPersonName
        companyName
        email
        phone
        fax
        title
        avatar
        isPrimary
        isDefaultRequestRecipient
        createdAt
        updatedAt
      }
      nonresponsiveSince
      lastActivityDate
      totalDocumentsCount
      totalDocumentsToReviewCount
      totalDocumentsReviewedCount
      totalApplicableDocumentsCount
      totalApplicableDocumentsReviewedCount
      totalApplicableDocumentsToReviewCount
      taggings {
        _id
        partyTag {
          _id
          name
          color
        }
        addedAt
        addedBy
        expiresAt
      }
      organization
      createdAt
      updatedAt
      lastRequestSentOn
      nearestFlagSeverityOrder
      externalIds
      defaultRequestRecipients {
        _id
      }
      applicableDocuments {
        _id
        friendlyName
        expirationDate
        notes
        s3Key
        organization
        party {
          _id
        }
        metadata
        insurers
        issuedBy {
          partyContact
          email
        }
        flag {
          addedOn
          severityLevel
          affectedSubjects
          notes
          user {
            _id
            email
            profile {
              name
            }
          }
          authorName
        }
        verification {
          verifiedOn
          verifiedVia
          affectedSubjects
          notes
          user {
            _id
            email
            profile {
              name
            }
          }
        }
        archivedAt
        reviewedAt
        createdAt
        updatedAt
      }
    }
  }
`);
