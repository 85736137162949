import { gql } from '@apollo/client';

export const FILLABLE_FORM_FRAGMENT = gql`
  fragment Form on Form {
    _id
    organization
    name
    documentName
    documentType {
      _id
      name
    }
    documentName
    documentUrl
    formFields
    formFieldValues
    annotations
    updatedAt
    additionalInfo
    appliesToAllProjects
    trackExpiration
    affectedPartiesCount
  }
`;

export type FillableForm = {
  _id: string;
  organization: string;
  name: string;
  documentName: string;
  documentType: {
    _id: string;
    name: string;
  };
  documentUrl: string;
  formFieldValues: Record<string, any>[];
  formFields: Record<string, any>[];
  annotations: Record<string, any>[];
  updateAt: Date;
  additionalInfo: string;
  appliesToAllProjects: boolean;
  trackExpiration: boolean;
  affectedPartiesCount: number;
};
