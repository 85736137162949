type Requirement = {
  attributeCode: string;
  attributeLabel: string;
  custom: boolean;
  attributeType: string;
  operator: string;
  targetValue: string | string[];
  status: string;
  documentAttributeCode: string;
  matchingCriteria: string;
};

type Subject = {
  code: string;
  label: string;
  requirements: Requirement[];
};

export type Module = {
  code: string;
  label: string;
  subjects: Subject[] | [];
};

export enum ComplianceProfileEntity {
  modules = 'modules',
  subjects = 'subjects',
  requirements = 'requirements',
}
