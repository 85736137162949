import * as R from 'ramda';

import {
  DateSuffix,
  FilterInputType,
  FilterTypeOptions,
} from '@common/components/DateFilterSelector';

export const pickOptionsFromFilterTypeOptions = (options) =>
  R.filter(({ key }) => R.includes(key, options), FilterTypeOptions);

/**
 * Filter type option map.
 */
export const FilterTypeOptionsMap = R.indexBy(R.prop('key'), FilterTypeOptions);

/**
 * Reset fields except given.
 */
const prepareResetFields = (filterName = '', fields) =>
  R.compose(
    R.reduce((result, key) => R.assoc(key, null, result), {}),
    R.map((suffix) => `${filterName}${suffix}`),
    R.values,
    R.omit(fields),
  )(DateSuffix);

/**
 * Reset fields map.
 */
export const ResetFieldsByFormType = {
  [FilterInputType.SELECT]: (filterName) =>
    prepareResetFields(filterName, [
      DateSuffix.WithinDateCount,
      DateSuffix.WithinDateType,
    ]),
  [FilterInputType.RANGE]: (filterName) =>
    prepareResetFields(filterName, [DateSuffix.From, DateSuffix.To]),
  [FilterInputType.PICKER]: (filterName) =>
    prepareResetFields(filterName, [DateSuffix.PickerValue]),
};
