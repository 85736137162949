import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_ASSOCIATE_DOCUMENTS_TO_PROJECT = `${namespace}/BULK_ASSOCIATE_DOCUMENTS_TO_PROJECT`;

const BULK_ASSOCIATE_DOCUMENTS_TO_PROJECT_MUTATION = gql`
  mutation bulkAssociateDocumentsToProject(
    $payload: BulkAssociateDocumentsToProjectInput
  ) {
    bulkAssociateDocumentsToProject(data: $payload) {
      scheduled
    }
  }
`;

export const bulkAssociateDocumentsToProject = (payload) => ({
  type: BULK_ASSOCIATE_DOCUMENTS_TO_PROJECT,
  payload: {
    key: 'bulkAssociateDocumentsToProject',
    graphql: {
      mutation: BULK_ASSOCIATE_DOCUMENTS_TO_PROJECT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
