import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_DATA_FRAGMENT } from '../fragments';

export const DELETE_PARTIES = `${namespace}/DELETE_PARTIES`;

const DELETE_PARTIES_MUTATION = gql`
  mutation deletePartiesData($ids: [String!]!) {
    deletePartiesData(ids: $ids) {
      ...PartyData
    }
  }
  ${PARTY_DATA_FRAGMENT}
`;

export const deleteParties = (ids) => ({
  type: DELETE_PARTIES,
  payload: {
    key: 'deletePartiesData',
    graphql: {
      mutation: DELETE_PARTIES_MUTATION,
      variables: {
        ids,
      },
    },
  },
});
