import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { USER_FRAGMENT } from '../fragments';

export const FETCH_ME = `${namespace}/FETCH_ME`;
const FETCH_ME_QUERY = gql`
  query {
    me {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;

export const fetchMe = () => ({
  type: FETCH_ME,
  payload: {
    key: 'me',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_ME_QUERY,
    },
  },
});
