import { STATE_KEY } from '../constants';

type GlobalStore = {
  user: {
    _id: string;
    email: string;
    isActive: boolean;
    activeOrganization: string;
    profile: {
      name: string;
      avatar: string;
    };
    isAsideNavigationCollapsed: boolean;
  };
};

export const getUser = (state: GlobalStore) => state[STATE_KEY];
export const getMyId = (state: GlobalStore) => state[STATE_KEY]?._id || '';
export const getEmail = (state: GlobalStore) => state[STATE_KEY]?.email;
export const getProfile = (state: GlobalStore) => state[STATE_KEY]?.profile;
export const getName = (state: GlobalStore) => state[STATE_KEY]?.profile?.name;
export const getAvatar = (state: GlobalStore) =>
  state[STATE_KEY]?.profile?.avatar;
export const isAsideNavigationCollapsed = (state: GlobalStore) =>
  state[STATE_KEY]?.isAsideNavigationCollapsed || false;
