import styled from 'styled-components';

import { Compliance } from '@common/icons';
import { Space } from 'antd';
import { getComplianceStatusFromValue } from './ComplianceStats.utils';

type RequestComplianceProps = {
  compliantCount: number;
  totalCount: number;
  rightLabel?: string;
};

/**
 * @description This component is used to display the compliance stats of a **request record**.
 */
export const ComplianceStats = ({
  totalCount,
  compliantCount,
  rightLabel,
}: RequestComplianceProps) => {
  const status = getComplianceStatusFromValue(compliantCount, totalCount);

  return (
    <StyledWrapper size="small">
      <StyledCompliance type={status} />
      <span>
        {compliantCount}/{totalCount} {rightLabel}
      </span>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Space)`
  .ant-space-item {
    display: inline-flex;
    align-items: center;
  }
`;

const StyledCompliance = styled(Compliance)`
  width: 20px;
  height: 20px;
`;
