import { Select } from 'antd';

import { FullWidthSelect } from '@trustlayer/ui';
import type { KeyType } from './constants';
import type { FilterType, SelectedFilter } from './types';

type DateFilterTypeSelectProps = {
  selectedFilter?: SelectedFilter;
  filterTypes: FilterType[];
  onChange: (key: KeyType) => void;
};

const DateFilterTypeSelect = ({
  selectedFilter,
  filterTypes,
  onChange,
}: DateFilterTypeSelectProps) => {
  return (
    <div data-cy="dateFilterTypeSelect">
      <FullWidthSelect
        allowClear
        placeholder="Select..."
        defaultValue={selectedFilter?.key}
        onChange={onChange}
      >
        {filterTypes.map((option) => (
          <Select.Option key={option.key} value={option.key}>
            {option.label}
          </Select.Option>
        ))}
      </FullWidthSelect>
    </div>
  );
};

export default DateFilterTypeSelect;
