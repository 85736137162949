import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { isProduction } from '@config/EnvironmentConfig';
import graphqlClient from '@graphql/client';
import graphqlMiddleware from '@graphql/middlewares/graphqlMiddleware';

import loggerMiddleware from './middlewares/loggerMiddleware';
import promiseMiddleware from './middlewares/promiseMiddleware';
import sagaMiddleware from './middlewares/sagaMiddleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const configureStore = () => {
  const initialState = isServer ? {} : window.__PRELOADED_STATE__ || {};

  const middlewares = [
    graphqlMiddleware(graphqlClient),
    thunk,
    promiseMiddleware,
    sagaMiddleware,
  ];

  if (
    !isServer &&
    typeof jest === 'undefined' &&
    typeof Cypress === 'undefined' &&
    !isProduction
  ) {
    middlewares.push(loggerMiddleware);
  }

  const composeEnhancers = isServer
    ? compose
    : composeWithDevTools({ trace: true });

  const store = createStore(
    rootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store, { manualPersist: true });

  // eslint-disable-next-line fp/no-let
  let sagaTask = sagaMiddleware.run(rootSaga);

  if (import.meta.hot) {
    import.meta.hot.accept('./rootReducer', () => {
      import('./rootReducer').then((rootReducer) => {
        store.replaceReducer(rootReducer);
      });
    });

    import.meta.hot.accept('./rootSaga', () => {
      import('./rootSaga').then((rootSaga) => {
        sagaTask.cancel();
        sagaTask.toPromise().then(() => {
          sagaTask = sagaMiddleware.run(rootSaga);
        });
      });
    });
  }

  if (window.Cypress) {
    window.__store__ = store;
  }

  return { persistor, store };
};

export const { store, persistor } = configureStore();
