import Icon, { SyncOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import CheckIcon from '@common/icons/CheckIcon';
import UploadIcon from '@modules/request/components/Icons/UploadIcon';

import { fileValidator } from '../utils/request-helpers';

import DocumentUploadFail from './DocumentUploadFail';

const DocumentUploader = ({
  onUpload,
  format = '',
  failed = false,
  disableClick = false,
  uploading = false,
  uploaded = false,
}) => {
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      onUpload(acceptedFiles, rejectedFiles);
    },
    [onUpload],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    validator: fileValidator,
  });

  return (
    <DocumentUploader.Wrapper
      {...getRootProps({
        onClick: (e) => (disableClick ? e.preventDefault() : e),
      })}
    >
      <input
        {...getInputProps({
          onClick: (e) => (disableClick ? e.preventDefault() : e),
        })}
      />

      <DocumentUploader.Content>
        {failed ? (
          <DocumentUploadFail format={format} />
        ) : (
          <>
            {uploading && (
              <DocumentUploader.StatusWrapper>
                <SyncOutlined spin style={{ fontSize: 40 }} />
                <span>Uploading documents, please wait...</span>
              </DocumentUploader.StatusWrapper>
            )}
            {uploaded && (
              <DocumentUploader.StatusWrapper>
                <CheckIcon width={40} height={40} />
                <span>Documents successfully uploaded</span>
              </DocumentUploader.StatusWrapper>
            )}
            {!uploading && !uploaded && (
              <>
                <DocumentUploader.Icon
                  component={() => <UploadIcon size={40} />}
                />
                <DocumentUploader.ItemText>
                  Upload documents
                </DocumentUploader.ItemText>
              </>
            )}
          </>
        )}
        {!uploading && !uploaded && (
          <DocumentUploader.TextInstruction>
            Drag and drop files or <ins>click to browse and select</ins>
          </DocumentUploader.TextInstruction>
        )}
      </DocumentUploader.Content>
    </DocumentUploader.Wrapper>
  );
};

DocumentUploader.Wrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blueGray};
  border: 1px dashed ${(props) => props.theme.colors.darkGray};
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
  outline: none;
  text-align: center;

  &:hover {
    border-color: ${(props) => props.theme.colors.blue};
  }
`;
DocumentUploader.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  font-size: 15px;
`;

DocumentUploader.StatusWrapper = styled.div`
  display: grid;
  justify-items: center;
  gap: 10px;
`;

DocumentUploader.ItemText = styled.div`
  padding-top: 9px;
`;

DocumentUploader.TextInstruction = styled.div`
  color: ${(props) => props.theme.colors.grayText};
  padding-top: 7px;
  font-size: 12px;
`;

DocumentUploader.Icon = styled(Icon)`
  font-size: 40px;
`;

export default DocumentUploader;
