interface ComplianceProps extends React.SVGProps<SVGSVGElement> {
  type: 'good' | 'progress' | 'bad' | 'null' | undefined;
}

export const Compliance = ({ type, ...props }: ComplianceProps) => {
  switch (type) {
    case 'good':
      return <ComplianceGood fill="#52C41A" {...props} />;

    case 'progress':
      return <ComplianceProgress fill="#FA8C16" {...props} />;

    case 'bad':
      return <ComplianceBad fill="#F5222D" {...props} />;

    default:
      return null;
  }
};

const ComplianceGood = ({ fill, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.26562 16.1328C3.86719 16.1328 3.21875 15.4844 3.21875 14.0859V12.3984C3.21875 12.2344 3.17969 12.125 3.0625 12L1.86719 10.7969C0.882812 9.8125 0.875 8.89844 1.86719 7.91406L3.0625 6.71094C3.17969 6.59375 3.21875 6.47656 3.21875 6.32031V4.625C3.21875 3.21875 3.86719 2.57812 5.26562 2.57812H6.96094C7.11719 2.57812 7.23438 2.53906 7.35156 2.42188L8.55469 1.22656C9.53906 0.242188 10.4531 0.234375 11.4375 1.22656L12.6406 2.42188C12.7656 2.53906 12.875 2.57812 13.0391 2.57812H14.7266C16.1328 2.57812 16.7734 3.23438 16.7734 4.625V6.32031C16.7734 6.47656 16.8203 6.59375 16.9375 6.71094L18.1328 7.91406C19.1172 8.89844 19.125 9.8125 18.1328 10.7969L16.9375 12C16.8203 12.125 16.7734 12.2344 16.7734 12.3984V14.0859C16.7734 15.4922 16.125 16.1328 14.7266 16.1328H13.0391C12.875 16.1328 12.7656 16.1797 12.6406 16.2969L11.4375 17.4922C10.4531 18.4766 9.53906 18.4844 8.55469 17.4922L7.35156 16.2969C7.23438 16.1797 7.11719 16.1328 6.96094 16.1328H5.26562ZM9.125 13.1719C9.39062 13.1719 9.61719 13.0469 9.78125 12.7969L13.4531 7.00781C13.5469 6.85156 13.6484 6.67188 13.6484 6.49219C13.6484 6.13281 13.3281 5.89844 12.9922 5.89844C12.7812 5.89844 12.5781 6.01562 12.4297 6.25781L9.09375 11.6172L7.50781 9.57031C7.3125 9.3125 7.14062 9.23438 6.92188 9.23438C6.5625 9.23438 6.28906 9.52344 6.28906 9.88281C6.28906 10.0547 6.35938 10.2344 6.47656 10.3906L8.4375 12.7969C8.64062 13.0703 8.85938 13.1719 9.125 13.1719Z"
      fill={fill}
    />
  </svg>
);

const ComplianceProgress = ({
  fill,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25781 14.5625C1.625 14.5625 0.804688 13.7578 0.804688 12.1406V2.60156C0.804688 0.984375 1.625 0.179688 3.25781 0.179688H12.7344C14.375 0.179688 15.1875 0.992188 15.1875 2.60156V12.1406C15.1875 13.75 14.375 14.5625 12.7344 14.5625H3.25781ZM4.47656 7.91406C4.47656 8.11719 4.64062 8.26562 4.84375 8.26562H7.57812L6.11719 12.1797C5.92188 12.6953 6.47656 12.9766 6.8125 12.5547L11.2344 7.01562C11.3125 6.92188 11.3594 6.80469 11.3594 6.70312C11.3594 6.5 11.2031 6.35156 10.9922 6.35156H8.26562L9.71875 2.4375C9.92188 1.92188 9.35938 1.64062 9.02344 2.05469L4.60938 7.59375C4.52344 7.69531 4.47656 7.8125 4.47656 7.91406Z"
      fill={fill}
    />
  </svg>
);

const ComplianceBad = ({ fill, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.94531 15.7422C1.61719 15.7422 0.8125 14.8203 0.8125 13.625C0.8125 13.2578 0.90625 12.8828 1.11719 12.5312L7.17969 1.96875C7.57812 1.25781 8.28125 0.898438 9 0.898438C9.71875 0.898438 10.4141 1.25781 10.8203 1.96875L16.8828 12.5312C17.0781 12.875 17.1875 13.2578 17.1875 13.625C17.1875 14.8203 16.3828 15.7422 15.0547 15.7422H2.94531ZM9.00781 10.5234C9.39062 10.5234 9.61719 10.2969 9.625 9.875L9.74219 5.63281C9.75 5.21875 9.42188 4.91406 9 4.91406C8.5625 4.91406 8.25781 5.21094 8.26562 5.625L8.36719 9.875C8.375 10.2891 8.60156 10.5234 9.00781 10.5234ZM9.00781 13.1406C9.46875 13.1406 9.875 12.7734 9.875 12.3047C9.875 11.8281 9.47656 11.4609 9.00781 11.4609C8.53125 11.4609 8.13281 11.8359 8.13281 12.3047C8.13281 12.7656 8.53906 13.1406 9.00781 13.1406Z"
      fill={fill}
    />
  </svg>
);
