import type { Address as AddressAPI } from '@graphql/types/graphql';

export const ATTRIBUTES_TYPENAMES = {
  SystemAttribute: 'SystemAttribute',
  CustomAttribute: 'CustomAttribute',
};

export const DEFAULT_EMPTY_SELECT_VALUE = {
  _id: 'empty',
  value: 'Not Set',
};

export enum ATTRIBUTE_TYPES {
  Dropdown = 'dropdown',
  Number = 'number',
  Text = 'text',
  Address = 'address',
  MultiSelect = 'multiSelect',
}

export type AttributeType =
  (typeof ATTRIBUTE_TYPES)[keyof typeof ATTRIBUTE_TYPES];

export type Address = {
  raw: string;
  city: string;
  country: string;
  postalCode?: string;
  region: string;
  addressLine1: string;
  addressLine2?: string;
  latitude: number;
  longitude: number;
};

export type AttributeToUpdate =
  | {
      id: string;
      value: string | number | AddressAPI | null;
    }
  | {
      id: string;
      optionIds: string[] | null;
    };
