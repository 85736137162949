import styled from 'styled-components';

export default styled.div`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);
  text-overflow: ellipsis;
  overflow: hidden;

  > span,
  > a {
    white-space: nowrap;
  }

  > p {
    margin: 0;
    font-size: 11px;
    color: ${(props) => props.theme.colors.grayText};
    text-overflow: ellipsis;
    overflow: hidden;

    > span {
      white-space: nowrap;
    }
  }
`;
