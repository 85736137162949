import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const UPLOAD_DOCUMENT_MUTATION = graphql(`
  mutation uploadDocument($payload: ProcessDocumentUrlInput!) {
    processDocumentUrl(data: $payload) {
      _id
    }
  }
`);

export const useUploadDocumentMutation = () => {
  const [uploadDocument, { loading }] = useMutation(UPLOAD_DOCUMENT_MUTATION, {
    context: { hasUpload: true },
  });

  return {
    uploadDocument,
    loading,
  };
};
