import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const INTEGRATION_STATUS_META = `${namespace}/INTEGRATION_STATUS_META`;

const INTEGRATION_STATUS_META_QUERY = gql`
  query integrationStatus($data: IntegrationStatusInput!) {
    integrationStatus(data: $data) {
      provider
      instance
      status {
        meta {
          lastSyncedAt
          parties {
            total
            unlinked
          }
          projects {
            total
            unlinked
          }
        }
      }
    }
  }
`;

export const fetchIntegrationMeta = (data) => ({
  type: INTEGRATION_STATUS_META,
  payload: {
    key: 'integrationStatus',
    graphql: {
      fetchPolicy: 'no-cache',
      query: INTEGRATION_STATUS_META_QUERY,
      variables: {
        data: {
          ...data,
          onlyMeta: true,
        },
      },
    },
  },
});
