import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ARCHIVE_PRIMARY_RECORDS_MUTATION = graphql(`
  mutation ArchivePrimaryRecords($data: ArchivePrimaryRecordsInput!) {
    archivePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useArchivePrimaryRecordsMutation = () => {
  const [
    archivePrimaryRecordsMutation,
    { loading: isArchivingPrimaryRecords },
  ] = useMutation(ARCHIVE_PRIMARY_RECORDS_MUTATION);

  const archivePrimaryRecords = (filters?: ServerFilterInput) => {
    return archivePrimaryRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    archivePrimaryRecords,
    isArchivingPrimaryRecords,
  };
};
