import { graphql } from '@graphql/types';

export const WEB_APP_UPDATES_SUBSCRIPTION = graphql(`
  subscription WebAppUpdatesSubscription($version: String) {
    webAppUpdates(version: $version) {
      version
      hardrefresh
    }
  }
`);
