import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PROJECT_FRAGMENT } from '../fragments';

export const CREATE_PROJECT = `${namespace}/CREATE_PROJECT`;

const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($payload: CreateProjectInput!) {
    createProject(data: $payload) {
      ...Project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const createProject = (payload) => ({
  type: CREATE_PROJECT,
  payload: {
    key: 'createProject',
    graphql: {
      mutation: CREATE_PROJECT_MUTATION,
      variables: {
        payload,
      },
      context: { hasUpload: true },
    },
  },
});
