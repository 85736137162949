import { graphql } from '@graphql/types';

export const ADDITIONAL_NOTES_QUERY = graphql(`
  query PrimaryRecord_AdditionalNotes($primaryRecordInput: PrimaryRecordInput!) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      additionalNotes {
        message
      }
    }
  }
`);
