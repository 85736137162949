import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_FRAGMENT } from '../fragments';

export const CREATE_ORGANIZATION = `${namespace}/CREATE_ORGANIZATION`;

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation createOrganization($payload: CreateOrganizationInput!) {
    createOrganization(data: $payload) {
      ...Organization
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const createOrganization = (payload) => ({
  type: CREATE_ORGANIZATION,
  payload: {
    key: 'createOrganization',
    graphql: {
      mutation: CREATE_ORGANIZATION_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
