import { type FirebaseApp, getApps, initializeApp } from 'firebase/app';
import {
  type Auth,
  type Unsubscribe,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth';
import type React from 'react';
import { type ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Preloader from '@common/components/Preloader';
import FirebaseConfig from '@config/FirebaseConfig';
import { authConsume } from '@modules/auth/actions';
import FirebaseAuthProvider from '@modules/auth/components/FirebaseAuthProvider';
import { useAppDispatch } from '@store/hooks';

import { getIsAuthenticated } from '../selectors';

type WithFirebase = (
  Page: React.FC,
) => (props: any) => ReactElement<any, any> | null;

const withFirebase: WithFirebase = (Page) => (props) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [isLoading, setIsLoading] = useState(true);
  const unsubscribeAuthChangeRef = useRef<Unsubscribe>(() => {});
  const [firebaseApp, setFirebaseApp] = useState<{
    app: FirebaseApp;
    auth: Auth;
  }>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const initializeFirebase = () => {
      const { apiKey, authDomain, tenantId, emulatorHost } = FirebaseConfig;

      const app = initializeApp({ apiKey, authDomain });
      const auth = getAuth(app);

      if (emulatorHost) {
        connectAuthEmulator(auth, emulatorHost, { disableWarnings: true });
      }

      if (!emulatorHost && tenantId) {
        // Use tenantId that maps to the app environment by default.
        console.log(`Using tenantId ${tenantId}`);
        auth.tenantId = tenantId;
      }
    };

    setIsLoading(true);
    const apps = getApps();
    const isFirebaseAppInitialised = apps?.length;

    if (!isFirebaseAppInitialised) {
      initializeFirebase();
    }

    const app = apps?.[0];
    const auth = getAuth(app);

    unsubscribeAuthChangeRef.current = onAuthStateChanged(
      auth,
      async (user) => {
        // user is already authenticated
        if (user && !isAuthenticated) {
          const token = await user.getIdToken();
          await dispatch(
            authConsume({
              token,
              organizationSlugifyName:
                // Accessing the organizationSlugifyName from the props because the organizationSlugifyName
                // is not available inside the PrivateRoute component (where the HOC chains end).
                props.computedMatch?.params?.organizationSlugifyName,
            }),
          );
        }

        setFirebaseApp({
          auth,
          app,
        });
        setIsLoading(false);
        unsubscribeAuthChangeRef.current?.();
      },
    );

    // cleanup
    return unsubscribeAuthChangeRef.current;
  }, [dispatch]);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <FirebaseAuthProvider
      firebaseApp={firebaseApp?.app}
      firebaseAuth={firebaseApp?.auth}
    >
      <Page {...props} />
    </FirebaseAuthProvider>
  );
};

export default withFirebase;
