import { Popover } from 'antd';
import moment from 'moment';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { SendgridStatus, TitleByStatus } from '../constants';
import { getMostRecentUniqueEvent } from '../utils/party-conversation-helpers';

type Log = {
  _id: string;
  status: string;
  date: string;
  email: string;
};

const getFormatDate = (date: string): string => {
  if (!date) return '';
  return moment(date).format('lll');
};

function getMessageByStatus({ _id, status, date, email = '' }: Log) {
  if (status === SendgridStatus.Bounce || status === SendgridStatus.Dropped) {
    return (
      <StyledMessageInfoWarn key={_id}>
        Message unable to be delivered {Boolean(email) ? `to ${email}` : ''} on{' '}
        {getFormatDate(date)}
      </StyledMessageInfoWarn>
    );
  }

  return (
    <li key={_id}>
      {TitleByStatus[status]} on {getFormatDate(date)}
    </li>
  );
}

type EventLogsPopoverProps = {
  senderEmail: string;
  isSenderLogVisible: boolean;
  eventDate: string;
  recipients: string[];
  logs: any[];
  eventStatus: string;
  children: ReactNode;
};

export const EventLogsPopover = ({
  senderEmail,
  isSenderLogVisible,
  eventDate,
  recipients,
  logs,
  children,
}: EventLogsPopoverProps) => {
  return (
    <>
      <Popover
        title="Message information"
        placement="topLeft"
        content={
          <StyledLogsWrapper>
            <ul>
              {isSenderLogVisible && (
                <li>
                  {`Received from ${senderEmail} on ${getFormatDate(
                    eventDate,
                  )}`}
                </li>
              )}
              {Boolean(recipients?.length) && (
                <li>
                  {`Sent to ${recipients.join(', ')} on ${getFormatDate(
                    eventDate,
                  )}`}
                </li>
              )}
              {Boolean(logs?.length) &&
                getMostRecentUniqueEvent(logs).map((log) =>
                  getMessageByStatus(log as Log),
                )}
            </ul>
          </StyledLogsWrapper>
        }
      >
        {children}
      </Popover>
    </>
  );
};

const StyledLogsWrapper = styled.div`
  width: 13vw;
  font-size: 12px;

  li {
    margin-left: 8px;
  }
`;

const StyledMessageInfoWarn = styled.li`
  color: ${({ theme }) => theme.colors.darkYellow};
`;
