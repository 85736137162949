import { Modal, message as notify } from 'antd';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getEditorHTMLContent,
  getEmptyEditorState,
  getUpdatedEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import { getOrganizationMembers } from '@modules/organization-member/selectors';
import { getUser } from '@modules/user/selectors';

import { trackEvent } from '@common/utils/track-helpers';
import { deleteCommentEvent, updateCommentEvent } from '../actions';
import CommentEventLog from '../components/CommentEventLog';

const CommentEventLogContainer = ({ log }) => {
  const logData = log?.data || {};

  const [commentEditorState, setCommentContent] = useState(
    getEmptyEditorState(),
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);
  const organizationMembers = useSelector(getOrganizationMembers);

  const dispatch = useDispatch();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (isEdit) {
      setCommentContent(
        getUpdatedEditorState(logData.message, commentEditorState),
      );
    }
  }, [isEdit, log]);

  const members = R.compose(
    R.filter((member) => member.id !== user._id && member.name !== '@'),
    R.map((member) => ({
      id: R.path(['user', '_id'], member),
      name: `@${R.pathOr('', ['user', 'profile', 'name'], member).replace(
        / /g,
        '',
      )}`,
    })),
  )(organizationMembers);

  const getUserIdsFromComment = () => {
    const namesFromComment = R.compose(
      R.filter((word) => R.head(word) === '@'),
      R.split(' '),
    )(getEditorHTMLContent(commentEditorState).replace(/<[^>]*>?/gm, ''));

    return R.compose(
      R.map((member) => member.id.toString()),
      R.filter((member) =>
        namesFromComment.some((name) => name === member.name),
      ),
    )(members);
  };

  const updateComment = async () => {
    if (!isEditorStateBlank(commentEditorState)) {
      setIsLoading(true);
      await dispatch(
        updateCommentEvent({
          id: log._id,
          data: {
            'data.message': getEditorHTMLContent(commentEditorState),
            usersToSend: getUserIdsFromComment(),
          },
        }),
      );
      setIsEdit(false);
      setIsLoading(false);
    }
  };

  const deleteComment = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this comment?',
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const response = await dispatch(deleteCommentEvent({ id: log._id }));

        trackEvent('User left a comment on a party');

        if (response.error) {
          notify.error(response.error[0].message);
        }
      },
      onCancel: () => {},
    });
  };

  return (
    <CommentEventLog
      members={members}
      log={log}
      commentEditorState={commentEditorState}
      onCommentEditorChange={setCommentContent}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      isLoading={isLoading}
      updateComment={updateComment}
      deleteComment={deleteComment}
      isAuthorComment={user._id === log.actorId}
    />
  );
};

export default CommentEventLogContainer;
