export const ROLE_SUPPORT = 'support';
export const ROLE_OWNER = 'owner';
export const ROLE_ADMIN = 'admin';
export const ROLE_MEMBER = 'member';
export const ROLE_OBSERVER = 'observer';
export const ROLE_VIEWER = 'viewer';

export const ROLES = {
  SUPPORT: 'support',
  OWNER: 'owner',
  ADMIN: 'admin',
  MEMBER: 'member',
  OBSERVER: 'observer',
  VIEWER: 'viewer',
};

export const roles = [
  {
    value: ROLES.OWNER,
    label: 'Admin (Owner)',
  },
  {
    value: ROLES.ADMIN,
    label: 'Admin',
  },
  {
    value: ROLES.MEMBER,
    label: 'Team member',
  },
  {
    value: ROLES.OBSERVER,
    label: 'Observer',
  },
  {
    value: ROLES.VIEWER,
    label: 'Viewer',
  },
];

export const rolePriority = (role) => {
  const priorityMap = {
    [ROLES.SUPPORT]: 100,
    [ROLES.OWNER]: 50,
    [ROLES.ADMIN]: 40,
    [ROLES.MEMBER]: 30,
    [ROLES.OBSERVER]: 20,
    [ROLES.VIEWER]: 10,
  };

  return priorityMap[role?.toLowerCase()] || 0;
};

export const adminRoles = [ROLES.OWNER, ROLES.ADMIN];
export const nonAdminRoles = [ROLES.MEMBER, ROLES.OBSERVER, ROLES.VIEWER];
