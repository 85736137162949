import { setContext } from '@apollo/client/link/context';
import { getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { decodeToken } from '@common/utils/auth-utils';

const tokenRefreshLink = setContext(async (request, prevContext) => {
  if (!getApps().length) {
    return prevContext;
  }

  // eslint-disable-next-line fp/no-let
  let token;
  const auth = getAuth();
  token = await auth?.currentUser?.getIdToken();
  const { exp } = decodeToken(token);
  const buffer = 5 * 60 * 1000; // this will force Firebase to re-fetch a token if we're close to expiration
  const now = new Date();
  const expirationMinusBuffer = new Date(exp * 1000 - buffer);

  if (now > expirationMinusBuffer) {
    token = await auth?.currentUser?.getIdToken(true);
  }

  return { ...prevContext, token };
});

export default tokenRefreshLink;
