import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { DELETE_COMPLIANCE_PROFILE } from '@modules/compliance-profile/actions';
import {
  ARCHIVE_DOCUMENT,
  DELETE_DOCUMENT,
  DETACH_DOCUMENTS_FROM_PARTY,
} from '@modules/document/actions';
import {
  DELETE_PARTY,
  UPDATE_PARTY_ACTIVE_STATUS,
} from '@modules/party/actions';
import {
  ADD_PARTIES_TO_PROJECT,
  DELETE_PROJECTS,
  DETACH_DOCUMENTS_FROM_PROJECT,
  FETCH_PROJECTS_TOTAL_COUNT,
  REMOVE_PARTIES_FROM_PROJECT,
  REMOVE_PARTY_FROM_PROJECTS,
  UPDATE_PROJECTS_ACTIVE_STATUS,
} from '@modules/project/actions';
import { REMOVE_QUICKBOOKS_CREDENTIALS } from '@modules/quickbooks/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  BULK_OPERATION_COMPLETED,
  CLEAR_INTEGRATION_STATUS,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  DELETE_PERSONAL_ACCESS_TOKEN,
  FETCH_ORGANIZATION_BY_ID,
  FETCH_PERSONAL_ACCESS_TOKENS_BY_ORGANIZATION_ID,
  INTEGRATION_STATUS,
  INTEGRATION_STATUS_META,
  RELOAD_PAGE,
  SET_ACTIVE_ORGANIZATION,
  SET_ORGANIZATION_SLUGIFY_NAME,
  UPDATE_ACTIVE_ORGANIZATION,
  UPDATE_INTEGRATION_STATUS,
  UPDATE_ORGANIZATIONS,
  UPDATE_PERSONAL_ACCESS_TOKEN,
} from '../actions';
import { REMOVE_DELETED_INTEGRATION } from '../actions/removeDeletedIntegration';
import { SET_UPDATED_INTEGRATIONS } from '../actions/setUpdatedIntegrations';

const initialState = {
  data: {},
  active: null,
  slugifyName: '',
  integrationStatus: {
    procore: {},
    quickbooks: {},
  },
  reload: false,
  personalAccessTokens: [],
  bulkOperationCompleted: { status: false },
};

export const getIntegrationKey = (provider, instance) => {
  if (provider && instance) {
    return `${provider}__${instance}`;
  }

  return provider;
};

export const getProviderInstanceFromKey = (key) => key.split('__');

const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_ORGANIZATION}_SUCCESS`: {
      const organization = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assocPath(['data', organization.id], organization, state);
    }
    case `${FETCH_ORGANIZATION_BY_ID}_SUCCESS`: {
      const organization = action.payload;
      return R.assocPath(['data', organization.id], organization, state);
    }
    case UPDATE_ORGANIZATIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    }
    case SET_ACTIVE_ORGANIZATION: {
      return R.assoc('active', action.payload, state);
    }
    case `${UPDATE_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${REMOVE_QUICKBOOKS_CREDENTIALS}_SUCCESS`: {
      const organization = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.mergeDeepRight(state, {
        active: organization,
        data: { [organization.id]: organization },
      });
    }
    case SET_ORGANIZATION_SLUGIFY_NAME: {
      return R.assoc('slugifyName', action.payload, state);
    }
    case `${REMOVE_PARTY_FROM_PROJECTS}_SUCCESS`:
    case `${REMOVE_PARTIES_FROM_PROJECT}_SUCCESS`:
    case `${UPDATE_PARTY_ACTIVE_STATUS}_SUCCESS`:
    case `${DELETE_PARTY}_SUCCESS`:
    case `${DETACH_DOCUMENTS_FROM_PARTY}_SUCCESS`:
    case `${DETACH_DOCUMENTS_FROM_PROJECT}_SUCCESS`:
    case `${ARCHIVE_DOCUMENT}_SUCCESS`:
    case `${DELETE_DOCUMENT}_SUCCESS`:
    case `${UPDATE_PROJECTS_ACTIVE_STATUS}_SUCCESS`:
    case `${DELETE_PROJECTS}_SUCCESS`:
    case `${DELETE_COMPLIANCE_PROFILE}_SUCCESS`:
    case `${ADD_PARTIES_TO_PROJECT}_SUCCESS`: {
      return R.assoc('reload', true, state);
    }
    case RELOAD_PAGE: {
      return R.assoc('reload', action.payload, state);
    }
    case BULK_OPERATION_COMPLETED: {
      return {
        ...state,
        bulkOperationCompleted: action.payload,
      };
    }
    case `${DELETE_ORGANIZATION}_SUCCESS`: {
      const organization = R.compose(
        R.defaultTo({}),
        getGraphqlPayload,
      )(action);

      return R.assoc(
        'data',
        R.omit([organization.id], R.path(['data'], state)),
        state,
      );
    }

    case `${INTEGRATION_STATUS_META}_SUCCESS`: {
      const payload = getGraphqlPayload(action);
      if (!payload?.provider) return state;

      const key = getIntegrationKey(payload.provider, payload.instance);
      const mergedData = R.mergeRight(
        payload?.status,
        state?.integrationStatus?.[key],
      );

      return R.assocPath(['integrationStatus', key], mergedData, state);
    }

    case `${INTEGRATION_STATUS}_SUCCESS`: {
      const payload = getGraphqlPayload(action);
      if (!payload?.provider) return state;

      if (R.pathOr(true, ['status', 'inProgress'], payload)) {
        return state;
      }

      const key = getIntegrationKey(payload.provider, payload.instance);
      // Merge all the data except parties and projects
      const mergedData = R.compose(
        R.mergeLeft(payload?.status),
        R.omit(['parties', 'projects']),
      )(state?.integrationStatus?.[key]);

      return R.assocPath(['integrationStatus', key], mergedData, state);
    }

    case UPDATE_INTEGRATION_STATUS: {
      const integrationStatus = R.prop('integrationStatus', state);

      return R.assoc(
        'integrationStatus',
        R.mergeDeepRight(integrationStatus, action.payload),
        state,
      );
    }
    case CLEAR_INTEGRATION_STATUS: {
      const key = getIntegrationKey(
        action.payload.provider,
        action.payload.instance,
      );

      return R.assoc(
        'integrationStatus',
        R.assoc(key, {}, R.prop('integrationStatus', state)),
        state,
      );
    }
    case `${FETCH_PROJECTS_TOTAL_COUNT}_SUCCESS`: {
      return R.assocPath(
        ['active', 'projectsTotalCount'],
        R.prop('totalCount', getGraphqlPayload(action)),
        state,
      );
    }
    case `${FETCH_PERSONAL_ACCESS_TOKENS_BY_ORGANIZATION_ID}_SUCCESS`: {
      return R.assoc(
        'personalAccessTokens',
        R.propOr([], 'personalAccessTokens', getGraphqlPayload(action)),
        state,
      );
    }
    case `${DELETE_PERSONAL_ACCESS_TOKEN}_SUCCESS`:
    case `${UPDATE_PERSONAL_ACCESS_TOKEN}_SUCCESS`: {
      return R.assoc('personalAccessTokens', getGraphqlPayload(action), state);
    }
    case `${REMOVE_DELETED_INTEGRATION}`: {
      const { provider, instance } = action.payload;
      const filteredCredentials = state.active.credentials?.filter(
        (credential) => {
          if (
            credential.provider === provider &&
            (!instance || credential.instance === instance)
          ) {
            return false;
          }

          return true;
        },
      );

      const integrationKey = getIntegrationKey(provider, instance);

      return {
        ...state,
        integrationStatus: {
          ...state.integrationStatus,
          [integrationKey]: {},
        },
        active: {
          ...state.active,
          credentials: filteredCredentials,
        },
        data: {
          ...state.data,
          [state.active.id]: {
            ...state.data[state.active.id],
            credentials: filteredCredentials,
          },
        },
      };
    }
    case `${SET_UPDATED_INTEGRATIONS}`: {
      return {
        ...state,
        data: {
          ...state.data,
          [state.active.id]: {
            ...state.data[state.active.id],
            credentials: action.payload,
          },
        },
        active: {
          ...state.active,
          credentials: action.payload,
        },
      };
    }
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default OrganizationReducer;
