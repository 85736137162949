import { ClockCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const WaitIcon = () => {
  return (
    <StyledIconWrapper>
      <ClockCircleOutlined style={{ color: '#fa8c16' }} />
    </StyledIconWrapper>
  );
};

const StyledIconWrapper = styled.span`
  padding: 5px 7px;
  border-radius: 8px;
  background-color: #fff0de;
`;
