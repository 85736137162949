import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

export const FilterButton = ({
  activeFiltersCount,
  onClick,
  className,
}: {
  activeFiltersCount: number;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <Button icon={<FilterOutlined />} onClick={onClick} className={className}>
      Filter
      {activeFiltersCount > 0 && (
        <StyledFilterCount>{activeFiltersCount}</StyledFilterCount>
      )}
    </Button>
  );
};
const StyledFilterCount = styled.span`
  margin-left: 6px;
  position: relative;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.blue};
  font-size: 11px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
`;
