import { EventType } from './project';

export * from './project';

export const namespace = 'PROJECT';
export const STATE_KEY = 'project';

export const bulkEventDefaultMessage = {
  [EventType.bulkUpdateProjectsActiveStatusSuccess]: `Your projects status have been successfully updated`,
  [EventType.bulkDeleteProjectsSuccess]: `Your projects have been successfully deleted`,
};

export const bulkEventAnalyticsMessage = {
  [EventType.bulkUpdateProjectsActiveStatusSuccess]:
    'User updated projects status',
  [EventType.bulkDeleteProjectsSuccess]: 'User deleted projects',
};
