import * as R from 'ramda';

import { AUTH_CONSUME, LOGIN, LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  FETCH_ME,
  SET_IS_ASIDE_NAVIGATION_COLLAPSED,
  UPDATE_USER,
} from '../actions';

const initialState = {
  _id: null,
  email: null,
  isActive: false,
  activeOrganization: '',
  profile: null,
  createdAt: null,
  isAsideNavigationCollapsed: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_SUCCESS`:
    case `${AUTH_CONSUME}_SUCCESS`: {
      return R.mergeDeepRight(
        state,
        R.propOr({}, 'user', getGraphqlPayload(action)),
      );
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${UPDATE_USER}_SUCCESS`:
    case `${FETCH_ME}_SUCCESS`: {
      return R.mergeDeepRight(state, getGraphqlPayload(action));
    }
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    case SET_IS_ASIDE_NAVIGATION_COLLAPSED: {
      return {
        ...state,
        isAsideNavigationCollapsed: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default UserReducer;
