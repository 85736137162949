import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { checkDocumentRequestExistence } from '@modules/request/actions';
import { RequestLandingOrganizationFeatureProvider } from '@modules/request/containers/RequestLandingOrganizationFeatureProvider';
import { getGraphqlPayload } from '@store/helpers';
import { getIsAuthenticated } from '../selectors';

const RequestLandingGuardLayout = () => {
  const { requestId } = useParams();
  const [requestOpen, setRequestOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated && requestId) {
        const res = await dispatch(checkDocumentRequestExistence(requestId));
        if (getGraphqlPayload(res as any)) {
          setRequestOpen(true);
          return;
        }
        navigate('/404');
      }
    })();
  }, [dispatch, navigate, isAuthenticated, requestId]);

  return (
    (isAuthenticated || requestOpen) && (
      <RequestLandingOrganizationFeatureProvider requestId={requestId!}>
        <Outlet />
      </RequestLandingOrganizationFeatureProvider>
    )
  );
};

export default RequestLandingGuardLayout;
