import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getGraphqlPayload } from '@store/helpers';

import { fetchDocument } from '../actions';
import {
  downloadDocumentFile,
  downloadPSPDFKitDocument,
  isSupportedFile,
} from '../utils/document-helpers';

export const useDownloadDocument = () => {
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);
  const dispatch = useDispatch();

  const downloadDocument = async (document) => {
    setIsDownloadingDocument(true);

    const isDocumentMissingData =
      !document?.annotations || !document?.fillableForm;

    const fullDocument = isDocumentMissingData
      ? getGraphqlPayload(await dispatch(fetchDocument({ id: document?._id })))
      : document;

    if (!isSupportedFile(document?.s3Key)) {
      await downloadDocumentFile(document);
    } else {
      await downloadPSPDFKitDocument(fullDocument);
    }

    setIsDownloadingDocument(false);
  };

  return {
    isDownloadingDocument,
    downloadDocument,
  };
};
