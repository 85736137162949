import type { ReactNode } from 'react';
import styled from 'styled-components';

type CollapseHeaderProps = {
  label: string;
  icon?: ReactNode;
};

export const CollapseHeader = ({ label, icon }: CollapseHeaderProps) => {
  return (
    <StyledWrapper>
      <div className="icon">{icon || null}</div>
      {label}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mineShaft};

  .icon {
    opacity: 0.7;
  }
`;
