import qs from 'query-string';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getPreferredSearchParams } from '@modules/system-settings/selectors';

const useEntityQueryString = () => {
  const { search } = useLocation();
  const preferredSearchParams = useSelector(getPreferredSearchParams);
  const searchParams = qs.parse(search);

  const getQsForEntityList = useCallback(
    (entity) => qs.stringify(preferredSearchParams[entity]),
    [preferredSearchParams],
  );

  const getQsForEntityInParty = useCallback(
    (entity) => qs.stringify(preferredSearchParams[`party-${entity}`]),
    [preferredSearchParams],
  );

  return { getQsForEntityList, getQsForEntityInParty };
};

export default useEntityQueryString;
