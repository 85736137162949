import { graphql } from '@graphql/types';

export const EMAIL_ACTIVITIES_QUERY = graphql(`
  query PrimaryRecord_EmailActivities(
    $primaryRecordInput: PrimaryRecordInput!
    $input: PrimaryRecordEmailActivitiesInput
  ) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      emailActivities(input: $input) {
        nodes {
          _id
          data
          primaryRecordName
          type
          createdAt
        }
        totalCount
      }
    }
  }
`);
