import DOMPurify from 'dompurify';
import * as R from 'ramda';

/**
 * Camelize string.
 */
export const camelize = (str) =>
  str
    .replace(/\s(.)/g, ($1) => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());

/**
 * For decamelize.
 */
export const decamelize = (str, separator) =>
  str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2');

/**
 * Camelize string with first upper letter.
 */
export const camelizeWithFirstUpper = (str) =>
  R.adjust(0, R.toUpper, camelize(str)).join('');

export const containsText = (content) => {
  const sanitizedContent = DOMPurify.sanitize(content);
  const node = document.createElement('div');
  node.innerHTML = sanitizedContent;
  return node.innerText?.trim() !== '';
};

export const stringToArrayBuffer = (binaryString = '') => {
  const buffer = new ArrayBuffer(binaryString.length);
  // eslint-disable-next-line fp/no-let
  let bufferView = new Uint8Array(buffer);
  // eslint-disable-next-line fp/no-loops, fp/no-let
  for (let i = 0, len = binaryString.length; i < len; i++) {
    bufferView[i] = binaryString.charCodeAt(i);
  }
  return buffer;
};

/**
 * @description - takeFirstIfIsArray takes a string or array as input and returns
 *                the firs element if is an array or return the value if is null || string
 */
export const takeFirstIfIsArray = (value) => {
  if (Array.isArray(value)) return value[0];
  return value;
};

export const addTargetBlankToLinks = (text) => {
  const linkRegex = /<a href="([^"]+)"/g;
  const linkReplacement = `<a target="_blank" href="$1"`;
  return text.replace(linkRegex, linkReplacement);
};
