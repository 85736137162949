import { getActiveOrganizationData } from '@modules/organization/selectors';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { PageLayoutWithSideNavigation } from '../PageLayoutWithSideNavigation';

export const PageLayout = () => {
  const activeOrg = useSelector(getActiveOrganizationData);
  return (
    <PageLayoutWithSideNavigation organizationCode={activeOrg.slugifyName}>
      <Outlet />
    </PageLayoutWithSideNavigation>
  );
};
