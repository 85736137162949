function isNewFlagEnabled(flagNames?: string[], prevFlagNames?: string[]) {
  if (flagNames === undefined || prevFlagNames === undefined) {
    return false;
  }

  const flagsSet = new Set(flagNames);
  const prevFlagsSet = new Set(prevFlagNames);

  if (flagsSet.size > prevFlagsSet.size) {
    return true;
  }

  for (const item of flagsSet) {
    if (!prevFlagsSet.has(item)) {
      return true;
    }
  }

  return false;
}

type FeatureFlags = Record<
  string,
  {
    name: string;
    enabled: boolean;
  }
>;

const getEnabledFeatureFlagsNames = (featureFlags?: FeatureFlags) => {
  if (!featureFlags) return;
  return Object.values(featureFlags)
    ?.filter((flag) => flag.enabled)
    .map((flag) => flag.name);
};

export const checkShouldRefresh = ({
  featureFlags,
  prevFeatureFlags,
  latestVersion,
  currentVersion,
  hardrefresh,
}: {
  featureFlags: FeatureFlags;
  prevFeatureFlags?: FeatureFlags;
  latestVersion?: string;
  currentVersion: string;
  hardrefresh?: boolean;
}) => {
  const enabledFeatureFlagsNames = getEnabledFeatureFlagsNames(featureFlags);
  const prevEnabledFeatureFlagsNames =
    getEnabledFeatureFlagsNames(prevFeatureFlags);

  const newFeatureFlagTurnedOn = isNewFlagEnabled(
    enabledFeatureFlagsNames,
    prevEnabledFeatureFlagsNames,
  );

  const shouldForceRefresh = Boolean(
    latestVersion &&
      currentVersion !== latestVersion &&
      (newFeatureFlagTurnedOn || hardrefresh),
  );

  return shouldForceRefresh;
};
