import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const TOGGLE_COMPLIANCE_TRACKING = `${namespace}/TOGGLE_COMPLIANCE_TRACKING`;

const TOGGLE_COMPLIANCE_TRACKING_MUTATION = gql`
  mutation toggleComplianceTracking($payload: ToggleComplianceTrackingInput!) {
    toggleComplianceTracking(data: $payload) {
      _id
      isTrackingCompliance
    }
  }
`;

export const toggleComplianceTracking = (payload) => ({
  type: TOGGLE_COMPLIANCE_TRACKING,
  payload: {
    key: 'toggleComplianceTracking',
    graphql: {
      mutation: TOGGLE_COMPLIANCE_TRACKING_MUTATION,
      variables: { payload },
    },
  },
});
