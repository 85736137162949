import { gql } from '@apollo/client';

import { graphql } from '@graphql/types';

const PARTY_AUTOMATIONS = gql`
  fragment PartyAutomations on PartyDataEntity {
    automations {
      _id
      status
      processing
      actionsCount
      scheduledActions {
        status
        scheduledAt
        executedAt
        result
      }
    }
  }
`;

export const ENABLE_PARTY_AUTOMATION_MUTATION = gql`
  ${PARTY_AUTOMATIONS}
  mutation EnablePartyAutomation($data: EnablePartyAutomationInput!) {
    enablePartyAutomation(data: $data) {
      ...PartyAutomations
    }
  }
`;

export const DISABLE_PARTY_AUTOMATION_MUTATION = gql`
  ${PARTY_AUTOMATIONS}
  mutation DisablePartyAutomation($data: DisablePartyAutomationInput!) {
    disablePartyAutomation(data: $data) {
      ...PartyAutomations
    }
  }
`;

export const BULK_ENABLE_PARTY_AUTOMATION_MUTATION = gql`
  mutation BulkEnablePartyAutomation($data: BulkEnablePartyAutomationInput!) {
    bulkEnablePartyAutomation(data: $data) {
      scheduled
    }
  }
`;

export const BULK_DISABLE_PARTY_AUTOMATION_MUTATION = gql`
  mutation BulkDisablePartyAutomation($data: BulkDisablePartyAutomationInput!) {
    bulkDisablePartyAutomation(data: $data) {
      scheduled
    }
  }
`;

export const MATCH_COMPLIANCE_MUTATION = graphql(`
  mutation MatchCompliance($data: MatchComplianceInput!) {
    matchCompliance(data: $data) {
      _id
    }
  }
`);

export const BULK_MATCH_COMPLIANCE_MUTATION = graphql(`
  mutation BulkMatchCompliance($data: BulkMatchComplianceInput!) {
    bulkMatchCompliance(data: $data) {
      scheduled
    }
  }
`);

export const BULK_ENABLE_PARTIES_CONTACTS_REMINDERS_MUTATION = graphql(`
  mutation BulkEnablePartiesContactsReminders($data: BulkEnablePartiesContactsRemindersInput!) {
    bulkEnablePartiesContactsReminders(data: $data) {
      scheduled
    }
  }
`);
