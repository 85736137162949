import { message } from 'antd';
import debounce from 'lodash/debounce';

import { AUTH_ERRORS_CODES } from '@modules/auth/constants';

const IGNORED_ERROR_CODES = [...AUTH_ERRORS_CODES];

const debouncedErrorMessage = debounce((displayedMessage) => {
  message.error(displayedMessage);
}, 900);

export const showGraphqlErrorToastMessage = (errorMessage: string) => {
  if (!errorMessage) return;

  if (!IGNORED_ERROR_CODES.includes(errorMessage)) {
    debouncedErrorMessage(`Error: ${errorMessage}`);
  }
};
