export const STATE_KEY = 'party-conversation' as const;

export const namespace = 'PARTY_CONVERSATION' as const;

export const MessageType = {
  Reminder: 'reminder',
  Request: 'request',
  Message: 'message',
  Expiring: 'expiring',
  NewTokenLink: 'new:token:link',
  RequestForwarded: 'request:forwarded',
  ContactRemovedFromConversation: 'contact:removed:from:conversation',
  ContactDeleted: 'contact:deleted',
  ContactEmailUpdatedResent: 'contact:email:updated:resent',
  MessageResent: 'message:resent',
} as const;

export const MessageTitleByType = {
  [MessageType.Reminder]: 'Reminder was sent.',
  [MessageType.Request]: 'Document request was sent.',
  [MessageType.Expiring]: 'Automatic reminder was sent.',
} as const;

export const SenderType = {
  User: 'User',
  TrustLayer: 'TrustLayer',
};

export const PartyMessageStatus = {
  Warning: 'warning',
  Read: 'read',
  Sent: 'sent',
} as const;

export const SendgridStatus = {
  Delivered: 'delivered',
  Bounce: 'bounce',
  Dropped: 'dropped',
  Open: 'open',
  Click: 'click',
  Processed: 'processed',
  SpamReport: 'spamreport',
};

export const TitleByStatus = {
  [SendgridStatus.Processed]: 'Processed',
  [SendgridStatus.Dropped]: 'Dropped',
  [SendgridStatus.Delivered]: 'Delivered',
  [SendgridStatus.Bounce]: 'Bounced',
  [SendgridStatus.Open]: 'Opened',
  [SendgridStatus.Click]: 'Clicked',
  [SendgridStatus.SpamReport]: 'Spam report',
} as const;

export const PartyConversationType = {
  Conversation: 'conversation',
  DocumentRequest: 'document:request',
} as const;

export const ConversationSubscriptionType = {
  ConversationUpdate: 'conversation:update',
  ConversationMessageFailed: 'conversation:message:failed',
  ConversationMessageSent: 'conversation:message:sent',
} as const;

export const ReplyMarker = `trustlayer-reply-marker` as const;
