import { gql } from '@apollo/client';

export const PARTY_TYPE_FRAGMENT = gql`
  fragment PartyType on PartyType {
    _id
    name
    pluralName
    organization
    defaultComplianceProfile {
      _id
      name
    }
    partiesCount
    createdAt
    updatedAt
  }
`;
