import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const DELETE_CONTACT = `${namespace}/DELETE_CONTACT`;

const DELETE_CONTACT_MUTATION = gql`
  mutation deletePartyContact($input: DeletePartyContactInput!) {
    deletePartyContact(input: $input) {
      _id
      partyContacts {
        _id
        party
        contactPersonName
        companyName
        email
        phone
        fax
        title
        avatar
        isPrimary
        isDefaultRequestRecipient
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteContact = ({ id, conversationId }) => ({
  type: DELETE_CONTACT,
  payload: {
    key: 'deletePartyContact',
    graphql: {
      mutation: DELETE_CONTACT_MUTATION,
      variables: {
        input: {
          id,
          conversationId,
        },
      },
    },
  },
});
