import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_LAYOUT_FRAGMENT } from '../fragments';

export const RESET_TO_SYSTEM_DEFAULT = `${namespace}/RESET_TO_SYSTEM_DEFAULT`;

const RESET_TO_SYSTEM_DEFAULT_MUTATION = gql`
  mutation resetToSystemDefault($_id: String!) {
    resetToSystemDefault(_id: $_id) {
      ...EmailLayout
    }
  }
  ${EMAIL_LAYOUT_FRAGMENT}
`;

export const resetToSystemDefault = (_id) => ({
  type: RESET_TO_SYSTEM_DEFAULT,
  payload: {
    key: 'resetToSystemDefault',
    graphql: {
      mutation: RESET_TO_SYSTEM_DEFAULT_MUTATION,
      variables: {
        _id,
      },
    },
  },
});
