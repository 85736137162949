import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_DELETE_DOCUMENTS = `${namespace}/BULK_DELETE_DOCUMENTS`;

const BULK_DELETE_DOCUMENTS_MUTATION = gql`
  mutation bulkDeleteDocuments($payload: BulkDeleteDocumentsInput) {
    bulkDeleteDocuments(data: $payload) {
      scheduled
    }
  }
`;

export const bulkDeleteDocuments = (payload) => ({
  type: BULK_DELETE_DOCUMENTS,
  payload: {
    key: 'bulkDeleteDocuments',
    graphql: {
      mutation: BULK_DELETE_DOCUMENTS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
