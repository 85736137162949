import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getPartyTypes = R.compose(R.values, R.path([STATE_KEY, 'data']));

export const getPartyTypeById = (state, partyTypeId) =>
  R.path([STATE_KEY, 'data', partyTypeId], state);

export const getIsAssigningType = R.path([STATE_KEY, 'isAssigningType']);
