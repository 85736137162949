import { Progress, notification } from 'antd';
import * as R from 'ramda';
import { useEffect } from 'react';

const ProgressNotification = ({ notificationKey, percentage, onClose }) => {
  useEffect(() => {
    if (R.isNil(percentage)) {
      notification.close(notificationKey);
      return;
    }

    notification.open({
      key: notificationKey,
      message: 'Download in progress...',
      description: <Progress percent={percentage} />,
      duration: 0,
      placement: 'bottomRight',
      onClose,
      className: 'data-cy_ProgressNotification',
    });

    if (percentage === 100) {
      setTimeout(() => notification.close(notificationKey), 1000);
      return;
    }
  }, [percentage, notificationKey, onClose]);

  return null;
};

export default ProgressNotification;
