import { FILTER_OPERATORS } from '@common/constants/filters';

export const getIdsFilter = (ids: string[]) => ({
  and: [
    {
      name: 'id',
      operator: FILTER_OPERATORS.in,
      value: ids,
    },
  ],
});
