const IntegrationBadge = ({ width = 12, height = 12 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75 1.5C2.06 1.5 1.5 2.06 1.5 2.75V8.25C1.5 8.94 2.06 9.5 2.75 9.5H4V11H2.75C2.02065 11 1.32118 10.7103 0.805456 10.1945C0.289731 9.67882 0 8.97935 0 8.25V2.75C0 2.02065 0.289731 1.32118 0.805456 0.805456C1.32118 0.289731 2.02065 0 2.75 0H8.25C8.97935 0 9.67882 0.289731 10.1945 0.805456C10.7103 1.32118 11 2.02065 11 2.75V8.25C11 8.97935 10.7103 9.67882 10.1945 10.1945C9.67882 10.7103 8.97935 11 8.25 11H7.5V9.5H8.25C8.94 9.5 9.5 8.94 9.5 8.25V2.75C9.5 2.06 8.94 1.5 8.25 1.5H2.75ZM5 7.75C5 7.02065 5.28973 6.32118 5.80546 5.80546C6.32118 5.28973 7.02065 5 7.75 5H8.75V6.5H7.75C7.06 6.5 6.5 7.06 6.5 7.75V13.25C6.5 13.94 7.06 14.5 7.75 14.5H13.25C13.94 14.5 14.5 13.94 14.5 13.25V7.75C14.5 7.06 13.94 6.5 13.25 6.5H11.75V5H13.25C13.9793 5 14.6788 5.28973 15.1945 5.80546C15.7103 6.32118 16 7.02065 16 7.75V13.25C16 13.9793 15.7103 14.6788 15.1945 15.1945C14.6788 15.7103 13.9793 16 13.25 16H7.75C7.02065 16 6.32118 15.7103 5.80546 15.1945C5.28973 14.6788 5 13.9793 5 13.25V7.75Z"
      fill="currentColor"
    />
  </svg>
);

export default IntegrationBadge;
