import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_MEMBER_FRAGMENT } from '../fragments';

export const FETCH_ORGANIZATION_MEMBERS = `${namespace}/FETCH_ORGANIZATION_MEMBER`;

export const FETCH_ORGANIZATION_MEMBERS_QUERY = gql`
  query listOrganizationMembers {
    listOrganizationMembers {
      ...OrganizationMember
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`;

export const fetchOrganizationMembers = () => ({
  type: FETCH_ORGANIZATION_MEMBERS,
  payload: {
    key: 'listOrganizationMembers',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_ORGANIZATION_MEMBERS_QUERY,
    },
  },
});
