import { FeatureFlagsMap } from '../constants';

import { useFeatureFlag } from './useFeatureFlag';

export const useDisableProjectComplianceFeatureFlag = () => {
  const { DISABLE_PROJECT_COMPLIANCE } = FeatureFlagsMap;
  const isDisableProjectComplianceFeatureFlagEnabled = useFeatureFlag(
    DISABLE_PROJECT_COMPLIANCE,
  );
  return { isDisableProjectComplianceFeatureFlagEnabled };
};
