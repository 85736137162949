import {
  DownCircleOutlined,
  FontSizeOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import { CustomFieldType } from '../constants';

const IconByTypeMap = {
  [CustomFieldType.Dropdown]: <DownCircleOutlined />,
  [CustomFieldType.Text]: <FontSizeOutlined />,
  [CustomFieldType.Number]: <NumberOutlined />,
};

const CustomFieldTypeName = ({ type, title }) => (
  <div>
    <StyledIcon>{IconByTypeMap[type]}</StyledIcon>
    <span>{title}</span>
  </div>
);

const StyledIcon = styled.span`
  margin-right: 10px;
`;

export default CustomFieldTypeName;
