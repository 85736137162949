export const STATE_KEY = 'user';
export const namespace = 'USER';

export const partiesNotificationFormData = [
  {
    subject: 'New party added',
    subscription: 'by team member of organization',
    fieldName: 'partyNew',
  },
  {
    subject: 'Party compliance is expiring',
    subscription: '1 week prior due date',
    fieldName: 'partyComplianceExpiring',
  },
  {
    subject: 'Party compliance is expired',
    subscription: 'weekly since expired date',
    fieldName: 'partyComplianceExpired',
  },
  {
    subject: 'Daily parties report',
    subscription: 'compliance, active parties and additional data',
    fieldName: 'partyDailyReport',
  },
  {
    subject: 'Weekly parties report',
    subscription: 'compliance, active parties and additional data',
    fieldName: 'partyWeeklyReport',
  },
];

export const organizationNotificationFormData = [
  {
    subject: 'New team member added',
    subscription: 'by team member of organization',
    fieldName: 'organizationTeamMemberAdded',
  },
];

export const requestNotificationFormData = [
  {
    subject: 'New request sent',
    subscription: 'by team member of organization',
    fieldName: 'requestNew',
  },
  {
    subject: 'Documents uploaded to request',
    subscription: 'instant notification',
    fieldName: 'requestDocumentUploaded',
  },
  {
    subject: 'Upcoming request due date',
    subscription: '1 week prior to due date',
    fieldName: 'requestUpcomingDueDate',
  },
  {
    subject: 'Request fully compliant',
    subscription: 'instant notification',
    fieldName: 'requestFullyCompliant',
  },
  {
    subject: 'Request weekly report',
    subscription: 'compliance, active parties and additional data',
    fieldName: 'requestWeeklyReport',
  },
];

export const communicationNotificationFormData = [
  {
    subject: 'New message from party',
    subscription: 'instant notification',
    fieldName: 'communicationMessageFromParty',
  },
];
