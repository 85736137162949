import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import throttle from 'lodash/throttle';
import { call } from 'ramda';
import { END, eventChannel } from 'redux-saga';
import { all, cancelled, put, take, takeEvery } from 'redux-saga/effects';

import {
  DOWNLOAD_CANCEL,
  DOWNLOAD_INIT,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_UPDATE,
} from '../actions';

function downloadHelper(payload) {
  const { files, zipName } = payload;

  return eventChannel((emitter) => {
    const throttleEmit = throttle((progress) => {
      //It ensures the progress is completed only after the file is generated
      if (progress < files.length) {
        emitter({
          type: DOWNLOAD_UPDATE,
          payload: {
            ...payload,
            percentage: Math.floor((progress / files.length) * 100),
          },
        });
      }
    }, 300);

    const abortController = new AbortController();
    //eslint-disable-next-line
    let progress = 0;

    const requests = files.map((f) =>
      fetch(f.url, {
        signal: abortController.signal,
      }).then((response) => {
        progress += 1;

        throttleEmit(progress);

        if (response.status !== 200) {
          return null;
        }

        return response.blob();
      }),
    );

    Promise.all(requests)
      .then((blobs) => {
        if (blobs.length > 1) {
          const zip = new JSZip();

          blobs.forEach((b, i) => {
            if (b !== null) {
              zip.file(files[i].name, b);
            }
          });

          return zip;
        }

        return blobs[0];
      })
      .then((content) => {
        if (content !== null) {
          if (content instanceof Blob) {
            return content;
          }
          return content.generateAsync({ type: 'blob' });
        }

        return null;
      })
      .then((content) => {
        if (content !== null) {
          saveAs(
            content,
            zipName ? `${zipName}.zip` : `${files[0].name}.${files[0].format}`,
          );
        }
      })
      .then(() => {
        emitter({
          type: DOWNLOAD_UPDATE,
          payload: {
            ...payload,
            percentage: 100,
          },
        });

        setTimeout(() => {
          emitter({
            type: DOWNLOAD_SUCCESS,
            payload: payload.id,
          });
          emitter(END);
        }, 300);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});

    return () => {
      abortController.abort();
    };
  });
}

function* downloadFiles(action) {
  const { payload } = action;
  const channel = yield call(downloadHelper, payload);

  yield takeEvery(DOWNLOAD_CANCEL, (cancelAction) => {
    if (cancelAction.payload === action.payload.id) {
      channel.close();
    }
  });

  try {
    //eslint-disable-next-line
    while (true) {
      const action = yield take(channel);

      yield put(action);
    }
  } finally {
    yield cancelled();
  }
}

export default function* downloadSaga() {
  yield all([takeEvery(DOWNLOAD_INIT, downloadFiles)]);
}
