import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PROJECTS_BY_REQUEST = `${namespace}/FETCH_PROJECTS_BY_REQUEST`;

// TODO: remove complianceStatus when removing feature flag SEPARATE_EXPIRING_REQUEST_LANDING
const FETCH_PROJECTS_BY_REQUEST_QUERY_LEGACY = gql`
  query getProjectsByRequest($requestId: String!) {
    getProjectsByRequest(requestId: $requestId) {
      _id
      name
      complianceStatus {
        status
      }
    }
  }
`;

const FETCH_PROJECTS_BY_REQUEST_QUERY = gql`
  query getProjectsByRequest($requestId: String!) {
    getProjectsByRequest(requestId: $requestId) {
      _id
      name
      status
      expiring
    }
  }
`;

// TODO: remove complianceStatus when removing feature flag SEPARATE_EXPIRING_REQUEST_LANDING
export const fetchProjectsByRequestLegacy = (requestId) => ({
  type: FETCH_PROJECTS_BY_REQUEST,
  payload: {
    key: 'getProjectsByRequest',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PROJECTS_BY_REQUEST_QUERY_LEGACY,
      variables: { requestId },
    },
  },
});

export const fetchProjectsByRequest = (requestId) => ({
  type: FETCH_PROJECTS_BY_REQUEST,
  payload: {
    key: 'getProjectsByRequest',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PROJECTS_BY_REQUEST_QUERY,
      variables: { requestId },
    },
  },
});
