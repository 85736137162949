import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTY_TO_EDIT = `${namespace}/FETCH_PARTY_TO_EDIT`;

const FETCH_PARTY_TO_EDIT_QUERY = gql`
  query getPartyData($payload: String!, $byContext: Boolean) {
    getPartyData(id: $payload, byContext: $byContext) {
      _id
      name
      externalIds
      type {
        _id
        name
      }
      isActive
      address {
        rawAddress
        line1
        line2
        city
        region
        country
        postalCode
        latitude
        longitude
      }
      additionalNotes
      partyContacts {
        _id
        contactPersonName
        companyName
        email
        phone
        isPrimary
        isDefaultRequestRecipient
      }
      organization
      lastRequestSentOn
    }
  }
`;

export const fetchPartyToEdit = (payload) => ({
  type: FETCH_PARTY_TO_EDIT,
  payload: {
    key: 'getPartyData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_TO_EDIT_QUERY,
      variables: { payload, byContext: false },
    },
  },
});
