import { DEFAULT_CONTEXT } from './constants';

type Request = {
  name: string;
  profile: string;
  related?: string;
};

export const mapClientRequestToMutationRequest = (
  requests: Request[],
  primaryRecordId: string,
) => {
  return requests.map((request) => ({
    primaryRecordId,
    name: request.name,
    ...(request.profile && { complianceProfileId: request.profile }),
    ...(request.related === DEFAULT_CONTEXT._id
      ? {}
      : { contextRecordId: request.related }),
  }));
};
