import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { NetworkStatus } from '@apollo/client';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { ProfileValue } from '../ComplianceProfilesSelect';

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DEFAULT_PAGE_SIZE,
  sort: {
    name: 1,
  },
};

export const COMPLIANCE_PROFILES_QUERY = graphql(`
  query ComplianceProfilesSelect($input: ComplianceProfilesInput) {
    complianceProfiles(input: $input) {
      nodes {
        _id
        name
        baseComplianceProfile
      }
      totalCount
    }
  }
`);

export const COMPLIANCE_PROFILES_SELECT_DEFAULT_COMPLIANCE_PROFILE_QUERY =
  graphql(`
  query ComplianceProfilesSelect_DefaultComplianceProfileQuery($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      _id
      name
      baseComplianceProfile
    }
  }
`);

export const COMPLIANCE_PROFILES_SELECT_SELECTED_COMPLIANCE_PROFILE_QUERY =
  graphql(`
  query ComplianceProfilesSelect_SelectedComplianceProfileQuery($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      _id
      name
      baseComplianceProfile
    }
  }
`);

export const useComplianceProfiles = ({
  defaultValue,
  value,
}: {
  defaultValue?: string | ProfileValue;
  value?: string | ProfileValue;
}) => {
  const [searchedValue, setSearchedValue] = useState<string | undefined>();

  const getFilters = useCallback(() => {
    let filters = [];

    if (searchedValue) {
      filters.push({
        name: 'name',
        operator: FILTER_OPERATORS.contains,
        value: searchedValue,
      });
    }

    return { and: filters };
  }, [searchedValue]);

  const [fetchComplianceProfiles, { data, loading, fetchMore, networkStatus }] =
    useLazyQuery(COMPLIANCE_PROFILES_QUERY, {
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: 0,
        },
      },
      notifyOnNetworkStatusChange: true,
    });

  const [
    fetchDefaultComplianceProfile,
    {
      data: defaultComplianceProfile,
      loading: defaultComplianceProfileLoading,
    },
  ] = useLazyQuery(COMPLIANCE_PROFILES_SELECT_DEFAULT_COMPLIANCE_PROFILE_QUERY);

  const [
    fetchSelectedComplianceProfile,
    {
      data: selectedComplianceProfile,
      loading: selectedComplianceProfileLoading,
    },
  ] = useLazyQuery(
    COMPLIANCE_PROFILES_SELECT_SELECTED_COMPLIANCE_PROFILE_QUERY,
  );

  const fetchMoreComplianceProfiles = () => {
    fetchMore({
      variables: {
        input: {
          ...DEFAULT_INPUT,
          filter: getFilters(),
          offset: data?.complianceProfiles.nodes?.length,
        },
      },
    });
  };

  const debouncedFetchComplianceProfiles = useCallback(
    debounce((filters) => {
      fetchComplianceProfiles({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: 0,
            filter: filters,
          },
        },
      });
    }, 300),
    [],
  );

  const defaultValueId = typeof defaultValue === 'string' ? defaultValue : null;
  const valueId = typeof value === 'string' ? value : null;

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isLoading = !isLoadingMore && loading;
  const defaultComplianceProfileData =
    typeof defaultValue === 'string'
      ? defaultComplianceProfile?.getComplianceProfile
      : defaultValue;
  const selectedComplianceProfileData =
    typeof value === 'string'
      ? selectedComplianceProfile?.getComplianceProfile
      : value;

  useEffect(() => {
    debouncedFetchComplianceProfiles(getFilters());
  }, [debouncedFetchComplianceProfiles, getFilters]);

  useEffect(() => {
    // fetch only the first time defaultComplianceProfileId is valued and never again
    if (!defaultComplianceProfile && defaultValueId)
      fetchDefaultComplianceProfile({
        variables: { id: defaultValueId },
      });
  }, [fetchDefaultComplianceProfile, defaultComplianceProfile, defaultValueId]);

  useEffect(() => {
    if (valueId) {
      fetchSelectedComplianceProfile({
        variables: { id: valueId },
      });
    }
  }, [fetchSelectedComplianceProfile, valueId]);

  return {
    complianceProfiles: data?.complianceProfiles?.nodes,
    complianceProfilesTotalCount: data?.complianceProfiles?.totalCount ?? 0,
    selectedComplianceProfile: selectedComplianceProfileData,
    defaultComplianceProfile: defaultComplianceProfileData,
    loading: isLoading,
    loadingMore: isLoadingMore,
    loadingDefaultComplianceProfile: defaultComplianceProfileLoading,
    loadingSelectedComplianceProfile: selectedComplianceProfileLoading,
    fetchMoreComplianceProfiles,
    setSearchedValue,
    searchedValue,
  };
};
