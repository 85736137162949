import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const CONFIGURE_STATUS = `${namespace}/CONFIGURE_STATUS`;

const CONFIGURE_STATUS_MUTATION = gql`
  mutation integrationConfigure($data: IntegrationConfigureInput!) {
    integrationConfigure(data: $data) {
      jobId
    }
  }
`;

export const configureStatus = (data) => ({
  type: CONFIGURE_STATUS,
  payload: {
    key: 'integrationConfigure',
    graphql: {
      mutation: CONFIGURE_STATUS_MUTATION,
      variables: {
        data,
      },
    },
  },
});
