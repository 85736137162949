import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useMemo, useState } from 'react';

const PRIMARY_RECORD_REQUEST_RECORD_STATS = graphql(`
    query PrimaryRecordRequestRecordsStats($input: PrimaryRecordInput!, $recordsInput: RequestRecordsInput) {
        primaryRecord(input: $input) {
            _id
            requestRecords(input: $recordsInput) {
                nodes {
                    _id
                    complianceStats {
                        compliantSubjects
                        totalSubjects
                    }
                    name
                }
                totalCount
            }
        }
    }
`);

export function usePrimaryRecordRequestRecordsStats(id: string) {
  const [nonCompliantOnly, setNonCompliantOnly] = useState(false);
  const [page, setPage] = useState(0);
  const { data, loading, error } = useQuery(
    PRIMARY_RECORD_REQUEST_RECORD_STATS,
    {
      variables: {
        input: {
          id,
        },
        recordsInput: {
          offset: page * 3,
          first: 3,
          filter: nonCompliantOnly
            ? {
                and: [
                  {
                    name: 'complianceStatus',
                    operator: 'in',
                    value: ['nonCompliant'],
                  },
                ],
              }
            : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const onToggleNonCompliantOnly = useMemo(
    () => (checked: boolean) => {
      setNonCompliantOnly(checked);
      setPage(0);
    },
    [],
  );

  return {
    data,
    loading,
    error,
    nonCompliantOnly,
    onToggleNonCompliantOnly,
    page,
    onPageChange: setPage,
  };
}
