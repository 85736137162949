import { message as notify } from 'antd';
import * as R from 'ramda';
import React from 'react';
import { all, call, putResolve, takeLatest } from 'redux-saga/effects';

import { trackEvent } from '@common/utils/track-helpers';
import { SET_AUTHENTICATED_APP_READY } from '../../app/actions';
import {
  DELETE_ORGANIZATION_MEMBER,
  INVITE_ORGANIZATION_MEMBER,
  deleteMember,
  fetchOrganizationMembers,
  inviteMember,
} from '../actions';

function* inviteMemberSaga({ payload: { member, organizationName } }) {
  const { email } = member;
  const res = yield putResolve(inviteMember(member));
  const invitedMember = R.pathOr(
    {},
    ['payload', 'data', 'inviteOrganizationMember'],
    res,
  );

  yield call(trackEvent, 'User added a team member');

  if (R.isEmpty(invitedMember)) {
    yield notify.error({
      content: React.createElement(
        'span',
        {
          'data-cy': 'notificationErrorOnInvite',
        },
        `An error occurred while inviting ${email}. Please try again later.`,
      ),
    });
  } else {
    yield notify.success({
      content: React.createElement(
        'span',
        {
          'data-cy': 'notificationSuccessOnInvite',
        },
        `${email} successfully invited to ${organizationName}`,
      ),
    });
  }
}

function* deleteMemberSaga({ payload: { id, organizationName } }) {
  const res = yield putResolve(deleteMember(id));

  const deletedMember = R.pathOr(
    {},
    ['payload', 'data', 'deleteOrganizationMember', 'user'],
    res,
  );

  if (R.isEmpty(deletedMember)) {
    yield notify.error(
      `An error occurred while removing ${deletedMember.email}. Please try again later.`,
    );
  } else {
    yield notify.success(
      `${deletedMember.email} successfully removed from ${organizationName}`,
    );
  }
}

function* fetchOrganizationMemberByUserSaga() {
  yield putResolve(fetchOrganizationMembers());
}

function* organizationMemberSagas() {
  yield all([
    takeLatest(INVITE_ORGANIZATION_MEMBER, inviteMemberSaga),
    takeLatest(DELETE_ORGANIZATION_MEMBER, deleteMemberSaga),
    takeLatest(SET_AUTHENTICATED_APP_READY, fetchOrganizationMemberByUserSaga),
  ]);
}

export default organizationMemberSagas;
