import { PlayCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const ActionIcon = ({
  IconComponent,
}: {
  IconComponent?: React.ComponentType<any>;
}) => {
  return (
    <StyledIconWrapper>
      {IconComponent ? (
        <IconComponent style={{ color: '#2F54EB' }} />
      ) : (
        <PlayCircleOutlined style={{ color: '#2F54EB' }} />
      )}
    </StyledIconWrapper>
  );
};

const StyledIconWrapper = styled.span`
  padding: 5px 7px;
  border-radius: 8px;
  background-color: #f0f5ff;
`;
