import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const REMOVE_CARRIER_FROM_DOCUMENT = `${namespace}/REMOVE_CARRIER_FROM_DOCUMENT`;

const REMOVE_CARRIER_FROM_DOCUMENT_MUTATION = gql`
  mutation removeCarrierFromDocument($payload: RemoveCarrierInput!) {
    removeCarrierFromDocument(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const removeCarrierFromDocument = (payload) => ({
  type: REMOVE_CARRIER_FROM_DOCUMENT,
  payload: {
    key: 'removeCarrierFromDocument',
    graphql: {
      mutation: REMOVE_CARRIER_FROM_DOCUMENT_MUTATION,
      variables: { payload },
    },
  },
});
