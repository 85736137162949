import type { ViewsSelectorQuery } from '@graphql/types/graphql';

type ViewsType = ViewsSelectorQuery['listReports'];
/**
 *
 * @param views - array of views
 * @returns [publicViews: ViewsType, privateViews: ViewsType
 */
export function groupViewsByPrivacy(
  views?: ViewsType | null | undefined,
): [ViewsType, ViewsType] {
  if (!views) {
    return [[], []];
  }

  return views.reduce<[ViewsType, ViewsType]>(
    (acc, view) => {
      acc[view.isPrivate ? 1 : 0].push(view);
      return acc;
    },
    [[], []],
  );
}

export const getViewById = (activeViewId: string, views?: ViewsType) =>
  views?.find((view) => view._id === activeViewId);
