import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPDATE_SUBJECT_STATUS = `${namespace}/UPDATE_SUBJECT_STATUS`;

const UPDATE_SUBJECT_STATUS_MUTATION = gql`
  mutation updateSubjectStatus($payload: UpdateSubjectStatusInput!) {
    updateSubjectStatus(data: $payload) {
      _id
      name
      status
      isTrackingCompliance
      hasWaivedRequirements
      hasOverriddenRequirements
      partyComplianceProfile {
        _id
        isProcessing
        party
        metadata
        complianceProfile
        name
        nextExpirationDate
        latestExpirationDate
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
        createdAt
        updatedAt
      }
      subjects {
        subjectId
        subjectLabel
        notes
        requirementComplianceStatusValue
        resetRequirementOn
      }
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        createdAt
        updatedAt
      }
      documentChecklists {
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;

type updateSubjectStatusPayload = {
  partyId: string;
  subjectId: string;
  status: 'waived' | 'overridden' | 'non_compliant';
  notes?: string;
  resetRequirementOn?: string;
  projectId?: string;
};

export const updateSubjectStatus = (payload: updateSubjectStatusPayload) => ({
  type: UPDATE_SUBJECT_STATUS,
  payload: {
    graphql: {
      mutation: UPDATE_SUBJECT_STATUS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
