import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const REVIEW_DOCUMENT = `${namespace}/REVIEW_DOCUMENT`;

const REVIEW_DOCUMENT_MUTATION = gql`
  mutation reviewDocument($payload: ReviewDocumentInput!) {
    reviewDocument(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const reviewDocument = (payload) => ({
  type: REVIEW_DOCUMENT,
  payload: {
    key: 'reviewDocument',
    graphql: {
      mutation: REVIEW_DOCUMENT_MUTATION,
      variables: { payload },
    },
  },
});
