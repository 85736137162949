import type { Nullable } from '@common/types';

export function checkAppOutdated(
  currentVersion: string,
  latestVersion: Nullable<string>,
): boolean {
  if (!latestVersion || !currentVersion) {
    return false;
  }

  return Boolean(currentVersion !== latestVersion);
}
