import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const LOGOUT = `${namespace}/LOGOUT`;

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export const logout = ({ redirectTo } = {}) => ({
  type: LOGOUT,
  payload: {
    redirectTo,
    key: 'logout',
    graphql: {
      mutation: LOGOUT_MUTATION,
    },
  },
});
