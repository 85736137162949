import { namespace } from '../constants';

export const SET_IS_ASIDE_NAVIGATION_COLLAPSED = `${namespace}/SET_IS_ASIDE_NAVIGATION_COLLAPSED`;

export const setIsAsideNavigationCollapsed = (status: boolean) => {
  return {
    type: SET_IS_ASIDE_NAVIGATION_COLLAPSED,
    payload: status,
  };
};
