export const namespace = 'FILLABLE_FORM';
export const STATE_KEY = 'fillable-form';

export const FIELD_TYPE = {
  TEXT: 'text',
  CHECKBOX: 'checkbox',
  SIGNATURE: 'signature',
  EFFECTIVE_DATE: 'effectiveDate',
  EXPIRATION_DATE: 'expirationDate',
} as const;

export type FieldType = (typeof FIELD_TYPE)[keyof typeof FIELD_TYPE];

export const FIELD_ASSOCIATION_TYPES = {
  PARTY: 'party',
  REQUESTER: 'requester',
};

export const FILLABLE_FORMS_MODULE_ID = 'fillableForms';

export const FIELD_NAME_PREFIXES = {
  SIGNATURE: 'SignatureField-',
  TEXT: 'TextField-',
  CHECKBOX: 'CheckboxField-',
};

// same type declared on api as FillableFormStatus
export const FILLABLE_FORM_STATUS = {
  SIGNING: '-2',
  SIGNED: '-1',
  COMPLETED: '0',
  ONE_TO_GO: '1',
  TWO_TO_GO: '2',
};

export const REQUIRED_INFO_TEXT_MAP: Record<string, string> = {
  [FIELD_TYPE.CHECKBOX]: 'Checkbox fields cannot be marked as required',
  [FIELD_TYPE.SIGNATURE]: 'Signature fields are always required',
};

export const DISABLED_REQUIRED_FIELD_TYPES = [
  FIELD_TYPE.SIGNATURE,
  FIELD_TYPE.CHECKBOX,
];
