import { DATA_MODEL_REFERENCES } from '@common/constants';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const SET_PRIMARY_CONTACT_MUTATION = graphql(`
  mutation SetPrimaryContact($data: UpdateContactsInput!) {
    updateContacts(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useSetPrimaryContactMutation = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [setPrimaryContact] = useMutation(SET_PRIMARY_CONTACT_MUTATION);

  const setPrimaryContactMutation = useCallback(
    (contactId: string) =>
      setPrimaryContact({
        variables: {
          data: {
            contacts: [
              {
                _id: contactId,
                records: [
                  {
                    id: primaryRecordId,
                    reference: DATA_MODEL_REFERENCES.primaryRecord,
                    primary: true,
                    defaultRequestRecipient: false,
                  },
                ],
              },
            ],
          },
        },
      }),
    [setPrimaryContact, primaryRecordId],
  );

  return {
    setPrimaryContactMutation,
  };
};
