import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const ADD_BULK_DOCUMENT_TYPES = `${namespace}/ADD_BULK_DOCUMENT_TYPES`;

export const ADD_BULK_DOCUMENT_TYPES_MUTATION = gql`
  mutation addBulkDocumentTypes($data: bulkDocumentTypesInput!) {
    addBulkDocumentTypes(data: $data) {
      _id
      types {
        _id
        name
        pluralName
        isSystemType
      }
    }
  }
`;

export const addBulkDocumentTypes = (data) => ({
  type: ADD_BULK_DOCUMENT_TYPES,
  payload: {
    key: 'addBulkDocumentTypes',
    graphql: {
      mutation: ADD_BULK_DOCUMENT_TYPES_MUTATION,
      variables: { data },
    },
  },
});
