import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const REVIEW_DOCUMENTS = `${namespace}/REVIEW_DOCUMENTS`;

const REVIEW_DOCUMENTS_MUTATION = gql`
  mutation reviewDocuments($payload: ReviewDocumentsInput!) {
    reviewDocuments(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const reviewDocuments = (payload) => ({
  type: REVIEW_DOCUMENTS,
  payload: {
    key: 'reviewDocuments',
    graphql: {
      mutation: REVIEW_DOCUMENTS_MUTATION,
      variables: { payload },
    },
  },
});
