import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ASSIGN_COMPLIANCE_PROFILE_MUTATION = graphql(`
  mutation AssignComplianceProfileToRequestRecord($data: AssignComplianceProfileNewInput!) {
    assignComplianceProfileTo(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useAssignComplianceProfileMutation = () => {
  const [assignComplianceProfile, { loading }] = useMutation(
    ASSIGN_COMPLIANCE_PROFILE_MUTATION,
  );

  return {
    assignComplianceProfile,
    isAssignComplianceProfileLoading: loading,
  };
};
