import { CloudDownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { LoadingSpinner } from '@common/components/Spinner/LoadingSpinner';
import type { Document } from '@graphql/types/graphql';

import { useDownloadDocument } from '../../hooks/useDownloadDocument';

export const DownloadDocumentButton = ({
  document,
}: {
  document: Document;
}) => {
  const { downloadDocument, isDownloadingDocument } = useDownloadDocument();

  return (
    <>
      {isDownloadingDocument ? (
        <LoadingSpinner iconStyle={{ fontSize: 18 }} />
      ) : (
        <StyledDownloadIcon onClick={() => downloadDocument(document)} />
      )}
    </>
  );
};

const StyledDownloadIcon = styled(CloudDownloadOutlined)`
  font-size: 18px;
`;
