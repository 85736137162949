import type { ApolloError } from '@apollo/client';
import { useSelector } from 'react-redux';

import { useSubscription } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { getActiveOrganizationId } from '@modules/organization/selectors';

const BULK_DOCUMENTS_ACTIONS_PROCESSED_SUBSCRIPTION = graphql(`
  subscription DocumentsUpdates($organizationId: String!) {
    bulkDocumentsProcessed(organizationId: $organizationId)
  }
`);

type UseBulkActionsProps = {
  onSubscriptionUpdate: (params: { eventType: string }) => void;
  onSubscriptionError: (error: ApolloError) => void;
};

export const useBulkActions = ({
  onSubscriptionUpdate,
  onSubscriptionError,
}: UseBulkActionsProps) => {
  const organizationId = useSelector(getActiveOrganizationId);

  const { data } = useSubscription(
    BULK_DOCUMENTS_ACTIONS_PROCESSED_SUBSCRIPTION,
    {
      variables: {
        organizationId,
      },
      onData: ({ data }) => {
        onSubscriptionUpdate({
          eventType: data?.data?.bulkDocumentsProcessed.type,
        });
      },
      onError: onSubscriptionError,
    },
  );

  return {
    bulkActionType: data?.bulkDocumentsProcessed.type,
  };
};
