import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_VIEWED_PROJECTS = `${namespace}/FETCH_VIEWED_PROJECTS`;

type ViewedProjectsFilterInput = {
  partyId?: string;
  isActive?: boolean;
  name?: string;
};

type ViewedProjectsInput = {
  first?: number;
  offset?: number;
  filter?: ViewedProjectsFilterInput;
};

const FETCH_VIEWED_PROJECTS_QUERY = gql`
  query viewedProjects($input: ViewedProjectsInput!) {
    viewedProjects(input: $input) {
      nodes {
        _id
        name
        isActive
      }
      totalCount
    }
  }
`;

export const getViewedProjects = (input: ViewedProjectsInput) => ({
  type: FETCH_VIEWED_PROJECTS,
  payload: {
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_VIEWED_PROJECTS_QUERY,
      variables: { input },
    },
  },
});
