import { DATA_MODEL_REFERENCES } from '@common/constants';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';
import type { Contact as FormContact } from '../containers/ContactForm/ContactForm';

const CREATE_CONTACT_MUTATION = graphql(`
  mutation CreateContact($data: CreateContactInput!) {
    createContact(data: $data)
  }
`);

export const useCreatePrimaryRecordContact = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [createContactMutation, { loading }] = useMutation(
    CREATE_CONTACT_MUTATION,
  );

  const createPrimaryRecordContact = useCallback(
    ({
      contact,
      isPrimary,
      isDefaultRequestRecipient,
    }: {
      contact: FormContact;
      isPrimary: boolean;
      isDefaultRequestRecipient: boolean;
    }) =>
      createContactMutation({
        variables: {
          data: {
            ...contact,
            records: [
              {
                id: primaryRecordId,
                reference: DATA_MODEL_REFERENCES.primaryRecord,
                primary: Boolean(isPrimary),
                defaultRequestRecipient: Boolean(isDefaultRequestRecipient),
              },
            ],
          },
        },
      }),
    [createContactMutation, primaryRecordId],
  );

  return {
    loading,
    createPrimaryRecordContact,
  };
};
