import { ApolloLink, Observable } from '@apollo/client/core';

import { flattenPdfFile } from '@modules/document/utils/document-helpers';

// Recursively flatten all PDF files in an object
export const flattenFilesInObj = (obj) =>
  Promise.all(
    Object.keys(obj || {}).map(async (key) => {
      if (obj[key] instanceof File) {
        obj[key] = await flattenPdfFile(obj[key]);
      } else if (Array.isArray(obj[key])) {
        obj[key] = await Promise.all(obj[key].map(flattenPdfFile));
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        await flattenFilesInObj(obj[key]);
      }
    }),
  );

/**
 * This link is used to flatten PDF files in the variables of a mutation.
 * It is needed so that PDFs with annotations coming from other systems
 * are not preserved with the risk to interfere with PSPDFKit annotations
 */
const flattenPdfLink = new ApolloLink(
  (operation, forward) =>
    new Observable(async (observer) => {
      const { variables } = operation;

      await flattenFilesInObj(variables);

      try {
        const subscription = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });

        return () => {
          if (subscription) {
            subscription.unsubscribe();
          }
        };
      } catch (error) {
        observer.error(error);
      }
    }),
);

export default flattenPdfLink;
