import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PROJECTS = `${namespace}/FETCH_PROJECTS`;

const FETCH_PROJECTS_QUERY = gql`
  query listProjects($filters: JSON, $sort: ElementsSort) {
    listProjects(filters: $filters, sort: $sort) {
      data {
        _id
        name
        description
        isActive
        startDate
        endDate
        customFields {
          totalCount
        }
        complianceStatus {
          status
          compliantPartiesCount
          totalPartiesCount
        }
        externalIds
      }
      totalCount
    }
  }
`;

export const fetchProjects = (payload, query = FETCH_PROJECTS_QUERY) => ({
  type: FETCH_PROJECTS,
  payload: {
    key: 'listProjects',
    graphql: {
      fetchPolicy: 'no-cache',
      query,
      variables: payload,
    },
  },
});
