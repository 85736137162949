import { PlusOutlined } from '@ant-design/icons';
import {
  BulkOperationStatus,
  type PrimaryRecord,
  type ReportEntity as ViewEntity,
  ReportType as ViewType,
} from '@graphql/types/graphql';
import { Button, Skeleton } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ActionsMenuWithButtons } from '@common/components/ActionsMenuWithButtons';
import type { SelectedFilter } from '@common/components/DateFilterSelector';
import { PageTitle } from '@common/components/PageTitleWrapper';
import RefreshDataBanner from '@common/components/RefreshDataBanner';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import {
  type CustomSelectionChangedEvent,
  FilterButton,
  type IdFilter,
  type NumberFilter,
  type SetFilter,
  SortButton,
  type SortDirection,
  type SortingItems,
  type TextFilter,
} from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';
import { mapTableFiltersToServerFilters } from '@common/utils/filters';
import { getIdsFilter } from '@common/utils/filters/getIdsSelectionFilter';
import { getAntdIconOutlinedByName } from '@common/utils/get-antd-icon-by-name';
import { useOperationsUpdates } from '@modules/organization/hooks/useOperationsUpdates';
import {
  type ActionType,
  CreatePrimaryRecordModal,
  type CreatePrimaryRecordModalState,
  PRIMARY_RECORDS_ACTION_TYPES,
  getActionsMenuItems,
} from '@modules/primary-records';
import { PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES } from '@modules/primary-records/constants';
import { ArchivePrimaryRecordModal } from '@modules/primary-records/containers/ArchivePrimaryRecordModal';
import { ArchivePrimaryRecordsModal } from '@modules/primary-records/containers/ArchivePrimaryRecordsModal';
import { DeletePrimaryRecordModal } from '@modules/primary-records/containers/DeletePrimaryRecordModal';
import { DeletePrimaryRecordsModal } from '@modules/primary-records/containers/DeletePrimaryRecordsModal';
import { EnableDisableAutomationModal } from '@modules/primary-records/containers/EnableDisableAutomationModal';
import { EnableDisableAutomationsModal } from '@modules/primary-records/containers/EnableDisableAutomationsModal';
import { MakeActivePrimaryRecordModal } from '@modules/primary-records/containers/MakeActivePrimaryRecordModal';
import { MakeActivePrimaryRecordsModal } from '@modules/primary-records/containers/MakeActivePrimaryRecordsModal';
import type { PrimaryRecordsTableRef } from '@modules/primary-records/containers/PrimaryRecordsTable';
import { PrimaryRecordsTable } from '@modules/primary-records/containers/PrimaryRecordsTable';
import {
  FILTERABLE_COLUMN_FIELDS,
  PRIMARY_RECORD_STATUS,
  SERVER_FILTERS_CONFIG,
} from '@modules/primary-records/containers/PrimaryRecordsTable/constants';
import type { LegacyPartiesReportParams } from '@modules/report/types';
import { DEFAULT_VIEWS, ViewsSelector } from '@modules/view';
import styled from 'styled-components';
import { usePrimaryObjectQuery } from './hooks/usePrimaryObjectQuery';
import { mapLegacyPartiesReportParamsToTableParams } from './utils/mapLegacyPartiesReportParamsToTableParams';
import { mapTableParamsToLegacyPartiesReportParams } from './utils/mapTableParamsToLegacyPartiesReportParams';

export type PrimaryObjectRecordsTableState = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
  pagination: {
    currentPage?: number;
    pageSize?: number;
  };
  viewId?: string;
};

export type TableFiltersModel = {
  [FILTERABLE_COLUMN_FIELDS.name]?: TextFilter;
  [FILTERABLE_COLUMN_FIELDS.tags]?: IdFilter;
  [FILTERABLE_COLUMN_FIELDS.activeDocuments]?: NumberFilter;
  [FILTERABLE_COLUMN_FIELDS.reviewStatus]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.flagsSeverity]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.status]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.types]?: IdFilter;
  [FILTERABLE_COLUMN_FIELDS.contextRecords]?: IdFilter;
  [FILTERABLE_COLUMN_FIELDS.waiversStatus]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.overridesStatus]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.nextComplianceExpiration]?: SelectedFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.lastActivity]?: SelectedFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.messageFailures]?: SelectedFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.contactEmailStatus]?: SetFilter;
};

const filterInitialState = {
  status: {
    filterType: 'set' as const,
    values: [PRIMARY_RECORD_STATUS.Active],
  },
};

const sortingInitialState = {
  columnId: 'createdAt',
  direction: 'desc' as const,
};

const paginationInitialState = {
  pageSize: 12,
  currentPage: 0,
};

const DEFAULT_TABLE_STATE = {
  filters: filterInitialState,
  sorting: sortingInitialState,
  pagination: paginationInitialState,
  viewId: undefined,
};

const initState = (
  initialState?: PrimaryObjectRecordsTableState,
): PrimaryObjectRecordsTableState => {
  if (!initialState) {
    return DEFAULT_TABLE_STATE;
  }

  if (isEmpty(initialState.filters)) {
    return DEFAULT_TABLE_STATE;
  }

  return {
    ...initialState,
    filters: initialState.filters,
  };
};

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedPrimaryRecordsIds: string[];
  selectedPrimaryRecordId?: string;
  selectedRowsData: any[];
};

const createRecordModalInitialState = {
  visible: false,
  primaryObjectId: '',
  primaryObjectName: '',
};

export function PrimaryObject() {
  const { objectSlug } = useParams<{ objectSlug: string }>();
  const tableRef = useRef<PrimaryRecordsTableRef>(null);

  const [tableState, setTableState] =
    useSetState<PrimaryObjectRecordsTableState>(initState());

  const [visibleColumns, setVisibleColumns] = useState<SortingItems>([]);
  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);

  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedPrimaryRecordsIds: [],
    selectedPrimaryRecordId: undefined,
    selectedRowsData: [],
  });

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
    activeFilters: 0,
  });

  const [createPrimaryRecordModalState, setCreatePrimaryRecordModalState] =
    useSetState<CreatePrimaryRecordModalState>(createRecordModalInitialState);

  const [isBulkArchiveModalVisible, setIsBulkArchiveModalVisible] =
    useState(false);

  const [isBulkDeleteModalVisible, setIsBulkDeleteModalVisible] =
    useState(false);

  const [isBulkMakeActiveModalVisible, setIsBulkMakeActiveModalVisible] =
    useState(false);

  const [singleArchiveModalState, setSingleArchiveModalState] = useSetState<{
    visible: boolean;
    primaryRecordId: string;
  }>({
    visible: false,
    primaryRecordId: '',
  });

  const [singleMakeActiveModalState, setSingleMakeActiveModalState] =
    useSetState<{
      visible: boolean;
      primaryRecordId: string;
    }>({
      visible: false,
      primaryRecordId: '',
    });

  const [singleDeleteModalState, setSingleDeleteModalState] = useSetState<{
    visible: boolean;
    primaryRecordId: string;
    primaryRecordName: string;
  }>({
    visible: false,
    primaryRecordId: '',
    primaryRecordName: '',
  });

  const [
    singleEnableDisableAutomationModalState,
    setSingleEnableDisableAutomationModalState,
  ] = useSetState<{
    type?: 'enable' | 'disable';
    visible: boolean;
    primaryRecordId?: string;
  }>({
    visible: false,
  });

  const [
    bulkEnableDisableAutomationModalState,
    setBulkEnableDisableAutomationModalState,
  ] = useSetState<{
    visible: boolean;
    type: 'enable' | 'disable';
  }>({
    visible: false,
    type: 'enable',
  });

  const [shouldRefreshData, setShouldRefreshData] = useState(false);

  useOperationsUpdates({
    operationNames: Object.values(PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES),
    operationStatuses: [BulkOperationStatus.Completed],
    onUpdate: () => {
      setShouldRefreshData(true);
    },
  });

  // QUERIES AND MUTATIONS
  const { primaryObjectData, isPrimaryObjectLoading } =
    usePrimaryObjectQuery(objectSlug);

  const bulkTotalDocumentsArchived: number = selection.selectedRowsData.reduce(
    (sum, record) => {
      return sum + (record.stats.documents.totalCount || 0);
    },
    0,
  );

  const viewParams = useMemo(() => {
    return mapTableParamsToLegacyPartiesReportParams({
      filters: tableState.filters,
      sorting: tableState.sorting,
    });
  }, [tableState.filters, tableState.sorting]);

  const refreshData = () => {
    setShouldRefreshData(false);
    tableRef?.current?.refreshTable();
  };

  const handleCreatePrimaryRecord = () => {
    if (!primaryObjectData) return;

    setCreatePrimaryRecordModalState({
      visible: true,
      primaryObjectId: primaryObjectData._id,
      primaryObjectName: primaryObjectData.name,
    });
  };

  const handleOnFiltersChanged = (params: {
    activeFiltersCount: number;
    activeFilters: TableFiltersModel;
  }) => {
    setCounters({
      activeFilters: params.activeFiltersCount,
    });

    setTableState({
      filters: params.activeFilters,
    });
  };

  const handleOnSelectionChanged = (params: CustomSelectionChangedEvent) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedPrimaryRecordsIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleOnPaginationChanged = (params: any) => {
    setTableState({
      pagination: params,
    });
  };

  const handleOnSortChanged = (sorting: {
    columnId?: string;
    direction?: SortDirection;
  }) => {
    setTableState({
      sorting: sorting || sortingInitialState,
    });
  };

  const handleChangeView = useCallback(
    ({
      viewId,
      viewParams,
    }: {
      viewId?: string;
      viewParams: LegacyPartiesReportParams;
    }) => {
      const { filters, sorting } = mapLegacyPartiesReportParamsToTableParams(
        viewParams || {},
      );

      setTableState({ viewId, filters, sorting });

      tableRef?.current?.setFilters(filters);
      tableRef?.current?.setSorting(
        sorting.columnId || sortingInitialState.columnId,
        sorting.direction || sortingInitialState.direction,
      );
    },
    [setTableState],
  );

  const handleDeleteView = () => {
    setTableState({
      viewId: undefined,
      sorting: sortingInitialState,
      filters: filterInitialState,
    });

    tableRef?.current?.setFilters(filterInitialState);
  };

  const handleRecordActionClick = (
    code: ActionType,
    { _id, name }: PrimaryRecord,
    options?: {
      totalDocumentsCount?: number;
    },
  ) => {
    switch (code) {
      case PRIMARY_RECORDS_ACTION_TYPES.enableAutomation:
        setSingleEnableDisableAutomationModalState({
          type: 'enable',
          visible: true,
          primaryRecordId: _id,
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.disableAutomation:
        setSingleEnableDisableAutomationModalState({
          type: 'disable',
          visible: true,
          primaryRecordId: _id,
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.archive:
        setSingleArchiveModalState({
          visible: true,
          primaryRecordId: _id,
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.delete:
        setSingleDeleteModalState({
          visible: true,
          primaryRecordId: _id,
          primaryRecordName: name,
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.makeActive:
        setSingleMakeActiveModalState({
          visible: true,
          primaryRecordId: _id,
        });
        break;
      default:
        break;
    }
  };

  const bulkActionFilterValue = useMemo(() => {
    return selection.isSelectAllChecked
      ? mapTableFiltersToServerFilters({
          tableFilters: tableState.filters,
          serverFiltersConfig: SERVER_FILTERS_CONFIG,
        })
      : getIdsFilter(selection.selectedPrimaryRecordsIds);
  }, [
    selection.isSelectAllChecked,
    selection.selectedPrimaryRecordsIds,
    tableState.filters,
  ]);

  const handleBulkActionClick = (
    code: ActionType,
    options?: Record<string, any>,
  ) => {
    switch (code) {
      case PRIMARY_RECORDS_ACTION_TYPES.enableAutomation:
        setBulkEnableDisableAutomationModalState({
          visible: true,
          type: 'enable',
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.disableAutomation:
        setBulkEnableDisableAutomationModalState({
          visible: true,
          type: 'disable',
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.archive:
        setIsBulkArchiveModalVisible(true);
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.delete:
        setIsBulkDeleteModalVisible(true);
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.makeActive:
        setIsBulkMakeActiveModalVisible(true);
        break;
      default:
        break;
    }
  };

  const bulkActionsMenuItems = useMemo(() => getActionsMenuItems({}), []);

  const handleSelectAll = () => {
    tableRef.current?.selectAllRows();
  };

  const handleSelectVisible = () => {
    tableRef.current?.selectVisibleRows();
  };

  const handleTableSelectionReset = () => {
    tableRef.current?.resetSelection();
  };

  return (
    <StyledWrapper>
      <ArchivePrimaryRecordsModal
        open={isBulkArchiveModalVisible}
        count={counters.selectedRows}
        primaryObject={{
          name: primaryObjectData?.name ?? '',
          pluralName: primaryObjectData?.pluralName ?? '',
        }}
        filters={bulkActionFilterValue}
        totalDocumentsArchived={
          selection.isSelectAllChecked ? undefined : bulkTotalDocumentsArchived
        }
        onCancel={() => setIsBulkArchiveModalVisible(false)}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setIsBulkArchiveModalVisible(false);
        }}
        onScheduled={() => {
          tableRef.current?.resetSelection();
          setIsBulkArchiveModalVisible(false);
        }}
      />

      <ArchivePrimaryRecordModal
        open={singleArchiveModalState.visible}
        primaryRecordId={singleArchiveModalState.primaryRecordId}
        onCancel={() => setSingleArchiveModalState({ visible: false })}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setSingleArchiveModalState({ visible: false });
        }}
      />
      <DeletePrimaryRecordModal
        open={singleDeleteModalState.visible}
        primaryRecord={{
          _id: singleDeleteModalState.primaryRecordId,
          name: singleDeleteModalState.primaryRecordName,
        }}
        onCancel={() => setSingleDeleteModalState({ visible: false })}
        onCompleted={() => {
          setSingleDeleteModalState({ visible: false });
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
        }}
      />
      <DeletePrimaryRecordsModal
        open={isBulkDeleteModalVisible}
        count={counters.selectedRows}
        filters={bulkActionFilterValue}
        primaryObject={{
          name: primaryObjectData?.name ?? '',
          pluralName: primaryObjectData?.pluralName ?? '',
        }}
        onCancel={() => setIsBulkDeleteModalVisible(false)}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setIsBulkDeleteModalVisible(false);
        }}
        onScheduled={() => {
          tableRef.current?.resetSelection();
          setIsBulkDeleteModalVisible(false);
        }}
      />

      <MakeActivePrimaryRecordModal
        open={singleMakeActiveModalState.visible}
        primaryRecordId={singleMakeActiveModalState.primaryRecordId}
        onCancel={() => setSingleMakeActiveModalState({ visible: false })}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setSingleMakeActiveModalState({ visible: false });
        }}
      />
      <MakeActivePrimaryRecordsModal
        open={isBulkMakeActiveModalVisible}
        count={counters.selectedRows}
        primaryObject={{
          name: primaryObjectData?.name ?? '',
          pluralName: primaryObjectData?.pluralName ?? '',
        }}
        filters={bulkActionFilterValue}
        onCancel={() => setIsBulkMakeActiveModalVisible(false)}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setIsBulkMakeActiveModalVisible(false);
        }}
        onScheduled={() => {
          tableRef.current?.resetSelection();
          setIsBulkMakeActiveModalVisible(false);
        }}
        onFailed={() => {
          setIsBulkMakeActiveModalVisible(false);
        }}
      />

      <CreatePrimaryRecordModal
        modalState={createPrimaryRecordModalState}
        onCompleted={() => {
          tableRef.current?.refreshTable();
          setCreatePrimaryRecordModalState((currentState) => ({
            ...currentState,
            visible: false,
          }));
        }}
        onCancel={() => {
          setCreatePrimaryRecordModalState((currentState) => ({
            ...currentState,
            visible: false,
          }));
        }}
      />
      <EnableDisableAutomationModal
        open={singleEnableDisableAutomationModalState.visible}
        primaryRecordId={
          singleEnableDisableAutomationModalState.primaryRecordId!
        }
        type={singleEnableDisableAutomationModalState.type!}
        onCancel={() =>
          setSingleEnableDisableAutomationModalState({
            visible: false,
          })
        }
        onCompleted={() => {
          tableRef.current?.refreshTable();
          setSingleEnableDisableAutomationModalState({
            visible: false,
          });
        }}
        onScheduled={() => {
          setSingleEnableDisableAutomationModalState({
            visible: false,
          });
        }}
        onFailed={() => {
          setSingleEnableDisableAutomationModalState({
            visible: false,
          });
        }}
      />
      <EnableDisableAutomationsModal
        open={bulkEnableDisableAutomationModalState.visible}
        filters={bulkActionFilterValue}
        count={counters.selectedRows}
        type={bulkEnableDisableAutomationModalState.type!}
        primaryObject={{
          name: primaryObjectData?.name ?? '',
          pluralName: primaryObjectData?.pluralName ?? '',
        }}
        onCancel={() =>
          setBulkEnableDisableAutomationModalState({
            visible: false,
          })
        }
        onCompleted={() => {
          tableRef.current?.refreshTable();
          tableRef.current?.resetSelection();
          setBulkEnableDisableAutomationModalState({
            visible: false,
          });
        }}
        onScheduled={() => {
          tableRef.current?.resetSelection();
          setBulkEnableDisableAutomationModalState({
            visible: false,
          });
        }}
        onFailed={() => {
          setBulkEnableDisableAutomationModalState({
            visible: false,
          });
        }}
      />
      <TableHeader>
        {isPrimaryObjectLoading ? (
          <PageTitle.Skeleton />
        ) : (
          <PageTitle
            title={primaryObjectData?.pluralName}
            icon={getAntdIconOutlinedByName(primaryObjectData?.icon)}
          />
        )}

        <TableHeader.TopRightActions>
          {isPrimaryObjectLoading ? (
            <Skeleton.Input style={{ width: 30 }} active />
          ) : (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreatePrimaryRecord}
            >
              New {primaryObjectData?.name}
            </Button>
          )}
        </TableHeader.TopRightActions>
        {areBulkActionsButtonsVisible ? (
          <TableHeader.BottomLeftActions>
            <StyledBulkActions>
              <SelectionDropdown
                areAllSelected={selection.isSelectAllChecked}
                selectedEntitiesCount={counters.selectedRows}
                onSelectAll={handleSelectAll}
                onSelectAllVisible={handleSelectVisible}
                onSelectNone={handleTableSelectionReset}
                totalCount={counters.totalRows}
                totalVisible={counters.visibleRows}
              />
              <ActionsMenuWithButtons
                items={bulkActionsMenuItems}
                onClick={handleBulkActionClick}
              />
            </StyledBulkActions>
          </TableHeader.BottomLeftActions>
        ) : (
          <>
            <TableHeader.BottomLeftActions>
              <StyledSearchInput
                //? implement `useConnectionBetweenTableAndURL` hook for primary objects for retriving the initial
                //? state from the url
                // defaultValue={value from url}
                onSearch={(value) => {
                  tableRef.current?.setFilters({
                    name: {
                      filterType: 'text',
                      filter: value,
                      type: 'contains',
                    },
                  });
                }}
              />

              <ViewsSelector
                type={ViewType.Parties}
                defaultView={
                  DEFAULT_VIEWS.primaryObject(
                    primaryObjectData?.pluralName ?? '',
                  ) as ViewEntity
                }
                viewParams={viewParams}
                viewId={tableState.viewId}
                onCreated={handleChangeView}
                onDeleted={handleDeleteView}
                onReset={handleDeleteView}
                onSelectChanged={handleChangeView}
              />
            </TableHeader.BottomLeftActions>
            <TableHeader.BottomRightActions>
              <FilterButton
                activeFiltersCount={counters.activeFilters}
                onClick={() => {
                  tableRef?.current?.toggleFiltersPanel();
                }}
              />
              <SortButton
                items={visibleColumns}
                initialValues={tableState.sorting}
                onChange={(sort) => {
                  tableRef.current!.setSorting(sort.columnId, sort.direction);
                }}
                onClick={() => {
                  const columns = tableRef?.current
                    ?.getSortableColumns()
                    ?.sort((a, b) => (a.name > b.name ? 1 : -1));
                  setVisibleColumns(columns || []);
                }}
              />
            </TableHeader.BottomRightActions>
          </>
        )}
      </TableHeader>
      {shouldRefreshData && <RefreshDataBanner onRefreshClick={refreshData} />}
      <StyledTable>
        <PrimaryRecordsTable
          ref={tableRef}
          primaryObjectSlug={objectSlug!}
          onSelectionChanged={handleOnSelectionChanged}
          onFilterChanged={handleOnFiltersChanged}
          onSortChanged={handleOnSortChanged}
          onPaginationChanged={handleOnPaginationChanged}
          onRowActionClick={handleRecordActionClick}
          tableState={tableState}
        />
      </StyledTable>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 280px;
`;

const StyledBulkActions = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;
