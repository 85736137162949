import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { REPORT_FRAGMENT } from '../fragments';

export const DELETE_REPORT = `${namespace}/DELETE_REPORT`;
const DELETE_REPORT_MUTATION = gql`
  mutation deleteReport($id: String!) {
    deleteReport(id: $id) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const deleteReport = (id) => ({
  type: DELETE_REPORT,
  payload: {
    key: 'deleteReport',
    graphql: {
      mutation: DELETE_REPORT_MUTATION,
      variables: {
        id,
      },
    },
  },
});
