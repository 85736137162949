import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const REQUEST_RECORD_DETAILS_QUERY = graphql(`
query RequestRecordDetails($input: RequestRecordInput!) {
  requestRecord(input: $input) {
    _id
    name
    contextRecord {
      _id
      name
    }
    primaryRecord {
      _id
      name
      primaryObject {
        _id
        name
      }
    }
  }
}
`);

export const useRequestRecordDetails = (requestId?: string) => {
  return useQuery(REQUEST_RECORD_DETAILS_QUERY, {
    variables: { input: { id: requestId } },
    skip: !requestId,
  });
};
