export const COLUMN_FIELDS = {
  name: 'name',
  related: 'related',
  description: 'description',
  compliance: 'compliance',
  actions: 'actions',
} as const;

export const REQUESTS_QUERY_FILTER_NAMES = {
  primaryRecordId: 'primaryRecordId',
  name: 'name',
  id: 'id',
} as const;
