/**
 * Badge color enum.
 */
export const BadgeColors = {
  redIcon: 'iconRed',
  redBg: 'lightRedTooltipBg',
  redBorder: 'redTooltipBorder',
  orangeIcon: 'iconOrange',
  blueIcon: 'iconBlue',
  blueBg: 'lightBlueTooltipBg',
  blueBorder: 'blueTooltipBorder',
  greenIcon: 'iconGreen',
  greenBg: 'lightGreenTooltipBg',
  greenBorder: 'greenTooltipBorder',
  grayIcon: 'iconGray',
  yellowBg: 'paleYellow',
  yellowBorder: 'yellowTooltipBorder',
  yellowIcon: 'iconYellow',
};

/**
 * Badge size.
 */
export const BadgeSize = {
  Small: 13,
  Medium: 17,
  Large: 21,
};
