const shadows = {
  softOuterShadow: '0 5px 10px 0 rgba(9,8,61,0.04)',
  altOuterShadow: 'rgba(71, 88, 114, 0.14) 0 2px 2px',
  altOuterShadowHover: 'rgba(71, 88, 114, 0.16) 0 4px 16px',
  raitingShadow: `0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08),
  0 3px 6px -4px rgba(0, 0, 0, 0.12);`,
  card: '0 1px 11px 0 rgba(9, 8, 61, 0.04)',
  pageCard: '0px 2px 2px 0px rgba(71, 88, 114, 0.14)',
};

export default shadows;
