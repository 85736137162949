import { Collapse } from 'antd';
import { type ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

const { Panel } = Collapse;

type SingleCollapseProps = {
  header: string;
  isCollapsed?: boolean;
  children: ReactNode;
};

export const SingleCollapse = ({
  children,
  header,
  isCollapsed = false,
  ...rest
}: SingleCollapseProps) => {
  const [state, setState] = useState(isCollapsed ? '' : '1');

  useEffect(() => {
    setState(isCollapsed ? '' : '1');
  }, [isCollapsed]);

  return (
    <div {...rest}>
      <StyledCollapse
        onChange={() =>
          setState((currentState) => (currentState === '1' ? '' : '1'))
        }
        activeKey={state}
        ghost
      >
        <Panel header={header} key="1">
          {children}
        </Panel>
      </StyledCollapse>
    </div>
  );
};

const StyledCollapse = styled(Collapse)`
  border-left: 2px solid ${({ theme }) => theme.colors.lightGray};

  .ant-collapse-item > .ant-collapse-header {
    padding: 5px 10px;
  }
`;
