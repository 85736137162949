import styled from 'styled-components';

const ListDivider = ({ label }: { label?: string }) => (
  <StyledWrapper>{label && <p>{label}</p>}</StyledWrapper>
);

const StyledWrapper = styled.div`
  position: relative;
  height: 27px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.darkGrayText};
  padding: 0 24px;
  font-weight: 600;
  font-size: 12px;

  p {
    position: absolute;
    bottom: 0;
    margin: 0;
  }
`;

export default ListDivider;
