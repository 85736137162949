import { Tooltip } from 'antd';
import type React from 'react';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

type ConversationHeaderProps = PropsWithChildren<{
  subject: string;
  participants: {
    _id: string;
    email: string;
    name?: string;
  }[];
  contactsEditor: React.ReactNode;
}>;

export const ConversationHeader = ({
  participants,
  subject,
  contactsEditor,
  children,
}: ConversationHeaderProps) => (
  <StyledWrapper>
    <div className="left_actions">
      <StyledSubjectTitle>{subject}</StyledSubjectTitle>
      <StyledUserSection>
        <p className="participants_list">
          {participants.map((participant) => (
            <span key={participant._id}>
              <Tooltip title={participant.email}>
                {participant.name || participant.email}
              </Tooltip>
            </span>
          ))}
        </p>

        {contactsEditor}
      </StyledUserSection>
    </div>

    <StyledActionsContainer>{children}</StyledActionsContainer>
  </StyledWrapper>
);

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .left_actions {
    flex: 1;
    /* hack to make sure the flex item shrinks when the container resizes */
    width: 0%;
  }
`;

const StyledSubjectTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledUserSection = styled.section`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;

  .participants_list {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;

    span::before {
      content: ', ';
    }

    span:first-child::before {
      content: '';
    }
  }

  button {
    margin-left: 5px;
    font-size: 12px;
    flex-shrink: 0;
  }
`;

const StyledActionsContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
`;
