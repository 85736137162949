import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTIES_WIDGET = `${namespace}/FETCH_PARTIES_WIDGET`;

const FETCH_PARTIES_WIDGET_QUERY = gql`
  query getPartiesWidgetData($filters: PartiesWidgetFilter!) {
    getPartiesWidgetData(filters: $filters) {
      partiesNonCompliant {
        _id
        name
        partyComplianceProfile {
          nextExpirationDate
        }
      }
      partiesNonresponsive {
        _id
        name
        partyComplianceProfile {
          nextExpirationDate
        }
      }
      partiesExpiring {
        _id
        name
        partyComplianceProfile {
          nextExpirationDate
        }
      }
      totalNonCompliant
      totalNonresponsive
      totalExpiring
    }
  }
`;

export const fetchPartiesWidget = ({ filters }) => ({
  type: FETCH_PARTIES_WIDGET,
  payload: {
    key: 'getPartiesWidgetData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTIES_WIDGET_QUERY,
      variables: { filters },
    },
  },
});
