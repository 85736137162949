import type {
  OrganizationMember,
  RecordActivity,
} from '@graphql/types/graphql';

export const mapActivitiesToLegacyEventFields = ({
  activities,
}: {
  activities: RecordActivity[];
}) => {
  return activities.map((activity) => ({
    ...activity,
    partyName: activity.primaryRecordName,
    projectName: activity.contextRecordName,
    partyId: activity.primaryRecordId,
    contextEntityId: activity.referenceId,
  }));
};

type Member = Omit<OrganizationMember, 'organization' | 'role'>;

export const excludeMembersWithoutName = (members: Member[]) =>
  members.filter(({ user }) => user?.profile?.name?.trim());

const formatToUserTag = (name: string) => `@${name.replace('', '')}`;

export const mapMembersToUserTags = (members: Member[]) => {
  return members.map(({ user }) => ({
    id: user?._id,
    name: formatToUserTag(user?.profile?.name),
  }));
};

export const excludeUserById = (idToExclude: string, members: Member[]) => {
  return members.filter((member) => member.user._id !== idToExclude);
};

export const getTaggedNames = (text: string = '') =>
  text.split(' ').filter((word) => word[0] === '@');

export const getMemberIdsByNames = ({
  names = [],
  members = [],
}: {
  names: string[];
  members: { id: string; name: string }[];
}) =>
  members
    .filter((member) => names.includes(member.name))
    .map((member) => member.id);
