import { put, takeEvery } from 'redux-saga/effects';

import { logout } from '@modules/auth/actions';

import { DELETE_USER } from '../actions';

function* deleteUserSaga() {
  yield put(logout());
}

function* userSagas() {
  yield takeEvery(`${DELETE_USER}_SUCCESS`, deleteUserSaga);
}

export default userSagas;
