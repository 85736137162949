import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const DELETE_PERSONAL_ACCESS_TOKEN = `${namespace}/DELETE_PERSONAL_ACCESS_TOKEN`;

const DELETE_PERSONAL_ACCESS_TOKEN_MUTATION = gql`
  mutation deletePersonalAccessToken($id: String!) {
    deletePersonalAccessToken(id: $id) {
      _id
      name
      level
      createdAt
    }
  }
`;

export const deletePersonalAccessToken = (id) => ({
  type: DELETE_PERSONAL_ACCESS_TOKEN,
  payload: {
    key: 'deletePersonalAccessToken',
    successMessage: 'Token deleted successfully!',
    graphql: {
      mutation: DELETE_PERSONAL_ACCESS_TOKEN_MUTATION,
      variables: { id },
    },
  },
});
