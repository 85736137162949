export const EventType = {
  DocumentArchived: 'document:archived',
  DocumentDeleted: 'document:deleted',
  DocumentReviewed: 'document:reviewed',
  DocumentUploaded: 'document:uploaded',
  DocumentFlagAdded: 'document:flag:added',
  DocumentFlagEdited: 'document:flag:edited',
  DocumentFlagRemoved: 'document:flag:removed',
  DocumentVerificationLogged: 'document:verification:added',
  DocumentVerificationEdited: 'document:verification:edited',
  DocumentVerificationRemoved: 'document:verification:removed',
  DocumentAssociatedAllProjects: 'document:associated:all:projects',
  DocumentAssociatedToProject: 'document:associated:to:project',
  DocumentAssignedToParty: 'document:assigned:to:party',
  PartyAddedToProject: 'party:added:to:project',
  PartyRemovedFromProject: 'party:removed:from:project',
  PartyCreated: 'party:created',
  PartyEdited: 'party:edited',
  PartyEditedAdditionalNotes: 'party:edited:additionalnotes',
  PartyMarkAs: 'party:marked',
  PartyTagAdded: 'party:tag:added',
  PartyTagDeleted: 'party:tag:deleted',
  PartyContactCreated: 'party:contact:created',
  PartyContactDeleted: 'party:contact:deleted',
  ComplianceChanged: 'compliance:changed',
  ComplianceTrackingDisabled: 'compliance:tracking:disabled',
  ComplianceTrackingEnabled: 'compliance:tracking:enabled',
  ExpiringPartyTagDeleted: 'expiring:party:tag:deleted',
  RequestSend: 'request:send',
  RequestSendAutomatic: 'automatic:request:send',
  RequestSendReminder: 'request:send:reminder',
  RequestSendReminderAutomatic: 'automatic:send:reminder',
  ReportDocumentsUploaded: 'report:documents:uploaded',
  MessageSend: 'message:send',
  ComplianceProfileAssign: 'compliance:profile:assign',
  CommentCreated: 'comment:created',
  ConversationMessageSent: 'conversation:message:sent',
  ConversationMessageReplied: 'conversation:message:replied',
  RequirementWaiverAdded: 'requirement:waiver:added',
  RequirementWaiverRemoved: 'requirement:waiver:removed',
  RequirementOverrideAdded: 'requirement:override:added',
  RequirementOverrideRemoved: 'requirement:override:removed',
  SubjectWaiverAdded: 'subject:waiver:added',
  SubjectWaiverRemoved: 'subject:waiver:removed',
  SubjectOverrideAdded: 'subject:override:added',
  SubjectOverrideRemoved: 'subject:override:removed',
  /** @deprecated replaced by `AutomationEnabled` */
  AutomaticRequestsEnabled: 'automatic:requests:enabled',
  /** @deprecated replaced by `AutomationDisabled` */
  AutomaticRequestsDisabled: 'automatic:requests:disabled',
  AutomationEnabled: 'automation:enabled',
  AutomationDisabled: 'automation:disabled',
  ProjectCreated: 'project:created',
  ExternalPartyRemovedFromProject: 'external:party:removed:from:project',
  PartyCreateMatchingDocument: 'party:create:matching:document',
  PrimaryRecordCreated: 'primary_record:created',
  PrimaryRecordUpdated: 'primary_record:updated',
  PrimaryRecordDeleted: 'primary_record:deleted',
  RequestRecordCreated: 'request_record:created',
  RequestRecordUpdated: 'request_record:updated',
  RequestRecordDeleted: 'request_record:deleted',
  PrimaryObjectCreated: 'primary_object:created',
  PrimaryObjectUpdated: 'primary_object:updated',
  PrimaryObjectDeleted: 'primary_object:deleted',
} as const;

export const namespace = 'EVENT_LOG';
export const STATE_KEY = 'eventLog';
