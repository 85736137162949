import type { SetFilter } from '../../components/Table';
import { FILTER_OPERATORS } from '../../constants/filters';

export const getOptionsFilter = (param: string[] | SetFilter) => {
  const value = Array.isArray(param) ? param : param.values;

  if (!value.length) {
    return;
  }

  return { operator: FILTER_OPERATORS.in, value };
};
