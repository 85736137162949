import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import type React from 'react';

type LoadingSpinnerProp = {
  iconStyle?: React.CSSProperties;
};

export const LoadingSpinner: React.FC<LoadingSpinnerProp> = ({ iconStyle }) => (
  <Spin indicator={<LoadingOutlined style={iconStyle} spin />} />
);
