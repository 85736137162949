export const ProgressColorsPalette = ['#4ED256', '#FFA81E', '#E84848'];
export const namespace = 'DASHBOARD';
export const STATE_KEY = 'dashboard';

export const DocumentsWidgetTabKeys = {
  Recent: 'recent',
  ToReview: 'toReview',
  Expiring: 'expiring',
};

export const PartiesWidgetTabKeys = {
  NonCompliant: 'noncompliant',
  NonResponsive: 'nonresponsive',
  Expiring: 'expiring',
};

export const TrendPeriods = [
  {
    name: '7 days',
    value: 'week',
  },
  {
    name: '30 days',
    value: 'month',
  },
  {
    name: '6 months',
    value: 'halfYear',
  },
  {
    name: '12 months',
    value: 'year',
  },
] as const;

export const TimeFrameLabelMapping = {
  week: '7 days',
  month: '30 days',
  halfYear: '6 months',
  year: '12 months',
} as const;

export const defaultTimeInterval = 'week';
