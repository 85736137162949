import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchProjectsForSearch } from '@modules/project/actions';
import { getGraphqlResponse } from '@store/helpers';

type FilterOptions = {
  filters: {
    skip?: number;
    limit?: number;
    name?: string;
    isActive?: boolean;
  };
};

const DEFAULT_FIRST = 10;
const DEFAULT_FILTER_OPTIONS = {
  filters: {
    skip: 0,
    limit: DEFAULT_FIRST,
    name: '',
    isActive: true,
  },
};

export const useProjectsForSearch = () => {
  const [projects, setProjects] = useState<any[]>([]);
  const [projectsTotalCount, setProjectsTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const loadProjectsForSearch = useCallback(
    async (filterOptions: FilterOptions = DEFAULT_FILTER_OPTIONS) => {
      setLoading(true);
      try {
        const res = await dispatch(
          fetchProjectsForSearch({
            filters: {
              ...filterOptions.filters,
              name: search,
            },
          }),
        );
        // @ts-ignore
        const projectsData = getGraphqlResponse(res);

        setProjects(projectsData?.data || []);
        setProjectsTotalCount(projectsData?.totalCount || 0);
      } catch (err) {
        console.log('[useProjectsForSearch] error: ', err);
      }

      setLoading(false);
    },
    [dispatch, search],
  );

  const loadMoreProjectsForSearch = async (
    filterOptions: FilterOptions = DEFAULT_FILTER_OPTIONS,
  ) => {
    setLoading(true);

    const res = await dispatch(
      fetchProjectsForSearch({
        filters: {
          ...filterOptions.filters,
          skip: projects.length,
          name: search,
        },
      }),
    );

    // @ts-ignore
    const projectsData: { data: any[] } = getGraphqlResponse(res);

    setProjects([...projects, ...projectsData.data]);

    setLoading(false);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  const setSearchWithDebounce = useMemo(
    () => debounce(setSearch, 500),
    [setSearch],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    loadProjectsForSearch();
  }, [loadProjectsForSearch, search]);

  return {
    loadProjectsForSearch,
    loadMoreProjectsForSearch,
    setSearch: setSearchWithDebounce,
    projects,
    projectsTotalCount,
    loading,
  };
};
