import { namespace } from '../constants';

export const CONFIGURE_INTEGRATIONS_STATUS = `${namespace}/CONFIGURE_INTEGRATIONS_STATUS`;

export const configureIntegrationsStatus = (
  data,
  { page, pageSize, sort },
) => ({
  type: CONFIGURE_INTEGRATIONS_STATUS,
  payload: {
    data,
    page,
    pageSize,
    sort,
  },
});
