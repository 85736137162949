import { message as notify } from 'antd';
import { all, takeLatest } from 'redux-saga/effects';

import { SEARCH } from '../actions';

function handleSearchFailure() {
  notify.error('Search failed. Please retry!');
}

export default function* searchSagas() {
  yield all([takeLatest(`${SEARCH}_FAIL`, handleSearchFailure)]);
}
