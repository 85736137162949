import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { CUSTOM_FIELD_FRAGMENT } from '../fragments';

export const DELETE_CUSTOM_FIELD = `${namespace}/DELETE_CUSTOM_FIELD`;

const DELETE_CUSTOM_FIELD_MUTATION = gql`
  mutation deleteCustomField($_id: String!) {
    deleteCustomField(_id: $_id) {
      ...CustomField
    }
  }
  ${CUSTOM_FIELD_FRAGMENT}
`;

export const deleteCustomField = (_id) => ({
  type: DELETE_CUSTOM_FIELD,
  payload: {
    key: 'deleteCustomField',
    graphql: {
      mutation: DELETE_CUSTOM_FIELD_MUTATION,
      variables: {
        _id,
      },
    },
  },
});
