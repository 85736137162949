import { Popover } from 'antd';
import * as R from 'ramda';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import VerifiedIcon from '@common/icons/VerifiedIcon';

import { BadgeColors, BadgeSize } from '../model/badges';

import BadgePopover from './BadgePopover';

const VerificationBadgePopover = ({
  documentData,
  flagData,
  verificationData,
  sizeIcon,
  isShortVersion = false,
}) => {
  const themeContext = useContext(ThemeContext);

  const renderIconContent = () => (
    <VerificationBadgePopover.IconContent>
      <VerifiedIcon
        color={
          themeContext.colors[
            !R.isNil(R.propOr(null, 'verifiedOn', verificationData))
              ? BadgeColors.greenIcon
              : BadgeColors.grayIcon
          ]
        }
        size={BadgeSize[sizeIcon]}
      />
    </VerificationBadgePopover.IconContent>
  );

  return (
    <VerificationBadgePopover.Flag>
      {Boolean(R.propOr(false, 'verifiedOn', verificationData)) ? (
        <Popover
          placement="bottom"
          overlayStyle={{ maxWidth: '600px' }}
          content={
            <BadgePopover
              documentData={documentData}
              flagData={flagData}
              verificationData={verificationData}
              sizeIcon={sizeIcon}
              isShortVersion={isShortVersion}
            />
          }
        >
          <VerificationBadgePopover.IconContent>
            <VerifiedIcon
              color={
                themeContext.colors[
                  !R.isNil(R.propOr(null, 'verifiedOn', verificationData))
                    ? BadgeColors.greenIcon
                    : BadgeColors.grayIcon
                ]
              }
              size={BadgeSize[sizeIcon]}
            />
          </VerificationBadgePopover.IconContent>
        </Popover>
      ) : (
        <>{renderIconContent()}</>
      )}
    </VerificationBadgePopover.Flag>
  );
};

VerificationBadgePopover.defaultProps = {
  sizeIcon: 'Medium',
};

VerificationBadgePopover.Flag = styled.div`
  display: flex;
`;

VerificationBadgePopover.Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
`;

VerificationBadgePopover.IconContent = styled.div`
  display: flex;
`;

VerificationBadgePopover.IconTooltip = styled.div`
  display: flex;
  margin-top: 4px;
`;

VerificationBadgePopover.Description = styled.div`
  color: #354052;
  font-size: 13px;
  margin: 0;
  padding: 0 40px 0 15px;

  & > span {
    display: block;
    font-weight: 300;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

VerificationBadgePopover.Close = styled.div`
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;

  & > i {
    color: #e8edf3;
  }
`;

export default VerificationBadgePopover;
