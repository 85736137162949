import { useMutation } from '@graphql/hooks';

import {
  DISABLE_PARTY_AUTOMATION_MUTATION,
  ENABLE_PARTY_AUTOMATION_MUTATION,
} from '../mutations';

type MutationPayload = {
  automationCode: string;
  id: string;
};

export const usePartyAutomationUpdate = () => {
  const [enablePartyAutomation, { loading: isEnablePartyAutomationLoading }] =
    useMutation(ENABLE_PARTY_AUTOMATION_MUTATION);
  const [disablePartyAutomation, { loading: isDisablePartyAutomationLoading }] =
    useMutation(DISABLE_PARTY_AUTOMATION_MUTATION);

  const updatePartyAutomation = async ({
    isEnabled,
    ...data
  }: MutationPayload & { isEnabled: boolean }) => {
    if (isEnabled) {
      return await enablePartyAutomation({
        variables: {
          data,
        },
      });
    }

    return await disablePartyAutomation({
      variables: {
        data,
      },
    });
  };

  return {
    updatePartyAutomation,
    isEnablePartyAutomationLoading,
    isDisablePartyAutomationLoading,
  };
};
