import { NetworkStatus } from '@apollo/client';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DEFAULT_PAGE_SIZE,
};

const SUBJECTS_QUERY = graphql(`
  query SubjectsSelect_ListSubjectsQuery($input: ListSubjectsInput!) {
    listSubjects(input: $input) {
      totalCount
      nodes {
        _id
        subjectLabel
      }
    }
}`);

export const useSubjectsQuery = ({ value }: { value?: string[] }) => {
  const [searchedValue, setSearchedValue] = useState<string | undefined>();
  const searchedValueDebounced = useDebounce(searchedValue, 300);
  const isFetchingInitialValuesRef = useRef(false);

  const [fetch, { loading, fetchMore, data: listResult, networkStatus }] =
    useLazyQuery(SUBJECTS_QUERY, {
      notifyOnNetworkStatusChange: true,
    });

  const [
    fetchInitialValues,
    { data: initialValuesResult, loading: isInitialValuesLoading },
  ] = useLazyQuery(SUBJECTS_QUERY, {});

  const subjectsResult = listResult?.listSubjects;

  useEffect(() => {
    if (!value || isFetchingInitialValuesRef.current) return;

    const subjects = subjectsResult?.nodes || [];
    if (!subjects.length) return;

    const existingSubjectsIds = new Set([
      ...subjects.map((subject) => subject._id),
      ...(initialValuesResult?.listSubjects?.nodes || []).map(
        (subject) => subject._id,
      ),
    ]);

    const missingSubjects = value.filter((id) => !existingSubjectsIds.has(id));
    if (!missingSubjects.length) return;

    isFetchingInitialValuesRef.current = true;
    fetchInitialValues({
      onCompleted: () => {
        isFetchingInitialValuesRef.current = false;
      },
      variables: {
        input: {
          first: missingSubjects.length,
          offset: 0,
          filter: {
            and: [
              {
                name: 'subjectId',
                operator: FILTER_OPERATORS.in,
                value: missingSubjects,
              },
            ],
          },
        },
      },
    });
  }, [fetchInitialValues, value, subjectsResult, initialValuesResult]);

  const getFilters = useCallback(() => {
    if (searchedValueDebounced) {
      return {
        and: [
          {
            name: 'subjectLabel',
            operator: FILTER_OPERATORS.contains,
            value: searchedValueDebounced,
          },
        ],
      };
    }
  }, [searchedValueDebounced]);

  const fetchSubjects = useCallback(() => {
    fetch({
      variables: {
        input: {
          ...DEFAULT_INPUT,
          filter: getFilters(),
        },
      },
    });
  }, [fetch, getFilters]);

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  const fetchMoreSubjects = useCallback(() => {
    fetchMore({
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: subjectsResult?.nodes.length || 0,
          filter: getFilters(),
        },
      },
    });
  }, [fetchMore, subjectsResult, getFilters]);

  const options = useMemo(() => {
    const subjectsItems = (subjectsResult?.nodes || []).reduce(
      (acc, subject) => {
        acc[subject._id] = subject;
        return acc;
      },
      {} as Record<string, { _id: string; subjectLabel: string }>,
    );

    const subjectsMap = (initialValuesResult?.listSubjects?.nodes || []).reduce(
      (acc, subject) => {
        acc[subject._id] = subject;
        return acc;
      },
      subjectsItems,
    );

    return Object.values(subjectsMap)
      .sort((a, b) => {
        return a.subjectLabel.localeCompare(b.subjectLabel);
      })
      .map((subject) => ({
        value: subject._id,
        label: subject.subjectLabel,
      }));
  }, [subjectsResult, initialValuesResult]);

  return {
    subjects: subjectsResult?.nodes || [],
    totalSubjects: subjectsResult?.totalCount || 0,
    options,
    fetchSubjects,
    isInitialValuesLoading:
      isInitialValuesLoading && value && !subjectsResult?.nodes.length,
    fetchMoreSubjects,
    isSubjectsLoading: loading,
    isSubjectsLoadingMore: networkStatus === NetworkStatus.fetchMore,
    searchedValue,
    setSearchedValue,
  };
};
