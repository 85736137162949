import {
  FolderOutlined,
  LinkOutlined,
  MoreOutlined,
  SendOutlined,
  ShopOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { PageTitle } from '@common/components/PageTitleWrapper';
import { TabButtons } from '@common/components/TabButtons';
import { REQUEST_RECORDS_TABS } from '@modules/request-records';
import { Breadcrumb, Button, Result, Skeleton } from 'antd';
import { DetailsPageLayout } from 'layouts';
import pluralize from 'pluralize';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { usePrimaryRecordRequestRecordsCount } from './hooks/usePrimaryRecordRequestRecordsCount';
import { useRequestRecordDetails } from './hooks/useRequestRecordDetails';

export const RequestRecordDetail = () => {
  const { requestId, recordId } = useParams<{
    requestId: string;
    recordId: string;
  }>();
  const {
    data: requestRecordDetails,
    loading: isLoadingRequestRecordDetails,
    error: requestRecordDetailsError,
  } = useRequestRecordDetails(requestId);
  const {
    data: primaryRecordRequestRecordsCount,
    loading: isLoadingPrimaryRecordRequestRecordsCount,
  } = usePrimaryRecordRequestRecordsCount(recordId);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/');
  const tab = pathSegments[7];
  const activeTab =
    Object.values(REQUEST_RECORDS_TABS).find((t) => t.key === tab)?.key ?? '';
  const requestRecordsCount =
    primaryRecordRequestRecordsCount?.primaryRecord?.requestRecords?.totalCount;

  const handleClose = () => {
    const pathSegmentsWithoutTab = pathSegments.slice(0, -2).join('/');

    navigate(pathSegmentsWithoutTab);
  };

  if (requestRecordDetailsError) {
    return (
      <Result
        status={500}
        title={requestRecordDetailsError.name}
        subTitle={requestRecordDetailsError.message}
      />
    );
  }

  return (
    <DetailsPageLayout>
      <DetailsPageLayout.TopBar>
        <DetailsPageLayout.PageActions onClose={handleClose} />
        <Breadcrumb>
          <Breadcrumb.Item>
            {isLoadingRequestRecordDetails ? (
              <Skeleton.Input active size="small" />
            ) : (
              requestRecordDetails?.requestRecord?.primaryRecord?.name
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isLoadingPrimaryRecordRequestRecordsCount ? (
              <Skeleton.Input active size="small" />
            ) : (
              `${requestRecordsCount} ${pluralize('Request', requestRecordsCount)}`
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
      </DetailsPageLayout.TopBar>

      <DetailsPageLayout.Header>
        <StyledTopLeftActions>
          <PageTitle.Composite
            leftTitle={
              <PageTitle
                title={
                  isLoadingRequestRecordDetails ? (
                    <Skeleton.Input active size="small" />
                  ) : (
                    requestRecordDetails?.requestRecord?.primaryRecord
                      ?.primaryObject?.name
                  )
                }
                icon={<ShopOutlined />}
                iconSize="small"
              />
            }
            middleIcon={
              requestRecordDetails?.requestRecord?.contextRecord?.name ? (
                <SwapOutlined />
              ) : undefined
            }
            rightTitle={
              requestRecordDetails?.requestRecord?.contextRecord?.name ? (
                <PageTitle
                  title={
                    requestRecordDetails?.requestRecord?.contextRecord?.name
                  }
                  icon={<FolderOutlined />}
                  iconSize="small"
                />
              ) : undefined
            }
          />
          <p className="requestName">
            {isLoadingRequestRecordDetails ? (
              <Skeleton.Input active size="small" />
            ) : (
              requestRecordDetails?.requestRecord?.name
            )}
          </p>
        </StyledTopLeftActions>

        <StyledBottomLeftActions>
          <TabButtons tabs={REQUEST_RECORDS_TABS} activeTab={activeTab} />
        </StyledBottomLeftActions>

        <StyledBottomRightActions>
          <Button type="primary" icon={<SendOutlined />}>
            Send Email
          </Button>
          <Button icon={<LinkOutlined />} />
          <Button icon={<MoreOutlined />} />
        </StyledBottomRightActions>
      </DetailsPageLayout.Header>

      <DetailsPageLayout.MainContent>
        <DetailsPageLayout.Content>
          <DetailsPageLayout.Body>
            <Outlet />
          </DetailsPageLayout.Body>
        </DetailsPageLayout.Content>
      </DetailsPageLayout.MainContent>
    </DetailsPageLayout>
  );
};

const StyledTopLeftActions = styled.div.attrs({
  className: 'top-full-actions',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .requestName {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.subtitleText};
    margin: 0;
    padding: 0;
  }
`;

const StyledBottomLeftActions = styled.div.attrs({
  className: 'bottom-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledBottomRightActions = styled.div.attrs({
  className: 'bottom-right-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;
