import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const PRIMARY_RECORD_NAME_QUERY = graphql(`
  query ContactsTablePrimaryRecordName($input: PrimaryRecordInput!) {
    primaryRecord(input: $input) {
      _id
      name
    }
  }
`);

export const usePrimaryRecordName = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const { data } = useQuery(PRIMARY_RECORD_NAME_QUERY, {
    variables: {
      input: {
        id: primaryRecordId,
      },
      skip: !primaryRecordId,
    },
  });

  return {
    name: data?.primaryRecord.name || '',
  };
};
