import { BadgeColors, BadgeSize } from '@common/components/Badges/model/badges';

const VerifiedIcon = ({ size, color }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 20 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M15.3055184,8.29939389 L9.61810198,13.986059 C9.46797421,14.1338912 9.23595855,14.1338912 9.09113832,13.9754995 L7.19710204,11.9448378 C7.0484907,11.7864462 7.0484907,11.5289583 7.19710204,11.3705667 C7.34495515,11.2113628 7.58455302,11.2113628 7.73316435,11.3705667 L9.36940546,13.1234339 L14.7884116,7.70562841 C14.940814,7.55292264 15.1811701,7.56104529 15.3237157,7.72512276 C15.4670195,7.88920023 15.4586791,8.14587587 15.3055184,8.29939389 M18.4383332,12.1479059 L20.2141113,10.6680908 L18.4383332,9.18935983 L19.5116057,7.1414692 L17.3054345,6.45414116 L17.5146684,4.15148381 L15.2120111,4.36071774 L14.524683,2.15454652 L12.4789606,3.22781901 L10.9991455,1.453125 L9.51933041,3.22781901 L7.47252388,2.15454652 L6.78519584,4.36071774 L4.4825385,4.15148381 L4.69177243,6.45414116 L2.4856012,7.1414692 L3.55887369,9.18935983 L1.78417969,10.6680908 L3.55887369,12.1479059 L2.4856012,14.1947124 L4.69177243,14.8820405 L4.4825385,17.1846978 L6.78519584,16.9743798 L7.47252388,19.180551 L9.51933041,18.1072785 L10.9991455,19.8830566 L12.4789606,18.1072785 L14.524683,19.180551 L15.2120111,16.9743798 L17.5146684,17.1846978 L17.3054345,14.8820405 L19.5116057,14.1947124 L18.4383332,12.1479059 L18.4383332,12.1479059 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      id="Parties-List"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Parties-List-|-List-Expanded-Row"
        transform="translate(-97.000000, -1538.000000)"
      >
        <g
          id="-Icons-/-Document-verified"
          transform="translate(96.000000, 1537.000000)"
        >
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use
            id="Combined-Shape"
            fill={color}
            fillRule="evenodd"
            xlinkHref="#path-1"
          ></use>
        </g>
      </g>
    </g>
  </svg>
);

VerifiedIcon.defaultProps = {
  size: BadgeSize.Medium,
  color: BadgeColors.grayIcon,
};

export default VerifiedIcon;
