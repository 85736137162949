import { InputNumber, Select } from 'antd';
import type { Moment } from 'moment-timezone';
import styled from 'styled-components';

import DatePicker from '@common/components/DatePicker';
import { defaultDateFormats } from '@common/constants';

import { KeyType, WithinDateOptions } from './constants';
import type {
  OnFilter,
  RangeFilter,
  SelectedFilter,
  WithinFilter,
} from './types';

const DateFilterInput = ({
  selectedFilter,
  onChange,
}: {
  selectedFilter?: Partial<SelectedFilter>;
  onChange: (filter: Partial<SelectedFilter>) => void;
}) => {
  if (selectedFilter?.key === KeyType.ON) {
    return (
      <SingleDatePicker selectedFilter={selectedFilter} onChange={onChange} />
    );
  }

  if (selectedFilter?.key === KeyType.BETWEEN) {
    return <RangePicker selectedFilter={selectedFilter} onChange={onChange} />;
  }

  if (
    selectedFilter?.key === KeyType.LAST ||
    selectedFilter?.key === KeyType.NEXT ||
    selectedFilter?.key === KeyType.OLDER
  ) {
    return (
      <BetweenInput
        selectedFilter={selectedFilter}
        onChange={onChange}
        // key ensures all the inputs are cleared and component is unmounted
        key={selectedFilter.key}
      />
    );
  }

  return null;
};

const BetweenInput = ({
  selectedFilter,
  onChange,
}: {
  selectedFilter?: Partial<WithinFilter>;
  onChange: (filter: Partial<WithinFilter>) => void;
}) => {
  return (
    <StyledWrapper>
      <InputNumber
        placeholder="1"
        min={1}
        defaultValue={selectedFilter?.withinDateValue}
        onChange={(value) =>
          onChange({
            ...selectedFilter,
            withinDateValue: value !== null ? value : undefined,
          })
        }
        data-cy="dateFilterWithinValue"
      />
      <Select
        placeholder="Select..."
        defaultValue={selectedFilter?.withinDateType}
        onChange={(value) =>
          onChange({
            ...selectedFilter,
            withinDateType: value,
          })
        }
        data-cy="dateFitlerWithinType"
      >
        {WithinDateOptions.map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </StyledWrapper>
  );
};

const RangePicker = ({
  selectedFilter,
  onChange,
}: {
  selectedFilter: Partial<RangeFilter>;
  onChange: (filter: Partial<RangeFilter>) => void;
}) => {
  return (
    <StyledWrapper>
      <StyledRangeWrapper>
        <div data-cy="dateFilterFromDatePicker">
          <StyledDatePicker
            format={defaultDateFormats}
            allowClear={false}
            defaultValue={selectedFilter?.from}
            onChange={(value) =>
              onChange({
                ...selectedFilter,
                from: value as Moment,
              })
            }
          />
          <span className="divider">and</span>
        </div>
        <div data-cy="dateFilterToDatePicker">
          <StyledDatePicker
            format={defaultDateFormats}
            allowClear={false}
            defaultValue={selectedFilter?.to}
            onChange={(value) =>
              onChange({
                ...selectedFilter,
                to: value as Moment,
              })
            }
          />
        </div>
      </StyledRangeWrapper>
    </StyledWrapper>
  );
};

const SingleDatePicker = ({
  selectedFilter,
  onChange,
}: {
  selectedFilter: Partial<OnFilter>;
  onChange: (filter: Partial<SelectedFilter>) => void;
}) => {
  return (
    <StyledWrapper data-cy="dateFilterSingleDatePicker">
      <StyledDatePicker
        allowClear={false}
        format={defaultDateFormats}
        defaultValue={selectedFilter?.value}
        onChange={(value) => {
          onChange({
            ...selectedFilter,
            value: value as Moment,
          });
        }}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`;

const StyledRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .divider {
    margin-left: 10px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 135px !important;
`;

export default DateFilterInput;
