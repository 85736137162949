import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import AppConfig from '@config/AppConfig';

const wsSubscriptionClient = new createClient({
  url: AppConfig.graphqlApiWsUrl,
  shouldRetry: () => true,
});

const wsLink = new GraphQLWsLink(wsSubscriptionClient);

export default wsLink;
