import { useSelector } from 'react-redux';

import { getDownloads } from '../selectors';

import DownloadProgress from './DownloadProgress';

const DownloadList = () => {
  const downloads = useSelector(getDownloads);

  return (
    <>
      {downloads.map((d) => (
        <DownloadProgress download={d} key={d.id} />
      ))}
    </>
  );
};

export default DownloadList;
