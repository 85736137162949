import { Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

const DateTimeline = ({ date }) => (
  <DateTimeline.Date className="timestamp">
    <Tooltip title={moment(date).fromNow()}>
      {moment(date).format('lll')}
    </Tooltip>
  </DateTimeline.Date>
);

DateTimeline.Date = styled.div`
  margin-top: 3px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);

  .anticon {
    margin-right: 3px;
  }
`;

export default DateTimeline;
