import {
  type DocumentNode,
  type OperationVariables,
  type SubscriptionHookOptions,
  type SubscriptionResult,
  type TypedDocumentNode,
  useSubscription as useApolloSubscription,
} from '@apollo/client';

import { getDataWithoutTypename } from '@graphql/utils';

type UseSubscriptionCustomOptions = { includeTypename?: boolean };

const useSubscription = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables> &
    UseSubscriptionCustomOptions,
): SubscriptionResult<TData, TVariables> => {
  const { includeTypename, ...subscriptionOptions } = options || {};
  const res = useApolloSubscription<TData, TVariables>(
    query,
    subscriptionOptions,
  );

  const data = includeTypename
    ? res.data
    : getDataWithoutTypename<TData>(res.data);

  return {
    ...res,
    data,
  };
};

export default useSubscription;
