import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ARCHIVE_PRIMARY_RECORD_MUTATION = graphql(`
  mutation ArchivePrimaryRecord($data: ArchivePrimaryRecordsInput!) {
    archivePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useArchivePrimaryRecordMutation = () => {
  const [archivePrimaryRecordMutation, { loading: isArchivingPrimaryRecord }] =
    useMutation(ARCHIVE_PRIMARY_RECORD_MUTATION);

  const archivePrimaryRecord = (id: string) => {
    return archivePrimaryRecordMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: [id],
              },
            ],
            or: [],
          },
        },
      },
    });
  };

  return {
    archivePrimaryRecord,
    isArchivingPrimaryRecord,
  };
};
