import { Modal } from 'antd';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import PdfReader from '@common/components/PdfReader';
import { useDocumentInstance } from '@common/components/PdfReader/hooks';
import Spinner from '@common/components/Spinner';
import {
  fetchDocument,
  updateDocumentAnnotations,
} from '@modules/document/actions';
import DownloadDocument from '@modules/document/components/DownloadDocument';
import { isSupportedFile } from '@modules/document/utils/document-helpers';
import { getGraphqlPayload } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import AssignDocumentToParty from '../containers/AssignDocumentToParty';
import { usePartiesByName } from '../hooks';

const AssignOneDocumentModal = ({
  visible,
  documentId,
  onSuccess,
  onCancel,
}) => {
  const [documentData, setDocumentData] = useState(null);
  const { documentInstance } = useDocumentInstance();

  const dispatch = useDispatch();

  const { setSearchPartyName, setPartyData } = usePartiesByName();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const updateAnnotations = useCallback(
    debounce((annotations) => {
      dispatch(
        updateDocumentAnnotations({
          documentId,
          annotations,
        }),
      );
    }, 200),
    [documentId],
  );

  useEffect(() => {
    if (!documentInstance) {
      return;
    }

    const onAnnotationsDidSave = async () => {
      const json = await documentInstance.exportInstantJSON();
      updateAnnotations(json.annotations);
    };

    documentInstance.addEventListener(
      'annotations.didSave',
      onAnnotationsDidSave,
    );

    return () => {
      documentInstance.removeEventListener(
        'annotations.didSave',
        onAnnotationsDidSave,
      );
    };
  }, [documentInstance, updateAnnotations]);

  useEffect(() => {
    const fetchDocumentData = async () => {
      const res = await dispatch(fetchDocument({ id: documentId }));
      setDocumentData(getGraphqlPayload(res));
    };

    if (visible && Boolean(documentId)) {
      fetchDocumentData();
    } else {
      setDocumentData();
    }
  }, [dispatch, documentId, visible]);

  const handleAfterAssign = ({ documentId, partyId }) => {
    trackEvent('User assigned a document to a party');
    onSuccess();
  };

  return (
    <StyledModal
      destroyOnClose
      title={documentData?.friendlyName}
      open={visible}
      footer={null}
      onCancel={() => {
        setSearchPartyName([]);
        setPartyData([]);
        onCancel();
      }}
    >
      {!documentData ? (
        <Spinner />
      ) : (
        <StyledWrapper>
          <StyledContent>
            <AssignDocumentToParty
              documentId={documentId}
              onAfterAssign={handleAfterAssign}
              isShownOnModal
            />
          </StyledContent>
          <StyledPdfContainer>
            {isSupportedFile(documentData?.s3Key) ? (
              <PdfReader document={documentData} />
            ) : (
              <DownloadDocument document={documentData} />
            )}
          </StyledPdfContainer>
        </StyledWrapper>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 1024px !important;

  .ant-modal-body {
    padding: 0;
  }
`;

const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayBg};
`;

const StyledContent = styled.div`
  background-color: #fff;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPdfContainer = styled.div`
  width: 100%;
  height: 100vh;
  border-top: 1px solid #f0f0f0;
`;

export default AssignOneDocumentModal;
