import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  FETCH_DOCUMENTS_WIDGET,
  FETCH_PARTIES_WIDGET,
  FETCH_WIDGETS_DATA,
} from '../actions';

const initialState = {
  statusWidget: {},
  complianceWidget: {},
  complianceTrendWidget: {},
  partiesWidget: {},
  documentsWidget: {},
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_WIDGETS_DATA}_SUCCESS`: {
      const widgetsData = getGraphqlPayload(action);

      return R.merge(state, widgetsData);
    }
    case `${FETCH_PARTIES_WIDGET}_SUCCESS`: {
      const partiesData = getGraphqlPayload(action);

      return R.assoc('partiesWidget', partiesData, state);
    }
    case `${FETCH_DOCUMENTS_WIDGET}_SUCCESS`: {
      const documentsData = getGraphqlPayload(action);

      return R.assoc('documentsWidget', documentsData, state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default DashboardReducer;
