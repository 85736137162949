import { CloseCircleFilled } from '@ant-design/icons';
import { Alert, Divider } from 'antd';
import pluralize from 'pluralize';
import styled from 'styled-components';

import FileName from '@common/components/FileName/FileName';
import { downloadDocumentFile } from '@modules/document/utils/document-helpers';
import DocumentUploader from '@modules/request/components/DocumentUploader';

const ProjectAttachmentsTabContainer = ({
  uploadFiles,
  setUploadFiles,
  rejectedFiles,
  setRejectedFiles,
  attachments,
  setAttachments,
}) => {
  return (
    <>
      <ProjectAttachmentsTabContainer.Title>
        Attach one or more files to this project using the control below:
      </ProjectAttachmentsTabContainer.Title>
      <DocumentUploader
        onUpload={(acceptedFiles, rejectedFiles) => {
          setUploadFiles(acceptedFiles);
          setRejectedFiles(rejectedFiles);
        }}
      />
      <ProjectAttachmentsTabContainer.FilesWrapper>
        {rejectedFiles?.length > 0 && (
          <StyledAlert
            type="error"
            description={
              <>
                <p>
                  The files below cannot be uploaded because the file format is
                  not supported or is corrupted
                </p>
                <StyledRejectedFiles>
                  {rejectedFiles.map(({ file }) => (
                    <li key={`key_${file.name}`}>{file.name}</li>
                  ))}
                </StyledRejectedFiles>
              </>
            }
            icon={<CloseCircleFilled />}
            showIcon
          />
        )}
        {uploadFiles.length > 0 && (
          <>
            <ProjectAttachmentsTabContainer.FilesTitle>
              {pluralize('attachment', uploadFiles.length, true)} ready to be
              uploaded:
            </ProjectAttachmentsTabContainer.FilesTitle>
            <ProjectAttachmentsTabContainer.FileWrapper>
              {uploadFiles.map((file) => (
                <FileName
                  key={`key_${file.name}`}
                  filename={file.name}
                  onRemove={() =>
                    setUploadFiles(
                      uploadFiles.filter(
                        (uploadFile) => file.name !== uploadFile.name,
                      ),
                    )
                  }
                />
              ))}
            </ProjectAttachmentsTabContainer.FileWrapper>
          </>
        )}
      </ProjectAttachmentsTabContainer.FilesWrapper>
      <Divider />
      <ProjectAttachmentsTabContainer.FilesWrapper>
        {Boolean(attachments.length) && (
          <ProjectAttachmentsTabContainer.FileWrapper>
            {attachments.map((attachment) => (
              <FileName
                key={`key_${attachment._id}`}
                filename={attachment.friendlyName}
                onClick={() => downloadDocumentFile(attachment)}
                confirmRemoveMessage={`Are you sure you want to remove the attachment ${attachment.friendlyName}?`}
                onRemove={() =>
                  setAttachments(
                    attachments.filter((el) => el._id !== attachment._id),
                  )
                }
              />
            ))}
          </ProjectAttachmentsTabContainer.FileWrapper>
        )}
      </ProjectAttachmentsTabContainer.FilesWrapper>
    </>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 30px;
`;

const StyledRejectedFiles = styled.ul`
  padding-left: 30px;
`;

ProjectAttachmentsTabContainer.Title = styled.div`
  font-size: 13px;
  margin-bottom: 30px;
`;

ProjectAttachmentsTabContainer.FilesTitle = styled.div`
  font-size: 13px;
  margin: 10px 0;
`;

ProjectAttachmentsTabContainer.FilesWrapper = styled.div`
  margin: 16px 0;
`;

ProjectAttachmentsTabContainer.FileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > div {
    width: auto;
    margin-right: 5px;
  }
`;

export default ProjectAttachmentsTabContainer;
