import { KeyBindingUtil, getDefaultKeyBinding } from 'draft-js';

/**
 * Accepts editorState and entityType(optional)
 * @param {Object} editorState
 * @param {string | undefined} entityType
 * @returns {Array} entities.
 */
export const getEditorEntities = (editorState, entityType = null) => {
  const content = editorState.getCurrentContent();
  const entities = [];

  content.getBlocksAsArray().forEach((block) => {
    // eslint-disable-next-line fp/no-let
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      },
    );
  });

  return entities;
};

export const insertLinkKeyBinding = (e) => {
  if (e.keyCode === 75 && KeyBindingUtil.hasCommandModifier(e)) {
    return 'open-insert-link-modal';
  }

  return getDefaultKeyBinding(e);
};

/*
getRelativeParent and getOriginalPositionSuggestions are taken from original positionSuggestion from draft-js mention plugin
https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/mention/src/utils/positionSuggestions.ts
*/

const getRelativeParent = (element) => {
  if (!element) {
    return null;
  }

  const position = window
    .getComputedStyle(element)
    .getPropertyValue('position');
  if (position !== 'static') {
    return element;
  }

  return getRelativeParent(element.parentElement);
};

export const getOriginalPositionSuggestions = ({ decoratorRect, popover }) => {
  const relativeParent = getRelativeParent(popover.parentElement);

  if (relativeParent) {
    const relativeParentRect = relativeParent.getBoundingClientRect();
    return {
      scrollLeft: relativeParent.scrollLeft,
      scrollTop: relativeParent.scrollTop,
      left: decoratorRect.left - relativeParentRect.left,
      top: decoratorRect.bottom - relativeParentRect.top,
    };
  }
  return {
    scrollTop: window.pageYOffset || document.documentElement.scrollTop,
    scrollLeft: window.pageXOffset || document.documentElement.scrollLeft,
    top: decoratorRect.bottom,
    left: decoratorRect.left,
  };
};
