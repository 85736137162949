import { Button, Dropdown, Menu, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import moment from 'moment';
import * as R from 'ramda';
import styled from 'styled-components';

import RichTextEditor from '@common/components/RichTextEditor';
import DotsIcon from '@common/icons/Dots';

const CommentEventLog = ({
  members,
  log,
  commentEditorState,
  onCommentEditorChange,
  isEdit,
  setIsEdit,
  isLoading,
  updateComment,
  deleteComment,
  isAuthorComment,
}) => (
  <StyledWrapper>
    <StyledHeader>
      <div>
        {R.path(['data', 'userName'], log)
          ? `${R.path(['data', 'userName'], log)} (${R.path(
              ['data', 'userEmail'],
              log,
            )})`
          : R.path(['data', 'userEmail'], log)}{' '}
        commented on{' '}
        <Tooltip title={moment(log.createdAt).fromNow()}>
          {moment(log.createdAt).format('lll')}
        </Tooltip>
        <StyledCommentStatus>
          {R.path(['data', 'previousVersions', 'length'], log) > 0 &&
            !Boolean(R.prop('deletedAt', log)) &&
            'edited'}
          {Boolean(R.prop('deletedAt', log)) && 'deleted'}
        </StyledCommentStatus>
      </div>
      {isAuthorComment && !isEdit && !Boolean(R.prop('deletedAt', log)) && (
        <Dropdown
          data-cy="dropdownMenu"
          dropdownRender={() => (
            <Menu>
              <Menu.Item
                key="comment-event-log-edit"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="comment-event-log-delete"
                onClick={() => deleteComment()}
              >
                Delete
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
        >
          <StyledKebabMenuContainer data-cy="commentEventLogMenuButton">
            <DotsIcon />
          </StyledKebabMenuContainer>
        </Dropdown>
      )}
    </StyledHeader>
    <StyledContent>
      {!isEdit && (
        <StyledMessage>
          {Boolean(R.prop('deletedAt', log)) ? (
            <span className="deleted">This comment has been deleted.</span>
          ) : (
            <div
              data-cy="commentMessage"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  R.pathOr('', ['data', 'message'], log),
                ),
              }}
            />
          )}
        </StyledMessage>
      )}
      <RichTextEditor
        visible={isEdit}
        isValid
        minHeight={50}
        placeholder="Enter your comment..."
        editorState={commentEditorState}
        onChange={onCommentEditorChange}
        members={members}
      />
      {isEdit && (
        <StyledActions>
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => updateComment()}
          >
            Update comment
          </Button>
          <Button
            onClick={() => {
              setIsEdit(false);
            }}
          >
            Cancel
          </Button>
        </StyledActions>
      )}
    </StyledContent>
  </StyledWrapper>
);

const StyledWrapper = styled.div`
  position: relative;
  margin-left: -45px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;

  &::after,
  &::before {
    position: absolute;
    right: 100%;
    top: 18px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #f3f6f8;
    border-width: 7px;
    margin-top: -7px;
  }

  &::before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #e2e9ee;
    border-width: 8px;
    margin-top: -8px;
  }
`;

const StyledHeader = styled.div`
  padding-left: 18px;
  padding-right: 6px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  background-color: #f3f6f8;
`;

const StyledCommentStatus = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightBlack};
`;

const StyledKebabMenuContainer = styled.div`
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const StyledActions = styled.div`
  display: flex;
  padding: 10px 0;

  button {
    margin-right: 10px;
  }
`;

const StyledContent = styled.section`
  padding: 14px 15px 20px 19px;
  border-radius: 0 0 4px 4px;
  background-color: ${(props) => props.theme.colors.white};
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.grayishBlue};
  font-size: 13px;

  .deleted {
    opacity: 0.6;
  }

  br {
    margin-bottom: 1em;
    display: block;
    content: '.';
  }

  p {
    margin: 0;
  }

  ul,
  ol,
  dl {
    padding-left: 20px;
    margin: 10px 0;
  }

  h1,
  h2,
  h3 {
    margin: 10px 0;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }
`;

export default CommentEventLog;
