import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_PRIMARY_RECORDS_MUTATION = graphql(`
  mutation DeletePrimaryRecords($data: DeletePrimaryRecordsInput!) {
    deletePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDeletePrimaryRecordsMutation = () => {
  const [deletePrimaryRecordsMutation, { loading: isDeletingPrimaryRecords }] =
    useMutation(DELETE_PRIMARY_RECORDS_MUTATION);

  const deletePrimaryRecords = (filters?: ServerFilterInput) => {
    return deletePrimaryRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    deletePrimaryRecords,
    isDeletingPrimaryRecords,
  };
};
