export enum NotificationType {
  All = '',
  PartyCreated = 'party:created',
  DocumentUpload = 'document:upload',
  ConversationReply = 'conversation:reply',
  MentionInPartyComment = 'mention:in:party:comment',
  MentionInDocumentFlag = 'mention:in:document:flag',
  EmailBounce = 'email:bounce',
  Requests = 'bulk:send:requests',
}

type BaseNotification = {
  _id: string;
  recipient: string;
  readAt: string | null;
  createdAt: string;
  updatedAt: string;
  data: any;
  type: NotificationType;
};

export type PartyCreatedNotification = BaseNotification & {
  type: NotificationType.PartyCreated;
  data: {
    partyId: string;
    partyName: string;
    userName: string;
  };
};

export type DocumentUploadNotification = BaseNotification & {
  type: NotificationType.DocumentUpload;
  data: {
    partyId: string;
    partyName: string;
  };
};

export type EmailBounceNotification = BaseNotification & {
  type: NotificationType.EmailBounce;
  data: {
    messageId: string;
    messageRecipientEmail: string;
    messageType: string;
    partyConversationId: string;
    partyId: string;
  };
};

export type MentionInPartyNotification = BaseNotification & {
  type: NotificationType.MentionInPartyComment;
  data: {
    commentator: string;
    partyId: string;
    partyName: string;
  };
};

export type MentionInDocumentFlagNotification = BaseNotification & {
  type: NotificationType.MentionInDocumentFlag;
  data: {
    mentionedBy: string;
    documentFriendlyName: string;
    documentId: string;
  };
};

export type ConversationReplyNotification = BaseNotification & {
  type: NotificationType.ConversationReply;
  data: {
    partyId: string;
    partyConversationId: string;
    partyName: string;
  };
};

export type NotificationNode =
  | PartyCreatedNotification
  | EmailBounceNotification
  | DocumentUploadNotification
  | MentionInPartyNotification
  | MentionInDocumentFlagNotification
  | ConversationReplyNotification;
