import { Select } from 'antd';

import Spinner from '@common/components/Spinner';

const ProjectMultipleSelectView = ({
  initialProjects,
  loadedProjects,
  onChange,
  onSearch,
  highlightFirstElement,
  isLoading,
}) => (
  <Select
    data-cy="projectMultipleSelect"
    value={initialProjects.map((project) => ({
      value: project._id,
      label: project.name,
    }))}
    mode="multiple"
    style={{ width: '100%' }}
    placeholder="Search projects..."
    notFoundContent={isLoading ? <Spinner /> : null}
    filterOption={false}
    onChange={onChange}
    onSearch={onSearch}
    onFocus={() => onSearch('')}
    labelInValue
  >
    {loadedProjects.map((project, i) => (
      <Select.Option
        key={project._id}
        value={project._id}
        {...(highlightFirstElement && !i ? { style: { fontWeight: 500 } } : {})}
      >
        {project.name}
      </Select.Option>
    ))}
  </Select>
);

export default ProjectMultipleSelectView;
