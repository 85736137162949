import moment from 'moment';
import * as R from 'ramda';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import DocumentLinkName from '@common/components/DocumentLinkName';
import FlagIcon from '@common/icons/FlagIcon';
import VerifiedIcon from '@common/icons/VerifiedIcon';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import { BadgeColors, BadgeSize } from '../model/badges';

const colorVariants = {
  red: {
    bg: BadgeColors.redBg,
    border: BadgeColors.redBorder,
    icon: BadgeColors.redIcon,
  },
  blue: {
    bg: BadgeColors.blueBg,
    border: BadgeColors.blueBorder,
    icon: BadgeColors.blueIcon,
  },
  green: {
    bg: BadgeColors.greenBg,
    border: BadgeColors.greenBorder,
    icon: BadgeColors.greenIcon,
  },
  yellow: {
    bg: BadgeColors.yellowBg,
    border: BadgeColors.yellowBorder,
    icon: BadgeColors.yellowIcon,
  },
};

const DocumentSeverityColorMap = {
  low: 'blue',
  medium: 'yellow',
  high: 'red',
};

/**
 *  @param {Boolean} isStatic - shows if this block using in tooltip or static
 *  @param {Boolean} isShowFlagInfo - show by default flag data and icons even if exist verification instance
 */

const BadgePopover = ({
  documentData,
  flagData,
  verificationData,
  isShowFlagInfo,
  isStatic,
  isShortVersion = false,
  withButtons,
  editVerificationHandle = () => {},
  removeVerificationHandle = () => {},
  editFlagHandle = () => {},
  removeFlagHandle = () => {},
}) => {
  const themeContext = useContext(ThemeContext);
  const isVerified = !R.isEmpty(verificationData);
  const isShowVerificationData = isVerified && !isShowFlagInfo;
  const verificationOwner = R.path(
    ['user', 'profile', 'name'],
    verificationData,
  );
  const staticOwnerName = R.propOr('', 'authorName', flagData);
  const flagOwner = R.pathOr(
    staticOwnerName,
    ['user', 'profile', 'name'],
    flagData,
  );

  const colorVariant =
    DocumentSeverityColorMap[R.propOr('', 'severityLevel', flagData)];
  const wrapperColorVariant = isShowVerificationData
    ? colorVariants.green
    : R.prop(colorVariant, colorVariants);
  const colorIcon = isShowVerificationData
    ? BadgeColors.greenIcon
    : R.path([colorVariant, 'icon'], colorVariants);
  const tooltipDetails = isShowVerificationData
    ? `Verification logged on ${
        Boolean(R.propOr('', 'verifiedOn', verificationData))
          ? moment(R.propOr('', 'verifiedOn', verificationData)).format('ll')
          : 'n/a'
      } by ${
        verificationOwner || R.pathOr('', ['user', 'email'], verificationData)
      }.`
    : `Flag added on ${
        R.has('addedOn', flagData)
          ? moment(R.propOr('', 'addedOn', flagData)).format('ll')
          : 'n/a'
      }
          by ${flagOwner || R.pathOr('', ['user', 'email'], flagData)}.`;
  const issuerName = R.pathOr('', ['issuedBy', 'name'], documentData);

  return (
    <BadgePopover.Wrapper isStatic={isStatic} {...wrapperColorVariant}>
      <BadgePopover.Header>
        <BadgePopover.IconPopover isStatic={isStatic}>
          {isShowVerificationData ? (
            <VerifiedIcon
              color={themeContext.colors[colorIcon]}
              size={BadgeSize.Medium}
            />
          ) : (
            <FlagIcon
              color={themeContext.colors[colorIcon]}
              size={BadgeSize.Medium}
            />
          )}
        </BadgePopover.IconPopover>
        <BadgePopover.PopoverDetails>
          {tooltipDetails}
          <br />
          {!isShortVersion && (
            <>
              Document:{' '}
              <DocumentLinkName document={documentData}>
                <ins>{R.prop('documentName', documentData)}</ins>
              </DocumentLinkName>
              {issuerName ? ` issued by ${issuerName}` : ''}
            </>
          )}
        </BadgePopover.PopoverDetails>
        {withButtons && (
          <HideForViewerRole>
            <BadgePopover.Buttons>
              <BadgePopover.Button
                onClick={() => {
                  if (isShowVerificationData) {
                    editVerificationHandle();
                    return;
                  }
                  editFlagHandle();
                }}
              >
                Edit
              </BadgePopover.Button>
              <BadgePopover.DotDivider>·</BadgePopover.DotDivider>
              <BadgePopover.Button
                onClick={() => {
                  if (isShowVerificationData) {
                    removeVerificationHandle();
                    return;
                  }
                  removeFlagHandle();
                }}
              >
                Remove
              </BadgePopover.Button>
            </BadgePopover.Buttons>
          </HideForViewerRole>
        )}
      </BadgePopover.Header>
      <BadgePopover.Description>
        <div
          dangerouslySetInnerHTML={{
            __html: `<blockquote>${
              verificationData?.notes || flagData?.notes
            }</blockquote>`,
          }}
        ></div>
      </BadgePopover.Description>
    </BadgePopover.Wrapper>
  );
};

BadgePopover.Wrapper = styled.div`
  position: relative;
  ${({ isStatic }) =>
    isStatic ? `padding: 8px 12px 8px 34px;` : ` padding: 0 0 10px 22px;`}
  ${({ isStatic }) => isStatic && `margin: 15px 0;`}
  ${({ isStatic }) => isStatic && `box-shadow: rgba(0,0,0,0.2) 1px 1px 0px;`}
  background: ${({ bg, theme, isStatic }) =>
    theme.colors[isStatic ? bg : 'white']};
  border: 1px solid
    ${({ border, theme, isStatic }) =>
      theme.colors[isStatic ? border : 'white']};
  border-radius: 4px;
  border-top: white;
  border-right: white;
  border-bottom: white;
  border-left-width: 4px;
`;

BadgePopover.Header = styled.div`
  display: flex;
  width: 100%;
`;

BadgePopover.IconContent = styled.div`
  display: flex;
`;

BadgePopover.IconPopover = styled.div`
  position: absolute;
  left: ${({ isStatic }) => (isStatic ? '10px' : '-2px')};
  top: ${({ isStatic }) => (isStatic ? '10px' : '3px')};
`;

BadgePopover.PopoverDetails = styled.p`
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 3px;
`;

BadgePopover.Description = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
  margin: 0;
  padding: 0 40px 0 0;

  & > blockquote {
    display: block;
    font-weight: 300;
    overflow-wrap: break-word;
    padding: 0;
    margin: 0;
    font-style: italic;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

BadgePopover.Close = styled.div`
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;

  & > i {
    color: #e8edf3;
  }
`;

BadgePopover.Buttons = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;

BadgePopover.DotDivider = styled.span`
  margin-left: 4px;
  margin-right: 4px;
  margin-top: -3px;
`;

BadgePopover.Button = styled.span`
  height: 20px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;

  &&:hover {
    text-decoration: none;
  }
`;

BadgePopover.defaultProps = {
  defaultVisible: false,
  sizeIcon: 'Medium',
};

export default BadgePopover;
