import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_QUICKBOOKS_AUTH_LINK = `${namespace}/FETCH_QUICKBOOKS_AUTH_LINK`;

const FETCH_QUICKBOOKS_AUTH_LINK_QUERY = gql`
  query getQuickbooksAuthLink {
    getQuickbooksAuthLink
  }
`;

export const fetchQuickbooksAuthLink = () => ({
  type: FETCH_QUICKBOOKS_AUTH_LINK,
  payload: {
    key: 'getQuickbooksAuthLink',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_QUICKBOOKS_AUTH_LINK_QUERY,
    },
  },
});
