import { useMemo } from 'react';

import { useMutation, useQuery } from '@graphql/hooks';
import type { ViewsSelectorQuery } from '@graphql/types/graphql';

import { NetworkStatus } from '@apollo/client';
import {
  CREATE_VIEW_MUTATION,
  DELETE_VIEW_MUTATION,
  UPDATE_VIEW_MUTATION,
} from '../../mutations';
import { VIEWS_SELECTOR_QUERY } from './queries';

type UseViewsOptions = {
  viewsType?: 'parties' | 'projects' | 'documents';
  onFetchCompleted?: (data: ViewsSelectorQuery['listReports']) => void;
};

const useViews = ({ viewsType, onFetchCompleted }: UseViewsOptions) => {
  const { data, error, refetch, networkStatus } = useQuery(
    VIEWS_SELECTOR_QUERY,
    {
      //? it will always take the date from cache after it is filled
      fetchPolicy: 'cache-first',
      //? calls to refetch will go to network and update cache
      nextFetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ listReports: listViews }) => {
        onFetchCompleted?.(listViews);
      },
    },
  );

  const [createView, { loading: createViewLoading }] = useMutation(
    CREATE_VIEW_MUTATION,
    {
      onCompleted: () => {
        refetch();
      },
    },
  );
  const [updateView, { loading: updateViewLoading }] = useMutation(
    UPDATE_VIEW_MUTATION,
    {
      onCompleted: () => {
        refetch();
      },
    },
  );

  const [deleteView, { loading: deleteViewLoading }] = useMutation(
    DELETE_VIEW_MUTATION,
    {
      onCompleted: () => {
        refetch();
      },
    },
  );

  const viewsByType = useMemo(() => {
    if (!Boolean(viewsType)) return data?.listReports;

    return data?.listReports.filter((view) => view.type === viewsType);
  }, [data, viewsType]);

  return {
    data: viewsByType,
    refetchViews: refetch,
    createView,
    deleteView,
    updateView,
    loading: networkStatus === NetworkStatus.loading,
    createViewLoading,
    updateViewLoading,
    deleteViewLoading,
    error,
  };
};

export default useViews;
