import { graphql } from '@graphql/types';

export const AUTOMATIONS_QUERY = graphql(`
  query PrimaryRecord_Automations($primaryRecordInput: PrimaryRecordInput!) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      automations {
        _id
        code
        name
        actionsCount
        branches {
          nodes {
            _id
            trigger {
              event
              type
              filter
            }
            steps {
              ... on Action {
                type
                job
                data {
                  ... on EmailActionData {
                    emailTemplate {
                      _id
                    }
                  }
                }
              }
              ... on Wait {
                type
                duration
              }
            }
          }
        }
        status
        processing
        scheduledActions {
          status
          scheduledAt
          executedAt
          result
        }
      }
    }
  }
`);
