import { Popover } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import type { Document } from '@graphql/types/graphql';

type DocumentVerificationProps = {
  verification: Document['verification'];
};

type PopoverProps = {
  date: string;
  userMail?: string;
  notes?: string;
};

const PopoverContent = ({ date, userMail, notes }: PopoverProps) => {
  return (
    <>
      <StyledText>
        Verification logged on {moment(date).format('ll')} by {userMail}
      </StyledText>
      {notes && (
        <div
          dangerouslySetInnerHTML={{
            __html: `<blockquote>${notes}</blockquote>`,
          }}
        />
      )}
    </>
  );
};

export const DocumentVerification = ({
  verification,
}: DocumentVerificationProps) => {
  if (!verification) {
    return <StyledNotVerifiedText>Not verified</StyledNotVerifiedText>;
  }

  return (
    <Popover
      content={
        <PopoverContent
          date={verification.verifiedOn}
          userMail={verification.user?.email}
          //@ts-expect-error - is of type Maybe<string> but should be string | undefined only
          notes={verification.notes}
        />
      }
    >
      <StyledVerifiedText>Verified</StyledVerifiedText>
    </Popover>
  );
};

const StyledText = styled.p`
  font-size: 12px;
`;

const StyledNotVerifiedText = styled.span`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.grayText};
`;
const StyledVerifiedText = styled.span`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.green};
`;
