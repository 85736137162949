import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTY_REQUIREMENTS = `${namespace}/FETCH_PARTY_REQUIREMENTS`;

const FETCH_PARTY_REQUIREMENTS_QUERY = gql`
  query getPartyData($id: String!, $byContext: Boolean) {
    getPartyData(id: $id, byContext: $byContext) {
      _id
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        createdAt
        updatedAt
      }
    }
  }
`;

export const fetchPartyRequirements = (id, byContext = true) => ({
  type: FETCH_PARTY_REQUIREMENTS,
  payload: {
    key: 'getPartyData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_REQUIREMENTS_QUERY,
      variables: { id, byContext },
    },
  },
});
