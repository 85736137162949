import { gql } from '@apollo/client';

import type {
  AttributeType,
  ComplianceProfileContext,
  operators,
} from '../constants';

export const COMPLIANCE_PROFILE_FRAGMENT = gql`
  fragment ComplianceProfileFrag on ComplianceProfile {
    _id
    name
    context
    contextId
    baseComplianceProfile
    rules {
      _id
      moduleId
      moduleLabel
      subjectId
      subjectLabel
      attributeId
      attributeLabel
      attributeType
      attributeDescription
      matchingCriteria
      automaticallyDetected
      masterDocumentAttributeId
      operator
      targetValue
      isActive
      complianceProfile
      invalidatedBy
      invalidationScope
    }
    documentChecklists {
      _id
      type {
        _id
        name
        pluralName
        isSystemType
      }
      attributeId
      attributeLabel
      complianceProfile
      isActive
      createdAt
      updatedAt
    }
    partiesCount
    projectsCount
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export type DocumentChecklist = {
  _id?: string;
  type: string | null;
  attributeId: string;
  attributeLabel: string;
  complianceProfile: ComplianceProfile['_id'] | null;
  isActive: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type Rule = {
  _id: string;
  moduleId: string;
  moduleLabel: string;
  subjectId: string;
  subjectLabel: string;
  attributeId: string;
  attributeLabel: string;
  attributeType: (typeof AttributeType)[keyof typeof AttributeType];
  attributeDescription: string | '';
  matchingCriteria: string;
  automaticallyDetected: string | '';
  masterDocumentAttributeId: string;
  operator: (typeof operators)[keyof typeof operators][number];
  targetValue: string;
  isActive: boolean;
  complianceProfile: string;
  createdAt: Date;
  updatedAt: Date;
  invalidatedBy: string[];
  invalidationScope: null | string;
};

export type ComplianceProfile = {
  _id: string;
  name: string;
  context: (typeof ComplianceProfileContext)[keyof typeof ComplianceProfileContext];
  contextId: string | '';
  baseComplianceProfile: string | null;
  organization: string;
  rules: Rule[];
  documentChecklists: DocumentChecklist[];
  partiesCount: number;
  projectsCount: number;
  deletedAt: Date | null;
  deletedBy: string | null;
  createdAt: Date;
  updatedAt: Date;
};
