import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';

const ConversationLinkName = ({ children, conversation }) => {
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);

  if (!children) return null;

  return (
    <Tooltip title="Open Conversation">
      <Link
        to={{
          pathname: `${organizationNamespace}/parties/${
            conversation.partyId
          }/messages/${conversation._id || ''}`,
        }}
      >
        {children}
      </Link>
    </Tooltip>
  );
};

export default ConversationLinkName;
