import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { TEXT_SNIPPETS_FRAGMENT } from '../fragments';

export const UPDATE_TEXT_SNIPPET = `${namespace}/UPDATE_TEXT_SNIPPET`;

const UPDATE_TEXT_SNIPPET_MUTATION = gql`
  mutation updateTextSnippet($payload: UpdateTextSnippetInput!) {
    updateTextSnippet(data: $payload) {
      ...TextSnippet
    }
  }
  ${TEXT_SNIPPETS_FRAGMENT}
`;

export const updateTextSnippet = (payload) => ({
  type: UPDATE_TEXT_SNIPPET,
  payload: {
    key: 'updateTextSnippet',
    graphql: {
      mutation: UPDATE_TEXT_SNIPPET_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
