import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const GET_ORGANIZATION_INFO = `${namespace}/GET_ORGANIZATION_INFO`;

const GET_ORGANIZATION_INFO_QUERY = gql`
  query getOrganizationInfo($slug: String, $domain: String) {
    getOrganizationInfo(slug: $slug, domain: $domain) {
      name
      slugifyName
      logo
      domains
      authProvider {
        type
        id
        tenantId
      }
    }
  }
`;

export const getOrganizationInfo = ({ slug, domain }) => ({
  type: GET_ORGANIZATION_INFO,
  payload: {
    key: 'getOrganizationInfo',
    graphql: {
      fetchPolicy: 'no-cache',
      query: GET_ORGANIZATION_INFO_QUERY,
      variables: { slug, domain },
    },
  },
});
