import { LoadingOutlined } from '@ant-design/icons';
import type { PartyAutomation } from '@modules/party/utils';
import { Popover, Switch } from 'antd';
import type React from 'react';
import styled from 'styled-components';
import {
  MISSING_AUTOMATION_ACTIONS_MESSAGE,
  MISSING_CONTACT_MESSAGE,
} from '../../primary-records/containers/SidebarAutomations/constants';

interface AutomationSwitchProps {
  automation: PartyAutomation;
  isPrimaryContactInvalid: boolean;
  onAutomationChange: (args: { value: boolean; code: string }) => void;
  isAutomationChanging?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const AutomationSwitch = ({
  automation,
  isPrimaryContactInvalid,
  onAutomationChange,
  isAutomationChanging,
  children,
  disabled = false,
}: AutomationSwitchProps) => {
  const isDisabledForMissingInfo =
    isPrimaryContactInvalid || !automation.isEnablable;
  const popoverContent = isPrimaryContactInvalid
    ? MISSING_CONTACT_MESSAGE
    : MISSING_AUTOMATION_ACTIONS_MESSAGE;

  const handleChange = (value: boolean) => {
    onAutomationChange({ value, code: automation.code });
  };

  const switchContent = (
    <StyledSwitchWrapper data-cy={`${automation.code}Wrapper`}>
      <StyledSwitch
        disabled={disabled || isDisabledForMissingInfo}
        checked={automation.isEnabled}
        size="small"
        onChange={handleChange}
        data-cy={`${automation.code}Switch`}
      />
      <StyledSwitchContent>
        {isAutomationChanging ? (
          <StyledLoadingStatus data-cy={`${automation.code}SwitchLoader`} />
        ) : (
          children || <div className="title">{automation.title}</div>
        )}
      </StyledSwitchContent>
    </StyledSwitchWrapper>
  );

  if (!disabled && isDisabledForMissingInfo) {
    return <Popover content={popoverContent}>{switchContent}</Popover>;
  }

  return switchContent;
};

const StyledSwitchWrapper = styled.div`
  margin-top: 8px;
  display: flex;
`;

const StyledSwitch = styled(Switch)`
  margin-top: 3px;
`;

const StyledSwitchContent = styled.div`
  margin-left: 10px;

  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
    color: ${(props) => props.theme.colors.lightTextGray};
  }
`;

const StyledLoadingStatus = styled(LoadingOutlined)`
  margin-top: 5px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.blue};
`;
