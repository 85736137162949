import { gql } from '@apollo/client';

export const PROJECT_FOR_LIST_FRAGMENT = gql`
  fragment ProjectForList on Project {
    _id
    name
    description
    isActive
    organization
    startDate
    endDate
    parties {
      _id
    }
    customFields {
      nodes {
        customField {
          _id
          type
          color
          name
          options {
            key
            value
          }
        }
        value
      }
      totalCount
    }
    complianceStatus {
      status
      compliantPartiesCount
      totalPartiesCount
    }
    createdAt
    updatedAt
    externalIds
  }
`;
