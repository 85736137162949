import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { setAuthenticatedAppReady } from '@modules/app';

import { trackEvent } from '@common/utils/track-helpers';
import { setActiveOrganization, updateOrganizations } from '../../../actions';

type CreateOrganizationParams = {
  name: string;
};

const CREATE_ORGANIZATION_MUTATION = graphql(`
  mutation CreateOrganization($payload: CreateOrganizationInput!) {
    createOrganization(data: $payload) {
      ...Organization
    }
  }
`);

export const useCreateOrganization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createOrganizationMutation, { loading }] = useMutation(
    CREATE_ORGANIZATION_MUTATION,
    {
      onCompleted: ({ createOrganization: organization }) => {
        dispatch(setActiveOrganization(organization));
        dispatch(updateOrganizations(organization));
        navigate('/');
        dispatch(setAuthenticatedAppReady());

        message.success(`${organization?.name} created successfully.`);

        trackEvent('User created an organization');
      },
      onError: (error) => {
        message.success(`Unable to update organization ${error.message}`);
      },
    },
  );

  const createOrganization = ({ name }: CreateOrganizationParams) => {
    createOrganizationMutation({
      variables: {
        payload: {
          name,
        },
      },
    });
  };

  return {
    createOrganization,
    loading,
  };
};
