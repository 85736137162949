import type { Contact } from '@graphql/types/graphql';
import { ContactsInput } from '@modules/contacts/components/ContactsInput';
import { Form, type FormInstance, Input } from 'antd';

export const ContactModalFormItems = ({
  form,
}: {
  form: FormInstance<{
    contactEmail: string;
    contactName?: string;
    contactId?: string;
  }>;
}) => {
  const contactId = Form.useWatch('contactId', form);
  const contactEmail = Form.useWatch('contactEmail', form);
  const handleOnSelect = (contact: Contact) => {
    form.setFieldsValue({
      contactId: contact._id,
      contactName: contact.contactPersonName ?? undefined,
      contactEmail: contact.email ?? undefined,
    });
  };

  const handleOnChange = (searchedValue: string) => {
    form.setFieldsValue({
      contactEmail: searchedValue,
      contactName: undefined,
      contactId: undefined,
    });
  };
  const handleOnClear = () => {
    form.setFieldsValue({
      contactId: undefined,
      contactName: undefined,
      contactEmail: undefined,
    });
  };

  return (
    <>
      <Form.Item
        name="contactEmail"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please enter a valid email',
            type: 'email',
          },
        ]}
      >
        <ContactsInput
          onSelect={handleOnSelect}
          onChange={handleOnChange}
          onClear={handleOnClear}
          email={contactEmail}
          placeholder="Search an existing contact email or type a new one..."
        />
      </Form.Item>
      <Form.Item name="contactName" label="Name">
        <Input
          data-cy="contactName"
          disabled={!!contactId}
          type="text"
          placeholder="Enter contact name..."
        />
      </Form.Item>
      <Form.Item hidden={true} name="contactId" label="Id">
        <Input type="text" />
      </Form.Item>
    </>
  );
};
