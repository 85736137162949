import { excludeNullableAndEmptyString } from '@common/utils/filter-helpers';
import { PartyFilterFields } from '@modules/party/constants';

import type { TagsFilterType } from './TagsFilter';
import type { TagsFilterOperator } from './constants';

type NormalizeParams = {
  tags?: TagsFilterType;
} & Record<string, unknown>;

type ParseParams = {
  tags?: string;
} & Record<string, unknown>;

export const stringifyTagsFilter = ({
  operator,
  value = [],
}: TagsFilterType) => {
  return value.length ? `${operator}-${value.join('-')}` : '';
};

export const splitTagsFilter = (tagFilter: string) => {
  const [operator, ...value] = tagFilter.split('-');

  const sanitizedValues = excludeNullableAndEmptyString(value);

  return {
    operator: operator as TagsFilterOperator,
    value: sanitizedValues as string[],
  };
};

export const normalizeTagsFilter = (params: NormalizeParams = {}) => {
  const tagsFilter = params?.[PartyFilterFields.Tags];

  if (!tagsFilter) {
    return {};
  }
  const stringifiedFilter = stringifyTagsFilter(tagsFilter);

  return { [PartyFilterFields.Tags]: stringifiedFilter };
};

export const parseTagsFilter = (params: ParseParams) => {
  const tagsFilter = params?.[PartyFilterFields.Tags];

  if (tagsFilter) {
    const parsedFilter = splitTagsFilter(tagsFilter);

    return {
      [PartyFilterFields.Tags]: parsedFilter,
    };
  }

  return {};
};

export const getSanitzedValues = (value?: string | string[]): string[] => {
  if (!value) return [];
  return Array.isArray(value) ? value.flat() : [value];
};
