import { Popover } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import type { Document } from '@graphql/types/graphql';

type FlagSeverity = keyof typeof DocumentSeverityColorMap;

type DocumentSeverityProps = {
  flag: Document['flag'];
};

type PopoverProps = {
  date: string;
  userMail?: string;
  notes?: string;
};

const DocumentSeverityColorMap = {
  low: 'blue',
  medium: 'yellow',
  high: 'red',
  default: 'black',
};

const getColorBySeverity = (severity: FlagSeverity) => {
  return DocumentSeverityColorMap[severity];
};

const PopoverContent = ({ date, userMail, notes }: PopoverProps) => {
  return (
    <>
      <StyledText>
        Flag added on {moment(date).format('ll')} by {userMail}
      </StyledText>
      {notes && (
        <div
          dangerouslySetInnerHTML={{
            __html: `<blockquote>${notes}</blockquote>`,
          }}
        />
      )}
    </>
  );
};

export const DocumentSeverity = ({ flag }: DocumentSeverityProps) => {
  if (!flag) {
    return '-';
  }

  /// Assign a default value if severityLevel is not one of the valid options
  const severityLevel = (flag?.severityLevel || 'default') as FlagSeverity;

  return (
    <Popover
      content={
        <PopoverContent date={flag.addedOn} userMail={flag.user?.email} />
      }
    >
      <StyledSeverity $severityLevel={severityLevel}>
        {flag?.severityLevel}
      </StyledSeverity>
    </Popover>
  );
};

const StyledText = styled.p`
  font-size: 12px;
`;

const StyledSeverity = styled.span<{ $severityLevel: FlagSeverity }>`
  text-transform: capitalize;
  color: ${({ $severityLevel }) => getColorBySeverity($severityLevel)};
`;
