import { Select } from 'antd';
import styled from 'styled-components';

import { DisableForViewerRole } from '@modules/organization-member/containers/DisableForRoles';

const { Option } = Select;

const DocumentTypeSelect = ({ types, documentTypes, onChange }) => (
  <DisableForViewerRole>
    <DocumentTypeSelect.Wrapper>
      <DocumentTypeSelect.Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Select document type..."
        value={documentTypes}
        onChange={onChange}
      >
        {types.map((type) => (
          <Option key={type._id} value={type._id}>
            {type.name}
          </Option>
        ))}
      </DocumentTypeSelect.Select>
    </DocumentTypeSelect.Wrapper>
  </DisableForViewerRole>
);

DocumentTypeSelect.Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.grayishBlue};
  font-size: 14px;
  font-weight: 500;
`;

DocumentTypeSelect.Select = styled(Select)`
  width: 100%;
  font-size: 12px;

  .ant-select-selection--multiple {
    .ant-select-selection__rendered {
      margin-left: 3px;
    }

    .ant-select-selection__choice {
      background-color: rgb(250, 250, 250);
    }
  }
`;

export default DocumentTypeSelect;
