import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_SENDER_FRAGMENT } from '../fragments';

export const SELECT_EMAIL_SENDER = `${namespace}/SELECT_EMAIL_SENDER`;

const SELECT_EMAIL_SENDER_MUTATION = gql`
  mutation selectEmailSender($_id: String!) {
    selectEmailSender(_id: $_id) {
      ...EmailSender
    }
  }
  ${EMAIL_SENDER_FRAGMENT}
`;

export const selectEmailSender = (_id) => ({
  type: SELECT_EMAIL_SENDER,
  payload: {
    key: 'selectEmailSender',
    graphql: {
      mutation: SELECT_EMAIL_SENDER_MUTATION,
      variables: {
        _id,
      },
    },
  },
});
