import { gql } from '@apollo/client';

import { EMAIL_LAYOUT_FRAGMENT } from '@modules/email-layout/fragments';
import { EMAIL_SENDER_FRAGMENT } from '@modules/email-sender/fragments';

export const EMAIL_TEMPLATE_FRAGMENT = gql`
  fragment EmailTemplate on EmailTemplate {
    _id
    name
    organization
    layout {
      ...EmailLayout
    }
    sender {
      ...EmailSender
    }
    subject
    regions
    type
    deletedAt
    deletedBy
  }
  ${EMAIL_SENDER_FRAGMENT}
  ${EMAIL_LAYOUT_FRAGMENT}
`;
