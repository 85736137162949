import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_TEMPLATE_FRAGMENT } from '../fragments';

export const DELETE_EMAIL_TEMPLATE = `${namespace}/DELETE_EMAIL_TEMPLATE`;

const DELETE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation deleteEmailTemplate($_id: ID!) {
    deleteEmailTemplate(_id: $_id) {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const deleteEmailTemplate = (_id) => ({
  type: DELETE_EMAIL_TEMPLATE,
  payload: {
    key: 'deleteEmailTemplate',
    graphql: {
      mutation: DELETE_EMAIL_TEMPLATE_MUTATION,
      variables: {
        _id,
      },
    },
  },
});
