import { createUploadLink } from 'apollo-upload-client';

import AppConfig from '@config/AppConfig';

const uploadLink = createUploadLink({
  uri: AppConfig.graphqlApiUrl,
  // ref https://www.apollographql.com/docs/apollo-server/security/cors/#graphql-upload
  headers: { 'Apollo-Require-Preflight': 'true' },
});

export default uploadLink;
