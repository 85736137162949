import { Empty, Input, type InputRef } from 'antd';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { InifiniteScrollList } from '@common/components/InfiniteScrollList';
import OrganizationAvatar from '@common/components/OrganizationAvatar';
import type { Organization } from '@graphql/types/graphql';
import { useOrganizations } from './hooks';

type OrganizationListProps = {
  onClick: (params: Organization) => void;
};

export const OrganizationList = ({ onClick }: OrganizationListProps) => {
  const {
    organizations,
    fetchOrganizations,
    fetchMoreOrganizations,
    isLoading,
    isFetchingMore,
    total,
  } = useOrganizations();

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const handleOnInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    fetchOrganizations(target?.value);
  };

  return (
    <>
      <StyledInputWrapper>
        <Input
          ref={inputRef}
          placeholder="Search for a workspace..."
          onChange={handleOnInputChange}
        />
      </StyledInputWrapper>
      <StyledListWrapper id="scrollableDiv">
        {!isLoading && organizations.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <InifiniteScrollList
            list={organizations}
            hasMoreItems={total > organizations.length}
            loading={isFetchingMore}
            loadMoreItems={() =>
              fetchMoreOrganizations(inputRef.current?.input?.value)
            }
          >
            {(organization) => {
              const { name, icon } = organization;

              return (
                <StyledItem
                  key={organization.id}
                  onClick={() => onClick(organization)}
                >
                  <StyledOptionContent>
                    <OrganizationAvatar name={name} size={24} iconUrl={icon} />
                    <div className="label">{name}</div>
                  </StyledOptionContent>
                </StyledItem>
              );
            }}
          </InifiniteScrollList>
        )}
      </StyledListWrapper>
    </>
  );
};

const StyledInputWrapper = styled.div`
  padding: 6px 8px;
`;

const StyledListWrapper = styled.section`
  height: 140px;
  overflow: hidden;
`;

const StyledItem = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBGGray};
  }
`;

const StyledOptionContent = styled.div`
  display: flex;
  align-items: center;

  .label {
    margin-left: 10px;
    width: 208px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
