const BuildingIcon = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 0.5H10.5C10.8978 0.5 11.2794 0.658035 11.5607 0.93934C11.842 1.22064 12 1.60218 12 2V15.5H0V2C0 1.60218 0.158035 1.22064 0.43934 0.93934C0.720644 0.658035 1.10218 0.5 1.5 0.5ZM1.5 14H4.5V11H7.5V14H10.5V2H1.5V14ZM6.75 3.5H9V5.75H6.75V3.5ZM3 7.25H5.25V9.5H3V7.25ZM6.75 7.25H9V9.5H6.75V7.25ZM3 3.5H5.25V5.75H3V3.5Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

export default BuildingIcon;
