import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const DELETE_SCHEDULED_REPORT = `${namespace}/DELETE_SCHEDULED_REPORT`;

const DELETE_SCHEDULED_REPORT_MUTATION = gql`
  mutation deleteScheduledReport($scheduledReportId: ObjectId!) {
    deleteScheduledReport(scheduledReportId: $scheduledReportId) {
      _id
      report
    }
  }
`;

export const deleteScheduledReport = (scheduledReportId) => ({
  type: DELETE_SCHEDULED_REPORT,
  payload: {
    key: 'deleteScheduledReport',
    graphql: {
      mutation: DELETE_SCHEDULED_REPORT_MUTATION,
      variables: {
        scheduledReportId,
      },
    },
  },
});
