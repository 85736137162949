import * as Sentry from '@sentry/react';
import { REHYDRATE } from 'redux-persist';
import { eventChannel } from 'redux-saga';
import {
  all,
  fork,
  put,
  putResolve,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { AUTH_CONSUME, LOGIN, LOGOUT, logout } from '../../auth/actions';
import { PROPAGATE_LOGOUT_TO_ALL_TABS } from '../../auth/constants';
import { getEmail, getId, getIsAuthenticated } from '../../auth/selectors';
import { fetchMe } from '../../user/actions';
import { getUser } from '../../user/selectors';

function* onStorageChange(event) {
  const { key, newValue } = event;

  if (key === PROPAGATE_LOGOUT_TO_ALL_TABS && newValue) {
    yield put(logout());
  }
}

function* appBootstrapSaga() {
  const user = yield select(getUser);

  // the following is needed to sync logged out between tabs
  localStorage?.removeItem(PROPAGATE_LOGOUT_TO_ALL_TABS);

  const storageChannel = eventChannel((emitter) => {
    window.addEventListener('storage', emitter);

    return () => {
      window.removeEventListener('storage', emitter);
    };
  });

  yield takeEvery(storageChannel, onStorageChange);
  yield takeEvery(`${LOGOUT}_SUCCESS`, () => storageChannel.close());

  if (!user) {
    yield putResolve(fetchMe());
  }
}

function* setAuthenticatedAppReadySaga() {
  // eslint-disable-next-line fp/no-loops
  while (true) {
    yield take([
      `${LOGIN}_SUCCESS`, // deprecated
      `${AUTH_CONSUME}_SUCCESS`,
    ]);

    const isAuthenticated = yield select(getIsAuthenticated);

    const id = yield select(getId);
    const email = yield select(getEmail);

    if (isAuthenticated) {
      Sentry.getCurrentScope().setUser({ id, email });
    }
  }
}

function* appSagas() {
  yield all([
    takeLatest(REHYDRATE, appBootstrapSaga),
    fork(setAuthenticatedAppReadySaga),
  ]);
}

export default appSagas;
