import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const ADD_PARTIES_TO_PROJECT = `${namespace}/ADD_PARTIES_TO_PROJECT`;

const ADD_PARTIES_TO_PROJECT_MUTATION = gql`
  mutation addPartiesToProject($data: AddPartiesToProjectInput!) {
    addPartiesToProject(data: $data)
  }
`;

export const addPartiesToProject = (data) => ({
  type: ADD_PARTIES_TO_PROJECT,
  payload: {
    key: 'addPartiesToProject',
    graphql: {
      mutation: ADD_PARTIES_TO_PROJECT_MUTATION,
      variables: {
        data,
      },
    },
  },
});
