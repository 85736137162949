import * as R from 'ramda';

import { LOGOUT } from '@modules/auth/actions';
import { SWITCH_ACTIVE_ORGANIZATION } from '@modules/organization/actions';
import { getGraphqlPayload } from '@store/helpers';

import {
  CLEAR_EVENT_LOGS,
  CREATE_EVENT,
  DELETE_COMMENT_EVENT,
  FETCH_EVENT_LOGS,
  UPDATE_COMMENT_EVENT,
  UPDATE_EVENT_LOG_STATE,
} from '../actions';

const initialState = {
  data: {},
  totalCount: 0,
};

const EventLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_EVENT_LOGS}_SUCCESS`: {
      const payload = getGraphqlPayload(action);
      const eventLogs = R.compose(
        R.mergeDeepRight(state.data),
        R.indexBy(R.prop('_id')),
        R.propOr([], 'data'),
      )(payload);
      const totalCount = R.propOr(0, 'totalCount', payload);

      return R.mergeRight(state, {
        data: R.mergeRight(state.data, eventLogs),
        totalCount,
      });
    }
    case `${FETCH_EVENT_LOGS}_HOOK_SUCCESS`: {
      // returning the payload as it is since the merging between pages is handled in the typePolicies
      return getGraphqlPayload(action);
    }
    case `${DELETE_COMMENT_EVENT}_SUCCESS`:
    case `${UPDATE_COMMENT_EVENT}_SUCCESS`:
    case `${CREATE_EVENT}_SUCCESS`: {
      const eventLog = R.compose(R.defaultTo({}), getGraphqlPayload)(action);

      return R.assocPath(['data', eventLog._id], eventLog, state);
    }
    case UPDATE_EVENT_LOG_STATE: {
      const eventLog = R.pathOr(
        {},
        ['payload', 'data', 'documentProcessed', 'eventLog'],
        action,
      );
      return R.assocPath(['data', eventLog._id], eventLog, state);
    }
    case `${SWITCH_ACTIVE_ORGANIZATION}_SUCCESS`:
    case CLEAR_EVENT_LOGS:
    case `${LOGOUT}_SUCCESS`: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default EventLogsReducer;
