import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const PRIMARY_RECORD_REQUEST_RECORDS_COUNT_QUERY = graphql(`
query PrimaryRecordRequestRecordsCount($input: PrimaryRecordInput!) {
  primaryRecord(input: $input) {
    requestRecords {
      totalCount
    }
  }
}
`);

export const usePrimaryRecordRequestRecordsCount = (recordId?: string) => {
  return useQuery(PRIMARY_RECORD_REQUEST_RECORDS_COUNT_QUERY, {
    variables: { input: { id: recordId } },
    skip: !recordId,
  });
};
