import { pageEvent } from '@common/utils/track-helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const OnLocationChange = () => {
  const { pathname } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    window.scrollTo(0, 0);
    pageEvent();
  }, [pathname]);

  return null;
};

export default OnLocationChange;
