import * as R from 'ramda';

export const getAbbreviature = R.compose(
  R.toUpper,
  R.join(''),
  R.map(R.head),
  R.take(3),
  R.split(' '),
  R.defaultTo(''),
);
