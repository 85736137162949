export default function skipLimitPagination(keyArgs: Array<any>) {
  return {
    keyArgs,
    merge(
      existing: { data: any[] },
      incoming: { data: any[] },
      { args }: { args: any },
    ) {
      const { filters } = args;

      if (!filters?.skip) {
        return incoming;
      }

      const mergedNodes = [
        ...(existing?.data || []),
        ...(incoming?.data || []),
      ];

      return {
        ...(existing || {}),
        ...(incoming || {}),
        data: mergedNodes,
      };
    },
  };
}
