import { isProduction } from '@config/EnvironmentConfig';
import * as Sentry from '@sentry/react';

type TrackErrorParams = {
  error: Error;
  fingerprintKeys?: string[];
  tags: Record<string, string>;
};

const logLevel = isProduction ? 'error' : 'info';

export const identifyUser = (userId: string) => {
  try {
    window.analytics.identify(userId);
  } catch (e) {
    console[logLevel]('identifyUser: window.analytics not available');
  }
};

export const trackEvent = (eventName: string) => {
  try {
    window.analytics.track(eventName);
  } catch (e) {
    console[logLevel]('trackEvent: window.analytics not available');
  }
};

export const pageEvent = () => {
  try {
    window.analytics.page();
  } catch (e) {
    console[logLevel]('pageEvent: window.analytics not available');
  }
};

export const resetTracking = () => {
  try {
    window.analytics.reset();
  } catch (e) {
    console[logLevel]('resetTracking: window.analytics not available');
  }
};

export const trackError = ({
  error,
  fingerprintKeys,
  tags,
}: TrackErrorParams) => {
  Sentry.withScope((scope) => {
    if (fingerprintKeys) {
      /**
       * force Sentry to group errors based on a specific fingerprint
       */
      scope.setFingerprint(fingerprintKeys);
    }

    Sentry.captureException(error, {
      tags,
    });
  });
};
