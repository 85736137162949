import { gql } from '@apollo/client';

export const ORGANIZATION_FRAGMENT = gql`
  fragment Organization on Organization {
    id
    name
    slugifyName
    type
    website
    logo
    icon
    primaryContactName
    primaryContactEmail
    primaryContactPhone
    primaryContactTimezone
    billingManager
    address {
      line1
      line2
      city
      region
      country
      postalCode
      latitude
      longitude
    }
    requestTokenTTL
    defaultRequestMessage
    suspendedAt
    suspendedReason
    features
    credentials
    salesforceId
    settings {
      dashboardType
    }
    createdAt
    updatedAt
  }
`;
