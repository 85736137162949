import {
  DOCUMENT_ACTION_TYPES,
  DocumentsWrapper,
  useConnectionBetweenTableAndURL as useDocumentsTableStateAndURL,
} from '@modules/document';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';

import { getCurrentProject } from '@modules/project/selectors';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const EXCLUDED_ACTIONS = [
  DOCUMENT_ACTION_TYPES.assignToParty,
  DOCUMENT_ACTION_TYPES.detachFromParty,
  DOCUMENT_ACTION_TYPES.associateToProject,
];

const VIEWER_EXCLUDED_ACTIONS = Object.values(DOCUMENT_ACTION_TYPES);

export function PrimaryRecordDocuments() {
  const {
    updateURLFromTableState: handleTableStateChanged,
    getTableStateFromURL: getDocumentsTableState,
  } = useDocumentsTableStateAndURL();
  const { recordId, organizationSlugifyName } =
    useParams<PrimaryRecordsRoutesParams>();
  const currentProjectId = useSelector(
    getCurrentProject as (state: any) => { _id: string },
  )._id;

  const memberRole = useMemberRole();

  const excludedActions =
    memberRole === ROLES.VIEWER ? VIEWER_EXCLUDED_ACTIONS : EXCLUDED_ACTIONS;

  return (
    <DocumentsWrapper
      initialState={getDocumentsTableState()}
      partyId={recordId}
      projectId={currentProjectId}
      organizationSlugifyName={organizationSlugifyName!}
      excludedActions={excludedActions}
      onStateChanged={handleTableStateChanged}
    />
  );
}
