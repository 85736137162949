import { CalendarOutlined, SwapRightOutlined } from '@ant-design/icons';
import { Popover, Tabs, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import moment from 'moment';
import pluralize from 'pluralize';
import styled from 'styled-components';

import PopoverContent from '@common/components/PopoverContent';
import TagWithPopover from '@common/components/TagWithPopover';
import { containsText } from '@common/utils/string-helpers';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import {
  type CustomField,
  CustomFieldType,
} from '@modules/custom-field/constants';
import { getSelectedOptionValue } from '@modules/custom-field/utils/custom-field-helpers';

type CurrentProject = {
  name?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  complianceStatus?: Record<string, number>;
  customFields?: {
    nodes: { customField: CustomField; value: string }[];
    totalCount: number;
  };
};

type CurrentProjectHeaderProps = {
  currentProject: CurrentProject;
};

const getHumanizedDate = (date: string | undefined) => {
  return moment.utc(date).format('ll');
};

const CurrentProjectHeader = ({
  currentProject,
}: CurrentProjectHeaderProps) => {
  const { complianceStatus = {}, customFields = { nodes: [] } } =
    currentProject || {};
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const counterLabel = `compliant active ${pluralize(
    'party',
    complianceStatus.compliantActivePartiesCount,
  )} `;

  const showDates = currentProject.startDate || currentProject.endDate;
  const hasCustomFields = customFields?.nodes?.length > 0;

  if (!isComplianceRequirementsAddOnEnabled && !showDates && !hasCustomFields) {
    return null;
  }

  return (
    <StyledContent>
      {isComplianceRequirementsAddOnEnabled && (
        <span>
          <i data-cy="currentProjectComplianceStatus">
            {complianceStatus.compliantActivePartiesCount ?? 0}/
            {complianceStatus.totalActivePartiesCount ?? 0}
          </i>
          {counterLabel}
          {containsText(currentProject.description) ? (
            <Popover
              trigger="click"
              title={currentProject.name}
              content={
                <PopoverContent
                  data-cy="currentProjectHeaderDescriptionPopover"
                  style={{ minWidth: '500px' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      currentProject?.description || '',
                    ),
                  }}
                />
              }
            >
              <u
                style={{ cursor: 'pointer' }}
                data-cy="inThisProjectHeaderButton"
              >
                in this project
              </u>
            </Popover>
          ) : (
            <span>in this project</span>
          )}
        </span>
      )}

      {showDates && (
        <StyledDates data-cy="currentProjectStyledDates">
          <CalendarOutlined />
          {currentProject.startDate ? (
            <Tooltip title={'Start date'}>
              {getHumanizedDate(currentProject.startDate)}
            </Tooltip>
          ) : (
            '—'
          )}
          <SwapRightOutlined />
          {currentProject.endDate ? (
            <Tooltip title={'End date'}>
              {getHumanizedDate(currentProject.endDate)}
            </Tooltip>
          ) : (
            '—'
          )}
        </StyledDates>
      )}
      <StyledCustomFieldsTabs
        animated={false}
        size="small"
        tabBarGutter={0}
        items={(customFields.nodes || []).map(
          ({ customField, value }, index) => {
            return {
              key: `key_${customField._id}_${index}`,
              label: (
                <TagWithPopover
                  key={customField._id}
                  color={customField.color}
                  name={customField.name}
                  value={
                    customField.type === CustomFieldType.Dropdown
                      ? getSelectedOptionValue({
                          options: customField.options,
                          selectedOptionId: value,
                        })
                      : value
                  }
                />
              ),
            };
          },
        )}
      />
    </StyledContent>
  );
};

const StyledContent = styled.section`
  height: 37px !important;
  line-height: 37px !important;
  border-bottom: 1px solid #e6eaef;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0 34px;
  display: grid;
  grid-template-columns: fit-content(100%) fit-content(100%) auto;
  gap: 20px;
  align-items: center;
  font-size: 12px;

  i {
    font-style: normal;
    border: 1px solid #cfdae7;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
    border-radius: 99em;
    padding: 2px 8px;
    margin-right: 5px;
    background: #fff;
    font-weight: 500;
  }
`;

const StyledDates = styled.div`
  color: rgba(0, 0, 0, 0.65);

  & > span {
    margin: 0 3px;
  }
`;

const StyledCustomFieldsTabs = styled(Tabs)`
  overflow: hidden;

  .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-content {
    margin-top: 0 !important;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-bar {
    background-color: transparent !important;
    margin-bottom: 0;
    border-bottom: none;

    .ant-tabs-tab-active {
      color: inherit;
      font-weight: inherit;
    }
  }
`;

export default CurrentProjectHeader;
