import { BulkOperationStatus } from '@graphql/types/graphql';
import { PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES } from '@modules/primary-records/constants';
import { REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES } from '@modules/request-records/constants';
import { message } from 'antd';
import { useOperationsUpdates } from '../hooks/useOperationsUpdates';

const OPERATIONS_COMPLETED_MESSAGES: Record<string, string> = {
  [REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES.deleteRequestRecords]:
    'Your Request(s) have been succesfully deleted',
  [REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES.createRequestRecords]:
    'Your Request(s) have been succesfully created',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.createPrimaryRecords]:
    'Your Record(s) have been succesfully created',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.deletePrimaryRecords]:
    'Your Record(s) have been succesfully deleted',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.archivePrimaryRecords]:
    'Your Record(s) have been succesfully archived',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.makeActivePrimaryRecords]:
    'Your Record(s) have been succesfully made active',
};

const OperationsUpdatesNotifier = () => {
  useOperationsUpdates({
    operationStatuses: [BulkOperationStatus.Completed],
    onUpdate: (data) => {
      if (!data.operations?.operation?.name) {
        return;
      }

      message.success(
        OPERATIONS_COMPLETED_MESSAGES[data.operations?.operation?.name],
      );
    },
  });

  return null;
};

export default OperationsUpdatesNotifier;
