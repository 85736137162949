import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { EMAIL_TEMPLATE_FRAGMENT } from '../fragments';

export const UPDATE_EMAIL_TEMPLATE = `${namespace}/UPDATE_EMAIL_TEMPLATE`;

const UPDATE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation updateEmailTemplate($payload: UpdateEmailTemplateInput!) {
    updateEmailTemplate(data: $payload) {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const updateEmailTemplate = (payload) => ({
  type: UPDATE_EMAIL_TEMPLATE,
  payload: {
    key: 'updateEmailTemplate',
    graphql: {
      mutation: UPDATE_EMAIL_TEMPLATE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
