import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_MEMBER_FRAGMENT } from '../fragments';

export const INVITE = `${namespace}/CREATE`;

const INVITE_ORGANIZATION_MEMBER_MUTATION = gql`
  mutation inviteOrganizationMember($payload: InviteOrganizationMemberInput!) {
    inviteOrganizationMember(data: $payload) {
      ...OrganizationMember
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`;

export const inviteMember = (payload) => ({
  type: INVITE,
  payload: {
    key: 'inviteOrganizationMember',
    graphql: {
      mutation: INVITE_ORGANIZATION_MEMBER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
