import { FeatureFlagsMap } from '../constants';

import { useFeatureFlag } from './useFeatureFlag';

export const useComplianceProfileEditor = () => {
  const { COMPLIANCE_PROFILE_EDITOR } = FeatureFlagsMap;
  const isComplianceProfileEditorFeatureFlagEnabled = useFeatureFlag(
    COMPLIANCE_PROFILE_EDITOR,
  );
  return { isComplianceProfileEditorFeatureFlagEnabled };
};
