import storage from 'redux-persist/lib/storage';

export const rootConfig = {
  storage,
  timeout: 0,
  key: 'primary',
  whitelist: ['user', 'system-settings', 'request'],
};

export const organizationConfig = {
  storage,
  timeout: 0,
  key: 'organization',
  whitelist: ['active'],
};
