import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import type {
  CreateRequestRecordsInput,
  CreateRequestRecordsMutation,
} from '@graphql/types/graphql';

type CreateRequestRecordsParams = {
  requestRecords: CreateRequestRecordsInput['requestRecords'];
  onCompleted: (data: CreateRequestRecordsMutation) => void;
};

const CREATE_REQUEST_RECORDS_MUTATION = graphql(`
  mutation CreateRequestRecords($data: CreateRequestRecordsInput!) {
    createRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useCreateRequestRecordsMutation = () => {
  const [createRequestRecordsMutation, { loading }] = useMutation(
    CREATE_REQUEST_RECORDS_MUTATION,
  );

  const createRequestRecords = ({
    requestRecords,
    onCompleted,
  }: CreateRequestRecordsParams) => {
    createRequestRecordsMutation({
      variables: {
        data: {
          requestRecords,
        },
      },
      onCompleted,
    });
  };

  return {
    createRequestRecords,
    loading,
  };
};
