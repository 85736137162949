import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { AutomationSelect } from '@modules/automations/containers/AutomationSelect';
import { useDisableAutomationsMutation } from '@modules/primary-records/hooks/useDisableAutomationsMutation';
import { useEnableAutomationsMutation } from '@modules/primary-records/hooks/useEnableAutomationsMutation';
import { Form, Modal, Typography, message } from 'antd';
import { capitalize } from 'lodash';
import styled from 'styled-components';

type EnableDisableAutomationsModalProps = {
  open: boolean;
  filters?: ServerFilterInput;
  count: number;
  type: 'enable' | 'disable';
  primaryObject: {
    name: string;
    pluralName: string;
  };
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export function EnableDisableAutomationsModal({
  open,
  filters,
  count,
  type,
  primaryObject,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: EnableDisableAutomationsModalProps) {
  const [form] = Form.useForm<{ automationId: string }>();

  const { enableAutomations, isEnablingAutomations } =
    useEnableAutomationsMutation();
  const { disableAutomations, isDisablingAutomations } =
    useDisableAutomationsMutation();

  const isLoading = isEnablingAutomations || isDisablingAutomations;
  const actionLabel = capitalize(type);
  const objectName =
    count === 1 ? primaryObject.name : primaryObject.pluralName;

  const handleEnableAutomations = async (values: { automationId: string }) => {
    const res = await enableAutomations(values.automationId, filters);
    const status = res.data?.enablePrimaryRecordsAutomation?.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Automation for ${count} ${objectName} enabled successfully.`,
      );
      onCompleted?.();
    } else if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Automation for ${count} ${objectName} has been scheduled for enabling.`,
      );
      onScheduled?.();
    } else if (status === BulkOperationStatus.Failed) {
      message.error(
        `The enabling of the automation for ${count} ${objectName} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const handleDisableAutomations = async (values: { automationId: string }) => {
    const res = await disableAutomations(values.automationId, filters);
    const status = res.data?.disablePrimaryRecordsAutomation?.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Automation for ${count} ${objectName} disabled successfully.`,
      );
      onCompleted?.();
    } else if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Automation for ${count} ${objectName} has been scheduled for disabling.`,
      );
      onScheduled?.();
    } else if (status === BulkOperationStatus.Failed) {
      message.error(
        `The disabling of the automation for ${count} ${objectName} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const handleAction =
    type === 'enable' ? handleEnableAutomations : handleDisableAutomations;

  return (
    <Modal
      data-cy={`${type}-automations-modal`}
      open={open}
      okButtonProps={{
        loading: isLoading,
      }}
      okText={actionLabel}
      title={`${actionLabel} automation for multiple ${primaryObject.pluralName}`}
      onCancel={onCancel}
      onOk={() => form.submit()}
      modalRender={(modal) => (
        <Form form={form} onFinish={handleAction}>
          {modal}
        </Form>
      )}
    >
      <Typography.Paragraph>
        {`${actionLabel} the automation for ${count} ${objectName}`}
      </Typography.Paragraph>
      <StyledSelectWrapper>
        <span>Automation</span>
        <Form.Item
          name="automationId"
          rules={[{ required: true, message: 'Please select an automation' }]}
        >
          {/* @ts-expect-error - value and onChange are implicitly defined by Form.Item */}
          <StyledAutomationSelect />
        </Form.Item>
      </StyledSelectWrapper>
    </Modal>
  );
}

const StyledSelectWrapper = styled.section`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;

  > span {
    margin-top: 5px;
  }
`;

const StyledAutomationSelect = styled(AutomationSelect)`
  width: fit-content;
`;
