export const ratingValues = {
  160: 'A++',
  150: 'A+',
  140: 'A',
  130: 'A-',
  120: 'B++',
  110: 'B+',
  100: 'B',
  90: 'B-',
  80: 'C++',
  70: 'C+',
  60: 'C',
  50: 'C-',
  40: 'D++',
  30: 'D+',
  20: 'D',
  10: 'D-',
} as const;

export const financialStrengthValues = {
  150: 'XV (2,000M or greater)',
  140: 'XIV (1,500M to 2,000M)',
  130: 'XIII (1,250M to 1,500M)',
  120: 'XII (1,000M to 1,250M)',
  110: 'XI (750M to 1,000M)',
  100: 'X (500M to 750M)',
  90: 'IX (250M to 500M)',
  80: 'VIII (100M to 250M)',
  70: 'VII (50M to 100M)',
  60: 'VI (25M to 50M)',
  50: 'V (10M to 25M)',
  40: 'IV (5M to 10M)',
  30: 'III (2M to 5M)',
  20: 'II (1M to 2M)',
  10: 'I (Less than 1M)',
} as const;
