import { Checkbox, type CheckboxProps } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import type { CustomHeaderProps } from '../types';

import styled from 'styled-components';

export type CustomHeaderWithSelectionRef = {
  resetState: () => void;
};

const getCurrentPageRowIds = (gridApi: CustomHeaderProps['api']) => {
  const renderedNodes = gridApi.getRenderedNodes();
  return renderedNodes.map((node) => node.id) as string[];
};

export const CustomHeaderWithSelection = forwardRef<
  CustomHeaderWithSelectionRef,
  CustomHeaderProps
>((props, ref) => {
  const [isChecked, setIsChecked] = useState(false);

  useImperativeHandle(ref, () => ({
    resetState: () => {
      setIsChecked(false);
    },
  }));

  const handleCheckboxChange: CheckboxProps['onChange'] = ({ target }) => {
    setIsChecked(target.checked);

    props.api.deselectAll();
    props.api.setServerSideSelectionState({
      selectAll: false,
      toggledNodes: target.checked ? getCurrentPageRowIds(props.api) : [],
    });
  };

  return (
    <StyledCustomHeader>
      <StyledCheckbox onChange={handleCheckboxChange} checked={isChecked} />
      <span>{props.displayName}</span>
    </StyledCustomHeader>
  );
});

const StyledCustomHeader = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    box-shadow: none;
  }
  
  .ant-checkbox-inner {
    /* @note custom color to replicate Ag Grid checkbox border color */
    border-color: #BCC0C1;
  }
`;
