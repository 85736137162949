import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const UPLOAD_USER_AVATAR_MUTATION = graphql(`
  mutation UploadAvatar($input: UploadAvatarInput!) {
    uploadAvatar(data: $input) {
      url
      friendlyName
      s3Key
    }
  }
`);

export const useUploadAvatar = () => {
  const [uploadAvatarMutation, { loading }] = useMutation(
    UPLOAD_USER_AVATAR_MUTATION,
  );

  const uploadAvatar = useCallback(
    async (image: File) => {
      const res = await uploadAvatarMutation({
        variables: {
          input: { file: image },
        },
        context: { hasUpload: true },
      });

      const url = res.data?.uploadAvatar.url;

      if (!url) {
        throw new Error('Failed to upload avatar');
      }

      return url;
    },
    [uploadAvatarMutation],
  );

  return {
    uploadAvatar,
    loading,
  };
};
