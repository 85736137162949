import type { InputRef } from 'antd';
import { useEffect, useRef } from 'react';

export const useInputFocusOnMount = (elementRef: InputRef | null) => {
  const ref = useRef<InputRef>(elementRef);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return ref;
};
