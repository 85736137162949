import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const CREATE_CONTACT = `${namespace}/CREATE_CONTACT`;

const CREATE_CONTACT_MUTATION = gql`
  mutation createPartyContact($payload: CreatePartyContactInput!) {
    createPartyContact(data: $payload) {
      _id
      partyContacts {
        _id
        party
        contactPersonName
        companyName
        email
        phone
        fax
        title
        avatar
        isPrimary
        isDefaultRequestRecipient
        createdAt
        updatedAt
      }
    }
  }
`;

export const createContact = (payload) => ({
  type: CREATE_CONTACT,
  payload: {
    key: 'createPartyContact',
    graphql: {
      mutation: CREATE_CONTACT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
