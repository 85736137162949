import { gql } from '@apollo/client';

//! Do not insert the “issue” field at the first level in the fragment as it supports filters and in order to pass them to it, it must be defined where the query is declared

export const PARTY_CONVERSATION_FRAGMENT = gql`
  fragment PartyConversation on PartyConversation {
    _id
    party
    organization
    type
    request
    subject
    substitutions
    participants
    usersParticipants {
      _id
      email
      profile {
        name
      }
    }
    createdAt
    updatedAt
  }
`;

export const PARTY_CONVERSATION_MESSAGE_FRAGMENT = gql`
  fragment PartyConversationMessage on PartyMessage {
    _id
    request
    sendgridMessageId
    type
    status
    message
    senderContactId
    senderType
    data {
      ... on MessageResent {
        partyContact {
          _id
          email
        }
      }
      ... on ContactDeleted {
        partyContact {
          _id
          email
        }
      }
      ... on ContactRemovedFromConversation {
        partyContact {
          _id
          email
        }
      }
      ... on ContactEmailUpdatedResent {
        partyContactAfterUpdate {
          _id
          email
        }
      }
    }
    issues {
      _id
      email
      messageId
      partyConversationId
      contactId
      sendgridEventId
      reason
      ignorable
      resendable
      resolved
    }
    senderUser {
      _id
      email
      profile {
        name
        avatar
      }
    }
    recipients
    outputHtml
    sendgridEvents {
      _id
      email
      status
      date
      reason
    }
    attachments {
      url
      friendlyName
      s3Key
    }
    readAt
    createdAt
    updatedAt
  }
`;

export type PartyConversation = {
  _id: string;
  party: string;
  organization: string;
  type: 'conversation' | 'document:request';
  request: string;
  subject: string;
  substitutions: any;
  participants: string[];
  usersParticipants: {
    _id: string;
    email: string;
    profile: {
      name: string;
    };
  };
  messages?: {
    nodes: PartyMessage[];
    totalCount: number;
  };
  createdAt: string;
  updatedAt: string;
};

type PartyMessage = {
  _id: string;
  request: string | null;
  sendgridMessageId: string | null;
  type:
    | 'message'
    | 'request'
    | 'message'
    | 'expiring'
    | 'new:token:link'
    | 'request:forwarded';
  status: 'bounce' | 'warning' | 'sent' | 'read' | null;
  message: 'string';
  senderType: 'User' | 'TrustLayer';
  issues: PartyMessageIssue[];
  data:
    | ContactRemovedFromConversation
    | ContactDeleted
    | ContactEmailUpdatedResent
    | MessageResent
    | Message;
  senderContactId: string | null;
  senderUser: unknown;
  recipients: string[];
  outputHtml: string;
  sendgridEvents: SendgridEvent[];
  attachments: unknown;
  readAt: string;
  createdAt: string;
  updatedAt: string;
};

type PartyContactInEvent = {
  _id: string;
  email: string;
};

type ContactRemovedFromConversation = {
  type: 'ContactRemovedFromConversation';
  partyContact: PartyContactInEvent;
};

type ContactDeleted = {
  type: 'ContactDeleted';
  partyContact: PartyContactInEvent;
};

type ContactEmailUpdatedResent = {
  type: 'ContactEmailUpdatedResent';
  partyContactBeforeUpdate: PartyContactInEvent;
  partyContactAfterUpdate: PartyContactInEvent;
};

type MessageResent = {
  type: 'MessageResent';
  partyContact: PartyContactInEvent;
};

type Message = {
  type: 'Message';
};

export type PartyMessageIssue = {
  _id: string;
  organization: string;
  email: string;
  messageId: string;
  partyConversationId: string;
  contactId: string;
  sendgridEventId: string;
  reason: string | null;
  ignorable: boolean;
  resendable: boolean;
  resolved: boolean;
};

type SendgridEvent = {
  _id: string;
  email: string | null;
  status: 'delivered' | 'bounce' | 'processed' | 'open';
  date: string;
  reason: string;
};
