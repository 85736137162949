import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import Spinner from '@common/components/Spinner';
import { Select } from 'antd';
import { useAutomationsQuery } from './hooks';

type AutomationSelectProps = {
  className?: string;
} & (
  | {
      value: string[];
      onChange: (params: string[]) => void;
      mode: 'multiple' | 'tags';
    }
  | {
      value: string;
      onChange: (params: string) => void;
      mode?: undefined;
    }
);

export const AutomationSelect = ({
  value,
  onChange,
  mode,
  className,
}: AutomationSelectProps) => {
  const { automations, automationsTotalCount, fetchMoreAutomations, loading } =
    useAutomationsQuery();

  return (
    <InfiniteScrollSelect
      data-cy="automationSelect"
      placeholder="Enter automation..."
      value={value}
      isDataLoading={loading}
      hasMore={automations.length < automationsTotalCount}
      mode={mode}
      className={className}
      loadMore={() => {
        fetchMoreAutomations({
          variables: {
            input: {
              offset: automations.length,
              first: 10,
            },
          },
        });
      }}
      onChange={onChange}
      loader={<Spinner />}
    >
      {automations?.map((item) => (
        <Select.Option
          data-cy="automationSelectsOption"
          key={`key_${item._id}`}
          value={item._id}
          label={item.name}
        >
          {item.name}
        </Select.Option>
      ))}
    </InfiniteScrollSelect>
  );
};
