import { Modal, Select } from 'antd';
import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentTypes } from '@modules/document-type/selectors';

import { FullWidthSelect } from '@trustlayer/ui';
import * as ComplianceProfileActions from '../actions';
import { getCurrentDocumentChecklists } from '../selectors';

const SelectDocumentChecklistModal = ({ visible, setIsVisible }) => {
  const [typeId, setTypeId] = useState('');
  const currentChecklists = useSelector(getCurrentDocumentChecklists);
  const documentTypes = useSelector(getDocumentTypes);

  const dispatch = useDispatch();

  const addDocumentType = () => {
    dispatch(
      ComplianceProfileActions.setCurrentDocumentChecklists(
        R.append(
          {
            type: R.find(R.propEq('_id', typeId), documentTypes),
            attributeId: '',
            attributeLabel: '',
            isActive: true,
          },
          currentChecklists,
        ),
      ),
    );
    setIsVisible(false);
    setTypeId('');
  };

  return (
    <Modal
      open={visible}
      title="Add document checklist"
      okText="Add document checklist"
      onOk={() => addDocumentType()}
      okButtonProps={{
        'data-cy': 'addChecklistButton',
        disabled: typeId === '',
      }}
      onCancel={() => setIsVisible(false)}
      zIndex={1003}
    >
      <p>
        A document checklist helps you define requirements that documents of a
        specific type should meet.
      </p>
      <FullWidthSelect
        data-cy="selectChecklistType"
        placeholder="Select a document type..."
        onChange={(typeId) => setTypeId(typeId)}
      >
        {documentTypes
          .filter(
            (type) =>
              !currentChecklists.some(
                (checklists) =>
                  checklists.type._id === type._id && checklists.isActive,
              ),
          )
          .map((type) => (
            <Select.Option
              className="e2e_checklistTypeOption"
              value={type._id}
              key={type._id}
            >
              {type.name}
            </Select.Option>
          ))}
      </FullWidthSelect>
    </Modal>
  );
};

export default SelectDocumentChecklistModal;
