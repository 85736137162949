import type React from 'react';
import { useEffect, useRef, useState } from 'react';

/*
 * Antd transition duration of the Antd Modal is 200ms, Drawer 300ms.
 * We default to 500ms to stay safer, since unmounting after a few ms more does not hurt anything
 */
const DEFAULT_UNMOUNT_AFTER_MS = 500;

/**
 * HOC that mounts a component when a condition is met.
 * It defaults looking for the `open` prop, but this can be configured by passing a custom function `mountWhen`.
 *
 * Example of usages:
 * - withControlledMount(Component)
 * - withControlledMount(Component, { mountWhen: (props) => props.visible })
 * - withControlledMount(Component, { mountWhen: (props) => !props.hidden, unmountAfterMs: 1000 })
 */
export const withControlledMount = <T extends object>(
  Component: React.FC<T>,
  {
    unmountAfterMs = DEFAULT_UNMOUNT_AFTER_MS,
    mountWhen = (props) => 'open' in props && Boolean(props.open),
  }: {
    unmountAfterMs?: number;
    mountWhen?: (props: T) => boolean;
  } = {},
): React.FC<T> => {
  return (props: T) => {
    const isVisible = mountWhen(props);
    const [internalVisible, setInternalVisible] = useState(isVisible);
    const timeoutRef = useRef<NodeJS.Timeout | undefined>();

    useEffect(() => {
      if (isVisible) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setInternalVisible(isVisible);
      } else {
        timeoutRef.current = setTimeout(() => {
          setInternalVisible(isVisible);
        }, unmountAfterMs);
      }
    }, [isVisible, unmountAfterMs]);

    if (!internalVisible) {
      return null;
    }

    return <Component {...props} />;
  };
};
