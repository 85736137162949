import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const DELETE_DOCUMENT_FLAG = `${namespace}/DELETE_DOCUMENT_FLAG`;

const DELETE_DOCUMENT_FLAG_MUTATION = gql`
  mutation deleteDocumentFlag($payload: DeleteDocumentFlagInput!) {
    deleteDocumentFlag(data: $payload) {
      ...Document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const deleteDocumentFlag = (payload) => ({
  type: DELETE_DOCUMENT_FLAG,
  payload: {
    key: 'deleteDocumentFlag',
    graphql: {
      mutation: DELETE_DOCUMENT_FLAG_MUTATION,
      variables: { payload },
    },
  },
});
