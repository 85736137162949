import { List, Popover } from 'antd';
import * as R from 'ramda';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import Spinner from '@common/components/Spinner';
import FlagIcon from '@common/icons/FlagIcon';

import { fetchFlagsByPartyId } from '../../../../modules/party/actions';
import { BadgeColors, BadgeSize } from '../model/badges';

import BadgePopover from './BadgePopover';

export const DocumentSeverityColorMap = {
  low: BadgeColors.blueIcon,
  medium: BadgeColors.yellowIcon,
  high: BadgeColors.redIcon,
};

const FlagBadgePopover = ({
  defaultVisible,
  documentData,
  partyFlags = [],
  flagData,
  verificationData,
  sizeIcon,
  isShortVersion = false,
  party = null,
  nearestFlagSeverity,
  className,
}) => {
  const themeContext = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onIconHover = async () => {
    if (
      !R.isNil(party) &&
      !partyFlags.length &&
      !R.isNil(nearestFlagSeverity)
    ) {
      setIsLoading(true);

      await dispatch(fetchFlagsByPartyId(party));

      setTimeout(() => setIsLoading(false), 300);
    }
  };

  // nearestFlagSeverity is used on the party list page, but unused on the document list page
  // flagData is used on the document list page, but unused on the parties list page
  const hasFlagData =
    !!nearestFlagSeverity || (!!flagData && !R.isEmpty(flagData));

  if (!defaultVisible && !partyFlags.length) {
    return null;
  }

  const getContent = () => {
    if (isLoading) {
      return <Spinner spinnerWrapperStyle={{ padding: '12px 16px' }} />;
    }

    if (!hasFlagData) return <>No flags added</>;

    if (partyFlags.length > 0) {
      return (
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            pageSize: 3,
            size: 'small',
            hideOnSinglePage: true,
          }}
          dataSource={partyFlags.sort(
            (a, b) => new Date(b.addedOn) - new Date(a.addedOn),
          )}
          renderItem={(flag) => (
            <BadgePopover
              key={flag.document._id}
              documentData={{
                _id: R.prop('_id', flag.document),
                documentName: R.prop('friendlyName', flag.document),
                issuedBy: R.prop('issuedBy', flag.document),
                party: R.prop('party', flag.document),
              }}
              flagData={flag}
              verificationData={R.prop('verification', document)}
              isShowFlagInfo
              isShortVersion={isShortVersion}
            />
          )}
        />
      );
    }

    return (
      <BadgePopover
        documentData={documentData}
        flagData={flagData}
        verificationData={verificationData}
        isShowFlagInfo
        isShortVersion={isShortVersion}
      />
    );
  };

  return (
    <FlagBadgePopover.Flag className={className}>
      <Popover
        arrowPointAtCenter
        overlayClassName="flagBadgePopover"
        placement="topLeft"
        overlayStyle={{
          maxWidth: '500px',
        }}
        mouseEnterDelay={0.5}
        content={getContent()}
      >
        <FlagBadgePopover.IconContent
          data-cy="flagIcon"
          onMouseEnter={() => onIconHover()}
        >
          <FlagIcon
            color={
              themeContext.colors[
                R.propOr(
                  BadgeColors.grayIcon,
                  R.propOr(nearestFlagSeverity, 'severityLevel', flagData),
                  DocumentSeverityColorMap,
                )
              ]
            }
            size={BadgeSize[sizeIcon]}
          />
        </FlagBadgePopover.IconContent>
      </Popover>
    </FlagBadgePopover.Flag>
  );
};

FlagBadgePopover.Flag = styled.div`
  display: flex;
  transform: rotate(-10deg);
  margin: 0 7px;
`;

FlagBadgePopover.Wrapper = styled.div`
  display: flex;
  position: relative;
`;

FlagBadgePopover.IconContent = styled.div`
  display: flex;
`;

FlagBadgePopover.IconTooltip = styled.div`
  display: flex;
  margin-top: 4px;
`;

FlagBadgePopover.Description = styled.div`
  color: #354052;
  font-size: 13px;
  margin: 0;
  padding: 0 40px 0 10px;

  & > span {
    display: block;
    font-weight: 300;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

FlagBadgePopover.Close = styled.div`
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;

  & > i {
    color: #e8edf3;
  }
`;

FlagBadgePopover.defaultProps = {
  defaultVisible: false,
  sizeIcon: 'Medium',
};

export default FlagBadgePopover;
