import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { ORGANIZATION_MEMBER_FRAGMENT } from '../fragments';

export const UPDATE_ORGANIZATION_MEMBER = `${namespace}/UPDATE_ORGANIZATION_MEMBER`;

const UPDATE_ORGANIZATION_MEMBER_MUTATION = gql`
  mutation updateOrganizationMember($payload: UpdateOrganizationMemberInput!) {
    updateOrganizationMember(data: $payload) {
      ...OrganizationMember
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`;

export const updatedOrganizationMember = (payload) => ({
  type: UPDATE_ORGANIZATION_MEMBER,
  payload: {
    key: 'updateOrganizationMember',
    graphql: {
      mutation: UPDATE_ORGANIZATION_MEMBER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
