import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_DATA_FRAGMENT } from '../fragments';

export const UPDATE_PARTY_ACTIVE_STATUS = `${namespace}/UPDATE_PARTY_ACTIVE_STATUS`;

const UPDATE_PARTY_ACTIVE_STATUS_MUTATION = gql`
  mutation updatePartyActiveStatus($payload: UpdatePartyActiveStatusInput!) {
    updatePartyActiveStatus(data: $payload) {
      ...PartyData
    }
  }
  ${PARTY_DATA_FRAGMENT}
`;

export const updatePartyActiveStatus = (payload) => ({
  type: UPDATE_PARTY_ACTIVE_STATUS,
  payload: {
    key: 'updatePartyActiveStatus',
    graphql: {
      mutation: UPDATE_PARTY_ACTIVE_STATUS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
