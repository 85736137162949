const UploadIcon = ({ size, fill }) => (
  <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 32 32">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1045.000000, -186.000000)"
        fill={fill || '#1890FF'}
        fillRule="nonzero"
      >
        <g transform="translate(700.000000, 112.000000)">
          <g>
            <g transform="translate(235.000000, 72.000000)">
              <g transform="translate(108.000000, 0.000000)">
                <g transform="translate(2.250000, 2.250000)">
                  <path d="M14.9238281,0.355078125 C15.159375,0.11953125 15.4722656,0 15.7851563,0 C16.0980469,0 16.4074219,0.11953125 16.6464844,0.355078125 L22.1238281,5.8359375 C22.5984375,6.31054688 22.5984375,7.08046875 22.1238281,7.55507813 C21.8953125,7.78359375 21.5894531,7.91015625 21.2660156,7.91015625 C20.9425781,7.91015625 20.6367188,7.78359375 20.4082031,7.55507813 L17.0015625,4.1484375 L17.0015625,24.4617187 C17.0015625,25.1332031 16.4566406,25.678125 15.7851563,25.678125 C15.1136719,25.678125 14.56875,25.1332031 14.56875,24.4617187 L14.56875,4.1484375 L11.1621094,7.55507813 C10.6875,8.0296875 9.91757813,8.0296875 9.44648438,7.55507813 C8.971875,7.08046875 8.971875,6.31054688 9.44296875,5.8359375 L14.9238281,0.355078125 Z M30.2835937,20.2640625 C29.6121094,20.2640625 29.0671875,20.8089844 29.0671875,21.4804688 L29.0671875,28.35 C29.0671875,28.7472656 28.74375,29.0742188 28.3429687,29.0742188 L3.15351562,29.0742188 C2.75625,29.0742188 2.42929687,28.7507812 2.42929687,28.35 L2.42929687,21.4769531 C2.42929687,20.8054687 1.884375,20.2605469 1.21289062,20.2605469 C0.54140625,20.2605469 0,20.8089844 0,21.4769531 L0,29.3132812 C0,30.5191406 0.980859375,31.4964844 2.18320312,31.4964844 L29.3167969,31.4964844 C30.5226563,31.4964844 31.5,30.515625 31.5,29.3132812 L31.5,21.4769531 C31.5,20.8089844 30.9550781,20.2640625 30.2835937,20.2640625 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

UploadIcon.defaultProps = {
  size: 32,
};

export default UploadIcon;
