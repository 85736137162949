import { Card, Skeleton, Space } from 'antd';
import styled from 'styled-components';

export const ComplianceProfileSkeleton = () => {
  return (
    <Space direction="vertical" size="middle">
      <Card>
        <Space direction="vertical" size="middle">
          <Skeleton.Input active />
          <SubjectPlaceholder active size="small" />
          <RequirementPlaceholder active size="small" />
          <RequirementPlaceholder active size="small" />
        </Space>
      </Card>

      <Card>
        <Space direction="vertical" size="middle">
          <Skeleton.Input active />
          <SubjectPlaceholder active size="small" />
          <RequirementPlaceholder active size="small" />
          <RequirementPlaceholder active size="small" />
        </Space>
      </Card>
    </Space>
  );
};

const RequirementPlaceholder = styled(Skeleton.Input)`
  padding: 0 64px;
`;

const SubjectPlaceholder = styled(Skeleton.Input)`
  padding: 0 32px;
`;
