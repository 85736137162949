import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const EXPORT_CUSTOM_FIELDS = `${namespace}/EXPORT_CUSTOM_FIELDS`;

const EXPORT_CUSTOM_FIELDS_MUTATION = gql`
  mutation exportCustomFields($payload: ExportCustomFieldsInput!) {
    exportCustomFields(data: $payload) {
      scheduled
    }
  }
`;

export const exportCustomFields = (payload) => ({
  type: EXPORT_CUSTOM_FIELDS,
  payload: {
    key: 'exportCustomFields',
    graphql: {
      mutation: EXPORT_CUSTOM_FIELDS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
