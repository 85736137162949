import moment from 'moment';

export const getHuminzedDurationDiffWithNow = ({
  executedDate,
}: {
  executedDate: string;
}) => {
  const currentDate = moment();
  const executedDateMoment = moment(executedDate);
  const diffDuration = executedDateMoment.diff(currentDate);

  return moment.duration(diffDuration).humanize(true, { d: 7, w: 4, M: 12 });
};
