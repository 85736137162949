import { BadgeColors, BadgeSize } from '@common/components/Badges/model/badges';

const FlagIcon = ({ size, color }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 17 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M4.18759711,0.713631029 L4.18759711,20.713631 L3.18759711,20.713631 L3.18759711,0.713631029 L4.18759711,0.713631029 Z M10.4951236,1.05715014 L10.4951236,3.84481148 L20.0917969,3.84481148 L13.5486105,9.42013417 L20.0917969,14.9954569 L7.00542422,14.9954569 C7.00542422,12.9047109 10.4951236,12.2077955 10.4951236,12.2077955 L4.38814969,12.2077955 L4.38814969,1.05715014 L10.4951236,1.05715014 Z"
        id="path-2"
      ></path>
    </defs>
    <g
      id="Parties-List"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Parties-List-|-List-Expanded-Row"
        transform="translate(-103.000000, -1430.000000)"
      >
        <g
          id="-Icons-/-Document-flag"
          transform="translate(100.000000, 1430.000000)"
        >
          <use
            id="Combined-Shape"
            fill={color}
            fillRule="evenodd"
            xlinkHref="#path-2"
          ></use>
        </g>
      </g>
    </g>
  </svg>
);

FlagIcon.defaultProps = {
  size: BadgeSize.Medium,
  color: BadgeColors.grayIcon,
};

export default FlagIcon;
