const CloseIcon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
  >
    <g>
      <path
        fill={color}
        d="M562.3,499.6L974.8,87.1c16-16,16-44.8,0-63.9c-16-16-44.8-16-63.9,0L498.4,435.7L85.9,23.2c-16-16-44.8-16-63.9,0C6,39.2,6,68,22,87.1l412.5,412.5L22,912.1c-16,16-16,44.8,0,63.9c9.6,9.6,19.2,12.8,32,12.8c12.8,0,22.4-3.2,32-12.8l412.5-412.5L914.1,976c9.6,9.6,19.2,12.8,32,12.8c12.8,0,22.4-3.2,32-12.8c16-16,16-44.8,0-63.9L562.3,499.6L562.3,499.6z"
      />
    </g>
  </svg>
);

CloseIcon.defaultProps = {
  size: 15,
  color: 'white',
};

export default CloseIcon;
