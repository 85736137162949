import { gql } from '@apollo/client';

import { CustomFieldsEntities, namespace } from '../constants';
import { CUSTOM_FIELD_FRAGMENT } from '../fragments';

export const CREATE_PROJECT_CUSTOM_FIELDS = `${namespace}/CREATE_PROJECT_CUSTOM_FIELDS`;
export const CREATE_PARTY_CUSTOM_FIELD = `${namespace}/CREATE_PARTY_CUSTOM_FIELD`;

const CREATE_CUSTOM_FIELD_MUTATION = gql`
  mutation createCustomField($payload: CreateCustomFieldInput!) {
    createCustomField(data: $payload) {
      ...CustomField
    }
  }
  ${CUSTOM_FIELD_FRAGMENT}
`;

const createCustomField = (entity) => (payload) => {
  const type =
    entity === CustomFieldsEntities.party
      ? CREATE_PARTY_CUSTOM_FIELD
      : CREATE_PROJECT_CUSTOM_FIELDS;

  return {
    type,
    payload: {
      key: 'createCustomField',
      graphql: {
        mutation: CREATE_CUSTOM_FIELD_MUTATION,
        variables: {
          payload: {
            ...payload,
            associatedEntities: [entity],
          },
        },
      },
    },
  };
};

export const createPartyCustomField = createCustomField(
  CustomFieldsEntities.party,
);

export const createProjectCustomField = createCustomField(
  CustomFieldsEntities.project,
);
