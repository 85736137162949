import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PARTY_CONVERSATION_FRAGMENT } from '../fragments';

export const UPDATE_PARTY_CONVERSATION = `${namespace}/UPDATE_PARTY_CONVERSATION`;

const UPDATE_PARTY_CONVERSATION_MUTATION = gql`
  mutation updatePartyConversation($payload: UpdateConversationInput!) {
    updatePartyConversation(data: $payload) {
      ...PartyConversation
    }
  }
  ${PARTY_CONVERSATION_FRAGMENT}
`;

export const updatePartyConversation = (payload) => ({
  type: UPDATE_PARTY_CONVERSATION,
  payload: {
    key: 'updatePartyConversation',
    graphql: {
      mutation: UPDATE_PARTY_CONVERSATION_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
