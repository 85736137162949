import { useDispatch } from 'react-redux';

import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { FETCH_ORGANIZATION_BY_ID } from '@modules/organization/actions';

const ORGANIZATION_INFO_QUERY = graphql(`
  query OrganizationInfo($id: ObjectId!) {
    requestPage(id: $id) {
      organization {
        id
        name
        logo
        website
        features
        primaryContactEmail
        primaryContactName
        primaryContactPhone
      }
    }
  }
`);

export const useOrganization = (requestId: string) => {
  const dispatch = useDispatch();
  const { data: organizationQueryData, loading } = useQuery(
    ORGANIZATION_INFO_QUERY,
    {
      variables: { id: requestId },
      skip: !requestId,
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        dispatch({
          type: `${FETCH_ORGANIZATION_BY_ID}_SUCCESS`,
          payload: data.requestPage.organization,
        });
      },
    },
  );

  return {
    organization: organizationQueryData?.requestPage?.organization,
    loading,
  };
};
