/**
 * @note they could be not used anymore after firbase implementation.
 * These are not error codes but part of the error messages.
 *
 * @todo remove them if not used anymore, or replace them with proper codes.
 */
export const AUTH_ERRORS_CODES = [
  'Email or password is invalid', // @todo API should expose a code
  '(ERR006)', // no orgnaization linked to a domain
  '(ERR007)', // user not confirmed
  '(ERR008)', // user disabled
];
