import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

type PrimaryRecordType = {
  _id: string;
  name: string;
};

const DAFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
};

const PRIMARY_RECORD_TYPES_QUERY = graphql(`
  query PrimaryRecordTypes($input: PrimaryRecordTypesInput) {
    primaryRecordTypes(input: $input) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`);

export const usePrimaryRecordTypes = () => {
  const [types, setTypes] = useState<PrimaryRecordType[]>([]);
  const [typesTotalCount, settypesTotalCount] = useState(0);

  const [fetchPrimaryRecordTypes, { loading, fetchMore }] = useLazyQuery(
    PRIMARY_RECORD_TYPES_QUERY,
    {
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: 0,
        },
      },
    },
  );

  const onCompleted = ({ primaryRecordTypes }: any) => {
    setTypes(primaryRecordTypes.nodes);
    settypesTotalCount(primaryRecordTypes.totalCount);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const filterTypesByName = useMemo(
    () =>
      debounce((name: string) => {
        fetchPrimaryRecordTypes({
          variables: {
            input: {
              ...DEFAULT_INPUT,
              offset: 0,
              filter: {
                and: [
                  {
                    name: 'name',
                    operator: FILTER_OPERATORS.contains,
                    value: name,
                  },
                ],
              },
            },
          },
          onCompleted,
        });
      }, 500),
    [fetchPrimaryRecordTypes],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    fetchPrimaryRecordTypes({
      onCompleted,
    });
  }, [fetchPrimaryRecordTypes]);

  return {
    types,
    typesTotalCount,
    loading,
    fetchMoreTypes: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: types.length,
          },
        },
      });
    },
    filterTypesByName,
  };
};
