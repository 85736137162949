import { CloudDownloadOutlined } from '@ant-design/icons';
import { getType } from 'mime';
import * as R from 'ramda';
import styled from 'styled-components';

import { useDownloadDocument } from '@modules/document/hooks';

const DownloadDocument = ({ document }) => {
  const { downloadDocument } = useDownloadDocument();

  const friendlyName = R.propOr('', 'friendlyName', document);
  const s3Key = R.propOr('', 's3Key', document);
  const mimeType = getType(s3Key);
  const extension = R.compose(R.last, R.split('.'))(s3Key);

  return (
    <DownloadDocument.Wrapper>
      <DownloadDocument.Box>
        <div>No viewer available for this file type ({extension})</div>
        <button onClick={() => document && downloadDocument(document)}>
          <CloudDownloadOutlined />
          <u>{friendlyName}</u>
        </button>
        <p>Click on the icon to download and open the file on your system.</p>
        <small>
          File type: <code>{mimeType}</code> · Original extension:{' '}
          <code>.{extension}</code>
        </small>
      </DownloadDocument.Box>
    </DownloadDocument.Wrapper>
  );
};

DownloadDocument.Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 60px;
  align-items: center;
  justify-content: center;

  small,
  u {
    display: block;
    color: rgba(0, 0, 0, 0.65);
  }

  u {
    margin-top: 5px;
    font-size: 12px;
  }

  code {
    font-size: inherit;
    padding: 2px 4px;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: #fafafa;
  }
`;

DownloadDocument.Box = styled.div`
  width: 550px;
  padding: 30px;
  background: #fff;
  border: 1px solid hsl(218deg, 12%, 31%);
  border-radius: 4px;
  box-shadow: rgba(5, 14, 42, 0.08) 0 0 0 10px;
  text-align: center;

  > div {
    padding: 10px;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    margin-bottom: 15px;
  }

  p {
    font-size: 12px;
    padding: 10px;
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid #eee;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    margin: 10px;
    transition: all 0.3s;

    .anticon-cloud-download {
      font-size: 60px;
    }

    &:hover {
      color: #1890ff;
    }
  }
`;

export default DownloadDocument;
