import { SET_COMMANDBAR_READY } from '@modules/commandbar/actions';

/*
Adding a ready state to the store allows us to check before adding
context or callbacks in the CommandBar componenet.
This prevents adding CommandBar state to its window object before
it has been booted.
*/
export const initialState = {
  isCommandBarReady: false,
};

const CommandBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMANDBAR_READY: {
      return {
        ...state,
        isCommandBarReady: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default CommandBarReducer;
