import { BulkOperationStatus } from '@graphql/types/graphql';
import { emptyUndefinedToNull } from '@trustlayer/common';
import { Form, Modal, message } from 'antd';
import { capitalize } from 'lodash';
import { useEffect } from 'react';
import { useCreatePrimaryRecordsMutation } from '../../hooks/useCreatePrimaryRecordsMutation';
import { ModalFormContent } from './container/FormModalContent';

export type CreatePrimaryRecordModalState = {
  visible: boolean;
  primaryObjectId: string;
  primaryObjectName: string;
};

type CreatePrimaryRecordModalProps = {
  modalState: CreatePrimaryRecordModalState;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
  onCancel?: () => void;
};

type FormValues = {
  recordName: string | null;
  contactEmail: string | null;
  contactName: string | null;
  contactNumber: string | null;
  contactId: string | null;
};

export const CreatePrimaryRecordModal = ({
  modalState,
  onCompleted,
  onScheduled,
  onFailed,
  onCancel,
}: CreatePrimaryRecordModalProps) => {
  const [form] = Form.useForm<FormValues>();

  const { createPrimaryRecords, isCreatingPrimaryRecords } =
    useCreatePrimaryRecordsMutation();

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      const res = await createPrimaryRecords({
        variables: [
          {
            name: formData.recordName,
            primaryObjectId: modalState.primaryObjectId,
            contacts: [
              {
                email: formData.contactEmail,
                name: formData.contactName
                  ? emptyUndefinedToNull(formData.contactName)
                  : null,
                id: formData.contactId,
                // add here the number when BE accepts it
              },
            ],
          },
        ],
      });

      const status = res.data?.createPrimaryRecords.operation.status;

      if (status === BulkOperationStatus.Completed) {
        message.success(
          `${capitalize(modalState.primaryObjectName)} created successfully.`,
        );
        onCompleted?.();
      }

      if (status === BulkOperationStatus.Scheduled) {
        message.success(
          `The creation of a ${modalState.primaryObjectName} is being processed.`,
        );
        onScheduled?.();
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `The creation of a ${modalState.primaryObjectName} has failed. Please try again or contact support.`,
        );
        onFailed?.();
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  useEffect(() => {
    if (!modalState.visible) {
      form.resetFields();
    }
  }, [modalState.visible, form]);

  return (
    <Modal
      title={`New ${modalState.primaryObjectName}`}
      open={modalState.visible}
      destroyOnClose
      okText={`Create ${modalState.primaryObjectName}`}
      bodyStyle={{ padding: '24px' }}
      width={800}
      okButtonProps={{
        loading: isCreatingPrimaryRecords,
        onClick: handleSubmit,
      }}
      onCancel={onCancel}
    >
      <ModalFormContent formInstance={form} />
    </Modal>
  );
};
