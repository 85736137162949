import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import type { ReactElement } from 'react';

type OrganizationFeatureProviderProps = {
  children: ReactElement<any, any>;
  features: string[];
};

export const OrganizationFeatureProvider = ({
  children,
  features,
}: OrganizationFeatureProviderProps) => {
  return <FeatureToggles features={features}>{children}</FeatureToggles>;
};
