import styled from 'styled-components';

import DateFilterSelector, {
  KeyType,
} from '@common/components/DateFilterSelector';
import { pickOptionsFromFilterTypeOptions } from '@common/utils/date';

import type { CustomFilterProps } from '../../types';

import {
  denormalizeFilterValue,
  normalizeFilterValue,
} from './DateCustomFilter.utils';

/**
 * @note "denormalizeFilterValue" and "normalizeFilterValue" are used to save in the table state
 * a duration number instead of a moment instance
 */
export const DateCustomFilter = ({
  model,
  onModelChange,
}: CustomFilterProps) => {
  return (
    <StyledFilter>
      <DateFilterSelector
        selectedFilter={denormalizeFilterValue(model)}
        // @ts-expect-error - pickOptionsFromFilterTypeOptions is not typed yet
        filterTypes={pickOptionsFromFilterTypeOptions([
          KeyType.NEXT,
          KeyType.LAST,
          KeyType.BETWEEN,
          KeyType.ON,
          KeyType.OLDER,
        ])}
        onChange={(selectedFilter) => {
          onModelChange(normalizeFilterValue(selectedFilter));
        }}
      />
    </StyledFilter>
  );
};

const StyledFilter = styled.div`
  padding: 6px 12px;
`;
