import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPDATE_PERSONAL_ACCESS_TOKEN = `${namespace}/UPDATE_PERSONAL_ACCESS_TOKEN`;

const UPDATE_PERSONAL_ACCESS_TOKEN_MUTATION = gql`
  mutation updatePersonalAccessToken(
    $payload: UpdatePersonalAccessTokenInput!
  ) {
    updatePersonalAccessToken(data: $payload) {
      _id
      name
      level
      createdAt
    }
  }
`;

export const updatePersonalAccessToken = (payload) => ({
  type: UPDATE_PERSONAL_ACCESS_TOKEN,
  payload: {
    key: 'updatePersonalAccessToken',
    successMessage: 'Token updated successfully!',
    graphql: {
      mutation: UPDATE_PERSONAL_ACCESS_TOKEN_MUTATION,
      variables: { payload },
    },
  },
});
