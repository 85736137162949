import qs from 'query-string';
import * as R from 'ramda';

import { ProjectFilterFields } from '@modules/project/constants';

import { CustomFieldType, customFieldUrlRegexp } from '../constants';

export const CF_PATH_SEPARATOR = ':';

export const getCustomFieldKeys = () => {
  const searchParams = qs.parse(window.location.search);
  return R.compose(
    R.filter((key) => customFieldUrlRegexp.test(key)),
    R.keys,
  )(searchParams);
};

export const formatCustomFieldValue = (key, valueArray, customFields) => {
  const searchParams = qs.parse(window.location.search);
  const [id, type] = R.split(CF_PATH_SEPARATOR, key).slice(1);
  const value = R.prop(key, searchParams);
  const customFieldMap = R.indexBy(R.prop('_id'), customFields);
  const customField = R.prop(id, customFieldMap);

  if (type === CustomFieldType.Dropdown) {
    return {
      label: R.prop('name', customField),
      value: R.propOr([], 'options', customField)
        .filter(({ key }) => valueArray.includes(key))
        .map(({ value }) => value)
        .join(', '),
    };
  }

  return { label: R.prop('name', customField), value };
};

export const customFieldPath = (id, type) =>
  [ProjectFilterFields.CustomField, id, type].join(CF_PATH_SEPARATOR);

const parseValue = (value, type) => {
  switch (type) {
    case CustomFieldType.Number: {
      return Boolean(value) ? parseFloat(value) : null;
    }
    case CustomFieldType.Dropdown: {
      return Array.isArray(value) ? value : [value];
    }
    default: {
      return value;
    }
  }
};

export const getValueFromUrl = (id, type, params) => {
  const path = customFieldPath(id, type);

  return parseValue(R.prop(path, params), type);
};

export const buildCustomFieldsOps = (keys, params) =>
  keys.reduce((options, key) => {
    const value = R.prop(key, params);
    const valueAsArray = Array.isArray(value) ? value : [value];
    const customFieldPath = R.split(CF_PATH_SEPARATOR, key);
    const type = customFieldPath[2];
    const id = customFieldPath[1];

    if (R.values(CustomFieldType).includes(type)) {
      return {
        ...options,
        [`${id}:${type}`]: valueAsArray,
      };
    }

    return options;
  }, {});

export const getSelectedOptionValue = ({ options = [], selectedOptionId }) => {
  return options.find(({ key }) => key === selectedOptionId)?.value || '';
};
