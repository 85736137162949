import { Badge, Checkbox, Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ExpirationBadgePopover from '@common/components/Badges/components/ExpirationBadgePopover';
import FlagBadgePopover from '@common/components/Badges/components/FlagBadgePopover';
import VerificationBadgePopover from '@common/components/Badges/components/VerificationBadgePopover';
import Box from '@common/components/Box';
import DocumentLinkName from '@common/components/DocumentLinkName';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import { getSubjects } from '../utils/document-helpers';
import { nextExpirationDate } from '../utils/nextExpirationDate.legacy';

import { useIsBaseAddOnEnabled } from '@modules/add-on';
import DocumentReviewStatusTag from './DocumentReviewStatusTag';
import SubjectBadgePopover from './SubjectBadgePopover';

const DocumentCard = ({
  document,
  organizationNamespace,
  selectedDocuments,
  setSelectedDocuments,
  setSelectedDocument,
  setIsVisibleAssignOneModal,
  setSelectedDocumentsCount,
  setAreAllDocumentsSelected,
}) => {
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  const [type, ...types] = R.propOr([], 'types', document).map((type) =>
    R.propOr('', 'name', type),
  );

  const expirationDate = isBaseAddOnEnabled
    ? document.expirationDate
    : nextExpirationDate(document, document.party);

  return (
    <DocumentCard.Wrapper data-cy="documentCard">
      <Row type="flex">
        <DocumentCard.CheckboxWrapper>
          <HideForViewerRole>
            <Checkbox
              checked={selectedDocuments.includes(document._id)}
              onChange={() => {
                const documents = selectedDocuments.includes(document._id)
                  ? R.without([document._id], selectedDocuments)
                  : R.append(document._id, selectedDocuments);

                setAreAllDocumentsSelected(false);
                setSelectedDocuments(documents);
                setSelectedDocumentsCount(documents.length);
              }}
            />
          </HideForViewerRole>
        </DocumentCard.CheckboxWrapper>
        <DocumentCard.Content>
          <DocumentCard.Name>
            {R.prop('party', document) ? (
              <DocumentLinkName document={document}>
                <Tooltip
                  mouseEnterDelay={0.5}
                  placement="topLeft"
                  title={R.propOr('', 'friendlyName', document)}
                >
                  {R.propOr('', 'friendlyName', document)}
                </Tooltip>
              </DocumentLinkName>
            ) : (
              <Tooltip
                mouseEnterDelay={0.5}
                placement="topLeft"
                title={R.propOr('', 'friendlyName', document)}
              >
                <span
                  onClick={() => {
                    setIsVisibleAssignOneModal(true);
                    setSelectedDocument(document._id);
                  }}
                >
                  {R.propOr('', 'friendlyName', document)}
                </span>
              </Tooltip>
            )}
          </DocumentCard.Name>
          <DocumentCard.Badges>
            <VerificationBadgePopover
              documentData={{
                _id: R.propOr('', '_id', document),
                issuedBy: R.propOr({}, 'issuedBy', document),
                documentName: R.propOr(null, 'friendlyName', document),
              }}
              verificationData={R.prop('verification', document)}
              sizeIcon="Large"
              isShortVersion
            />
            <FlagBadgePopover
              documentData={{
                _id: R.propOr('', '_id', document),
                issuedBy: R.propOr({}, 'issuedBy', document),
                documentName: R.propOr(null, 'friendlyName', document),
              }}
              flagData={R.prop('flag', document)}
              sizeIcon="Large"
              defaultVisible
              isShortVersion
            />
          </DocumentCard.Badges>
          <DocumentCard.TypeWrapper>
            <DocumentCard.TypeName>
              <span>{type || <i>No document type</i>}</span>
              {!R.isEmpty(types) && (
                <Tooltip title={types.join(', ')}>
                  <TypeCount>+{types.length}</TypeCount>
                </Tooltip>
              )}
            </DocumentCard.TypeName>
            <DocumentReviewStatusTag
              isReviewed={Boolean(document.reviewedAt)}
            />
          </DocumentCard.TypeWrapper>
          <DocumentCard.Subjects data-cy="subjectsBadgeWrapper">
            {getSubjects(document).length > 0 ? (
              getSubjects(document).map((subject) => (
                <SubjectBadgePopover
                  key={`key_${subject.name}`}
                  subject={subject}
                >
                  <DocumentCard.Badge
                    dot={!!subject.expColor}
                    color={subject.expColor}
                  >
                    <DocumentCard.Abbr data-cy="subjectBadgeAbbr">
                      {subject.abbr}
                    </DocumentCard.Abbr>
                  </DocumentCard.Badge>
                </SubjectBadgePopover>
              ))
            ) : (
              <span className="empty" />
            )}
          </DocumentCard.Subjects>
          <DocumentCard.PartyName>
            {Boolean(R.prop('party', document)) ? (
              <Link
                to={`/${organizationNamespace}/parties/${R.path(
                  ['party', '_id'],
                  document,
                )}/overview`}
              >
                {R.pathOr('', ['party', 'name'], document)}
              </Link>
            ) : (
              'Not assigned'
            )}
          </DocumentCard.PartyName>
          <DocumentCard.Dates>
            <div>
              Uploaded{' '}
              <span className="prominent">
                {moment(document.createdAt).format('ll')}
              </span>
            </div>

            <DocumentCard.ExpirationDate>
              <ExpirationBadgePopover
                expirationDate={expirationDate}
                sizeIcon="Medium"
              />
              <span className="prominent">
                {expirationDate ? moment.utc(expirationDate).format('ll') : '—'}
              </span>
            </DocumentCard.ExpirationDate>
          </DocumentCard.Dates>
        </DocumentCard.Content>
      </Row>
    </DocumentCard.Wrapper>
  );
};

DocumentCard.Wrapper = styled(Box)`
  padding: 15px;
  height: 100%;
`;

DocumentCard.CheckboxWrapper = styled(Col)`
  margin-right: 13px;
`;

DocumentCard.Content = styled(Col)`
  width: calc(100% - 30px);
`;

DocumentCard.TypeWrapper = styled.div`
  margin-top: 12px;
  display: flex;
`;

DocumentCard.TypeName = styled.span`
  font-size: 12px;
  line-height: 24px;
  display: flex;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 10px;

  i {
    font-style: normal;
    color: #aaa;
  }
`;

export const TypeCount = styled.div`
  margin-left: 8px;
  line-height: 24px;
  padding: 0 7px;
  background-color: #e8edf3;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.grayText};
`;

DocumentCard.Subjects = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  .empty {
    height: 25px;
  }
`;

DocumentCard.Name = styled.div`
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.85);
  padding-right: 70px;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: nowrap;
  }
`;

DocumentCard.Badges = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 20px 20px;
  gap: 10px;
  top: 0;
  right: 0;
`;

DocumentCard.Badge = styled(Badge)`
  margin-right: 5px !important;
  margin-bottom: 5px !important;

  .ant-badge-dot {
    background-color: ${(props) => props.color} !important;
    z-index: auto;
  }
`;

DocumentCard.Abbr = styled.div`
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grayText};
  user-select: none;
`;

DocumentCard.PartyName = styled.div`
  font-size: 12px;
`;

DocumentCard.Dates = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);

  .prominent {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 450;
  }
`;

DocumentCard.ExpirationDate = styled.div`
  display: flex;
`;

export default DocumentCard;
