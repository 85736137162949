import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PROJECT_FRAGMENT } from '../fragments';

export const REMOVE_PARTIES_FROM_PROJECT = `${namespace}/REMOVE_PARTIES_FROM_PROJECT`;

const REMOVE_PARTIES_FROM_PROJECT_MUTATION = gql`
  mutation removePartiesFromProject($data: RemovePartiesFromProjectInput!) {
    removePartiesFromProject(data: $data) {
      ...Project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const removePartiesFromProject = (data) => ({
  type: REMOVE_PARTIES_FROM_PROJECT,
  payload: {
    key: 'removePartiesFromProject',
    graphql: {
      mutation: REMOVE_PARTIES_FROM_PROJECT_MUTATION,
      variables: {
        data,
      },
    },
  },
});
