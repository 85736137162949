export const TAG_COLORS = {
  magenta: { text: '', border: '', background: '' },
  red: { text: '', border: '', background: '' },
  volcano: { text: '', border: '', background: '' },
  gold: { text: '', border: '', background: '' },
  lime: { text: '', border: '#c5ef0e', background: '' },
  green: { text: '', border: '', background: '' },
  cyan: { text: '', border: '', background: '' },
  blue: { text: '', border: '', background: '' },
  geekblue: { text: '', border: '', background: '' },
  purple: { text: '', border: '', background: '' },
  '#f50': { text: '#fff', border: '', background: '' },
  '#2db7f5': { text: '#fff', border: '', background: '' },
  '#87d068': { text: '#fff', border: '', background: '' },
  '#108ee9': { text: '#fff', border: '', background: '' },
  '#de49c6': { text: '#fff', border: '', background: '' },
  '#fff': { text: '#666', border: '#ccc', background: '' },
  '#444': { text: '#fff', border: '', background: '' },
};
