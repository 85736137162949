import * as R from 'ramda';

import {
  ArrowType,
  SortDirectionsFullMap,
  SortDirectionsMap,
} from '@common/constants';
import { normalizeArray, normalizeDate } from '@common/utils/filter-helpers';

export const PartyStatus = {
  Active: 'active',
  Inactive: 'inactive',
};

export const PartyComplianceStatus = {
  Compliant: 'compliant',
  NonCompliant: 'non_compliant',
};

export const PartyComplianceStatusLabelsMap = {
  [PartyComplianceStatus.Compliant]: 'Compliant',
  [PartyComplianceStatus.NonCompliant]: 'Non-compliant',
};

export const PartyReviewStatus = {
  Reviewed: 'reviewed',
  ToReview: 'toReview',
};

export const PartyReviewStatusLabelMap = {
  [PartyReviewStatus.Reviewed]: 'Reviewed',
  [PartyReviewStatus.ToReview]: 'To review',
};

export const SeverityLevel = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
};

export const SeverityLevelLabelMap = {
  [SeverityLevel.High]: 'High severity',
  [SeverityLevel.Medium]: 'Medium severity',
  [SeverityLevel.Low]: 'Low severity',
};

export const PartyComplianceAcrossProjectsStatus = {
  CompliantAcrossAllProjects: 'compliantAcrossAllProjects',
  NonCompliantForOneOrMoreProjects: 'nonCompliantForOneOrMoreProjects',
};

export const PartyComplianceAcrossProjectsStatusLabelsMap = {
  [PartyComplianceAcrossProjectsStatus.CompliantAcrossAllProjects]:
    'Compliant across projects',
  [PartyComplianceAcrossProjectsStatus.NonCompliantForOneOrMoreProjects]:
    'Non-compliant for one or more projects',
};

/**
 * Party sort fields.
 */
export const PartySortFields = {
  Name: 'name',
  Type: 'type',
  Flag: 'flag',
  Status: 'status',
  ExpirationDate: 'nextExpirationDate',
  LastRequestSent: 'lastRequestSent',
  LastActivityDate: 'lastActivityDate',
  Added: 'createdAt',
  FailedMessages: 'failedMessages',
};

/**
 * Party filter fields.
 */
export const PartyFilterFields = {
  Name: 'name',
  Type: 'type',
  ComplianceStatus: 'complianceStatus',
  ComplianceAcrossProjects: 'complianceAcrossProjects',
  ReviewStatus: 'reviewStatus',
  LastRequestSent: 'lastRequestSent',
  LastActivityDate: 'lastActivityDate',
  ExpirationDate: 'nextExpirationDate',
  Tag: 'tag',
  Tags: 'tags',
  CustomField: 'customField',
  DocumentsCount: 'documentsCount',
  Flag: 'flag',
  Waivers: 'waivers',
  Overrides: 'overrides',
  Project: 'project',
  Automations: 'automations',
  AutomationExpiringDocumentRemaindersStatus:
    'automationExpiringDocumentRemaindersStatus',
  AutomationDocumentRemindersSentCount: 'automationDocumentRemindersSentCount',
  AssignedToProjects: 'assignedToProjects',
  AssignedToAnyProject: 'assignedToAnyProject',
  NotAssignedToAnyProject: 'notAssignedToAnyProject',
  InsuranceLicenseStatus: 'insuranceLicense',
  FailedMessages: 'failedMessages',
  ContactEmail: 'contactEmail',
  AssignedComplianceProfile: 'assignedComplianceProfile',
} as const;

export const DocumentsCountOptionsType = {
  Exactly: {
    value: 'exactly',
    label: 'Exactly',
  },
  MoreThan: {
    value: 'moreThan',
    label: 'More than',
  },
  LessThan: {
    value: 'lessThan',
    label: 'Less than',
  },
};

/**
 * Normalize document count filter.
 */
export const normalizeCounter = (value: string | undefined) => {
  if (value) {
    const [fromParamsType, fromParamsValue] = R.split('-', value);

    return R.compose(
      (label) => `${label} ${fromParamsValue}`,
      R.propOr('', 'label'),
      R.find(R.propEq('value', fromParamsType)),
      R.values,
    )(DocumentsCountOptionsType);
  }
};

function normalizeCustomField(value: string | undefined) {
  return value?.replaceAll?.('-', ' ');
}

/**
 * Normalized party filters.
 */
export const NormalizedPartyFilters = {
  [PartyFilterFields.Name]: {
    label: 'Name',
  },
  [PartyFilterFields.Type]: {
    label: 'Type',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.ComplianceStatus]: {
    label: 'Compliance status',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.ComplianceAcrossProjects]: {
    label: 'Compliance across projects',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.ReviewStatus]: {
    label: 'Review status',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.Flag]: {
    label: 'Flag',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.AutomationExpiringDocumentRemaindersStatus]: {
    label: 'Automations',
  },
  [PartyFilterFields.AutomationDocumentRemindersSentCount]: {
    label: 'Automations',
  },
  [PartyFilterFields.Automations]: {
    label: 'Automations',
  },
  [PartyFilterFields.LastRequestSent]: {
    label: 'Last request',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [PartyFilterFields.LastActivityDate]: {
    label: 'Last activity',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [PartyFilterFields.ExpirationDate]: {
    label: 'Expiration',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [PartyFilterFields.FailedMessages]: {
    label: 'Message Failures',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [PartyFilterFields.Tags]: {
    label: 'Tags',
  },
  [PartyFilterFields.CustomField]: {
    label: 'Custom Field',
    normalizeValue: normalizeCustomField,
  },
  [PartyFilterFields.AssignedToProjects]: {
    label: 'Associated to projects',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.AssignedComplianceProfile]: {
    label: 'Compliance Profile',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.DocumentsCount]: {
    label: 'Documents count',
    normalizeValue: normalizeCounter,
  },
  [PartyFilterFields.Waivers]: {
    label: 'Waivers',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.Overrides]: {
    label: 'Overrides',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.InsuranceLicenseStatus]: {
    label: 'Insurance License',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.ContactEmail]: {
    label: 'Contact Email',
    normalizeValue: normalizeArray,
  },
  [PartyFilterFields.AssignedToAnyProject]: {
    label: 'Projects',
  },
  [PartyFilterFields.NotAssignedToAnyProject]: {
    label: 'Projects',
  },
};

/**
 * Data for parties sort.
 */
export const getPartiesSortOptions = ({
  isComplianceRequirementsAddOnEnabled,
}: {
  isComplianceRequirementsAddOnEnabled: boolean;
}) =>
  [
    {
      label: 'Name',
      iconType: ArrowType.Up,
      field: PartySortFields.Name,
      direction: SortDirectionsMap.asc,
      helpText: 'A › Z',
    },
    {
      label: 'Name',
      iconType: ArrowType.Down,
      field: PartySortFields.Name,
      direction: SortDirectionsMap.desc,
      helpText: 'Z › A',
    },
    {
      label: 'Party type',
      iconType: ArrowType.Up,
      field: PartySortFields.Type,
      direction: SortDirectionsMap.asc,
      helpText: 'A › Z',
    },
    {
      label: 'Party type',
      iconType: ArrowType.Down,
      field: PartySortFields.Type,
      direction: SortDirectionsMap.desc,
      helpText: 'Z › A',
    },
    {
      label: 'Message failures',
      iconType: ArrowType.Up,
      field: PartySortFields.FailedMessages,
      direction: SortDirectionsMap.asc,
      helpText: 'close › far',
    },
    {
      label: 'Message failures',
      iconType: ArrowType.Down,
      field: PartySortFields.FailedMessages,
      direction: SortDirectionsMap.desc,
      helpText: 'far › close',
    },
    {
      label: 'Flag',
      iconType: ArrowType.Up,
      field: PartySortFields.Flag,
      direction: SortDirectionsMap.asc,
      helpText: 'none › low › medium › high',
    },
    {
      label: 'Flag',
      iconType: ArrowType.Down,
      field: PartySortFields.Flag,
      direction: SortDirectionsMap.desc,
      helpText: 'high › medium › low › none',
    },
    {
      label: 'Status',
      iconType: ArrowType.Up,
      field: PartySortFields.Status,
      isVisible: isComplianceRequirementsAddOnEnabled,
      direction: SortDirectionsMap.asc,
      helpText: 'noncompliant › compliant',
    },
    {
      label: 'Status',
      iconType: ArrowType.Down,
      field: PartySortFields.Status,
      direction: SortDirectionsMap.desc,
      isVisible: isComplianceRequirementsAddOnEnabled,
      helpText: 'compliant › noncompliant',
    },
    {
      label: 'Expiration',
      iconType: ArrowType.Up,
      field: PartySortFields.ExpirationDate,
      direction: SortDirectionsMap.asc,
      helpText: 'close › far',
    },
    {
      label: 'Expiration',
      iconType: ArrowType.Down,
      field: PartySortFields.ExpirationDate,
      direction: SortDirectionsMap.desc,
      helpText: 'far › close',
    },
    {
      label: 'Last request',
      iconType: ArrowType.Up,
      field: PartySortFields.LastRequestSent,
      direction: SortDirectionsMap.asc,
      helpText: 'less recent › more recent',
    },
    {
      label: 'Last request',
      iconType: ArrowType.Down,
      field: PartySortFields.LastRequestSent,
      direction: SortDirectionsMap.desc,
      helpText: 'more recent › less recent',
    },
    {
      label: 'Last activity',
      iconType: ArrowType.Up,
      field: PartySortFields.LastActivityDate,
      direction: SortDirectionsMap.asc,
      helpText: 'less recent › more recent',
    },
    {
      label: 'Last activity',
      iconType: ArrowType.Down,
      field: PartySortFields.LastActivityDate,
      direction: SortDirectionsMap.desc,
      helpText: 'more recent › less recent',
    },
    {
      label: 'Added',
      iconType: ArrowType.Up,
      field: PartySortFields.Added,
      direction: SortDirectionsMap.asc,
      helpText: 'less recent › more recent',
    },
    {
      label: 'Added',
      iconType: ArrowType.Down,
      field: PartySortFields.Added,
      direction: SortDirectionsMap.desc,
      helpText: 'more recent › less recent',
    },
  ].filter((item) => item.isVisible !== false);

/**
 * Get sort label by field and direction.
 */
export const getSortLabelData = ({
  field,
  direction,
  isComplianceRequirementsAddOnEnabled,
}: {
  field: string;
  direction: string;
  isComplianceRequirementsAddOnEnabled: boolean;
}) =>
  getPartiesSortOptions({ isComplianceRequirementsAddOnEnabled }).find(
    (option) => option.field === field && option.direction === direction,
  );

/**
 * Checks if the primary contact email is empty
 */
// @ts-expect-error
export const isPrimaryContactEmailEmpty = R.compose(
  R.isEmpty,
  R.prop('email'),
  R.find(R.propEq('isPrimary', true)),
  R.propOr([], 'partyContacts'),
);

export const NoContactInformationLabel = 'No contact information available';

export const getPartiesColumns = ({
  sortField,
  sortDirection,
}: {
  sortField: string;
  sortDirection: string;
}) => {
  // @ts-expect-error
  const order = SortDirectionsFullMap[sortDirection];

  return [
    {
      title: 'Name',
      dataIndex: PartySortFields.Name,
      key: PartySortFields.Name,
      sorter: true,
      sortOrder: sortField === PartySortFields.Name ? order : false,
    },
    {
      title: 'Status',
      dataIndex: PartySortFields.Status,
      key: PartySortFields.Status,
      sorter: true,
      sortOrder: sortField === PartySortFields.Status ? order : false,
      width: 180,
    },
    {
      title: 'Next expiration',
      dataIndex: PartySortFields.ExpirationDate,
      key: PartySortFields.ExpirationDate,
      sorter: true,
      sortOrder: sortField === PartySortFields.ExpirationDate ? order : false,
      width: 110,
    },
    {
      title: 'Last request',
      dataIndex: PartySortFields.LastRequestSent,
      key: PartySortFields.LastRequestSent,
      sorter: true,
      sortOrder: sortField === PartySortFields.LastRequestSent ? order : false,
      width: 110,
    },
    {
      title: 'Last activity',
      dataIndex: PartySortFields.LastActivityDate,
      key: PartySortFields.LastActivityDate,
      sorter: true,
      sortOrder: sortField === PartySortFields.LastActivityDate ? order : false,
      width: 110,
    },
    {
      dataIndex: 'menu',
      key: 'menu',
      width: 100,
    },
  ];
};

export const getAdvancedComplianceProfileColor = ({
  type,
  score,
}: {
  type: string;
  score: number;
}) => {
  if (score === 100) {
    return '#41CA00';
  }

  if (score === 0) {
    return '#eee';
  }

  if (type === 'project') {
    return '#1890FF';
  }

  if (type === 'base') {
    return '#1890FF';
  }

  return '';
};

export const EventType = {
  bulkUpdateActiveStatusPartiesFail: 'bulk:update:parties:status:fail',
  bulkUpdateActiveStatusPartiesSuccess: 'bulk:update:parties:status:success',
  BulkDeletePartiesFail: 'bulk:delete:parties:fail',
  BulkDeletePartiesSuccess: 'bulk:delete:parties:success',
  bulkAssignComplianceProfilePartiesFail: 'bulk:assign:compliance:parties:fail',
  bulkAssignComplianceProfilePartiesSuccess:
    'bulk:assign:compliance:parties:success',
  bulkAssignPartyTypePartiesFail: 'bulk:assign:type:parties:fail',
  bulkAssignPartyTypePartiesSuccess: 'bulk:assign:type:parties:success',
  bulkAddTagsPartiesFail: 'bulk:add:tags:parties:fail',
  bulkAddTagsPartiesSuccess: 'bulk:add:tags:parties:success',
  bulkDeleteTagsPartiesFail: 'bulk:delete:tags:parties:fail',
  bulkDeleteTagsPartiesSuccess: 'bulk:delete:tags:parties:success',
  bulkAddPartiesToProjectFail: 'bulk:add:parties:to:project:fail',
  bulkAddPartiesToProjectSuccess: 'bulk:add:parties:to:project:success',
  bulkEnablePartiesContactsRemindersSuccess:
    'bulk:enable:parties:contacts:reminders:success',
  bulkEnablePartiesContactsRemindersFail:
    'bulk:enable:parties:contacts:reminders:fail',
};
