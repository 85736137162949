import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { REPORT_FRAGMENT } from '../fragments';

export const CREATE_REPORT = `${namespace}/CREATE_REPORT`;
const CREATE_REPORT_MUTATION = gql`
  mutation createReport($payload: CreateReportInput!) {
    createReport(data: $payload) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const createReport = (payload) => ({
  type: CREATE_REPORT,
  payload: {
    key: 'createReport',
    graphql: {
      mutation: CREATE_REPORT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
