import { graphql } from '@graphql/types';

export const MARK_NOTIFICATION_MUTATION = graphql(`
  mutation MarkNotifications(
    $data: MarkNotificationsInput!
    $filter: NotificationFiltersInput!
  ) {
    markNotifications(data: $data, filter: $filter)
  }
`);
