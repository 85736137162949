import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const CREATE_REQUEST = `${namespace}/CREATE_REQUEST`;

const CREATE_REQUEST_MUTATION = gql`
  mutation createRequest($payload: CreateRequestInput!) {
    createRequest(data: $payload) {
      _id
      lastRequestSentOn
      isSendingExpiringRequest
      requests {
        _id
        deletedAt
        deletedBy
        forwardedAt
        forwardedTo
        sentByUser
        party
        message
        attachments {
          url
          friendlyName
          s3Key
        }
        documentChecklists
        sentOn
        organization
        dueDate
        project
        createdAt
        updatedAt
      }
    }
  }
`;

export const createRequest = (payload) => ({
  type: CREATE_REQUEST,
  payload: {
    key: 'createRequest',
    graphql: {
      mutation: CREATE_REQUEST_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
