import { gql } from '@apollo/client';

import { namespace } from '../constants';
import { PROJECT_FRAGMENT } from '../fragments';

export const UPDATE_PROJECT = `${namespace}/UPDATE_PROJECT`;
const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject($payload: UpdateProjectInput!) {
    updateProject(data: $payload) {
      ...Project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const updateProject = (payload) => ({
  type: UPDATE_PROJECT,
  payload: {
    key: 'updateProject',
    graphql: {
      mutation: UPDATE_PROJECT_MUTATION,
      variables: {
        payload,
      },
      context: { hasUpload: true },
    },
  },
});
